import { Image } from 'antd'

const Avatar = (props) => {
  // eslint-disable-next-line react/prop-types
  const { isMobile } = props
  const avatarSize = isMobile ? 80 : 120

  const avatarStyles = {
    borderRadius: '50%',
    width: avatarSize,
    height: avatarSize
  }
  return <Image {...props} style={avatarStyles} />
}

export { Avatar }
