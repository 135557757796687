import { Col, Divider, Row } from 'antd'

import PropTypes from 'prop-types'
import { SettingsNotificationSimpleView } from 'domains/Notification/components'
import { useGetSettingsNotificationListItems } from 'domains/Notification/hooks'
import { useMemo } from 'react'

const SettingsNotificationList = (props) => {
  const { initialValues } = props
  const notifications = useGetSettingsNotificationListItems()

  const lastElement = useMemo(
    () => notifications?.length - 1,
    [notifications?.length]
  )

  const notificationListItem = useMemo(
    () =>
      notifications?.map((item, index) => (
        <Col span={24} key={`verification-item${index}`}>
          <SettingsNotificationSimpleView
            initialValues={initialValues}
            notification={item}
            userId={initialValues?._id}
            area-label={`notification-${index + 1}`}
            index={index}
          />
          {lastElement === index ? null : <Divider />}
        </Col>
      )),
    [initialValues, lastElement, notifications]
  )

  return <Row>{notificationListItem}</Row>
}

SettingsNotificationList.propTypes = {
  initialValues: PropTypes.object
}

export default SettingsNotificationList
