const SETTINGS_PATHS = {
  MY_PROFILE: '/settings/my-profile',
  CHANGE_PASSWORD: '/settings/change-password',
  NOTIFICATIONS: '/settings/notifications',
  STRIPE_ACCOUNT: '/settings/stripe-account',
  ADDRESSES_ALL: '/settings/addresses',
  ADDRESS_CREATE: '/settings/addresses/create',
  ADDRESS_EDIT: '/settings/addresses/:addressId/edit',
  CONTACT_US: '/settings/contactUs',
  VERIFICATION: '/settings/verifications'
}

export default SETTINGS_PATHS
