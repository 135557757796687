import { MOMENT_FORMATS } from '__constants__'
import PropTypes from 'prop-types'
import { Typography } from 'antd'
import moment from 'moment'

const ComplaintDate = (props) => {
  const { complaint, noData } = props

  const startDateSeconds = moment
    .unix(complaint?._createdAt?.seconds)
    .format(MOMENT_FORMATS.DAYS_MONTH_YEAR_WITH_DOTS)

  return startDateSeconds ? (
    <Typography.Text>{startDateSeconds}</Typography.Text>
  ) : (
    noData
  )
}

ComplaintDate.propTypes = {
  complaint: PropTypes.object,
  noData: PropTypes.node,
  noIcon: PropTypes.bool
}

export default ComplaintDate
