import { Col, Divider, Row, Typography } from 'antd'

import { CANCELLATION_TERMS_TYPES_DESCRIPTION } from '__constants__'
import { Form } from 'antd'
import PropTypes from 'prop-types'
import { REFUND_TYPES_DESCRIPTION } from '__constants__'
import { calculateFinalPayment } from 'helpers'
import { calculateProductPriceByDays } from 'domains/Product/helpers'
import { currencySymbol } from 'domains/Product/hooks'
import { useMemo } from 'react'
import { useTranslations } from 'hooks'

const CheckoutProductPriceDetails = (props) => {
  const { cart, isSmallScreen, withTitle, form } = props
  const { t } = useTranslations()
  const rentingDays = Form.useWatch(['dates'], form)
  const [totalPrice, totalDays] = calculateProductPriceByDays(rentingDays, cart)

  const productCurrencySymbol = currencySymbol(cart?.currency)

  const totalPriceToShow = calculateFinalPayment(totalPrice)
  // TODO: change to real data + create logic
  const checkoutInfo = useMemo(() => {
    return [
      {
        label: `${t('Period')}`,
        value: `${totalDays} ${totalDays === 1 ? 'day' : 'days'}`
      }
      // we dont apply any discounts for now. So we can hide it for now
      // {
      //   label: t('Taxes'),
      //   value: `0 ${productCurrencySymbol}`
      // },
      // {
      //   label: t('Weekly discount'),
      //   value: `0 ${productCurrencySymbol}`
      // }
    ]
  }, [t, totalDays])

  return (
    <>
      {withTitle && (
        <div className="mb-24">
          <Typography.Title level={4}>{t('Price details')}</Typography.Title>
        </div>
      )}
      {checkoutInfo?.map((info, index) => {
        return (
          <Row key={`${index}-checkout-info-string`}>
            <Col
              span={24}
              className={
                index === checkoutInfo?.length - 1
                  ? 'flex flex-row justify-between align-center'
                  : 'flex flex-row justify-between align-center mb-8'
              }>
              <Typography.Text>{info?.label}</Typography.Text>
              <Typography.Text>{info?.value}</Typography.Text>
            </Col>
          </Row>
        )
      })}
      {!isSmallScreen && <Divider />}
      <Row className={isSmallScreen && 'mt-8'}>
        <Col span={24} className="flex flex-row justify-between align-center">
          <Typography.Text strong className={isSmallScreen && 'mt-8'}>
            {t('Total')}
          </Typography.Text>
          <Typography.Text
            strong>{`${totalPriceToShow}${productCurrencySymbol}`}</Typography.Text>
        </Col>
      </Row>
      <Divider />
      <Row className={isSmallScreen && 'mt-8'}>
        <Col span={24} className="flex flex-col justify-between">
          {/* cancellationTerms */}
          <Typography.Text strong className="mb-6">
            {t('Cancellation terms')}:
          </Typography.Text>
          <Typography.Text>
            {t(CANCELLATION_TERMS_TYPES_DESCRIPTION[cart?.cancellationTerms])}
          </Typography.Text>
        </Col>

        {/* Refund info */}
        <Col span={24} className="flex flex-col justify-between">
          <Typography.Text className="mt-12 mb-6" strong>
            {t('Refund info')}:
          </Typography.Text>
          <Typography.Text>
            <Typography.Text>
              {t(REFUND_TYPES_DESCRIPTION.BASIC)}
            </Typography.Text>
          </Typography.Text>
        </Col>
      </Row>
      {isSmallScreen && <Divider />}
    </>
  )
}

CheckoutProductPriceDetails.propTypes = {
  cart: PropTypes.object,
  isSmallScreen: PropTypes.bool,
  withTitle: PropTypes.bool,
  form: PropTypes.object
}
export default CheckoutProductPriceDetails
