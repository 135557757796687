import { Select, Typography } from 'antd'

import PropTypes from 'prop-types'
import _ from 'lodash'
import { getId } from 'services/api/firebase'
import { useGetTags } from 'domains/Tag/hooks'
import { useMemo } from 'react'
import { useTranslations } from 'hooks'

const { Option } = Select

const TagListWithCreate = (props) => {
  const { value, onChange, withTitle } = props

  /* Getting translations instance */
  const { t } = useTranslations()

  /* Getting all Tags documents */
  const [allTags = []] = useGetTags({ orderBy: [['name', 'asc']] })

  /* Computing possible options */
  const possibleOptions = useMemo(
    () => (value ? _.uniqBy([...allTags, ...(value || [])], 'name') : allTags),
    [allTags, value]
  )

  /* Getting default value */
  const computedValue = useMemo(() => value?.map((item) => item?.name), [value])

  /* Handling changing value */
  const handleChange = (value) => {
    const checkIfArrayContainsItem = (item) => {
      return allTags?.some((tag) => tag.name === item)
    }

    onChange(
      value.map((item) => {
        return !checkIfArrayContainsItem(item)
          ? { name: item, _id: item._id || getId('tags') }
          : Array.from(possibleOptions).find((tag) => tag?.['name'] === item)
      })
    )
  }

  return (
    <div className="mb-24">
      {withTitle && (
        <Typography.Title level={3} className="mb-8">
          {t('Tags')}
        </Typography.Title>
      )}
      <Select
        onChange={handleChange}
        mode="multiple"
        placeholder={t('Select tags')}
        value={computedValue}>
        {possibleOptions.map((tag, index) => (
          <Option key={index} value={tag?.name}>
            {tag?.name}
          </Option>
        ))}
      </Select>
    </div>
  )
}

TagListWithCreate.propTypes = {
  value: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
  withTitle: PropTypes.bool
}

export default TagListWithCreate
