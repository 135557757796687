import { Breadcrumbs, PageActions, PageLayout, Spinner } from 'components'
import { Col, Row } from 'antd'
import {
  useGetNotifications,
  useNotificationBaseActions
} from 'domains/Notification/hooks'
import { useListView, useTranslations } from 'hooks'

import { NotificationList } from 'domains/Notification/components'
import { PATHS } from '__constants__'
import PropTypes from 'prop-types'

const NotificationsAllPage = (props) => {
  const { isListWithCreate, withHeader = true } = props

  /* Getting translations instance */
  const { t } = useTranslations()
  /* State for controlling list view with localStorage remembering */
  const [listView, setListView] = useListView('notification')
  /* Fetching notifications */
  const [notifications, loading] = useGetNotifications()
  /* Getting base actions */
  const listActions = useNotificationBaseActions()

  /* Configuring page header */
  const headingProps = {
    title: t('Notifications'),
    textAlign: 'left'
  }

  /* Configuring page actions */
  const actions = {
    filter: true,
    sort: true,
    listView: true,
    create: Boolean(
      PATHS.AUTHENTICATED.NOTIFICATION_CREATE ||
        PATHS.AUTHENTICATED.NOTIFICATION_WIZARD
    )
  }

  /* Extracting base actions */
  const { onBack, onCreate } = listActions

  return (
    <PageLayout
      actions={
        withHeader ? (
          <PageActions
            listView={listView}
            setListView={setListView}
            onCreate={onCreate}
            actions={actions}
            createText={t('Create notification')}
          />
        ) : null
      }
      onBack={withHeader ? onBack : null}
      breadcrumbs={withHeader ? <Breadcrumbs /> : null}
      headingProps={withHeader ? headingProps : null}
      area="notifications">
      <Row gutter={[24, 16]}>
        {loading ? (
          <Col flex="auto">
            <Spinner height="70vh" text={t('Notification loading')} />
          </Col>
        ) : (
          <Col span={24}>
            <NotificationList
              listView={listView}
              actions={{ onCreate }}
              isListWithCreate={isListWithCreate}
              notifications={notifications}
            />
          </Col>
        )}
      </Row>
    </PageLayout>
  )
}

NotificationsAllPage.propTypes = {
  isListWithCreate: PropTypes.bool,
  withHeader: PropTypes.bool
}

export default NotificationsAllPage
