import { ProductLike } from 'domains/Product/components'
import PropTypes from 'prop-types'

const RenteeActions = (props) => {
  const layout = props?.layout || 'horizontal'

  const wrapperClassComputed = `flex ${
    layout !== 'horizontal' && 'flex-col'
  } justify-between gap-12 align-center`
  // for now we dont have any way to share product so hidden
  // const shareDropdownMenu = [
  //   {
  //     label: t('Mail'),
  //     icon: (
  //       <Button
  //         className="flex align-center justify-center p-0 m-0"
  //         type="link">
  //         <Icon name="MailFilled" size={16} mr={2} fill={colorTextTertiary} />
  //       </Button>
  //     ),
  //     key: 'MailFilled'
  //   },
  //   {
  //     label: t('Facebook'),
  //     icon: (
  //       <Button
  //         className="flex align-center justify-center p-0 m-0"
  //         type="link">
  //         <Icon name="FacebookOutlined" size={16} mr={2} />
  //       </Button>
  //     ),
  //     key: 'FacebookFilled'
  //   },
  //   {
  //     label: t('Twitter'),
  //     icon: (
  //       <Button
  //         className="flex align-center justify-center p-0 m-0"
  //         type="link">
  //         <Icon name="Twitter1Outlined" size={16} mr={2} />
  //       </Button>
  //     ),
  //     key: 'Twitter1Outlined'
  //   }
  // ]

  return (
    <div className={wrapperClassComputed}>
      <ProductLike productId={props?.productId} style={{ width: 'inherit' }} />
      {/* for now we dont have any way to share product so hidden */}
      {/* <Dropdown
        id="like-product"
        key="like-product"
        menu={{ items: shareDropdownMenu }}
        placement="bottomRight">
        <Button
          className="flex align-center justify-center"
          icon={<Icon name="Share7Outlined" size={16} />}
        />
      </Dropdown> */}
    </div>
  )
}

RenteeActions.propTypes = {
  layout: PropTypes.string,
  productId: PropTypes.string
}
export default RenteeActions
