import { NOTIFICATION_TYPES } from '__constants__'
import { ORDER_STATUSES } from '__constants__'
import { generatePath } from 'react-router-dom'

const generateNotificationPath = (
  navigate,
  notification,
  orderStatus,
  isMobile,
  isRentee,
  setIsRentee
) => {
  const orderStatusChangeForRentee = orderStatus === ORDER_STATUSES.CANCELED

  const navigationMap = {
    [NOTIFICATION_TYPES.PRODUCT_REVIEW]: () => {
      navigate(`/my-products/${notification?.productId}`)
      setIsRentee(false)
    },
    [NOTIFICATION_TYPES.ORDER_REQUEST]: () => {
      isMobile
        ? navigate(`/orders/${notification?.orderId}`, {
            state: { orderId: notification.orderId }
          })
        : navigate('/orders', {
            state: { orderId: notification.orderId }
          })
      setIsRentee(false)
    },
    [NOTIFICATION_TYPES.ORDER_STATUS_CHANGE]: () => {
      isMobile
        ? navigate(`/orders/${notification?.orderId}`, {
            state: {
              orderId: notification.orderId
            }
          })
        : navigate('/orders', {
            state: {
              orderId: notification.orderId
            }
          })
      setIsRentee(orderStatusChangeForRentee ? false : true)
    },
    [NOTIFICATION_TYPES.MESSAGE]: () =>
      navigate('/chats', {
        state: { chatId: notification?.chatId }
      }),
    [NOTIFICATION_TYPES.COMPLAINT]: () => generatePath('/complaints'),
    [NOTIFICATION_TYPES.COMPLAINT_STATUS_CHANGE]: () => navigate('/complaints'),
    [NOTIFICATION_TYPES.TICKET]: () => navigate('/tickets'),
    [NOTIFICATION_TYPES.TICKET_STATUS_CHANGE]: () => navigate('/tickets')
  }

  const navigateToPath = navigationMap[notification.type]
  if (navigateToPath) {
    return navigateToPath()
  }

  return '/'
}
export default generateNotificationPath
