import { Breadcrumbs, List, PageLayout, Spinner } from 'components'
import {
  ComplaintList,
  ComplaintMobileActions,
  ComplaintSimpleView
} from 'domains/Complaint/components'
import {
  useComplaintBaseActions,
  useComplaintFilter,
  useComplaintSearch,
  useComplaintSort,
  useGetMyComplaintsAndRelatedData
} from 'domains/Complaint/hooks'
import { useCurrentScreen, useListView, useTranslations } from 'hooks'

import { PATHS } from '__constants__'
import PropTypes from 'prop-types'

const ComplaintsAllPage = (props) => {
  const { withHeader = true } = props
  const currScr = useCurrentScreen()
  const isSmallScr = currScr === 'xs' || currScr === 'sm'

  /* Getting translations instance */
  const { t } = useTranslations()
  /* State for controlling list view with localStorage remembering */
  const [listView] = useListView('complaint')

  /* Getting base actions */
  const listActions = useComplaintBaseActions()
  /* Getting complains by query*/
  const [data, loading] = useGetMyComplaintsAndRelatedData()
  const sortProps = useComplaintSort(data)
  const filterProps = useComplaintFilter(sortProps?.sortedComplaints)
  const searchProps = useComplaintSearch(
    filterProps?.filteredComplaints || sortProps?.sortedComplaints
  )

  /* Configuring page header */
  const headingProps = {
    title: t('My Complaints'),
    textAlign: 'left'
  }

  /* Extracting base actions */
  const { onBack } = listActions

  return (
    <PageLayout
      onBack={withHeader ? onBack : null}
      breadcrumbs={
        withHeader ? (
          <Breadcrumbs collection={PATHS.AUTHENTICATED.COMPLAINTS} />
        ) : null
      }
      headingProps={withHeader ? headingProps : null}
      area="complaints">
      {!loading ? (
        !isSmallScr ? (
          <ComplaintList
            span={{ xs: 24 }}
            listView={listView}
            complaints={data}
          />
        ) : (
          <>
            <ComplaintMobileActions
              sortProps={sortProps}
              filterProps={filterProps}
              searchProps={searchProps}
            />
            <List
              emptyProps={{ message: t('No orders are available') }}
              span={{ xs: 24, sm: 24, md: 8, lg: 6 }}
              dataSource={searchProps?.searchedComplaints}
              renderItem={(complaint) => (
                <ComplaintSimpleView
                  isSmallScr={isSmallScr}
                  complaint={complaint}
                />
              )}
            />
          </>
        )
      ) : (
        <Spinner height="70vh" />
      )}
    </PageLayout>
  )
}

ComplaintsAllPage.propTypes = {
  isListWithCreate: PropTypes.bool,
  withHeader: PropTypes.bool
}

export default ComplaintsAllPage
