import { useCallback, useEffect } from 'react'

import { Form } from 'antd'

const useInitializeContactUsAdvancedForm = (initialData) => {
  /* Getting form instance */
  const [form] = Form.useForm()

  const setInitialFieldsValue = useCallback(() => {
    /* Getting initial values */
    const { user } = initialData

    /* Formatting date fields */
    const formValues = {
      contactUs: {
        contactUs: user?.contactUs ?? null
      }
    }
    /* Setting initial values to form instance */
    form.setFieldsValue(formValues)
  }, [form, initialData])

  /* Setting initial data */
  useEffect(() => {
    if (initialData) setInitialFieldsValue()
  }, [initialData, setInitialFieldsValue])

  return { form, setInitialFieldsValue }
}

export default useInitializeContactUsAdvancedForm
