import { cloneElement } from 'react'
import { theme } from 'antd'

const useWrapMenuItems = (menuLinks, selectedKeys, handleClick) => {
  const { colorText, colorPrimaryActive } = theme.useToken().token

  const getItemIcon = (icon, key) => {
    if (!icon) return null

    const isActive = selectedKeys?.includes(key)
    const iconProps = { fill: isActive ? colorPrimaryActive : colorText }

    return cloneElement(icon, iconProps)
  }

  return menuLinks?.map((item) => ({
    ...item,
    icon: getItemIcon(item.icon, item.key),
    children: item?.children?.map((subItem) => ({
      ...subItem,
      icon: getItemIcon(subItem.icon, subItem.key)
    })),
    onClick: item?.onClick || handleClick
  }))
}

export default useWrapMenuItems
