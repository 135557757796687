import {
  ProductDetailsForm,
  ProductSimpleForm
} from 'domains/Product/components'
import {
  ProductLocationFormWithPreview,
  ProductMediaForm
} from 'domains/Product/components/ProductAdvancedForm/components'
import { useCallback, useMemo, useState } from 'react'

import { useTranslations } from 'hooks'
import { useValidateProductForm } from '../../validate'

const useGetProductFormItems = (form, initialValues) => {
  /* Getting translations instance */
  const { t } = useTranslations()

  const [step, setStep] = useState(0)
  const [isStepValid, isFormValid] = useValidateProductForm(form, step)

  const prevStep = useCallback(() => setStep((prev) => prev - 1), [])
  const nextStep = useCallback(() => setStep((prev) => prev + 1), [])

  const steps = useMemo(
    () => [
      {
        key: 'general',
        title: t('General'),
        children: <ProductSimpleForm form={form} group={['product']} />
      },
      {
        key: 'details',
        title: t('Details'),
        children: <ProductDetailsForm form={form} group={['product']} />
      },
      {
        key: '#images-files',
        title: t('Media'),
        children: <ProductMediaForm initialValues={initialValues} form={form} />
      },
      {
        key: '#addresses',
        title: t('Pick-up Location'),
        children: (
          <ProductLocationFormWithPreview
            initialValues={initialValues}
            form={form}
          />
        )
      }
    ],
    [t, form, initialValues]
  )

  const currentStep = useMemo(() => steps[step], [step, steps])

  const isNextStepDisabled = useMemo(
    () => !isStepValid || step >= steps.length - 1,
    [isStepValid, step, steps.length]
  )

  const isPrevStepDisabled = useMemo(() => step <= 0, [step])
  const isFirstStep = useMemo(() => step === 0, [step])
  const isLastStep = useMemo(() => step === steps.length - 1, [step, steps])

  return {
    step,
    steps,
    prevStep,
    nextStep,
    currentStep,
    isNextStepDisabled,
    isPrevStepDisabled,
    isFormValid,
    isFirstStep,
    isLastStep,
    setStep
  }
}

export default useGetProductFormItems
