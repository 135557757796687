import { Spinner } from 'components'
import PropTypes from 'prop-types'

const LoadingBox = (props) => {
  const { loading, spinnerProps, children } = props

  if (loading) return <Spinner {...spinnerProps} />

  return children
}

LoadingBox.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node,
  spinnerProps: PropTypes.object
}
export default LoadingBox
