import { Badge, theme } from 'antd'
import {
  CategoriesStyledButton,
  FooterStyled,
  StyledMenu,
  StyledMenuItem
} from './Footer.styles'

import { APP_PATHS } from '__constants__'
import { Icon } from '@qonsoll/icons'
import { MENU_ITEMS } from '__constants__'
import PropTypes from 'prop-types'
import { useLikedProducts } from 'contexts'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useUser } from 'modules/session-module/contexts'

const { PRODUCT_CREATE } = APP_PATHS

const Footer = (props) => {
  const { handleToggleCategoriesDrawer, isOpenedDrawer } = props

  const token = theme.useToken().token
  const { isRentee } = useUser()
  const navigate = useNavigate()
  const { productsAmount } = useLikedProducts()

  const handleGoToCreateProduct = () => {
    isOpenedDrawer && handleToggleCategoriesDrawer()
    navigate(PRODUCT_CREATE)
  }
  const handleClickMainButton = isRentee
    ? handleToggleCategoriesDrawer
    : handleGoToCreateProduct

  const menuItems = isRentee ? MENU_ITEMS.RENTEE : MENU_ITEMS.RENTER

  const firstGroupOfMenuItems = isRentee
    ? menuItems?.slice(0, 2)
    : menuItems?.slice(0, 3)
  const secondGroupOfMenuItems = isRentee
    ? menuItems?.slice(2, 5)
    : menuItems?.slice(3, 6)
  const computeButtonIcon = isRentee
    ? isOpenedDrawer
      ? 'XCloseOutlined'
      : 'Dashboard2Outlined'
    : 'PlusOutlined'

  const handleNavigate = (path) => {
    isOpenedDrawer && handleToggleCategoriesDrawer()
    navigate(path)
  }

  const customCalculations = useMemo(
    () => ({
      [APP_PATHS.FAVORITE_PRODUCTS]: productsAmount
    }),
    [productsAmount]
  )

  return (
    <FooterStyled theme={token}>
      <StyledMenu>
        {firstGroupOfMenuItems?.map(({ footerLabel, label, iconName, key }) => (
          <StyledMenuItem
            onClick={() => handleNavigate(key)}
            theme={token}
            key={key}
            icon={
              <Badge count={customCalculations?.[key]} size="small">
                <Icon name={iconName} size={24} fill="currentColor" />
              </Badge>
            }>
            {footerLabel || label}
          </StyledMenuItem>
        ))}

        {secondGroupOfMenuItems?.map(
          ({ footerLabel, label, iconName, key }) => (
            <StyledMenuItem
              onClick={() => handleNavigate(key)}
              theme={token}
              key={key}
              icon={<Icon name={iconName} size={24} fill="currentColor" />}>
              {footerLabel || label}
            </StyledMenuItem>
          )
        )}
        <CategoriesStyledButton
          position="right"
          type="text"
          theme={token}
          onClick={handleClickMainButton}>
          <Icon name={computeButtonIcon} size={24} />
        </CategoriesStyledButton>
      </StyledMenu>
    </FooterStyled>
  )
}

Footer.propTypes = {
  handleToggleCategoriesDrawer: PropTypes.func,
  isOpenedDrawer: PropTypes.bool
}
export default Footer
