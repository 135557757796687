import { Typography, Row, Col, theme, Grid } from 'antd'
import { useTranslations } from 'hooks'
import { Icon } from '@qonsoll/icons'
import {
  IconWrapper,
  StyledCard,
  InfoIconStyled
} from './StatisticSimpleView.styled'
import PropTypes from 'prop-types'
import { GrowthPercentageView } from 'domains/Statistic/components'

const { Title, Text } = Typography
const { useBreakpoint } = Grid

/**
 * Renders a simple view for total statistics.
 *
 * @param {Object} props - The component props.
 * @param {string} props.title - The title of the statistic.
 * @param {number} props.value - The value of the statistic.
 * @param {string} props.valueType - The type of the statistic value.
 * @param {string} props.icon - The icon for the statistic.
 * @param {string} props.yesterdayValue - The value from yesterday for calculating growth.
 * @param {string} props.todayValue - The value from today for calculating growth.
 * @param {function} props.setIsModalOpen - The function to set the modal open state.
 * @param {function} props.setStatisticsKey - The function to set the statistics key.
 * @param {string} props.statisticKey - The key for the statistic.
 * @returns {JSX.Element} The rendered component.
 */
const StatisticSimpleView = (props) => {
  const {
    title,
    value,
    valueType,
    icon,
    yesterdayValue,
    todayValue,
    growthTime,
    setIsModalOpen,
    setStatisticsKey,
    statisticKey
  } = props

  const { t } = useTranslations()
  const { md } = useBreakpoint()

  const { colorPrimaryBg, colorPrimaryText } = theme.useToken().token

  const handleOpenInfoModal = () => {
    setStatisticsKey(statisticKey)
    setIsModalOpen(true)
  }

  return (
    <StyledCard className="full-width full-height flex" md={md}>
      <Row gutter={[8, 0]} className="mb-8 align-center">
        <Col>
          <IconWrapper bg={colorPrimaryBg}>
            <Icon name={icon} size={16} fill={colorPrimaryText} />
          </IconWrapper>
        </Col>
        <Col>
          <Text className="medium">{t(title)}</Text>
        </Col>
        <InfoIconStyled
          onClick={handleOpenInfoModal}
          md={md}
          name="InfoCircleOutlined"
          fill={colorPrimaryText}
          size={16}
        />
      </Row>

      <Row className="align-baseline" gutter={[2, 0]}>
        <Col>
          <Title level={4} mr={1}>
            {value}
          </Title>
        </Col>
        <Col>
          <Text type="secondary">{valueType}</Text>
        </Col>
      </Row>

      <Row className="align-center" gutter={[2, 0]}>
        <Col>
          <GrowthPercentageView
            firstValue={yesterdayValue}
            secondValue={todayValue}
          />
        </Col>
        <Col>
          <Text color="secondary-lighten-t-4">
            ({todayValue} {t(growthTime)})
          </Text>
        </Col>
      </Row>
    </StyledCard>
  )
}
StatisticSimpleView.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  valueType: PropTypes.string,
  icon: PropTypes.string,
  growthTime: PropTypes.string,
  yesterdayValue: PropTypes.number,
  todayValue: PropTypes.number,
  setIsModalOpen: PropTypes.func,
  setStatisticsKey: PropTypes.func,
  statisticKey: PropTypes.string
}
export default StatisticSimpleView
