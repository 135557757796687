import { Breadcrumbs, SettingsLayout, Spinner } from 'components'
import { Col, Row } from 'antd'

import PropTypes from 'prop-types'
import { SettingsVerificationList } from 'domains/Verification/components'
import { useAddressBaseActions } from 'domains/Address/hooks'
import { useGetUserAddresses } from 'domains/Address/hooks'
import { useTranslations } from 'hooks'

const SettingsVerificationsAll = (props) => {
  const { isListWithCreate, layoutProps } = props
  /* Getting translations instance */
  const { t } = useTranslations()

  /* Fetching addresses */
  const [addresses, loading] = useGetUserAddresses()
  /* Getting base actions */
  const { onCreate } = useAddressBaseActions()

  const headingProps = {
    title: t('Verification')
  }

  return (
    <SettingsLayout
      breadcrumbs={<Breadcrumbs />}
      headingProps={headingProps}
      area="verificationsAll">
      <Row>
        {loading ? (
          <Col flex="auto">
            <Spinner height="30vh" text={t('Verification loading')} />
          </Col>
        ) : (
          <Col {...layoutProps}>
            <SettingsVerificationList
              actions={{ onCreate }}
              isListWithCreate={isListWithCreate}
              addresses={addresses}
            />
          </Col>
        )}
      </Row>
    </SettingsLayout>
  )
}

SettingsVerificationsAll.propTypes = {
  withHeader: PropTypes.bool,
  isListWithCreate: PropTypes.bool,
  layoutProps: PropTypes.object
}

export default SettingsVerificationsAll
