import { Button, Col, Progress, Row, Tooltip, theme } from 'antd'
import {
  ButtonStyled,
  CardStyled,
  ImageStyled,
  ImageWrapper
} from './ProductUploadItem.styled'
import { useEffect, useMemo, useState } from 'react'

import { Icon } from '@qonsoll/icons'
import PropTypes from 'prop-types'
import { UPLOADING_STATUSES } from '__constants__'
import { getImageSrcBeforeUpload } from 'helpers'
import { getUploadData } from './helpers'
import { useGetUploadItemBaseActions } from './hooks'
import { useTranslations } from 'hooks'

const ProductUploadItem = (props) => {
  const { value, onChange, setUploadedFiles, file } = props

  const { t } = useTranslations()

  const [imageSrc, setImageSrc] = useState(null)
  const [isDeleted, setIsDeleted] = useState(null)

  const {
    colorSuccess,
    colorSuccessActive,
    colorSuccessHover,
    colorBgContainer,
    borderRadius
  } = theme.useToken().token

  useEffect(() => {
    getImageSrcBeforeUpload(file, setImageSrc)
  }, [file])

  const { handleClick, handleDelete } = useGetUploadItemBaseActions({
    onChange,
    value,
    setUploadedFiles,
    file,
    onDeleteCallback: setIsDeleted
  })

  const isCoverImage = useMemo(() => {
    const hasCoverImage = value?.find((item) => item?.isCoverImage)

    return value?.find((item, index) => {
      const computedCondition = hasCoverImage ? item?.isCoverImage : index === 0
      const [computedItem, computedFile] = getUploadData(item, file)
      return computedItem === computedFile && computedCondition
    })
  }, [value, file])

  const computedPercent = useMemo(() => file?.percent || 0, [file?.percent])

  const coverButtonProps = {
    activeColor: colorSuccessActive,
    hoverColor: colorSuccessHover,
    color: isCoverImage ? colorSuccess : colorBgContainer,
    size: 'small',
    type: 'link'
  }

  const deleteButtonProps = { danger: true, size: 'small', type: 'link' }

  const isUploadingData = useMemo(
    () => file?.status === UPLOADING_STATUSES.UPLOADING && file?.percent !== 0,
    [file?.percent, file?.status]
  )

  const actions = (
    <>
      <Tooltip
        tooltipTitle={
          !isCoverImage && `${t('Click to set this photo as the cover')}.`
        }>
        <ButtonStyled
          {...coverButtonProps}
          icon={
            <Icon
              name="CheckCircleBrokenOutlined"
              size={16}
              fill="currentColor"
            />
          }
          disabled={isCoverImage}
          onClick={handleClick}
        />
      </Tooltip>
      <Button
        {...deleteButtonProps}
        icon={<Icon name="Trash2Outlined" size={16} fill="currentColor" />}
        onClick={handleDelete}
      />
    </>
  )

  return (
    <CardStyled isDeleted={isDeleted}>
      <Row align="middle" justify="center" gutter={0}>
        <Col flex="none">
          {isUploadingData ? (
            <Progress
              percent={computedPercent}
              size={86}
              type="circle"
              className="p-8"
            />
          ) : (
            <ImageWrapper borderRadius={borderRadius}>
              <ImageStyled
                preview={{
                  mask: actions
                }}
                draggable={false}
                src={imageSrc}
                borderRadius={borderRadius}
              />
            </ImageWrapper>
          )}
        </Col>
      </Row>
    </CardStyled>
  )
}

ProductUploadItem.propTypes = {
  file: PropTypes.object,
  value: PropTypes.array,
  onChange: PropTypes.func,
  setUploadedFiles: PropTypes.func
}

export default ProductUploadItem
