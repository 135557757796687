import {
  Breadcrumbs,
  PageActions,
  PageLayout,
  PageNav,
  Spinner
} from 'components'

import { List } from 'components'
import { NAV_OPTIONS } from '__constants__'
import { ProductWithReviewsSimpleView } from 'domains/Review/components'
import PropTypes from 'prop-types'
import { useGetProductsAndRelatedDataByQuery } from 'domains/Product/hooks'
import { useMemo } from 'react'
import { useReviewBaseActions } from 'domains/Review/hooks'
import { useTranslations } from 'hooks'
import { useUser } from 'modules/session-module/contexts'

const ReviewsAllPage = (props) => {
  const { withHeader = true } = props
  const { user } = useUser()
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Query for fetching products */
  const query = useMemo(
    () =>
      user?._id && {
        isDraftsPage: false,
        whereClauses: [['_createdBy', '==', user?._id]]
      },
    [user?._id]
  )

  /* Fetching products and irs related data */
  const [products, loading] = useGetProductsAndRelatedDataByQuery(query)
  /* Getting base actions */
  const listActions = useReviewBaseActions()

  /* Configuring page header */
  const headingProps = {
    title: t('Reviews'),
    textAlign: 'left'
  }

  /* Configuring page actions */
  const actions = {
    filter: true,
    sort: true,
    listView: true
  }
  /* Extracting base actions */
  const { onBack } = listActions

  return (
    <PageLayout
      actions={
        withHeader ? (
          <PageActions actions={actions} createText={t('Create review')} />
        ) : null
      }
      onBack={withHeader ? onBack : null}
      breadcrumbs={withHeader ? <Breadcrumbs /> : null}
      headingProps={withHeader ? headingProps : null}
      area="reviews">
      {!loading ? (
        <>
          <PageNav options={NAV_OPTIONS.COMPLAINTS} />
          <List
            span={{ xs: 24, sm: 24, md: 12, lg: 12, xl: 8, xxl: 8 }}
            dataSource={products}
            renderItem={(product) => (
              <ProductWithReviewsSimpleView product={product} />
            )}
          />
        </>
      ) : (
        <Spinner height="70vh" />
      )}
    </PageLayout>
  )
}

ReviewsAllPage.propTypes = {
  isListWithCreate: PropTypes.bool,
  withHeader: PropTypes.bool
}

export default ReviewsAllPage
