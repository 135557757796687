import { Box } from 'components'
import PropTypes from 'prop-types'
import { StyledSpinner } from './Spinner.styled'
import { Typography } from 'antd'

const Spinner = (props) => {
  const { text, className, ...rest } = props

  return (
    <Box
      className={`flex flex-1 justify-center align-center ${className}`}
      {...rest}>
      <Box className="flex gap-8">
        <StyledSpinner />
        {text && <Typography.Text>{text}</Typography.Text>}
      </Box>
    </Box>
  )
}

Spinner.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string
}

export default Spinner
