import {
  CategoryButton,
  ProductFilter,
  ProductList
} from 'domains/Product/components'
import { Col, Empty, Pagination, Row, Typography } from 'antd'
import { generatePath, useNavigate } from 'react-router-dom'
import { memo, useCallback } from 'react'
import {
  useFilterCategories,
  useGetSubCategories
} from 'domains/Category/hooks'
import { useLoading, useScreen, useTranslations } from 'hooks'

import { LoadingBox } from 'components'
import { PATHS } from '__constants__'
import PropTypes from 'prop-types'
import { StyledSpace } from './ProductsByCategoryAdvancedView.styled'
import { useFilterContext } from 'contexts'
import { useHorizontalScroll } from 'hooks'

const ProductsByCategoryAdvancedView = (props) => {
  const { category } = props

  const { t } = useTranslations()
  const { isSmallScreen, isExtraSmallScreen } = useScreen()
  const navigate = useNavigate()

  const {
    loading: searchLoading,
    setCurrentPage,
    currentPage,
    totalResults,
    products
  } = useFilterContext()
  /* Fetching products and irs related data */
  const [scrollRef, handleMouseEnter, handleMouseLeave] = useHorizontalScroll()

  const [subcategories, subcategoriesLoading] = useGetSubCategories(
    category?._id
  )
  const [filteredSubCategories, filteredSubCategoriesLoading] =
    useFilterCategories(subcategories)

  const handleOpenSubcategory = useCallback(
    (categoryId) => () => {
      const path = generatePath(PATHS.AUTHENTICATED.PRODUCTS_ALL_BY_CATEGORY, {
        categoryId
      })

      navigate(path)
    },
    [navigate]
  )

  // actions for product rating
  const loading = useLoading([
    searchLoading,
    subcategoriesLoading,
    filteredSubCategoriesLoading
  ])
  const heightToNegotiate = category?.isTopLevel
    ? isExtraSmallScreen
      ? 'calc(100vh - 244px)'
      : 'calc(100vh - 200px)'
    : isExtraSmallScreen
    ? 'calc(100vh - 188px)'
    : 'calc(100vh - 142px)'

  return (
    <Row gutter={24} className="flex-1 flex-nowrap">
      <Col flex="auto">
        <LoadingBox spinnerProps={{ height: '100%' }} loading={loading}>
          <StyledSpace
            ref={scrollRef}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className="full-width max100 x-scroll gap-8 mb-24">
            {filteredSubCategories?.map((subcategory) => (
              <CategoryButton
                category={subcategory}
                key={`${subcategory?._id}-button`}
                handleOpen={handleOpenSubcategory}
              />
            ))}
          </StyledSpace>
          <>
            {products?.length ? (
              <ProductList
                maxHeight={heightToNegotiate}
                products={products}
                span={{ xs: 24, sm: 24, md: 12, lg: 12, xl: 8, xxl: 6 }}
                pagination={
                  <Pagination
                    hideOnSinglePage={true}
                    style={{ justifyContent: 'center', display: 'flex' }}
                    defaultCurrent={1}
                    total={totalResults}
                    pageSize={8}
                    current={currentPage}
                    onChange={(page) => {
                      setCurrentPage(page)
                    }}
                  />
                }
              />
            ) : (
              <div className="flex justify-center align-center flex-1">
                <Empty
                  description={
                    <Typography.Text type="secondary">
                      {t("There aren't products for current category")}
                    </Typography.Text>
                  }
                />
              </div>
            )}
          </>
        </LoadingBox>
      </Col>
      {!isSmallScreen && !category?.isTopLevel && (
        <Col
          style={{
            minWidth: '268px',
            maxWidth: '268px'
          }}>
          <ProductFilter filterVisibility category={category} />
        </Col>
      )}
    </Row>
  )
}

ProductsByCategoryAdvancedView.propTypes = {
  category: PropTypes.object
}

export default memo(ProductsByCategoryAdvancedView)
