import RenteeActions from './RenteeActions'
import RenterActions from './RenterActions'

const useGetProductCustomActions = (props) => {
  const actions = props?.isRentee ? (
    <RenteeActions {...props} />
  ) : (
    <RenterActions {...props} />
  )

  return actions
}

export default useGetProductCustomActions
