import { useCallback, useMemo } from 'react'

import { useTranslations } from 'hooks'

const useGetMenuLinks = (menuItems, setOpenedSubMenu) => {
  const { t } = useTranslations()

  const handleTitleClick = useCallback(
    ({ key }) => setOpenedSubMenu((prev) => (prev === key ? null : key)),
    [setOpenedSubMenu]
  )

  return useMemo(
    () =>
      menuItems?.map((item) => ({
        ...(item ? item : {}),
        label: item.label ? t(item.label) : 'n/a',
        children: item?.submenu,
        onTitleClick: item?.submenu ? handleTitleClick : null
      })),
    [t, menuItems, handleTitleClick]
  )
}

export default useGetMenuLinks
