import { CardStyled, IconWrapper, TextStyled } from './AddressSimpleView.styles'
import { Col, Row, Typography } from 'antd'

import { ADDRESS_ICONS } from 'domains/Address/__constants__'
import { ButtonDropdown } from 'components'
import { Icon } from '@qonsoll/icons'
import PropTypes from 'prop-types'
import { formatAddress } from 'helpers'
import { useAddressBaseActions } from 'domains/Address/hooks'
import { useMemo } from 'react'
import { useTranslations } from 'hooks'

const AddressSimpleView = (props) => {
  const { address, onSelect, index, actions, onlyView } = props
  const { t } = useTranslations()
  /* Getting base actions */
  const { onOpen, onDelete, onEdit } = useAddressBaseActions({
    addressId: address?._id,
    userId: address?.userId,
    actions
  })

  /* Handling selection of the item */
  const handleSelect = (e) => {
    e.stopPropagation()
    onSelect?.(address)
  }

  const formattedAddress = formatAddress(address)

  const iconName = ADDRESS_ICONS?.[address?.icon] || ADDRESS_ICONS.PIN

  const actionsProps = useMemo(() => ({ onDelete, onEdit }), [onDelete, onEdit])

  const handleClick = (e) => {
    handleSelect(e)
    formattedAddress && onOpen()
  }
  return !onlyView ? (
    <CardStyled
      className="cursor-pointer"
      bordered={false}
      padding={'12px 16px'}
      onClick={handleClick}
      index={index}
      data-testid="addressListItem">
      <Row align="middle" gutter={[16, 0]} className="mx-0" wrap={false}>
        <Col flex="none">
          <div className="p-12">
            <Icon name={iconName} size={24} id="location-icon" />
          </div>
        </Col>
        <Col flex="auto">
          <div className="mb-4">
            <TextStyled fontWeight={700}>{address?.name || 'n/a'}</TextStyled>
          </div>
          <Typography.Text type="secondary" ellipsis>
            {formattedAddress || 'n/a'}
          </Typography.Text>
        </Col>
        <Col flex="none">
          <ButtonDropdown {...actionsProps} />
        </Col>
      </Row>
    </CardStyled>
  ) : (
    <div className="flex align-center full-width">
      <IconWrapper className="flex align-center justify-center mr-16">
        <Icon name="MarkerPin6Outlined" size={20} />
      </IconWrapper>
      <div className="flex flex-col">
        <Typography.Text strong>{t('Location')}</Typography.Text>
        <Typography.Text>{formattedAddress || 'n/a'}</Typography.Text>
      </div>
    </div>
  )
}

AddressSimpleView.propTypes = {
  address: PropTypes.object,
  onSelect: PropTypes.func,
  index: PropTypes.number,
  isSelected: PropTypes.bool,
  onlyView: PropTypes.bool,
  actions: PropTypes.object
}

export default AddressSimpleView
