import { Breadcrumbs, PageLayout, Spinner, FilterButton } from 'components'

import { COLLECTIONS } from '__constants__'
import { FilterProvider } from 'contexts'
import { ProductsByCategoryAdvancedView } from 'domains/Product/components'
import { useDocument } from 'services/api/firebase'
import { useParams } from 'react-router-dom'
import { useTranslations, useScreen } from 'hooks'

const ProductsAllByCategory = () => {
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting params from router */
  const params = useParams()

  /* Fetching document data from useDocument hook */
  const [category, loading] = useDocument({
    ref: COLLECTIONS.CATEGORIES + '/' + params.categoryId
  })
  const { isSmallScreen } = useScreen()

  const showFilter =
    isSmallScreen && !category?.subcategories?.length && !loading

  return loading ? (
    <Spinner height="70vh" text={t('Category loading')} />
  ) : (
    <FilterProvider category={category}>
      <PageLayout
        breadcrumbs={<Breadcrumbs categoryId={category?._id} />}
        filter={
          showFilter && <FilterButton filterVisibility category={category} />
        }
        area="category">
        <ProductsByCategoryAdvancedView category={category} />
      </PageLayout>
    </FilterProvider>
  )
}

export default ProductsAllByCategory
