import { Upload, theme } from 'antd'

import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useMemo } from 'react'

const { Dragger } = Upload

const DraggerStyled = styled(Dragger)`
  max-height: 30vh !important;
  height: 100%;
  overflow-y: scroll;

  .ant-upload-drag {
    padding: 48px 16px;
    path {
      stroke: ${({ color }) => color};
      transition: 0.1s linear;
    }

    * {
      color: ${({ color }) => color} !important;
      transition: 0.1s linear;
    }

    &:hover {
      background-color: ${({ backgroundColor }) => backgroundColor};
      path {
        stroke: ${({ hoverColor }) => hoverColor};
        transition: 0.1s linear;
      }

      * {
        color: ${({ hoverColor }) => hoverColor} !important;
        transition: 0.1s linear;
      }
    }
  }

  .ant-upload-list {
    overflow-x: scroll;
    display: flex;

    margin-top: ${({ uploadList }) => uploadList.marginTop};
    /* Offset to scroll */
    padding-bottom: 8px;

    .ant-upload-list-item-container:not(:first-child):not(:last-child) {
      ${({ uploadList }) =>
        `padding-left: ${uploadList.gutters};
      padding-right: ${uploadList.gutters};`};
    }

    .ant-upload-list-item-container:last-child {
      padding-right: 0px;
      padding-left: ${({ uploadList }) => uploadList.gutters};
    }
    .ant-upload-list-item-container:first-child {
      padding-right: ${({ uploadList }) => uploadList.gutters};
      padding-left: 0px;
    }
  }
`

const DraggerMediaStyled = (props) => {
  const { children, ...rest } = props
  const { colorPrimaryBg, colorPrimary, colorTextTertiary } =
    theme.useToken().token

  const uploadProps = useMemo(
    () => ({
      uploadList: rest?.fileList?.length
        ? { marginTop: '24px', gutters: '8px' }
        : {}
    }),
    [rest?.fileList?.length]
  )

  return (
    <DraggerStyled
      backgroundColor={colorPrimaryBg}
      color={colorTextTertiary}
      hoverColor={colorPrimary}
      {...uploadProps}
      {...rest}>
      {children}
    </DraggerStyled>
  )
}

DraggerMediaStyled.propTypes = {
  children: PropTypes.node,
  rest: PropTypes.object
}

export default DraggerMediaStyled
