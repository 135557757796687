import { Row } from 'antd'
import { ParentCategorySimpleView } from 'domains/Category/components/CategoriesDrawer/components'
import PropTypes from 'prop-types'

const ParentCategoriesList = (props) => {
  const { categories, handleSelectCategory } = props

  return (
    <Row>
      {categories?.map((category, index) => (
        <ParentCategorySimpleView
          key={`${category?._id}-parent`}
          handleSelectCategory={handleSelectCategory}
          category={category}
          categories={categories}
          index={index}
        />
      ))}
    </Row>
  )
}

ParentCategoriesList.propTypes = {
  categories: PropTypes.array,
  handleSelectCategory: PropTypes.func
}
export default ParentCategoriesList
