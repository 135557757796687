import { ProductSimpleView, ProductTable } from 'domains/Product/components'

import PropTypes from 'prop-types'
import { StyledList } from './ProductList.styles'
import { Typography } from 'antd'
import { useTranslations } from 'hooks'
import { useUser } from 'modules/session-module/contexts'

const ProductList = (props) => {
  const {
    products,
    emptyProps,
    actions,
    onSelect,
    selected,
    onDraftsToPublishSelect,
    selectedDrafts,
    isDraftsPage,
    isBlockedPage,
    listView,
    span,
    pagination,
    maxHeight,
    total,
    pageSize,
    handlePageChange,
    currentPage,
    onlyView,
    ...rest
  } = props

  const { isRentee } = useUser()
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting emptyProps from empty object */
  const emptyParams = {
    ...emptyProps,
    message: t(
      isBlockedPage
        ? 'No products have been blocked yet'
        : isDraftsPage
        ? 'No draft products have been created yet'
        : 'No products have been created yet'
    )
  }

  if (!products?.length) {
    return (
      <Typography.Text type="secondary">{emptyParams?.message}</Typography.Text>
    )
  }

  return products?.length ? (
    !listView ? (
      <StyledList
        wrapperStyles={{
          maxHeight: `${maxHeight || 'calc(100vh - 200px)'}`,
          overflowY: 'visible'
        }}
        span={span}
        {...rest}
        actions={actions}
        dataSource={products}
        emptyProps={emptyParams}
        renderItem={(product, index) => (
          <ProductSimpleView
            onlyView={onlyView}
            isRentee={isRentee}
            onDraftsToPublishSelect={onDraftsToPublishSelect}
            selectedDrafts={selectedDrafts}
            product={product}
            area-label={`product-${index + 1}`}
            actions={actions}
            index={index}
            key={product?._id}
          />
        )}
        pagination={pagination}
      />
    ) : (
      <ProductTable
        isDraftsPage={isDraftsPage}
        products={products}
        onDraftsToPublishSelect={onDraftsToPublishSelect}
        selectedDrafts={selectedDrafts}
        isRentee={isRentee}
        total={total}
        pageSize={pageSize}
        handlePageChange={handlePageChange}
        currentPage={currentPage}
      />
    )
  ) : (
    <Typography.Text type="secondary">{emptyParams?.message}</Typography.Text>
  )
}

ProductList.propTypes = {
  products: PropTypes.array,
  emptyProps: PropTypes.object,
  actions: PropTypes.any,
  onSelect: PropTypes.func,
  selected: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  onDraftsToPublishSelect: PropTypes.func,
  selectedDrafts: PropTypes.array,
  isDraftsPage: PropTypes.bool,
  isBlockedPage: PropTypes.bool,
  listView: PropTypes.bool,
  onlyView: PropTypes.bool,
  span: PropTypes.object,
  pagination: PropTypes.object,
  maxHeight: PropTypes.string,
  total: PropTypes.number,
  pageSize: PropTypes.number,
  handlePageChange: PropTypes.func,
  currentPage: PropTypes.number
}

export default ProductList
