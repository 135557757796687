import { COLLECTIONS } from '__constants__'
import { doc } from 'firebase/firestore'
import { firestore } from 'services/firebase'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { useMemo } from 'react'

const useGetOrder = (orderId) => {
  const querySnapshot = useMemo(
    () => (orderId ? doc(firestore, COLLECTIONS.ORDERS, orderId) : null),
    [orderId]
  )

  return useDocumentData(querySnapshot)
}
export default useGetOrder
