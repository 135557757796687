import { APP_PATHS } from '../paths'

const {
  DASHBOARD,
  MY_PRODUCTS_ALL,
  CUSTOMERS_ALL,
  TICKETS_ALL,
  COMPLAINTS_ALL,
  FAVORITE_PRODUCTS,
  ORDERS_ALL,
  CHATS
} = APP_PATHS

const MENU_ITEMS = {
  RENTER: [
    {
      key: DASHBOARD,
      label: 'Overview',
      iconName: 'Dashboard2Outlined'
    },
    {
      key: MY_PRODUCTS_ALL,
      label: 'My Products',
      iconName: 'BoxOutlined'
    },
    {
      key: ORDERS_ALL,
      label: 'Orders',
      iconName: 'FileCheck2Outlined'
    },
    {
      key: CUSTOMERS_ALL,
      label: 'Customers',
      iconName: 'Users1Outlined'
    },
    {
      key: TICKETS_ALL,
      label: 'Tickets',
      iconName: 'Star1Outlined'
    },
    {
      key: COMPLAINTS_ALL,
      label: 'Complaints',
      iconName: 'Lightning1Outlined'
    },
    {
      key: CHATS,
      label: 'Chats',
      iconName: 'MessageChatSquareOutlined'
    }
  ],
  RENTEE: [
    {
      key: DASHBOARD,
      label: 'Near by me',
      footerLabel: 'Near by',
      iconName: 'Target5Outlined'
    },
    {
      key: FAVORITE_PRODUCTS,
      label: 'Favorites',
      iconName: 'HeartOutlined'
    },
    {
      key: ORDERS_ALL,
      label: 'Orders',
      iconName: 'ShoppingBag3Outlined'
    },
    {
      key: TICKETS_ALL,
      label: 'Tickets',
      iconName: 'Star1Outlined'
    },
    {
      key: COMPLAINTS_ALL,
      label: 'Complaints',
      iconName: 'Lightning1Outlined'
    },
    {
      key: CHATS,
      label: 'Chats',
      iconName: 'MessageChatSquareOutlined'
    }
  ]
}

export { MENU_ITEMS }
export default MENU_ITEMS
