import {
  SettingsAddressCreate,
  SettingsAddressEdit,
  SettingsAddressesAll,
  SettingsChangePassword,
  SettingsContactUs,
  SettingsNotificationsConfig,
  SettingsProfileEdit,
  SettingsVerificationsAll
} from 'pages/App'
import { SETTINGS_PATHS } from '__constants__'
import { SettingsStripeAccount } from 'pages/App/Stripe'

const {
  MY_PROFILE,
  CHANGE_PASSWORD,
  NOTIFICATIONS,
  CONTACT_US,
  ADDRESSES_ALL,
  ADDRESS_EDIT,
  ADDRESS_CREATE,
  STRIPE_ACCOUNT,
  VERIFICATION
} = SETTINGS_PATHS

const layoutProps = { xs: 24 }
const formLayoutProps = { ...layoutProps, xl: 12, xxl: 8 }

export const SETTINGS_ROUTES = [
  {
    key: MY_PROFILE,
    path: MY_PROFILE,
    element: <SettingsProfileEdit layoutProps={formLayoutProps} />
  },
  {
    key: CHANGE_PASSWORD,
    path: CHANGE_PASSWORD,
    element: <SettingsChangePassword layoutProps={formLayoutProps} />
  },
  {
    key: ADDRESS_EDIT,
    path: ADDRESS_EDIT,
    element: <SettingsAddressEdit layoutProps={layoutProps} />
  },
  {
    key: ADDRESS_CREATE,
    path: ADDRESS_CREATE,
    element: <SettingsAddressCreate layoutProps={layoutProps} />
  },
  {
    key: ADDRESSES_ALL,
    path: ADDRESSES_ALL,
    element: <SettingsAddressesAll layoutProps={layoutProps} isListWithCreate />
  },
  {
    key: NOTIFICATIONS,
    path: NOTIFICATIONS,
    element: <SettingsNotificationsConfig layoutProps={layoutProps} />
  },
  {
    key: STRIPE_ACCOUNT,
    path: STRIPE_ACCOUNT,
    element: <SettingsStripeAccount layoutProps={layoutProps} />
  },
  {
    key: CONTACT_US,
    path: CONTACT_US,
    element: <SettingsContactUs layoutProps={layoutProps} />
  },
  {
    key: VERIFICATION,
    path: VERIFICATION,
    element: <SettingsVerificationsAll layoutProps={layoutProps} />
  }
]

export default SETTINGS_ROUTES
