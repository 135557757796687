import { Col, Row, Skeleton, Typography } from 'antd'

import PropTypes from 'prop-types'
import { StatisticProductSimpleView } from 'domains/Statistic/components'
import { useGetPopularRentedProducts } from 'domains/Statistic/hooks/get'
import { useTranslations } from 'hooks'

const StatisticProductList = (props) => {
  const { gutter, span } = props

  const [popularProducts, loading] = useGetPopularRentedProducts(3)
  const { t } = useTranslations()

  return loading ? (
    <Skeleton />
  ) : popularProducts?.length ? (
    <Row gutter={gutter || 16}>
      {popularProducts.map((item, index) => (
        <Col
          key={`${index}-statistic-product-simple-view`}
          span={span || 24}
          className="flex justify-center align-center mb-16">
          <StatisticProductSimpleView {...item} />
        </Col>
      ))}
    </Row>
  ) : (
    <Typography.Text type="secondary">
      {t('No popular products are available for suggestion')}
    </Typography.Text>
  )
}

StatisticProductList.propTypes = {
  span: PropTypes.number,
  gutter: PropTypes.number
}

export default StatisticProductList
