import { useScreen, useTranslations } from 'hooks'

import { Button, Tooltip } from 'antd'
import { ComplaintAdvancedForm } from 'domains/Complaint/components'
import { Icon } from '@qonsoll/icons'
import PropTypes from 'prop-types'
import { StyledModal } from './ComplaintModal.styled'
import { useActionsComplaintAdvancedForm } from 'domains/Complaint/components/ComplaintAdvancedForm/hooks'
import { useInitializeComplaintAdvancedForm } from 'domains/Complaint/hooks'
import { useState } from 'react'

const ComplaintModal = (props) => {
  const { onlyIcon = true, productId, orderId, className } = props

  const [isComplainsModalOpen, setIsComplainsModalOpen] = useState(false)
  const { t } = useTranslations()

  const { isSmallScreen } = useScreen()

  const complaintButtonIcon = <Icon name="AlertTriangleOutlined" size={14} />
  const eStopPropagation = (e) => e.stopPropagation()

  const handleModalOpen = () => setIsComplainsModalOpen(true)
  const handleOk = async () => setIsComplainsModalOpen(false)
  const handleCancel = () => setIsComplainsModalOpen(false)

  const { form } = useInitializeComplaintAdvancedForm()
  const { onFinish } = useActionsComplaintAdvancedForm({
    form,
    productId,
    orderId,
    onFinishCallback: handleOk,
    onResetCallback: handleCancel
  })

  return (
    <div onClick={eStopPropagation} className={className}>
      <Tooltip title={t('Create complaint')}>
        <Button
          onClick={handleModalOpen}
          className="flex align-center justify-center"
          icon={complaintButtonIcon}>
          {!onlyIcon && t('Complaint')}
        </Button>
      </Tooltip>
      <StyledModal
        centered
        open={isComplainsModalOpen}
        footer={null}
        title={t('Complaint about this product/lessor')}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={t('Complaint')}
        forceRender
        width={isSmallScreen ? '90%' : '600px'}
        bodyStyle={{
          padding: '0px !important',
          height: isSmallScreen ? 'calc(100vh - 260px)' : '400px'
        }}>
        <ComplaintAdvancedForm
          onSubmit={onFinish}
          form={form}
          onCancel={handleCancel}
        />
      </StyledModal>
    </div>
  )
}

ComplaintModal.propTypes = {
  onlyIcon: PropTypes.bool,
  orderId: PropTypes.string,
  productId: PropTypes.string,
  className: PropTypes.string
}

export default ComplaintModal
