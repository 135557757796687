import { useEffect, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { ComplaintSideDrawer } from 'domains/Complaint/components'
import PropTypes from 'prop-types'
import { Table } from 'antd'
import { useDrawerActions } from './hooks'
import { useGetComplaintsTableConfig } from 'domains/Complaint/components/ComplaintList/hooks'

const ComplaintList = (props) => {
  const { complaints, complaintId } = props
  const navigate = useNavigate()
  const location = useLocation()
  const { columns, paginationProps, tableProps } =
    useGetComplaintsTableConfig(complaints)

  /* Getting emptyProps from empty object */

  const {
    complaintDrawerProps,
    onComplaintSideDrawerOpen,
    onComplaintSideDrawerClose
  } = useDrawerActions()

  const complaintSideDrawerOpen = useMemo(
    () => complaintDrawerProps?.visibility && '0',
    [complaintDrawerProps?.visibility]
  )

  const complaintToOpen = useMemo(() => {
    if (!complaints || complaints.length === 0) {
      return null
    }
    const foundOrder = complaints.find(
      (complaint) => complaint?._id === complaintId
    )

    return foundOrder
  }, [complaints, complaintId])
  //
  const cleanLocaleOnDrawerClose = () => {
    onComplaintSideDrawerClose()
    navigate(location.pathname, {
      state: { ...location.state, orderId: null },
      replace: true
    })
  }

  // Effect to open the drawer for the specific order and role from notifications
  useEffect(() => {
    if (complaintToOpen) {
      onComplaintSideDrawerOpen(complaintToOpen)
    }
  }, [onComplaintSideDrawerOpen, complaintToOpen])

  return (
    <>
      <Table
        rowClassName="cursor-pointer"
        onRow={(record) => ({
          onClick: (e) => {
            e.preventDefault()
            e.stopPropagation()
            onComplaintSideDrawerOpen(record)
          }
        })}
        dataSource={complaints}
        columns={columns}
        pagination={paginationProps}
        {...tableProps}
      />
      <ComplaintSideDrawer
        complaintDrawerProps={complaintDrawerProps}
        rightShiftOpen={complaintSideDrawerOpen}
        onComplaintSideDrawerClose={cleanLocaleOnDrawerClose}
      />
    </>
  )
}

ComplaintList.propTypes = {
  complaints: PropTypes.array,
  emptyProps: PropTypes.object,
  actions: PropTypes.any,
  rest: PropTypes.any,
  complaintId: PropTypes.string
}

export default ComplaintList
