// NOTE: do not use seed tokens in code instead use map tokens
const seedTokens = {
  borderRadius: 7,
  colorTextBase: '#0B0A0E',
  fontFamily: 'Inter',
  colorPrimary: '#1c74e0',
  fontFamilyCode: 'monospace'
}
const mapToken = {
  colorTextSecondary: 'rgba(11, 10, 14, 0.65)',
  colorWrapperBg: '#00000085',
  colorOverlayBg: '#00000069',
  renteeTableYScroll: 'calc(100vh - 340px)',
  renterTableYScroll: 'calc(100vh - 275px)'
}
const theme = {
  token: {
    ...seedTokens,
    ...mapToken
  }
}

export default theme
export { seedTokens, theme }
