import { Menu, Badge } from 'antd'
import styled from 'styled-components'

const StyledMenu = styled(Menu)`
  &.ant-menu.ant-menu-root.ant-menu-inline {
    border-inline-end: unset !important;
  }
  &.ant-menu .ant-menu-item {
    margin-top: 0;
    margin-bottom: 0;
  }
`

const StyledBadge = styled(Badge)`
  .ant-badge-count {
    top: -2px;
    right: -10px;
  }
`

export { StyledMenu, StyledBadge }
