import { Select, theme } from 'antd'

import { Icon } from '@qonsoll/icons'
import PropTypes from 'prop-types'
import SelectWrapper from './LanguageSelect.styled'
import { useMemo } from 'react'
import { useTranslations } from 'hooks'

const LanguageSelect = (props) => {
  const { isIconExist } = props
  const { setCurrentLanguage, language, languages, loading } = useTranslations()

  const handleChange = ({ value }) => {
    setCurrentLanguage(value)
    // moment.locale(shortCodeLanguage === 'no' ? 'nb' : shortCodeLanguage)
  }

  const options = useMemo(
    () =>
      languages
        .filter(({ value }) => value !== language)
        .map(({ name, value, label }) => ({ label, name, value })),
    [language, languages]
  )
  const defaultLanguage = useMemo(
    () => languages.find(({ value }) => value === language),
    [language, languages]
  )

  const {
    token: { colorTextBase }
  } = theme.useToken()

  return (
    <SelectWrapper isIconExist={isIconExist}>
      {isIconExist && (
        <div className="prefix-icon-wrapper">
          <Icon name="LanguageOutlined" size="16" fill={colorTextBase} />
        </div>
      )}
      <Select
        labelInValue
        onSelect={handleChange}
        defaultValue={defaultLanguage}
        options={options}
        loading={loading}
      />
    </SelectWrapper>
  )
}

LanguageSelect.propTypes = {
  isIconExist: PropTypes.bool
}

export default LanguageSelect
