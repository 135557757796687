import PropTypes from 'prop-types'
import { theme } from 'antd'

export const LogoWrapper = (props) => {
  const { children, collapsed, ...rest } = props

  const {
    token: { colorBgContainer }
  } = theme.useToken()

  return (
    <div
      {...rest}
      style={{
        backgroundColor: colorBgContainer,
        position: 'sticky',
        top: 0,
        zIndex: 2,
        width: 120,
        height: 28,
        marginLeft: collapsed ? 17 : 20,
        marginTop: 24
      }}>
      {children}
    </div>
  )
}

LogoWrapper.propTypes = {
  children: PropTypes.node,
  collapsed: PropTypes.bool
}
