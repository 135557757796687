import { Breadcrumbs, SettingsLayout, Spinner } from 'components'
import { Col, Row } from 'antd'

import PropTypes from 'prop-types'
import { SettingsNotificationList } from 'domains/Notification/components'
import { useTranslations } from 'hooks'
import { useUser } from 'modules/session-module/contexts'

const SettingsNotificationsConfigPage = (props) => {
  const { layoutProps } = props
  /* Getting translations instance */
  const { t } = useTranslations()

  const { user, loading } = useUser()

  const headingProps = {
    title: t('Notifications')
  }

  return (
    <SettingsLayout
      breadcrumbs={<Breadcrumbs />}
      headingProps={headingProps}
      area="notification-settings">
      <Row>
        {loading ? (
          <Col span={24} sm={22}>
            <Spinner height="70vh" text={t('Notification settings loading')} />
          </Col>
        ) : (
          <Col {...layoutProps}>
            <SettingsNotificationList initialValues={user} />
          </Col>
        )}
      </Row>
    </SettingsLayout>
  )
}

SettingsNotificationsConfigPage.propTypes = {
  isListWithCreate: PropTypes.bool,
  withoutHeader: PropTypes.bool,
  layoutProps: PropTypes.object
}

export default SettingsNotificationsConfigPage
