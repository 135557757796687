import { Breadcrumbs, PageLayout, Spinner } from 'components'
import { Col, Row } from 'antd'
import { collection, query, where } from 'firebase/firestore'
import { useListView, useTranslations } from 'hooks'

import { COLLECTIONS } from '__constants__'
import { CategoryList } from 'domains/Category/components'
import PropTypes from 'prop-types'
import { firestore } from 'services/firebase'
import { useCollectionData } from 'react-firebase-hooks/firestore'

const CategoriesAllPage = (props) => {
  const { isListWithCreate, withHeader = true } = props

  /* Getting translations instance */
  const { t } = useTranslations()
  /* State for controlling list view with localStorage remembering */
  const [listView] = useListView('category')
  /* Fetching categories */
  const [categories, loading] = useCollectionData(
    query(
      collection(firestore, COLLECTIONS.CATEGORIES),
      where('isTopLevel', '==', true)
    )
  )
  /* Configuring page header */
  const headingProps = {
    title: t('Categories'),
    textAlign: 'left'
  }

  return (
    <PageLayout
      breadcrumbs={withHeader ? <Breadcrumbs /> : null}
      headingProps={withHeader ? headingProps : null}
      area="categories">
      <Row gutter={[24, 16]}>
        {loading ? (
          <Col flex="auto">
            <Spinner height="70vh" text={t('Category loading')} />
          </Col>
        ) : (
          <Col flex="auto">
            <CategoryList
              span={{ xs: 24, sm: 24, md: 8, lg: 8, xl: 6, xxl: 4 }}
              listView={listView}
              isListWithCreate={isListWithCreate}
              categories={categories}
            />
          </Col>
        )}
      </Row>
    </PageLayout>
  )
}

CategoriesAllPage.propTypes = {
  isListWithCreate: PropTypes.bool,
  withHeader: PropTypes.bool
}

export default CategoriesAllPage
