import { Button, Checkbox, Space, Typography, theme } from 'antd'
import { PATHS, PRODUCT_STATUSES, PRODUCT_STATUSES_LABELS } from '__constants__'
import { RateSelect, RelativeImage } from 'components'
import { generatePath, useNavigate } from 'react-router-dom'
import { useCallback, useMemo } from 'react'

import { Icon } from '@qonsoll/icons'
import { ProductBrand } from 'domains/Product/components/ProductSimpleView/components'
import { TitleStyled } from 'domains/Product/components/ProductSimpleView/ProductSimpleView.styles'
import { getTransformedImageUrl } from 'helpers'
import { useTranslations } from 'hooks'

const showRenteePath = PATHS.AUTHENTICATED.PRODUCT_SHOW
const showRenterPath = PATHS.AUTHENTICATED.MY_PRODUCT_SHOW
const editPath = PATHS.AUTHENTICATED.PRODUCT_EDIT

const useGetProductsTableConfig = (props) => {
  const {
    onDraftsToPublishSelect,
    selectedDrafts,
    withCheckboxes,
    isRentee = false,
    total,
    pageSize,
    handlePageChange,
    currentPage,
    setModalVisible
  } = props
  /* Getting navigate instance */
  const navigate = useNavigate()
  const { t } = useTranslations()
  const { colorErrorText } = theme.useToken().token

  // product actions
  const onOpen = useCallback(
    (productId) => () => {
      const pathParams = { productId }
      const pathComputed = isRentee ? showRenteePath : showRenterPath
      const path = generatePath(pathComputed, pathParams)
      navigate(path)
    },
    [isRentee, navigate]
  )
  const onEdit = useCallback(
    (productId) => () => {
      const pathParams = { productId }
      const path = generatePath(editPath, pathParams)
      navigate(path)
    },
    [navigate]
  )

  const onDelete = useCallback(() => {
    setModalVisible(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const columns = useMemo(
    () => [
      {
        title: t('Product name & brand'),
        dataIndex: 'name',
        key: 'name',
        width: '500px',
        render: (_, record) => {
          const { previewImage } = record
          const checked = selectedDrafts?.includes(record?._id)

          const computedImgUrl = getTransformedImageUrl(previewImage)

          return (
            <Space size={16}>
              {withCheckboxes && (
                <Checkbox
                  checked={checked}
                  onChange={onDraftsToPublishSelect(record?._id)}
                />
              )}
              <div className="relative" style={{ width: '80px' }}>
                <RelativeImage src={computedImgUrl} withOverlay={false} />
              </div>
              <div>
                <TitleStyled level={5}>{record?.name}</TitleStyled>
                <ProductBrand brandId={record?.brandId} />
              </div>
            </Space>
          )
        }
      },
      {
        title: t('Price'),
        dataIndex: 'price',
        key: 'price',
        render: (_, record) => {
          return (
            <>
              <Typography.Text className="mr-4">
                {record?.pricePerDay}
              </Typography.Text>
              <Typography.Text type="secondary">
                {record?.currency}
              </Typography.Text>
            </>
          )
        }
      },
      {
        title: t('Status'),
        dataIndex: 'status',
        key: 'status',
        render: (_, record) => {
          return (
            <Typography.Text className="mr-4">
              {PRODUCT_STATUSES_LABELS[record?.status]}
            </Typography.Text>
          )
        }
      },
      {
        title: t('Rate'),
        dataIndex: 'rate',
        key: 'rate',
        render: (_, record) => {
          return (
            <div className="flex flex-col align-start">
              <RateSelect
                isRentee={isRentee}
                size="sm"
                value={record?.avgRating}
                document={record}
                mb={-2}
              />
            </div>
          )
        }
      },
      {
        title: t('Actions'),
        dataIndex: 'actions',
        key: 'actions',
        align: 'right',
        render: (_, record) => {
          const isBlocked = record?.status === PRODUCT_STATUSES.BLOCKED
          return (
            <Space size={2} className="flex justify-end">
              <Button
                onClick={onOpen(record?._id)}
                type="text"
                className="flex justify-center align-center"
                icon={<Icon name="EyeOutlined" size={16} />}
              />

              {!isBlocked && (
                <Button
                  onClick={onEdit(record?._id)}
                  type="text"
                  className="flex justify-center align-center"
                  icon={<Icon name="Edit3Outlined" size={16} />}
                />
              )}
              <Button
                onClick={onDelete}
                type="text"
                className="flex justify-center align-center"
                icon={
                  <Icon name="Trash3Outlined" size={16} fill={colorErrorText} />
                }
              />
            </Space>
          )
        }
      }
    ],
    [
      colorErrorText,
      isRentee,
      onDelete,
      onDraftsToPublishSelect,
      onEdit,
      onOpen,
      selectedDrafts,
      t,
      withCheckboxes
    ]
  )

  const paginationStyles = {
    position: ['bottomCenter'],
    hideOnSinglePage: true,
    total: total,
    pageSize: pageSize,
    current: currentPage,
    onChange: handlePageChange
  }
  const tableProps = { scroll: { x: 1000 } }

  return {
    columns,
    paginationStyles,
    tableProps
  }
}

export default useGetProductsTableConfig
