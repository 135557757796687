import { Menu, Modal } from 'antd'

import styled from 'styled-components'

const StyledTriggerWrapper = styled.div`
  z-index: 4;
  transition: left 0.2s, width 0.2s;
  left: ${({ collapsed }) => (collapsed ? '24px' : '17px')};
  width: ${({ collapsed }) => (collapsed ? 'auto' : '206px')};
`

const StyledMenu = styled(Menu)`
  width: 230px !important;
  min-width: 230px !important;
  border-inline-end: none !important;

  .ant-menu-title-content {
    width: 230px;
    padding: 0;
  }
`
const StyledMenuItem = styled(Menu.Item)`
  width: 100%;
  margin: 0 !important;
  &.ant-menu-item {
    margin: 0 !important;
    display: flex;
    align-items: center;
    .ant-menu-title-content {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: pre-wrap;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .ant-menu-title-content {
    display: flex;
    align-items: center;
  }
`
const StyledModal = styled(Modal)`
  .ant-modal-content {
    width: 100%;
    height: 100%;
    padding: 24px 20px !important;
    overflow-y: ${({ isSmallScreen }) => (isSmallScreen ? 'auto' : 'unset')};
    ::-webkit-scrollbar {
      display: none;
    }
  }
  .ant-modal-body {
    ::-webkit-scrollbar {
      display: none;
    }
  }
`

const styles = {
  bodyStyles: {
    width: '100%',
    height: '100%'
  },
  iconStyles: {
    name: 'Dashboard2Outlined',
    size: 16,
    fill: 'currentColor'
  },
  buttonStyles: {
    className: 'flex justify-center align-center full-width'
  }
}

export { StyledMenu, StyledMenuItem, StyledModal, StyledTriggerWrapper, styles }
