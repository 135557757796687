import { Button, Grid, Tooltip, Modal, Space, Typography, theme } from 'antd'
import { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { SETTINGS_PATHS } from '__constants__/paths'

import { ButtonStyled, styles, TextStyled } from './RetnerRenteeActions.styled'
import { ComplaintModal } from 'domains/Complaint/components'
import { Icon } from '@qonsoll/icons'
import { ORDER_STATUSES, STRIPE_ACCOUNT_STATUSES } from '__constants__'
import PropTypes from 'prop-types'
import { useOrderActions } from 'domains/Order/hooks'
import { useTranslations } from 'hooks'
import { useUser } from 'modules/session-module/contexts'

const { useBreakpoint } = Grid
const RenterActions = (props) => {
  const { order, fullWidth, onComplaintFormToggle } = props
  const { t } = useTranslations()
  const [loading, setLoading] = useState()
  const { acceptOrder, declineOrder } = useOrderActions(setLoading)

  const { md } = useBreakpoint()

  const eStopPropagation = (e) => e.stopPropagation()
  const computeOrderStatus = useCallback(
    (status) => status === order?.status,
    [order?.status]
  )

  const orderStatuses = useMemo(
    () => ({
      accepted: computeOrderStatus(ORDER_STATUSES.ACCEPTED),
      declined: computeOrderStatus(ORDER_STATUSES.DECLINED),
      canceled: computeOrderStatus(ORDER_STATUSES.CANCELED)
    }),
    [computeOrderStatus]
  )

  const isDisabledButton =
    orderStatuses.accepted || orderStatuses.declined || orderStatuses?.canceled
  const computedTooltipTitle = useMemo(() => {
    const computedStatusLabel = ORDER_STATUSES[order?.status]?.toLowerCase()
    return isDisabledButton && `${t('Order was')} ${computedStatusLabel}`
  }, [isDisabledButton, order?.status, t])

  const computedOffset = useMemo(
    () => (fullWidth ? 'mr-16' : 'mr-8'),
    [fullWidth]
  )

  const computedLoading = useMemo(
    () => ({
      [ORDER_STATUSES.ACCEPTED]:
        loading?.type === ORDER_STATUSES.ACCEPTED && loading?.loading,
      [ORDER_STATUSES.DECLINED]:
        loading?.type === ORDER_STATUSES.DECLINED && loading?.loading
    }),
    [loading]
  )

  const { user, loading: userLoading } = useUser()

  const isStripeAccountConnected = useMemo(
    () =>
      userLoading
        ? false
        : user?.stripeAccountId &&
          (user?.stripeAccountStatus === STRIPE_ACCOUNT_STATUSES.ENABLED ||
            user?.stripeAccountStatus === STRIPE_ACCOUNT_STATUSES.COMPLETED),
    [userLoading, user]
  )

  const [isModalOpen, setIsModalOpen] = useState(false)

  const closeModal = () => setIsModalOpen(false)

  const isAcceptLoading = computedLoading?.[ORDER_STATUSES.ACCEPTED]
  const isDeclineLoading = computedLoading?.[ORDER_STATUSES.DECLINED]

  const complaintButtonIcon = <Icon name="AlertTriangleOutlined" size={14} />
  // type is to define what state callback to use
  const changeVisibility = (e) => {
    e.stopPropagation()
    if (
      e.target.id === 'review-form-bottom-drawer' ||
      // handles btn-icon click
      e.target.nodeName === 'svg' ||
      e.target.nodeName === 'path' ||
      e.target.noName === 'BUTTON' ||
      e.target.id === 'review-form-toggler'
    ) {
      onComplaintFormToggle(e)
    }
  }

  const generateBtnClass = (isLast = false) => {
    return !isLast
      ? `flex align-center justify-center ${computedOffset}`
      : 'flex align-center justify-center'
  }

  const navigate = useNavigate()

  const { fontSizeSM } = theme.useToken().token

  const handleOk = () => {
    setIsModalOpen(false)
    navigate(SETTINGS_PATHS.STRIPE_ACCOUNT)
  }
  return (
    <div className="flex align-center justify-end" onClick={eStopPropagation}>
      {md ? (
        <ComplaintModal
          orderId={order?._id}
          productId={order?.product?._id}
          className={generateBtnClass()}
        />
      ) : (
        <Button
          onClick={changeVisibility}
          className={`${generateBtnClass()} px-8`}
          icon={complaintButtonIcon}
        />
      )}
      <Tooltip title={computedTooltipTitle}>
        <ButtonStyled
          block={fullWidth}
          loading={isAcceptLoading}
          className={generateBtnClass()}
          type="primary"
          disabled={isDisabledButton}
          onClick={
            isStripeAccountConnected
              ? acceptOrder(order?._id)
              : () => setIsModalOpen(true)
          }
          icon={<Icon name="CheckmarkFilled" fill="currentColor" />}
        />
      </Tooltip>
      <Tooltip title={computedTooltipTitle}>
        <ButtonStyled
          block={fullWidth}
          loading={isDeclineLoading}
          className={generateBtnClass(true)}
          onClick={declineOrder(order?._id)}
          disabled={isDisabledButton}
          icon={<Icon name="XCloseOutlined" fill="currentColor" />}
        />
      </Tooltip>
      {isModalOpen && (
        <Modal
          style={styles.modal}
          footer={[
            <Button key="back" onClick={closeModal}>
              {t('Cancel')}
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={loading}
              onClick={handleOk}>
              {t('Fill out')}
            </Button>
          ]}
          open={isModalOpen}
          onCancel={closeModal}>
          <Space {...styles.headerSpace}>
            <div className="flex justify-center">
              <Icon name="CoinsHandOutlined" size={42} />
            </div>

            <Typography.Title level={3}>
              {t('Stripe is not connected')}
            </Typography.Title>
            <div {...styles.descriptionWrapper}>
              <TextStyled fontSize={fontSizeSM}>
                {t(
                  'You have not connected a Stripe account for withdrawal money'
                )}
                . {t('You need to fill it out first')}
              </TextStyled>
            </div>
          </Space>
        </Modal>
      )}
    </div>
  )
}

RenterActions.propTypes = {
  order: PropTypes.object,
  fullWidth: PropTypes.bool,
  onComplaintFormToggle: PropTypes.func
}

export default RenterActions
