import { useMemo, useState } from 'react'

const useOrderSearch = (orders) => {
  const [searchedValue, setSearchedValue] = useState('')

  const onSearchChange = (e) => setSearchedValue(e.target.value)

  const searchedOrders = useMemo(() => {
    if (!searchedValue) {
      return orders
    }

    const searchedValueLower = searchedValue.toLowerCase()

    return orders?.filter((item) => {
      const orderNumberLower = item?.orderNumber?.toLowerCase()
      const productNameLower = item?.product?.name?.toLowerCase()

      return (
        (orderNumberLower?.includes(searchedValueLower) ||
          productNameLower?.includes(searchedValueLower)) &&
        item
      )
    })
  }, [orders, searchedValue])

  return { onSearchChange, searchedOrders }
}

export default useOrderSearch
