import { Col, Typography } from 'antd'

import { Image } from 'components'
import PropTypes from 'prop-types'
import { Spinner } from 'components'
import { getTransformedImageUrl } from 'helpers'
import styles from '../../ComplaintList.styles'
import { useGetProductInitialValues } from 'domains/Product/hooks'

const ComplaintProduct = (props) => {
  const { complaint, isSmallScr } = props
  const [product, loading] = useGetProductInitialValues(complaint?.productId)
  const { product: productData, previewImage } = product

  const computedImgUrl = getTransformedImageUrl(previewImage)

  return (
    <>
      {loading ? (
        <Col flex="auto" height="inherit" justify="center">
          <Spinner height="48px" />
        </Col>
      ) : (
        <div
          className={
            isSmallScr ? 'flex align-start flex-col gap-8' : 'flex align-center'
          }>
          <Image
            isSquare={false}
            src={computedImgUrl}
            style={styles.imageProps}
          />
          <div className="flex flex-col justify-center mr-24">
            <Typography.Text strong>{productData?.name}</Typography.Text>
          </div>
        </div>
      )}
    </>
  )
}

ComplaintProduct.propTypes = {
  complaint: PropTypes.object,
  isSmallScr: PropTypes.bool
}

export default ComplaintProduct
