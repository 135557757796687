import { useCallback, useMemo } from 'react'

import { getUploadData } from '../../../helpers'

const useGetUploadItemBaseActions = ({
  onChange,
  setUploadedFiles,
  value,
  file,
  onDeleteCallback
}) => {
  const handleClick = useCallback(
    (e) => {
      e.preventDefault()
      e.stopPropagation()

      const computedValue = value?.map((item) => {
        const [computedItem, computedFile, isIncludesElements] = getUploadData(
          item,
          file
        )

        const isCoverImageItem =
          computedItem === computedFile && isIncludesElements

        return { ...item, isCoverImage: isCoverImageItem ? true : false }
      })

      onChange(computedValue)
    },
    [value, onChange, file]
  )

  const handleDelete = useCallback(
    (e) => {
      e.preventDefault()
      e.stopPropagation()

      onDeleteCallback(true)

      const computedValue = value?.filter((item) => {
        const [computedItem, computedFile, includesElements] = getUploadData(
          item,
          file
        )
        return includesElements && computedItem !== computedFile
      })

      onChange(computedValue)

      setUploadedFiles((prev) =>
        prev?.filter((item) => item?.file?.uid !== file?.uid)
      )
    },

    [value, file, onChange, setUploadedFiles, onDeleteCallback]
  )

  return useMemo(
    () => ({
      handleDelete,
      handleClick
    }),
    [handleClick, handleDelete]
  )
}

export default useGetUploadItemBaseActions
