import {
  createDocument,
  deleteDocument,
  updateDocument
} from 'services/api/firebase'
import { useCallback, useMemo } from 'react'

import firebase from 'firebase/compat/app'
import { serverTimestamp } from 'firebase/firestore'

const useMutate = () => {
  const create = useCallback(async (collectionPath, documentData, id) => {
    const userId = firebase.auth().currentUser.uid

    const data = {
      ...documentData,
      _createdAt: serverTimestamp(),
      _createdBy: userId,
      _updatedAt: null,
      _updatedBy: null
    }

    const res = await createDocument(collectionPath, data, id)

    return res
  }, [])

  const update = useCallback(
    async (collectionPath, documentId, documentData) => {
      const userId = firebase.auth().currentUser.uid

      const data = {
        ...documentData,
        _updatedAt: serverTimestamp(),
        _updatedBy: userId
      }

      const res = await updateDocument(collectionPath, documentId, data)

      return res
    },
    []
  )

  const remove = useCallback(deleteDocument, [])

  return useMemo(() => ({ create, update, remove }), [create, update, remove])
}

export default useMutate
