import { useState } from 'react'

const useProductPreviewFormActions = () => {
  const [isisOpenModal, setIsisOpenModal] = useState(false)

  const handleOpenModal = () => setIsisOpenModal((prev) => !prev)

  return { isisOpenModal, handleOpenModal }
}

export default useProductPreviewFormActions
