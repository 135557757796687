import { Breadcrumbs, PageLayout } from 'components'
import { SettingsList, SettingsTabs } from './components'

import { useScreen } from 'hooks'
import { useTranslations } from 'hooks'

const Settings = () => {
  const { t } = useTranslations()

  const { lg } = useScreen()

  const headingProps = {
    title: !lg && t('Settings'),
    titleSize: 3
  }

  return (
    <PageLayout
      headingProps={headingProps}
      breadcrumbs={!lg && <Breadcrumbs />}>
      {!lg ? <SettingsList /> : <SettingsTabs />}
    </PageLayout>
  )
}

export default Settings
