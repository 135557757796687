import {
  ActionsColStyled,
  PageHeaderWrapper,
  StyledIcon,
  StyledSubtitle
} from './PageHeader.styled'
import { Col, Divider, Row, Tooltip, Typography } from 'antd'

import PropTypes from 'prop-types'
import { useScreen } from 'hooks'

const PageHeader = (props) => {
  const {
    title,
    actions,
    titleSize,
    titleClasses = '',
    subTitle,
    subTitleSize = 5,
    breadcrumbs,
    withoutHeader,
    divider,
    tooltipTitle,
    topHeader,
    filter
  } = props
  const { xs } = useScreen()
  if (withoutHeader) return null
  const titleSizeComputed = !xs ? 1 : 2
  return (
    <>
      {topHeader && <PageHeaderWrapper>{topHeader}</PageHeaderWrapper>}
      <div
        className="relative mb-24"
        style={{ marginTop: topHeader && '68px' }}>
        {(breadcrumbs || filter) && (
          <Row className="align-center">
            <Col className="mr-auto">{breadcrumbs}</Col>
            <Col className="ml-auto">{filter}</Col>
          </Row>
        )}
        <Row align="center" className="gap-8">
          <Col flex="auto">
            {title && (
              <>
                <div className={`flex align-center ${subTitle && 'mb-8'}`}>
                  <Typography.Title
                    className={titleClasses}
                    level={titleSize || titleSizeComputed}>
                    {title}
                  </Typography.Title>
                  {tooltipTitle && (
                    <Tooltip title={tooltipTitle} className="ml-8">
                      <StyledIcon
                        titleSize={titleSize}
                        name="InfoCircleOutlined"
                      />
                    </Tooltip>
                  )}
                </div>
                {subTitle && (
                  <StyledSubtitle level={subTitleSize}>
                    {subTitle}
                  </StyledSubtitle>
                )}
              </>
            )}
            {divider && <Divider className="mb-0 mt-8" />}
          </Col>
          {actions && <ActionsColStyled>{actions}</ActionsColStyled>}
        </Row>
      </div>
    </>
  )
}

PageHeader.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  subTitleSize: PropTypes.number,
  tooltipTitle: PropTypes.string,
  actions: PropTypes.node,
  titleSize: PropTypes.number,
  marginBottom: PropTypes.number,
  breadcrumbs: PropTypes.node,
  withoutHeader: PropTypes.bool,
  divider: PropTypes.bool,
  topHeader: PropTypes.node,
  filter: PropTypes.node,
  titleClasses: PropTypes.string
}

export default PageHeader
