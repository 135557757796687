import { collection, query, where } from 'firebase/firestore'

import { COLLECTIONS } from '__constants__'
import { firestore } from 'services/firebase'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { useMemo } from 'react'
import { useUser } from 'modules/session-module/contexts'

/**
 * It returns an array of values that are used to render the tickets
 * @param props - An object that contains the query parameters.
 * @returns An array of values.
 */
const useGetTickets = () => {
  const { user } = useUser()

  /* Query for getting collection reference */
  const queryRef = useMemo(() => {
    const userId = user?._id || null

    return userId
      ? query(
          collection(firestore, COLLECTIONS.TICKETS),
          where('_createdBy', '==', userId)
        )
      : null
  }, [user?._id])

  /* Getting collection data */
  return useCollectionData(queryRef)
}

export default useGetTickets
