import { CardDropdown, RateSelect, RelativeImage } from 'components'
import {
  CheckboxStyled,
  HoverableCard,
  ImageOverlay,
  RibbonStyled,
  imageRow
} from './ProductSimpleView.styles'
import { Col, Row, Space, Typography } from 'antd'
import { PRODUCT_STATUSES, STRIPE_ACCOUNT_STATUSES } from '__constants__'
import {
  currencySymbol,
  useCheckProductCompletionForPublish,
  useGetProductDropdownItems,
  useProductBaseActions
} from 'domains/Product/hooks'
import { memo, useCallback, useMemo, useState } from 'react'

import { ProductDeletionModal } from 'domains/Product/components'
import { ProductLocationDetails } from 'domains/Product/components/ProductSimpleView/components'
import PropTypes from 'prop-types'
import { calculateFinalPayment } from 'helpers'
import noImage from '__assets__/illustrations/no-image.png'
import { useTranslations } from 'hooks'
import { useUser } from 'modules/session-module/contexts'

const ProductSimpleView = (props) => {
  const {
    product,
    index,
    actions,
    onDraftsToPublishSelect,
    selectedDrafts,
    isRentee,
    category,
    onlyView
  } = props
  const [modalVisible, setModalVisible] = useState(false)

  const onDelete = useCallback(() => setModalVisible(true), [])

  const { previewImgUrl } = product
  const { t } = useTranslations()
  const { user, loading: userLoading } = useUser()

  const isCompleteForPublish = useCheckProductCompletionForPublish(product)

  const isPublished = useMemo(
    () => product?.status === PRODUCT_STATUSES.PUBLISHED,
    [product?.status]
  )

  // computed properties
  const isDraft = useMemo(
    () => product?.status === PRODUCT_STATUSES.DRAFT,
    [product?.status]
  )

  const isStripeAccountConnected = useMemo(
    () =>
      userLoading
        ? false
        : user?.stripeAccountId &&
          (user?.stripeAccountStatus === STRIPE_ACCOUNT_STATUSES.ENABLED ||
            user?.stripeAccountStatus === STRIPE_ACCOUNT_STATUSES.COMPLETED),
    [user, userLoading]
  )

  /* Getting base actions */
  const { onOpen, onEdit, onUnpublish, onPublish } = useProductBaseActions({
    productId: product?._id,
    isRentee,
    actions,
    setModalVisible
  })

  const dropdownActions = useMemo(
    () => ({
      onUnpublish: isPublished ? onUnpublish : null,
      onPublish: isStripeAccountConnected && isDraft ? onPublish : null
    }),
    [isPublished, onPublish, onUnpublish, isDraft, isStripeAccountConnected]
  )

  const extraItems = useGetProductDropdownItems(dropdownActions)

  const isBlocked = useMemo(
    () => product?.status === PRODUCT_STATUSES.BLOCKED,
    [product?.status]
  )
  const isCheckboxVisible = !onlyView && isDraft && isCompleteForPublish
  const checked = selectedDrafts?.includes(product?._id)
  const productCurrencySymbol = currencySymbol(product?.currency)
  const ribbonText = !isCompleteForPublish && isDraft && t('Uncompleted')

  const computeProductPropsActions = useMemo(
    () =>
      isRentee ? {} : { onEdit: isBlocked ? null : onEdit, onDelete: onDelete },
    [isRentee, onDelete, onEdit, isBlocked]
  )

  const handleImageError = useCallback((e) => {
    e.currentTarget.src = noImage
  }, [])

  const productFinalPrice = calculateFinalPayment(product?.pricePerDay)

  return (
    <CardDropdown
      {...computeProductPropsActions}
      onClick={onOpen}
      document={product}
      index={index}
      data-testid="productListItem"
      extraItems={extraItems}
      checked={checked}
      wrapperStyles={{ overflow: 'visible' }}>
      <RibbonStyled text={ribbonText}>
        <HoverableCard bordered={false} checked={checked}>
          {!!isCheckboxVisible && (
            <CheckboxStyled
              onClick={(e) => e.stopPropagation()}
              checked={checked}
              onChange={onDraftsToPublishSelect?.(product?._id)}
            />
          )}
          <Row className="flex align-center">
            <Col span={24}>
              <RelativeImage
                ratio={1.5}
                withOverlay={false}
                src={previewImgUrl}
                onError={handleImageError}
              />
              {/* to darken the bottom and top of images */}
              <ImageOverlay />
            </Col>
          </Row>
          <Row className="px-16 pb-16 pt-8 justify-between" style={imageRow}>
            <Col span={24} className="mb-4" style={{ height: 'fit-content' }}>
              <Typography.Paragraph
                level={5}
                ellipsis={{ rows: 2 }}
                className="mb-0">
                {product?.name}
              </Typography.Paragraph>
              <Typography.Text
                className="mb-4"
                type="secondary"
                ellipsis={{ rows: 1 }}>
                {category}
              </Typography.Text>
              <ProductLocationDetails product={product} onlyAddress />
            </Col>

            <Col
              span={24}
              className="flex align-end justify-between"
              wrap={false}>
              <Space size={4} className="flex align-baseline">
                <Typography.Title level={4}>
                  {productFinalPrice || '0'}
                </Typography.Title>
                <Typography.Title level={4}>
                  {productCurrencySymbol}
                </Typography.Title>
                <Typography.Text type="secondary">
                  {' / '}
                  {t('day')}
                </Typography.Text>
              </Space>
              <Space className="flex flex-col align-end" size={0}>
                <RateSelect
                  mb={-1}
                  size="sm"
                  value={product?.avgRating}
                  document={product}
                />
              </Space>
            </Col>
          </Row>
          <ProductDeletionModal
            modalVisible={modalVisible}
            productId={product?._id}
            setModalVisible={setModalVisible}
          />
        </HoverableCard>
      </RibbonStyled>
    </CardDropdown>
  )
}

ProductSimpleView.propTypes = {
  product: PropTypes.object,
  index: PropTypes.number,
  actions: PropTypes.object,
  onDraftsToPublishSelect: PropTypes.func,
  selectedDrafts: PropTypes.array,
  isRentee: PropTypes.bool,
  onlyView: PropTypes.bool,
  category: PropTypes.string
}

export default memo(ProductSimpleView)
