import { Button } from 'antd'

import styled from 'styled-components'

const styles = {
  datePicker: {
    width: '100%',
    padding: '4px 11px'
  },
  label: {
    color: 'rgba(11, 10, 14, 0.88)',
    fontSize: 14,
    padding: '4px 4px 8px'
  },
  address: {
    overflow: 'hidden',
    maxWidth: '100%',
    textOverflow: 'ellipsis',
    marginTop: 4
  },
  errorMessage: {
    fontSize: 14,
    paddingBottom: 8,
    color: '#ff4d4f'
  },
  modal: { padding: '0px !important', height: '60vh' }
}

const ButtonStyled = styled(Button)`
  width: 100%;
  height: 32px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: ${({ isaddressexist }) => (!isaddressexist ? 0 : 24)};
  border-color: ${({ isaddressexist }) => !isaddressexist && '#ff4d4f'};
`

export { styles, ButtonStyled }
