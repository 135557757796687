import { Image } from 'antd'
import styled from 'styled-components'

const ImageStyled = styled(Image)`
  width: 102px !important;
  height: 102px !important;
  border-radius: 50%;
  cursor: pointer;
`

export { ImageStyled }
