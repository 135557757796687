import { Breadcrumbs, PageLayout, Spinner } from 'components'
import { Col, Row } from 'antd'
import { useTranslations } from 'hooks'

import { ProductList } from 'domains/Product/components'
import PropTypes from 'prop-types'
import { useLikedProducts } from 'contexts'

const FavoriteProducts = (props) => {
  const { withHeader = true } = props

  const { t } = useTranslations()
  const { products, loading } = useLikedProducts()

  const headingProps = {
    title: t('Favorite Products'),
    textAlign: 'left'
  }

  return (
    <PageLayout
      breadcrumbs={withHeader ? <Breadcrumbs /> : null}
      headingProps={withHeader ? headingProps : null}
      area="products">
      <Row gutter={[24, 16]}>
        {loading ? (
          <Col flex="auto">
            <Spinner height="70vh" text={t('Product loading')} />
          </Col>
        ) : (
          <Col flex="auto">
            <ProductList
              span={{ xs: 24, sm: 12, md: 12, lg: 8, xl: 8, xxl: 6 }}
              products={products}
            />
          </Col>
        )}
      </Row>
    </PageLayout>
  )
}
FavoriteProducts.propTypes = {
  isListWithCreate: PropTypes.bool,
  withHeader: PropTypes.bool,
  isDraftsPage: PropTypes.bool
}

export default FavoriteProducts
