import styled from 'styled-components'

export const StyledBox = styled.div`
  background: white;
  padding-y: 8px;
  padding-x: 12px;
  border-top-left-radius: ${({ borderRadius }) => `${borderRadius || '7px'}`};
  border-top-right-radius: ${({ borderRadius }) => `${borderRadius || '7px'}`};
  display: flex,
  justify-content: space-between,
  align-items: center
  `

export const styles = {
  rightIconStyles: {
    cursor: 'pointer',
    size: 20,
    fill: 'white'
  },
  backIconStyles: {
    size: 22,
    fill: 'white',
    mr: '8px',
    cursor: 'pointer'
  },
  textStyles: {
    color: 'white',
    fontWeight: 500,
    mr: '8px'
  },
  textWrapperStyles: {
    display: 'flex',
    alignItems: 'center'
  }
}
