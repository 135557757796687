import styled from 'styled-components'

const RefundStatusDot = styled.div`
  background: ${({ statusColor }) => statusColor};
  height: 8px;
  width: 8px;
  border-radius: 50%;
`

export default RefundStatusDot
