import { COLLECTIONS, RDB_COLLECTIONS } from '__constants__'
import { useListenDataFromRDB, useLoading } from 'hooks'

import { PRODUCT_STATUSES } from '__constants__'
import { useGetDocumentsByIds } from 'services/api/firebase'
import { useMemo } from 'react'
import { useMergeProductMediaObjects } from 'domains/Product/hooks'

const useGetFavoriteProducts = (userId) => {
  const path = useMemo(
    () =>
      userId ? `${RDB_COLLECTIONS.PRODUCT_LIKES_BY_USERS}/${userId}` : null,
    [userId]
  )
  const [userLikedProducts, likesLoading] = useListenDataFromRDB(path)

  const likedProductIds = useMemo(
    () => Object.keys(userLikedProducts || {}),
    [userLikedProducts]
  )

  const [productsData, dataLoading] = useGetDocumentsByIds({
    ids: likedProductIds,
    collection: COLLECTIONS.PRODUCTS
  })

  const filteredProducts = useMemo(
    () =>
      productsData?.filter(
        (product) => product?.status === PRODUCT_STATUSES.PUBLISHED
      ),
    [productsData]
  )

  const [transformedProducts, transformedProductsLoading] =
    useMergeProductMediaObjects(filteredProducts)

  const loading = useLoading([
    likesLoading,
    !likedProductIds,
    dataLoading,
    transformedProductsLoading
  ])

  return [transformedProducts, loading]
}

export default useGetFavoriteProducts
