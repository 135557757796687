import {
  useActionsUserAdvancedForm,
  useInitializeUserAdvancedForm
} from './hooks'
import { useCheckFormChange, useTranslations } from 'hooks'
import { FormWrapper, Box } from 'components'
import { KEYS } from '__constants__'
import PropTypes from 'prop-types'
import { UserSimpleForm } from 'domains/User/components'
import { Fragment, useState, useEffect } from 'react'
import { LocationFormInModal } from 'domains/GoogleMaps/components'
import { Typography } from 'antd'

const { Text } = Typography

const UserAdvancedForm = (props) => {
  const { initialData, group, showTitle, withNavigate, ...rest } = props
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting form instance from hook */
  const { form, setInitialFieldsValue, initialValue } =
    useInitializeUserAdvancedForm(initialData)

  const [onValuesChange, isEqualForm] = useCheckFormChange(initialValue, form)

  /* Handling enter key press */
  const handleKeyPress = (e) => {
    if (e.key === KEYS.ENTER) {
      e.preventDefault()
      onFinish()
    }
  }

  /* Getting loading state and actions from hook */
  const { loading, onFinish, onReset } = useActionsUserAdvancedForm({
    setInitialFieldsValue,
    initialData,
    form,
    withNavigate
  })

  /* Generating form field names */
  const getFieldName = (subGroup) => (group ? [...group, subGroup] : [subGroup])

  const [addressData, setAddressData] = useState()

  useEffect(() => {
    // Trigger onValuesChange for checking address field
    onValuesChange()
  }, [addressData, onValuesChange])

  return (
    <Fragment>
      <FormWrapper
        submitDisabled={isEqualForm}
        submitText={t('Save changes')}
        onValuesChange={onValuesChange}
        onKeyPress={handleKeyPress}
        onFinish={onFinish}
        form={form}
        showTitle={showTitle}
        onReset={onReset}
        submitLoading={loading}
        group={group}
        size="small"
        buttonSize="middle"
        title={t('User')}
        {...rest}>
        <UserSimpleForm
          group={getFieldName('user')}
          initialValues={initialData}
        />
        <Box className="mb-8 px-4">
          <Text>{t('Address')}</Text>
        </Box>
        <LocationFormInModal
          addressId={initialData?.user?.addressId}
          showAddressTitle={false}
          address={initialData?.address}
          form={form}
          group={['user']}
          onSaveValue={setAddressData}
          loading={loading}
          className="mb-24"
        />
      </FormWrapper>
    </Fragment>
  )
}

UserAdvancedForm.propTypes = {
  initialData: PropTypes.object,
  group: PropTypes.array,
  showTitle: PropTypes.bool,
  withNavigate: PropTypes.bool
}

export default UserAdvancedForm
