import { COLLECTIONS } from '__constants__'
import { doc } from 'firebase/firestore'
import firebase from 'firebase/compat/app'
import { firestore } from 'services/firebase'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { useMemo } from 'react'

const useGetCart = () => {
  const userId = firebase.auth()?.currentUser?.uid

  const cartQuery = useMemo(
    () => (userId ? doc(firestore, COLLECTIONS.CARTS, userId) : null),
    [userId]
  )

  return useDocumentData(cartQuery)
}

export default useGetCart
