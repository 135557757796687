import { Button, Form } from 'antd'
import { useCallback, useMemo } from 'react'
import { useScreen, useTranslations } from 'hooks'

import { CONTACT_US_EMAIL } from '__constants__/enums'
import { ContactUsSimpleForm } from 'domains/ContactUs/components'
import PropTypes from 'prop-types'
import { useInitializeContactUsAdvancedForm } from './hooks'

const ContactUsAdvancedForm = (props) => {
  const { group, initialData } = props

  /* Getting translations instance */
  const { t } = useTranslations()

  const { xl } = useScreen()

  /* Getting form instance from hook */
  const { form } = useInitializeContactUsAdvancedForm(initialData)

  /* Generating form field names */
  const getFieldName = (subGroup) => (group ? [...group, subGroup] : [subGroup])

  const prepareMessageText = useCallback(
    (string) => string?.trim()?.replaceAll(' ', '%20'),
    []
  )

  const onFinish = (credentials) => {
    const { contactUs } = credentials

    const subject = prepareMessageText(contactUs?.subject)
    const message = prepareMessageText(contactUs?.message)

    const hrefData = `mailto:${CONTACT_US_EMAIL}?subject=${subject}&body=${message}`

    const element = document.createElement('a')
    element.style.display = 'none'
    element.setAttribute('href', hrefData)
    element.click()
  }

  const computedButtonProps = useMemo(
    () => ({
      size: !xl ? 'large' : 'middle',
      type: 'primary',
      htmlType: 'submit',
      block: !xl
    }),
    [xl]
  )
  return (
    <Form
      form={form}
      layout="vertical"
      requiredMark="optional"
      onFinish={onFinish}>
      <ContactUsSimpleForm group={getFieldName('contactUs')} />
      <Button {...computedButtonProps}>{t('Send message')}</Button>
    </Form>
  )
}

ContactUsAdvancedForm.propTypes = {
  initialData: PropTypes.object,
  group: PropTypes.array,
  showTitle: PropTypes.bool
}

export default ContactUsAdvancedForm
