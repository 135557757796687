import { useCallback, useMemo } from 'react'

import { APP_PATHS } from '__constants__/paths'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Modal } from 'antd'
import firebase from 'firebase/compat/app'
import { useNavigate } from 'react-router-dom'
import { useTranslations } from 'hooks'
import { useUser } from 'modules/session-module/contexts'

const useAccountMenuActions = () => {
  const { setIsRentee } = useUser()
  const { t } = useTranslations()
  const navigate = useNavigate()

  const confirmLogout = useCallback(() => {
    Modal.confirm({
      title: t('Confirm Logout'),
      centered: true,
      icon: <ExclamationCircleOutlined />,
      content: t('Are you sure you want to log out') + '?',
      okText: t('Logout'),
      onOk: () => firebase.auth().signOut(),
      cancelText: t('Cancel')
    })
  }, [t])

  const toggleRentee = useCallback(() => {
    setIsRentee((prev) => !prev)
    navigate(APP_PATHS.DASHBOARD)
  }, [setIsRentee, navigate])

  return useMemo(
    () => ({ confirmLogout, toggleRentee }),
    [confirmLogout, toggleRentee]
  )
}

export default useAccountMenuActions
