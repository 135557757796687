const ORDER_STATUSES = {
  IN_PROGRESS: 'IN_PROGRESS',
  // PAID: 'PAID',
  CANCELED: 'CANCELED',
  // REFUND: 'REFUND',
  ACCEPTED: 'ACCEPTED',
  DECLINED: 'DECLINED'
}

const ORDER_STATUSES_LABELS = {
  IN_PROGRESS: 'In progress',
  // PAID: 'Paid',
  CANCELED: 'Canceled',
  // REFUND: 'Refund',
  ACCEPTED: 'Accepted',
  DECLINED: 'Declined'
}

const ORDER_STATUSES_OPTIONS = Object.keys(ORDER_STATUSES_LABELS).map(
  (key) => ({ label: ORDER_STATUSES_LABELS[key], value: key })
)

export default ORDER_STATUSES
export { ORDER_STATUSES_OPTIONS, ORDER_STATUSES, ORDER_STATUSES_LABELS }
