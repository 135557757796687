import { Card, Typography, theme } from 'antd'

import PropTypes from 'prop-types'
import { SelectableCard } from 'components/forms'
import styled from 'styled-components'

const CardStyled = styled(Card)`
  .ant-card-body {
    padding: ${({ padding }) => padding};
  }
`

const StyledSelectableCard = styled(SelectableCard)`
  #location-icon path {
    stroke: ${({ theme }) => theme.colorPrimaryActive};
  }
  .ant-card-body {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
  }
`

const TextStyled = styled(Typography.Text)`
  font-weight: ${({ fontWeight }) => fontWeight};
`
const IconWrapper = (props) => {
  const { children } = props
  const { colorFillSecondary } = theme.useToken().token
  const wrapperStyles = {
    width: `40px`,
    minWidth: '40px',
    height: '40px',
    borderRadius: '50%',
    backgroundColor: colorFillSecondary
  }
  return (
    <div style={wrapperStyles} {...props}>
      {children}
    </div>
  )
}
IconWrapper.propTypes = {
  children: PropTypes.node
}

export default StyledSelectableCard
export { IconWrapper, CardStyled, TextStyled }
