import { useCallback, useMemo } from 'react'

import { useTranslations } from 'hooks'

const useToggleLanguage = () => {
  const { setCurrentLanguage, language, languages } = useTranslations()

  const toggleLanguage = useCallback(() => {
    const currentIndex = languages.findIndex(
      ({ shortCode }) => language === shortCode
    )
    const nextIndex = (currentIndex + 1) % languages.length
    setCurrentLanguage(languages[nextIndex].shortCode)
  }, [language, languages, setCurrentLanguage])

  const currentLanguage = useMemo(
    () => languages.find(({ shortCode }) => language === shortCode),
    [language, languages]
  )

  return [currentLanguage, toggleLanguage]
}

export default useToggleLanguage
