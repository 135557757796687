import PropTypes from 'prop-types'
import { Table } from 'antd'
import { useGetTicketsTableConfig } from 'domains/Ticket/hooks'

const TicketList = (props) => {
  const { tickets } = props

  const { columns, paginationStyles, tableProps } = useGetTicketsTableConfig()

  return (
    <Table
      dataSource={tickets}
      columns={columns}
      pagination={paginationStyles}
      {...tableProps}
    />
  )
}

TicketList.propTypes = {
  tickets: PropTypes.array
}

export default TicketList
