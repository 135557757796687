import { Button, InputNumber } from 'antd'

import styled from 'styled-components'

const border = (borderColor) => `2px solid ${borderColor}`

const InputNumberStyled = styled(InputNumber)`
  width: 50% !important;
  border: none;
  border-left: ${({ borderColor }) => border(borderColor)};
  border-right: ${({ borderColor }) => border(borderColor)};
  border-radius: 0px;

  & input {
    padding: 0 !important;
    margin: 0 !important;
    text-align: center !important;
  }

  &:hover {
    border-left: ${({ borderColor }) => border(borderColor)};
    border-right: ${({ borderColor }) => border(borderColor)};
    background-color: ${({ backgroundColor }) => backgroundColor};
  }
`

const ButtonStyled = styled(Button)`
  width: 25%;
  border-radius: 0;
  margin: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Wrapper = styled.div`
  border: ${({ borderColor }) => border(borderColor)} !important;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
`
export { InputNumberStyled, ButtonStyled, Wrapper }
