import { getStripeBalance } from 'modules/stripe-module/helpers'
import { useState, useEffect, useCallback } from 'react'
import { useHandleError, useTranslations } from 'hooks'

const useGetBalance = (accountId) => {
  const handleError = useHandleError()
  const { t } = useTranslations()

  const [balance, setBalance] = useState()
  const [loading, setLoading] = useState()

  const handleGetStripeBalance = useCallback(async () => {
    try {
      setLoading(true)

      const _balance = await getStripeBalance(accountId)

      setBalance(_balance)
    } catch (error) {
      handleError(error, t('Error during getting address'))
    } finally {
      setLoading(false)
    }
  }, [accountId, handleError, t])

  useEffect(() => {
    accountId && handleGetStripeBalance()
  }, [accountId, handleGetStripeBalance])

  return [balance, loading]
}

export default useGetBalance
