import { Badge, Col, Row, Typography, theme } from 'antd'
import { TICKET_STATUSES, TICKET_STATUSES_LABELS } from '__constants__'

import PropTypes from 'prop-types'
import { useMemo } from 'react'

const TicketStatus = (props) => {
  const { status } = props

  const token = theme.useToken().token
  const { colorPrimary, colorWarning, colorSuccess } = token

  const STATUS_COLORS = useMemo(
    () => ({
      [TICKET_STATUSES.PENDING]: colorWarning,
      [TICKET_STATUSES.ON_REVIEW]: colorPrimary,
      [TICKET_STATUSES.CLOSED]: colorSuccess
    }),
    [colorPrimary, colorSuccess, colorWarning]
  )

  const computedStatus = useMemo(
    () => TICKET_STATUSES_LABELS?.[status],
    [status]
  )

  const computedStatusColors = useMemo(
    () => STATUS_COLORS?.[status],
    [status, STATUS_COLORS]
  )

  return (
    <Row>
      <Col span={24}>
        <div className="flex gap-8">
          <Badge size="large" color={computedStatusColors} />
          <Typography.Text>{computedStatus}</Typography.Text>
        </div>
      </Col>
    </Row>
  )
}

TicketStatus.propTypes = {
  status: PropTypes.string
}

export default TicketStatus
