import { COLLECTIONS } from '__constants__'
import { useCollection } from 'services/api/firebase'
import { useMemo } from 'react'
import { useUser } from 'modules/session-module/contexts'

/**
 * It returns an array of values that are used to render the notifications
 * @param props - An object that contains the query parameters.
 * @returns An array of values.
 */
const useGetNotifications = (props) => {
  const { user } = useUser()
  const query = useMemo(() => {
    return {
      ref: COLLECTIONS.NOTIFICATIONS,
      where: [[`receivers.${user?._id}`, 'in', [true, false]]]
    }
  }, [user?._id])
  /* Getting collection data */
  const [value, loading, error, next, loadingMore, loadMoreAvailable] =
    useCollection(query)

  const sortedNotifications = value?.sort((a, b) => {
    return b._createdAt?.toDate() - a._createdAt?.toDate()
  })

  // Query for counting unseen notifications
  const countUnseenQuery = useMemo(
    () => ({
      ref: COLLECTIONS.NOTIFICATIONS,
      where: [[`receivers.${user?._id}`, '==', false]]
    }),
    [user?._id]
  )
  /* Getting collection data */
  const [unseenNotifications, unseenLoading] = useCollection(countUnseenQuery)
  return [
    sortedNotifications,
    unseenNotifications,
    loading,
    unseenLoading,
    error,
    next,
    loadingMore,
    loadMoreAvailable
  ]
}

export default useGetNotifications
