import PropTypes from 'prop-types'
import { theme } from 'antd'
const DottedDiv = (props) => {
  const { height } = props
  const { colorTextQuaternary } = theme.useToken().token

  const styles = {
    borderBottom: `1px dotted ${colorTextQuaternary}`,
    display: 'flex',
    flex: 'none',
    alignSelf: 'stretch',
    flexGrow: '1',
    height: height
  }
  return <div {...props} style={styles} />
}

DottedDiv.propTypes = {
  height: PropTypes.string
}
export default DottedDiv
