import { APP_PATHS, SETTINGS_PATHS } from '__constants__'
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useCallback, useMemo } from 'react'

import { Icon } from '@qonsoll/icons'
import { SettingsTabs, Box } from 'components'
import { useTranslations, useGetSettingsTabsData } from 'hooks'
import ErrorIconStyled from './SettingsTabs.styled'

const SettingsTabsPage = () => {
  /* Getting current path name */
  const pathName = useLocation().pathname
  const SETTINGS_TABS_DATA = useGetSettingsTabsData()

  const { t } = useTranslations()

  /* Getting navigate instance */
  const navigate = useNavigate()

  const currentPath = useMemo(
    () => pathName?.split('/')?.slice(0, 3)?.join('/'),
    [pathName]
  )

  const lastActiveTab = useMemo(
    () => currentPath || SETTINGS_TABS_DATA?.[0]?.key,
    [SETTINGS_TABS_DATA, currentPath]
  )

  const onChangeTab = useCallback((key) => navigate(key), [navigate])

  return (
    <SettingsTabs
      tabPosition="left"
      defaultActiveKey={lastActiveTab}
      activeKey={lastActiveTab}
      onChange={onChangeTab}>
      {SETTINGS_TABS_DATA.map(({ title, key, icon, isError }) => (
        <SettingsTabs.TabPane
          tab={
            <Box className="flex align-center justify-between gap-4">
              <Box className="flex align-center">
                <Icon name={icon} size={14} className="mr-8" />
                {t(title)}
              </Box>
              {isError && <ErrorIconStyled name="AlertDangerWarningFilled" />}
            </Box>
          }
          key={key}>
          <Outlet />
          {APP_PATHS.SETTINGS === pathName &&
            Object.values(SETTINGS_PATHS).includes(lastActiveTab) && (
              <Navigate to={lastActiveTab} />
            )}
        </SettingsTabs.TabPane>
      ))}
    </SettingsTabs>
  )
}

export default SettingsTabsPage
