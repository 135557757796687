import { SETTINGS_PATHS } from '__constants__'
import { useEvent } from 'hooks'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

/* Path's */
const createPath = SETTINGS_PATHS.ADDRESS_CREATE
const editPath = SETTINGS_PATHS.ADDRESS_EDIT

const useVerificationBaseActions = ({ showPath }) => {
  /* Getting navigate instance */
  const navigate = useNavigate()

  /* Function to handle back click */
  const handleBack = useEvent(() => navigate(-1))

  /* Handling opening of the show page */
  const handleOpen = useEvent(() => navigate(showPath))

  return useMemo(
    () => ({
      onBack: handleBack,
      onOpen: handleOpen,
      createPath,
      editPath
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleBack, handleOpen]
  )
}

export default useVerificationBaseActions
