import { useEffect, useState, useCallback } from 'react'
import { getDocument } from 'services/api/firebase'
import { COLLECTIONS } from '__constants__'

/**
 * It fetches a user document from Firestore and returns it
 * @param notification - The notification object that we're getting the user for.
 * @returns An array with four values. The first value is the user object, and the second value is a
 * boolean that indicates whether the user is loading.
 */
const useGetNotificationReceiverUserIdData = (notification) => {
  /* State for data */
  const [user, setReceiverUserId] = useState(null)
  /* State for loading */
  const [loading, setLoading] = useState(!!notification?.receiverUserId)
  /* State for error */
  const [error, setError] = useState(null)

  /* Fetching data */
  const getReceiverUserId = useCallback(async () => {
    try {
      if (!notification?.receiverUserId) return setReceiverUserId(null)

      /* Setting loading state to true */
      setLoading(true)

      /* Getting user document */
      const document = await getDocument(
        COLLECTIONS.USERS,
        notification.receiverUserId
      )

      /* Setting user data */
      setReceiverUserId(document)
    } catch (error) {
      setError(error)
    }

    /* Setting loading state to false */
    setLoading(false)
  }, [notification?.receiverUserId])

  useEffect(() => {
    notification?.receiverUserId !== undefined && getReceiverUserId()
  }, [notification?.receiverUserId, getReceiverUserId])

  return [user, loading, error, getReceiverUserId]
}

export default useGetNotificationReceiverUserIdData
