import {
  useActionsPaymentMethodAdvancedForm,
  useInitializePaymentMethodAdvancedForm
} from './hooks'
import { useCheckFormChange, useTranslations } from 'hooks'

import { FormWrapper } from 'components'
import { KEYS } from '__constants__'
import { PaymentMethodSimpleForm } from 'domains/PaymentMethod/components'
import PropTypes from 'prop-types'

const PaymentMethodAdvancedForm = (props) => {
  const { initialData, group, showTitle, onCheckout, ...rest } = props

  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting form instance from hook */
  const { form, initialValues } =
    useInitializePaymentMethodAdvancedForm(initialData)

  const [handleValuesChange, isEqualForm] = useCheckFormChange(initialValues)
  /* Handling enter key press */
  const handleKeyPress = (e) => {
    if (e.key === KEYS.ENTER) {
      e.preventDefault()
      onFinish()
    }
  }

  /* Getting loading state and actions from hook */
  const { loading, onFinish, onReset } = useActionsPaymentMethodAdvancedForm({
    initialData,
    form,
    onCheckout
  })

  /* Generating form field names */
  const getFieldName = (subGroup) => (group ? [...group, subGroup] : [subGroup])

  return (
    <FormWrapper
      onKeyPress={handleKeyPress}
      onFinish={onFinish}
      onValuesChange={handleValuesChange}
      submitDisabled={isEqualForm}
      form={form}
      showTitle={showTitle}
      submitText={t('Save method')}
      actionsDisabled={false}
      onReset={!onCheckout && onReset}
      submitLoading={loading}
      group={group}
      title={t('Payment method')}
      {...rest}>
      <PaymentMethodSimpleForm
        onCheckout={onCheckout}
        group={getFieldName('paymentMethod')}
      />
    </FormWrapper>
  )
}

PaymentMethodAdvancedForm.propTypes = {
  initialData: PropTypes.object,
  group: PropTypes.array,
  showTitle: PropTypes.bool,
  onCheckout: PropTypes.func
}

export default PaymentMethodAdvancedForm
