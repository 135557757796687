import { iconStyles, wrapperStyles } from './ChatLoading.styles'

import { Box } from 'components'
import { Icon } from '@qonsoll/icons'
import PropTypes from 'prop-types'
import { useTranslations } from '@qonsoll/translation'
import { theme } from 'antd'

const ChatLoading = (props) => {
  const { text } = props
  const { colorPrimary } = theme.useToken().token

  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()

  return (
    <Box {...wrapperStyles}>
      <Icon fill={colorPrimary} {...iconStyles} />
      {text || `${t('Chat is loading')}...`}
    </Box>
  )
}

ChatLoading.propTypes = { text: PropTypes.string }

export default ChatLoading
