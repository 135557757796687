import { DottedBlock } from 'components'
import PropTypes from 'prop-types'
import { Typography } from 'antd'
import { useGetUserDisplayName } from 'domains/User/hooks'
import { useTranslations } from 'hooks'

const OrderRenter = (props) => {
  const { order } = props
  const { t } = useTranslations()

  const renterDisplayName = useGetUserDisplayName(order?.renter || {})

  return (
    <>
      <Typography.Title level={5} className="mt-24 mb-12">
        {t('Renter')}
      </Typography.Title>
      <DottedBlock
        className="mb-8"
        height="22px"
        left={<Typography.Text>{t('Name')}</Typography.Text>}
        right={<Typography.Text>{renterDisplayName}</Typography.Text>}
      />
      <DottedBlock
        className="mb-8"
        height="22px"
        left={<Typography.Text>{t('Email')}</Typography.Text>}
        right={<Typography.Text>{order?.renter?.email}</Typography.Text>}
      />
      <DottedBlock
        className="mb-8"
        height="22px"
        left={<Typography.Text>{t('Phone')}</Typography.Text>}
        right={<Typography.Text>{order?.renter?.phone}</Typography.Text>}
      />
    </>
  )
}

OrderRenter.propTypes = {
  order: PropTypes.object
}

export default OrderRenter
