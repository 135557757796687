/**
 * Formats a number with thousands separators and decimal places.
 *
 * @param {number} value - The number to format.
 * @param {number} n - The number of decimal places to include.
 * @param {number} x - The number of digits between separators.
 * @returns {string} The formatted number.
 */
const formatPointsNumbers = (value, n, x) => {
  var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')'
  return value
    ? // eslint-disable-next-line no-bitwise
      value?.toFixed?.(Math?.max(0, ~~n))?.replace(new RegExp(re, 'g'), '$&,')
    : 0
}

export default formatPointsNumbers
