import { Col, Row, Typography, theme } from 'antd'

import { Image } from 'components'
import PropTypes from 'prop-types'
import { imageStyles } from './CheckoutProductPreview.styled'
import noImage from '__assets__/illustrations/no-image.png'
import { useCallback } from 'react'

const CheckoutProductPreview = (props) => {
  const { cart = {} } = props
  const { previewImgUrl, name } = cart

  const { borderRadiusLG, colorBorder } = theme.useToken().token

  const handleImageError = useCallback((e) => {
    e.currentTarget.src = noImage
  }, [])

  return (
    <Row>
      <Col span={24} className="flex flex-row align-center">
        <Image
          isSquare={false}
          {...imageStyles}
          src={previewImgUrl}
          onError={handleImageError}
          borderRadius={borderRadiusLG}
          borderColor={colorBorder}
        />
        <Typography.Text level={4} className="ml-16">
          {name}
        </Typography.Text>
      </Col>
    </Row>
  )
}

CheckoutProductPreview.propTypes = {
  cart: PropTypes.object
}
export default CheckoutProductPreview
