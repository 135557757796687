const STATISTIC_KEYS = {
  TOTAL_EARNING: 'TOTAL_EARNING',
  RENTED_PRODUCTS: 'RENTED_PRODUCTS',
  NEW_CUSTOMERS: 'NEW_CUSTOMERS',
  MONTH_EARNING: 'MONTH_EARNING',
  MONTH_PRODUCT_VIEWS: 'MONTH_PRODUCT_VIEWS'
}

export default STATISTIC_KEYS
export { STATISTIC_KEYS }
