import { Divider } from 'antd'
import styled from 'styled-components'

const styles = {
  fieldTitleProps: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '250px',
    whiteSpace: 'nowrap'
  },
  fieldRowProps: {
    justifyContent: 'center',
    alignItems: 'center',
    py: '4px',
    mb: 2
  }
}
const StyledDivider = styled(Divider)`
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: -15px;
    border-radius: 0 30px 30px 0;
    left: -25px;
    width: 20px;
    height: 30px;
    background-color: ${({ colorBgLayout }) => colorBgLayout};
  }
  &::after {
    content: '';
    position: absolute;
    top: -15px;
    border-radius: 30px 0 0 30px;
    right: -25px;
    width: 20px;
    height: 30px;
    background-color: ${({ colorBgLayout }) => colorBgLayout};
  }
`

export { styles, StyledDivider }
