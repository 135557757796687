import { COLLECTIONS, RDB_COLLECTIONS } from '__constants__'
import { collection, query, where } from 'firebase/firestore'
import { useGetDataFromRDB, useLoading } from 'hooks'

import { firestore } from 'services/firebase'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { useMemo } from 'react'
import { useUser } from 'modules/session-module/contexts'

const useGetOrderReviewWithRating = (order) => {
  const { user } = useUser()

  const [review, reviewLoading] = useCollectionData(
    order?._id &&
      query(
        collection(firestore, COLLECTIONS.REVIEWS),
        where('orderId', '==', order?._id)
      )
  )
  const [rating, ratingLoading] = useGetDataFromRDB(
    `${RDB_COLLECTIONS.PRODUCT_RATINGS}/${order?.product?._id}/${user?._id}`
  )

  const loading = useLoading([reviewLoading, ratingLoading])

  return useMemo(() => [review, rating, loading], [loading, rating, review])
}

export default useGetOrderReviewWithRating
