import { getDatabase, onValue, ref } from 'firebase/database'
import { useEffect, useState } from 'react'

import { COLLECTIONS } from '__constants__'

const useListenOnlineStatus = (userId) => {
  const [data, setData] = useState([])

  useEffect(() => {
    if (!userId) return

    const db = getDatabase()

    const onlineStatusListener = onValue(
      ref(db, `${COLLECTIONS.USER_STATUSES}/${userId}`),
      (snapshot) => {
        const snapshotData = snapshot.val()
        if (snapshotData) {
          const { isOnline, lastActiveTime } = snapshotData
          setData([isOnline, lastActiveTime])
        }
      }
    )

    return () => {
      onlineStatusListener?.()
    }
  }, [userId])

  return data
}

export default useListenOnlineStatus
