import { COLLECTIONS } from '__constants__'
import { getUserFullName } from 'helpers'
import { useGetDocumentsByIds } from 'services/api/firebase'
import { useMemo } from 'react'

/**
 * It returns an array of values that are used to render the customers
 * @param props - An object that contains the query parameters.
 * @returns An array of values.
 */
const useGetCustomersByIds = (customerIds) => {
  const customerParams = useMemo(
    () => ({ collection: COLLECTIONS.USERS, ids: customerIds }),
    [customerIds]
  )

  const [customers, loading] = useGetDocumentsByIds(customerParams)

  const transformedCustomers = useMemo(
    () =>
      customers?.map((customer) => {
        customer.fullName = getUserFullName(
          customer?.firstName,
          customer?.lastName
        )
        return customer
      }),
    [customers]
  )

  return [transformedCustomers, loading]
}

export default useGetCustomersByIds
