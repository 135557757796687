import { collection, orderBy, query, where } from 'firebase/firestore'

import { COLLECTIONS } from '__constants__'
import { firestore } from 'services/firebase'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { useMemo } from 'react'

const useGetUserOrdersAmount = (userId) => {
  const q = useMemo(
    () =>
      userId &&
      query(
        collection(firestore, COLLECTIONS.ORDERS),
        where('renterUserId', '==', userId),
        orderBy('_createdAt', 'desc')
      ),
    [userId]
  )
  const [orders, loading] = useCollectionData(q)
  return useMemo(
    () => (!loading && orders?.length) || 0,
    [loading, orders?.length]
  )
}

export default useGetUserOrdersAmount
