import { Row, Col, Image, Typography } from 'antd'
import success from '__assets__/illustrations/success.svg'
import { useTranslations } from 'hooks'
import imageStyles from './ReturnStripeAccountAdvancedView.styles'
import PropTypes from 'prop-types'

const { Text, Title } = Typography

const ReturnStripeAccountAdvancedView = (props) => {
  const { isEdit } = props

  const { t } = useTranslations()

  const subtitle = isEdit
    ? t('Stripe account was connected successfully')
    : t('Stripe account was updated successfully')

  return (
    <Row align="center">
      <Col span={24} align="center" className="mb-16">
        <Image {...imageStyles} src={success} preview={false} />
      </Col>
      <Col span={24} align="center" className="mb-2">
        <Title level={2}> {t('Completed')}!</Title>
      </Col>
      <Col>
        <Text>{subtitle}</Text>
      </Col>
    </Row>
  )
}

ReturnStripeAccountAdvancedView.propTypes = {
  isEdit: PropTypes.bool
}

export default ReturnStripeAccountAdvancedView
