import styled from 'styled-components'

const footerHeight = '64px'
const wrapperHeight = '570px'
const headerHeight = '90px'

const DrawerBottom = styled.div`
  position: fixed;
  padding: 18px 24px;
  height: fit-content;
  background: ${({ theme }) => theme.colorBgBase};
  left: ${({ xs }) =>
    xs ? 0 : '78px'}; // 78px is width aside for xs media-size
  right: 0;
  bottom: ${({ isOpened, xs, bottomShift }) =>
    xs
      ? isOpened
        ? footerHeight
        : bottomShift ||
          `calc(( ${wrapperHeight} - ${headerHeight} - ${footerHeight}) * -1)`
      : isOpened
      ? '0'
      : bottomShift || `calc((${wrapperHeight} - ${headerHeight}) * -1)`};
  z-index: 1000;
  border-top: ${({ theme }) => `1px solid ${theme.colorBorderSecondary}`};
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);

  &:before {
    content: '';
    width: 64px;
    height: 4px;
    background: ${({ theme }) => theme.colorBorder};
    position: absolute;
    left: calc(50% - 32px);
    top: 12px;
    border-radius: 3px;
  }
`

export default DrawerBottom
