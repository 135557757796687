import { useHandleError, useTranslations } from 'hooks'
import { useLocation, useNavigate } from 'react-router-dom'

import { LS } from 'modules/session-module/__constants__'
import { PATHS } from '__constants__'
import PropTypes from 'prop-types'
import { Spinner } from '../../components'
import firebase from 'firebase/compat/app'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useEffect } from 'react'

const unauthenticatedPaths = Object.values(PATHS.UNAUTHENTICATED)?.map(
  (key) => `/auth/${key}`
)

const RoutesRedirect = ({ children }) => {
  const { t } = useTranslations()
  const navigate = useNavigate()
  const location = useLocation()
  const handleError = useHandleError()
  const [user, loading, error] = useAuthState(firebase.auth())
  // const user = useUser() // uncomment this part if you need to add extra conditions based on data from the DB

  // Making decision how to redirect
  useEffect(() => {
    /* This code is checking if the current path is in the unauthenticatedPaths array. */
    const isUnauthenticatedPath = unauthenticatedPaths.includes(
      location.pathname
    )
    /* If the user is logged in, and the user's email is verified, then the user is logged in. */
    const isEmailNotVerified = user && !user.emailVerified
    const isLoggedIn =
      isUnauthenticatedPath && user && !loading && !isEmailNotVerified
    const isLoggedOut = !isUnauthenticatedPath && !user && !loading
    const isConfirmEmailPage = !location.pathname?.includes(
      `/auth/${PATHS.UNAUTHENTICATED.CONFIRM_EMAIL}`
    )

    /* If the user is logged in, redirect to the config page. If the user is logged out, redirect to
    the logout page. If the user's email is not verified, redirect to the email confirmation page.
    */
    if (isLoggedIn) {
      navigate(`${PATHS.CONFIG.AFTER_LOGIN}`, { replace: true })
      localStorage.removeItem(LS.SIGNUP_DATA)
    } else if (isEmailNotVerified && isConfirmEmailPage) {
      // Log out when the user leaves the confirmation email page and their email is not verified.
      // firebase.auth().signOut()
    } else if (isLoggedOut) {
      if (location?.pathname === '/vipps-callback') return
      navigate(`/auth/${PATHS.CONFIG.AFTER_LOGOUT}`)
    }
  }, [navigate, user, loading, location.pathname])

  // Session fetching error handling
  useEffect(() => {
    error && handleError(error)
  }, [error, handleError])

  return loading ? <Spinner text={t('Loading', 'Loading...')} /> : children
}

RoutesRedirect.propTypes = {
  children: PropTypes.element
}

export default RoutesRedirect
