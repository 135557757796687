import styled from 'styled-components'

const AvatarWrapper = styled.div`
  position: relative;

  .verification {
    position: absolute;
    top: -5px;
    right: -5px;
    background: ${({ colorBgContainer }) => colorBgContainer};
    border-radius: 20px;
    border: ${({ colorBgContainer }) => `1px solid  ${colorBgContainer}`};
  }
`

export default AvatarWrapper
