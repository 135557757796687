import { Button, Typography } from 'antd'

import styled from 'styled-components'

const TextStyled = styled(Typography.Text)`
  font-size: ${({ fontSize }) => `${fontSize}px`};
  color: ${({ color }) => color};
`

const ButtonStyled = styled(Button)`
  color: ${({ color }) => color};
  & span {
    display: inline-block;
    text-decoration: underline;
    color: currentColor;
    text-underline-offset: 2px;
  }
`

const styles = {
  actionsWrapper: {
    style: { maxWidth: '300px' },
    className: 'flex flex-col mx-auto row-gap-12'
  },

  image: {
    alt: 'Confirmation',
    draggable: false,
    preview: false,
    width: '25%'
  },

  headerSpace: {
    size: 12,
    direction: 'vertical',
    className: 'flex flex-col align-center mb-24'
  },
  mainSpace: {
    direction: 'vertical',
    className: 'flex flex-col align-center mb-24'
  },
  actionsSpace: {
    direction: 'vertical',
    className: 'full-width'
  },

  verificationInput: {
    length: 6,
    autoFocus: true,
    placeholder: '',
    classNames: {
      container: 'container',
      character: 'character',
      characterInactive: 'character-inactive',
      characterSelected: 'character-selected'
    }
  },
  descriptionWrapper: {
    style: { maxWidth: '500px' },
    className: 'text-align-center'
  }
}

export { styles, ButtonStyled, TextStyled }
