import styled from 'styled-components'

const StyledList = styled.div`
  width: 100%;
  max-height: 70vh;
  overflow-y: scroll;
  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none !important;

    background-color: red !important;
  }

  /* Hide scrollbar for Internet Explorer and Edge */
  -ms-overflow-style: none !important;

  /* Hide scrollbar for Firefox */
  scrollbar-width: none !important;

  /* You may also want to add this to remove padding/margin in some browsers */
  margin: 0 !important;
  padding: 0 !important;
  overflow: -moz-scrollbars-none !important;
`
export { StyledList }
