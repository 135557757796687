import { Button, Divider, Space, Typography } from 'antd'
import { DrawerOverlay, DrawerSide } from 'components'

import { ComplaintAdvancedView } from 'domains/Complaint/components'
import { Icon } from '@qonsoll/icons'
import PropTypes from 'prop-types'
import styles from './ComplaintSideDrawer.styled'
import { useTranslations } from 'hooks'

const OrderSideDrawer = (props) => {
  const { complaintDrawerProps, onComplaintSideDrawerClose, rightShiftOpen } =
    props
  const { complaint, visibility } = complaintDrawerProps || {}
  const { t } = useTranslations()
  return (
    <div>
      <DrawerSide
        isOpen={visibility}
        rightShiftOpen={rightShiftOpen}
        hideScroll>
        <Space size={16}>
          <Button
            type="text"
            className="flex align-center justify-center"
            icon={<Icon name="XCloseOutlined" size={24} />}
            onClick={onComplaintSideDrawerClose}
          />

          <div>
            <Typography.Title level={5}>{t('Complaint')}</Typography.Title>
          </div>
        </Space>
        <Divider className="mt-16 mb-24" style={styles.fullWidthDivider} />

        {rightShiftOpen && <ComplaintAdvancedView complaint={complaint} />}
      </DrawerSide>
      <DrawerOverlay isOpen={visibility} onClick={onComplaintSideDrawerClose} />
    </div>
  )
}

OrderSideDrawer.propTypes = {
  complaintDrawerProps: PropTypes.object,
  onComplaintSideDrawerClose: PropTypes.func,
  rightShiftOpen: PropTypes.string
}

export default OrderSideDrawer
