import { COLLECTIONS, PATHS, PRODUCT_STATUSES } from '__constants__'
import { generatePath, useNavigate } from 'react-router-dom'
import { useEvent, useTranslations } from 'hooks'

import { message } from 'antd'
import moment from 'moment'
import { updateUserViewsStatistics } from 'domains/Product/helpers'
import { useMemo } from 'react'
import { useMutate } from 'hooks'

/* Path's */
const createPath = PATHS.AUTHENTICATED.PRODUCT_CREATE
const editPath = PATHS.AUTHENTICATED.PRODUCT_EDIT
const showPath = PATHS.AUTHENTICATED.PRODUCT_SHOW
const renterShowPath = PATHS.AUTHENTICATED.MY_PRODUCT_SHOW

const useProductBaseActions = (params) => {
  const { productId, actions, isRentee, product } = params || {}

  const { update } = useMutate()

  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting navigate instance */
  const navigate = useNavigate()

  /* Function to handle back click */
  const handleBack = useEvent(() => navigate(-1))

  /* Handling opening of the create page */
  const handleCreate = useEvent(() => {
    navigate(createPath)
  })

  /* Handling opening of the show page */
  const handleOpen = useEvent(async (e) => {
    // Check if the clicked element or any ancestor has a class indicating it's part of the modal
    const isModalClick = e.target.closest('.ant-modal-content')

    // If it's a modal click, do nothing and return
    if (isModalClick) return
    const isCardClick = e.target.tagName !== 'path'
    const pathParams = { productId }
    const computePath = isRentee ? showPath : renterShowPath
    const path = generatePath(computePath, pathParams)
    // Prevent handleOpen when rating component was clicked
    isCardClick && navigate(path)
    isRentee && (await updateUserViewsStatistics(product))
  })

  /* Handling opening of the edit page or change view to edit mode */
  const handleEdit = useEvent(() => {
    const pathParams = { productId }
    const path = generatePath(editPath, pathParams)
    navigate(path)
  })

  const handleUnpublish = useEvent(async () => {
    try {
      await update(COLLECTIONS.PRODUCTS, productId, {
        publishDate: null,
        status: PRODUCT_STATUSES.DRAFT
      })
      message.success(t('Product successfully unpublished'))
    } catch (error) {
      message.error(t('Error during unpublish product'), error.message)
    }
  })

  const handlePublish = useEvent(async () => {
    try {
      await update(COLLECTIONS.PRODUCTS, productId, {
        publishDate: moment().toISOString(),
        status: PRODUCT_STATUSES.PUBLISHED
      })
      message.success(t('Product successfully published'))
    } catch (error) {
      message.error(t('Error during publish product'), error.message)
    }
  })

  return useMemo(
    () => ({
      onBack: handleBack,
      onCreate: handleCreate,
      onOpen: handleOpen,
      onEdit: handleEdit,
      onUnpublish: handleUnpublish,
      onPublish: handlePublish,
      ...(actions || {}),
      createPath,
      editPath,
      showPath
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      actions?.onBack,
      actions?.onCreate,
      actions?.onDelete,
      actions?.onEdit,
      actions?.onOpen,
      handleBack,
      handleCreate,
      handleEdit,
      handleOpen,
      handleUnpublish
    ]
  )
}

export default useProductBaseActions
