import { ENDPOINTS } from '__constants__/environment'
import { ORDER_STATUSES } from '__constants__'
import { fetchApi } from 'services/api/rest'
import { message } from 'antd'
import moment from 'moment'
import { useAppNotifications } from 'contexts/AppNotificationsContext'
import { useTranslations } from 'hooks'
const useOrderActions = (loadingCallback) => {
  const { sendErrorNotification } = useAppNotifications()
  const { t } = useTranslations()

  const notificationParams = { style: { top: 0 } }

  const utc = moment().format('Z')

  // for rentee
  const cancelOrder = (orderId) => async (e) => {
    e.stopPropagation()
    try {
      loadingCallback?.({ type: ORDER_STATUSES.CANCELED, loading: true })

      const fetchObj = {
        path: ENDPOINTS.UPDATE_ORDER_STATUS,
        method: 'POST',
        body: {
          _id: orderId ?? null,
          status: ORDER_STATUSES.CANCELED
        }
      }

      const [response, responseData] = await fetchApi(fetchObj)

      if (response?.ok) {
        message.success('Order successfully canceled')
      } else {
        const { description } = responseData
        throw Error(description)
      }
    } catch (error) {
      sendErrorNotification(
        {
          message: t('Error during cancel order'),
          description: error.message
        },
        notificationParams
      )
    } finally {
      loadingCallback?.({ type: ORDER_STATUSES.CANCELED, loading: false })
    }
  }

  // for renter
  const acceptOrder = (orderId) => async (e) => {
    e.stopPropagation()
    try {
      loadingCallback?.({ type: ORDER_STATUSES.ACCEPTED, loading: true })
      const fetchObj = {
        path: ENDPOINTS.UPDATE_ORDER_STATUS,
        method: 'POST',
        body: {
          _id: orderId ?? null,
          status: ORDER_STATUSES.ACCEPTED,
          utc
        }
      }

      const [response, responseData] = await fetchApi(fetchObj)

      if (response?.ok) {
        message.success(t('Order successfully approved'))
      } else {
        const { description } = responseData
        throw Error(description)
      }
    } catch (error) {
      sendErrorNotification(
        {
          message: t('Error during accept order'),
          description: error.message
        },
        notificationParams
      )
    } finally {
      loadingCallback?.({ type: ORDER_STATUSES.ACCEPTED, loading: false })
    }
  }

  const declineOrder = (orderId) => async (e) => {
    e.stopPropagation()
    try {
      loadingCallback?.({ type: ORDER_STATUSES.DECLINED, loading: true })

      const fetchObj = {
        path: ENDPOINTS.UPDATE_ORDER_STATUS,
        method: 'POST',
        body: {
          status: ORDER_STATUSES.DECLINED,
          _id: orderId
        }
      }

      const [response, responseData] = await fetchApi(fetchObj)

      if (response?.ok) {
        message.success(t('Order successfully declined'))
      } else {
        const { description } = responseData
        throw Error(description)
      }
    } catch (error) {
      sendErrorNotification(
        {
          message: t('Error during decline order'),
          description: error.message
        },
        notificationParams
      )
    } finally {
      loadingCallback?.({ type: ORDER_STATUSES.DECLINED, loading: false })
    }
  }
  return { cancelOrder, acceptOrder, declineOrder }
}

export default useOrderActions
