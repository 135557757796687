import { Col, Divider, Row, Typography } from 'antd'

import PropTypes from 'prop-types'

const SettingsHeader = (props) => {
  const {
    title,
    titleSize = 1,
    description,
    divider,
    actions,
    breadcrumbs,
    withoutHeader
  } = props

  if (withoutHeader) return null

  return (
    <div className="mb-24">
      {breadcrumbs && (
        <Row>
          <Col span={24}>
            <div className="mb-12">{breadcrumbs}</div>
          </Col>
        </Row>
      )}
      <Row align="center">
        <Col flex="auto">
          {title && (
            <Typography.Title
              level={titleSize}
              className={description && 'mb-8'}>
              {title}
            </Typography.Title>
          )}
          {description && <Typography.Text>{description}</Typography.Text>}
          {divider && <Divider className="mb-0 mt-8" />}
        </Col>
        <Col className="flex align-center justify-end flex-auto ml-16">
          {actions}
        </Col>
      </Row>
    </div>
  )
}

SettingsHeader.propTypes = {
  title: PropTypes.string,
  actions: PropTypes.node,
  titleSize: PropTypes.number,
  breadcrumbs: PropTypes.node,
  withoutHeader: PropTypes.bool,
  divider: PropTypes.bool,
  description: PropTypes.string
}

export default SettingsHeader
