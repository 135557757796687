import {
  CustomerTableActions,
  CustomerTableSimpleView
} from 'domains/Customer/components'
import { useGetTableSearchProps, useScreen, useTranslations } from 'hooks'

import { Typography } from 'antd'
import { sortByAlphabet } from 'helpers'
import { useMemo } from 'react'

const useGetCustomersTableConfig = () => {
  const { t } = useTranslations()
  const { isSmallScreen } = useScreen()
  const getColumnSearchProps = useGetTableSearchProps()

  const columns = useMemo(
    () => [
      {
        width: isSmallScreen ? '200px' : '300px',
        title: t('Name'),
        dataIndex: 'fullName',
        key: 'name',
        fixed: 'left',
        sorter: (a, b) => sortByAlphabet(a?.fullName, b?.fullName),
        ...getColumnSearchProps('fullName', ['fullName']),

        render: (_, record) => <CustomerTableSimpleView customer={record} />
      },

      {
        title: t('Email'),
        dataIndex: 'email',
        key: 'email',
        sorter: (a, b) => sortByAlphabet(a?.email, b?.email),
        ...getColumnSearchProps('email', ['email']),

        render: (_, record) => (
          <Typography.Text>{record?.email || 'n/a'}</Typography.Text>
        )
      },
      {
        title: t('Phone'),
        dataIndex: 'phone',
        key: 'phone',
        sorter: (a, b) => sortByAlphabet(a?.phone, b?.phone),
        ...getColumnSearchProps('phone', ['phone']),

        render: (_, record) => (
          <Typography.Text>{record?.phone || 'n/a'}</Typography.Text>
        )
      },
      {
        width: '160px',
        title: t('Actions'),
        dataIndex: 'actions',
        key: 'actions',
        align: 'center',
        render: (text, record) => <CustomerTableActions user={record} />
      }
    ],
    [t, getColumnSearchProps, isSmallScreen]
  )

  const paginationStyles = {
    position: ['bottomCenter'],
    pageSize: 8,
    hideOnSinglePage: true
  }
  const tableProps = {
    scroll: { x: 1000 }
  }

  return {
    columns,
    paginationStyles,
    tableProps
  }
}

export default useGetCustomersTableConfig
