import * as firebaseui from 'firebaseui'

import { Col, Divider, Row, Space, Typography } from 'antd'
import { useCallback, useMemo } from 'react'
import { useHandleError, useTranslations } from 'hooks'

import { AUTH_PATHS } from 'modules/session-module/__constants__'
import { LoginForm } from 'modules/session-module/domains/Session/components'
import { PATHS } from '__constants__'
import PropTypes from 'prop-types'
import StyledFirebaseAuth from './StyledFirebaseAuth'
import VippsAuth from './VippsAuth'
import firebase from 'firebase/compat/app'
import { useNavigate } from 'react-router-dom'
import { useSessionActions } from 'modules/session-module/hooks'

const AuthMethods = (props) => {
  const { authSwitchText, authSwitchLinkText, authSwitchPath } = props
  const { t } = useTranslations()
  const onError = useHandleError()

  const { login } = useSessionActions()

  const forgotPassword = () => navigate(`../${AUTH_PATHS.FORGOT_PASSWORD}`)

  const navigate = useNavigate()

  // DO NOT DELETE
  const uiConfig = useMemo(
    () => ({
      signInFlow: 'popup',
      signInOptions: [
        {
          provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          customParameters: { prompt: 'select_account' },
          clientId: process.env.REACT_APP_OAUTH_CLIENT_ID
        }
      ],
      callbacks: {
        // Avoid redirects after sign-in.
        signInSuccessWithAuthResult: () => false
      },
      credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO
      // queryParameterForWidgetMode: 'mode'
    }),
    []
  )

  const redirect = () => navigate(authSwitchPath)

  const onSuccess = () => {
    // !user?.isVerified && navigate(`/auth/${AUTH_PATHS.CONFIRM_EMAIL}`)
    navigate(PATHS.CONFIG.AFTER_SIGNUP)
  }

  const uiCallback = useCallback((ui) => ui.disableAutoSignIn(), [])

  const firebaseAuthProps = useMemo(
    () => ({ uiCallback, uiConfig, firebaseAuth: firebase.auth() }),
    [uiCallback, uiConfig]
  )
  return (
    <>
      <Row gutter={[24, 0]} justify="center">
        <Col flex="none">
          <StyledFirebaseAuth {...firebaseAuthProps} />
        </Col>
        <Col flex="none">
          <VippsAuth />
        </Col>
      </Row>
      <Divider plain>{t('OR')}</Divider>
      <LoginForm
        login={login}
        onError={onError}
        onSuccess={onSuccess}
        onForgotPasswordClick={forgotPassword}
      />

      <Space align="center" className="mt-24 flex justify-center">
        <Typography.Text type="secondary">{authSwitchText}</Typography.Text>
        <Typography.Link onClick={redirect}>
          {authSwitchLinkText}
        </Typography.Link>
      </Space>
    </>
  )
}

AuthMethods.propTypes = {
  authSwitchLinkText: PropTypes.any,
  authSwitchPath: PropTypes.any,
  authSwitchText: PropTypes.any
}

export default AuthMethods
