import { getAuth, unlink } from 'firebase/auth'

import { useAppNotifications } from 'contexts'

const useRemoveUserProvider = () => {
  const { sendErrorNotification } = useAppNotifications()

  return async () => {
    try {
      const auth = getAuth()
      await unlink(auth?.currentUser, 'phone')
    } catch (error) {
      sendErrorNotification({
        message: 'Error removing provider:',
        descriptioon: error?.message
      })
    }
  }
}

export default useRemoveUserProvider
