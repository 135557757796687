import { Card, Typography } from 'antd'

import styled from 'styled-components'

const CardStyled = styled(Card)`
  .ant-card-body {
    padding: 12px 16px;
  }
`
const TextStyled = styled(Typography.Text)`
  font-weight: ${({ fontWeight }) => fontWeight};
`

const styles = {
  fieldTitleProps: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '250px',
    whiteSpace: 'nowrap'
  },
  fieldRowProps: {
    justifyContent: 'center',
    alignItems: 'center',
    py: '4px',
    mb: 2
  },
  imageWrapper: {
    style: { width: '80px' },
    className: 'flex justify-center align-center'
  }
}

export { CardStyled, styles, TextStyled }
