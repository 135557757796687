import { useEffect, useState } from 'react'

import { COLLECTIONS, ORDER_STATUSES } from '__constants__'
import firebase from 'firebase/compat/app'

/**
 * Custom React hook that checks if there are any unresolved orders for a given product ID.
 * @param {string} productId - The ID of the product to check for unresolved orders.
 * @returns {boolean|null} - Returns a boolean value indicating whether there are any unresolved orders for the given product ID. Returns null if the check has not yet completed.
 */
const useGetUnresolvedOrderByProductId = (productId) => {
  const [isExistUnresolvedOrder, setIsExistUnresolvedOrder] = useState(null)

  useEffect(() => {
    const checkProductCompliance = async () => {
      const snap = await firebase
        .firestore()
        .collection(COLLECTIONS.ORDERS)
        .where('productId', '==', productId)
        .get()
      const orders = snap.docs.map((doc) => doc.data())
      const unresolvedOrders = orders.filter(
        (item) =>
          item.status === ORDER_STATUSES.IN_PROGRESS ||
          (item.status === ORDER_STATUSES.ACCEPTED &&
            item.paymentStatus !== 'CANCELED')
      )

      setIsExistUnresolvedOrder(unresolvedOrders?.length > 0)
    }
    checkProductCompliance()
  }, [productId])

  return isExistUnresolvedOrder
}

export default useGetUnresolvedOrderByProductId
