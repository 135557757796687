import PropTypes from 'prop-types'
import { Typography } from 'antd'
import { memo } from 'react'
import { useGetBrand } from 'domains/Brand/hooks'

const ProductBrand = (props) => {
  const { brandId, ...rest } = props

  const [brand] = useGetBrand(brandId)

  return <Typography.Text {...rest}>{brand?.name}</Typography.Text>
}

ProductBrand.propTypes = {
  brandId: PropTypes.string
}

export default memo(ProductBrand)
