import { useMemo } from 'react'

const useCheckProductCompletionForPublish = (product) => {
  const name = product?.name
  const addressId = product?.addressId
  const pricePerDay = product?.pricePerDay
  const mediaObjects = product?.mediaObjects
  const cancellationTerms = product?.cancellationTerms

  return useMemo(
    () =>
      !!name &&
      !!pricePerDay &&
      mediaObjects?.length &&
      !!cancellationTerms &&
      !!addressId,
    [name, addressId, cancellationTerms, pricePerDay, mediaObjects?.length]
  )
}

export default useCheckProductCompletionForPublish
