/* eslint-disable no-console */
import AppNotificationsContext from './AppNotificationsContext'
import PropTypes from 'prop-types'
import { notification } from 'antd'

const notificationParams = { style: { top: '64px', right: 0 }, duration: 6 }

const AppNotificationsProvider = ({ children }) => {
  const sendErrorNotification = (error = {}, params) => {
    notification.error({ ...error, ...notificationParams, ...params })
    console.error(error)
  }

  const sendSuccessNotification = (message = {}, params) => {
    notification.success({ ...message, ...notificationParams, ...params })
  }

  return (
    <AppNotificationsContext.Provider
      value={{ sendErrorNotification, sendSuccessNotification }}>
      {children}
    </AppNotificationsContext.Provider>
  )
}

AppNotificationsProvider.propTypes = {
  children: PropTypes.element.isRequired,
  params: PropTypes.object
}

export default AppNotificationsProvider
