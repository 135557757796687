import { COLLECTIONS } from '__constants__/models'
import ILLUSTRATION_NO_MESSAGES from '__assets__/illustrations/ill-no-messages.svg'

export const CHAT_CONFIG = {
  constants: {
    // VIDEO_ARCHIVE: VIDEOS_MODEL_NAME,
    USERS: COLLECTIONS.USERS
  },
  assets: { noDataImage: ILLUSTRATION_NO_MESSAGES },
  functionality: { scheduleMessages: true, videoArchive: true }
}
