import { Card } from 'antd'
import styled from 'styled-components'
import { Icon } from '@qonsoll/icons'

const StyledCard = styled(Card)`
  .ant-card-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: ${({ md }) => (md ? '24px' : '16px')};
  }
`
const IconWrapper = styled.div`
  border-radius: 50%;
  width: 42px;
  height: 42px;
  background: ${({ bg }) => bg};
  display: flex;
  justify-content: center;
  align-items: center;
`

const InfoIconStyled = styled(Icon)`
  position: absolute;
  right: ${({ md }) => (md ? '24px' : '16px')};
  top: ${({ md }) => (md ? '24px' : '16px')};
  cursor: pointer;
`
export { IconWrapper, StyledCard, InfoIconStyled }
