import { COMPLAINT_STATUSES } from '__constants__'
import PropTypes from 'prop-types'
import { StatusDot } from './StatusDot.styled'
import { theme } from 'antd'

const StatusDotView = (props) => {
  const { status } = props
  const {
    colorWarning,
    colorSuccess,
    colorPrimary,
    colorError,
    colorTextDisabled
  } = theme.useToken().token

  const statusColors = {
    [COMPLAINT_STATUSES.PENDING]: colorWarning,
    [COMPLAINT_STATUSES.ON_REVIEW]: colorPrimary,

    [COMPLAINT_STATUSES.RESOLVED]: colorSuccess,
    [COMPLAINT_STATUSES.REJECTED]: colorError,
    [COMPLAINT_STATUSES.CLOSED]: colorTextDisabled
  }

  const statusColorComputed =
    statusColors[status] || statusColors[COMPLAINT_STATUSES.IN_PROGRESS]
  return <StatusDot {...props} statusColor={statusColorComputed} />
}

StatusDotView.propTypes = {
  status: PropTypes.string
}

export default StatusDotView
