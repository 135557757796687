import { Button, Tooltip } from 'antd'

import { APP_PATHS } from '__constants__/paths'
import { Icon } from '@qonsoll/icons'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { useTranslations } from 'hooks'
import { useUser } from 'modules/session-module/contexts'

const CreateRentButton = (props) => {
  const { collapsed } = props
  const { t } = useTranslations()
  const navigate = useNavigate()
  const text = t('Rent out product')
  const { isRentee, setIsRentee } = useUser()

  const computedTooltipText = collapsed && text
  const computedButtonText = !collapsed && text

  const handleClick = () => {
    if (isRentee) setIsRentee(false)
    navigate(APP_PATHS.PRODUCT_CREATE)
  }

  return (
    <Tooltip title={computedTooltipText} placement="right">
      <Button
        type="primary"
        onClick={handleClick}
        icon={<Icon name="Plus1Outlined" size={16} fill="currentColor" />}
        className="flex justify-center align-center"
        block={!collapsed}>
        {computedButtonText}
      </Button>
    </Tooltip>
  )
}

CreateRentButton.propTypes = {
  collapsed: PropTypes.bool
}

export default CreateRentButton
