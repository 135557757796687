import { useLoadScript } from '@react-google-maps/api'

const REACT_APP_GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY

const scriptData = {
  language: 'en',
  googleMapsApiKey: REACT_APP_GOOGLE_MAP_API_KEY,
  libraries: ['places']
}

const useInitializeMap = () => {
  // isLoaded, ...
  const result = useLoadScript(scriptData)

  return result
}

export default useInitializeMap
