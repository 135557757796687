import { ENDPOINTS } from 'modules/stripe-module/__constants__'

const API_URL = process.env.REACT_APP_FIREBASE_FUNCTIONS_API_URL

const createStripeAccount = async (data) => {
  try {
    const apiEndpoint = `${API_URL}/${ENDPOINTS.CREATE_STRIPE_ACCOUNT}`

    const result = await fetch(apiEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        origin: 'lentlimobilebackend'
      },
      body: JSON.stringify(data)
    })

    if (result?.ok) {
      return 'ok'
    } else {
      const response = await result?.json()
      throw new Error(response?.error)
    }
  } catch (error) {
    throw new Error(`Error during creating an account: ${error}`)
  }
}

export default createStripeAccount
