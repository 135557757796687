import { PageHeaderSimple, PageLayout, Spinner } from 'components'
import {
  useActionsCheckoutAdvancedForm,
  useInitializeCheckoutAdvancedForm
} from 'domains/Checkout/components/CheckoutAdvancedForm/hooks'
import { useNavigate, useParams } from 'react-router-dom'

import { APP_PATHS } from '__constants__'
import { CheckoutAdvancedFormWithPreview } from 'domains/Checkout/components/CheckoutAdvancedForm/components'
import { CheckoutFormActions } from 'domains/Checkout/components'
import { useGetCheckoutInitialValues } from 'domains/Checkout/hooks'
import { useTranslations } from 'hooks'
import { validatePressTarget } from 'domains/Product/helpers'

const Checkout = () => {
  const { t } = useTranslations()
  const params = useParams()
  const navigate = useNavigate()

  const { checkoutId } = params
  const headingProps = {
    title: t('Request to book'),
    textAlign: 'left'
  }
  const [cartItem, productInitialValues, loading] =
    useGetCheckoutInitialValues(checkoutId)
  const { form } = useInitializeCheckoutAdvancedForm(
    productInitialValues,
    cartItem,
    loading
  )
  const {
    loading: submitLoading,
    onFinish,
    onReset
  } = useActionsCheckoutAdvancedForm({
    form,
    initialData: productInitialValues,
    cartItem
  })

  const handleEnterPress = (e) => {
    form.validateFields()
    const isIncorrectTarget = validatePressTarget(e)
    if (isIncorrectTarget) return
    form.submit()
  }
  const onBack = () => {
    navigate(APP_PATHS.DASHBOARD)
  }

  const horizontalOffsets = { xs: 0, sm: 0, md: 0, lg: 80, xl: 114, xxl: 148 }
  return (
    <PageLayout
      horizontalOffsets={horizontalOffsets}
      headingProps={headingProps}
      topHeader={<PageHeaderSimple onlyLogo onBack={onBack} />}
      area="checkout"
      actions={
        <CheckoutFormActions
          submitLoading={submitLoading}
          onReset={onReset}
          form={form}
        />
      }>
      {loading ? (
        <Spinner height="70vh" text={t('Product loading')} />
      ) : (
        <CheckoutAdvancedFormWithPreview
          cartItem={cartItem}
          initialValues={productInitialValues}
          handleEnterPress={handleEnterPress}
          onFinish={onFinish}
          form={form}
        />
      )}
    </PageLayout>
  )
}

export default Checkout
