const API_URL_LOCALHOST = 'http://localhost:5001/lentli-dev/us-central1'
const API_URL = process.env.REACT_APP_FIREBASE_FUNCTIONS_API_URL
/**
 * It takes a path, method, headers, and body, and returns a promise that resolves to the response data
 * @param path - The path to the API endpoint.
 * @param method - The HTTP method to use.
 * @param [headers] - { 'Content-Type': contentType, ...restHeaders }
 * @param body - The body of the request.
 * @returns The response data from the fetch request.
 */
const fetchApi = async ({
  customPath,
  path,
  method,
  headers = {},
  body,
  localhost
} = {}) => {
  const { contentType = 'application/json', ...restHeaders } = headers

  const computedApiUrl = localhost ? API_URL_LOCALHOST : API_URL
  const apiPath = customPath ? customPath : `${computedApiUrl}/${path}`
  const options = {
    method,
    headers: { 'Content-Type': contentType, ...restHeaders }
  }
  if (body) options.body = JSON.stringify(body)

  const response = await fetch(apiPath, options)
  const responseData = await response.json()

  return [response, responseData]
}

export default fetchApi
