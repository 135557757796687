import { Button, Form, Input, Space, Typography, message } from 'antd'

import { AUTH_PATHS } from 'modules/session-module/__constants__'
import { Label } from 'modules/session-module/domains/Session/components'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { useTranslations } from 'hooks'

const ForgotPasswordForm = ({ resetPassword, onError }) => {
  const { t } = useTranslations()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)

  const onFinish = (credentials) => {
    /* 
      The `resetPassword` function is called with the user's email address.
      The `onError` function is called if the request fails.
      The `message.success` function is called to display a success message.
      The `setLoading` function is called to set the loading state to false.
      The `history.push` function is called to redirect the user to the login page.
    */
    setLoading(true)
    resetPassword({ email: credentials.email, onError })
    message.success(t('Reset password was sent to your email'))
    setLoading(false)
    navigate(`../${AUTH_PATHS.LOGIN}`)
  }

  const toLoginPage = () => navigate(`../${AUTH_PATHS.LOGIN}`)

  const rules = [
    {
      type: 'email',
      required: true,
      message: t('Please enter valid email')
    }
  ]

  return (
    <>
      <Form onFinish={onFinish} layout="vertical" requiredMark="optional">
        <Form.Item
          label={Label({ value: 'Email', htmlFor: 'email' })}
          name="email"
          rules={rules}>
          <Input
            autoFocus
            placeholder="john.doe@example.com"
            textAlign="center"
          />
        </Form.Item>
        <Button type="primary" block htmlType="submit" loading={loading}>
          {t('Restore Password')}
        </Button>
      </Form>
      <Space align="center" className="mt-24 flex justify-center">
        <Typography.Text type="secondary">{t('Back to')}</Typography.Text>
        <Typography.Link onClick={toLoginPage}>{t('Login')}</Typography.Link>
      </Space>
    </>
  )
}

ForgotPasswordForm.propTypes = {
  form: PropTypes.object,
  onError: PropTypes.any.isRequired,
  onFinish: PropTypes.func,
  onFinishFailed: PropTypes.func,
  resetPassword: PropTypes.func.isRequired
}
export default ForgotPasswordForm
