/**
 * Calculates the percentage growth between two values.
 *
 * @param {number} previousValue - The previous value.
 * @param {number} currentValue - The current value.
 * @returns {string} The percentage growth formatted as a string with a percentage sign.
 */
const calculatePercentageGrowth = (previousValue, currentValue) => {
  // If the previous value is 0, avoid division by zero
  if (previousValue === 0) {
    // If the current value is also 0, return 0%
    if (currentValue === 0) {
      return '0%'
    } else {
      // If the current value is not 0, return the percentage compared to 0
      return '100%'
    }
  }

  // Calculate the percentage growth
  const percentageGrowth =
    ((currentValue - previousValue) / Math.abs(previousValue)) * 100

  // Format the result without decimal places if it's an integer, otherwise, show one decimal place
  const formattedResult = Number.isInteger(percentageGrowth)
    ? percentageGrowth.toFixed(0)
    : percentageGrowth.toFixed(1)

  // Concatenate the percentage sign
  return formattedResult + '%'
}

export default calculatePercentageGrowth
