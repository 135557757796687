const REFUND_FAILURE_REASONS = {
  charge_for_pending_refund_disputed:
    'A customer disputed the charge while the refund is pending',
  declined: 'Refund declined by our financial partners',
  expired_or_canceled_card:
    'Payment method is canceled by a customer or expired by the partner',
  insufficient_funds:
    'Refund is pending due to insufficient funds and has crossed the pending refund expiry window',
  lost_or_stolen_card:
    'Refund has failed due to loss or theft of the original card',
  merchant_request: "Refund failed upon the business's request",
  unknown: 'Refund has failed due to an unknown reason'
}

export { REFUND_FAILURE_REASONS }
