import styled from 'styled-components'

const ContentWrapper = styled.div`
  max-height: calc(100vh - 148px);
  width: 400px;
  overflow-y: scroll;
  box-sizing: border-box;
`

export { ContentWrapper }
