import { useMemo, useState } from 'react'

const useOrderFilter = (sortedOrders) => {
  const [filterStatus, setFilterStatus] = useState(null)

  const onFilterStatusChange = (value) => setFilterStatus(value)

  const filteredOrderResult = useMemo(
    () =>
      filterStatus
        ? sortedOrders?.filter((order) => {
            const filteredFieldsWithValues = Object.entries(filterStatus)
            return filteredFieldsWithValues.some(
              (entry) =>
                entry[1]?.length === 0 || entry[1]?.includes(order[entry[0]])
            )
          })
        : sortedOrders,
    [filterStatus, sortedOrders]
  )

  return {
    filteredOrders: filteredOrderResult,
    onFilterStatusChange,
    filterStatus
  }
}

export default useOrderFilter
