import { generatePath, useNavigate } from 'react-router-dom'
import { useEffect, useMemo, useState } from 'react'
import { useFilterCategories, useGetCategories } from 'domains/Category/hooks'

import { PATHS } from '__constants__'
import _ from 'lodash'

const useGetGroupedCategories = ({ handleCloseDrawer }) => {
  const [allCategories, loadingAllCategories] = useGetCategories()

  const [, filteredCategoriesLoading, topLevelCategories, subcategories] =
    useFilterCategories(allCategories)

  const computedLoadings = useMemo(
    () => filteredCategoriesLoading || loadingAllCategories,
    [filteredCategoriesLoading, loadingAllCategories]
  )

  const navigate = useNavigate()

  const [subcategoriesView, setSubcategoriesView] = useState(null)
  const [isRoot, setIsRoot] = useState(true)
  const [selectedCategory, setSelectedCategory] = useState(null)

  const handleSelectCategory = (category) => {
    setSelectedCategory(category)
    setIsRoot(false)
  }
  const handleGoBack = () => {
    setIsRoot(true)
  }

  const handleSelectSubcategory = (id) => {
    const path = generatePath(PATHS.AUTHENTICATED.PRODUCTS_ALL_BY_CATEGORY, {
      categoryId: id
    })
    navigate(path)
    setIsRoot(true)
    handleCloseDrawer?.()
  }

  const groupedByParentId = useMemo(
    () => !computedLoadings && _.groupBy(subcategories, 'parentId'),
    [computedLoadings, subcategories]
  )

  useEffect(() => {
    let isMounted = true
    if (
      isMounted &&
      selectedCategory &&
      groupedByParentId &&
      Object.keys(groupedByParentId)?.length
    ) {
      const selectedCategoryId = selectedCategory?._id ?? null

      setSubcategoriesView(() => groupedByParentId?.[selectedCategoryId])
    }

    return () => (isMounted = false)
  }, [selectedCategory, groupedByParentId])

  return {
    topLevelCategories,
    subcategories: subcategoriesView,
    groupedByParentId,
    loading: computedLoadings,
    handleSelectCategory,
    handleSelectSubcategory,
    isRoot,
    selectedCategory,
    handleGoBack
  }
}
export default useGetGroupedCategories
