import { AVAILABLE_IMAGE_FORMATS } from '__constants__'
import { Form } from 'antd'
import { ProductUploadItem } from '../'
import PropTypes from 'prop-types'
import { UploadMediaWithContext } from 'components'

const ProductMediaForm = (props) => {
  const { initialValues, form } = props

  const mediaObjectsPath = `/mediaObjects/product/${initialValues?.product?._id}`

  return (
    <Form.Item preserve name={['product', 'mediaObjects']} className="mb-0">
      <UploadMediaWithContext
        accept={AVAILABLE_IMAGE_FORMATS}
        maxCount={100}
        path={mediaObjectsPath}
        listType={'picture-card'}
        itemRender={ProductUploadItem}
        form={form}
      />
    </Form.Item>
  )
}

ProductMediaForm.propTypes = {
  initialValues: PropTypes.object,
  form: PropTypes.object
}

export default ProductMediaForm
