import { ButtonStyled } from './Auth.styled'
import { Icon } from '@qonsoll/icons'
import { useAuthBaseActions } from './hooks'

const VippsAuth = () => {
  const { onVippsLogin } = useAuthBaseActions()

  return (
    <ButtonStyled onClick={onVippsLogin} className="py-8 px-16 ">
      <Icon name="VippsOutlined" size={24} />
    </ButtonStyled>
  )
}

export default VippsAuth
