import styled from 'styled-components'

const PreviewContainer = styled.div`
  & .overlay {
    background: transparent;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    opacity: 0;
    transition: all 0.66s ease;
    border-radius: 10px;
  }
  width: 100%;
  height: 100%;
  &:hover .overlay {
    background: rgba(144, 142, 142, 0.69);
    opacity: 1;
  }
`

export { PreviewContainer }
