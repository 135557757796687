import { useMemo } from 'react'

const useGetUserProductsRating = (products, loading) => {
  const ratings = useMemo(
    () =>
      !loading &&
      products
        ?.filter((product) => product?.avgRating)
        ?.map((product) => product?.avgRating),
    [loading, products]
  )

  const rating = useMemo(
    () =>
      (ratings &&
        !loading &&
        ratings?.reduce((res, rating) => {
          return (res += rating)
        }, 0) / ratings?.length) ||
      0,
    [loading, ratings]
  )

  return rating
}

export default useGetUserProductsRating
