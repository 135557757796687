import { Row } from 'antd'
import { SubcategoriesItem } from 'domains/Category/components/CategoriesDrawer/components'
import PropTypes from 'prop-types'

const SubcategoriesList = (props) => {
  const { subcategories, groupedByParentId, handleSelectSubcategory } = props

  return (
    <Row>
      {subcategories?.map(({ name, _id, names }) => {
        return (
          <SubcategoriesItem
            key={_id}
            name={name}
            names={names}
            groupedByParentId={groupedByParentId}
            _id={_id}
            handleSelectSubcategory={handleSelectSubcategory}
          />
        )
      })}
    </Row>
  )
}

SubcategoriesList.propTypes = {
  subcategories: PropTypes.array,
  groupedByParentId: PropTypes.object,
  handleSelectSubcategory: PropTypes.func
}

export default SubcategoriesList
