import { PageLayout } from 'components'
import { Tabs } from 'antd'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useStateWithStorage } from 'hooks'
import { useTranslations } from 'hooks'

const Statistics = () => {
  // [ADDITIONAL_HOOKS]
  const [lastUsedTab, setTab] = useStateWithStorage(null, `statistics-tab`)
  const { t } = useTranslations()
  const navigate = useNavigate()

  // [HANDLERS]
  const onChangeTab = (key) => {
    navigate(key)
    setTab(key)
  }

  // [COMPUTED_PROPERTIES]
  const { TabPane } = Tabs

  const tabsData = useMemo(() => [], [])
  const headingProps = { title: t('Statistics') }

  return (
    <PageLayout headingProps={headingProps}>
      <Tabs defaultActiveKey={lastUsedTab} onChange={onChangeTab}>
        {tabsData.map(({ title, key }) => (
          <TabPane tab={title} key={key} />
        ))}
      </Tabs>
    </PageLayout>
  )
}

export default Statistics
