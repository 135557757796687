import { collection, orderBy, query, where } from 'firebase/firestore'

import { COLLECTIONS } from '__constants__'
import { firestore } from 'services/firebase'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { useMemo } from 'react'

/**
 * It returns an array of values that are used to render the tags
 * @param props - An object that contains the query parameters.
 * @returns An array of values.
 */
const useGetTags = (props) => {
  const computedWhere = useMemo(
    () =>
      props?.where
        ? props?.where
            ?.map(
              ([field, operand, value]) =>
                field && operand && value && where(field, operand, value)
            )
            ?.filter(Boolean)
        : [],
    [props?.where]
  )

  const computedOrderBy = useMemo(
    () =>
      props?.orderBy
        ? props?.orderBy
            ?.map(([field, type]) => field && type && orderBy(field, type))
            ?.filter(Boolean)
        : [],
    [props?.orderBy]
  )
  /* Query for getting collection reference */
  const querySnapshot = useMemo(
    () =>
      query(
        collection(firestore, COLLECTIONS.TAGS),
        ...computedWhere,
        ...computedOrderBy
      ),
    [computedOrderBy, computedWhere]
  )

  /* Getting collection data */
  return useCollectionData(querySnapshot)
}

export default useGetTags
