import { Col, Divider, Typography } from 'antd'
import { Fragment, useMemo } from 'react'

import { ProductCharacteristics } from 'domains/Product/components/ProductAdvancedView/components'
import { ReviewsList } from 'domains/Review/components'
import { TagList } from 'domains/Tag/components'
import { useTranslations } from 'hooks'

const { Title, Text } = Typography

const ProductExtendedInfo = (props) => {
  const { product, reviews, tags } = props

  const { t } = useTranslations()

  const showDescription = !!product?.description
  const showCharacteristics = !!product?.fields?.length
  const showTags = tags ? !!tags?.length : !!product?.tags?.length
  const showReviews = !!product?.reviews?.length || reviews?.length

  const productInfo = useMemo(
    () => [
      {
        isDisplay: showCharacteristics,
        title: t('Characteristics'),
        component: <ProductCharacteristics characteristic={product?.fields} />
      },
      {
        isDisplay: showDescription,
        title: null,
        component: <Text>{product?.description}</Text>
      },
      {
        isDisplay: showTags,
        title: t('Tags'),
        component: <TagList tags={tags || product?.tags} />
      },
      {
        isDisplay: showReviews,
        title: t('Reviews'),
        component: <ReviewsList reviews={reviews} />
      }
    ],
    [
      product?.description,
      product?.fields,
      product?.tags,
      reviews,
      showCharacteristics,
      showDescription,
      showReviews,
      showTags,
      t,
      tags
    ]
  )
  return productInfo?.map((item) =>
    item.isDisplay ? (
      <Fragment>
        <Col span={24}>
          <Col span={24} className="mb-8">
            <Title level={5}>{item.title}</Title>
          </Col>
        </Col>
        <Col span={24}>{item.component}</Col>
        <Col span={24}>
          <Divider />
        </Col>
      </Fragment>
    ) : null
  )
}

export default ProductExtendedInfo
