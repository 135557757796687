import {
  SelectedMessageProvider,
  useChatFetch,
  useChatsContext
} from '@qonsoll/lentli-chat-core'
import { Suspense, lazy } from 'react'

import { Box } from 'components'
import { ChatHeader } from 'modules/chat-module/domains/Chat/components'
import { ChatLoading } from 'modules/chat-module/domains/Chat/components'
import { Divider } from 'antd'
import { Icon } from '@qonsoll/icons'
import PropTypes from 'prop-types'
import { theme } from 'antd'

const ChatMessageSimpleForm = lazy(() =>
  import('../../../MessageForm/components/MessageSimpleForm')
)
const MessageList = lazy(() =>
  import(
    'modules/chat-module/domains/MessageList/components/MessageListWrapper'
  )
)

const Chat = (props) => {
  const { height = 600, setOppositeUserId } = props
  const chat = useChatsContext()
  const chatLoading = useChatFetch()[1]
  const { colorPrimary } = theme.useToken().token

  const fallback = (
    <Icon name="LoadingFilled" spin mr="16px" size={24} fill={colorPrimary} />
  )

  return (
    <Box height={height}>
      <Box display="flex" flexDirection="column" height="100%">
        {chatLoading || chat?.oppositeUserDataLoading ? (
          <ChatLoading />
        ) : (
          <Suspense fallback={fallback}>
            <SelectedMessageProvider>
              <ChatHeader
                withBackButton
                setOppositeUserId={setOppositeUserId}
                withUser
                withWrapper={false}
              />
              <Divider className="my-8" />
              <MessageList />
              <Divider className="disquss-footer-divider my-8" />
              <Suspense fallback={fallback}>
                <ChatMessageSimpleForm chat={chat} />
              </Suspense>
            </SelectedMessageProvider>
          </Suspense>
        )}
      </Box>
    </Box>
  )
}

Chat.propTypes = {
  height: PropTypes.number,
  setOppositeUserId: PropTypes.func
}

export default Chat
