import { PageHeader } from 'components'
import PropTypes from 'prop-types'
import { useCurrentScreen } from 'hooks'

const PageLayout = (props) => {
  const {
    children,
    actions,
    headingProps,
    breadcrumbs,
    onBack,
    topHeader,
    horizontalOffsets,
    filter
  } = props

  const currScr = useCurrentScreen()

  return (
    <div
      className="flex flex-col flex-1"
      style={{
        paddingLeft: horizontalOffsets?.[currScr],
        paddingRight: horizontalOffsets?.[currScr]
      }}>
      <PageHeader
        topHeader={topHeader}
        onBack={onBack}
        actions={actions}
        {...headingProps}
        breadcrumbs={breadcrumbs}
        filter={filter}
      />
      {children}
    </div>
  )
}

PageLayout.propTypes = {
  children: PropTypes.node,
  actions: PropTypes.node,
  headingProps: PropTypes.object,
  breadcrumbs: PropTypes.node,
  onBack: PropTypes.func,
  topHeader: PropTypes.node,
  horizontalOffsets: PropTypes.object,
  filter: PropTypes.node
}

export default PageLayout
