import { useEffect, useState } from 'react'

import firebase from 'firebase/compat/app'

function useGetSubCategories(categoryId) {
  const [subCategories, setSubCategories] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (categoryId) {
      setLoading(true)
      const fetchCategories = firebase
        .firestore()
        .collection('categories')
        .where('parentId', '==', categoryId)
        .onSnapshot((snapshot) => {
          const fetchedSubCategories = snapshot.docs.map((doc) => ({
            ...doc.data(),
            _id: doc.id
          }))
          setSubCategories(fetchedSubCategories)
          setLoading(false)
        })

      // Cleanup function to unsubscribe from Firestore on unmount or when categoryId changes
      return () => fetchCategories()
    }
  }, [categoryId])

  return [subCategories, loading]
}
export default useGetSubCategories
