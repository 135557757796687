import { Button } from 'antd'
import PropTypes from 'prop-types'
import { useTranslations } from 'hooks'

const CheckoutFormActions = (props) => {
  const { submitLoading, form } = props
  const { t } = useTranslations()
  return (
    <Button
      loading={submitLoading}
      type="primary"
      onClick={() => form.submit()}>
      {t('Create order')}
    </Button>
  )
}

CheckoutFormActions.propTypes = {
  submitLoading: PropTypes.bool,
  onReset: PropTypes.func,
  form: PropTypes.object,
  isFormValid: PropTypes.bool
}

export default CheckoutFormActions
