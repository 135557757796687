import { Col, Row, Switch, Typography, theme } from 'antd'
import { useMutate, useScreen } from 'hooks'

import { COLLECTIONS } from '__constants__'
import { Fragment } from 'react'
import { Icon } from 'components'
import PropTypes from 'prop-types'
import { useMemo } from 'react'

const SettingsNotificationSimpleView = (props) => {
  const { notification, userId, initialValues } = props
  const { update } = useMutate()
  const { description, title, fieldName } = notification
  const { fontSizeSM } = theme.useToken().token

  const { xs } = useScreen()

  const handleSwitchState = async (state) =>
    await update(COLLECTIONS.USERS, userId, {
      [fieldName]: state
    })

  const computedDescription = useMemo(
    () =>
      xs ? null : (
        <Typography.Text style={{ fontSize: fontSizeSM }}>
          {description}
        </Typography.Text>
      ),
    [description, xs, fontSizeSM]
  )
  return (
    <Fragment>
      <Row align="middle" justify="space-between" gutter={[24, 0]} wrap={false}>
        <Col flex="auto" className="flex gap-16 align-center">
          <Icon name={notification?.iconName} size={24} />
          <div>
            <Typography.Title level={5}>{title}</Typography.Title>
            {computedDescription}
          </div>
        </Col>
        <Col flex="none">
          <Switch
            checked={initialValues?.[fieldName]}
            onChange={handleSwitchState}
          />
        </Col>
      </Row>
    </Fragment>
  )
}

SettingsNotificationSimpleView.propTypes = {
  notification: PropTypes.array,
  userId: PropTypes.string,
  initialValues: PropTypes.object
}

export default SettingsNotificationSimpleView
