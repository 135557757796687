import { PageHeaderSimple, PageLayout } from 'components'

import { APP_PATHS } from '__constants__/paths'
import { CheckoutSessionSuccess } from 'modules/stripe-module/domains/Checkout/components'
import { useNavigate } from 'react-router-dom'

const StripeCheckoutSuccessPage = () => {
  const navigate = useNavigate()

  const onBack = () => navigate(APP_PATHS.DASHBOARD)

  return (
    <PageLayout topHeader={<PageHeaderSimple onlyLogo onBack={onBack} />}>
      <CheckoutSessionSuccess />
    </PageLayout>
  )
}

export default StripeCheckoutSuccessPage
