import { Button, Input, Form, Typography } from 'antd'
import { useTranslations } from 'hooks'
import PropTypes from 'prop-types'
import {
  useInitializeConnectAccountForm,
  useActionsConnectAccountForm
} from 'modules/stripe-module/domains/Connect/hooks'
import FormWrapperStyled from './ConnectDetailsSimpleForm.styles'
import { Box } from 'components'
import { useAuthState } from 'react-firebase-hooks/auth'
import firebase from 'firebase/compat/app'
import { useUser } from 'modules/session-module/contexts'
import { useNavigate } from 'react-router-dom'
import { SETTINGS_PATHS } from '__constants__/paths'
import { Icon } from '@qonsoll/icons'
import { useGetAddress } from 'domains/Address/hooks'

const { Text } = Typography

const ConnectDetailsSimpleForm = (props) => {
  const { handleNextStep, isEdit, externalAccount, accountId, ...rest } = props

  const { t } = useTranslations()
  const { user: currentUser } = useUser()
  const [address, addressLoading] = useGetAddress(currentUser?.addressId)

  const [user] = useAuthState(firebase.auth())
  const navigate = useNavigate()

  const { form } = useInitializeConnectAccountForm()
  const { onFinish, loading } = useActionsConnectAccountForm({
    form,
    onFinishCallback: handleNextStep,
    address
  })

  const isAddress = !!currentUser?.addressId && !addressLoading && !!address

  const openCalculator = () => {
    window.open('https://www.iban.com/calculate-iban', '_blank')
  }
  const handleProfile = () => navigate(SETTINGS_PATHS.MY_PROFILE)
  const handleVerification = () => navigate(SETTINGS_PATHS.VERIFICATION)

  const isBirthDate = !!currentUser?.birthday
  const isLastName = !!currentUser?.lastName
  const isFirstName = !!currentUser?.firstName
  const isEmailVerified = !!user?.emailVerified
  const isPhoneVerified = !!user?.phoneNumber
  const isFullUserData =
    isBirthDate &&
    isLastName &&
    isFirstName &&
    isAddress &&
    isEmailVerified &&
    isPhoneVerified

  return (
    <FormWrapperStyled
      onFinish={onFinish}
      form={form}
      submitText={'Next'}
      spaceProps={{ className: 'flex full-width justify-end' }}
      submitLoading={loading || addressLoading}
      requiredMark={null}
      submitDisabled={!isFullUserData}
      {...rest}>
      <Form.Item
        data-area="IBAN"
        label={t('IBAN')}
        id="iban"
        tooltip={
          isEdit ? (
            <Text>{t("You can't edit IBAN after saving")}</Text>
          ) : (
            <Box>
              {t('If you do not know or cannot find your IBAN')}{' '}
              <Button onClick={openCalculator} type="link" className="p-0">
                {t('generate it')}
              </Button>{' '}
              {t('by your account ID')}.{' '}
              {t('Please, copy and past generated IBAN to the form')}.
            </Box>
          )
        }
        name="iban"
        rules={[{ required: !isEdit, message: t('Please enter IBAN!') }]}>
        {isEdit ? (
          <Text>{`***${externalAccount?.last4}`}</Text>
        ) : (
          <Input placeholder="NO199680350245" disabled={isEdit} />
        )}
      </Form.Item>
      {!isFullUserData && (
        <Box className="row-gap-4 mb-12" display="flex" flexDirection="column">
          {!isBirthDate && (
            <Box className="flex align-center gap-12">
              <Icon name="AlertCircleOutlined" fill="#fd0000" />
              <Text>
                {t('You should first')}{' '}
                <Button type="link" className="p-0" onClick={handleProfile}>
                  {t('go to profile')}
                </Button>{' '}
                {t('and set your')} <strong>{t('birthday')}</strong>
              </Text>
            </Box>
          )}
          {!isLastName && (
            <Box className="flex align-center gap-12">
              <Icon name="AlertCircleOutlined" fill="#fd0000" />
              <Text>
                {t('You should first')}{' '}
                <Button type="link" className="p-0" onClick={handleProfile}>
                  {t('go to profile')}
                </Button>{' '}
                {t('and set your')} <strong>{t('last name')}</strong>
              </Text>
            </Box>
          )}
          {!isFirstName && (
            <Box className="flex align-center gap-12">
              <Icon name="AlertCircleOutlined" fill="#fd0000" />
              <Text>
                {t('You should first')}{' '}
                <Button type="link" className="p-0" onClick={handleProfile}>
                  {t('go to profile')}
                </Button>{' '}
                {t('and set your')} <strong>{t('first name')}</strong>
              </Text>
            </Box>
          )}
          {!isAddress && (
            <Box className="flex align-center gap-12">
              <Icon name="AlertCircleOutlined" fill="#fd0000" />
              <Text>
                {t('You should first')}{' '}
                <Button type="link" className="p-0" onClick={handleProfile}>
                  {t('go to profile')}
                </Button>{' '}
                {t('and select your')} <strong>{t('address')}</strong>
              </Text>
            </Box>
          )}
          {!isEmailVerified && (
            <Box className="flex align-center gap-12">
              <Icon name="AlertCircleOutlined" fill="#fd0000" />

              <Text>
                {t('You should first')}{' '}
                <Button
                  type="link"
                  className="p-0"
                  onClick={handleVerification}>
                  {t('verify')}
                </Button>{' '}
                <strong>{t('email address')}</strong>
              </Text>
            </Box>
          )}
          {!isPhoneVerified && (
            <Box className="flex align-center gap-12">
              <Icon name="AlertCircleOutlined" fill="#fd0000" />
              <Text>
                {t('You should first')}{' '}
                <Button
                  type="link"
                  className="p-0"
                  onClick={handleVerification}>
                  {t('verify')}
                </Button>{' '}
                {t('your')} <strong>{t('phone number')}</strong>
              </Text>
            </Box>
          )}
        </Box>
      )}
    </FormWrapperStyled>
  )
}

ConnectDetailsSimpleForm.propTypes = {
  handleNextStep: PropTypes.func,
  isEdit: PropTypes.bool,
  externalAccount: PropTypes.object,
  accountId: PropTypes.string
}

export default ConnectDetailsSimpleForm
