import { Button } from 'antd'
import { Icon } from '@qonsoll/icons'
import PropTypes from 'prop-types'
import { useAccountMenuActions } from '../AccountMenu/hooks'
import { useTranslations } from 'hooks'

const LogoutBtn = (props) => {
  const { collapsed } = props
  const { t } = useTranslations()

  const { confirmLogout } = useAccountMenuActions()

  return (
    <div className="px-4 full-width">
      <Button
        style={{ height: '40px' }}
        block
        type="text"
        onClick={confirmLogout}
        className="flex align-center justify-start px-24">
        <Icon
          name="LogOut2Outlined"
          size={16}
          style={{ marginRight: '10px' }}
          fill="currentColor"
        />
        {!collapsed ? t('Log out') : null}
      </Button>
    </div>
  )
}

LogoutBtn.propTypes = {
  collapsed: PropTypes.bool
}

export default LogoutBtn
