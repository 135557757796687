import { Row, Col, Typography, theme, Tooltip, Button } from 'antd'
import { useTranslations } from 'hooks'
import { Box } from 'components'
import { capitalizeString } from 'helpers/environment'
import PropTypes from 'prop-types'
import { Icon } from '@qonsoll/icons'

const { Text } = Typography

const PayoutInformationSimpleView = (props) => {
  const { externalAccount, settings } = props

  const { t } = useTranslations()

  const { colorInfoText } = theme.useToken().token

  const payoutsSchedule = settings?.payouts?.schedule

  const information = [
    { title: t('Bank name'), value: externalAccount?.bank_name },
    { title: t('Currency'), value: externalAccount?.currency },
    {
      title: t('IBAN'),
      value: externalAccount?.last4 && `***${externalAccount?.last4}`
    },
    {
      title: t('Payout schedule'),
      value: payoutsSchedule && (
        <Row className="flex align-center" gutter={[4, 0]}>
          <Col>
            {`${capitalizeString(payoutsSchedule?.interval)} - ${
              payoutsSchedule?.delay_days
            } ${t('day rolling basic')}`}
          </Col>
          <Col>
            <Tooltip
              title={
                <Box>
                  {`${t(
                    'Charge funds will be held for the specified delay then included in the following automatic payout'
                  )}. ${t('For details, see our')}`}
                  <Button
                    size="small"
                    target="_blank"
                    href="https://stripe.com/docs/connect/manage-payout-schedule"
                    type="link">
                    {t(' documentation ')}
                  </Button>
                  {`${t('for setting bank and debit card payouts')}`}.
                </Box>
              }
              placement="top">
              <Icon
                name="AlertInfoFilled"
                fill={colorInfoText}
                className="cursor-pointer"
              />
            </Tooltip>
          </Col>
        </Row>
      )
    }
  ]

  return (
    <Box className="flex flex-col gap-4">
      {information?.map(({ title, value }) => (
        <Row key={title} justify="space-between">
          <Col span={10}>
            <Text type="secondary">{title}:</Text>
          </Col>
          <Col span={12}>
            <Text>{value}</Text>
          </Col>
        </Row>
      ))}
    </Box>
  )
}

PayoutInformationSimpleView.propTypes = {
  externalAccount: PropTypes.shape({
    bank_name: PropTypes.string,
    currency: PropTypes.string,
    last4: PropTypes.string
  }),
  settings: PropTypes.shape({
    payouts: PropTypes.shape({
      schedule: PropTypes.shape({
        interval: PropTypes.string,
        delay_days: PropTypes.number
      })
    })
  })
}
export default PayoutInformationSimpleView
