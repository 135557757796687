import { useCallback, useMemo, useState } from 'react'

import { Icon } from '@qonsoll/icons'

const useComplaintSort = (complaints) => {
  const [sortedComplaints, setSortedComplaints] = useState([])
  const [sortDirection, setSortDirection] = useState('asc')
  const [currSortField, setCurrSortField] = useState(null)

  const sortedComplaintsPrepared = useMemo(
    () => (sortedComplaints?.length ? sortedComplaints : complaints),
    [complaints, sortedComplaints]
  )
  const onSortDirChange = () =>
    setSortDirection((prev) => (prev === 'asc' ? 'desc' : 'asc'))

  /* Fields for sorting */
  const sortKeys = useMemo(
    () => ({
      'Start date': '_createdAt'
    }),
    []
  )
  /* Getting sort icon for field */
  const getSortIcon = useCallback(
    (key) => {
      const field = sortKeys[key]
      if (field === currSortField) {
        return sortDirection === 'asc' ? (
          <Icon name="ArrowLongUpFilled" />
        ) : (
          <Icon name="ArrowLongDownFilled" />
        )
      } else {
        return <Icon name="MinusOutlined" />
      }
    },
    [sortKeys, currSortField, sortDirection]
  )

  const getDate = (date) => {
    const parsedDate = Date?.parse(date)
    // Convert milliseconds to seconds
    return Number(parsedDate) ? parsedDate * 0.001 : date?.seconds
  }
  /* Sort button click action */
  const onSortChange = (key) => {
    const field = sortKeys[key]
    setCurrSortField(field)
    onSortDirChange()

    const newSortedComplaints = sortedComplaintsPrepared
      .slice()
      .sort((a, b) => {
        const valueA = getDate(a?.[field])
        const valueB = getDate(b?.[field])

        if (valueA < valueB) {
          return sortDirection === 'asc' ? 1 : -1
        }
        if (valueA > valueB) {
          return sortDirection === 'asc' ? -1 : 1
        }
        return 0
      })

    setSortedComplaints(newSortedComplaints)
  }

  const onClearSort = () => {
    setSortedComplaints([])
    setCurrSortField(null)
  }

  return {
    sortKeys,
    currSortField,
    sortDirection,
    onSortChange,
    onClearSort,
    getSortIcon,
    sortedComplaints: sortedComplaintsPrepared
  }
}

export default useComplaintSort
