import { Col, Divider, Grid, Row } from 'antd'
import {
  ImagesPreview,
  ProductDetails,
  ProductExtendedInfo,
  ProductLessorView
} from 'domains/Product/components/ProductAdvancedView/components'

import { ProductsListFromCurrentLessor } from 'domains/Product/components'
import PropTypes from 'prop-types'
import { Spinner } from 'components'
import { getTransformedImageUrl } from 'helpers'
import { useGetReviews } from 'domains/Review/hooks'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useUser } from 'modules/session-module/contexts'

const { useBreakpoint } = Grid

const ProductPreviewAdvancedView = (props) => {
  const { product, address } = props
  const { user } = useUser()
  const { lg } = useBreakpoint()
  const { productId } = useParams()

  const [reviews, loading] = useGetReviews(productId)

  const mediaObjectsUrls = useMemo(
    () =>
      product?.mediaObjects?.map((mediaObject) =>
        getTransformedImageUrl(mediaObject)
      ),
    [product?.mediaObjects]
  )
  return !loading ? (
    <div className="pointer-events-none">
      <Row gutter={32} className="mb-48">
        <Col xs={24} md={24} lg={24} xxl={24} className="mb-24">
          <ImagesPreview
            noActions
            mediaObjects={mediaObjectsUrls}
            productId={productId}
            productName={product?.name}
          />
        </Col>
        <Col xs={24} lg={24} xl={24}>
          <Row>
            <Col span={24}>
              <ProductDetails
                product={product}
                address={address?.locationData}
              />
            </Col>
            <Col span={24}>
              <Divider />
            </Col>
            <ProductExtendedInfo product={product} reviews={reviews} />
            <Col span={24}>
              <ProductLessorView user={user} />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className={!lg && 'mb-96'}>
        <Col span={24}>
          <ProductsListFromCurrentLessor user={user} />
        </Col>
      </Row>
    </div>
  ) : (
    <Spinner height="70vh" />
  )
}

ProductPreviewAdvancedView.propTypes = {
  product: PropTypes.object,
  mediaObjects: PropTypes.array,
  address: PropTypes.object,
  user: PropTypes.object,
  productsByCurrentUser: PropTypes.array,
  tags: PropTypes.array,
  reviews: PropTypes.array
}

export default ProductPreviewAdvancedView
