import {
  OrderPaymentDetails,
  OrderPickupDetails
} from 'domains/Order/components/OrderAdvancedView/components'
import { OrderRentingDays, OrderStatus } from 'domains/Order/components'
import { Typography, theme } from 'antd'

import { DottedBlock } from 'components'
import { Icon } from '@qonsoll/icons'
import PropTypes from 'prop-types'
import { StyledCollapse } from './ComplaintOrderDetails.styled'
import { useTranslations } from 'hooks'

const ComplaintOrderDetails = (props) => {
  const { order, isOpenOrderDetails, setIsOpenOrderDetails } = props
  const { colorPrimary } = theme.useToken().token
  const { Panel } = StyledCollapse
  const { t } = useTranslations()

  const handleShowDetails = (defaultActiveKey) =>
    setIsOpenOrderDetails(defaultActiveKey)
  return (
    <div>
      {order && (
        <StyledCollapse
          onChange={handleShowDetails}
          bordered={false}
          ghost
          expandIcon={({ isActive }) => (
            <Icon
              name={isActive ? 'ArrowShortUpFilled' : 'ArrowShortDownFilled'}
              size={20}
              fill={isActive ? colorPrimary : 'currentColor'}
            />
          )}
          expandIconPosition="end"
          activeKey={isOpenOrderDetails}>
          <Panel
            header={
              <Typography.Title level={5}>
                {t('Order details')}
              </Typography.Title>
            }
            key="1">
            <div className="flex flex-col mb-12">
              <DottedBlock
                className="mb-8"
                height="22px"
                left={<Typography.Text>{`${t('Order')} №`}</Typography.Text>}
                right={
                  <Typography.Text level={5}>
                    {order?.orderNumber}
                  </Typography.Text>
                }
              />
              <DottedBlock
                className="mb-8"
                height="22px"
                left={<Typography.Text>{t('Status')}</Typography.Text>}
                right={<OrderStatus order={order} />}
              />

              <DottedBlock
                className="mb-8"
                height="22px"
                left={<Typography.Text>{t('Dates')}</Typography.Text>}
                right={<OrderRentingDays order={order} inRow />}
              />
            </div>
            {/* Pick-up details */}
            <OrderPickupDetails order={order} />

            {/* payment details */}
            <OrderPaymentDetails order={order} isComplaintView />
          </Panel>
        </StyledCollapse>
      )}
    </div>
  )
}
ComplaintOrderDetails.propTypes = {
  order: PropTypes.object,
  isOpenOrderDetails: PropTypes.string,
  setIsOpenOrderDetails: PropTypes.func
}
export default ComplaintOrderDetails
