import { useEffect, useState } from 'react'
import _ from 'lodash'
import moment from 'moment'

import { formatPointsNumbers, kFormatter } from 'domains/Statistic/helpers'
import { useGetAllStatisticsByUser } from 'domains/Statistic/hooks'
import { STATISTIC_KEYS } from 'domains/Statistic/__constants__'

/**
 * Custom hook that retrieves total statistics for a given user.
 *
 * @param {string} userId - The ID of the user.
 * @returns {Array} - An array of objects containing the total statistics.
 */
const useGetTotalStatistics = (userId) => {
  // Define state variables for earnings, rented products, and new customers
  const [earnings, setEarnings] = useState({
    total: 0,
    today: 0,
    yesterdayEarnings: 0,
    todayEarnings: 0
  })
  const [rentedProducts, setRentedProducts] = useState({
    total: 0,
    today: 0,
    yesterdayRentedProducts: 0,
    todayRentedProducts: 0
  })
  const [newCustomers, setNewCustomers] = useState({
    total: 0,
    today: 0,
    yesterdayRentedProducts: 0,
    todayRentedProducts: 0
  })

  // Fetch all statistics and statistics by month for the current user
  const [allStatistic, statisticsByMonth] = useGetAllStatisticsByUser({
    userId
  })

  useEffect(() => {
    if (!allStatistic) return

    // Calculate earnings
    const totalEarnings = _.sumBy(
      allStatistic,
      (item) => item.total_rent_price || 0
    )
    const currentDayKey = moment().format('YYYY-MM-DD')
    const yesterdayKey = moment().subtract(1, 'days').format('YYYY-MM-DD')
    const statisticsByYesterday = allStatistic.find(
      (item) => item.date === yesterdayKey
    )
    const statisticsByToday = allStatistic.find(
      (item) => item.date === currentDayKey
    )
    const yesterdayEarnings = statisticsByYesterday?.total_rent_price || 0
    const todayEarnings = statisticsByToday?.total_rent_price || 0

    setEarnings({
      total: totalEarnings,
      yesterdayEarnings,
      todayEarnings
    })

    // Calculate rented products
    const monthRentedProducts = _.sumBy(
      statisticsByMonth,
      (item) => item.total_rent_number || 0
    )
    const yesterdayRentedProducts =
      statisticsByYesterday?.total_rent_number || 0
    const todayRentedProducts = statisticsByToday?.total_rent_number || 0

    setRentedProducts({
      total: monthRentedProducts,
      yesterdayRentedProducts,
      todayRentedProducts
    })

    // Calculate new customers
    const monthNewCustomers = _.sumBy(
      statisticsByMonth,
      (item) => item.customers_number || 0
    )
    const yesterdayNewCustomers = statisticsByYesterday?.customers_number || 0
    const todayNewCustomers = statisticsByToday?.customers_number || 0

    setNewCustomers({
      total: monthNewCustomers,
      yesterdayNewCustomers,
      todayNewCustomers
    })
  }, [allStatistic, statisticsByMonth, userId])

  // Prepare data for rendering
  const data = [
    {
      statisticKey: STATISTIC_KEYS.TOTAL_EARNING,
      title: 'Total earnings',
      value: formatPointsNumbers(earnings.total),
      valueType: 'NOK',
      icon: 'LineChartUp2Outlined',
      today: kFormatter(earnings.today),
      yesterdayValue: earnings?.yesterdayEarnings,
      todayValue: earnings?.todayEarnings,
      growthTime: 'Today'
    },
    {
      statisticKey: STATISTIC_KEYS.RENTED_PRODUCTS,
      title: 'Rented products',
      value: formatPointsNumbers(rentedProducts.total),
      valueType: '/Month',
      icon: 'BarChart4Outlined',
      today: kFormatter(rentedProducts.today),
      yesterdayValue: rentedProducts?.yesterdayRentedProducts,
      todayValue: rentedProducts?.todayRentedProducts,
      growthTime: 'Today'
    },
    {
      statisticKey: STATISTIC_KEYS.NEW_CUSTOMERS,
      title: 'New customers',
      value: formatPointsNumbers(newCustomers.total),
      valueType: '/Month',
      icon: 'Users1Outlined',
      today: kFormatter(newCustomers.today),
      yesterdayValue: newCustomers?.yesterdayNewCustomers,
      todayValue: newCustomers?.todayNewCustomers,
      growthTime: 'Today'
    }
  ]

  return data
}
export default useGetTotalStatistics
