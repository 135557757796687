const COUNTRIES = {
  NORWAY: 'NORWAY',
  GREAT_BRITAIN: 'GREAT_BRITAIN',
  UKRAINE: 'UKRAINE',
  ROMANIA: 'ROMANIA',
  POLAND: 'POLAND'
}

const COUNTRIES_LABELS = {
  NORWAY: 'Norway',
  GREAT_BRITAIN: 'Great britain',
  UKRAINE: 'Ukraine',
  ROMANIA: 'Romania',
  POLAND: 'Poland'
}

const COUNTRIES_OPTIONS = Object.keys(COUNTRIES_LABELS).map((key) => ({
  label: COUNTRIES[key],
  value: key
}))

export default COUNTRIES
export { COUNTRIES_OPTIONS, COUNTRIES, COUNTRIES_LABELS }
