import { Button, Input, Popover } from 'antd'
import { OrderFilter, OrderSortMenu } from 'domains/Order/components'

import { Icon } from '@qonsoll/icons'
import PropTypes from 'prop-types'
import { useTranslations } from 'hooks'

const OrderMobileActions = (props) => {
  const { sortProps, filterProps, searchProps } = props
  const { t } = useTranslations()

  return (
    <div className="flex align-center mb-24">
      <Input
        prefix={<Icon name="SearchLgOutlined" />}
        className="mr-8"
        onChange={searchProps?.onSearchChange}
        placeholder={`${t('Name or number')}...`}
      />
      <Popover content={<OrderSortMenu {...sortProps} />}>
        {/* TODO: change icon */}
        <Button
          className="flex align-center justify-center p-8 mr-8"
          icon={<Icon name="SortDownBlockAscOutlined" size={18} />}
        />
      </Popover>
      <Popover content={<OrderFilter {...filterProps} />}>
        <Button
          className="flex align-center justify-center p-8"
          icon={<Icon name="FilterLinesOutlined" size={18} />}
        />
      </Popover>
    </div>
  )
}

OrderMobileActions.propTypes = {
  sortProps: PropTypes.object,
  filterProps: PropTypes.object,
  searchProps: PropTypes.object
}
export default OrderMobileActions
