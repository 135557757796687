import {
  CANCELLATION_TERMS_TYPES_DESCRIPTION,
  CURRENCIES_OPTIONS,
  KEYS
} from '__constants__'
import { Form, Input, InputNumber, Select, Typography, theme } from 'antd'

import { CANCELLATION_TERMS_TYPES_OPTIONS } from '__constants__/enums/cancellationTerms'
import { FormWrapper } from 'components'
import PropTypes from 'prop-types'
import { SegmentedSelect } from 'components'
import { useMemo } from 'react'
import { useTranslations } from 'hooks'

const ProductSimpleForm = (props) => {
  const {
    initialValues,
    onSubmit,
    onCancel,
    showTitle,
    form,
    group,
    children,
    ...rest
  } = props
  const { colorBorder } = theme.useToken().token
  const currencySelectStyles = { minWidth: '80px' }
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting new form instance from Form.useForm hook */
  const [baseForm] = Form.useForm()

  /* Getting used form from props or using base form */
  const usedForm = form || baseForm

  /* Resetting form on cancel */
  const handleCancel = () => {
    usedForm.resetFields()
    onCancel?.()
  }
  /* Submitting form on enter key press */
  const handleKeyPress = (e) => {
    if (e.key === KEYS.ENTER) {
      e.preventDefault()
      e.stopPropagation()
      usedForm?.submit()
    }
  }

  /* Generating form field names */
  const getFieldName = (subGroup) => (group ? [...group, subGroup] : [subGroup])

  const chosenCancellationTerm = Form.useWatch(
    getFieldName('cancellationTerms'),
    usedForm
  )
  const cancellationTermsDescr = useMemo(
    () =>
      CANCELLATION_TERMS_TYPES_DESCRIPTION?.[chosenCancellationTerm] ||
      CANCELLATION_TERMS_TYPES_DESCRIPTION.FIXED,
    [chosenCancellationTerm]
  )
  return (
    <FormWrapper
      title={t('Product')}
      onFinish={onSubmit}
      initialValues={initialValues}
      onKeyPress={handleKeyPress}
      onReset={handleCancel}
      showTitle={showTitle}
      form={usedForm}
      group={group}
      formActions={null}
      {...rest}>
      <Form.Item
        data-area="product-title"
        label={t('Product name')}
        name={getFieldName('name')}
        rules={[{ required: true, message: t('Please enter product name!') }]}>
        <Input placeholder={t('Please enter product name')} />
      </Form.Item>
      <Form.Item
        data-area="product-price-per-day"
        label={t('Price per day')}
        name={getFieldName('pricePerDay')}
        rules={[
          { required: true, message: t('Please enter price per day!') },
          {
            type: 'number',
            min: 3,
            message: t('The price should be at least 3 kr')
          }
        ]}>
        <InputNumber
          style={{ width: '100%' }}
          placeholder={t('Please enter price per day')}
          addonAfter={
            <Form.Item
              noStyle
              name={getFieldName('currency')}
              rules={[
                { required: true, message: t('Please enter product price!') }
              ]}>
              <Select
                disabled
                options={CURRENCIES_OPTIONS}
                style={currencySelectStyles}
              />
            </Form.Item>
          }
        />
      </Form.Item>
      <Form.Item
        className="mb-8"
        data-area="cancellation terms"
        label={t('Cancellation Terms')}
        name={getFieldName('cancellationTerms')}
        rules={[
          {
            required: true
          }
        ]}>
        <SegmentedSelect
          fullWidth
          wrapperStyles={{ border: `1px solid ${colorBorder}` }}
          options={CANCELLATION_TERMS_TYPES_OPTIONS}
        />
      </Form.Item>
      <Typography.Text type="secondary" className="block mb-24">
        {cancellationTermsDescr}
      </Typography.Text>
      <Form.Item
        className="mb-0"
        data-area="product-description"
        label={t('Product description')}
        name={getFieldName('description')}>
        <Input.TextArea
          rows={10}
          placeholder={t('Please enter your product description')}
        />
      </Form.Item>
    </FormWrapper>
  )
}

ProductSimpleForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  showTitle: PropTypes.bool,
  form: PropTypes.any,
  group: PropTypes.array,
  children: PropTypes.node
}

export default ProductSimpleForm
