import { Card, Grid, Typography } from 'antd'
import { DottedBlock, Image } from 'components'
import {
  OrderActions,
  OrderComplaintBottomDrawer,
  OrderRentingDays,
  OrderReviewBottomDrawer,
  OrderStatus,
  OrderPaymentStatus
} from 'domains/Order/components'
import { generatePath, useNavigate } from 'react-router-dom'
import { useMemo, useState } from 'react'

import { APP_PATHS, PAYMENT_STATUSES } from '__constants__'
import { OrderPrice } from 'domains/Order/components/OrderList/components'
import PropTypes from 'prop-types'
import styles from './OrderSimpleView.styled'
import { useTranslations } from 'hooks'

const { useBreakpoint } = Grid
const showPath = APP_PATHS.ORDER_SHOW

const OrderSimpleView = (props) => {
  const { order } = props
  const { t } = useTranslations()
  const navigate = useNavigate()
  const { md } = useBreakpoint()

  const [reviewDrawerVisible, setReviewDrawerVisible] = useState(false)
  const [complaintDrawerVisible, setComplaintDrawerVisible] = useState(false)
  const onReviewBottomDrawerToggle = () =>
    setReviewDrawerVisible((prev) => !prev)
  const onComplaintBottomDrawerToggle = () =>
    setComplaintDrawerVisible((prev) => !prev)

  const onOpen = (e) => {
    e.stopPropagation()
    const pathParams = { orderId: order?._id }
    const path = generatePath(showPath, pathParams)
    navigate(path)
  }

  const computedImageUrl = useMemo(
    () => order?.previewImageUrl || order?.product?.previewImgUrl,
    [order?.previewImageUrl, order?.product]
  )

  return (
    <>
      <Card onClick={onOpen}>
        <div className="flex align-start justify-between mb-12">
          <Image
            isSquare={false}
            src={computedImageUrl}
            style={styles.imageProps}
          />
          <OrderStatus order={order} />
        </div>
        <div className="flex flex-col justify-center mb-16">
          <Typography.Text className="mb-4">
            {order?.product?.name}
          </Typography.Text>
          <Typography.Text type="secondary">
            № {order?.orderNumber}
          </Typography.Text>
        </div>
        <DottedBlock
          className="mb-8"
          height="22px"
          left={<Typography.Text>{t('Dates')}</Typography.Text>}
          right={<OrderRentingDays order={order} inRow />}
        />
        <DottedBlock
          className="mb-8"
          height="22px"
          left={<Typography.Text>{t('Payment status')}</Typography.Text>}
          right={
            <OrderPaymentStatus
              paymentStatus={order?.paymentStatus || PAYMENT_STATUSES.UNPAID}
            />
          }
        />
        <DottedBlock
          className="mb-16"
          height="22px"
          left={<Typography.Text>{t('Total')}</Typography.Text>}
          right={<OrderPrice order={order} />}
        />

        <OrderActions
          order={order}
          onReviewFormToggle={onReviewBottomDrawerToggle}
          onComplaintFormToggle={onComplaintBottomDrawerToggle}
          fullWidth
        />
      </Card>
      {!md && (
        <>
          <OrderReviewBottomDrawer
            order={order}
            reviewDrawerVisible={reviewDrawerVisible}
            onReviewFormToggle={onReviewBottomDrawerToggle}
          />
          <OrderComplaintBottomDrawer
            orderId={order?._id}
            productId={order?.product?._id}
            complaintDrawerVisible={complaintDrawerVisible}
            onComplaintFormToggle={onComplaintBottomDrawerToggle}
          />
        </>
      )}
    </>
  )
}

OrderSimpleView.propTypes = {
  order: PropTypes.object
}

export default OrderSimpleView
