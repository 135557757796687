import { COLLECTIONS } from '__constants__'

const markAsSeen = async (update, notification, user) => {
  await update(COLLECTIONS.NOTIFICATIONS, notification?._id, {
    receivers: {
      ...notification.receivers,
      [user?._id]: true
    }
  })
}
export default markAsSeen
