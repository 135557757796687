import { COLLECTIONS } from '__constants__'
import { LS } from 'modules/session-module/__constants__'
import firebase from 'firebase/compat/app'
const { USERS } = COLLECTIONS

/**
 * Save the user's data to the database.
 * @returns A promise that resolves to the user object.
 */
const saveUserToDB = ({
  id,
  email,
  avatarUrl,
  agreement,
  gdpr,
  additionalData = {},
  onError
}) => {
  // Extract the first name and last name from the display name if they are not provided.

  const firestore = firebase.firestore()

  const json = localStorage.getItem(LS.SIGNUP_DATA)
  const signUpData = JSON.parse(json)

  if (!signUpData?.firstName || !signUpData?.lastName) {
    const [firstName = '', lastName = ''] = (
      additionalData.displayName || ''
    ).split(' ')
    additionalData.firstName = firstName
    additionalData.lastName = lastName
  }
  localStorage.removeItem(LS.SIGNUP_DATA)

  return firestore
    .collection(USERS)
    .doc(id)
    .set({
      _createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      _updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      _isUpdated: false,
      _updatedBy: id,
      _id: id,
      email,
      isVerified: false,
      ...additionalData,
      agreement,
      gdpr,
      avatarUrl,
      firstName: signUpData?.firstName ?? additionalData.firstName ?? '',
      lastName: signUpData?.lastName ?? additionalData.lastName ?? '',
      phone: signUpData?.phone ?? null
      // role
    })
    .catch((err) => {
      onError && onError(err)
    })
}

export default saveUserToDB
