import 'firebase/auth'
import 'firebase/database'
import 'firebase/compat/auth'
import 'firebase/compat/database'
import 'firebase/compat/firestore'

import {
  AppNotificationsProvider,
  CartProvider,
  LikedProductsProvider
} from './contexts'

import { App as AntdApp } from 'antd'
import PropTypes from 'prop-types'
import { StripeProvider } from 'modules/stripe-module/contexts'
import { ThemeProvider } from 'modules/theme-module/contexts'
import { TranslationsProvider } from 'modules/translation-module/contexts'
import { UserProvider } from 'modules/session-module/contexts'
import firebase from 'firebase/compat/app'
import { firebaseConfig } from 'services/firebase'

/* This is the code that initializes the firebase app. */
firebase.initializeApp(firebaseConfig)

const AppProviders = ({ children }) => {
  // Simple provider composition
  // Add more providers here in composition order
  const providers = [
    { provider: AntdApp },
    { provider: ThemeProvider },
    { provider: TranslationsProvider },
    { provider: AppNotificationsProvider },
    { provider: UserProvider },
    { provider: StripeProvider },
    { provider: CartProvider },
    { provider: LikedProductsProvider }
  ]

  return providers.reduceRight(
    (children, { provider: Provider, props }) => (
      <Provider {...props}>{children}</Provider>
    ),
    children
  )
}

AppProviders.propTypes = {
  children: PropTypes.node.isRequired
}

export default AppProviders
