import { Breadcrumbs, PageLayout } from 'components'
import { useFilterCategories, useGetCategories } from 'domains/Category/hooks'
import { useMemo, useState } from 'react'

import { LoadingBox } from 'components'
import { ProductsAllView } from 'domains/Product/components'
import { useLoading } from 'hooks'
import { useSearchAllProducts } from 'domains/Product/hooks'

const ProductsAll = () => {
  const [currentPage, setCurrentPage] = useState(1)

  const searchParams = useMemo(
    () => ({ perPage: 8, page: currentPage }),
    [currentPage]
  )

  const {
    loading: typeSenseProductsLoading,
    totalResults,
    products
  } = useSearchAllProducts(searchParams)
  const [categories, categoriesLoading] = useGetCategories()

  const [, filteredCategoriesLoading, topLevelCategories] =
    useFilterCategories(categories)

  const computedLoadings = useMemo(
    () => filteredCategoriesLoading || categoriesLoading,
    [filteredCategoriesLoading, categoriesLoading]
  )

  const loading = useLoading([typeSenseProductsLoading, computedLoadings])

  return (
    <PageLayout breadcrumbs={<Breadcrumbs />} area="category">
      <LoadingBox spinnerProps={{ height: '100%' }} loading={loading}>
        <ProductsAllView
          products={products}
          topLevelCategories={topLevelCategories}
          totalResults={totalResults}
          currentPage={currentPage}
          onPageChange={setCurrentPage}
        />
      </LoadingBox>
    </PageLayout>
  )
}

export default ProductsAll
