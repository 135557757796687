import { Route, Routes } from 'react-router-dom'

import { SERVICE_ROUTES } from './serviceRoutes'

const ServiceNavigator = () => {
  return (
    <Routes>
      {SERVICE_ROUTES.map(({ key, path, element }) => (
        <Route key={key} path={path} element={element} />
      ))}
    </Routes>
  )
}

export default ServiceNavigator
