import PropTypes from 'prop-types'
import { StyledCard } from './SelectableCard.styled'
import { theme } from 'antd'
import { useMemo } from 'react'

/**
 * If the card is selected, use the selected styles, otherwise use the default styles.
 * @param isSelected {boolean} - If true, the card is selected
 * @param onSelect {function} - The function to call when the card is selected
 */
const SelectableCard = (props) => {
  const { isSelected, onSelect, children, style, bodyStyle, ...rest } = props

  const token = theme.useToken().token

  const styles = useMemo(() => {
    const { colorPrimaryActive, borderRadiusLG } = token

    const styleComputed = {
      height: '100%',
      borderRadius: borderRadiusLG,
      padding: '16px',
      boxSizing: 'border-box',

      ...(style || {})
    }
    if (isSelected) {
      style.boxShadow = `0 0 0 2px ${colorPrimaryActive}`
    }
    return styleComputed
  }, [isSelected, style, token])
  return (
    <StyledCard
      onClick={onSelect}
      bodyStyle={{ ...styles, ...bodyStyle }}
      {...rest}>
      {children}
    </StyledCard>
  )
}

SelectableCard.propTypes = {
  isSelected: PropTypes.bool,
  onSelect: PropTypes.func,
  children: PropTypes.node,
  style: PropTypes.object,
  className: PropTypes.string,
  bodyStyle: PropTypes.object
}

export default SelectableCard
