import { calculateDistance } from 'domains/Product/helpers'
import { useTranslations } from 'hooks'

const useCalculateDistanceAndAddress = (product, address, onlyAddress) => {
  const { t } = useTranslations()

  // TODO to think about this usage of localStorage
  // TODO add distance on all products list (including selected location after connecting context to this page)
  // TODO rewrite this function after 1st task completes
  // now on map page there is selected location and on products all page - none
  const computedSelectedLocation = JSON.parse(
    localStorage.getItem('selectedLocation')
  ) || {
    lat: 59.9138688,
    lng: 10.7522454
  }
  const productDistance = computedSelectedLocation
    ? calculateDistance(computedSelectedLocation, [
        address?.latitude || product?.address?.location[0],
        address?.longitude || product?.address?.location[1]
      ])
    : ''

  const streetAddress = [
    address?.streetName || product?.address?.streetName,
    address?.streetNumber || product?.address?.streetNumber
  ]
    .filter(Boolean) // Filter out null or undefined values
    .join(' ')

  const productDetailedAddress = [
    streetAddress,
    address?.city || product?.address?.city,
    address?.country || product?.address?.country
  ]
    .filter(Boolean) // Filter out null or undefined values
    .join(', ')
  const productComputedAddress =
    productDistance && productDetailedAddress && !onlyAddress
      ? `(${productDetailedAddress})`
      : `${productDetailedAddress}`

  const productComputedDistance = `${productDistance || ''} ${
    productDistance ? `${t('km')} ` : ''
  }`

  return onlyAddress
    ? [productComputedAddress]
    : [productComputedAddress, productComputedDistance]
}
export default useCalculateDistanceAndAddress
