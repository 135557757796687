import { useEffect, useState } from 'react'
import { get, getDatabase, ref } from 'firebase/database'
import moment from 'moment'
import { RDB_COLLECTIONS } from '__constants__'

/**
 * Custom hook to fetch all statistics data for a specific user.
 *
 * @param {Object} options - The options object.
 * @param {string} options.userId - The ID of the user.
 * @returns {Array} - An array containing all statistics data and statistics data for the current month.
 */
const useGetAllStatisticsByUser = ({ userId }) => {
  const [allStatistic, setAllStatistic] = useState(null)
  const [statisticsByMonth, setStatisticsByMonth] = useState(null)

  useEffect(() => {
    const getAllStatisticsByDay = async () => {
      const db = getDatabase()

      // Fetch statistics data from the database
      const dbRef = ref(db, RDB_COLLECTIONS.STATISTICS_BY_DAYS)
      const snapshot = await get(dbRef)

      const result = snapshot.val()

      // Transform the statistics data by current user
      const transformedStatistics = Object.keys(result)?.map((item) => ({
        ...result[item]?.renters?.[userId],
        date: item
      }))

      // Filter statistics data by current month
      const _statisticsByMonth = transformedStatistics?.filter((item) => {
        const currentMonth = moment().format('YYYY-MM')
        const itemMonth = moment(item?.date).format('YYYY-MM')
        return itemMonth === currentMonth
      })

      // Set the filtered statistics data by month
      setStatisticsByMonth(_statisticsByMonth)

      // Set all statistics data
      setAllStatistic(transformedStatistics)
    }

    // Call the function to fetch statistics data
    getAllStatisticsByDay()
  }, [userId])

  return [allStatistic, statisticsByMonth]
}

export default useGetAllStatisticsByUser
