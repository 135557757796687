import { CurrentLocationButton, MapCircle, MapPoint } from './components'

import { GoogleMap } from '@react-google-maps/api'
import PropTypes from 'prop-types'
import { Spinner } from 'components'
import { useInitializeMap } from 'domains/GoogleMaps/hooks'
import { useTranslations } from 'hooks'

const initialValue = { lat: 59.9138688, lng: 10.7522454 }

const Map = (props) => {
  const {
    disabled,
    onChange,
    value,
    options,
    children,
    containerStyle,
    markers,
    circleRadius,
    ...rest
  } = props

  const { t } = useTranslations()
  const { isLoaded } = useInitializeMap()

  const handleClick = (e) => {
    const latLng = { lat: e.latLng.lat(), lng: e.latLng.lng() }
    onChange?.(latLng)
  }

  if (!isLoaded) return <Spinner height="50vh" text={t('Loading...')} />

  return (
    <div className="flex flex-col full-height full-width relative">
      <GoogleMap
        zoom={13}
        language="en"
        center={value || initialValue}
        options={options}
        mapContainerClassName="map-container"
        onClick={!disabled && handleClick}
        {...rest}>
        {value && <MapPoint position={value} />}
        {circleRadius && (
          <MapCircle value={value} circleRadius={circleRadius} />
        )}
        {markers}
      </GoogleMap>
      {options.currentLocation === false ? null : (
        <CurrentLocationButton onChange={onChange} />
      )}
      {children}
    </div>
  )
}

Map.propTypes = {
  children: PropTypes.node,
  containerStyle: PropTypes.object,
  disabled: PropTypes.bool,
  value: PropTypes.object,
  onChange: PropTypes.func,
  options: PropTypes.object,
  markers: PropTypes.arrayOf(PropTypes.node),
  circleRadius: PropTypes.number
}

export default Map
