import { CategorySimpleView } from 'domains/Category/components'
import { List } from 'components'
import PropTypes from 'prop-types'
import { useTranslations } from 'hooks'

const CategoryList = (props) => {
  const { categories, emptyProps, actions, span, ...rest } = props

  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting emptyProps from empty object */
  const emptyParams = {
    ...emptyProps,
    message: t('No categories have been created yet')
  }

  return (
    <List
      {...rest}
      actions={actions}
      span={span || { xs: 24, sm: 12, md: 8 }}
      dataSource={categories}
      emptyProps={emptyParams}
      renderItem={(category, index) => (
        <CategorySimpleView
          category={category}
          area-label={`category-${index + 1}`}
          actions={actions}
          index={index}
        />
      )}
    />
  )
}

CategoryList.propTypes = {
  categories: PropTypes.array,
  emptyProps: PropTypes.object,
  actions: PropTypes.any,
  rest: PropTypes.any,
  span: PropTypes.object
}

export default CategoryList
