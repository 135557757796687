import { EnumSelect, FormWrapper } from 'components'
import { Form, Input } from 'antd'
import {
  PAYMENT_METHOD_TYPES,
  PAYMENT_METHOD_TYPES_OPTIONS
} from '__constants__/enums/paymentMethodTypes'

import { CardNumberInput } from 'components'
import { KEYS } from '__constants__'
import PropTypes from 'prop-types'
import { useTranslations } from 'hooks'

const PaymentMethodSimpleForm = (props) => {
  const {
    initialValues,
    onSubmit,
    onCancel,
    showTitle,
    form,
    group,
    onCheckout
  } = props

  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting new form instance from Form.useForm hook */
  const [baseForm] = Form.useForm()

  /* Getting used form from props or using base form */
  const usedForm = form || baseForm

  /* Resetting form on cancel */
  const handleCancel = () => {
    usedForm.resetFields()
    onCancel?.()
  }
  /* Submitting form on enter key press */
  const handleKeyPress = (e) => {
    if (e.key === KEYS.ENTER) {
      e.preventDefault()
      e.stopPropagation()
      usedForm?.submit()
    }
  }

  /* Generating form field names */
  const getFieldName = (subGroup) => (group ? [...group, subGroup] : [subGroup])

  const paymentType = Form.useWatch(getFieldName('type'), form)

  const validatorMaxCreditCardCharacters = () => ({
    validator(_, value) {
      if (value?.replaceAll(' ', '')?.length < 13)
        return Promise.reject(
          new Error(t('Incorrect credit card number, min 13 characters'))
        )

      return Promise.resolve()
    }
  })

  const validatorMinCreditCardCharacters = () => ({
    validator(_, value) {
      if (value?.replaceAll(' ', '')?.length > 19)
        return Promise.reject(
          new Error(t('Incorrect credit card number, max 19 characters'))
        )

      return Promise.resolve()
    }
  })

  return (
    <FormWrapper
      title={t('Payment method')}
      onFinish={onSubmit}
      initialValues={initialValues}
      onKeyPress={handleKeyPress}
      onReset={handleCancel}
      showTitle={showTitle}
      form={usedForm}
      group={group}>
      {!onCheckout ? (
        <>
          <Form.Item
            data-area="payment method-name"
            label={t('Name')}
            rules={[
              {
                required: true,
                message: `${t('Please enter your payment method name')}!`
              }
            ]}
            name={getFieldName('name')}>
            <Input placeholder={t('Please enter your card name')} />
          </Form.Item>

          <Form.Item
            data-area="payment method-type"
            label={t('Type')}
            rules={[
              {
                required: true,
                message: `${t('Please choose your payment method type')}!`
              }
            ]}
            name={getFieldName('type')}>
            <EnumSelect
              placeholder={t('Please choose your payment method type')}
              options={PAYMENT_METHOD_TYPES_OPTIONS}
            />
          </Form.Item>
        </>
      ) : null}

      {(paymentType === PAYMENT_METHOD_TYPES.CARD || onCheckout) && (
        <Form.Item
          rules={[
            {
              required: true,
              message: `${t('Please enter credit card number')}!`
            },
            validatorMaxCreditCardCharacters(),
            validatorMinCreditCardCharacters()
          ]}
          data-area="payment credit-number"
          label={t('Card number')}
          name={getFieldName('cardNumber')}>
          <CardNumberInput placeholder="0000 0000 0000 0000" />
        </Form.Item>
      )}

      {!onCheckout ? (
        <Form.Item
          data-area="payment method-details"
          label={t('Details')}
          name={getFieldName('details')}>
          <Input.TextArea placeholder={t('Please enter your card details')} />
        </Form.Item>
      ) : null}
    </FormWrapper>
  )
}

PaymentMethodSimpleForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  showTitle: PropTypes.bool,
  form: PropTypes.any,
  group: PropTypes.array,
  onCheckout: PropTypes.func
}

export default PaymentMethodSimpleForm
