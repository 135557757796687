import {
  getProductsStatistic,
  getProductsByStatistic
} from 'domains/Statistic/helpers'
import { useGetDataFromRDB, useHandleError, useTranslations } from 'hooks'
import { RDB_COLLECTIONS } from '__constants__'
import { useCallback, useEffect, useState } from 'react'
/**
 * Hook is used for getting popular rent products
 * @param   {number} quantity limit of products
 * @returns {array}  an array of sorted popular products
 */
const useGetPopularRentedProducts = (quantity) => {
  const handleError = useHandleError()
  const { t } = useTranslations()

  // Get statistic by days from database
  const [statisticByDays, statisticByDaysLoading] = useGetDataFromRDB(
    RDB_COLLECTIONS.STATISTICS_BY_DAYS
  )

  const [sortedPopularProducts, setSortedPopularProducts] = useState()
  const [loadingProductsByStatistic, setLoadingProductsByStatistic] =
    useState(true)

  const getProducts = useCallback(
    async (productsStatistic) => {
      try {
        setLoadingProductsByStatistic(true)

        if (productsStatistic?.length) {
          // Get products data for popular products
          const _sortedPopularProducts = await getProductsByStatistic(
            productsStatistic
          )
          // Sort the array from most popular to least popular product
          quantity
            ? setSortedPopularProducts(
                _sortedPopularProducts?.slice(0, quantity)
              )
            : setSortedPopularProducts(_sortedPopularProducts)
        } else setSortedPopularProducts([])
      } catch (error) {
        handleError(error, t('Error during getting products'))
      } finally {
        setLoadingProductsByStatistic(false)
      }
    },
    [handleError, quantity, t]
  )

  useEffect(() => {
    if (!statisticByDaysLoading) {
      // Get formatted product statistic
      const productsStatistic = getProductsStatistic(statisticByDays)
      // Get products data
      getProducts(productsStatistic)
    }
  }, [statisticByDaysLoading, statisticByDays, getProducts])

  return [sortedPopularProducts, loadingProductsByStatistic]
}

export default useGetPopularRentedProducts
