import { Modal, Typography, Row, Col } from 'antd'
import { useTranslations } from 'hooks'
import { STATISTIC_INFO } from 'domains/Statistic/__constants__'
import PropTypes from 'prop-types'

const { Text, Title } = Typography

const StatisticInfoModal = (props) => {
  const { statisticsKey, isModalOpen, setIsModalOpen } = props

  const { t } = useTranslations()

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  return (
    <Modal
      footer={null}
      title={<Title level={4}>{t('Info')}</Title>}
      open={isModalOpen}
      onCancel={handleCancel}>
      {STATISTIC_INFO?.[statisticsKey]?.map((item) => (
        <Row key={item?.title} className="mb-8">
          <Col span={24} className="mb-4">
            <Text className="medium">{t(item?.title)}:</Text>
          </Col>
          <Col span={24}>
            <Text>{t(item?.descriptionKey)}</Text>
          </Col>
        </Row>
      ))}
    </Modal>
  )
}
StatisticInfoModal.propTypes = {
  statisticsKey: PropTypes.string,
  isModalOpen: PropTypes.bool,
  setIsModalOpen: PropTypes.func
}
export default StatisticInfoModal
