import { ENDPOINTS } from 'modules/stripe-module/__constants__'
import { SETTINGS_PATHS } from '__constants__'

const API_URL = process.env.REACT_APP_FIREBASE_FUNCTIONS_API_URL

const createStripeAccountLink = async (accountId) => {
  try {
    const apiEndpoint = `${API_URL}/${ENDPOINTS.CREATE_STRIPE_ACCOUNT_LINK}`

    const result = await fetch(apiEndpoint, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        accountId,
        refreshUrl: `${window.location.origin}${SETTINGS_PATHS.STRIPE_ACCOUNT}`,
        returnUrl: `${window.location.origin}${SETTINGS_PATHS.STRIPE_ACCOUNT}`
      })
    })
    const accountLink = (await result.json()).data
    return accountLink
  } catch (error) {
    throw new Error(`Error during creating an account link: ${error}`)
  }
}

export default createStripeAccountLink
