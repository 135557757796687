import { Col, Row, Typography } from 'antd'
import {
  StyledCard,
  StyledRelativeImage
} from './StatisticProductSimpleView.styled'
import { useScreen, useTranslations } from 'hooks'

import PropTypes from 'prop-types'
import { currencySymbol } from 'domains/Product/hooks'
import { formatNumber, getTransformedImageUrl } from 'helpers'
import { GrowthPercentageView } from 'domains/Statistic/components'

const { Text } = Typography

const StatisticProductSimpleView = (props) => {
  const {
    name,
    previewImageDocument,
    pricePerDayWithFees,
    currency,
    totalRentPrice,
    totalRentNumber,
    yesterdayRentNumber,
    todayRentNumber
  } = props

  const { t } = useTranslations()
  const { isExtraSmallScreen } = useScreen()

  const transformedImageUrl = getTransformedImageUrl(previewImageDocument)
  const formattedTotalRentPrice = formatNumber({
    locales: 'en-US',
    number: totalRentPrice
  })
  const productCurrency = currencySymbol(currency)

  const colWidth = isExtraSmallScreen ? 'full-width' : ''
  const rowWrap = isExtraSmallScreen
    ? 'align-center'
    : 'flex-nowrap align-center'
  const rowPosition = isExtraSmallScreen ? 'justify-start' : 'justify-end'

  return (
    <StyledCard isExtraSmallScreen={isExtraSmallScreen}>
      <Row gutter={[12, 12]} className={rowWrap}>
        <Col className={colWidth}>
          <StyledRelativeImage
            isExtraSmallScreen={isExtraSmallScreen}
            ratio={1.5}
            src={transformedImageUrl}
            withOverlay={false}
          />
        </Col>
        <Col md={4}>
          <Text ellipsis>{name}</Text>
        </Col>
        <Col className="full-width">
          <Row className={rowPosition}>
            <Col xs={6} xl={4} className="flex flex-col">
              <Text type="secondary">{t('Growth')}</Text>
              <GrowthPercentageView
                firstValue={yesterdayRentNumber}
                secondValue={todayRentNumber}
              />
            </Col>
            <Col xs={5} xl={4} className="flex flex-col ">
              <Text type="secondary">{t('Rented')}</Text>
              <Text>{totalRentNumber}</Text>
            </Col>
            <Col xs={5} xl={4} className="flex flex-col">
              <Text type="secondary">{t('Price')}</Text>
              <Text>
                {pricePerDayWithFees} {productCurrency}
              </Text>
            </Col>
            <Col xs={7} xl={4} className="flex flex-col">
              <Text type="secondary">{t('Total earning')}</Text>
              <Text>
                {formattedTotalRentPrice} {productCurrency}
              </Text>
            </Col>
          </Row>
        </Col>
      </Row>
    </StyledCard>
  )
}

StatisticProductSimpleView.propTypes = {
  name: PropTypes.string,
  previewImageDocument: PropTypes.object,
  pricePerDayWithFees: PropTypes.number,
  currency: PropTypes.string,
  totalRentPrice: PropTypes.number,
  totalRentNumber: PropTypes.number,
  yesterdayRentNumber: PropTypes.number,
  todayRentNumber: PropTypes.number
}

export default StatisticProductSimpleView
