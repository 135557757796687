import { PageHeaderSimple, PageLayout } from 'components'
import { APP_PATHS } from '__constants__'
import { useLocation, useNavigate } from 'react-router-dom'
import { CheckoutSuccessAdvancedView } from 'domains/Checkout/components'

const CheckoutSuccess = () => {
  const navigate = useNavigate()

  const location = useLocation()

  const onBack = () => {
    navigate(APP_PATHS.DASHBOARD)
  }

  const handleClick = () => {
    const searchParams = new URLSearchParams(location.search)
    const checkoutUrl = searchParams.get('url')

    window.open(checkoutUrl, '_self')
  }

  return (
    <PageLayout topHeader={<PageHeaderSimple onlyLogo onBack={onBack} />}>
      <CheckoutSuccessAdvancedView onClick={handleClick} />
    </PageLayout>
  )
}

export default CheckoutSuccess
