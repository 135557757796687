import { Image } from 'antd'
import { LogoWrapper } from './MenuLogo.styled'
import PropTypes from 'prop-types'
import logo from '__assets__/icons/logo.svg'
import logoSmall from '__assets__/icons/logo-small.svg'

const MenuLogo = (props) => {
  const { collapsed } = props

  return (
    <LogoWrapper collapsed={collapsed}>
      <Image
        src={collapsed ? logoSmall : logo}
        className="cursor-pointer"
        preview={false}
        height={28}
        alt="logo"
        {...props}
      />
    </LogoWrapper>
  )
}

MenuLogo.propTypes = {
  collapsed: PropTypes.bool
}

export default MenuLogo
