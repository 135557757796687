import { useCallback, useMemo } from 'react'

import { Button } from 'antd'
import { Icon } from '@qonsoll/icons'
import { ImageStyled } from './UserImageUploader.styles'
import { ImageUploader } from 'components'
import PropTypes from 'prop-types'
import noProfileImage from '__assets__/illustrations/no-profile.png'
import { useState } from 'react'
import { useTranslations } from 'hooks'

const UserImageUploader = (props) => {
  const { value } = props

  const [file, setFile] = useState(null)

  const { t } = useTranslations()

  const getUploadFile64 = useCallback((file) => setFile(file), [])

  const computedButtonText = useMemo(
    () => (value ? t('Edit') : t('Upload')),
    [value, t]
  )

  const uploadAction = useMemo(
    () => (
      <Button
        className="flex align-center justify-center"
        size="medium"
        icon={<Icon name="Edit2Outlined" fill="currentColor" />}>
        {computedButtonText}
      </Button>
    ),
    [computedButtonText]
  )

  return (
    <>
      <ImageStyled
        className="mr-24"
        src={file || value || noProfileImage}
        preview={false}
        draggable={false}
      />
      <ImageUploader
        {...props}
        getUploadFile64={getUploadFile64}
        uploadAction={uploadAction}
        listType={false}
        placeholder={t('Please enter your user avatar')}
      />
    </>
  )
}
UserImageUploader.propTypes = {
  value: PropTypes.string
}
export default UserImageUploader
