import { useLocation } from 'react-router-dom'
import { useMemo } from 'react'

const useGetMenuData = (menuLinks, openedSubMenu) => {
  const location = useLocation()

  // Select menu item based on current location
  const selectedKeys = useMemo(() => {
    const { pathname } = location
    const selectedKey = menuLinks?.find((item) => pathname?.includes(item.key))

    // If the key was not found, search it in the submenu items.
    if (!selectedKey)
      return menuLinks
        ?.map((menuLink) => {
          const submenu = menuLink?.submenu
          // Skip the iteration if the submenu was not found.
          if (!submenu) return null

          const submenuLink = submenu?.find((submenuLink) =>
            pathname?.includes(submenuLink?.key)
          )
          return submenuLink ? [menuLink?.key, submenuLink?.key] : null
        })
        ?.filter(Boolean)?.[0]

    return selectedKey ? [selectedKey.key] : []
  }, [location, menuLinks])

  // Compute open tabs
  const defaultOpenKeys = useMemo(
    () =>
      selectedKeys?.includes(openedSubMenu)
        ? selectedKeys
        : [...(selectedKeys || []), openedSubMenu],
    [selectedKeys, openedSubMenu]
  )

  return { selectedKeys, defaultOpenKeys }
}

export default useGetMenuData
