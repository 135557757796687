import { Avatar, Col, Empty, Row, Typography } from 'antd'
import { CardDropdown, SelectableCard } from 'components'

import { COLLECTIONS } from '__constants__'
import PropTypes from 'prop-types'
import { updateDocument } from 'services/api/firebase/helpers'
import { useGetNotificationReceiverUserIdData } from 'domains/Notification/hooks'
import { useTranslations } from 'hooks'
import { useUserBaseActions } from 'domains/User/hooks'

const NotificationReceiverUserIdRelationView = (props) => {
  const { notification } = props

  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting notification receiverUserId */
  const [receiverUserId, loading] =
    useGetNotificationReceiverUserIdData(notification)
  /* Getting user base actions */
  const { onOpen, onEdit, showPath, editPath } = useUserBaseActions()

  /* Handling deletion of the item */
  const onDelete = async () => {
    await updateDocument(COLLECTIONS.NOTIFICATIONS, notification?._id, {
      receiverUserId: null
    })
  }

  /* Getting actions for relationship view */
  const actions = {
    onDelete,
    onEdit: editPath ? onEdit : null,
    onClick: showPath ? onOpen : null
  }

  return (
    <CardDropdown
      document={receiverUserId}
      data-testid="receiverUserIdListItem"
      {...actions}>
      <SelectableCard>
        {!receiverUserId && !loading && <Empty />}
        {receiverUserId && (
          <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
              className="mb-32"
              data-area="receiverUserId-details-avatar">
              <Avatar size={156} src={receiverUserId?.avatar} />
            </div>
            <div className="mb-12" data-area="receiverUserId-details-firstName">
              <Typography.Title level={5}>
                {receiverUserId?.firstName || 'n/a'}
              </Typography.Title>
            </div>
            <div className="mb-12" data-area="receiverUserId-details-lastName">
              <Typography.Title level={5}>
                {receiverUserId?.lastName || 'n/a'}
              </Typography.Title>
            </div>
            <div className="mb-12" data-area="receiverUserId-details-email">
              <Row gutter={16}>
                <Col flex="auto" maxWidth="100%">
                  <Typography.Text type="secondary">
                    {t('Email')}
                  </Typography.Text>
                </Col>
                <Col flex="none" maxWidth="100%">
                  <Typography.Text>
                    {receiverUserId?.email || 'n/a'}
                  </Typography.Text>
                </Col>
              </Row>
            </div>
            <div className="mb-12" data-area="receiverUserId-details-phone">
              <Row gutter={16}>
                <Col flex="auto" maxWidth="100%">
                  <Typography.Text type="secondary">
                    {t('Phone')}
                  </Typography.Text>
                </Col>
                <Col flex="none" maxWidth="100%">
                  <Typography.Text>
                    {receiverUserId?.phone || 'n/a'}
                  </Typography.Text>
                </Col>
              </Row>
            </div>
          </>
        )}
      </SelectableCard>
    </CardDropdown>
  )
}

NotificationReceiverUserIdRelationView.propTypes = {
  notification: PropTypes.object
}

export default NotificationReceiverUserIdRelationView
