import { Modal, Typography } from 'antd'

import { deleteProduct } from 'domains/Product/helpers'
import { WarningOutlined } from '@ant-design/icons'
import { Box } from 'components'
import {
  useGetUnresolvedComplaintByProductId,
  useGetUnresolvedOrderByProductId
} from 'domains/Product/hooks'
import { useTranslations } from 'hooks'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'

const { Text } = Typography

const ProductDeletionModal = ({ modalVisible, productId, setModalVisible }) => {
  const { t } = useTranslations()
  const navigate = useNavigate()
  const isExistUnresolvedComplaint =
    useGetUnresolvedComplaintByProductId(productId)
  const isExistUnresolvedOrder = useGetUnresolvedOrderByProductId(productId)
  const removeBtnDisabled = isExistUnresolvedComplaint || isExistUnresolvedOrder

  return (
    <Modal
      title={
        <>
          <WarningOutlined style={{ color: 'orange', marginRight: '8px' }} />
          {t('Confirm Deletion')}
        </>
      }
      centered
      visible={modalVisible}
      onOk={() => deleteProduct({ productId, t, navigate, setModalVisible })}
      onCancel={() => setModalVisible(false)}
      okText={t('Delete')}
      okButtonProps={{ disabled: removeBtnDisabled }}
      okType="danger">
      <Text>{t('Are you sure you want to delete this product')}?</Text>
      {removeBtnDisabled && (
        <Box display="flex" flexDirection="column" className="mt-12">
          {isExistUnresolvedComplaint && (
            <Text type="secondary">
              {t(
                'The product cannot be removed because it has an unclosed complaints'
              )}
              .
            </Text>
          )}
          {isExistUnresolvedOrder && (
            <Text type="secondary">
              {t(
                'The product cannot be removed because an order has been created for it'
              )}
              .
            </Text>
          )}
        </Box>
      )}
    </Modal>
  )
}

ProductDeletionModal.propTypes = {
  modalVisible: PropTypes.bool,
  productId: PropTypes.string,
  setModalVisible: PropTypes.func
}

export default ProductDeletionModal
