import { Box, PageHeaderSimple, PageLayout, LoadingBox } from 'components'
import { Button, Col, Row } from 'antd'

import { APP_PATHS } from '__constants__'
import { useNavigate, useLocation } from 'react-router-dom'
import { ReturnStripeAccountAdvancedView } from 'modules/stripe-module/domains/Account/components'
import { useTranslations, useHandleError } from 'hooks'
import { useCallback, useEffect, useState } from 'react'
import queryString from 'query-string'
import { getStripeAccountStatus } from 'modules/stripe-module/helpers'
import { useUser } from 'modules/session-module/contexts'
import { STRIPE_ACCOUNT_STATUSES } from '__constants__'

const ReturnStripeAccount = () => {
  const navigate = useNavigate()
  const { t } = useTranslations()
  const location = useLocation()
  const { user, loading: userLoading } = useUser()
  const handleError = useHandleError()

  const [isEdit, setIsEdit] = useState(false)
  const [loading, setLoading] = useState(true)

  const handleGetStripeAccountStatus = useCallback(
    async (stripeAccountId) => {
      try {
        setLoading(true)
        if (stripeAccountId) {
          const status = await getStripeAccountStatus(stripeAccountId)

          if (status !== STRIPE_ACCOUNT_STATUSES.ENABLED)
            throw Error(
              t('You have restricted a stripe account, please check data')
            )
        } else {
          throw Error(t("You don't have connected a stripe account"))
        }
      } catch (error) {
        handleError(error)
        navigate(APP_PATHS.REFRESH_STRIPE_ACCOUNT)
      } finally {
        setLoading(false)
      }
    },
    [handleError, navigate, t]
  )

  const handleAddProduct = () => navigate(APP_PATHS.PRODUCT_CREATE)
  const onBack = () => navigate(APP_PATHS.DASHBOARD)

  useEffect(() => {
    const query = location.search
    const isEdit = queryString.parse(query)?.isEdit

    setIsEdit(isEdit)
  }, [location])

  useEffect(() => {
    !userLoading && handleGetStripeAccountStatus(user?.stripeAccountId)
  }, [handleGetStripeAccountStatus, user, userLoading])

  return (
    <PageLayout topHeader={<PageHeaderSimple onlyLogo onBack={onBack} />}>
      <LoadingBox loading={loading}>
        <Box className="full-height flex align-center justify-center">
          <Row align="center">
            <Col span={24} className="mb-16">
              <ReturnStripeAccountAdvancedView isEdit={isEdit} />
            </Col>
            <Col align="center">
              <Button type="primary" onClick={handleAddProduct}>
                {t('Add product')}
              </Button>
            </Col>
          </Row>
        </Box>
      </LoadingBox>
    </PageLayout>
  )
}

export default ReturnStripeAccount
