import { Form } from 'antd'
import { useEffect } from 'react'
import { useUser } from 'modules/session-module/contexts'

const useInitializeComplaintAdvancedForm = () => {
  const { user } = useUser()

  /* Getting form instance */
  const [form] = Form.useForm()

  /* Setting initial data */
  useEffect(() => {
    /* Formatting date fields */
    const formValues = {
      firstName: user?.firstName ?? null,
      lastName: user?.lastName ?? null,
      email: user?.email ?? null,
      phone: user?.phone ?? null
    }
    /* Setting initial values to form instance */
    form.setFieldsValue(formValues)
  }, [form, user])
  return { form }
}

export default useInitializeComplaintAdvancedForm
