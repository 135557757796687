import { collection, orderBy, query, where } from 'firebase/firestore'

import { COLLECTIONS } from '__constants__'
import { calculateProductPriceByDays } from 'domains/Product/helpers'
import { firestore } from 'services/firebase'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { useMemo } from 'react'
import { useUser } from 'modules/session-module/contexts'

const useGetOrdersByUserAndStatus = () => {
  const { isRentee, user } = useUser()
  const orderUserRoleField = isRentee ? 'rentee' : 'renter'

  const queryComp = useMemo(
    () =>
      user?._id &&
      query(
        collection(firestore, COLLECTIONS.ORDERS),
        where(`${orderUserRoleField}._id`, '==', user?._id),
        orderBy('_createdAt', 'desc')
      ),
    [orderUserRoleField, user?._id]
  )

  const [orders, loading, error] = useCollectionData(queryComp)

  const preparedForSearchItems = useMemo(
    () =>
      orders?.map((item) => ({
        ...item,
        productName: item?.product?.name,
        totalPrice:
          calculateProductPriceByDays(item, item?.product, false)?.[0] ?? 0
      })),
    [orders]
  )

  return [preparedForSearchItems, loading, error]
}

export default useGetOrdersByUserAndStatus
