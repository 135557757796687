import { Box } from 'components'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { theme } from 'antd'

export const StyledCard = (props) => {
  const { children, ...rest } = props

  const {
    // default
    colorBorder,
    colorBgElevated,
    colorBgContainer,
    colorBorderSecondary
  } = theme.useToken().token

  const computedColorBorder = colorBorder || props.borderColor
  const computedColorBg = colorBgContainer || props.backgroundColor
  const computedColorBgHover = colorBgElevated || props.backgroundColorHover
  const computedBorderColorHover =
    colorBorderSecondary || props.borderColorHover

  const HoverableBox = styled(Box)`
    width: 100%;
    height: 100%;
    padding: 24px;
    transition: 0.3s;
    background-color: ${computedColorBg};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 1px dashed ${computedColorBorder};
    cursor: pointer;
    flex-direction: column;

    &:hover {
      background-color: ${computedColorBgHover};
      border-color: ${computedBorderColorHover};
    }
  `

  return <HoverableBox {...rest}>{children}</HoverableBox>
}

StyledCard.propTypes = {
  children: PropTypes.node.isRequired,
  borderColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  backgroundColorHover: PropTypes.string,
  borderColorHover: PropTypes.string
}
