import {
  OrderReviewSideDrawer,
  OrderSideDrawer
} from 'domains/Order/components'
import { useEffect, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import PropTypes from 'prop-types'
import { Table } from 'antd'
import { useDrawerActions } from './hooks'
import { useGetOrdersTableConfig } from 'domains/Order/components/OrderList/hooks'

const OrderList = (props) => {
  const { orders, orderId } = props
  const location = useLocation()
  const navigate = useNavigate()

  const {
    orderDrawerProps,
    reviewDrawerProps,
    onOrderSideDrawerOpen,
    onOrderSideDrawerClose,
    onReviewSideDrawerOpen,
    onReviewSideDrawerClose
  } = useDrawerActions()

  const { columns, paginationProps, tableProps } = useGetOrdersTableConfig(
    orders,
    onReviewSideDrawerOpen
  )

  const orderDrawerOpenRightShift = useMemo(
    () =>
      orderDrawerProps?.visibility && reviewDrawerProps?.visibility && '160px',
    [orderDrawerProps?.visibility, reviewDrawerProps?.visibility]
  )

  const orderToOpen = useMemo(() => {
    if (!orders || orders.length === 0) {
      return null
    }
    const foundOrder = orders.find((order) => order?._id === orderId)

    return foundOrder
  }, [orders, orderId])

  const cleanLocaleOnDrawerClose = () => {
    onOrderSideDrawerClose()
    navigate(location.pathname, {
      state: { ...location.state, orderId: null },
      replace: true
    })
  }

  // Effect to open the drawer for the specific order and role from notifications
  useEffect(() => {
    if (orderToOpen) {
      onOrderSideDrawerOpen(orderToOpen)
    }
  }, [onOrderSideDrawerOpen, orderToOpen])

  return (
    <>
      <Table
        rowClassName="cursor-pointer"
        onRow={(record) => ({
          onClick: () => onOrderSideDrawerOpen(record)
        })}
        dataSource={orders}
        columns={columns}
        pagination={paginationProps}
        {...tableProps}
      />
      <OrderSideDrawer
        rightShiftOpen={orderDrawerOpenRightShift}
        orderDrawerProps={orderDrawerProps}
        onReviewSideDrawerOpen={onReviewSideDrawerOpen}
        onOrderSideDrawerClose={cleanLocaleOnDrawerClose}
      />
      <OrderReviewSideDrawer
        reviewDrawerProps={reviewDrawerProps}
        onReviewSideDrawerClose={onReviewSideDrawerClose}
      />
    </>
  )
}

OrderList.propTypes = {
  orders: PropTypes.array,
  emptyProps: PropTypes.object,
  actions: PropTypes.any,
  rest: PropTypes.any,
  orderId: PropTypes.string,
  role: PropTypes.string
}

export default OrderList
