import { Bar, Line } from 'react-chartjs-2'
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip
} from 'chart.js'
import {
  CHART_TYPES,
  STATISTIC_RANGES_LABELS,
  STATISTIC_RANGES_OPTIONS
} from '__constants__'
import { Card, Col, Row, Select, Typography } from 'antd'

import PropTypes from 'prop-types'
import { useGetChartConfig } from 'domains/Statistic/hooks/configs'
import { useTranslations } from 'hooks'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
)

const StatisticChart = (props) => {
  const {
    type = CHART_TYPES.LINE,
    title,
    data,
    onRangeChange,
    range,
    description
  } = props

  const config = useGetChartConfig(data, type, range)
  config.options.maintainAspectRatio = false
  const isLineChart = type === CHART_TYPES.LINE

  const { t } = useTranslations()

  return (
    <Card className="full-height">
      <Row
        className="flex justify-between align-center"
        gutter={16}
        wrap={false}>
        <Col>
          <Typography.Title level={4}>
            {title || t('Rent Figures')}
          </Typography.Title>
          {description && (
            <Typography.Text type="secondary">{description}</Typography.Text>
          )}
        </Col>

        <Col>
          <Select
            onChange={onRangeChange}
            options={STATISTIC_RANGES_OPTIONS}
            style={{ minWidth: '100px' }}
            defaultValue={STATISTIC_RANGES_LABELS[`${range}`]}
            aria-label="Select statistic range"
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {isLineChart ? (
            <Line {...config} height={220} />
          ) : (
            <Bar {...config} height={220} />
          )}
        </Col>
      </Row>
    </Card>
  )
}

StatisticChart.propTypes = {
  type: PropTypes.string,
  description: PropTypes.string,
  title: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.number),
  onRangeChange: PropTypes.func,
  range: PropTypes.arrayOf(PropTypes.string)
}

export default StatisticChart
