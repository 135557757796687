import { Col, Row, Typography } from 'antd'
import { ColStyled } from './SubcategoriesItem.styled'
import PropTypes from 'prop-types'
import { useTranslations } from 'hooks'
import { useGetProductsNumber } from 'domains/Category/hooks'

const { Text } = Typography

const SubcategoriesItem = (props) => {
  const { name, names, groupedByParentId, _id, handleSelectSubcategory } = props

  const _subcategories =
    groupedByParentId?.[_id] && Object.values(groupedByParentId?.[_id])
  const showSubcategories = _subcategories?.length
  const { language } = useTranslations()
  const productsNumber = useGetProductsNumber(_id)

  return (
    <Col span={24}>
      <Row>
        <ColStyled span={24} className="py-8 px-16">
          <Text
            type="secondary"
            className="cursor-pointer"
            onClick={() => handleSelectSubcategory(_id)}>
            {names?.[language.toUpperCase()] || name}
          </Text>
          <Text type="secondary">({productsNumber})</Text>
        </ColStyled>

        {showSubcategories &&
          _subcategories?.map(({ name, _id }) => (
            <ColStyled span={24} key={_id} className="py-8 px-24">
              <Text
                className="cursor-pointer"
                onClick={() => handleSelectSubcategory(_id)}>
                {name}
              </Text>
              <Text>({productsNumber})</Text>
            </ColStyled>
          ))}
      </Row>
    </Col>
  )
}

SubcategoriesItem.propTypes = {
  name: PropTypes.array,
  names: PropTypes.object,
  groupedByParentId: PropTypes.func,
  _id: PropTypes.string,
  handleSelectSubcategory: PropTypes.func
}

export default SubcategoriesItem
