import { Badge, Menu } from 'antd'
import { useGetMenuData, useGetMenuLinks, useWrapMenuItems } from './hooks'

import { APP_PATHS } from '__constants__'
import { Icon } from '@qonsoll/icons'
import PropTypes from 'prop-types'
import { StyledMenu, StyledBadge } from './MainMenu.styled'
import { useLikedProducts } from 'contexts'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useStateWithStorage, useScreen } from 'hooks'

const MainMenu = (props) => {
  const { menuItems, ...rest } = props
  const [openedSubMenu, setOpenedSubMenu] = useStateWithStorage(
    null,
    'opened-sub-menu'
  )
  const { isSmallScreen } = useScreen()
  const navigate = useNavigate()

  const { productsAmount } = useLikedProducts()

  // Add menu manipulations here
  // Like filtering, sorting, etc.
  const menuLinks = useGetMenuLinks(menuItems, setOpenedSubMenu)

  const { selectedKeys, defaultOpenKeys } = useGetMenuData(
    menuLinks,
    openedSubMenu
  )
  const handleClick = (e) => navigate(e.key)

  // Add correct items props (icon)
  const wrappedMenuItems = useWrapMenuItems(
    menuLinks,
    selectedKeys,
    handleClick
  )

  const customCalculations = useMemo(
    () => ({
      [APP_PATHS.FAVORITE_PRODUCTS]: productsAmount
    }),
    [productsAmount]
  )

  return (
    <StyledMenu
      fontSize="60"
      mode="inline"
      triggerSubMenuAction="click"
      defaultSelectedKeys={selectedKeys}
      defaultOpenKeys={defaultOpenKeys}
      selectedKeys={selectedKeys}
      {...rest}>
      {wrappedMenuItems?.map(({ key, iconName, label, ...restProps }) => {
        const count = customCalculations?.[key]

        const icon =
          count && isSmallScreen ? (
            <StyledBadge count={count} size="small">
              <Icon name={iconName} size={16} fill="currentColor" />
            </StyledBadge>
          ) : (
            <Icon name={iconName} size={16} fill="currentColor" />
          )

        return (
          <Menu.Item key={key} {...restProps} icon={icon} title={null}>
            <div className="flex align-center justify-between">
              {label}
              {count ? <Badge count={count} /> : null}
            </div>
          </Menu.Item>
        )
      })}
    </StyledMenu>
  )
}

MainMenu.propTypes = {
  height: PropTypes.string,
  menuItems: PropTypes.array
}

export default MainMenu
