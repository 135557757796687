import { Col, Row, Typography } from 'antd'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Spin } from 'antd'
import { urlParamsStringIntoParamsObject } from 'helpers'
import { useLoginWithVipps } from 'modules/session-module/hooks'
import { useTranslations } from 'hooks'
import { useUser } from 'modules/session-module/contexts'

const VippsCallback = () => {
  const [isLoginCalled, setIsLoginCalled] = useState(false)
  const location = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslations()

  const urlParamsObject = urlParamsStringIntoParamsObject(location.search)
  const { user, loading } = useUser()
  const loginWithVipps = useLoginWithVipps()

  // Vipps login
  useEffect(() => {
    if (!isLoginCalled && urlParamsObject?.code && !loading) {
      const { code, state } = urlParamsObject
      setIsLoginCalled(true)
      loginWithVipps(code, state, user?._id)
    }
  }, [
    urlParamsObject,
    loginWithVipps,
    isLoginCalled,
    navigate,
    loading,
    user?._id
  ])

  return (
    <Row align="middle" justify="center" className="full-height">
      <Col span={12}>
        <div className="flex justify-center mb-24">
          <Spin size="large" />
        </div>
        <div className="flex justify-center">
          <Typography.Text textAlign="center">
            {t(
              'Your account is being verified, please wait, the process will not take more than 10 seconds'
            )}
          </Typography.Text>
        </div>
      </Col>
    </Row>
  )
}

export default VippsCallback
