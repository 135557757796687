import { useEvent, useTranslations } from 'hooks'
import { Typography } from 'antd'
import { Icon, Box } from 'components'
import { SETTINGS_PATHS } from '__constants__'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useUser } from 'modules/session-module/contexts'
import { useGetConnectedAccount } from 'modules/stripe-module/domains/Connect/hooks'
import { VERIFICATION_STATUSES_KEYS } from 'modules/stripe-module/__constants__/verificationStatuses'

const { Text } = Typography

const {
  MY_PROFILE,
  CHANGE_PASSWORD,
  NOTIFICATIONS,
  STRIPE_ACCOUNT,
  VERIFICATION,
  ADDRESSES_ALL,
  CONTACT_US
} = SETTINGS_PATHS

const useGetSettingsMenu = () => {
  const { t } = useTranslations()
  const navigate = useNavigate()
  const { user } = useUser()
  const [connectedAccount] = useGetConnectedAccount(user?.stripeAccountId)

  const handleClick = useEvent((e) => navigate(e.key))

  const isStripeAccountUnverified = connectedAccount
    ? connectedAccount?.individual?.verification?.status ===
      VERIFICATION_STATUSES_KEYS.UNVERIFIED
    : false

  const dropdownMenu = useMemo(
    () => [
      {
        key: MY_PROFILE,
        label: t('My Profile'),
        onClick: handleClick,
        icon: (
          <div>
            <Icon size={16} name="User3Outlined" />
          </div>
        )
      },
      {
        key: CHANGE_PASSWORD,
        label: t('Change password'),
        onClick: handleClick,
        icon: (
          <div>
            <Icon size={16} name="Key1Outlined" />
          </div>
        )
      },
      {
        key: NOTIFICATIONS,
        label: t('Notifications'),
        onClick: handleClick,
        icon: (
          <div>
            <Icon size={16} name="Bell1Outlined" />
          </div>
        )
      },
      {
        key: STRIPE_ACCOUNT,
        label: (
          <Box className="flex align-center justify-between">
            <Text>{t('Stripe account')}</Text>
            {isStripeAccountUnverified && (
              <Icon name="AlertDangerWarningFilled" fill="#faad14" />
            )}
          </Box>
        ),
        onClick: handleClick,
        icon: (
          <div>
            <Icon size={16} name="Bank3Outlined" />
          </div>
        )
      },
      {
        key: VERIFICATION,
        label: t('Verification'),
        onClick: handleClick,
        icon: (
          <div>
            <Icon size={16} name="CheckVerified1Outlined" />
          </div>
        )
      },
      {
        key: ADDRESSES_ALL,
        label: t('Address'),
        onClick: handleClick,
        icon: (
          <div>
            <Icon size={16} name="MarkerPin1Outlined" />
          </div>
        )
      },
      {
        key: CONTACT_US,
        label: t('Contact us'),
        onClick: handleClick,
        icon: (
          <div>
            <Icon size={16} name="PhoneCall1Outlined" />
          </div>
        )
      }
    ],
    [handleClick, isStripeAccountUnverified, t]
  )

  return dropdownMenu
}

export default useGetSettingsMenu
