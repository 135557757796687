import { Input, Typography } from 'antd'

import { Box } from 'components'
import PropTypes from 'prop-types'
import { finalCheck } from './helpers'
import moment from 'moment'
import { useTranslations } from 'hooks'

const DateRangeInputs = (props) => {
  const { range, onChange } = props

  const { t } = useTranslations()

  const handleChangeStartDate = (event) => {
    const date = event.target.value
    const isValid = moment(date).isValid()
    isValid &&
      onChange?.({
        startDate: moment(date).toDate()
      })
  }

  const handleChangeEndDate = (event) => {
    const date = event.target.value
    const isValid = moment(date).isValid()
    isValid && onChange?.({ endDate: moment(date).toDate() })
  }

  const handleClick = (event) => event.preventDefault()

  const handleFinalCheck = () => finalCheck(range, t, onChange)

  return (
    <Box className="flex align-center gap-8">
      <Input
        value={moment(range?.startDate).format('YYYY-MM-DD')}
        allowClear
        type="date"
        className="flex-1"
        onChange={handleChangeStartDate}
        onClick={handleClick}
        onBlur={handleFinalCheck}
        onPressEnter={handleFinalCheck}
      />
      <Typography.Text type="secondary">-</Typography.Text>
      <Input
        value={moment(range?.endDate).format('YYYY-MM-DD')}
        allowClear
        type="date"
        className="flex-1"
        onChange={handleChangeEndDate}
        onClick={handleClick}
        onBlur={handleFinalCheck}
        onPressEnter={handleFinalCheck}
      />
    </Box>
  )
}

DateRangeInputs.propTypes = {
  range: PropTypes.object,
  onChange: PropTypes.func
}

export default DateRangeInputs
