import { Button, Dropdown, Typography } from 'antd'
import { useCallback, useMemo } from 'react'

import { Icon } from '@qonsoll/icons'
import PropTypes from 'prop-types'
import styles from './ButtonDropdown.styles'
import { useTranslations } from 'hooks'

const ButtonDropdown = (props) => {
  const { onEdit, onDelete, onOpen } = props

  const { t } = useTranslations()

  const onClick = useCallback(
    (callback) => (e) => {
      e.domEvent.stopPropagation()
      callback?.()
    },
    []
  )

  const dropdownActions = useMemo(
    () =>
      [
        {
          key: 'open',
          onClick: onOpen && onClick(onOpen),
          label: (
            <div {...styles.labelWrapper}>
              <Icon name="EyeOutlined" {...styles.iconProps} />
              <Typography.Text {...styles.textProps}>
                {t('Open')}
              </Typography.Text>
            </div>
          )
        },
        {
          key: 'edit',
          onClick: onEdit && onClick(onEdit),
          label: (
            <div {...styles.labelWrapper}>
              <Icon name="Edit3Outlined" {...styles.iconProps} />
              <Typography.Text {...styles.textProps}>
                {t('Edit')}
              </Typography.Text>
            </div>
          )
        },
        {
          key: 'delete',
          danger: true,
          onClick: onDelete && onClick(onDelete),
          label: (
            <div {...styles.labelWrapper}>
              <Icon name="Trash2Outlined" {...styles.iconProps} />
              <Typography.Text {...styles.textProps}>
                {t('Delete')}
              </Typography.Text>
            </div>
          )
        }
      ]?.filter(({ onClick }) => onClick),
    [onDelete, onEdit, onOpen, t, onClick]
  )

  const dropdownProps = useMemo(
    () => ({
      placement: 'top',
      arrow: true,
      menu: {
        items: dropdownActions
      },
      trigger: 'click'
    }),
    [dropdownActions]
  )

  return (
    <Dropdown {...dropdownProps}>
      <Button
        {...styles.buttonProps}
        onClick={(e) => e.stopPropagation()}
        icon={<Icon name="DotsVerticalOutlined" fill="currentColor" />}
      />
    </Dropdown>
  )
}

ButtonDropdown.propTypes = {
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onOpen: PropTypes.func
}
export default ButtonDropdown
