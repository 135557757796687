import { COLLECTIONS, PRODUCT_STATUSES } from '__constants__'
import { collection, query, where, orderBy } from 'firebase/firestore'

import { firestore } from 'services/firebase'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { useMemo } from 'react'
import { useMergeProductMediaObjects } from 'domains/Product/hooks'
import { useUser } from 'modules/session-module/contexts'

const useGetMyProducts = () => {
  const { user } = useUser()

  const [publishedProducts, loadingPublishedProducts] = useCollectionData(
    user?._id &&
      query(
        collection(firestore, COLLECTIONS.PRODUCTS),
        where('_createdBy', '==', user?._id),
        where('status', '==', PRODUCT_STATUSES.PUBLISHED),
        orderBy('_createdAt', 'desc')
      )
  )
  const [draftProducts, loadingDraftProducts] = useCollectionData(
    user?._id &&
      query(
        collection(firestore, COLLECTIONS.PRODUCTS),
        where('_createdBy', '==', user?._id),
        where('status', '==', PRODUCT_STATUSES.DRAFT),
        orderBy('_createdAt', 'desc')
      )
  )

  const [blockedProducts, loadingBlockedProducts] = useCollectionData(
    user?._id &&
      query(
        collection(firestore, COLLECTIONS.PRODUCTS),
        where('_createdBy', '==', user?._id),
        where('status', '==', PRODUCT_STATUSES.BLOCKED),
        orderBy('_createdAt', 'desc')
      )
  )

  const [transformedDraftProducts, transformedDraftLoading] =
    useMergeProductMediaObjects(draftProducts)

  const [transformedPublishedProducts, transformedPublishedLoading] =
    useMergeProductMediaObjects(publishedProducts)

  const [transformedBlockedProducts, transformedBlockedLoading] =
    useMergeProductMediaObjects(blockedProducts)

  const loadings = useMemo(
    () =>
      loadingDraftProducts ||
      loadingPublishedProducts ||
      loadingBlockedProducts ||
      transformedDraftLoading ||
      transformedPublishedLoading ||
      transformedBlockedLoading,
    [
      loadingDraftProducts,
      loadingPublishedProducts,
      loadingBlockedProducts,
      transformedDraftLoading,
      transformedPublishedLoading,
      transformedBlockedLoading
    ]
  )
  const productsAmount = useMemo(() => {
    const drafts = transformedDraftProducts?.length || 0
    const published = transformedPublishedProducts?.length || 0
    const blocked = transformedBlockedProducts?.length || 0
    return !loadings ? drafts + published + blocked : 0
  }, [
    loadings,
    transformedBlockedProducts,
    transformedDraftProducts,
    transformedPublishedProducts
  ])

  return [
    {
      draftProducts: transformedDraftProducts,
      publishedProducts: transformedPublishedProducts,
      blockedProducts: transformedBlockedProducts,
      productsAmount
    },
    loadings
  ]
}

export default useGetMyProducts
