import { Typography } from 'antd'
import styled from 'styled-components'

const TextStyled = styled(Typography.Text)`
  color: ${({ color }) => color};
  font-size: ${({ fontSize }) => `${fontSize}px`} !important;
`

const verticalSpace = { size: 16, direction: 'vertical', align: 'center' }

const styles = {
  descriptionWrapper: {
    style: { maxWidth: '500px' }
  },
  infoSpace: {
    ...verticalSpace,
    className: 'flex text-align-center my-32'
  },
  actionsSpace: {
    ...verticalSpace,
    className: 'mb-16 flex justify-center'
  },

  image: {
    draggable: false,
    width: '25%',
    preview: false,
    alt: 'Confirmation'
  }
}

export { TextStyled, styles }
