import { Collapse, Typography } from 'antd'

import styled from 'styled-components'

const StyledText = styled(Typography.Text)`
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  color: ${({ equalId, color }) => (equalId ? color : 'inherit')};
`
const StyledCollapse = styled(Collapse)`
  .ant-collapse-item {
    border: none !important;
  }
  width: 100%;
  &.ant-collapse {
    width: 100%;
    background-color: transparent;
  }

  .ant-modal-body {
  }
`
const StyledPanel = styled(Collapse.Panel)`
border-radius: ${({ borderRadiusLG }) =>
  borderRadiusLG ? `${borderRadiusLG} !important` : 'unset'}};
}
  .ant-collapse-header {
    background-color: ${({ equalId, colorPrimaryBg }) =>
      equalId ? colorPrimaryBg : 'transparent'};
    border-radius: ${({ borderRadiusLG }) =>
      borderRadiusLG ? `${borderRadiusLG} !important` : 'unset'}};
  }
`

export { StyledText, StyledCollapse, StyledPanel }
