import { COLLECTIONS } from '__constants__'
import { doc } from 'firebase/firestore'
import { firestore } from 'services/firebase'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { useLoading } from 'hooks'
import { useMemo } from 'react'
import { useUser } from 'modules/session-module/contexts'

const useGetAddressInitialValues = (id) => {
  const { user, loading: userLoading } = useUser()

  /* Fetching data  hook */
  const docRef = id && doc(firestore, COLLECTIONS.ADDRESSES, id)

  const [address, addressLoading] = useDocumentData(docRef)

  /* Merge all fetched data */
  const initialValues = useMemo(
    () => ({
      address,
      user
    }),
    [address, user]
  )

  /* Merge all loading states */
  const loadings = useMemo(
    () => (id ? [!user, userLoading, addressLoading] : []),
    [user, userLoading, addressLoading, id]
  )

  /* Getting loading state using merged loadings */
  const loading = useLoading(loadings)

  return [initialValues, loading]
}

export default useGetAddressInitialValues
