import styled from 'styled-components'

const CartBody = styled.div`
  max-height: ${({ maxBodyHeight }) => maxBodyHeight || '500px'};
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 0px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 20px;
  }
  &::-webkit-scrollbar-button {
    height: calc(var(--btn-border-radius-base) / 2);
  }
  &::-webkit-scrollbar-thumb:hover {
    background: var(--ql-color-dark-t-lighten4);
  }
`

export { CartBody }
