import { Avatar, Badge, Dropdown, theme } from 'antd'

import { APP_PATHS } from '__constants__'
import AvatarWrapper from './AccountMenu.styles'
import { Box } from 'components'
import { Icon } from '@qonsoll/icons'
import { ModeSelect } from 'components/layouts/AppLayout/components'
import { useAccountMenuActions } from 'components/layouts/AppLayout/components/AccountMenu/hooks'
import { useCart } from 'contexts'
import { useNavigate } from 'react-router-dom'
import { useTranslations } from 'hooks'
import { useUser } from 'modules/session-module/contexts'

const { CART, SETTINGS } = APP_PATHS

const AccountMenu = () => {
  const { user, isRentee } = useUser()
  const { t } = useTranslations()
  const navigate = useNavigate()
  const { confirmLogout } = useAccountMenuActions()
  const { cartItems } = useCart()

  const { colorPrimary, colorBgContainer } = theme.useToken().token

  const { avatarUrl, isVerified } = user || {}

  const items = [
    { key: 'mode', label: <ModeSelect /> },
    {
      key: 'my-profile',
      href: SETTINGS,
      label: t('Settings'),
      icon: <Icon name="Settings2Outlined" />,
      onClick: () => navigate(SETTINGS)
    },
    isRentee && {
      key: 'cart',
      label: (
        <Box className="flex justify-between align-center gap-8">
          {t('Cart')}
          <Badge count={cartItems?.length} size="small" />
        </Box>
      ),
      icon: <Icon name="ShoppingCart1Outlined" />,
      onClick: () => navigate(CART)
    },
    {
      key: 'log-out',
      label: t('Log out'),
      icon: <Icon name="LogOut2Outlined" fill="currentColor" />,
      danger: true,
      onClick: confirmLogout
    }
  ]?.filter((value) => Boolean(value))

  return (
    <Dropdown
      trigger="click"
      menu={{
        items
      }}>
      <AvatarWrapper colorBgContainer={colorBgContainer}>
        <Avatar src={avatarUrl} className="cursor-pointer" size={32} />
        {isVerified && (
          <Icon
            name="CheckVerified2Filled"
            size={14}
            fill={colorPrimary}
            className="verification"
          />
        )}
      </AvatarWrapper>
    </Dropdown>
  )
}

export default AccountMenu
