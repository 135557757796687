const PRODUCT_LIKES = 'product_likes'
const PRODUCT_RATINGS = 'product_ratings'
const RENT_DATES = 'rent_dates'
const PRODUCTS_NUMBERS_BY_CATEGORY = 'products_numbers_by_category'
const PRODUCT_LIKES_BY_USERS = 'product_likes_by_users'
const STATISTICS_BY_DAYS = 'statistics_by_days'
const STATISTICS_BY_MONTHS = 'statistics_by_months'

const RDB_COLLECTIONS = {
  PRODUCT_LIKES,
  PRODUCT_RATINGS,
  RENT_DATES,
  PRODUCT_LIKES_BY_USERS,
  STATISTICS_BY_DAYS,
  STATISTICS_BY_MONTHS,
  PRODUCTS_NUMBERS_BY_CATEGORY
}

export { RDB_COLLECTIONS }
