const getUserFullName = (firstName, lastName) => {
  const computedFirstName = firstName ? firstName?.trim() : ''
  const computedLastName = firstName ? lastName?.trim() : ''

  return computedFirstName || computedLastName
    ? `${firstName} ${lastName}`?.trim()
    : 'n/a'
}

export default getUserFullName
