import './ServiceLayout.css'

import { Col, Layout, Row } from 'antd'

import PropTypes from 'prop-types'

const ServiceLayout = (props) => {
  const { children } = props

  return (
    <Layout className="service-layout">
      <Layout.Content>
        <Row justify="center" align="middle" style={{ height: '100%' }}>
          <Col lg={6} xs={24} sm={12}>
            {children}
          </Col>
        </Row>
      </Layout.Content>
    </Layout>
  )
}

ServiceLayout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string
}

export default ServiceLayout
