import { Breadcrumbs, PageActions, PageLayout, Spinner } from 'components'
import { COLLECTIONS, PATHS } from '__constants__'
import { generatePath, useNavigate, useParams } from 'react-router-dom'

import { OrderAdvancedView } from 'domains/Order/components'
import { useListenDocument } from 'services/api/firebase'
import { useTranslations } from 'hooks'

const OrderShowPage = (props) => {
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting navigate instance */
  const navigate = useNavigate()

  /* Getting params from router */
  const params = useParams()
  /* Fetching document data from useDocument hook */
  const [order, loading] = useListenDocument({
    ref: COLLECTIONS.ORDERS + '/' + params.orderId
  })

  /* Getting id from params */
  const { orderId } = params

  /* Configuring page actions */
  const actions = { edit: !!PATHS.AUTHENTICATED.ORDER_EDIT }

  /* Function to handle back click */
  const handleBack = () => navigate(-1)
  /* Handling opening of the edit page */
  const handleEdit = () => {
    const pathParams = { orderId }
    const path = generatePath(PATHS.AUTHENTICATED.ORDER_EDIT, pathParams)
    navigate(path)
  }

  return (
    <PageLayout
      actions={<PageActions actions={actions} onEdit={handleEdit} />}
      onBack={handleBack}
      breadcrumbs={<Breadcrumbs />}
      area="order">
      {loading ? (
        <Spinner height="70vh" text={t('Order loading')} />
      ) : (
        <div
          style={{ position: 'relative' }}
          className="full-height full-width mb-24">
          <OrderAdvancedView order={order} />
        </div>
      )}
    </PageLayout>
  )
}

export default OrderShowPage
