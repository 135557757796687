import { Tabs } from 'antd'
import styled from 'styled-components'

const TabsStyled = styled(Tabs)`
  .ant-tabs-tab {
    padding-top: 0 !important;
  }

  .ant-tabs-nav {
    margin-bottom: 24px !important;
  }
`
const TabPaneStyled = styled(Tabs.TabPane)``
export { TabsStyled, TabPaneStyled }
