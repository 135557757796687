import { Card, Col, Row, Typography } from 'antd'

import { Avatar } from 'components'
import PropTypes from 'prop-types'
import { useTranslations } from 'hooks'

const CustomerAdvancedView = (props) => {
  const { customer } = props

  const { t } = useTranslations()

  return (
    <Row gutter={{ xs: 8, sm: 16, md: 24 }} data-area="customer-details">
      <Col span={8} mb={3} data-area="customer-details-info">
        <Card className="mb-32">
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
            className="mb-32"
            data-area="customer-details-avatarUrl">
            <Avatar size={156} src={customer?.avatarUrl} />
          </div>
          <div className="mb-12" data-area="customer-details-firstName">
            <Typography.Title level={5}>
              {customer?.firstName || 'n/a'}
            </Typography.Title>
          </div>
          <div className="mb-12" data-area="customer-details-lastName">
            <Typography.Title level={5}>
              {customer?.lastName || 'n/a'}
            </Typography.Title>
          </div>
          <div className="mb-12" data-area="customer-details-email">
            <Row gutter={16}>
              <Col flex="auto" maxWidth="100%">
                <Typography.Text type="secondary">{t('Email')}</Typography.Text>
              </Col>
              <Col flex="none" maxWidth="100%">
                <Typography.Text>{customer?.email || 'n/a'}</Typography.Text>
              </Col>
            </Row>
          </div>
          <div className="mb-12" data-area="customer-details-phone">
            <Row gutter={16}>
              <Col flex="auto" maxWidth="100%">
                <Typography.Text type="secondary">{t('Phone')}</Typography.Text>
              </Col>
              <Col flex="none" maxWidth="100%">
                <Typography.Text>{customer?.phone || 'n/a'}</Typography.Text>
              </Col>
            </Row>
          </div>
        </Card>
      </Col>
    </Row>
  )
}

CustomerAdvancedView.propTypes = {
  customer: PropTypes.object
}

export default CustomerAdvancedView
