import { COLLECTIONS, UPLOADING_STATUSES } from '__constants__'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import { useCallback, useState } from 'react'

import PropTypes from 'prop-types'
import UploadContext from './UploadContext'
import { deleteDocument } from 'services/api/firebase'
import { getId } from 'services/api/firebase'
import { message } from 'antd'
import { storage } from 'services/firebase'
import { useTranslations } from 'hooks'

const uploaderRef = (fileId, path) => {
  return ref(storage, `${path}/${fileId}`)
}

const UploadProvider = (props) => {
  const { children } = props
  const { t } = useTranslations()

  const [uploadedFiles, setUploadedFiles] = useState(null)

  const useMediaUpload = ({ value, onChange, form, formGroup }) => {
    const handleChange = ({ fileList: newFileList }) => onChange(newFileList)

    /* A callback function that is called when the upload fails. */
    const errorUploadingFile = (file) =>
      onChange(
        value?.map((item) => {
          const { uid } = item
          if (uid === file.uid)
            return {
              ...item,
              percent: 100,
              status: UPLOADING_STATUSES.ERROR
            }

          return item
        })
      )

    /* A callback function that is called when the upload is successful. */
    const completedUploadingFile = async (uploadMedia, file, fileId) => {
      const formValues = form.getFieldValue(formGroup)

      const computedMediaObject = formValues?.find(
        (item) => item?.uid === file?.uid
      )

      const downloadUrl = await getDownloadURL(uploadMedia.snapshot.ref)
      const fileData = {
        _id: fileId,
        name: file.name,
        type: file.type,
        url: downloadUrl,
        uid: file?.uid,
        size: file?.size,
        isCoverImage: computedMediaObject?.isCoverImage ?? null
      }

      return fileData
    }

    const processUploading = (snapshot, file) => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      const watchedValue = form.getFieldValue(formGroup)
      if (watchedValue?.length)
        form?.setFieldValue(
          ['product', 'mediaObjects'],
          watchedValue?.map((item) =>
            item?.uid === file.uid
              ? {
                  ...item,
                  percent: Number.parseInt(progress.toFixed(0)),
                  status:
                    snapshot.state === UPLOADING_STATUSES.RUNNING
                      ? UPLOADING_STATUSES.UPLOADING
                      : snapshot.state
                }
              : item
          )
        )
    }

    /* A callback function that is used to upload the file to the storage. */
    const onUpload = useCallback(
      async (data, path) => {
        const { file } = data
        try {
          const fileId = getId(COLLECTIONS.MEDIA_OBJECTS)
          const storageRef = uploaderRef(fileId, path)
          const uploadMedia = uploadBytesResumable(storageRef, file)
          const gettingFile = await new Promise((resolve, reject) => {
            uploadMedia?.on(
              'state_changed',
              /* Updating the state of the file. */
              (snapshot) => processUploading(snapshot, file),
              /* A callback function that is called when the upload fails. */
              () => {
                errorUploadingFile(file)
                reject()
              },
              /* A callback function that is called when the upload is successful. */
              async () => {
                const gettingFile = await completedUploadingFile(
                  uploadMedia,
                  file,
                  fileId
                )
                resolve(gettingFile)
              }
            )
          })

          return await gettingFile
        } catch {
          errorUploadingFile(file)
        } finally {
          setUploadedFiles(null)
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    )

    const handleUpload = useCallback(
      async (uploadedFiles, path) => {
        if (!uploadedFiles) return []

        const uploadedFilesPromises = uploadedFiles?.map(
          async (uploadData) => await onUpload(uploadData, path)
        )

        const result = await Promise.all(uploadedFilesPromises)

        return result
      },
      [onUpload]
    )

    // It removes files from storage and local storage
    const handleRemoveFromStorage = async (fileToDelete, path) => {
      try {
        await deleteDocument(COLLECTIONS.MEDIA_OBJECTS, fileToDelete?._id)
        return fileToDelete
      } catch (error) {
        message.error(`${t('Some problem with deleting files')}: ${error}`)
      }
    }

    const handleRemoveFiles = useCallback(async (deleteFiles, path) => {
      if (!deleteFiles) return []
      const deleteFilesPromises = deleteFiles?.map(
        async (item) => await handleRemoveFromStorage(item, path)
      )

      return await Promise.all(deleteFilesPromises)
    }, [])

    const customRequest = (uploadFile) => {
      setUploadedFiles((prev) => [...(prev || []), uploadFile])
      setTimeout(() => {
        uploadFile.status = 'uploadFile'
        uploadFile.percent = 0
      }, 0)
    }

    return {
      onUpload,
      customRequest,
      handleUpload,
      handleRemoveFiles,
      handleChange
    }
  }

  return (
    <UploadContext.Provider
      value={{
        useMediaUpload,
        uploadedFiles,
        setUploadedFiles
      }}>
      {children}
    </UploadContext.Provider>
  )
}

UploadProvider.propTypes = {
  children: PropTypes.element.isRequired
}

export default UploadProvider
