import RenteeDashboard from './RenteeDashboard'
import RenterDashboard from './RenterDashboard'
import { useUser } from 'modules/session-module/contexts'

const Dashboard = () => {
  const { isRentee } = useUser()

  return isRentee ? <RenteeDashboard /> : <RenterDashboard />
}

export default Dashboard
