import PropTypes from 'prop-types'
import { Table } from 'antd'
import { useGetProductsTableConfig } from 'domains/Product/hooks'
import { Fragment, useState } from 'react'
import { ProductDeletionModal } from 'domains/Product/components'

const ProductTable = (props) => {
  const {
    isDraftsPage,
    products,
    onDraftsToPublishSelect,
    selectedDrafts,
    isRentee,
    total,
    pageSize,
    handlePageChange,
    currentPage
  } = props

  const [modalVisible, setModalVisible] = useState(false)

  const { columns, paginationStyles, tableProps } = useGetProductsTableConfig({
    onDraftsToPublishSelect,
    selectedDrafts,
    withCheckboxes: isDraftsPage,
    isRentee,
    total,
    pageSize,
    handlePageChange,
    currentPage,
    setModalVisible
  })

  const [selectedProductId, setSelectedProductId] = useState(null)

  const handleRowClick = (record) => {
    setSelectedProductId(record?._id)
  }

  tableProps.onRow = (record, rowIndex) => {
    return {
      onClick: () => handleRowClick(record)
    }
  }

  return (
    <Fragment>
      <Table
        dataSource={products}
        columns={columns}
        pagination={paginationStyles}
        {...tableProps}
      />
      <ProductDeletionModal
        modalVisible={modalVisible}
        productId={selectedProductId}
        setModalVisible={setModalVisible}
      />
    </Fragment>
  )
}

ProductTable.propTypes = {
  isDraftsPage: PropTypes.bool,
  products: PropTypes.array,
  onDraftsToPublishSelect: PropTypes.func,
  selectedDrafts: PropTypes.array,
  isRentee: PropTypes.bool,
  total: PropTypes.number,
  pageSize: PropTypes.number,
  handlePageChange: PropTypes.func,
  currentPage: PropTypes.number
}

export default ProductTable
