const MOMENT_FORMATS = {
  MONTH_DAYS_YEAR: 'MMM DD YYYY',
  DAYS_MONTH_YEAR: 'DD MM YYYY',
  DAY_MONTH: 'DD MMM',
  MONTH_YEAR: 'MMM YYYY',
  DATE_MONTH_STR_YEAR: 'D MMM YYYY',
  HOURS_MINUTES: 'HH:mm',
  DATE_FORMATS: 'DD MMM YYYY, HH:mm',
  DATE_FORMATS_SHORT: 'MMM Do YY',
  DAYS_MONTH_YEAR_WITH_DOTS: 'DD.MM.YYYY',
  DAY_MONTH_YEAR: 'DD-MMMM-YYYY' // use for datepicker
}

export { MOMENT_FORMATS }
export default MOMENT_FORMATS
