import { Button, Col, Image, Row, Space, Typography } from 'antd'
import { useCallback, useEffect } from 'react'

import { APP_PATHS } from '__constants__/paths'
import stripePaymentImage from '__assets__/illustrations/stripe-payment.svg'
import { useNavigate } from 'react-router-dom'
import { useTranslations } from 'hooks'
import { useUser } from 'modules/session-module/contexts'

let checkoutSuccessTimeout

const CheckoutSessionSuccess = () => {
  const { t } = useTranslations()
  const navigate = useNavigate()
  const { setIsRentee } = useUser()

  const handleClick = useCallback(() => {
    clearTimeout(checkoutSuccessTimeout)
    setIsRentee(true)
    navigate(APP_PATHS.ORDERS_ALL)
  }, [navigate, setIsRentee])

  useEffect(() => {
    let isMounted = true
    if (isMounted && !checkoutSuccessTimeout) {
      checkoutSuccessTimeout = setTimeout(handleClick, 10000)
    }
    return () => (isMounted = false)
  }, [handleClick])

  return (
    <Space
      direction="vertical"
      block
      align="center"
      size={48}
      className="full-height justify-center">
      <div className="flex justify-center align-center">
        <Image
          src={stripePaymentImage}
          preview={false}
          draggable={false}
          width="45%"
        />
      </div>

      <Row justify="center">
        <Col sm={20} md={16}>
          <Typography.Title level={2} align="center" className="mb-8">
            {`${t('Payment successfully created')}!`}
          </Typography.Title>
          <Typography.Text align="center" className="flex justify-center mb-16">
            {`${t(
              'The payment has been successfully processed, and the funds will be debited from your account and held until further actions are taken'
            )}.`}
          </Typography.Text>

          <div className="flex justify-center">
            <Button onClick={handleClick} type="primary">
              {t('Go to orders')}
            </Button>
          </div>
        </Col>
      </Row>
    </Space>
  )
}

export default CheckoutSessionSuccess
