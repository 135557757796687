import { Breadcrumbs, PageLayout } from 'components'

import { PopularProductsTable } from 'domains/Statistic/components'
import { useNavigate } from 'react-router-dom'
import { useTranslations } from 'hooks'

const StatisticsPopularProductsAll = (props) => {
  const { t } = useTranslations()
  const navigate = useNavigate()
  /* Configuring page header */
  const headingProps = {
    title: t('Popular products'),
    textAlign: 'left'
  }
  const onBack = () => navigate(-1)
  return (
    <PageLayout
      onBack={onBack}
      headingProps={headingProps}
      breadcrumbs={<Breadcrumbs />}
      area="statistics-popular-products">
      <PopularProductsTable />
    </PageLayout>
  )
}

export default StatisticsPopularProductsAll
