import { StyledItemWrapper, StyledSpace } from './ProductHorizontalList.styled'
import { useLoading, useTranslations } from 'hooks'

import { LoadingBox } from 'components'
import { ProductSimpleView } from 'domains/Product/components'
import PropTypes from 'prop-types'
import { useHorizontalScroll } from 'hooks'

const ProductHorizontalList = (props) => {
  const { products, productsLoading } = props
  const { t } = useTranslations()

  const [scrollRef, handleMouseEnter, handleMouseLeave] = useHorizontalScroll()

  const loading = useLoading([productsLoading])
  return (
    <LoadingBox loading={loading} spinnerProps={{ text: t('Product loading') }}>
      <StyledSpace
        ref={scrollRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}>
        {products?.map((product, key) => (
          <StyledItemWrapper key={key}>
            <ProductSimpleView
              area-label={`product-${key + 1}`}
              product={product}
              isRentee
            />
          </StyledItemWrapper>
        ))}
      </StyledSpace>
    </LoadingBox>
  )
}

ProductHorizontalList.propTypes = {
  products: PropTypes.arrayOf(PropTypes.object),
  productsLoading: PropTypes.bool
}

export default ProductHorizontalList
