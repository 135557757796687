import { getDatabase, ref, serverTimestamp, update } from 'firebase/database'
import { useCallback, useEffect } from 'react'

import { COLLECTIONS } from '__constants__'

const useChangeUserStatus = (userId) => {
  const changeOnlineStatus = useCallback(
    async (isOnline) => {
      if (!userId) return
      const database = getDatabase()
      const path = `${COLLECTIONS.USER_STATUSES}/${userId}`

      const userStatusRef = ref(database, path)

      update(userStatusRef, {
        isOnline,
        lastActiveTime: serverTimestamp()
      })
    },
    [userId]
  )

  useEffect(() => {
    changeOnlineStatus(true)
    window.addEventListener('unload', () => changeOnlineStatus(false))
    return () => {
      changeOnlineStatus(false)
    }
  }, [changeOnlineStatus])
}

export default useChangeUserStatus
