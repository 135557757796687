/**
 * Returns the growth icon and color based on the given value.
 *
 * @param {string} value - The value used to determine the growth icon and color.
 * @returns {Object} - An object containing the growth icon and color.
 */
const getGrowthIconAndColor = (value, token) => {
  const growthIcon =
    value === '0%'
      ? 'ArrowNarrowRightOutlined'
      : value?.[0] === '-'
      ? 'ArrowNarrowDownRightOutlined'
      : 'ArrowNarrowUpRightOutlined'

  const growthIconColor =
    value === '0%'
      ? token.colorWarning
      : value?.[0] === '-'
      ? token.colorError
      : token.colorSuccess

  return { growthIcon, growthIconColor }
}

export default getGrowthIconAndColor
