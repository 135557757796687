import { useState, useEffect } from 'react'
import { useScreen } from 'hooks'

const useCategoriesDrawer = () => {
  const { xs } = useScreen()

  const [open, setOpen] = useState(false)

  const handleToggle = () => setOpen((prevValue) => !prevValue)

  useEffect(() => {
    !xs && setOpen(false)
  }, [xs])

  return { open, handleToggle }
}

export default useCategoriesDrawer
