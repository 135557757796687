import { StyledSpinnerWrapper, StyledWrapper } from './ContextLoader.styled'

import PropTypes from 'prop-types'
import Spinner from '../Spinner'

const ContextLoader = (props) => {
  const { children, loaded, loadingText } = props

  return (
    <StyledSpinnerWrapper>
      {!loaded && (
        <StyledWrapper>
          <Spinner text={loadingText} />
        </StyledWrapper>
      )}
      {children}
    </StyledSpinnerWrapper>
  )
}

ContextLoader.propTypes = {
  children: PropTypes.any,
  loaded: PropTypes.bool,
  loadingText: PropTypes.string
}

export default ContextLoader
