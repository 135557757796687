import { MOMENT_FORMATS } from '__constants__'
import PropTypes from 'prop-types'
import { Typography } from 'antd'
import { calculateProductPriceByDays } from 'domains/Product/helpers'
import moment from 'moment'
import pluralize from 'pluralize'
import { useTranslations } from 'hooks'

const OrderRentingDays = (props) => {
  const { order, noData, inRow } = props
  const { t } = useTranslations()

  const [, totalDays] = calculateProductPriceByDays(order, order?.product)

  const startDateSeconds = order?.startDate?.seconds
  const endDateSeconds = order?.endDate?.seconds

  const rentingDaysStr =
    startDateSeconds && endDateSeconds
      ? `${moment
          .unix(startDateSeconds)
          .format(MOMENT_FORMATS.DAYS_MONTH_YEAR_WITH_DOTS)} - ${moment
          .unix(endDateSeconds)
          .format(MOMENT_FORMATS.DAYS_MONTH_YEAR_WITH_DOTS)}`
      : null

  const totalDaysStr = inRow
    ? `(${totalDays}${t('d')})`
    : `${totalDays} ${totalDays > 1 ? t(pluralize('day')) : t('day')}`

  const classComputed = inRow ? 'flex' : 'flex flex-col justify-center'

  return rentingDaysStr ? (
    <div className={classComputed}>
      <Typography.Text className={inRow && 'mr-4'}>
        {rentingDaysStr}
      </Typography.Text>
      <Typography.Text type="secondary">{totalDaysStr}</Typography.Text>
    </div>
  ) : (
    noData
  )
}

OrderRentingDays.propTypes = {
  order: PropTypes.object,
  noData: PropTypes.node,
  noIcon: PropTypes.bool,
  inRow: PropTypes.bool
}

export default OrderRentingDays
