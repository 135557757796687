import AppProviders from 'AppProviders'
import Navigator from 'navigators/Navigator'

function App() {
  return (
    <AppProviders>
      <Navigator />
    </AppProviders>
  )
}

export default App
