import { Col, Row, theme } from 'antd'

import PropTypes from 'prop-types'

export const EnumContainer = (props) => {
  const { children, wrapperStyles } = props

  const {
    token: { borderRadiusLG, colorBgContainer }
  } = theme.useToken()

  return (
    <Row
      style={{
        borderRadius: borderRadiusLG,
        background: colorBgContainer,
        overflow: 'hidden',
        padding: '2px',
        width: 'inherit',
        ...wrapperStyles
      }}>
      {children}
    </Row>
  )
}

EnumContainer.propTypes = {
  children: PropTypes.node,
  wrapperStyles: PropTypes.object
}

export const EnumItem = (props) => {
  const { children, onClick, selected, ...rest } = props
  const {
    token: { sizeXXS, colorBgContainer, colorPrimaryBg, borderRadiusLG }
  } = theme.useToken()

  return (
    <Col
      flex="auto"
      onClick={onClick}
      style={{
        cursor: 'pointer',
        padding: sizeXXS,
        display: 'flex',
        background: selected ? colorPrimaryBg : colorBgContainer,
        borderRadius: borderRadiusLG,
        justifyContent: 'center'
      }}
      {...rest}>
      {children}
    </Col>
  )
}

EnumItem.propTypes = {
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  children: PropTypes.node
}
