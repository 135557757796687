import { Breadcrumbs, PageLayout, Spinner } from 'components'
import { Col, Row } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'

import { TicketAdvancedForm } from 'domains/Ticket/components'
import { useGetTicketInitialValues } from 'domains/Ticket/hooks'
import { useTranslations } from 'hooks'

const TicketEditPage = (props) => {
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting navigate instance */
  const navigate = useNavigate()
  /* Getting params from router */
  const params = useParams()
  /* Getting id from params */
  const { ticketId } = params

  /* Fetching ticket initial values */
  const [initialValues, loading] = useGetTicketInitialValues(ticketId)

  /* Configuring page header */
  const headingProps = {
    title: t('Ticket edit'),
    textAlign: 'left'
  }

  /* Function to handle back click */
  const handleBack = () => navigate(-1)

  return (
    <PageLayout
      onBack={handleBack}
      breadcrumbs={<Breadcrumbs />}
      headingProps={headingProps}
      area="ticket">
      <Row>
        {loading ? (
          <Col span={24}>
            <Spinner height="70vh" text={t('Ticket loading')} />
          </Col>
        ) : (
          <Col xs={24} md={20} lg={16} xl={14}>
            <TicketAdvancedForm initialData={initialValues} />
          </Col>
        )}
      </Row>
    </PageLayout>
  )
}

export default TicketEditPage
