import { Menu, Modal, Typography } from 'antd'

import styled from 'styled-components'

const StyledMenu = styled(Menu)`
  width: 230px !important;
  min-width: 230px !important;
  border-inline-end: none !important;

  :where(.css-dev-only-do-not-override-1lw592h) {
    overflow-y: scroll !important;
  }

  .ant-menu-title-content {
    width: 230px;
    padding: 0;
  }
`
const StyledText = styled(Typography.Text)`
  width: 60%;
`
const StyledModal = styled(Modal)`
  .ant-modal-content {
    width: 100%;
    height: 100%;
    padding: 24px 20px !important;
  }
`

const StyledCategoryBtn = styled(Typography.Text)`
  color: #fff;
`

export { StyledMenu, StyledModal, StyledCategoryBtn, StyledText }
