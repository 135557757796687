import { PageLayout } from 'components'

import { ProductsSearchResultAdvancedView } from 'domains/Product/components'
import { useLocation } from 'react-router-dom'

const ProductsSearchResultAll = () => {
  const { state } = useLocation()

  return (
    <PageLayout>
      <ProductsSearchResultAdvancedView searchValue={state?.searchValue} />
    </PageLayout>
  )
}

export default ProductsSearchResultAll
