import { capitalize } from 'helpers'

const PAYMENT_METHOD_TYPES = {
  CARD: 'CARD',
  PAYPAL: 'PAYPAL',
  GOOGLE_PAY: 'GOOGLE PAY',
  APPLE_PAY: 'APPLE PAY'
}

const PAYMENT_METHOD_TYPES_LABELS = {
  CARD: 'Card',
  PAYPAL: 'Paypal',
  GOOGLE_PAY: 'Google pay',
  APPLE_PAY: 'Apple pay'
}
const PAYMENT_METHOD_SERVICES = {
  CARD: 'CARD',
  GOOGLE_PAY: 'GOOGLE PAY',
  APPLE_PAY: 'APPLE PAY'
}
const PAYMENT_METHOD_SERVICES_LABELS = {
  CARD: 'Card',
  GOOGLE_PAY: 'Google pay',
  APPLE_PAY: 'Apple pay'
}
const PAYMENT_METHOD_TYPES_ICON = {
  CARD: 'CreditCard1Outlined',
  PAYPAL: 'CreditCard1Outlined',
  GOOGLE_PAY: 'GoogleColoredFilled',
  APPLE_PAY: 'AppleFilled'
}

const PAYMENT_METHOD_TYPES_OPTIONS = Object.keys(
  PAYMENT_METHOD_TYPES_LABELS
).map((key) => ({ label: capitalize(PAYMENT_METHOD_TYPES[key]), value: key }))

const PAYMENT_METHOD_SERVICES_OPTIONS = Object.keys(
  PAYMENT_METHOD_SERVICES_LABELS
).map((key) => ({
  label: capitalize(PAYMENT_METHOD_SERVICES[key]),
  value: key,
  icon: PAYMENT_METHOD_TYPES_ICON[key]
}))

export default PAYMENT_METHOD_TYPES
export {
  PAYMENT_METHOD_TYPES_OPTIONS,
  PAYMENT_METHOD_TYPES,
  PAYMENT_METHOD_TYPES_LABELS,
  PAYMENT_METHOD_SERVICES,
  PAYMENT_METHOD_SERVICES_OPTIONS
}
