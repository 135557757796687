const getDaysInMonth = (month, asArrOfStr, year) => {
  const yearComputed = year || new Date().getFullYear()

  const daysInMonth = new Date(yearComputed, month, 0).getDate()
  return asArrOfStr
    ? Array.from({ length: daysInMonth }, (_, index) =>
        (index + 1).toLocaleString('en-US', {
          minimumIntegerDigits: 2,
          useGrouping: false
        })
      )
    : daysInMonth
}

export default getDaysInMonth
