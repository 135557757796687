import { useHandleError, useTranslations } from 'hooks'

import { AuthLayout } from '../../components'
import { ForgotPasswordForm } from '../../domains/Session/components'
import { useSessionActions } from '../../hooks'

const ForgotPassword = () => {
  const { t } = useTranslations()
  const { resetPassword } = useSessionActions()
  const onError = useHandleError()

  return (
    <AuthLayout
      withBackButton
      title={t('Restore password')}
      subTitle={t('Enter the email address associated with your account')}>
      <ForgotPasswordForm resetPassword={resetPassword} onError={onError} />
    </AuthLayout>
  )
}

export default ForgotPassword
