import { useEffect, useState, useCallback } from 'react'
import { getDocument } from 'services/api/firebase'
import { COLLECTIONS } from '__constants__'

/**
 * It fetches a product document from Firestore and returns it
 * @param review - The review object that we're getting the product for.
 * @returns An array with four values. The first value is the product object, and the second value is a
 * boolean that indicates whether the product is loading.
 */
const useGetReviewSubjectData = (review) => {
  /* State for data */
  const [product, setSubject] = useState(null)
  /* State for loading */
  const [loading, setLoading] = useState(!!review?.subject)
  /* State for error */
  const [error, setError] = useState(null)

  /* Fetching data */
  const getSubject = useCallback(async () => {
    try {
      if (!review?.subject) return setSubject(null)

      /* Setting loading state to true */
      setLoading(true)

      /* Getting product document */
      const document = await getDocument(COLLECTIONS.PRODUCTS, review.subject)

      /* Setting product data */
      setSubject(document)
    } catch (error) {
      setError(error)
    }

    /* Setting loading state to false */
    setLoading(false)
  }, [review?.subject])

  useEffect(() => {
    review?.subject !== undefined && getSubject()
  }, [review?.subject, getSubject])

  return [product, loading, error, getSubject]
}

export default useGetReviewSubjectData
