import PropTypes from 'prop-types'
import { StatusFilter } from './components'

const OrderFilter = (props) => {
  const { filterStatus, onFilterStatusChange } = props

  return (
    <StatusFilter
      filterParams={filterStatus}
      setFilterParams={onFilterStatusChange}
    />
  )
}

OrderFilter.propTypes = {
  filterStatus: PropTypes.object,
  onFilterStatusChange: PropTypes.func
}

export default OrderFilter
