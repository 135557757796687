import { useCallback, useMemo } from 'react'

import { Button } from 'antd'
import { Icon } from '@qonsoll/icons'
import { SETTINGS_PATHS } from '__constants__/paths'
import { useNavigate } from 'react-router-dom'
import { useTranslations } from 'hooks'

const useGetAddressCustomActions = () => {
  const { t } = useTranslations()

  const navigate = useNavigate()

  const { ADDRESS_CREATE } = SETTINGS_PATHS

  const handleCreate = useCallback(
    () => navigate(ADDRESS_CREATE),
    [navigate, ADDRESS_CREATE]
  )

  return useMemo(
    () => [
      <Button
        key="create"
        onClick={handleCreate}
        className="flex align-center justify-center"
        icon={<Icon name="Plus1Outlined" fill="currentColor" />}>
        {t('Add address')}
      </Button>
    ],
    [t, handleCreate]
  )
}

export default useGetAddressCustomActions
