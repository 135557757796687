import { Button, Card, Col, Divider, Row, Typography, theme } from 'antd'
import { RelativeImage, Spinner } from 'components'
import { calculateFinalPayment, getTransformedImageUrl } from 'helpers'
import { generatePath, useNavigate } from 'react-router-dom'
import { useCallback, useMemo } from 'react'

import { APP_PATHS } from '__constants__'
import { Icon } from '@qonsoll/icons'
import PropTypes from 'prop-types'
import { TitleStyled } from './CartSimpleView.styled'
import moment from 'moment'
import noImage from '__assets__/illustrations/no-image.png'
import { useCart } from 'contexts'
import { useGetProductAdditionalData } from 'domains/Product/hooks'
import { useTranslations } from 'hooks'
import { useUser } from 'modules/session-module/contexts'

const CartSimpleView = (props) => {
  const { cart } = props
  const { previewImage, name } = cart

  const { user, loading: userLoading } = useUser()
  const checkoutId = cart?.checkoutId

  const { deleteCartItem } = useCart()

  const { t, language } = useTranslations()

  const navigate = useNavigate()

  const { borderRadiusLG, colorBorder } = theme.useToken().token

  const { category, loading } = useGetProductAdditionalData({
    categoryId: cart?.categoryId
  })

  const handleDeleteCartItem = useCallback(
    () => deleteCartItem(cart?.index),
    [cart?.index, deleteCartItem]
  )
  const handleImageError = useCallback((e) => {
    e.currentTarget.src = noImage
  }, [])

  const handleCheckout = useCallback(() => {
    const showPath = APP_PATHS.CHECKOUT
    const pathParams = { checkoutId }

    const path = generatePath(showPath, pathParams)
    navigate(path)
  }, [navigate, checkoutId])

  const startDate = moment(cart?.startDate?.toDate())?.format('DD MM YYYY')
  const endDate = moment(cart?.endDate?.toDate())?.format('DD MM YYYY')

  const computeRentDays = useMemo(() => {
    const startDate = cart.startDate.toDate()
    const endDate = cart?.endDate.toDate()

    const differenceDays = moment(startDate).diff(endDate, 'days')
    return differenceDays ? Math.abs(differenceDays) + 1 : 1
  }, [cart?.startDate, cart?.endDate])

  const isOwnerProduct = useMemo(
    () => (user ? user?._id === cart?._createdBy : true),
    [cart?._createdBy, user]
  )
  const computedImgUrl = getTransformedImageUrl(previewImage)

  if (loading || userLoading) return <Spinner height="20vh" />

  const productFinalPrice = calculateFinalPayment(
    computeRentDays * cart?.pricePerDay
  )

  return (
    <Card>
      <Row gutter={[16, 16]} className="mb-16">
        <Col xs={9} sm={7} md={4}>
          <RelativeImage
            withOverlay={false}
            src={computedImgUrl}
            onError={handleImageError}
            borderRadius={borderRadiusLG}
            borderColor={colorBorder}
          />
        </Col>

        <Col xs={24} sm={17}>
          <Row gutter={[0, 4]}>
            <Col span={24}>
              <Typography.Text type="secondary">
                {category?.names?.[language.toUpperCase()] || 'n/a'}
              </Typography.Text>
            </Col>
            <Col span={24}>
              <TitleStyled level={5}>{name}</TitleStyled>
            </Col>
            <Col span={24} className="flex align-center mb-4">
              <Icon size={14} name="CalendarOutlined" className="mr-8" />
              <Typography.Text
                style={{
                  fontSize: '12px'
                }}>{`${startDate} - ${endDate}`}</Typography.Text>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row justify="space-between" align="middle">
        <Col>
          <div className="flex">
            <Typography.Title level={5} className="mr-4">
              {productFinalPrice || 'n/a'}
            </Typography.Title>
            <Typography.Title level={5} type="secondary">
              {cart.currency}
            </Typography.Title>
          </div>
        </Col>
        <Col className="flex align-center">
          <Button
            size="small"
            type="link"
            onClick={handleCheckout}
            disabled={isOwnerProduct}
            className="flex justify-center">
            {t('Checkout')}
          </Button>
          <div className="py-4">
            <Divider
              type="vertical"
              className="mx-8"
              style={{ borderColor: colorBorder }}
            />
          </div>
          <Button
            onClick={handleDeleteCartItem}
            danger
            type="link"
            size="small">
            <Icon size={16} name="Trash3Outlined" fill="currentColor" />
          </Button>
        </Col>
      </Row>
    </Card>
  )
}

CartSimpleView.propTypes = {
  cart: PropTypes.object,
  isCheckout: PropTypes.bool
}

export default CartSimpleView
