import { Card, Skeleton, Typography } from 'antd'
import { CardDropdown, Image } from 'components'
import { cardStyles, imageStyles } from './ProductWithReviewSimpleView.styled'
import {
  useGetProductReviewAndRelatedData,
  useReviewBaseActions
} from 'domains/Review/hooks'

import PropTypes from 'prop-types'
import pluralize from 'pluralize'
import { useTranslations } from 'hooks'

const ProductWithReviewsSimpleView = (props) => {
  const { product } = props
  const { t } = useTranslations()
  const { onOpen } = useReviewBaseActions({ productId: product?._id })

  const [data, loading] = useGetProductReviewAndRelatedData(product)
  const { reviewsNumber } = data || {}

  const reviewNumberText =
    reviewsNumber > 1
      ? `${reviewsNumber} ${t(pluralize('review'))}`
      : reviewsNumber === 0
      ? t('No reviews')
      : `${reviewsNumber} ${t('review')}`

  return !loading ? (
    <CardDropdown
      onClick={onOpen}
      data-testid="addressListItem"
      className="full-height">
      <Card className="full-height" bodyStyle={cardStyles}>
        <div className="flex align-center full-height">
          <Image
            src={product?.previewImgUrl}
            style={imageStyles}
            isSquare={false}
          />
          <div className="ml-8">
            <Typography.Title level={5}>{product?.name}</Typography.Title>
            <Typography.Text>{reviewNumberText}</Typography.Text>
          </div>
        </div>
      </Card>
    </CardDropdown>
  ) : (
    <Skeleton />
  )
}

ProductWithReviewsSimpleView.propTypes = {
  product: PropTypes.object
}

export default ProductWithReviewsSimpleView
