import { COLLECTIONS } from '__constants__'
import { getDocument } from 'services/api/firebase'

const getCategoryNames = async (initialData, values) => {
  try {
    if (initialData?.category?._id === values?.['product']?.['categoryId']) {
      return initialData?.category?.names
    }
    // Fetch the category data if the ID does not match
    const categoryData = await getDocument(
      COLLECTIONS.CATEGORIES,
      values?.['product']?.['categoryId']
    )

    // Return the names from the category data or null if not available
    return categoryData?.names ?? null
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error in getCategoryNames:', error)
    return null
  }
}
export default getCategoryNames
