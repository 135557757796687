import { COLLECTIONS, TICKET_STATUSES } from '__constants__'
import { useHandleError, useMutate, useTranslations } from 'hooks'
import { useLocation, useNavigate } from 'react-router-dom'

import { message } from 'antd'
import { updateParent } from 'services/api/firebase'
import { updateParentHasManyRelationship } from 'services/api/firebase'
import { useState } from 'react'
import { useUser } from 'modules/session-module/contexts'

const useActionsTicketAdvancedForm = ({ initialData, form } = {}) => {
  /* State for loading */
  const [loading, setLoading] = useState(false)
  const { user } = useUser()
  /* Getting mutate function */
  const { create, update } = useMutate()

  const handleError = useHandleError()
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting navigate instance */
  const navigate = useNavigate()
  /* Getting location state */
  const navigationState = useLocation().state

  /* Function for preparing values for saving */
  const prepareValues = async (values = {}, additionalValues = {}) => {
    /* Preparing ticket values */
    const preparedValues = {
      _id: values.ticket.code,
      ...additionalValues,
      title: values?.ticket?.title ?? null,
      description: values?.ticket?.description ?? null,
      status: TICKET_STATUSES.PENDING
    }
    return preparedValues
  }

  /* Saving form data */
  const saveForm = async (values, callback) => {
    try {
      // Prepare data to be saved
      const data = await prepareValues(values)

      if (navigationState?.parent)
        await updateParent(navigationState.parent, data._id)

      // Save data
      if (initialData?.ticket) {
        await update(COLLECTIONS.TICKETS, initialData.ticket?._id, data)
        message.success(t('Ticket successfully updated'))
      } else {
        await create(COLLECTIONS.TICKETS, data, data._id)

        await updateParentHasManyRelationship(
          user?._id,
          COLLECTIONS.USERS,
          'ticketIds',
          data?._id
        )

        message.success(t('Ticket successfully created'))
      }
      // Final callback
      callback?.()
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      throw new Error(t('Something went wrong during data save'))
    }
  }

  /* On finish callback */
  const onFinish = async () => {
    if (loading) return // Avoid multiple calls

    try {
      setLoading(true)

      // Get form values
      const formValues = form.getFieldsValue()

      // Save data
      await saveForm(formValues)
      // Final callback
      navigate(-1)
    } catch (error) {
      handleError(
        error.message,
        t('Something went wrong during save ticket document')
      )
    } finally {
      setLoading(false)
    }
  }

  /* On cancel callback */
  const onReset = () => {
    form.resetFields()
    navigate(-1)
  }

  return { onFinish, onReset, loading, saveForm, prepareValues }
}

export default useActionsTicketAdvancedForm
