import { Row } from 'antd'
import styled from 'styled-components'
const styles = {
  scrollableList: {
    overflow: 'scroll',
    width: '100%'
  }
}
const StyledRow = styled(Row)`
  ::-webkit-scrollbar {
    display: none;
  }
`
export { styles, StyledRow }
