import { useCallback, useEffect, useState } from 'react'

import { Form } from 'antd'
import { PAYMENT_METHOD_TYPES } from '__constants__'

const useInitializePaymentMethodAdvancedForm = (initialData) => {
  const [initialValues, setInitialValues] = useState()
  /* Getting form instance */
  const [form] = Form.useForm()

  const computedFormValues = useCallback(
    (paymentMethod) => ({
      paymentMethod: {
        name: paymentMethod?.name ?? null,
        type: paymentMethod?.type ?? PAYMENT_METHOD_TYPES.CARD,
        cardNumber: paymentMethod?.cardNumber ?? null,
        details: paymentMethod?.details ?? null
      }
    }),
    []
  )

  const setFormValues = useCallback(
    (formValues) => {
      form.setFieldsValue(formValues)
      setInitialValues(formValues)
    },
    [form]
  )

  /* Setting initial data */
  useEffect(() => {
    if (initialData) {
      /* Getting initial values */
      const { paymentMethod } = initialData

      /* Formatting date fields */
      const formValues = computedFormValues(paymentMethod)
      /* Setting initial values to form instance */
      form.setFieldsValue(formValues)

      setFormValues(formValues)
    } else {
      const formValues = computedFormValues()

      /* Setting initial values to form instance */
      setFormValues(formValues)
    }
  }, [computedFormValues, initialData, setFormValues, form])

  return { form, initialValues }
}

export default useInitializePaymentMethodAdvancedForm
