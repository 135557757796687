import { memo, useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import { RelativeImage } from 'components'
import { getBase64 } from 'components/forms/ImageUploader/helpers'

const ProductPreviewImageBeforeUpload = (props) => {
  const { previewImgUrl } = props

  const [imageSrc, setImageSrc] = useState()

  useEffect(() => {
    const getImageSrc = async () => {
      if (!previewImgUrl) {
        setImageSrc()
        return
      }
      if (typeof previewImgUrl === 'string') {
        setImageSrc(previewImgUrl)
        return
      }
      if (typeof previewImgUrl === 'object') {
        const onTransformSuccess = (result) => setImageSrc(result)
        const onTransformError = (error) => {}
        getBase64(previewImgUrl, onTransformSuccess, onTransformError)
        return
      }
    }

    getImageSrc()
  }, [previewImgUrl])

  return <RelativeImage ratio={1.5} withOverlay={false} src={imageSrc} />
}

ProductPreviewImageBeforeUpload.propTypes = {
  previewImgUrl: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
}

export default memo(ProductPreviewImageBeforeUpload)
