import PropTypes from 'prop-types'
import { CollapseCategoryView } from 'domains/Category/components/CategoryFilter/components'

const TopCategoryWithSubcategoriesCollapse = ({
  topLevelCategories,
  categories,
  checkIsEnabled,
  onChange
}) => {
  return topLevelCategories?.map((topCategory) => (
    <CollapseCategoryView
      key={`${topCategory?._id}-category-collapse`}
      topCategory={topCategory}
      onChange={onChange}
      checkIsEnabled={checkIsEnabled}
      categories={categories}
    />
  ))
}

TopCategoryWithSubcategoriesCollapse.propTypes = {
  topLevelCategories: PropTypes.array,
  categories: PropTypes.array,
  checkIsEnabled: PropTypes.func,
  onChange: PropTypes.func
}

export default TopCategoryWithSubcategoriesCollapse
