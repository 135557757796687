import { DrawerBottom, Spinner } from 'components'
import { Grid, theme } from 'antd'

import PropTypes from 'prop-types'
import { ReviewAdvancedForm } from 'domains/Review/components'
import { useGetOrderReviewWithRating } from 'domains/Order/hooks'
import { useTranslations } from 'hooks'

const { useBreakpoint } = Grid

const OrderReviewBottomDrawer = (props) => {
  const { order, reviewDrawerVisible, onReviewFormToggle } = props
  const { t } = useTranslations()
  const [review, rating, loading] = useGetOrderReviewWithRating(order)

  const changeReviewVisibility = (e) => {
    e.stopPropagation()
    if (
      e.target.id === 'review-form-bottom-drawer' ||
      // handles btn-icon click
      e.target.nodeName === 'svg' ||
      e.target.nodeName === 'path' ||
      e.target.noName === 'BUTTON' ||
      e.target.id === 'review-form-toggler'
    ) {
      onReviewFormToggle?.(e, order)
    }
  }

  const { xs } = useBreakpoint()
  const token = theme.useToken().token

  return (
    <DrawerBottom
      id="review-form-bottom-drawer"
      xs={xs}
      isOpened={reviewDrawerVisible}
      theme={token}
      onClick={(e) => changeReviewVisibility(e)}>
      {!loading ? (
        <ReviewAdvancedForm
          submitText={t('Save review')}
          block
          afterSubmitCallback={onReviewFormToggle}
          rating={rating}
          initialData={review?.[0]}
          productId={order?.product?._id}
          orderId={order?._id}
        />
      ) : (
        <Spinner height="70vh" />
      )}
    </DrawerBottom>
  )
}

OrderReviewBottomDrawer.propTypes = {
  order: PropTypes.object,
  reviewDrawerVisible: PropTypes.bool,
  onReviewFormToggle: PropTypes.func
}

export default OrderReviewBottomDrawer
