const ROLES = {
  ADMIN: 'ADMIN',
  USER: 'USER',
  RENTEE: 'RENTEE',
  RENTER: 'RENTER'
}

const ROLES_LABELS = {
  ADMIN: 'Admin',
  USER: 'User',
  RENTEE: 'Rentee',
  RENTER: 'Renter'
}

const ROLES_OPTIONS = Object.keys(ROLES_LABELS).map((key) => ({
  label: ROLES[key],
  value: key
}))

export default ROLES
export { ROLES_OPTIONS, ROLES, ROLES_LABELS }
