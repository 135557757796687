import { useScreen, useTranslations } from 'hooks'

import { Badge } from 'antd'
import { Icon } from 'components'
import { MenuButton } from '../BottomMenu/BottomMenu.styled'
import { NotificationsContent } from 'domains/Notification/components'
import PropTypes from 'prop-types'
import { StyledPopover } from './NotificationCard.styled'
import { useGetNotifications } from 'domains/Notification/hooks'
import { useState } from 'react'

const NotificationCard = (props) => {
  const { collapsed } = props

  const { t } = useTranslations()

  /* Fetching notifications */
  const [notifications, unseenNotifications] = useGetNotifications()
  const [popoverVisible, setPopoverVisible] = useState(false)

  const { isSmallScreen } = useScreen()

  return (
    <StyledPopover
      overlayStyle={{ width: '340px' }}
      overlayInnerStyle={{ padding: '0' }}
      placement={isSmallScreen ? 'bottom' : 'rightBottom'}
      trigger="click"
      content={
        <NotificationsContent
          notifications={notifications}
          setPopoverVisible={setPopoverVisible}
        />
      }
      open={popoverVisible}
      onOpenChange={setPopoverVisible}>
      <MenuButton
        withBadge={collapsed}
        {...props}
        collapsed={collapsed}
        block
        prefix={<Icon size={16} name="Bell1Outlined" />}
        suffix={
          <Badge count={unseenNotifications?.length} className="full-width" />
        }
        badgeNumber={unseenNotifications?.length}>
        {t('Notifications')}
      </MenuButton>
    </StyledPopover>
  )
}
NotificationCard.propTypes = {
  collapsed: PropTypes.bool
}
export default NotificationCard
