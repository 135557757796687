import { Breadcrumbs, SettingsLayout, Spinner } from 'components'
import { Col, Row } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import { useScreen, useTranslations } from 'hooks'

import { COLLECTIONS } from '__constants__'
import { ChangePasswordForm } from 'domains/User/components'
import PropTypes from 'prop-types'
import { useGetUserInitialValues } from 'domains/User/hooks'

const SettingsChangePasswordPage = (props) => {
  const { layoutProps } = props
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting navigate instance */
  const navigate = useNavigate()
  /* Getting params from router */
  const params = useParams()

  const { xl, lg } = useScreen()

  /* Getting id from params */
  const { userId } = params

  /* Fetching user initial values */
  const [initialValues, loading] = useGetUserInitialValues(userId)

  /* Function to handle back click */
  const handleBack = () => navigate(-1)

  const headingProps = {
    title: t('Password')
  }

  return (
    <SettingsLayout
      headingProps={headingProps}
      breadcrumbs={<Breadcrumbs collection={COLLECTIONS.USERS} />}
      onBack={handleBack}
      area="changePassword">
      <Row>
        {loading ? (
          <Col span={24}>
            <Spinner height="70vh" text={t('Page loading')} />
          </Col>
        ) : (
          <Col {...layoutProps}>
            <ChangePasswordForm
              initialData={initialValues}
              isBlockButtons={!xl}
              withNavigate={!lg}
            />
          </Col>
        )}
      </Row>
    </SettingsLayout>
  )
}

SettingsChangePasswordPage.propTypes = {
  withoutHeader: PropTypes.bool,
  layoutProps: PropTypes.object
}

export default SettingsChangePasswordPage
