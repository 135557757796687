const NOTIFICATION_TYPES = {
  PRODUCT_REVIEW: 'PRODUCT_REVIEW', // this notification receive renter when his product receive a new review
  ORDER_REQUEST: 'ORDER_REQUEST', // this notification receive renter when his product was ordered
  ORDER_REQUEST_STATUS_CHANGE: 'ORDER_REQUEST_STATUS_CHANGE', // not sure what is this status about
  MESSAGE: 'MESSAGE', // this notification receive renter / rentee when they receive an message from opposite
  ORDER_STATUS_CHANGE: 'ORDER_STATUS_CHANGE', // this notification receive: 1) rentee when his order request get a response from renter; 2) renter when rentee cancel the order
  COMPLAINT: 'COMPLAINT', //this notification receive admin when renter/rentee create complaint
  COMPLAINT_STATUS_CHANGE: 'COMPLAINT_STATUS_CHANGE', //this notification receive renter/rentee when admin change their complaint status (complaints are the same for 2 roles)
  TICKET: 'TICKET', //this notification receive admin when renter/rentee create ticket
  TICKET_STATUS_CHANGE: 'TICKET_STATUS_CHANGE', //this notification receive renter/rentee when admin change their ticket status (tickets are the same for 2 roles)
  PRODUCT_REMOVE: 'PRODUCT_REMOVE' // this notification receive rentee when product added to cart was deleted
}

const NOTIFICATION_TYPES_LABELS = {
  PRODUCT_REVIEW: 'Product review',
  ORDER_REQUEST: 'Order request',
  ORDER_REQUEST_STATUS_CHANGE: 'Order request status change',
  MESSAGE: 'Message'
}

const NOTIFICATION_TYPES_OPTIONS = Object.keys(NOTIFICATION_TYPES_LABELS).map(
  (key) => ({ label: NOTIFICATION_TYPES[key], value: key })
)

export default NOTIFICATION_TYPES
export {
  NOTIFICATION_TYPES_OPTIONS,
  NOTIFICATION_TYPES,
  NOTIFICATION_TYPES_LABELS
}
