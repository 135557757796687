import { Col, Row, Typography } from 'antd'

import { Icon } from '@qonsoll/icons'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'

const SettingsListItem = (props) => {
  const { icon, title, path, isError } = props

  /* Getting navigate instance */
  const navigate = useNavigate()

  const handleClick = () => navigate(path)

  return (
    <div onClick={handleClick}>
      <Row align="middle" gutter={[20, 0]} className="py-8">
        <Col flex="none">
          <Icon name={icon} size={20} />
        </Col>
        <Col flex="auto" className="flex align-center gap-4">
          <Typography.Text>{title}</Typography.Text>
          {isError && <Icon name="AlertDangerWarningFilled" fill="#faad14" />}
        </Col>
        <Col flex="none">
          <Icon name="ChevronRightOutlined" size={12} />
        </Col>
      </Row>
    </div>
  )
}

SettingsListItem.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  path: PropTypes.string,
  isError: PropTypes.bool
}

export default SettingsListItem
