import { Col, Divider, Grid, Row } from 'antd'
import { Fragment, useMemo } from 'react'
import {
  ImagesPreview,
  ProductDetails,
  ProductExtendedInfo,
  ProductLessorView,
  ProductRangeSelect,
  ProductRangeSelectFixed
} from './components'

import { ProductsListFromCurrentLessor } from 'domains/Product/components'
import PropTypes from 'prop-types'
import { getTransformedImageUrl } from 'helpers'
import { useProductRangeSelectActions } from 'domains/Product/hooks'
import { useUser } from 'modules/session-module/contexts'

const { useBreakpoint } = Grid

const ProductAdvancedView = (props) => {
  const { product, address, mediaObjects, user, tags, reviews } = props
  const { user: currUser, isRentee } = useUser()
  const { lg } = useBreakpoint()

  const {
    range,
    periodInDays,
    pricePerDayDisplay,
    pricePerPeriodDisplay,
    computedDayLabel,
    formattedDateRange,
    disabledDates,
    handleChangeRange,
    handleBuyProduct
  } = useProductRangeSelectActions(product)

  const mediaObjectsUrls = useMemo(
    () =>
      mediaObjects?.map((mediaObject, index) => {
        const size = index === 0 ? 1600 : 1024
        return getTransformedImageUrl(mediaObject, size)
      }),
    [mediaObjects]
  )

  const isReserveBanned = useMemo(
    () => currUser?._id === product?._createdBy,
    [currUser?._id, product?._createdBy]
  )

  const isRangeBtnDisabled = useMemo(
    () => (isRentee ? isReserveBanned : true),
    [isRentee, isReserveBanned]
  )

  return (
    <Fragment>
      <Row gutter={32} className="mb-48">
        <Col xs={24} md={24} lg={24} xxl={24} className="mb-24">
          <ImagesPreview
            mediaObjects={mediaObjectsUrls}
            productId={product?._id}
            productName={product?.name}
          />
        </Col>
        <Col xs={24} lg={15} xl={16}>
          <Row>
            <Col span={24}>
              <ProductDetails product={product} address={address} />
            </Col>
            <Col span={24}>
              <Divider />
            </Col>
            <ProductExtendedInfo
              product={product}
              reviews={reviews}
              tags={tags}
            />

            <Col span={24}>
              <ProductLessorView user={user} />
            </Col>
          </Row>
        </Col>
        {lg && (
          <Col lg={9} xl={8} className="relative">
            <ProductRangeSelect
              disabledDates={disabledDates}
              disabled={isRangeBtnDisabled}
              productId={product._id}
              handleChangeRange={handleChangeRange}
              periodInDays={periodInDays}
              range={range}
              pricePerDayDisplay={pricePerDayDisplay}
              pricePerPeriodDisplay={pricePerPeriodDisplay}
              formattedDateRange={formattedDateRange}
              computedDayLabel={computedDayLabel}
              handleBuyProduct={handleBuyProduct}
              className="sticky"
            />
          </Col>
        )}
      </Row>
      <Row className={!lg && 'mb-96'}>
        <Col span={24}>
          <ProductsListFromCurrentLessor user={user} />
        </Col>
      </Row>
      {!lg && (
        <ProductRangeSelectFixed
          disabledDates={disabledDates}
          disabled={isRangeBtnDisabled}
          product={product}
          handleChangeRange={handleChangeRange}
          periodInDays={periodInDays}
          range={range}
          productId={product._id}
          pricePerDayDisplay={pricePerDayDisplay}
          pricePerPeriodDisplay={pricePerPeriodDisplay}
          formattedDateRange={formattedDateRange}
          computedDayLabel={computedDayLabel}
          handleBuyProduct={handleBuyProduct}
        />
      )}
    </Fragment>
  )
}

ProductAdvancedView.propTypes = {
  product: PropTypes.object,
  mediaObjects: PropTypes.array,
  address: PropTypes.object,
  user: PropTypes.object,
  productsByCurrentUser: PropTypes.array,
  tags: PropTypes.array,
  reviews: PropTypes.array
}

export default ProductAdvancedView
