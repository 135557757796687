// the texts are taken from the Stripe documentation, constants are used because there is no API that would provide what text
// https://stripe.com/docs/connect/handling-api-verification#handle-document-verification-problems
const VERIFICATION_DETAILS_CODES = {
  failed_keyed_identity:
    "The name on the account couldn't be verified, please provide your full legal name and to also provide a photo ID matching that name.",
  document_name_mismatch:
    "The information on the ID document doesn't match the provided information, please verify and correct the provided information on the account.",
  document_dob_mismatch:
    "The information on the ID document doesn't match the provided information, please verify and correct the provided information on the account.",
  document_address_mismatch:
    "The information on the ID document doesn't match the provided information, please verify and correct the provided information on the account.",
  document_id_number_mismatch:
    "The information on the ID document doesn't match the provided information, please verify and correct the provided information on the account.",
  document_photo_mismatch:
    "The information on the ID document doesn't match the provided information, please verify and correct the provided information on the account.",
  document_fraudulent:
    'The document might have been altered so it could not be verified. Contact with Stripe team to learn more.',
  document_manipulated:
    'The document might have been altered so it could not be verified. Contact with Stripe team to learn more.'
}

export default VERIFICATION_DETAILS_CODES
