const MIME_TYPES = {
  PDF: 'PDF',
  IMAGE: 'IMAGE'
}

const MIME_TYPES_LABELS = {
  PDF: 'Pdf',
  IMAGE: 'Image'
}

const MIME_TYPES_OPTIONS = Object.keys(MIME_TYPES_LABELS).map((key) => ({
  label: MIME_TYPES[key],
  value: key
}))

export default MIME_TYPES
export { MIME_TYPES_OPTIONS, MIME_TYPES, MIME_TYPES_LABELS }
