import { CHART_TYPES, STATISTIC_RANGES } from '__constants__'

import { StatisticChart } from 'domains/Statistic/components'
import moment from 'moment'
import { useGetUserViewsStats } from 'domains/Statistic/hooks/get'
import { useState } from 'react'
import { useTranslations } from 'hooks'

const UserViewsChart = () => {
  const currMonth = moment().format('MMM')
  const [range, setRange] = useState(
    STATISTIC_RANGES[`${currMonth.toUpperCase()}`]
  )
  const onRangeChange = (value) => setRange(value)
  const { t } = useTranslations()

  const [data] = useGetUserViewsStats(range)
  const chartDescription = `${t(
    'The user views chart shows the total views of your products for each day of the selected month'
  )}.`
  return (
    <StatisticChart
      onRangeChange={onRangeChange}
      range={range}
      type={CHART_TYPES.BAR}
      title={t('User Views')}
      data={data}
      description={chartDescription}
    />
  )
}

export default UserViewsChart
