import { COLLECTIONS, PRODUCT_STATUSES } from '__constants__'
import { collection, orderBy, query, where } from 'firebase/firestore'

import { firestore } from 'services/firebase'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { useMemo } from 'react'

const useGetUserPublishedProducts = (userId) => {
  const q = useMemo(
    () =>
      userId &&
      query(
        collection(firestore, COLLECTIONS.PRODUCTS),
        where('_createdBy', '==', userId),
        where('status', '==', PRODUCT_STATUSES.PUBLISHED),
        orderBy('_createdAt', 'desc')
      ),
    [userId]
  )
  return useCollectionData(q)
}

export default useGetUserPublishedProducts
