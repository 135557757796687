import { useCallback, useEffect, useState } from 'react'

import { Form } from 'antd'

const useInitializeTicketAdvancedForm = (initialData) => {
  const [initialValues, setInitialValues] = useState({})
  /* Getting form instance */
  const [form] = Form.useForm()

  const computedFormValues = useCallback(
    (ticket) => ({
      ticket: {
        title: ticket?.title ?? null,
        description: ticket?.description ?? null
      }
    }),
    []
  )

  const setFormValues = useCallback(
    (formValues) => {
      form.setFieldsValue(formValues)
      setInitialValues(formValues)
    },
    [form]
  )
  /* Setting initial data */
  useEffect(() => {
    if (initialData) {
      /* Getting initial values */
      const { ticket } = initialData

      /* Formatting date fields */
      const formValues = computedFormValues(ticket)
      /* Setting initial values to form instance */
      setFormValues(formValues)
    } else {
      const formValues = computedFormValues()
      setFormValues(formValues)
    }
  }, [computedFormValues, setFormValues, initialData, form])

  return { form, initialValues }
}

export default useInitializeTicketAdvancedForm
