import { PATHS } from '__constants__'
import { generatePath } from 'react-router-dom'
import { useUser } from 'modules/session-module/contexts'

const useGetUserProfilePaths = ({ user, userId }) => {
  const { user: currentUser } = useUser()

  const isCurrentUserTheOwner = currentUser?._id === user?._id
  const pagePath = isCurrentUserTheOwner
    ? generatePath(PATHS.AUTHENTICATED.MY_PROFILE)
    : generatePath(PATHS.AUTHENTICATED.USER_PROFILE, { userId: user?._id })

  // this will be only for My-profile
  // as for User-profile there will be ChatButton
  const messagesPath = generatePath(PATHS.AUTHENTICATED.CHATS)
  const ordersPath = generatePath(PATHS.AUTHENTICATED.ORDERS_ALL)
  return { pagePath, messagesPath, ordersPath }
}

export default useGetUserProfilePaths
