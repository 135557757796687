const LS = {
  LAST_SESSION_PROVIDER: 'lastSessionProvider',
  LAST_SESSION: 'lastSession',
  SESSIONS: 'sessions',
  GDPR: 'gdpr',
  SIGNUP_DATA: 'signUpData',
  AUTH_TOKEN: 'auth-token',
  TOKEN_EXPIRATION_TIME: 'token-expiration-time'
}

export default LS
