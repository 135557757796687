import { COLLECTIONS, COMPLAINT_STATUSES } from '__constants__'
import { useMutate, useTranslations } from 'hooks'

import { getId } from 'services/api/firebase'
import { message } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'

const useActionsComplaintAdvancedForm = ({
  form,
  initialData,
  orderId,
  productId,
  onFinishCallback,
  onResetCallback
}) => {
  /* State for loading */
  const [loading, setLoading] = useState(false)
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting navigate instance */
  const navigate = useNavigate()
  /* Getting mutate function */
  const { create, update } = useMutate()
  /* Function for preparing values for saving */
  const prepareValues = async (values, additionalValues) => {
    /* Getting id */
    const complaintId = initialData?._id || getId(COLLECTIONS.COMPLAINTS)
    /* Preparing complaint values */
    const preparedValues = {
      _id: complaintId,
      ...additionalValues,
      productId: productId ?? null,
      orderId: orderId ?? null,
      complaintReason: values?.['complaintReason'] ?? null,
      comment: values?.['comment'] ?? null,
      status: COMPLAINT_STATUSES.PENDING ?? null
    }
    return preparedValues
  }

  /* Function for saving complaint form */
  const saveForm = async (values, callback) => {
    try {
      // Prepare data to be saved
      const data = await prepareValues(values)
      // Save data
      if (initialData) {
        await update(COLLECTIONS.COMPLAINTS, initialData.complaint?._id, data)
        message.success(t('Complaint successfully updated'))
      } else {
        await create(COLLECTIONS.COMPLAINTS, data, data._id)
        message.success(t('Complaint successfully created'))
      }
      // Final callback
      callback?.()
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      throw new Error(t('Something went wrong during data save'))
    }
  }

  const onFinish = async () => {
    if (loading) return // Avoid multiple calls

    try {
      setLoading(true)
      // Get form values
      const formValues = form.getFieldsValue()

      // Save data
      await saveForm(formValues, onFinishCallback)
      // Final callback
      navigate(-1)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      message.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  const onReset = () => {
    form.resetFields()
    onResetCallback?.()
  }

  return { onFinish, onReset, loading, saveForm, prepareValues }
}

export default useActionsComplaintAdvancedForm
