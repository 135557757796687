import { Typography } from 'antd'
import styled from 'styled-components'

const StyledDisplayDate = styled(Typography.Text)`
  font-style: italic;
  font-size: 12px;
  padding: 2px;
`
const StyledMessage = styled(Typography.Text)`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
`
const StyledTitle = styled(Typography.Text)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`

const StyledNotificationWrapper = styled.div`
  cursor: pointer;
  &:hover {
    background-color: ${({ bg }) => bg || '#1c74e0'};
  }
`
const StyledDot = styled.div`
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: ${({ bg }) => bg || '#1c74e0'};
`
const styles = {
  markAsReadButtonStyles: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}

export {
  StyledDisplayDate,
  StyledNotificationWrapper,
  StyledMessage,
  StyledTitle,
  StyledDot,
  styles
}
