import styled from 'styled-components'
import { theme } from 'antd'

const DrawerSide = styled.div`
  position: fixed;
  right: ${({ isOpen, rightShiftOpen, width = '360px' }) =>
    !isOpen ? `-${width}` : rightShiftOpen || '0px'};
  top: 0;
  height: 100vh;
  width: ${({ width = '360px' }) => width};
  background-color: ${({ theme }) => theme.colorBgContainer};
  transition: all 1s ease;
  padding: 24px;
  z-index: ${({ zIndex }) => zIndex || 1000};
  border-left: 1px solid ${({ theme }) => theme.colorFillSecondary};
  overflow: auto;
  &::-webkit-scrollbar {
    display: ${({ hideScroll }) => (hideScroll ? 'none' : 'block')};
  }
  scrollbar-width: ${({ hideScroll }) => hideScroll && 'thin'}; /* Firefox  */
  scrollbar-color: ${({ hideScroll }) =>
    hideScroll && 'transparent transparent'}; /* Firefox only */
`
const DrawerOverlay = styled.div`
  background: ${({ theme }) => theme.colorBgMask};
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${({ zIndex }) => zIndex || 999};
  visibility: ${({ isOpen }) => (!isOpen ? 'hidden' : 'visible')};
  opacity: ${({ isOpen }) => (!isOpen ? '0%' : '100%')};
  transition: all 1s ease;
`

const DrawerWithStyles = (props) => {
  const token = theme.useToken().token
  return <DrawerSide {...props} theme={token} />
}

const DrawerOverlayWithStyles = (props) => {
  const token = theme.useToken().token
  return <DrawerOverlay {...props} theme={token} />
}

export {
  DrawerWithStyles as DrawerSide,
  DrawerOverlayWithStyles as DrawerOverlay
}
