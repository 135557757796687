/**
 * Formats a number by adding 'k' suffix if the absolute value is greater than 999.
 * @param {number} num - The number to be formatted.
 * @returns {string|number} - The formatted number.
 */
const kFormatter = (num) => {
  return Math?.abs?.(num) > 999
    ? Math?.sign?.(num) * (Math?.abs?.(num) / 1000)?.toFixed?.(1) + 'k'
    : Math?.sign?.(num) * Math?.abs?.(num)
}

export default kFormatter
