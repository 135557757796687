import { useTranslations } from 'hooks'
import PropTypes from 'prop-types'
import AlertStyled from './VerificationDetailsSimpleView.styled'
import moment from 'moment'
import { MOMENT_FORMATS } from '__constants__'
import { Row, Col, Typography, Button } from 'antd'
import { VerificationDetailsModal } from 'modules/stripe-module/domains/Connect/components'
import { useState } from 'react'
import { VERIFICATION_DETAILS_CODES } from 'modules/stripe-module/__constants__'

const { Text } = Typography

const VerificationDetailsSimpleView = (props) => {
  const { connectedAccount } = props

  const { t } = useTranslations()

  const [isOpened, setIsOpened] = useState(false)

  // Get object with requirements
  const requirements = connectedAccount?.requirements

  // Get string with details code
  const verificationDetailsCode =
    connectedAccount?.individual?.verification?.details_code
  // Check if there is details code
  const verificationDetailsCodeLength = !!verificationDetailsCode?.length
  // Get an array with fields that must be provided
  const currentlyDue = requirements?.currently_due
  // Check if an array has fields that  must be provided
  const currentlyDueLength = !!requirements?.currently_due?.length
  // Check and get deadline
  const deadline =
    requirements?.current_deadline &&
    moment(requirements?.current_deadline * 1000).format(
      MOMENT_FORMATS.DATE_MONTH_STR_YEAR
    )

  const details = (
    <>
      {/** Show verification details if they exist */}
      {verificationDetailsCodeLength && (
        <Row>
          <Col>{t(VERIFICATION_DETAILS_CODES?.[verificationDetailsCode])}</Col>
        </Row>
      )}
      {/** Show details if they ot deadline exist */}
      {deadline && (
        <Row gutter={[16, 8]}>
          <Col>
            <Text>
              {`${t(
                'To avoid an interruption in charges and payouts, an update is required by'
              )} ${deadline}`}
            </Text>
          </Col>
          <Col span={24}>
            <Button onClick={() => setIsOpened((prev) => !prev)}>
              {t('Details')}
            </Button>
          </Col>
        </Row>
      )}
    </>
  )

  return (
    <>
      {currentlyDueLength || verificationDetailsCodeLength ? (
        <Row className="mb-16">
          <Col xs={24}>
            <AlertStyled description={details} type="warning" showIcon />
          </Col>
        </Row>
      ) : null}
      {currentlyDueLength && (
        <VerificationDetailsModal
          setIsOpened={setIsOpened}
          isOpened={isOpened}
          requirements={requirements}
          currentlyDue={currentlyDue}
        />
      )}
    </>
  )
}

VerificationDetailsSimpleView.propTypes = {
  connectedAccount: PropTypes.object
}
export default VerificationDetailsSimpleView
