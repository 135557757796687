import moment from 'moment'

const prepareRDBDateKey = (month, day, year) => {
  const yearComputed = year ?? moment().format('YYYY')

  // get month as strings "01", "02", "11", etc
  const currMonthStr = month.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false
  })

  return `${yearComputed}-${currMonthStr}-${day}`
}
export default prepareRDBDateKey
