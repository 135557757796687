import { LoadingBox, PageLayout } from 'components'
import {
  useGetProductCustomActions,
  useGetProductInitialValues
} from 'domains/Product/hooks'
import { useNavigate, useParams } from 'react-router-dom'

import { PageHeaderSimple } from 'components/layouts/'
import { ProductImagesList } from 'domains/Product/components'
import { useMemo } from 'react'
import { useTranslations } from 'hooks'
import { useUser } from 'modules/session-module/contexts'

const ProductImagesAll = () => {
  const { productId } = useParams()
  const { user, loading = true } = useUser()

  const navigate = useNavigate()
  const { t } = useTranslations()

  const [initialValues, initialValuesLoading] =
    useGetProductInitialValues(productId)

  const { product } = initialValues

  const actions = useGetProductCustomActions({
    productId,
    product: initialValues?.product
  })

  const title = initialValues?.product?.name || t('Product images')
  const handleBack = () => navigate(-1)

  const loadings = useMemo(
    () => loading || initialValuesLoading,
    [loading, initialValuesLoading]
  )

  const computedActions = useMemo(
    () => (user && user?._id === product?._createdBy ? actions : null),
    [actions, user, product?._createdBy]
  )

  return (
    <PageLayout
      onBack={handleBack}
      topHeader={<PageHeaderSimple title={title} actions={computedActions} />}
      area="product-images">
      <LoadingBox loading={loadings}>
        <ProductImagesList mediaObjects={initialValues?.mediaObjects} />
      </LoadingBox>
    </PageLayout>
  )
}

export default ProductImagesAll
