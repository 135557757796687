import { useCallback, useEffect, useState } from 'react'

import { Form } from 'antd'

const useInitializeAddressAdvancedForm = (initialData) => {
  const [initialValues, setInitialValues] = useState()
  /* Getting form instance */
  const [form] = Form.useForm()

  const computedFormValues = useCallback(
    (address) => ({
      name: address?.name ?? null,
      icon: address?.icon ?? 'PIN',
      address: {
        latitude: address?.latitude ?? null,
        longitude: address?.longitude ?? null
      }
    }),
    []
  )

  const setFieldsValue = useCallback(
    (formValues) => {
      form.setFieldsValue(formValues)
      setInitialValues(formValues)
    },
    [form]
  )

  /* Setting initial data */
  useEffect(() => {
    if (initialData) {
      /* Getting initial values */
      const { address } = initialData

      /* Formatting date fields */
      const formValues = computedFormValues(address)

      /* Setting initial values to form instance */
      setFieldsValue(formValues)
    } else {
      const formValues = computedFormValues()

      /* Setting initial values to form instance */
      setFieldsValue(formValues)
    }
  }, [initialData, computedFormValues, setFieldsValue])

  return { form, initialValues }
}

export default useInitializeAddressAdvancedForm
