import { Row, Col, Typography, Menu } from 'antd'

import styled from 'styled-components'

const StyledRow = styled(Row)`
  justify-content: space-between;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
`
const StyledCol = styled(Col)`
  width: 100px;
  display: flex;
`

const StyledText = styled(Typography.Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  align-self: center;
`

const StyledMenuItem = styled(Menu.Item)`
  margin: 0 !important;
  &.ant-menu-item {
    margin: 0 !important;
    display: flex;
    align-items: center;
    .ant-menu-title-content {
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }
  }
  .ant-menu-title-content {
    display: flex;
  }
`

export { StyledRow, StyledCol, StyledText, StyledMenuItem }
