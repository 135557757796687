import './ConfirmPhoneAdvancedView.css'

import { Button, Form, Image, Space, Typography, theme } from 'antd'
import {
  ButtonStyled,
  TextStyled,
  styles
} from './ConfirmPhoneAdvancedView.styled'
import { Fragment, useCallback, useMemo } from 'react'
import { useTimerByDate, useTranslations } from 'hooks'

import { LS } from '__constants__'
import PropTypes from 'prop-types'
import VerificationInput from 'react-verification-input'
import confirmPhoneImg from '__assets__/illustrations/confirm-phone.svg'
import { useConfirmPhone } from 'modules/session-module/hooks'

const ConfirmPhoneAdvancedView = (props) => {
  const { verifyCallback, closeModal, phone } = props
  const { t } = useTranslations()
  const { colorTextTertiary, colorPrimaryText, fontSizeSM } =
    theme.useToken().token
  const [form] = Form.useForm()

  const [counter, setLeftSeconds, isOpen, setIsOpen] = useTimerByDate(
    LS.LAST_SENDED_PHONE
  )

  const transformedPhone = useMemo(() => (phone ? `+${phone}` : null), [phone])

  const { sendVerificationMessage, confirmPhone, loading } = useConfirmPhone(
    transformedPhone,
    setLeftSeconds,
    setIsOpen
  )

  const counterMinutes = counter?.minutes > 0
  const counterSeconds = counter?.seconds > 0

  const handleCancel = useCallback(() => closeModal(), [closeModal])

  const onFinish = (credentials) =>
    confirmPhone(credentials?.code, verifyCallback)

  const computedTitle = useMemo(
    () => (!isOpen ? t('Verify your phone') : t('Please check your phone')),
    [isOpen, t]
  )
  return (
    <Fragment>
      {/* Header */}
      <Space {...styles.headerSpace}>
        <div className="flex justify-center">
          <Image {...styles.image} src={confirmPhoneImg} />
        </div>

        <Typography.Title level={3}>{computedTitle}</Typography.Title>
        <div {...styles.descriptionWrapper}>
          <TextStyled fontSize={fontSizeSM}>
            {t('We have sent a confirmation code to your phone ')}
          </TextStyled>
          <TextStyled fontSize={fontSizeSM} color={colorPrimaryText} strong>
            {transformedPhone}
          </TextStyled>
          <TextStyled fontSize={fontSizeSM}>
            {`. ${t(
              'Verify your phone number to access all available features'
            )}.`}
          </TextStyled>
        </div>
      </Space>

      <div className={isOpen && 'none'}>
        <Space {...styles.actionsSpace}>
          <div {...styles.actionsWrapper}>
            <Button
              type="primary"
              loading={loading}
              onClick={sendVerificationMessage}>
              {t('Verify phone')}
            </Button>
          </div>
        </Space>
      </div>
      {/* Main */}
      <Form onFinish={onFinish} form={form} className={!isOpen && 'none'}>
        <Space {...styles.mainSpace}>
          <Form.Item name={'code'}>
            <VerificationInput {...styles.verificationInput} />
          </Form.Item>
          <div className="flex align-center">
            <Typography.Text type="secondary">
              {`${t("Didn't get a code?")}`}
            </Typography.Text>

            {counterMinutes || counterSeconds ? (
              <Typography.Text className="mx-8">
                {counter?.minutes}:{counter?.seconds}
              </Typography.Text>
            ) : (
              <ButtonStyled
                type="link"
                size="small"
                color={colorTextTertiary}
                loading={loading}
                onClick={sendVerificationMessage}>
                {`${t('Click to resend')}.`}
              </ButtonStyled>
            )}
          </div>
        </Space>
        {/* Space actions */}
        <Space {...styles.actionsSpace}>
          <div {...styles.actionsWrapper}>
            <Button type="primary" htmlType="submit" block>
              {t('Verify')}
            </Button>
            <Button onClick={handleCancel} block>
              {t('Cancel')}
            </Button>
          </div>
        </Space>
      </Form>
      <div id="sign-in-button"></div>
    </Fragment>
  )
}

ConfirmPhoneAdvancedView.propTypes = {
  verifyCallback: PropTypes.func,
  phone: PropTypes.string,
  closeModal: PropTypes.func
}

export default ConfirmPhoneAdvancedView
