import { Col, Row } from 'antd'
import { ProductList, ProductSearch } from 'domains/Product/components'
import {
  useGetProductsAndRelatedDataByIds,
  useProductLikesActions,
  useProductRatingActions,
  useSearchProductsByCategory
} from 'domains/Product/hooks'

import PropTypes from 'prop-types'
import { Spinner } from 'components'
import { useLoading } from 'hooks'

const ProductsSearchResultAdvancedView = (props) => {
  const { searchValue } = props

  const { productIdsToShow, loadingProductIdsToShow, setSearchValue } =
    useSearchProductsByCategory({ initialSearchValue: searchValue })

  /* Fetching products and irs related data */
  const [products, productLoading] =
    useGetProductsAndRelatedDataByIds(productIdsToShow)

  // actions for product likes
  const likeActions = useProductLikesActions()
  // actions for product rating
  const ratingActions = useProductRatingActions()

  const loading = useLoading([productLoading, loadingProductIdsToShow])

  return !loading ? (
    <Row gutter={24}>
      {/** Temporary commented */}
      {/* {!isSmallScreen && (
        <Col lg={8} xl={6} xxl={5}>
          <ProductFilter
            filterVisibility
            setFilterVisibility={() => {}}
            filterParams={filterParams}
            setFilterParams={setFilterParams}
            category={category}
          />
        </Col>
      )} */}
      <Col xs={24} lg={24} xl={24} xxl={24}>
        {/* Search + Search location */}
        <ProductSearch setSearchValue={setSearchValue} />
        <ProductList
          likeActions={likeActions}
          ratingActions={ratingActions}
          products={products}
          span={{ xs: 24, sm: 24, md: 12, lg: 12, xl: 8, xxl: 6 }}
        />
      </Col>
    </Row>
  ) : (
    <Spinner height="70vh" />
  )
}
ProductsSearchResultAdvancedView.propTypes = {
  searchValue: PropTypes.string
}

export default ProductsSearchResultAdvancedView
