import { createDocument, getIsDocumentExists } from 'services/api/firebase'

/**
 * It takes a collection and an array of objects, and returns an array of ids of the objects that were
 * created or already existed in the collection
 * @param collection - The collection you want to save the data to.
 * @param array - The array of objects that you want to save.
 * @returns An array of promises.
 */
const saveHasManyRelationship = async (
  collection,
  array,
  additionalData = {}
) => {
  const promises = array?.map(async (item) => {
    const isDocumentExists = await getIsDocumentExists(collection, item?._id)
    if (isDocumentExists) return item._id
    else {
      const { _id } = await createDocument(
        collection,
        {
          ...item,
          ...additionalData
        },
        item?._id
      )

      return _id
    }
  })
  return promises?.length ? Promise.all(promises) : null
}

export default saveHasManyRelationship
