import { Space, Typography } from 'antd'

import { AuthLayout } from '../../components'

const TermsAndConditionsOfUse = () => {
  return (
    <AuthLayout title="Terms and Conditions" withBackButton>
      <Space style={{ justifyContent: 'center', display: 'flex' }}>
        <Typography.Text>
          Add your terms and conditions of use here
        </Typography.Text>
      </Space>
    </AuthLayout>
  )
}

export default TermsAndConditionsOfUse
