import { Breadcrumbs, SettingsLayout, Spinner } from 'components'
import { Col, Row } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import { useScreen, useTranslations } from 'hooks'

import { PaymentMethodAdvancedForm } from 'domains/PaymentMethod/components'
import PropTypes from 'prop-types'
import { useGetPaymentMethodInitialValues } from 'domains/PaymentMethod/hooks'

const SettingsPaymentMethodEditPage = (props) => {
  const { layoutProps } = props

  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting navigate instance */
  const navigate = useNavigate()
  /* Getting params from router */
  const params = useParams()
  /* Getting id from params */
  const { paymentMethodId } = params

  /* Fetching paymentMethod initial values */
  const [initialValues, loading] =
    useGetPaymentMethodInitialValues(paymentMethodId)

  /* Function to handle back click */
  const handleBack = () => navigate(-1)

  const headingProps = {
    title: t('Payment method edit')
  }

  const { xl } = useScreen()

  return (
    <SettingsLayout
      onBack={handleBack}
      breadcrumbs={<Breadcrumbs />}
      headingProps={headingProps}
      area="paymentMethodCreate">
      <Row>
        {loading ? (
          <Col span={24}>
            <Spinner height="50%" text={t('Payment method loading')} />
          </Col>
        ) : (
          <Col {...layoutProps}>
            <PaymentMethodAdvancedForm
              initialData={initialValues}
              isBlockButtons={!xl}
            />
          </Col>
        )}
      </Row>
    </SettingsLayout>
  )
}

SettingsPaymentMethodEditPage.propTypes = {
  layoutProps: PropTypes.object
}

export default SettingsPaymentMethodEditPage
