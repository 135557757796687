import { LAST_SESSION_PROVIDERS, LS } from '../../../__constants__'

import firebase from 'firebase/compat/app'

/**
 * `signup` creates a new user with the given credentials, and then sends an email verification.
 * @returns A promise that resolves to the user object.
 */
const signup = ({ credentials, onError, onSuccess }) => {
  return firebase
    .auth()
    .createUserWithEmailAndPassword(credentials.email, credentials.password)
    .then(() => {
      localStorage.setItem(
        LS.LAST_SESSION_PROVIDER,
        LAST_SESSION_PROVIDERS.EMAIL
      )
      onSuccess?.()
    })
    .catch((err) => {
      onError && onError(err)
    })
}

export default signup
