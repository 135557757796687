import { Form } from 'antd'
import { useEffect } from 'react'

const useInitializeReviewAdvancedForm = (initialData, rating) => {
  /* Getting form instance */
  const [form] = Form.useForm()

  /* Setting initial data */
  useEffect(() => {
    if (initialData) {
      const formValues = {
        review: {
          _id: initialData?._id,
          summary: initialData?.['summary'] ?? null,
          review: initialData?.['review'] ?? null,
          isRecommended: initialData?.['isRecommended'] ?? null
        },
        rating: rating ?? null
      }

      /* Setting initial values to form instance */
      form.setFieldsValue(formValues)
    }
  }, [initialData, form, rating])

  return { form }
}

export default useInitializeReviewAdvancedForm
