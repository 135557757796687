import { Form } from 'antd'
import { useEffect } from 'react'
import { useUser } from 'modules/session-module/contexts'
import { useGetAddress } from 'domains/Address/hooks'
import moment from 'moment'
import dayjs from 'dayjs'

const useInitializeConnectAccountForm = () => {
  const { user } = useUser()
  const [address] = useGetAddress(user?.addressId)

  /* Getting form instance */
  const [form] = Form.useForm()

  /* Setting initial data */
  useEffect(() => {
    /* Formatting date fields */
    const formValues = {
      firstName: user?.firstName ?? null,
      lastName: user?.lastName ?? null,
      email: user?.email ?? null,
      phone: user?.phone ?? null,
      dateOfBirth: dayjs(moment(user?.birthday?.toDate())) ?? null,
      address: address
        ? `${address?.streetName} ${address?.streetNumber}`
        : null,
      postalCode: address?.postalCode ?? null,
      city: address?.city ?? address?.region ?? null,
      country: address?.country ?? null
    }
    /* Setting initial values to form instance */
    form.setFieldsValue(formValues)
  }, [
    address,
    address?.city,
    address?.country,
    address?.postalCode,
    address?.region,
    address?.streetName,
    address?.streetNumber,
    form,
    user
  ])

  return { form }
}

export default useInitializeConnectAccountForm
