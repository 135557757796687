import { Breadcrumbs, SettingsLayout, LoadingBox } from 'components'
import { Col, Row } from 'antd'
import { useTranslations } from 'hooks'

import PropTypes from 'prop-types'
import { useUser } from 'modules/session-module/contexts'
import { STRIPE_ACCOUNT_STATUSES } from '__constants__'
import { useState, useRef, useEffect } from 'react'
import {
  ConnectAdvancedForm,
  ConnectAdvancedView,
  VerificationDetailsSimpleView
} from 'modules/stripe-module/domains/Connect/components'
import {
  useGetConnectedAccount,
  useGetBalance
} from 'modules/stripe-module/domains/Connect/hooks'

const SettingsStripeAccount = (props) => {
  const { layoutProps } = props

  const { t } = useTranslations()
  const { user, loading } = useUser()

  // Create reference for isCreateAccountFlow variable, in order to after completing the first step (Providing details)
  // loading did not appear (loading appears because after the first step document for connected account is creating in DB and hook useGetConnectedAccount tries to get data - it crushes a flow)
  const reference = useRef({ isCreateAccountFlow: null })

  const [formLoading, setFormLoading] = useState(false)

  // Get stripeAccountIf from user's document
  const stripeAccountId = user?.stripeAccountId
  // Get value that shows if account is verified
  const isStripeAccountVerified = user?.isStripeAccountVerified
  // Get value that shows if documents are provided
  const isStripeDocumentsProvided = user?.isStripeDocumentsProvided
  // Get value that shows if account is completed
  const isStripeAccountCompleted =
    user?.stripeAccountStatus === STRIPE_ACCOUNT_STATUSES.ENABLED ||
    (user?.stripeAccountStatus === STRIPE_ACCOUNT_STATUSES.COMPLETED &&
      stripeAccountId)

  // Get connected account data only if it isn't flow for creating account
  const [connectedAccount, loadingConnectedAccount] = useGetConnectedAccount(
    !reference.current?.isCreateAccountFlow && stripeAccountId
  )

  const [balance, loadingBalance] = useGetBalance(
    !reference.current?.isCreateAccountFlow && stripeAccountId
  )

  const headingProps = {
    title: t('Stripe account'),
    description: isStripeAccountCompleted
      ? `${t('Your Stripe account is enabled')}${
          isStripeAccountVerified ? t(' and verified') : t(', but not verified')
        }`
      : `${t('Connect your Stripe account and receive money to your account')}.`
  }

  useEffect(() => {
    // Find out if user is loaded and set value for isCreateAccountFlow
    // isCreateAccountFlow variable can be true only if there is not stripeAccountId in user's document
    if (!loading && reference.current.isCreateAccountFlow === null) {
      reference.current.isCreateAccountFlow = !stripeAccountId
    }
  }, [loading, stripeAccountId])

  return (
    <SettingsLayout
      breadcrumbs={<Breadcrumbs />}
      headingProps={headingProps}
      area="contactUs">
      <LoadingBox
        loading={
          loading ||
          loadingConnectedAccount ||
          loadingBalance ||
          reference.current.isCreateAccountFlow === null // show loading if it is not clear it is flow for creating of not
        }>
        <VerificationDetailsSimpleView connectedAccount={connectedAccount} />
        <Row className="mb-16">
          <Col {...layoutProps}>
            {/* Show Form is it is Edit flow or creating flow */}
            {reference.current.isCreateAccountFlow ? (
              <ConnectAdvancedForm
                setLoading={setFormLoading}
                loading={formLoading}
                connectedAccount={connectedAccount}
                isStripeDocumentsProvided={isStripeDocumentsProvided}
                isStripeAccountVerified={isStripeAccountVerified}
                accountId={stripeAccountId}
              />
            ) : (
              <ConnectAdvancedView
                connectedAccount={connectedAccount}
                balance={balance}
              />
            )}
          </Col>
        </Row>
      </LoadingBox>
    </SettingsLayout>
  )
}

SettingsStripeAccount.propTypes = {
  layoutProps: PropTypes.object
}

export default SettingsStripeAccount
