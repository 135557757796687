import PropTypes from 'prop-types'
import styled from 'styled-components'
import { theme } from 'antd'
const ImageOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${({ bg }) => bg};
  border-radius: ${({ br }) => br};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
`
const OverlayWithStyles = (props) => {
  const { bg, children, br } = props

  const { colorOverlayBg, borderRadiusLG } = theme.useToken().token

  return (
    <ImageOverlay
      {...props}
      bg={bg || colorOverlayBg}
      br={br || `${borderRadiusLG}px`}>
      {children}
    </ImageOverlay>
  )
}
OverlayWithStyles.propTypes = {
  bg: PropTypes.string,
  br: PropTypes.string,
  children: PropTypes.node
}
export default OverlayWithStyles
