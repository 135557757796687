import { LikeWrapper, likeIconStyles } from './LikeForm.styled'
import { useEffect, useState } from 'react'

import { Button } from 'antd'
import { Icon } from '@qonsoll/icons'
import PropTypes from 'prop-types'

const LikeForm = (props) => {
  const { iconSize = 16, style, withWrapper = false, value, onChange } = props

  const [isLiked, setIsLiked] = useState(false)

  const handleChange = (e) => {
    e?.stopPropagation()
    setIsLiked((prevValue) => {
      const newValue = !prevValue
      onChange?.(newValue)
      return newValue
    })
  }

  useEffect(() => {
    if (value !== undefined) {
      setIsLiked(value)
    }
  }, [value])

  return (
    <LikeWrapper
      iconSize={iconSize}
      withWrapper={withWrapper}
      isLiked={isLiked}
      style={style}>
      <Button
        onClick={handleChange}
        className="flex justify-center align-center"
        shape="square"
        style={likeIconStyles}
        icon={<Icon name="HeartOutlined" size={iconSize} />}
      />
    </LikeWrapper>
  )
}

LikeForm.propTypes = {
  iconSize: PropTypes.number,
  style: PropTypes.object,
  withWrapper: PropTypes.bool,
  filled: PropTypes.bool,
  onChange: PropTypes.func,
  onLikeUpdate: PropTypes.func,
  value: PropTypes.bool,
  document: PropTypes.object
}

export default LikeForm
