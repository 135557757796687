import { COLLECTIONS, PRODUCT_STATUSES } from '__constants__'
import { collection, query, where } from 'firebase/firestore'

import { firestore } from 'services/firebase'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { useLoading } from 'hooks'
import { useMemo } from 'react'

const useGetProductsAndRelatedDataByQuery = (params) => {
  const { isDraftsPage, filterParams, whereClauses = [] } = params || {}

  const statusQuery = useMemo(
    () => (isDraftsPage ? PRODUCT_STATUSES.DRAFT : PRODUCT_STATUSES.PUBLISHED),
    [isDraftsPage]
  )

  /* Building filter query */
  const whereClausesComputed = useMemo(
    () => ({
      where: [
        ...(filterParams?.where || []),
        ['status', '==', statusQuery],
        ...whereClauses
      ]
    }),
    [filterParams, statusQuery, whereClauses]
  )

  // get products data
  const [products, productLoading] = useCollectionData(
    query(
      collection(firestore, COLLECTIONS.PRODUCTS),
      ...(whereClausesComputed?.where?.map?.((rule) => where(...rule)) || [])
    )
  )

  const loading = useLoading(!products, productLoading)
  return [products, loading]
}

export default useGetProductsAndRelatedDataByQuery
