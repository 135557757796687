import { Form, Input } from 'antd'

import { FormWrapper } from 'components'
import { KEYS } from '__constants__'
import PropTypes from 'prop-types'
import { useTranslations } from 'hooks'

const TicketSimpleForm = (props) => {
  const { initialValues, onSubmit, onCancel, showTitle, form, group } = props

  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting new form instance from Form.useForm hook */
  const [baseForm] = Form.useForm()

  /* Getting used form from props or using base form */
  const usedForm = form || baseForm

  /* Resetting form on cancel */
  const handleCancel = () => {
    usedForm.resetFields()
    onCancel?.()
  }
  /* Submitting form on enter key press */
  const handleKeyPress = (e) => {
    if (e.key === KEYS.ENTER) {
      e.preventDefault()
      e.stopPropagation()
      usedForm?.submit()
    }
  }

  /* Generating form field names */
  const getFieldName = (subGroup) => (group ? [...group, subGroup] : [subGroup])

  return (
    <FormWrapper
      title={t('Ticket')}
      onFinish={onSubmit}
      initialValues={initialValues}
      onKeyPress={handleKeyPress}
      onReset={handleCancel}
      showTitle={showTitle}
      form={usedForm}
      group={group}>
      <Form.Item
        data-area="ticket-title"
        label={t('Title')}
        name={getFieldName('title')}
        rules={[
          { required: true, message: `${t('Please enter your ticket title')}!` }
        ]}>
        <Input placeholder={t('Please enter your ticket title')} />
      </Form.Item>

      {/* Amount of ticket uses */}
      <Form.Item
        data-area="ticket-description"
        label={t('Description')}
        name={getFieldName('description')}
        rules={[
          {
            required: true,
            message: `${t('Please enter description')}!`
          }
        ]}>
        <Input.TextArea placeholder={t('Please enter description')} rows={3} />
      </Form.Item>
    </FormWrapper>
  )
}

TicketSimpleForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  showTitle: PropTypes.bool,
  form: PropTypes.any,
  group: PropTypes.array
}

export default TicketSimpleForm
