import { getDatabase, ref } from 'firebase/database'

import { useObjectVal } from 'react-firebase-hooks/database'

const useGetDataFromRDB = (path) => {
  const dbRef = ref(getDatabase(), path)
  const [data, loading, error] = useObjectVal(dbRef)

  return [data, loading, error]
}

export default useGetDataFromRDB
