import { AddressSimpleView } from 'domains/Address/components'
import { List } from 'components'
import PropTypes from 'prop-types'
import { useTranslations } from 'hooks'

const AddressList = (props) => {
  const { addresses, emptyProps, actions, ...rest } = props

  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting emptyProps from empty object */
  const emptyParams = {
    message: t('No addresses have been created yet'),
    ...emptyProps
  }

  return (
    <List
      message={t('Add address')}
      {...rest}
      actions={actions}
      dataSource={addresses}
      emptyProps={emptyParams}
      renderItem={(address, index) => (
        <AddressSimpleView
          address={address}
          area-label={`address-${index + 1}`}
          actions={actions}
          index={index}
        />
      )}
    />
  )
}

AddressList.propTypes = {
  addresses: PropTypes.array,
  emptyProps: PropTypes.object,
  actions: PropTypes.object
}
export default AddressList
