import { Tabs } from 'antd'
import styled from 'styled-components'

const TabsStyled = styled(Tabs)`
  .ant-tabs-nav-wrap {
    width: 100%;
  }
  .ant-tabs-nav {
    margin-bottom: 8px;
  }
  .ant-tabs-tab {
    background: unset !important;
    padding: 8px 0 !important;
    margin: 0 !important;
    cursor: default;
  }

  :hover {
    .ant-tabs-tab {
      background: unset;
    }
  }

  .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: black !important;
  }
`

export default TabsStyled
