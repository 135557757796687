import { useFilterCategories, useGetCategories } from 'domains/Category/hooks'
import { useLoading, useSubfilterActions, useTranslations } from 'hooks'

import PropTypes from 'prop-types'
import { Spinner } from 'components'
import { StyledCollapse } from 'components/views/Filter/Filter.styled'
import { TopCategoryWithSubcategoriesCollapse } from './components'
import { memo } from 'react'
import { useFilterContext } from 'contexts'

const CategoryFilter = () => {
  const { setFilterParams, filterParams } = useFilterContext()
  const { t } = useTranslations()

  const [categories, categoriesLoading] = useGetCategories()
  const [filteredCategories, filteredCategoriesLoading, topLevelCategories] =
    useFilterCategories(categories)

  const { onChange, checkIsEnabled } = useSubfilterActions({
    filterParams: filterParams,
    setFilterParams: setFilterParams,
    fieldName: 'subcategoryId',
    operand: 'array-contains'
  })

  const loading = useLoading([categoriesLoading, filteredCategoriesLoading])
  if (!topLevelCategories?.length) return null

  return !loading ? (
    <StyledCollapse ghost defaultActiveKey="categories">
      <StyledCollapse.Panel header={t('Categories')} key="categories">
        <TopCategoryWithSubcategoriesCollapse
          topLevelCategories={topLevelCategories}
          categories={filteredCategories}
          onChange={onChange}
          checkIsEnabled={checkIsEnabled}
        />
      </StyledCollapse.Panel>
    </StyledCollapse>
  ) : (
    <Spinner />
  )
}

CategoryFilter.propTypes = {
  filterParams: PropTypes.object,
  setFilterParams: PropTypes.func,
  categoryId: PropTypes.string
}

export default memo(CategoryFilter)
