import { Col, Row, theme } from 'antd'
import { ADDRESS_ICONS } from 'domains/Address/__constants__'
import { Icon } from '@qonsoll/icons'
import StyledSelectableCard from './AddressIconSelect.styles'
import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

const AddressIconSelect = (props) => {
  const { value, onChange } = props

  const { token } = theme.useToken()

  const [selectedValue, setSelectedValue] = useState(value)

  const handleChange = useCallback(
    (name) => {
      setSelectedValue(name)
      onChange?.(name)
    },
    [onChange]
  )

  useEffect(
    () => handleChange(value || Object.keys(ADDRESS_ICONS)?.[0]),
    [handleChange, value]
  )

  return (
    <Row gutter={[16, 16]}>
      {Object.keys(ADDRESS_ICONS).map((name) => (
        <Col key={name}>
          <StyledSelectableCard
            isSelected={name === selectedValue}
            style={{}}
            onSelect={() => handleChange(name)}
            theme={token}>
            <Icon name={ADDRESS_ICONS?.[name]} size={24} />
          </StyledSelectableCard>
        </Col>
      ))}
    </Row>
  )
}

AddressIconSelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func
}

export default AddressIconSelect
