import { useCallback, useEffect, useState } from 'react'
import { useHandleError, useTranslations } from 'hooks'

import { COLLECTIONS } from '__constants__'
import { getDocument } from 'services/api/firebase'
import { useUser } from 'modules/session-module/contexts'

const useGetUserAddresses = () => {
  const handleError = useHandleError()
  const { t } = useTranslations()
  const { user } = useUser()
  const [addresses, setAddresses] = useState([])
  const [loading, setLoading] = useState(false)

  const getAddresses = useCallback(async () => {
    try {
      setLoading(true)
      const addresses = user?.addressIds
        ? await Promise.all(
            user?.addressIds?.map((addressId) =>
              getDocument(COLLECTIONS.ADDRESSES, addressId)
            )
          )
        : null
      setAddresses(addresses)
    } catch (error) {
      handleError(error, t('Error during getting address'))
    } finally {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.addressIds, t])

  useEffect(() => {
    if (user) {
      getAddresses()
    }
  }, [getAddresses, user])
  return [addresses, loading]
}

export default useGetUserAddresses
