import { MOMENT_FORMATS } from '__constants__'
import moment from 'moment'

const formatDateForView = (date, format) => {
  return date
    ? moment
        .unix(date?.seconds)
        .format(format || MOMENT_FORMATS.MONTH_DAYS_YEAR)
    : 'n/a'
}

export default formatDateForView
