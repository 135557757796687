import { Space, Typography } from 'antd'
import { StyledBox, StyledSpinner } from './Spinner.styled'

import PropTypes from 'prop-types'

const Spinner = (props) => {
  const { text } = props

  return (
    <StyledBox>
      <Space>
        <StyledSpinner />
        {text && <Typography.Text>{text}</Typography.Text>}
      </Space>
    </StyledBox>
  )
}

Spinner.propTypes = {
  text: PropTypes.string
}

export default Spinner
