import { COLLECTIONS, RDB_COLLECTIONS } from '__constants__'
import { collection, query, where } from 'firebase/firestore'
import { useGetDataFromRDB, useLoading } from 'hooks'

import { firestore } from 'services/firebase'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { useMemo } from 'react'

const useGetProductReviewAndRelatedData = (product) => {
  const [productRatingsByUsers, ratingsLoading] = useGetDataFromRDB(
    `${RDB_COLLECTIONS.PRODUCT_RATINGS}/${product?._id}`
  )

  const [productReviews, reviewsLoading] = useCollectionData(
    product?._id &&
      query(
        collection(firestore, COLLECTIONS.REVIEWS),
        where('productId', '==', product?._id)
      )
  )
  const loading = useLoading([ratingsLoading, reviewsLoading])

  const reviewsNumber = useMemo(
    () => productReviews?.length,
    [productReviews?.length]
  )
  const data = useMemo(
    () => ({
      productData: product ?? null,
      productReviews: productReviews ?? null,
      reviewsNumber: reviewsNumber ?? 0,
      productRatingsByUsers: productRatingsByUsers ?? null
    }),
    [product, productRatingsByUsers, productReviews, reviewsNumber]
  )

  return [data, loading]
}

export default useGetProductReviewAndRelatedData
