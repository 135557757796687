import { collection, orderBy, query, where } from 'firebase/firestore'
import { useEffect, useMemo, useState } from 'react'

import { COLLECTIONS } from '__constants__'
import { firestore } from 'services/firebase'
import { getDocument } from 'services/api/firebase'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { useLoading } from 'hooks'
import { useUser } from 'modules/session-module/contexts'

const useGetMyComplaintsAndRelatedData = () => {
  const { user } = useUser()
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const queryComp = useMemo(
    () =>
      user?._id &&
      query(
        collection(firestore, COLLECTIONS.COMPLAINTS),
        where('_createdBy', '==', user?._id),
        orderBy('_createdAt', 'desc')
      ),
    [user?._id]
  )
  const [complaints, complaintsLoading, complaintsError] =
    useCollectionData(queryComp)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const promises = complaints?.map(async (complaint) => {
          const complaintProduct = await getDocument(
            COLLECTIONS.PRODUCTS,
            complaint?.productId
          )
          return { ...complaint, productName: complaintProduct?.name }
        })
        const data = await Promise.all(promises)
        setData(data)
      } catch (err) {
        setError(err)
      } finally {
        setLoading(false)
      }
    }
    if (complaints?.length) {
      fetchData()
    }
  }, [complaints])

  const resultLoading = useLoading([loading, complaintsLoading])
  const resultError = error || complaintsError

  return [data, resultLoading, resultError]
}

export default useGetMyComplaintsAndRelatedData
