import SETTINGS_PATHS from '__constants__/paths/settingsPaths'

const APP_PATHS = {
  ...SETTINGS_PATHS,
  DASHBOARD: '/dashboard',
  SETTINGS: '/settings',
  STATISTICS: '/statistics',
  POPULAR_PRODUCTS_ALL: '/popular-products',
  MY_PRODUCTS_ALL: '/my-products',
  PRODUCTS_ALL: '/products',
  PRODUCTS_ALL_SEARCH_RESULT: '/products-search-results',
  PRODUCTS_ALL_BY_CATEGORY: '/categories/:categoryId/products',
  PRODUCT_DRAFTS_ALL: '/product-drafts',
  PRODUCT_BLOCKED_ALL: '/product-blocked',
  PRODUCT_EDIT: '/my-products/:productId/edit',
  MY_PRODUCT_SHOW: '/my-products/:productId',
  PRODUCT_SHOW: '/products/:productId',
  PRODUCT_IMAGES_ALL: '/products/:productId/images',
  PRODUCT_CREATE: '/my-products/create',
  BROWSE_CATEGORIES: '/browse-categories',
  CATEGORIES_ALL: '/categories',
  CATEGORY_SHOW: '/categories/:categoryId',
  CATEGORY_CREATE: '/categories/create',
  CATEGORY_EDIT: '/categories/:categoryId/edit',
  COMPLAINTS_ALL: '/complaints',
  COMPLAINT_SHOW: '/complaints/:complaintId',
  ORDERS_ALL: '/orders',
  ORDER_SHOW: '/orders/:orderId',
  ORDER_CREATE: '/orders/create',
  USER_EDIT: '/users/:userId/edit',
  REVIEWS_ALL: '/reviews',
  REVIEW_SHOW: '/reviews/:reviewId',
  REVIEW_CREATE: '/reviews/create',
  REVIEW_EDIT: '/reviews/:reviewId/edit',
  NOTIFICATIONS_ALL: '/notifications',
  NOTIFICATION_SHOW: '/notifications/:notificationId',
  // ** BUGS  TODO**
  // If delete this payment methods paths, page doesn't display correctly in settings
  PAYMENT_METHOD_EDIT: '/payment-methods/:paymentMethodId/edit',
  // *****
  CANCELLATION_TERM_CREATE: '/cancellation-terms/create',
  CANCELLATION_TERM_EDIT: '/cancellation-terms/:cancellationTermId/edit',
  CUSTOMERS_ALL: '/customers',
  CUSTOMER_SHOW: '/customers/:customerId',
  CHECKOUT: '/checkout/:checkoutId',
  CHECKOUT_SUCCESS: '/checkout/success',
  RENTING_REQUESTS_ALL: '/rental-requests',
  RENTING_REQUEST_SHOW: '/rental-requests/:rentingRequestId',
  TICKETS_ALL: '/tickets',
  TICKET_CREATE: '/ticket/create',
  TICKET_EDIT: '/tickets/:ticketId/edit',
  FAVORITE_PRODUCTS: '/favorite-products',
  CART: '/cart',
  CHATS: '/chats',
  USER_PROFILE: '/user-profile/:userId',
  VIPPS_CALLBACK: '/vipps-callback',
  REFRESH_STRIPE_ACCOUNT: '/stripe/refresh',
  RETURN_STRIPE_ACCOUNT: '/stripe/return',
  STRIPE_CHECKOUT_SUCCESS: '/stripe/checkout-success',
  STRIPE_CHECKOUT_ERROR: '/stripe/checkout-error'
}

export default APP_PATHS
