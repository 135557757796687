import { Image as Img, theme } from 'antd'

import { Box } from 'components'
import PropTypes from 'prop-types'
import noImage from '__assets__/illustrations/no-image.png'
import { squareStyle } from './Image.styles'

/**
 * "If the image is square, apply the square styles to the image, otherwise, just render the image."
 *
 * The `Img` component is a styled component that renders an `img` tag. The `backgroundImage` prop is a
 * custom prop that sets the `background-image` CSS property
 * @param props - This is the props object that is passed to the component.
 * @returns A function that takes in props and returns an image.
 */
const Image = (props) => {
  const { src, isSquare = true, style, preview = false, ...rest } = props

  const { borderRadiusLG } = theme.useToken().token
  const imageStyles = {
    borderRadius: borderRadiusLG,
    objectFit: 'contain' || style?.objectFit,
    ...style
  }

  const srcComputed = src || noImage

  if (isSquare) {
    const imgStyles = { ...squareStyle, borderRadius: borderRadiusLG, ...style }
    const source = `url(${src || noImage})`
    return <Box style={imgStyles} backgroundImage={source} />
  }

  return (
    <Img preview={preview} style={imageStyles} src={srcComputed} {...rest} />
  )
}

Image.propTypes = {
  src: PropTypes.string,
  isSquare: PropTypes.bool,
  style: PropTypes.object,
  preview: PropTypes.bool
}

export default Image
