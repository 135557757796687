import { COLLECTIONS, MOMENT_FORMATS } from '__constants__'
import { useCallback, useEffect, useState } from 'react'
import { useHandleError, useMutate, useTranslations } from 'hooks'

import CartContext from './CartContext'
import PropTypes from 'prop-types'
import { checkIfDocumentExist } from 'helpers'
import firebase from 'firebase/compat/app'
import { getDocument } from 'services/api/firebase'
import md5 from 'md5'
import moment from 'moment'
import { notification } from 'antd'
import { useAppNotifications } from 'contexts/AppNotificationsContext'
import { useGetCart } from 'domains/Cart/hooks'
import { useUser } from 'modules/session-module/contexts'

const CartProvider = ({ children }) => {
  const { sendErrorNotification } = useAppNotifications()

  const { t } = useTranslations()
  const handleError = useHandleError()

  const userId = firebase.auth()?.currentUser?.uid

  const [cart, cartLoading] = useGetCart()
  const { loaded } = useUser()

  const { create, update } = useMutate()

  // FIXME: There must be additional loading state
  const [cartItems, setCartItems] = useState([])

  // Check if a document exists in the "carts" collection and create if it doesn't exist
  useEffect(() => {
    const fetchData = async () => {
      const isExistDocument = await checkIfDocumentExist(
        userId,
        COLLECTIONS.CARTS
      )

      if (isExistDocument) return

      const cartData = {
        _id: userId,
        products: []
      }

      create(COLLECTIONS.CARTS, cartData, cartData?._id)
    }

    userId && fetchData()
  }, [userId, create])

  // Returns a transformed array object that combines cart and product fields
  useEffect(() => {
    const fetchData = async () => {
      try {
        const cartsPromise = cart?.products?.map(async (cart, index) => {
          const product = await getDocument(
            COLLECTIONS.PRODUCTS,
            cart?.productId
          )
          if (product) {
            const previewImageId = product?.mediaObjects?.[0]

            const previewImage = await getDocument(
              COLLECTIONS.MEDIA_OBJECTS,
              previewImageId
            )
            return { ...(product ?? {}), ...(cart ?? {}), previewImage, index }
          } else return {}
        })

        const transformedDocuments = await Promise.all(cartsPromise)

        // Filtering documents prevents an error with a deleted product in a cart
        const filteredDocuments = transformedDocuments?.filter(
          (value) => value?._id
        )
        setCartItems(filteredDocuments)
      } catch (error) {
        sendErrorNotification({
          message: `${t('Something went wrong transforming cart data')}.`,
          description: error
        })
      }
    }

    cart && fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart])

  // Remove data from carts
  const deleteCartItem = useCallback(
    async (cartIndex) => {
      const filteredCartItems = cart?.products?.filter(
        (_, i) => i !== cartIndex
      )
      await update(COLLECTIONS.CARTS, userId, { products: filteredCartItems })
    },
    [update, cart?.products, userId]
  )

  // Add data to carts
  const addCartItem = useCallback(
    async (product, date, showNotification = true) => {
      try {
        const cartData = {
          productId: product?._id ?? null,
          startDate: date?.startDate ?? null,
          endDate: date?.endDate ?? null,
          amount: 1
        }

        const checkoutId = md5(JSON.stringify(cartData))

        await update(COLLECTIONS.CARTS, userId, {
          products: firebase.firestore.FieldValue.arrayUnion({
            ...cartData,
            checkoutId
          })
        })

        showNotification &&
          notification.success({
            message: t('Success'),
            description: t('Product was added to the cart')
          })
      } catch (error) {
        showNotification
          ? handleError(error, t('Error during adding product to cart'))
          : /* eslint-disable no-console */
            console.error('Error during adding cart items from LS to DB', error)
      }
    },
    [handleError, t, update, userId]
  )

  // Count amount products
  const countAmountProduct = useCallback(
    async (value, cartIndex) => {
      const updatedData = cart?.products?.map((cartItem, index) =>
        index === cartIndex ? { ...cartItem, amount: value } : cartItem
      )
      await update(COLLECTIONS.CARTS, userId, { products: updatedData })
    },
    [userId, update, cart?.products]
  )
  const addCartItemsFromStorage = useCallback(async () => {
    // Grt formatted object with cart items from LS
    const cart = localStorage.getItem('cart')
    const isExistCart =
      cart !== 'undefined' && cart !== 'null' && 'cart' in localStorage

    const cartFormatted = isExistCart ? JSON.parse(cart) : {}

    // Get cart item ids
    const cartItemIds = Object.keys(cartFormatted)

    // If cart items are exist - add it to DB
    if (cartItemIds?.length) {
      await Promise.all(
        cartItemIds?.map(async (_id) => {
          // Get all date ranges for product
          await Promise.all(
            Object.keys(cartFormatted?.[_id])?.map(async (date) => {
              // input '20.07.23 - 28.07.23'
              // output startDate = '20.07.23', endDate '28.07.23'
              const startDate = date?.split?.(' - ')?.[0]
              const endDate = date?.split?.(' - ')?.[1]

              const formattedStartDate = moment(
                startDate,
                MOMENT_FORMATS.DAYS_MONTH_YEAR_WITH_DOTS
              ).toDate()
              const formattedEndDate = moment(
                endDate,
                MOMENT_FORMATS.DAYS_MONTH_YEAR_WITH_DOTS
              ).toDate()

              await addCartItem(
                { _id },
                { startDate: formattedStartDate, endDate: formattedEndDate },
                false
              )
            })
          )
        })
      )
      localStorage.removeItem('cart')
    }
  }, [addCartItem])

  useEffect(() => {
    // if user is loaded add to db products from LS
    if (loaded) {
      addCartItemsFromStorage()
    }
  }, [addCartItem, addCartItemsFromStorage, loaded])

  return (
    <CartContext.Provider
      value={{
        cartItems,
        loading: cartLoading,
        deleteCartItem,
        countAmountProduct,
        addCartItem
      }}>
      {children}
    </CartContext.Provider>
  )
}

CartProvider.propTypes = {
  children: PropTypes.element.isRequired
}

export default CartProvider
