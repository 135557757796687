import { ENDPOINTS } from 'modules/chat-module/__constants__'

const API_URL = process.env.REACT_APP_FIREBASE_FUNCTIONS_API_URL

const handleChatCreate = async ({
  oppositeUserId,
  userId,
  isChatExist,
  setIsLoading,
  setIsChatOpen
}) => {
  const data = {
    currentUserId: userId,
    oppositeUserId: oppositeUserId
  }

  if (!isChatExist) {
    try {
      setIsLoading(true) // Set loading state to true before making the API request

      const apiEndpoint = `${API_URL}/${ENDPOINTS.CREATE_CHAT}`

      const request = await fetch(apiEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          origin: 'lentlimobilebackend'
        },
        body: JSON.stringify(data)
      })

      await request.json()
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    } finally {
      setIsLoading(false)
      setIsChatOpen(true)
    }
  }
}

export default handleChatCreate
