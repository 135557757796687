import { MarkerF } from '@react-google-maps/api'
import PropTypes from 'prop-types'
import point from '__assets__/map/point.png'

const MapPoint = ({ position }) => {
  if (!position) return null

  return (
    <MarkerF
      position={position}
      icon={{
        url: point,
        scaledSize: new window.google.maps.Size(16, 16),
        origin: new window.google.maps.Point(0, 0),
        anchor: new window.google.maps.Point(15, 15)
      }}
    />
  )
}

MapPoint.propTypes = {
  position: PropTypes.object
}

export default MapPoint
