import './AppLayout.css'

import { Aside, Footer, Header } from './components'
import { useAside, useFooter } from './hooks'

import { CategoriesDrawer } from 'domains/Category/components'
import { Layout } from 'antd'
import PropTypes from 'prop-types'
import { StyledContent } from './AppLayout.styled'
import { useCategoriesDrawer } from 'domains/Category/hooks'
import { useUser } from 'modules/session-module/contexts'

const AppLayout = (props) => {
  const { children } = props
  const { isRentee } = useUser()

  const { collapsed, handleCollapse, showAside } = useAside()
  const { showFooter } = useFooter()
  const { open, handleToggle } = useCategoriesDrawer()

  const onLogoClick = () => handleCollapse()

  return (
    <Layout hasSider className="app-layout relative">
      {showAside && (
        <Aside
          collapsed={collapsed}
          isRentee={isRentee}
          onLogoClick={onLogoClick}
        />
      )}
      <Layout className="relative">
        <Header collapsed={collapsed} />
        <StyledContent className="relative">{children}</StyledContent>
        <CategoriesDrawer handleClose={handleToggle} open={open} />
        {showFooter && (
          <Footer
            handleToggleCategoriesDrawer={handleToggle}
            isOpenedDrawer={open}
          />
        )}
      </Layout>
    </Layout>
  )
}

AppLayout.propTypes = {
  children: PropTypes.node.isRequired
}

export default AppLayout
