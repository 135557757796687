import { StyledCollapse, StyledPanel } from './CategoryModalCollapse.styled'
import { PanelHeader } from 'domains/Category/components/CategoryTree/components'
import PropTypes from 'prop-types'
import { SubCategoriesList } from 'domains/Category/components/CategoryTree/components'
import { theme } from 'antd'

const CategoryModalCollapse = (props) => {
  const {
    selectedCategoryId,
    setSelectedCategoryId,
    handleOpenCategory,
    topLevelCategories,
    isSmallScreen,
    categories
  } = props

  const { colorPrimary, colorPrimaryBg, borderRadiusLG } =
    theme.useToken().token

  return (
    <StyledCollapse bordered={false} accordion expandIconPosition="end">
      {topLevelCategories?.map((category) => {
        const equalId = selectedCategoryId === category?._id
        return (
          <StyledPanel
            equalId={equalId}
            colorPrimary={colorPrimary}
            colorPrimaryBg={colorPrimaryBg}
            borderRadius={borderRadiusLG}
            onClick={() => setSelectedCategoryId(category?._id)}
            header={
              <PanelHeader
                category={category}
                selectedCategoryId={selectedCategoryId}
                handleOpenCategory={handleOpenCategory}
              />
            }
            key={category._id}>
            {selectedCategoryId && (
              <SubCategoriesList
                level={1}
                handleOpenCategory={handleOpenCategory}
                categories={categories}
                parentId={selectedCategoryId}
                isSmallScreen={isSmallScreen}
              />
            )}
          </StyledPanel>
        )
      })}
    </StyledCollapse>
  )
}

CategoryModalCollapse.propTypes = {
  categories: PropTypes.array,
  selectedCategoryId: PropTypes.string,
  setSelectedCategoryId: PropTypes.func,
  handleOpenCategory: PropTypes.func,
  topLevelCategories: PropTypes.array,
  isSmallScreen: PropTypes.bool
}

export default CategoryModalCollapse
