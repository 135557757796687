import { Col, Grid, Row, Typography, theme } from 'antd'

import { DateRangeInputs } from 'components'
import { DateRangeStyled } from './DateRange.styled'
import { LOCALES } from '__constants__'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useTranslations } from 'hooks'

const DateRange = (props) => {
  const {
    range,
    computedDayLabel,
    showHeader,
    onChange,
    formattedDateRange,
    disabledDates,
    withoutTopOffset
  } = props
  const { useBreakpoint } = Grid

  const { xs } = useBreakpoint()
  const { colorErrorText } = theme.useToken().token

  const { language } = useTranslations()

  const locale = LOCALES?.[language]

  const disabledDay = (day) => {
    const formattedDay = moment(day)

    return (
      !moment().isBefore(formattedDay) &&
      !moment().isSame(moment(formattedDay), 'day')
    )
  }

  const handleChangeDateRange = (item) => {
    const { selection } = item
    const endDate = selection?.endDate
    const startDate = selection?.startDate ?? selection?.endDate

    onChange?.({ endDate, startDate })
  }

  const handleStopPropagation = (event) => event.stopPropagation()

  return (
    <>
      {showHeader && (
        <Row align="center" className="mb-24">
          <Col span={10}>
            <Typography.Title level={4}>{computedDayLabel}</Typography.Title>
            <Typography.Text type="secondary">
              {formattedDateRange}
            </Typography.Text>
          </Col>
          <Col span={14} className="flex" justify="center">
            <DateRangeInputs range={range} onChange={onChange} />
          </Col>
        </Row>
      )}
      <Row className={!withoutTopOffset && 'mt-24'}>
        <Col span={24} onClick={handleStopPropagation}>
          <DateRangeStyled
            className={xs && 'xs-date-range-picker'}
            months={2}
            locale={locale}
            editableDateInputs={false}
            onChange={handleChangeDateRange}
            disableColor={colorErrorText}
            disabledDates={disabledDates}
            moveRangeOnFirstSelection={false}
            showMonthAndYearPickers={false}
            showPreview={false}
            showDateDisplay={false}
            ranges={[{ ...range, key: 'selection' }]}
            weekdayDisplayFormat="EEEEEE"
            disabledDay={disabledDay}
            direction="horizontal"
          />
        </Col>
      </Row>
    </>
  )
}

DateRange.propTypes = {
  showHeader: PropTypes.bool,
  range: PropTypes.object,
  onChange: PropTypes.func,
  periodInDays: PropTypes.number,
  computedDayLabel: PropTypes.string,
  formattedDateRange: PropTypes.string,
  disabledDates: PropTypes.array,
  withoutTopOffset: PropTypes.bool
}

export default DateRange
