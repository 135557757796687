import { useTranslations } from 'hooks'

import { message } from 'antd'
import { useState } from 'react'
import { createStripeAccount } from 'modules/stripe-module/helpers'
import { useUser } from 'modules/session-module/contexts'

const useActionsConnectAccountForm = ({ form, onFinishCallback, address }) => {
  const [loading, setLoading] = useState(false)

  const { t } = useTranslations()
  const { user } = useUser()

  const prepareValues = async (values) => {
    const preparedValues = {
      userId: user?._id,
      phone: `+${user?.phone}`,
      firstName: user?.firstName,
      lastName: user?.lastName,
      dateOfBirth: user?.birthdayByNumbers,
      addressLine1: [address?.streetName, address?.streetNumber]
        ?.filter((value) => value)
        ?.join(' '),
      postalCode: address?.postalCode,
      city: address?.city || address?.region,
      country: address?.country,
      IBAN: values?.iban,
      email: user?.email
    }

    return preparedValues
  }

  const saveForm = async (values, callback) => {
    try {
      // Prepare data to be saved
      const data = await prepareValues(values)

      await createStripeAccount(data)

      callback?.()
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
      throw new Error(t(error?.message))
    }
  }

  const onFinish = async () => {
    if (loading) return // Avoid multiple calls

    try {
      setLoading(true)
      // Get form values
      const formValues = form.getFieldsValue()

      // Save data
      await saveForm(formValues, onFinishCallback)
      setLoading(false)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      message.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  const onReset = () => {
    form.resetFields()
  }

  return { onFinish, onReset, loading, saveForm, prepareValues }
}

export default useActionsConnectAccountForm
