import { Empty } from 'antd'
import { StyledDiv } from './SubCategoriesList.styled'
import { SubCategorySimpleView } from '../../components'
import PropTypes from 'prop-types'
import { useHorizontalScroll } from 'hooks'
import { useMemo } from 'react'
import { useTranslations } from 'hooks'

const SubCategoriesList = (props) => {
  const {
    categories,
    parentId,
    level,
    handleOpenCategory,
    isTree,
    isSmallScreen
  } = props
  const { t } = useTranslations()

  const subCategories = useMemo(
    () => categories?.filter((category) => category.parentId === parentId),
    [parentId, categories]
  )

  const [scrollRef, handleMouseEnter, handleMouseLeave] = useHorizontalScroll()
  const isOdd = level % 2 !== 0
  const maxWidth = isOdd && isTree ? '33%' : '100%'
  const minWidth = isOdd && isTree ? '25%' : '100%'
  const ml = !isTree && level < 2 && '24px'
  const mb = isOdd ? 'mb-16' : ''
  return (
    <>
      {!subCategories?.length ? (
        <div className="flex justify-center align-center flex-1">
          <Empty description={t('No products in this category')} />
        </div>
      ) : (
        <StyledDiv
          isSmallScreen={isSmallScreen}
          level={level}
          ref={isTree && scrollRef}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}>
          {subCategories?.map((subCategory) => {
            return (
              <div
                style={{
                  maxWidth: maxWidth,
                  minWidth: minWidth,
                  marginLeft: ml
                }}
                key={subCategory._id}
                className={mb}>
                <SubCategorySimpleView
                  level={level}
                  subCategory={subCategory}
                  handleOpenCategory={handleOpenCategory}
                />
                {subCategory.hasSubcategories && level < 2 && (
                  <SubCategoriesList
                    handleOpenCategory={handleOpenCategory}
                    categories={categories}
                    parentId={subCategory._id}
                    level={level + 1}
                  />
                )}
              </div>
            )
          })}
        </StyledDiv>
      )}
    </>
  )
}
SubCategoriesList.propTypes = {
  categories: PropTypes.array,
  parentId: PropTypes.string,
  level: PropTypes.string,
  handleOpenCategory: PropTypes.func,
  isTree: PropTypes.bool,
  isSmallScreen: PropTypes.bool
}
export default SubCategoriesList
