import { COLLECTIONS } from '__constants__'
import { doc } from 'firebase/firestore'
import { firestore } from 'services/firebase'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { useMemo } from 'react'

const useGetConnectedAccount = (accountId) => {
  const querySnapshot = useMemo(
    () =>
      accountId
        ? doc(firestore, COLLECTIONS.STRIPE_CONNECTED_ACCOUNTS, accountId)
        : null,
    [accountId]
  )
  return useDocumentData(querySnapshot)
}

export default useGetConnectedAccount
