import { Breadcrumbs, SettingsLayout, Spinner } from 'components'
import { Col, Row } from 'antd'
import {
  useAddressBaseActions,
  useGetAddressCustomActions
} from 'domains/Address/hooks'
import { useScreen, useTranslations } from 'hooks'

import { AddressList } from 'domains/Address/components'
import PropTypes from 'prop-types'
import { useGetUserAddresses } from 'domains/Address/hooks'
import { useMemo } from 'react'

const SettingsAddressesAllPage = (props) => {
  const { isListWithCreate, layoutProps } = props

  /* Getting translations instance */
  const { t } = useTranslations()

  const { xl } = useScreen()

  /* Fetching addresses */
  const [addresses, loading] = useGetUserAddresses()
  /* Getting base actions */
  const { onCreate } = useAddressBaseActions()

  const actions = useGetAddressCustomActions()

  const headingProps = {
    title: t('Shipping addresses')
  }

  const listProps = useMemo(
    () => ({
      isAddButton: true,
      isAddingLast: true,
      gutter: [0, 12],
      span: { xs: 24 },
      actions: { onCreate },
      isListWithCreate: !xl && isListWithCreate,
      addresses
    }),
    [isListWithCreate, addresses, onCreate, xl]
  )

  return (
    <SettingsLayout
      breadcrumbs={<Breadcrumbs />}
      headingProps={headingProps}
      actions={xl && actions}
      area="addressesAll">
      <Row gutter={[24, 16]}>
        {loading ? (
          <Col flex="auto">
            <Spinner height="30vh" text={t('Address loading')} />
          </Col>
        ) : (
          <Col {...layoutProps}>
            <AddressList {...listProps} />
          </Col>
        )}
      </Row>
    </SettingsLayout>
  )
}

SettingsAddressesAllPage.propTypes = {
  isListWithCreate: PropTypes.bool,
  layoutProps: PropTypes.object
}

export default SettingsAddressesAllPage
