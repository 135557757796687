import { getDocument } from 'services/api/firebase'
import { COLLECTIONS } from '__constants__'
/**
 * Function is used to expand statistics array with products data
 * @param   {array} productsStatisticArray an array with statistics by products
 * @returns {array} a filtered statistic array that expanded with product data
 */
const getProductsByStatistic = async (productsStatisticArray) => {
  try {
    const productsData = await Promise.all(
      productsStatisticArray?.map(async ({ productId, ...rest }) => {
        try {
          // Get a product's document
          const productData = await getDocument(COLLECTIONS.PRODUCTS, productId)

          const previewImageId = productData?.mediaObjects?.[0]

          // Get preview image document
          const previewImageDocument =
            previewImageId &&
            (await getDocument(COLLECTIONS.MEDIA_OBJECTS, previewImageId))

          // Return object with product's data, statistic and preview image document
          return productData
            ? {
                ...productData,
                ...rest,
                previewImageDocument: previewImageDocument || null
              }
            : null
        } catch (error) {
          throw new Error(error)
        }
      })
    )
    // Remove from array products that were deleted
    return productsData?.filter((value) => value)
  } catch (error) {
    throw new Error(error)
  }
}

export default getProductsByStatistic
