import { Typography, Col } from 'antd'
import styled from 'styled-components'

const CategoriesDrawerStyled = styled.div`
  padding: 20px;
  padding-bottom: 54px;
`

const ColStyled = styled(Col)`
  padding: 20px;
  flex-grow: 2;
`
const TextStyled = styled(Typography.Text)`
  font-size: 22px;
`

export { CategoriesDrawerStyled, ColStyled, TextStyled }
