import {
  AuthMethods,
  ProceedWithoutAuthBtn
} from '../../domains/Session/components'

import { AUTH_PATHS } from '../../__constants__'
import { AuthLayout } from '../../components'
import { useTranslations } from 'hooks'

const Login = () => {
  const { t } = useTranslations()

  return (
    <AuthLayout
      title={t('Log in')}
      subTitle={t(
        'Welcome to Lentli. Use one of the following methods below to start using the app.'
      )}>
      <AuthMethods
        authSwitchText={t('Dont have account') + '?'}
        authSwitchLinkText={t('Sign up')}
        authSwitchPath={`../${AUTH_PATHS.SIGNUP}`}
      />
      <ProceedWithoutAuthBtn />
    </AuthLayout>
  )
}

export default Login
