import { Button, Card, Image } from 'antd'

import styled from 'styled-components'

const CardStyled = styled(Card)`
  display: ${({ isDeleted }) => (isDeleted ? 'none' : 'block')};
  .ant-card-body {
    width: 100%;
    height: 100%;
    padding: 0px;
  }
`

const ImageWrapper = styled.div`
  & .ant-image {
    padding: 8px !important;
  }
  & .ant-image-mask {
    border-radius: ${({ borderRadius }) => `${borderRadius}px`} !important;
  }
`

const ImageStyled = styled(Image)`
  width: 86px !important;
  height: 86px !important;
  border-radius: ${({ borderRadius }) => `${borderRadius}px`};
  object-fit: cover;
`

const ButtonStyled = styled(Button)`
  color: ${({ color }) => color} !important;
  &:hover {
    color: ${({ hoverColor }) => hoverColor} !important;
  }

  &:active {
    color: ${({ activeColor }) => activeColor} !important;
  }
`

export { CardStyled, ImageStyled, ImageWrapper, ButtonStyled }
