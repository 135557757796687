import { CardDropdown, RateSelect, RelativeImage } from 'components'
import { Col, Row, Space, Typography } from 'antd'
import {
  HoverableCard,
  ImageOverlay,
  imageRow,
  likeFormStyles
} from './ProductSimpleView.styles'
import { currencySymbol, useProductBaseActions } from 'domains/Product/hooks'
import { memo, useCallback, useMemo } from 'react'

import { ProductLike } from 'domains/Product/components'
import { ProductLocationDetails } from 'domains/Product/components/ProductSimpleView/components'
import PropTypes from 'prop-types'
import { calculateFinalPayment } from 'helpers'
import noImage from '__assets__/illustrations/no-image.png'
import { useTranslations } from 'hooks'

const ProductSimpleView = (props) => {
  const { product, index, actions, isRentee, category } = props
  const { previewImgUrl } = product
  const { t } = useTranslations()
  const { onOpen, onEdit, onDelete } = useProductBaseActions({
    productId: product?._id,
    isRentee,
    actions,
    product
  })

  const computeProductPropsActions = useMemo(
    () => (isRentee ? {} : { onEdit, onDelete }),
    [isRentee, onDelete, onEdit]
  )
  const productCurrencySymbol = currencySymbol(product?.currency)

  const handleImageError = useCallback((e) => {
    e.currentTarget.src = noImage
  }, [])

  const productFinalPrice =
    product?.pricePerDayWithFees || calculateFinalPayment(product?.pricePerDay)

  return (
    <CardDropdown
      {...computeProductPropsActions}
      onClick={onOpen}
      document={product}
      index={index}
      data-testid="productListItem"
      {...actions}>
      <HoverableCard bordered={false}>
        <Row className="flex align-center">
          <Col span={24}>
            <RelativeImage
              ratio={1.5}
              onError={handleImageError}
              withOverlay={false}
              src={previewImgUrl}
            />
            {/* to darken the bottom and top of images */}
            <ImageOverlay />
            <ProductLike style={likeFormStyles} productId={product?._id} />
          </Col>
        </Row>
        <Row className="px-16 pb-16 pt-8 justify-between" style={imageRow}>
          <Col span={24} className="mb-4" style={{ height: 'fit-content' }}>
            <Typography.Paragraph
              strong
              className="mb-4"
              level={5}
              ellipsis={{ rows: 2 }}>
              {product?.name}
            </Typography.Paragraph>
            <Typography.Text
              className="mb-4"
              type="secondary"
              ellipsis={{ rows: 1 }}>
              {category}
            </Typography.Text>
            <ProductLocationDetails product={product} />
          </Col>
          <Col
            span={24}
            className="flex align-end justify-between"
            wrap={false}>
            <Space size={2} className="flex align-baseline">
              <Typography.Title level={4}>
                {productFinalPrice || 'n/a'}
              </Typography.Title>
              <Typography.Title level={4}>
                {productCurrencySymbol}
              </Typography.Title>
              <Typography.Text type="secondary">
                {' / '}
                {t('day')}
              </Typography.Text>
            </Space>
            <Space className="flex flex-col align-end" size={0}>
              <div onClick={(e) => e.stopPropagation()}>
                <RateSelect
                  mb={-1}
                  size="sm"
                  value={product?.avgRating || 0}
                  document={product}
                />
              </div>
            </Space>
          </Col>
        </Row>
      </HoverableCard>
    </CardDropdown>
  )
}

ProductSimpleView.propTypes = {
  product: PropTypes.object,
  index: PropTypes.number,
  actions: PropTypes.object,
  isRentee: PropTypes.bool,
  selectedDrafts: PropTypes.array,
  likeValue: PropTypes.number,
  category: PropTypes.string
}

export default memo(ProductSimpleView)
