import styled from 'styled-components'

const HeaderMenuStyled = styled.div`
  padding: 12px 20px;
  height: 56px;
  position: relative;
  z-index: 100;
  box-shadow: 0px -12px 24px rgba(0, 0, 0, 0.25);
  background: ${({ theme }) => theme.colorBgContainer};
`

export { HeaderMenuStyled }
