import { Col, Row, Space, Typography } from 'antd'

import PropTypes from 'prop-types'
import { RelativeImage } from 'components'
import { currencySymbol } from 'domains/Product/hooks'
import noImage from '__assets__/illustrations/no-image.png'
import { useCallback } from 'react'
import { useTranslations } from 'hooks'

const MapProductView = (props) => {
  const { previewImgUrl, name, pricePerDay, currency, onView } = props

  const productCurrencySymbol = currencySymbol(currency)
  const { t } = useTranslations()

  const handleImageError = useCallback((e) => {
    e.currentTarget.src = noImage
  }, [])

  return (
    <div style={{ width: '200px' }}>
      <Row className="mb-4">
        <Col span={10} className="pr-16">
          <RelativeImage
            src={previewImgUrl}
            withOverlay={false}
            onError={handleImageError}
          />
        </Col>
        <Col span={14}>
          <Space size={2} className="flex align-baseline">
            <Typography.Title level={5}>
              {pricePerDay || 'n/a'}
            </Typography.Title>
            <Typography.Title level={5}>
              {productCurrencySymbol}
            </Typography.Title>
            <Typography.Text type="secondary">
              {' / '}
              {t('day')}
            </Typography.Text>
          </Space>
          <Typography.Link onClick={onView}>
            {t('View details')}
          </Typography.Link>
        </Col>
      </Row>
      <Row>
        <Col flex="auto">
          <Typography.Paragraph
            ellipsis={{ rows: 2 }}
            className="full-width mb-0">
            {name}
          </Typography.Paragraph>
        </Col>
      </Row>
    </div>
  )
}

MapProductView.propTypes = {
  previewImgUrl: PropTypes.string,
  name: PropTypes.string,
  pricePerDay: PropTypes.number,
  currency: PropTypes.string,
  onView: PropTypes.func
}

export default MapProductView
