import { Avatar, Col, Row, Typography } from 'antd'

import { DottedBlock } from 'components'
import PropTypes from 'prop-types'
import { ROLES } from '__constants__'
import noProfile from '__assets__/illustrations/no-profile.png'
import { useGetUserDisplayName } from 'domains/User/hooks'
import { useMemo } from 'react'
import { useTranslations } from 'hooks'

const ComplaintReceiver = (props) => {
  const { order, role } = props
  const { t } = useTranslations()

  const userInfo = role === ROLES.RENTEE ? order?.rentee : order?.renter
  const roleInfo = {
    [ROLES.RENTEE]: {
      label: 'Rentee',
      email: order?.rentee?.email,
      phone: order?.rentee?.phone,
      avatarUrl: order?.rentee?.avatarUrl
    },
    [ROLES.RENTER]: {
      label: 'Renter',
      email: order?.renter?.email,
      phone: order?.renter?.phone,
      avatarUrl: order?.renter?.avatarUrl
    }
  }

  const { label, email, phone, avatarUrl } = roleInfo[role]
  const userDisplayName = useGetUserDisplayName(userInfo || {})
  const avatarComputed = useMemo(() => avatarUrl || noProfile, [avatarUrl])

  return (
    <>
      {order ? (
        <div className="mb-8">
          <Typography.Title level={5} className="mt-24 mb-12">
            {t('Complaint about')} {label.toLowerCase()}
          </Typography.Title>
          <Row className="flex flex-nowrap full-width align-center mb-8">
            <Col className="mr-16">
              <Avatar src={avatarComputed} size={40} />
            </Col>
            <Col flex="auto full-width">
              <Typography.Paragraph level={5} className="mb-0">
                {userDisplayName}
              </Typography.Paragraph>
              <Typography.Text type="secondary">{label}</Typography.Text>
            </Col>
          </Row>

          <DottedBlock
            className="mb-8"
            height="22px"
            left={<Typography.Text>{t('Email')}</Typography.Text>}
            right={<Typography.Text>{email}</Typography.Text>}
          />
          <DottedBlock
            className="mb-8"
            height="22px"
            left={<Typography.Text>{t('Phone')}</Typography.Text>}
            right={<Typography.Text>{phone}</Typography.Text>}
          />
        </div>
      ) : null}
    </>
  )
}

ComplaintReceiver.propTypes = {
  order: PropTypes.object,
  role: PropTypes.string
}

export default ComplaintReceiver
