import { SETTINGS_PATHS } from '__constants__/paths'
import { useUser } from 'modules/session-module/contexts'
import { STRIPE_ACCOUNT_STATUSES } from '__constants__'

/* t() is not necessary here, because we have it on settings page */
const {
  MY_PROFILE,
  CHANGE_PASSWORD,
  NOTIFICATIONS,
  ADDRESSES_ALL,
  STRIPE_ACCOUNT,
  CONTACT_US,
  VERIFICATION
} = SETTINGS_PATHS

const useGetSettingsTabsData = () => {
  const { user } = useUser()

  const SETTINGS_TABS_DATA = [
    { key: MY_PROFILE, title: 'My profile', icon: 'UserCircleOutlined' },
    { key: CHANGE_PASSWORD, title: 'Password', icon: 'Key1Outlined' },
    {
      key: NOTIFICATIONS,
      title: 'Notifications',
      icon: 'NotificationTextOutlined'
    },
    {
      key: STRIPE_ACCOUNT,
      title: 'Stripe account',
      icon: 'Bank3Outlined',
      isError:
        user?.stripeAccountStatus === STRIPE_ACCOUNT_STATUSES.ENABLED &&
        !user?.isStripeAccountVerified
    },
    {
      key: VERIFICATION,
      title: 'Verification',
      icon: 'CheckVerified2Outlined'
    },
    {
      key: ADDRESSES_ALL,
      title: 'Shipping addresses',
      icon: 'MarkerPin6Outlined'
    },
    { key: CONTACT_US, title: 'Contact us', icon: 'MessageTextSquare1Outlined' }
  ]

  return SETTINGS_TABS_DATA
}

export default useGetSettingsTabsData
