import { useEffect, useState } from 'react'

import { RDB_COLLECTIONS } from '__constants__'
import { useTranslations } from 'hooks'
import { message } from 'antd'
import firebase from 'firebase/compat/app'

const useGetAverageProductRating = (productId) => {
  const [loading, setLoading] = useState(false)
  const [review, setReview] = useState()

  const { t } = useTranslations()

  useEffect(() => {
    try {
      setLoading(true)
      const ref = firebase
        .database()
        .ref(`${RDB_COLLECTIONS.PRODUCT_RATINGS}/${productId}`)

      ref.on('value', (snapshot) => {
        const reviewData = snapshot?.val()

        if (reviewData) {
          /* Get count of rating */
          const countRating = Object.keys(reviewData)?.length
          /* Sum rating and divide */
          const ratingComputed = countRating
            ? Object.values(reviewData)?.reduce(
                (accumulator, currentValue) => accumulator + currentValue,
                0
              ) / countRating
            : null
          setReview(ratingComputed)
        } else setReview(0)
      })

      return () => {
        ref.off('value')
      }
    } catch (error) {
      message.error(
        `${t('An error occurred while fetching review data')}: ${error}`
      )
    } finally {
      setLoading(false)
    }
  }, [productId, t])

  return [review, loading]
}

export default useGetAverageProductRating
