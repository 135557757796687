import { Col, Divider, Row, Typography } from 'antd'
import {
  ComplaintDate,
  ComplaintReason
} from 'domains/Complaint/components/ComplaintList/components'
import { DottedBlock, Image } from 'components'

import { ComplaintOrderDetails } from 'domains/Complaint/components/ComplaintAdvancedView/components'
import { ComplaintReceiver } from 'domains/Complaint/components/ComplaintAdvancedView/components'
import { ComplaintStatus } from 'domains/Complaint/components/ComplaintList/components'
import PropTypes from 'prop-types'
import { ROLES } from '__constants__'
import { RefundDetails } from 'domains/Refund/components'
import { getTransformedImageUrl } from 'helpers'
import { styles } from './ComplaintAdvancedView.styles'
import { useGetOrder } from 'domains/Order/hooks'
import { useGetProductInitialValues } from 'domains/Product/hooks'
import { useGetRefund } from 'domains/Refund/hooks'
import { useStateWithStorage } from 'hooks'
import { useTranslations } from 'hooks'
import { useUser } from 'modules/session-module/contexts'

const ComplaintAdvancedView = (props) => {
  const { complaint } = props
  const { user } = useUser()

  const [productDataAll] = useGetProductInitialValues(complaint?.productId)
  const { product, mediaObjects } = productDataAll
  const previewImageUrl = getTransformedImageUrl(mediaObjects?.[0])
  const [order] = useGetOrder(complaint?.orderId)
  const [refunds] = useGetRefund(complaint?.orderId)
  const { t } = useTranslations()
  const refund = refunds?.[0]

  const [isOpenOrderDetails, setIsOpenOrderDetails] = useStateWithStorage(
    null,
    'complaint-order-details'
  )
  const role =
    user?._id === complaint?.productOwnerId ? ROLES.RENTEE : ROLES.RENTER
  const isRentee = role === ROLES.RENTEE
  return (
    <Row gutter={{ xs: 8, sm: 16, md: 24 }} data-area="complaint-details">
      <Col span={24} mb={3} data-area="complaint-details-info">
        {/* product preview */}
        <div className="flex align-center">
          <Image
            isSquare={false}
            src={previewImageUrl}
            style={styles.imageProps}
          />
          <div className="flex flex-col">
            <Typography.Text strong>{product?.name}</Typography.Text>
            <Typography.Text type="secondary">{t('Product')}</Typography.Text>
          </div>
        </div>
        <Divider />

        <DottedBlock
          className="mb-8"
          height="22px"
          left={<Typography.Text>{t('Status')}</Typography.Text>}
          right={<ComplaintStatus complaint={complaint} />}
        />

        <DottedBlock
          className="mb-8"
          height="22px"
          left={<Typography.Text>{t('Created')}</Typography.Text>}
          right={<ComplaintDate complaint={complaint} />}
        />

        <Typography.Title level={5} className="mt-24 mb-12">
          {t('Reason')}
        </Typography.Title>
        <ComplaintReason complaint={complaint} width="200px" />
        <div className="mb-12" data-area="complaint-details-comment">
          <Typography.Title level={5} className="mt-24 mb-12">
            {t('Comment')}
          </Typography.Title>

          <Typography.Text>{complaint?.comment || 'n/a'}</Typography.Text>
        </div>
        <ComplaintReceiver order={order} role={role} />
        {/* Order details */}
        <ComplaintOrderDetails
          order={order}
          isOpenOrderDetails={isOpenOrderDetails}
          setIsOpenOrderDetails={setIsOpenOrderDetails}
        />
        {/* we have to show this block only if this complaint was created with this user as a rentee */}
        {isRentee && <RefundDetails refund={refund} />}
      </Col>
    </Row>
  )
}

ComplaintAdvancedView.propTypes = {
  complaint: PropTypes.object,
  withTitle: PropTypes.bool,
  isSmallPhone: PropTypes.bool
}

export default ComplaintAdvancedView
