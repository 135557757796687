import { useCallback, useEffect } from 'react'

import { AddressView } from './components'
import PropTypes from 'prop-types'
import { Radio } from 'antd'
import { Spinner } from 'components'

const AddressAdvancedSelect = (props) => {
  const { addresses, loadingAddresses, defaultValue, value, onChange } = props

  const handleSelect = useCallback(
    (newValue) => {
      onChange?.(newValue)
    },
    [onChange]
  )

  // Set initial and next values
  useEffect(() => {
    handleSelect(value || defaultValue)
  }, [value, defaultValue, handleSelect])

  if (!loadingAddresses && !addresses?.length) return null
  if (loadingAddresses) return <Spinner height="80px" />

  return (
    !!addresses?.length && (
      <Radio.Group
        onChange={handleSelect}
        value={value}
        className="flex flex-col">
        {addresses?.map((address, index) => (
          <Radio
            className={index !== addresses?.length - 1 && 'mb-24'}
            key={address?._id}
            value={address}
            checked={address?.placeId === value?.placeId}>
            <div className="ml-24">
              <AddressView
                address={address}
                area-label={`address-${index + 1}`}
                index={index}
              />
            </div>
          </Radio>
        ))}
      </Radio.Group>
    )
  )
}

AddressAdvancedSelect.propTypes = {
  additionalAddress: PropTypes.object,
  value: PropTypes.object,
  onAddressChange: PropTypes.func,
  onSelect: PropTypes.func,
  onChange: PropTypes.func,
  defaultValue: PropTypes.object,
  addresses: PropTypes.array,
  loadingAddresses: PropTypes.bool
}

export default AddressAdvancedSelect
