import { Col, Row } from 'antd'
import {
  ProductAdvancedForm,
  ProductPreviewView
} from 'domains/Product/components'

import PropTypes from 'prop-types'
import { useProductPreviewFormActions } from 'domains/Product/components/ProductPreview/hooks'

const ProductFormWithPreview = (props) => {
  const {
    form,
    onFinish,
    steps,
    step,
    handleEnterPress,
    setStep,
    onValuesChange,
    handlePreview
  } = props

  // handle product preview modal actions
  const { isisOpenModal, handleOpenModal } = useProductPreviewFormActions()

  return (
    <Row gutter={24}>
      <Col xs={24} md={24} lg={24} xl={16} className="mb-16">
        <ProductAdvancedForm
          setStep={setStep}
          form={form}
          steps={steps}
          step={step}
          isOpenModal={isisOpenModal}
          onValuesChange={onValuesChange}
          handleOpenModal={handleOpenModal}
          onFinish={onFinish}
          handleEnterPress={handleEnterPress}
        />
      </Col>
      <Col xs={24} md={24} lg={24} xl={8}>
        <ProductPreviewView form={form} handlePreview={handlePreview} />
      </Col>
    </Row>
  )
}

ProductFormWithPreview.propTypes = {
  initialValues: PropTypes.object,
  form: PropTypes.object,
  onFinish: PropTypes.func,
  steps: PropTypes.array,
  step: PropTypes.number,
  handleEnterPress: PropTypes.func,
  handleBackspacePress: PropTypes.func,
  setStep: PropTypes.func,
  onValuesChange: PropTypes.func,
  handlePreview: PropTypes.func
}

export default ProductFormWithPreview
