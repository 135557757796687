import {
  getId,
  updateParent,
  updateParentHasManyRelationship
} from 'services/api/firebase'
import { useLocation, useNavigate } from 'react-router-dom'
import { useMutate, useTranslations } from 'hooks'

import { COLLECTIONS } from '__constants__'
import { message } from 'antd'
import { useState } from 'react'
import { PAYMENT_METHOD_TYPES } from '__constants__'

const useActionsPaymentMethodAdvancedForm = ({
  initialData,
  form,
  onCheckout
} = {}) => {
  /* State for loading */
  const [loading, setLoading] = useState(false)
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting navigate instance */
  const navigate = useNavigate()
  /* Getting location state */
  const navigationState = useLocation().state
  /* Getting mutate function */
  const { create, update } = useMutate()

  /* Function for preparing values for saving */
  const prepareValues = async (values = {}, additionalValues = {}) => {
    /* Getting id */
    const paymentMethodId =
      initialData?.paymentMethod?._id || getId(COLLECTIONS.PAYMENT_METHODS)

    /* Preparing paymentMethod values */
    const preparedValues = {
      _id: paymentMethodId,
      userId: initialData?.user?._id,
      ...additionalValues,
      name: values['paymentMethod']?.['name'] ?? null,
      details: values['paymentMethod']?.['details'] ?? null,
      cardNumber: values?.paymentMethod?.cardNumber ?? null,
      type: onCheckout
        ? PAYMENT_METHOD_TYPES.CARD
        : values['paymentMethod']?.['type'] ?? null
    }

    return preparedValues
  }

  /* Saving form data */
  const saveForm = async (values, callback) => {
    try {
      // Prepare data to be saved
      const data = await prepareValues(values)

      if (navigationState?.parent)
        await updateParent(navigationState.parent, data._id)

      // Save data
      let paymentMethodId
      if (initialData?.paymentMethod) {
        await update(
          COLLECTIONS.PAYMENT_METHODS,
          initialData.paymentMethod?._id,
          data
        )
        message.success(t('PaymentMethod successfully updated'))
      } else {
        await create(COLLECTIONS.PAYMENT_METHODS, data, data._id)

        await updateParentHasManyRelationship(
          data?.userId,
          COLLECTIONS.USERS,
          'paymentMethodIds',
          (paymentMethodId = data?._id)
        )

        message.success(t('PaymentMethod successfully created'))

        // Return the id of the newly created card
        return data._id
      }
      // Final callback
      callback?.(paymentMethodId)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      throw new Error(t('Something went wrong during data save'))
    }
  }

  /* On finish callback */
  const onFinish = async () => {
    if (loading) return // Avoid multiple calls

    try {
      setLoading(true)
      // Get form values
      const formValues = form.getFieldsValue()
      // Save data
      const newCardId = await saveForm(formValues)
      // Final callback
      if (onCheckout) {
        onCheckout(newCardId)
      } else {
        navigate(-1)
      }
    } catch (error) {
      setLoading(false)
      message.error(error.message)
    }
  }

  /* On cancel callback */
  const onReset = () => {
    form.resetFields()
    navigate(-1)
  }

  return { onFinish, onReset, loading, saveForm, prepareValues }
}

export default useActionsPaymentMethodAdvancedForm
