import { Spin } from 'antd'
import styled from 'styled-components'

export const StyledBox = styled.div`
  height: inherit;
  width: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const StyledSpinner = styled(Spin)`
  margin-bottom: -4px;
`
