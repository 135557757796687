import { Layout } from 'antd'
import styled from 'styled-components'

const StyledSider = styled(Layout.Sider)`
  &.ant-layout-sider {
    background-color: ${({ theme }) => theme.colorBgContainer};
    border: ${({ theme }) => `1px solid ${theme.colorBorderSecondary}`};
    top: 0;
    bottom: 0;
    z-index: 3;
  }
`
export default StyledSider
