import { Card, Col, Row, Tabs, Typography } from 'antd'
import { useStateWithStorage, useTranslations } from 'hooks'

import { NotificationReceiverUserIdRelationView } from 'domains/Notification/components'
import PropTypes from 'prop-types'
import { StickyContainer } from 'react-sticky'

const NotificationAdvancedView = (props) => {
  const { notification } = props

  /* State for active tab in AdvancedView with saving it to storage */
  const [activeTab, setActiveTab] = useStateWithStorage(
    null,
    'notification-advanced-view-active-tab'
  )

  /* Getting translations instance */
  const { t } = useTranslations()

  /* Getting TabPane component from Tabs */
  const { TabPane } = Tabs

  /* Handling change tab */
  const changeTab = (key) => setActiveTab(key)

  return (
    <Row gutter={{ xs: 8, sm: 16, md: 24 }} data-area="notification-details">
      <Col span={8} mb={3} data-area="notification-details-info">
        <Card className="mb-32">
          <div className="mb-12" data-area="notification-details-title">
            <Typography.Title level={5}>
              {notification?.title || 'n/a'}
            </Typography.Title>
          </div>
          <div className="mb-12" data-area="notification-details-message">
            <Row gutter={16}>
              <Col flex="auto" maxWidth="100%">
                <Typography.Text type="secondary">
                  {t('Message')}
                </Typography.Text>
              </Col>
              <Col flex="none" maxWidth="100%">
                <Typography.Text>
                  {notification?.message || 'n/a'}
                </Typography.Text>
              </Col>
            </Row>
          </div>
          <div className="mb-12" data-area="notification-details-status">
            <Row gutter={16}>
              <Col flex="auto" maxWidth="100%">
                <Typography.Text type="secondary">
                  {t('Status')}
                </Typography.Text>
              </Col>
              <Col flex="none" maxWidth="100%">
                <Typography.Text>
                  {notification?.status || 'n/a'}
                </Typography.Text>
              </Col>
            </Row>
          </div>
        </Card>
      </Col>
      <Col span={16} data-area="notification-extra-details">
        <StickyContainer>
          <Tabs defaultActiveKey={activeTab} onChange={changeTab}>
            <TabPane
              tab={t('Receiver user id')}
              key="#receiverUserId"
              tab-index="#receiverUserId">
              <NotificationReceiverUserIdRelationView
                notification={notification}
              />
            </TabPane>
          </Tabs>
        </StickyContainer>
      </Col>
    </Row>
  )
}

NotificationAdvancedView.propTypes = {
  notification: PropTypes.object
}

export default NotificationAdvancedView
