import { Button, Col, Divider, Image, Row, Typography, theme } from 'antd'

import { Icon } from '@qonsoll/icons'
import PageHeaderSimpleWrapper from './PageHeaderSimple.styles'
import PropTypes from 'prop-types'
import logo from '__assets__/icons/logo.svg'
import { useNavigate } from 'react-router-dom'
import { useScreen } from 'hooks'

const { Title } = Typography

const PageHeaderSimple = (props) => {
  const { title, actions, onlyLogo = false, onBack } = props

  const { xs } = useScreen()

  const token = theme.useToken().token

  const navigate = useNavigate()
  const handleOnBack = () => (onBack ? onBack() : navigate(-1))

  const logoSize = xs ? 24 : 28

  return (
    <PageHeaderSimpleWrapper theme={token}>
      {onlyLogo ? (
        <Row align="center">
          <Col>
            <Button
              onClick={handleOnBack}
              type="text"
              className="flex align-center justify-center"
              size="large"
              icon={<Icon name="ArrowLongLeftFilled" size={20} />}
            />
          </Col>
          <Col>
            <Divider className="full-height mx-16" type="vertical" />
          </Col>

          <Col className="flex align-center">
            <Image
              src={logo}
              className="cursor-pointer"
              preview={false}
              height={logoSize}
              alt="logo"
            />
          </Col>
        </Row>
      ) : (
        <Row align="center" className="full-width align-center">
          <Col>
            <Button
              onClick={handleOnBack}
              type="text"
              className="flex align-center justify-center"
              size="large"
              icon={<Icon name="ArrowLongLeftFilled" size={20} />}
            />
          </Col>
          <Col>
            <Divider className="full-height mx-16" type="vertical" />
          </Col>
          <Col className="flex align-center">
            <Title level={5}>{title}</Title>
          </Col>
          <Col className="ml-auto">{actions}</Col>
        </Row>
      )}
    </PageHeaderSimpleWrapper>
  )
}

PageHeaderSimple.propTypes = {
  actions: PropTypes.node,
  title: PropTypes.string,
  onlyLogo: PropTypes.bool,
  onBack: PropTypes.func
}

export default PageHeaderSimple
