import styled from 'styled-components'

const StyledBox = styled.div`
&:hover {
  background-color: ${({ colorFillSecondary }) => colorFillSecondary};
};
border-radius: ${({ borderRadius }) => `${borderRadius}px`};
cursor: pointer;
background: ${({ selected, colorPrimaryBg }) =>
  selected ? colorPrimaryBg : ''}}}
`
const styles = {
  boxStyles: {
    height: 10,
    width: 10,
    borderRadius: '50%',
    position: 'absolute',
    zIndex: 1000,
    border: '1px solid white'
  },
  avatarStyles: {
    name: 'PatientFilled',
    size: 18,
    justifyContent: 'center',
    alignItems: 'center',
    height: 'inherit'
  }
}
export { StyledBox, styles }
