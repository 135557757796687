import { Button, Grid, Typography, theme } from 'antd'
import {
  CountImagesWrapper,
  ImagesPreviewWrapper,
  MainImageWrapper,
  SmallImagePreview,
  SmallImagesPreviewWrapper
} from './ImagesPreview.styled'
import { generatePath, useNavigate } from 'react-router-dom'

import { APP_PATHS } from '__constants__/paths'
import { Icon } from '@qonsoll/icons'
import PropTypes from 'prop-types'
import { useGetImagesPreviewConfig } from 'domains/Product/hooks'
import { useTranslations } from 'hooks'

const { useBreakpoint } = Grid

const ImagesPreview = (props) => {
  const { productName, productId, mediaObjects } = props

  const { t } = useTranslations()
  const { sm, md } = useBreakpoint()
  const navigate = useNavigate()
  const { colorPrimaryActive } = theme.useToken().token

  const { config, count, showCount } = useGetImagesPreviewConfig(mediaObjects)

  const computedButtonText = sm ? t('Show all photos') : t('Photos')

  const handleShowAllPhotos = () => {
    const path = generatePath(APP_PATHS.PRODUCT_IMAGES_ALL, { productId })
    navigate(path)
  }

  return (
    <ImagesPreviewWrapper sm={sm} md={md}>
      <MainImageWrapper colorPrimaryActive={colorPrimaryActive} sm={sm}>
        <img
          src={config?.main}
          alt={productName}
          onClick={handleShowAllPhotos}
        />
        <Button
          icon={<Icon name="DotsGridOutlined" size={16} />}
          onClick={handleShowAllPhotos}>
          {computedButtonText}
        </Button>
      </MainImageWrapper>

      {config?.small?.length ? (
        <SmallImagesPreviewWrapper sm={sm} length={config?.small?.length}>
          {config?.small?.map((src, index) => {
            const isLastImage = index === config?.small?.length - 1
            const label = `+${count}`

            return (
              <SmallImagePreview
                length={config?.small?.length}
                key={`${src}-${index}`}
                sm={sm}
                onClick={handleShowAllPhotos}>
                {isLastImage && showCount && (
                  <CountImagesWrapper>
                    <Typography.Text className="count-images">
                      {label}
                    </Typography.Text>
                  </CountImagesWrapper>
                )}

                <img src={src} alt={productName} />
              </SmallImagePreview>
            )
          })}
        </SmallImagesPreviewWrapper>
      ) : null}
    </ImagesPreviewWrapper>
  )
}

ImagesPreview.propTypes = {
  mediaObjects: PropTypes.array,
  height: PropTypes.string,
  productId: PropTypes.string,
  productName: PropTypes.string
}

export default ImagesPreview
