import { EnumContainer, EnumItem } from './EnumSelect.styled'
import { Select, Typography } from 'antd'

import PropTypes from 'prop-types'
import { useTranslations } from 'hooks'

/**
 * It renders a selectable row if there are less than 4 options, otherwise it renders a select
 * @param value - The current value of the field
 * @param onChange - function that will be called when the value changes
 * @param options - An array of objects with the following shape:
 * @param placeholder - The placeholder text to show when no value is selected
 * @returns A function that returns a component
 */
const EnumSelect = (props) => {
  const { value, onChange, options, placeholder, wrapperStyles } = props

  /* Getting translations instance */
  const { t } = useTranslations()

  if (options?.length < 4) {
    return (
      <EnumContainer wrapperStyles={wrapperStyles}>
        {options?.map(({ label, value }) => (
          <EnumItem
            key={label}
            tabIndex={label}
            onClick={() => onChange?.(value)}
            selected={value === props.value}>
            <Typography.Text>{t(label)}</Typography.Text>
          </EnumItem>
        ))}
      </EnumContainer>
    )
  }

  return (
    <Select
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      options={options?.map((o) => ({ ...o, label: t(o.label) }))}
    />
  )
}

EnumSelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  wrapperStyles: PropTypes.object
}

export default EnumSelect
