import { Col, Popover, Row, Typography } from 'antd'
import { DateRange, DateRangeInputs } from 'components'
import { useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import moment from 'moment'
import { useGetProductDisabledDates } from 'domains/Product/hooks'
import { useTranslations } from 'hooks'

const CheckoutRangeSelect = (props) => {
  const {
    onChange,
    value,
    product,
    withTitle = true,
    setSelectedStartDate
  } = props
  const { t } = useTranslations()

  const [range, setRange] = useState({
    startDate: value?.startDate,
    endDate: value?.endDate
  })

  const [periodInDays, setPeriodInDays] = useState(1)

  const handleChange = (value) => {
    setRange((prevValue) => {
      const startDate = value?.startDate || prevValue?.startDate
      const endDate = value?.endDate || prevValue?.endDate
      return {
        startDate,
        endDate
      }
    })
    onChange?.({
      startDate: value?.startDate,
      endDate: value?.endDate
    })
    setSelectedStartDate(value?.startDate)
  }

  useEffect(() => {
    const { startDate, endDate } = range

    const computedPeriodInDays =
      moment(endDate).diff(moment(startDate), 'd') + 1
    setPeriodInDays(computedPeriodInDays)
  }, [range])

  useEffect(() => {
    setRange({
      startDate: value?.startDate,
      endDate: value?.endDate
    })
    setSelectedStartDate(value?.startDate)
  }, [value?.endDate, value?.startDate, setSelectedStartDate])

  const [disabledDates] = useGetProductDisabledDates(product?._id)

  return (
    <Row>
      <Col xs={24} sm={24} md={24} lg={20} xl={16} xxl={12}>
        {withTitle && (
          <div>
            <Typography.Title level={4} className="mb-24">
              {t('Dates')}
            </Typography.Title>
          </div>
        )}
        <Popover
          id="date-range-popover"
          content={
            <DateRange
              showHeader={false}
              withoutTopOffset={true}
              onChange={handleChange}
              range={range}
              periodInDays={periodInDays}
              disabledDates={disabledDates}
            />
          }
          arrow={false}
          trigger="click">
          <div>
            <DateRangeInputs range={range} onChange={handleChange} />
          </div>
        </Popover>
      </Col>
    </Row>
  )
}

CheckoutRangeSelect.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.object,
  withTitle: PropTypes.bool,
  product: PropTypes.object,
  setSelectedStartDate: PropTypes.func
}

export default CheckoutRangeSelect
