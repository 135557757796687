import {
  RefundARN,
  RefundStatus
} from 'domains/Refund/components/RefundDetails/components'
import { Typography, theme } from 'antd'

import { DottedBlock } from 'components'
import { Icon } from '@qonsoll/icons'
import { MOMENT_FORMATS } from '__constants__'
import PropTypes from 'prop-types'
import { StyledCollapse } from './RefundDetails.styled'
import moment from 'moment'
import { useStateWithStorage } from 'hooks'
import { useTranslations } from 'hooks'

const RefundDetails = (props) => {
  const { refund } = props

  const { t } = useTranslations()
  const { colorPrimary } = theme.useToken().token
  const { Panel } = StyledCollapse
  const [isOpenOrderDetails, setIsOpenOrderDetails] = useStateWithStorage(
    null,
    'complaint-refund-details'
  )

  const handleShowDetails = (defaultActiveKey) =>
    setIsOpenOrderDetails(defaultActiveKey)

  const date = moment
    .unix(refund?._createdAt?.seconds)
    .format(MOMENT_FORMATS.DAYS_MONTH_YEAR_WITH_DOTS)

  return (
    <>
      {refund ? (
        <StyledCollapse
          onChange={handleShowDetails}
          bordered={false}
          ghost
          expandIcon={({ isActive }) => (
            <Icon
              name={isActive ? 'ArrowShortUpFilled' : 'ArrowShortDownFilled'}
              size={20}
              fill={isActive ? colorPrimary : 'currentColor'}
            />
          )}
          expandIconPosition="end"
          activeKey={isOpenOrderDetails}>
          <Panel
            header={
              <Typography.Title level={5}>
                {t('Refund details')}
              </Typography.Title>
            }
            key="1">
            <div className="flex flex-col mb-12">
              <DottedBlock
                className="mb-8"
                height="22px"
                left={<Typography.Text>{t('Created')}</Typography.Text>}
                right={<Typography.Text>{date}</Typography.Text>}
              />
              <DottedBlock
                className="mb-8"
                height="22px"
                left={<Typography.Text>{t('Status')}</Typography.Text>}
                right={<RefundStatus refund={refund} />}
              />

              <DottedBlock
                className="mb-8"
                height="22px"
                left={<Typography.Text>{t('Track number')}</Typography.Text>}
                right={<RefundARN refund={refund} inRow />}
              />
            </div>
          </Panel>
        </StyledCollapse>
      ) : null}
    </>
  )
}
RefundDetails.propTypes = {
  refund: PropTypes.object,
  isOpenOrderDetails: PropTypes.string,
  setIsOpenOrderDetails: PropTypes.func
}

export default RefundDetails
