import { useEffect, useState } from 'react'

import { RDB_COLLECTIONS } from '__constants__'
import firebase from 'firebase/compat/app'

/**
 * Custom hook to get the number of products for a given category.
 *
 * @param {string} categoryId - The ID of the category.
 * @returns {number|null} The number of products for the category, or null if not available.
 */
const useGetProductsNumber = (categoryId) => {
  const [number, setNumber] = useState(null)

  useEffect(() => {
    const productsNumberRef = firebase
      .database()
      .ref(`${RDB_COLLECTIONS.PRODUCTS_NUMBERS_BY_CATEGORY}/${categoryId}`)

    const getProductsNumber = async () => {
      await productsNumberRef.on('value', (data) => {
        const result = data.val()

        if (result) {
          setNumber(result)
        } else {
          setNumber(0)
        }
      })
    }

    if (categoryId) {
      getProductsNumber()
    }
  }, [categoryId])

  return number
}

export default useGetProductsNumber
