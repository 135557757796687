import { message } from 'antd'

import { COLLECTIONS, PATHS } from '__constants__'
import firebase from 'firebase/compat/app'

/**
 * Deletes a product from the Firestore database and navigates back to the previous screen.
 * @async
 * @param {Object} props - The props object.
 * @param {string} options.productId - The ID of the product to delete.
 * @param {Function} props.t - The translation function.
 * @param {Object} props.navigate - The navigation object.
 * @returns {void}
 */
const deleteProduct = async ({ productId, t, navigate, setModalVisible }) => {
  try {
    navigate(PATHS.AUTHENTICATED.MY_PRODUCTS_ALL)
    await firebase
      .firestore()
      .collection(COLLECTIONS.PRODUCTS)
      .doc(productId)
      .delete()
    setModalVisible(false)
    message.success(t('Product successfully deleted'))
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error deleting product:', error)
    message.error(t('Failed to delete product'))
  }
}

export default deleteProduct
