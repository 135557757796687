import { Breadcrumbs, PageLayout } from 'components'
import { Col, Row } from 'antd'

import { OrderAdvancedForm } from 'domains/Order/components'
import { useNavigate } from 'react-router-dom'
import { useTranslations } from 'hooks'

const OrderCreatePage = (props) => {
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting navigate instance */
  const navigate = useNavigate()

  /* Configuring page header */
  const headingProps = {
    title: t('Order create'),
    textAlign: 'left'
  }

  /* Function to handle back click */
  const handleBack = () => navigate(-1)

  return (
    <PageLayout
      onBack={handleBack}
      breadcrumbs={<Breadcrumbs />}
      headingProps={headingProps}
      area="order">
      <Row>
        <Col xs={24} md={20} lg={16} xl={14}>
          <OrderAdvancedForm />
        </Col>
      </Row>
    </PageLayout>
  )
}

export default OrderCreatePage
