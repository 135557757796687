import { Image } from 'components'
import PropTypes from 'prop-types'
import { Typography } from 'antd'
import noImage from '__assets__/illustrations/no-image.png'
import styles from '../../OrderList.styles'
import { useMemo } from 'react'

const OrderProduct = (props) => {
  const { order } = props

  const computedImageUrl = useMemo(
    () => order?.previewImageUrl || order?.product?.previewImgUrl,
    [order?.previewImageUrl, order?.product]
  )
  return (
    <div className="flex align-center">
      <Image
        isSquare={false}
        src={computedImageUrl}
        style={styles.imageProps}
        fallback={noImage}
      />
      <div className="flex flex-col justify-center">
        <Typography.Text>{order?.product?.name}</Typography.Text>
        <Typography.Text type="secondary">
          № {order?.orderNumber}
        </Typography.Text>
      </div>
    </div>
  )
}

OrderProduct.propTypes = {
  order: PropTypes.object
}

export default OrderProduct
