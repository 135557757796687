import { Layout, Menu, Button } from 'antd'
import styled from 'styled-components'

const { Footer } = Layout

const FooterStyled = styled(Footer)`
  padding: 6px 8px 10px 8px;
  background: ${({ theme }) => theme.colorBgContainer};
  display: flex;
  height: 64px;
  position: relative;
  z-index: 10000;
  border-top: ${({ theme }) => `1px solid ${theme.colorBorderSecondary}`};
`

const StyledMenu = styled(Menu)`
  display: flex;
  height: fit-content;
  border-inline-end: none !important;
  width: 100%;
`

const StyledMenuItem = styled(Menu.Item)`
  height: 48px;
  overflow: initial !important;

  &:hover,
  &.ant-menu-item-selected {
    background: unset !important;
  }
  &.ant-menu-item {
    margin: 0 !important;
    padding: 5px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 22%;
    height: 48px;
    gap: 4px;
    color: ${({ theme }) => theme.colorTextLabel};
  }
  &.ant-menu-item:nth-child(2) {
    margin-right: auto !important;
  }
  .ant-menu-title-content {
    line-height: initial;
    margin-inline-start: 0 !important;
    font-size: 10px;
    line-height: 10px;
    font-weight: 600;
  }
`

const CategoriesStyledButton = styled(Button)`
  position: absolute;
  bottom: ${({ position }) => (position === 'right' ? '70px' : '20px')};
  right: ${({ position }) =>
    position === 'right' ? '8px' : 'calc(50% - 28px)'};
  width: 56px;
  height: 56px;
  border-radius: 100%;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 8px 16px rgba(11, 10, 14, 0.12);
  background: ${({ theme }) => theme.colorBgContainer};

  &:hover {
    background: ${({ theme }) => theme.colorBgContainer} !important;
    box-shadow: 0px 8px 16px rgba(11, 10, 14, 0.2);
  }
`
export { FooterStyled, StyledMenu, StyledMenuItem, CategoriesStyledButton }
