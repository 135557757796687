import { Typography } from 'antd'
import styled from 'styled-components'

const StyledText = styled(Typography.Text)`
  width: 100%;
  &:hover {
    text-decoration: underline;
    color: ${({ colorPrimary }) => colorPrimary};
    cursor: pointer;
  }
`
const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-wrap: wrap;
  overflow-y: auto;
  align-content: flex-start;
  ::-webkit-scrollbar {
    display: none;
  }
`

export { StyledText, StyledDiv }
