import { Card, theme } from 'antd'
import { RelativeImage } from 'components'

import styled from 'styled-components'

const StyledRelativeImage = styled(RelativeImage).attrs(
  ({ isExtraSmallScreen }) => ({
    style: {
      width: isExtraSmallScreen ? '100%' : '64px'
    }
  })
)`
  border-bottom-right-radius: ${({ isExtraSmallScreen }) =>
    isExtraSmallScreen ? '0 !important' : 'initial'};
  border-bottom-left-radius: ${({ isExtraSmallScreen }) =>
    isExtraSmallScreen ? '0 !important' : 'initial'};
`

const StyledCard = styled(Card)`
  width: 100%;
`
// IMAGE OVERLAY
const ImageOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: transparent;
  background-image: ${({ bg }) => `linear-gradient(
    transparent 60%,
    ${bg}
  )`};
`
const OverlayWithStyled = (props) => {
  const { colorWrapperBg } = theme.useToken().token

  return <ImageOverlay {...props} bg={colorWrapperBg} />
}
export { OverlayWithStyled as ImageOverlay, StyledRelativeImage, StyledCard }
