import { useLoading } from 'hooks'
import { useMemo } from 'react'
import { useUser } from 'modules/session-module/contexts'
import { useGetAddress } from 'domains/Address/hooks'

const useGetUserInitialValues = (id) => {
  const { user, loading: userLoading } = useUser()

  const [address, addressLoading] = useGetAddress(user?.addressId)

  /* Merge all fetched data */
  const initialValues = useMemo(
    () => ({
      user,
      address
    }),
    [user, address]
  )

  /* Merge all loading states */
  const loadings = useMemo(
    () => (id ? [!user, !address, userLoading, addressLoading] : []),
    [id, user, address, userLoading, addressLoading]
  )

  /* Getting loading state using merged loadings */
  const loading = useLoading(loadings)

  return [initialValues, loading]
}

export default useGetUserInitialValues
