import { reauthenticateWithCredential, updatePassword } from 'firebase/auth'
import { useHandleError, useTranslations } from 'hooks'

import { KEYS } from '__constants__'
import firebase from 'firebase/compat/app'
import { notification } from 'antd'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useNavigate } from 'react-router-dom'
import { useSessionActions } from 'modules/session-module/hooks'

const useActionsChangePasswordForm = ({ form, withNavigate }) => {
  const [user] = useAuthState(firebase.auth())
  const { t } = useTranslations()
  const { login } = useSessionActions()
  const handleError = useHandleError()

  const navigate = useNavigate()

  const navigateCallback = () => (withNavigate ? navigate(-1) : null)

  // Get user credential
  const getCredential = (oldPassword) =>
    firebase.auth.EmailAuthProvider.credential(user?.email, oldPassword)

  const reauthenticateWithCredentialSuccessCallback = (user, password) =>
    updatePassword(user, password)
      .then(() => updatePasswordSuccessCallback(password, user?.email))
      .catch((error) =>
        handleError(error, t('Something went wrong during update password'))
      )

  const updatePasswordSuccessCallback = (password, email) => {
    notification.success({
      message: t('Password successfully changed')
    })

    const credentials = { password, email }
    login({ credentials })
    form.resetFields()
    navigateCallback()
  }

  /* On finish callback */
  const onFinish = (formData) => {
    const { password, oldPassword } = formData

    // Get user credential
    const credential = getCredential(oldPassword)

    if (!credential)
      return handleError({ message: t('Cannot get user credentials') })

    // The function that allows to verify whether the user has entered the correct current password is:
    reauthenticateWithCredential(user, credential)
      .then((value) =>
        // If the check for the user's current password is successful, call password change function.
        reauthenticateWithCredentialSuccessCallback(value?.user, password)
      )
      .catch((error) =>
        handleError(error, t('Something went wrong during credential'))
      )
  }

  /* On cancel callback */
  const onReset = () => {
    form.resetFields()
    navigateCallback()
  }

  /* Handling enter key press */
  const handleKeyPress = (e) => {
    if (e.key === KEYS.ENTER) {
      e.preventDefault()
      onFinish()
    }
  }

  return { onFinish, onReset, handleKeyPress }
}

export default useActionsChangePasswordForm
