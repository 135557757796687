import { APP_PATHS } from '../paths'

const {
  MY_PRODUCTS_ALL,
  ORDERS_ALL,
  REVIEWS_ALL,
  PRODUCT_DRAFTS_ALL,
  RENTING_REQUESTS_ALL,
  TICKETS_ALL
} = APP_PATHS

const NAV_OPTIONS = {
  PRODUCT: [
    { label: 'Published', value: MY_PRODUCTS_ALL },
    {
      label: 'Drafts',
      value: PRODUCT_DRAFTS_ALL
    }
  ],
  ORDER_AND_REQUEST: [
    {
      label: 'Requests',
      value: RENTING_REQUESTS_ALL
    },
    { label: 'Orders', value: ORDERS_ALL }
  ],
  COMPLAINTS: [
    { label: 'Tickets', value: TICKETS_ALL },
    {
      label: 'Reviews',
      value: REVIEWS_ALL
    }
  ]
}

export default NAV_OPTIONS
