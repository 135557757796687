import { Image, theme } from 'antd'
import { memo, useEffect, useMemo, useRef, useState } from 'react'

import { ImageOverlay } from 'components'
import PropTypes from 'prop-types'
import { imgProps } from './RelativeImage.styled'
import noImage from '__assets__/illustrations/no-image.png'

const RelativeImage = (props) => {
  const {
    src,
    ratio = 4.5 / 3,
    overlay,
    withOverlay = true,
    imgStyle,
    style,
    ...rest
  } = props

  const [width, setWidth] = useState(200)
  const ref = useRef(null)

  useEffect(() => {
    const resizeObserver = new ResizeObserver((event) => {
      // Depending on the layout, you may need to swap inlineSize with blockSize
      // https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserverEntry/contentBoxSize
      setWidth(event?.[0]?.contentBoxSize?.[0]?.inlineSize || '100%')
    })

    resizeObserver?.observe?.(ref.current)
    return () => {
      resizeObserver?.disconnect?.()
    }
  }, [])

  const height = useMemo(() => width / ratio, [width, ratio])

  const { borderRadiusLG } = theme.useToken().token

  return (
    <div ref={ref} style={style}>
      <Image
        {...rest}
        src={src || noImage}
        width={width}
        height={height}
        style={{
          borderRadius: borderRadiusLG,
          ...imgProps,
          ...imgStyle,
          ...style
        }}
        preview={false}
      />
      {withOverlay && <ImageOverlay className="p-16">{overlay}</ImageOverlay>}
    </div>
  )
}

RelativeImage.propTypes = {
  src: PropTypes.string,
  ratio: PropTypes.number,
  overlay: PropTypes.node,
  withOverlay: PropTypes.bool,
  imgStyle: PropTypes.object,
  style: PropTypes.object
}

export default memo(RelativeImage)
