import { Card, Typography } from 'antd'
import {
  ComplaintDate,
  ComplaintProduct,
  ComplaintReason,
  ComplaintStatus
} from 'domains/Complaint/components/ComplaintList/components'
import { APP_PATHS } from '__constants__'
import { generatePath, useNavigate } from 'react-router-dom'

import { DottedBlock } from 'components'
import PropTypes from 'prop-types'
import { useTranslations } from 'hooks'
const showPath = APP_PATHS.COMPLAINT_SHOW
const ComplaintSimpleView = (props) => {
  const { complaint } = props
  const navigate = useNavigate()

  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting base actions */
  const onOpen = (e) => {
    e.stopPropagation()
    const pathParams = { complaintId: complaint?._id }
    const path = generatePath(showPath, pathParams)
    navigate(path)
  }

  return (
    <Card onClick={onOpen}>
      <div className="flex align-start justify-between mb-16">
        <ComplaintProduct isSmallScr complaint={complaint} />
        <ComplaintStatus complaint={complaint} />
      </div>

      <div className="flex align-center justify-between  mb-8">
        <DottedBlock
          height="22px"
          left={<Typography.Text>{t('Complaint reason')}</Typography.Text>}
          right={<ComplaintReason complaint={complaint} />}
        />
      </div>
      <div className="flex align-center justify-between ">
        <DottedBlock
          height="22px"
          left={<Typography.Text>{t('Created')}</Typography.Text>}
          right={<ComplaintDate complaint={complaint} />}
        />
      </div>
    </Card>
  )
}

ComplaintSimpleView.propTypes = {
  complaint: PropTypes.object,
  onSelect: PropTypes.func,
  index: PropTypes.number,
  isSelected: PropTypes.bool,
  actions: PropTypes.object
}

export default ComplaintSimpleView
