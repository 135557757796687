import { Elements } from '@stripe/react-stripe-js'
import PropTypes from 'prop-types'
import StripeContext from './StripeContext'
import { loadStripe } from '@stripe/stripe-js'
import { useMemo } from 'react'

const StripeProvider = (props) => {
  const { children } = props

  const stripePromise = useMemo(
    () => loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY),
    []
  )

  return (
    <StripeContext.Provider value={{}}>
      <Elements stripe={stripePromise}>{children}</Elements>
    </StripeContext.Provider>
  )
}

StripeProvider.propTypes = {
  children: PropTypes.any
}

export default StripeProvider
