const CITIES = {
  OSLO: 'OSLO',
  LONDON: 'LONDON',
  BERGEN: 'BERGEN',
  LIVERPOOL: 'LIVERPOOL',
  WARSAW: 'WARSAW',
  KRAKOW: 'KRAKOW',
  KYIV: 'KYIV'
}

const CITIES_LABELS = {
  OSLO: 'Oslo',
  LONDON: 'London',
  BERGEN: 'Bergen',
  LIVERPOOL: 'Liverpool',
  WARSAW: 'Warsaw',
  KRAKOW: 'Krakow',
  KYIV: 'Kyiv'
}

const CITIES_OPTIONS = Object.keys(CITIES_LABELS).map((key) => ({
  label: CITIES[key],
  value: key
}))

export default CITIES
export { CITIES_OPTIONS, CITIES, CITIES_LABELS }
