import './PhoneInput.css'

import PropTypes from 'prop-types'
import { StyledPhoneInput } from './PhoneInputStyled'
import { forwardRef } from 'react'
import { theme } from 'antd'

const PhoneInput = forwardRef((props, ref) => {
  const { country = 'no', value = '+47', ...rest } = props

  const number = value || ''
  const { colorBgContainer, colorBorder } = theme.useToken().token

  return (
    <StyledPhoneInput
      inputStyle={{
        background: colorBgContainer,
        borderColor: colorBorder,
        height: 40,
        borderRadius: 8,
        ...props?.inputStyle
      }}
      buttonStyle={{
        borderColor: colorBorder,
        borderBottomLeftRadius: 8,
        borderTopLeftRadius: 8,
        background: 'white',
        ...props?.buttonStyle
      }}
      inputClass="ant-input"
      country={country}
      value={number}
      ref={ref}
      {...rest}
    />
  )
})

PhoneInput.displayName = 'PhoneInput'
PhoneInput.propTypes = {
  country: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.string,

  onlyCountries: PropTypes.arrayOf(PropTypes.string),
  preferredCountries: PropTypes.arrayOf(PropTypes.string),
  excludeCountries: PropTypes.arrayOf(PropTypes.string),

  placeholder: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  searchNotFound: PropTypes.string,
  disabled: PropTypes.bool,

  containerStyle: PropTypes.object,
  inputStyle: PropTypes.object,
  buttonStyle: PropTypes.object,
  dropdownStyle: PropTypes.object,
  searchStyle: PropTypes.object,

  containerClass: PropTypes.string,
  inputClass: PropTypes.string,
  buttonClass: PropTypes.string,
  dropdownClass: PropTypes.string,
  searchClass: PropTypes.string,

  autoFormat: PropTypes.bool,

  enableAreaCodes: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  enableTerritories: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.arrayOf(PropTypes.string)
  ]),

  disableCountryCode: PropTypes.bool,
  disableDropdown: PropTypes.bool,
  enableLongNumbers: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  countryCodeEditable: PropTypes.bool,
  enableSearch: PropTypes.bool,
  disableSearchIcon: PropTypes.bool,
  disableInitialCountryGuess: PropTypes.bool,
  disableCountryGuess: PropTypes.bool,

  regions: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),

  inputProps: PropTypes.object,
  localization: PropTypes.object,
  masks: PropTypes.object,
  areaCodes: PropTypes.object,

  preserveOrder: PropTypes.arrayOf(PropTypes.string),

  defaultMask: PropTypes.string,
  alwaysDefaultMask: PropTypes.bool,
  prefix: PropTypes.string,
  copyNumbersOnly: PropTypes.bool,
  renderStringAsFlag: PropTypes.string,
  autocompleteSearch: PropTypes.bool,
  jumpCursorToEnd: PropTypes.bool,
  priority: PropTypes.object,
  enableAreaCodeStretch: PropTypes.bool,
  enableClickOutside: PropTypes.bool,
  showDropdown: PropTypes.bool,

  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  onEnterKeyPress: PropTypes.func,
  isValid: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  defaultErrorMessage: PropTypes.string,
  specialLabel: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'lg']),
  transform: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
}

export default PhoneInput
