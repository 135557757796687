import { Col, Row } from 'antd'
import { useCallback, useEffect, useState } from 'react'

import { Spinner } from 'components'

const StripeCallbackRN = () => {
  const [isOpened, setIsOpened] = useState(false)

  const redirect = useCallback(() => {
    window.open('lentli://', '_self')
    setIsOpened(true)
  }, [])

  useEffect(() => {
    let isMounted = true
    isMounted && !isOpened && redirect()
    return () => (isMounted = false)
  }, [isOpened, redirect])

  return (
    <Row>
      <Col span={24}>
        <Spinner height="100vh" text="Loading" />
      </Col>
    </Row>
  )
}

export default StripeCallbackRN
