import { Button, Image, Space, Typography, theme } from 'antd'

import IllustrationAccessDenied from '__assets__/illustrations/access-denied.svg'
import { ServiceLayout } from 'components'
import { useNavigate } from 'react-router-dom'
import { useTranslations } from 'hooks'

const AccessDenied = () => {
  const { t } = useTranslations()
  const navigate = useNavigate()
  const { sizeLG } = theme.useToken().token
  const goBack = () => navigate(-1)

  return (
    <ServiceLayout>
      <Space style={{ display: 'flex' }} direction="vertical" align="center">
        <Image src={IllustrationAccessDenied} alt="Qonsoll" preview={false} />
        <Typography.Title level={3}>{t('Restricted space')}</Typography.Title>
        <Typography.Text>
          {`${t('The page you')}'${t(
            're trying to access has restricted access'
          )}`}
        </Typography.Text>
        <Button
          type="primary"
          size="large"
          block
          onClick={goBack}
          style={{ marginTop: sizeLG }}>
          {t('Go back')}
        </Button>
      </Space>
    </ServiceLayout>
  )
}

export default AccessDenied
