import { CardDropdown, ImageOverlay } from 'components'
import { Col, Row } from 'antd'

import PropTypes from 'prop-types'
import { RelativeImage } from 'components'
import { TitleStyled } from './CategorySimpleView.styles'
import { useCategoryBaseActions } from 'domains/Category/hooks'
import { useTranslations } from '@qonsoll/translation'

const CategorySimpleView = (props) => {
  const { category, index, actions, categoryBgIcon, navigateToCategoriesAll } =
    props

  /* Getting base actions */
  const { onOpen } = useCategoryBaseActions({
    categoryId: category?._id,
    actions
  })

  const { language } = useTranslations()

  return (
    <CardDropdown
      onClick={navigateToCategoriesAll ? navigateToCategoriesAll : onOpen}
      document={category}
      index={index}
      data-testid="categoryListItem"
      {...actions}>
      <Row style={{ height: 'inherit' }}>
        <Col span={24}>
          {categoryBgIcon ? (
            <div
              className="flex justify-center align-center"
              style={{ height: '100%' }}>
              {categoryBgIcon}
              <ImageOverlay>
                <TitleStyled level={5}>
                  {category?.names?.[language.toUpperCase()] || category?.name}
                </TitleStyled>
              </ImageOverlay>
            </div>
          ) : (
            <RelativeImage
              src={category?.imageUrl}
              overlay={
                <TitleStyled level={5}>
                  {category?.names?.[language.toUpperCase()] || category?.name}
                </TitleStyled>
              }
            />
          )}
        </Col>
      </Row>
    </CardDropdown>
  )
}

CategorySimpleView.propTypes = {
  category: PropTypes.object,
  navigateToCategoriesAll: PropTypes.func,
  index: PropTypes.number,
  isSelected: PropTypes.bool,
  actions: PropTypes.object,
  categoryBgIcon: PropTypes.node
}

export default CategorySimpleView
