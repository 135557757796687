import { COLLECTIONS, PRODUCT_STATUSES } from '__constants__'
import { doc, writeBatch } from 'firebase/firestore'
import { useHandleError, useTranslations } from 'hooks'
import { useMemo, useState } from 'react'

import _ from 'lodash'
import { firestore } from 'services/firebase'
import { message } from 'antd'

const usePublishDraftProducts = (draftProducts) => {
  const { t } = useTranslations()
  const handleError = useHandleError()

  const [selectedDrafts, setSelectedDrafts] = useState([])
  const [publishLoading, setPublishLoading] = useState(false)

  const checkProductCompletionForPublish = (product) =>
    product?.name &&
    product?.addressId &&
    product?.pricePerDay &&
    product?.mediaObjects?.length &&
    product?.cancellationTerms

  const disablePublish =
    !draftProducts?.length ||
    !_.find(draftProducts, checkProductCompletionForPublish)

  const disableDraftProductsTab = useMemo(
    () => !draftProducts?.length,
    [draftProducts]
  )

  const onDraftsToPublishSelect = (id) => (e) => {
    e.stopPropagation()
    setSelectedDrafts((prevData) =>
      prevData.includes(id)
        ? prevData.filter((item) => item !== id)
        : [...prevData, id]
    )
  }

  const onPublishSelectedProducts = async () => {
    try {
      setPublishLoading(true)
      const batch = writeBatch(firestore)

      selectedDrafts?.forEach((item) => {
        const ref = doc(firestore, COLLECTIONS.PRODUCTS, item)

        batch.update(ref, { status: PRODUCT_STATUSES.PUBLISHED })
      })
      await batch.commit()

      setSelectedDrafts([])
      message.success(t('Product successfully updated'))
    } catch (error) {
      handleError(error, t('Something went wrong with products publishing'))
    } finally {
      setPublishLoading(false)
    }
  }

  const onPublishCompletedProducts = async () => {
    try {
      setPublishLoading(true)

      const batch = writeBatch(firestore)

      draftProducts?.forEach((product) => {
        if (checkProductCompletionForPublish(product)) {
          const ref = doc(firestore, COLLECTIONS.PRODUCTS, product?._id)

          batch.update(ref, { status: PRODUCT_STATUSES.PUBLISHED })
        }
      })
      await batch.commit()

      message.success(t('Product successfully updated'))
    } catch (error) {
      handleError(error, t('Something went wrong with products publishing'))
    } finally {
      setPublishLoading(false)
    }
  }

  return {
    onDraftsToPublishSelect,
    onPublishCompletedProducts,
    onPublishSelectedProducts,
    selectedDrafts,
    disablePublish,
    publishLoading,
    disableDraftProductsTab
  }
}

export default usePublishDraftProducts
