import LikedProductsContext from './LikedProductsContext'
import PropTypes from 'prop-types'
import { useGetFavoriteProducts } from 'domains/Product/hooks'
import { useMemo, useEffect, useCallback } from 'react'
import { useUser } from 'modules/session-module/contexts'
import { useProductLikesActions } from 'domains/Product/hooks'

const LikedProductsProvider = ({ children }) => {
  const { userId, loaded } = useUser()

  const [products, loading] = useGetFavoriteProducts(userId)
  const { onLikeUpdate } = useProductLikesActions()

  const productsAmount = useMemo(() => products?.length || 0, [products])

  const memoizedValue = useMemo(
    () => ({ products, loading, productsAmount }),
    [products, loading, productsAmount]
  )

  const addLikedProductsFromStorage = useCallback(async () => {
    // Grt formatted object with liked products  from LS
    const likedProducts = localStorage.getItem('likedProducts') || '[]'
    const likedProductsArray = JSON.parse(likedProducts)

    // If liked products are exist - add it to DB
    if (likedProductsArray?.length) {
      await Promise.all(likedProductsArray?.map((id) => onLikeUpdate(id, true)))
      localStorage.removeItem('likedProducts')
    }
  }, [onLikeUpdate])

  useEffect(() => {
    // if user is loaded add to db products from LS
    if (loaded) {
      addLikedProductsFromStorage()
    }
  }, [addLikedProductsFromStorage, loaded])

  return (
    <LikedProductsContext.Provider value={memoizedValue}>
      {children}
    </LikedProductsContext.Provider>
  )
}

LikedProductsProvider.propTypes = {
  children: PropTypes.element.isRequired
}

export default LikedProductsProvider
