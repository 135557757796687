import { Avatar, Badge, Col, Row, Tooltip, Typography, theme } from 'antd'
import { StyledBox, styles } from './ChatHeader.styles'
import { useChatsContext, useCurrentChat } from '@qonsoll/lentli-chat-core'

import AnimatedDots from '../../../../components/AnimatedDots/AnimatedDots'
import { Box } from 'components'
import { Icon } from '@qonsoll/icons'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useGetChatMembers } from 'modules/chat-module/domains/Chat/hooks'
import { useGetUserProfilePaths } from 'domains/User/hooks'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useScreen } from 'hooks'
import { useTranslations } from '@qonsoll/translation'

const ChatHeader = (props) => {
  const {
    withWrapper = true,
    onIconClick,
    iconProps,
    rightIconName,
    withUser,
    setOppositeUserId,
    withBackButton
  } = props

  const navigate = useNavigate()
  const chat = useCurrentChat()
  const { oppositeUserData } = useChatsContext()
  const { t, language } = useTranslations()
  const { colorPrimary, borderRadius } = theme.useToken().token
  const { lg } = useScreen()

  let inner = (
    <StyledBox className="disquss-header" borderRadius={borderRadius}>
      <Typography.Text {...styles.textStyles}>{t('Chat')}</Typography.Text>
    </StyledBox>
  )

  const displayName = useMemo(
    () => oppositeUserData?.firstName + ' ' + oppositeUserData?.lastName,
    [oppositeUserData]
  )

  const [members] = useGetChatMembers(chat?.chatId)

  const isOnline = useMemo(
    () =>
      members?.filter((member) => member?._id === chat?.oppositeUserId)?.[0]
        ?.isOnline,
    [chat, members]
  )
  const lastActiveTime = useMemo(
    () =>
      members?.filter((member) => member?._id === chat?.oppositeUserId)?.[0]
        ?.lastActiveTime,
    [chat, members]
  )

  const isTyping = useMemo(
    () =>
      members?.filter((member) => member?._id === chat?.oppositeUserId)?.[0]
        ?.isTyping,
    [chat?.oppositeUserId, members]
  )

  const status = isTyping
    ? `${t('typing')} ...`
    : isOnline
    ? t('online')
    : moment(lastActiveTime?.toDate()).locale(language).fromNow()

  const { pagePath } = useGetUserProfilePaths({ user: oppositeUserData })
  const navigateTo = (path) => () => navigate(path)

  if (withUser) {
    inner = (
      <Row
        gutter={16}
        onClick={navigateTo(pagePath)}
        className="cursor-pointer">
        {!lg && withBackButton && (
          <div className="flex align-center mx-8">
            <Icon
              cursor="pointer"
              size={20}
              name="ArrowLongLeftFilled"
              onClick={() => setOppositeUserId(null)}
            />
          </div>
        )}
        <Col flex="none">
          <Avatar
            src={oppositeUserData?.avatarUrl}
            size="large"
            icon={
              <Icon
                name="PatientFilled"
                size={18}
                justifyContent="center"
                alignItems="center"
                height="inherit"
              />
            }
          />
        </Col>
        <Col flex="none">
          <Box display="flex" flex-direction="column" alignItems="flex-start">
            <Tooltip title={displayName}>
              <Typography.Text fontWeight={600} ellipsis>
                {displayName}
              </Typography.Text>
            </Tooltip>
          </Box>
          <Box display="flex" flexDirection="column" position="relative">
            {isOnline && chat?.isOppositeUserTyping && (
              <Box display="flex" height={24}>
                <AnimatedDots colorPrimary={colorPrimary} />
                <Typography.Text
                  style={{ color: colorPrimary }}
                  variant="caption1"
                  fontWeight={500}
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                  overflow="hidden">
                  {t('typing')}
                </Typography.Text>
              </Box>
            )}

            {isOnline && !chat?.isOppositeUserTyping && (
              <Box height={24}>
                <Badge
                  color={colorPrimary}
                  text={
                    <Typography.Text
                      type="secondary"
                      variant="caption1"
                      fontWeight={400}>
                      {t('Online')}
                    </Typography.Text>
                  }
                />
              </Box>
            )}
            {!isOnline && (
              <Typography.Text
                type="secondary"
                variant="caption1"
                fontWeight={400}>
                {status}
              </Typography.Text>
            )}
          </Box>
        </Col>
      </Row>
    )
  }

  if (!withWrapper) {
    return inner
  }

  return (
    <StyledBox>
      {inner}
      <Icon
        onClick={onIconClick}
        name={rightIconName || 'CloseFilled'}
        {...styles.rightIconStyles}
        {...iconProps}
      />
    </StyledBox>
  )
}

ChatHeader.propTypes = {
  withSettings: PropTypes.bool,
  withWrapper: PropTypes.bool,
  onSettingsClick: PropTypes.func,
  onBackClick: PropTypes.func,
  isSettingsVisible: PropTypes.bool,
  onIconClick: PropTypes.func,
  iconProps: PropTypes.object,
  rightIconName: PropTypes.string,
  withUser: PropTypes.bool,
  withBackButton: PropTypes.bool,
  setOppositeUserId: PropTypes.func
}

export default ChatHeader
