import { Button, Tooltip } from 'antd'

import { Icon } from '@qonsoll/icons'
import PropTypes from 'prop-types'
import { SegmentedSelect } from 'components'
import { useAccountMenuActions } from '../AccountMenu/hooks'
import { useTranslations } from 'hooks'
import { useUser } from 'modules/session-module/contexts'

const ModeSelect = (props) => {
  const { collapsed } = props
  const { t } = useTranslations()
  const { isRentee } = useUser()
  const { toggleRentee } = useAccountMenuActions()

  const modeOptions = [
    {
      label: (
        <div className="flex align-center justify-center">
          <Icon name="StoreOutlined" size={16} mr={2} />
          {t('Rentee')}
        </div>
      ),
      value: 'STORE',
      onClick: toggleRentee
    },
    {
      label: (
        <div className="flex align-center justify-center">
          <Icon name="Bank1Outlined" size={16} mr={2} />
          {t('Renter')}
        </div>
      ),
      value: 'BUSINESS',
      onClick: toggleRentee
    }
  ]

  return (
    <div className="full-width flex justify-center">
      {!collapsed ? (
        <SegmentedSelect
          value={isRentee ? 'STORE' : 'BUSINESS'}
          onChange={toggleRentee}
          segmented
          options={modeOptions}
          fullWidth={true}
        />
      ) : (
        <Tooltip title={isRentee ? t('Rentee') : t('Renter')} placement="right">
          <Button
            onClick={toggleRentee}
            className="flex align-center justify-center"
            icon={
              <Icon
                name={isRentee ? 'CoinsHandOutlined' : 'ShoppingBag2Outlined'}
                size={16}
              />
            }
          />
        </Tooltip>
      )}
    </div>
  )
}

ModeSelect.propTypes = {
  collapsed: PropTypes.bool
}

export default ModeSelect
