const STATISTIC_RANGES = {
  JAN: 'JAN',
  FEB: 'FEB',
  MAR: 'MAR',
  APR: 'APR',
  MAY: 'MAY',
  JUN: 'JUN',
  JUL: 'JUL',
  AUG: 'AUG',
  SEP: 'SEP',
  OCT: 'OCT',
  NOV: 'NOV',
  DEC: 'DEC'
}

const STATISTIC_RANGES_LABELS = {
  JAN: 'Jan',
  FEB: 'Feb',
  MAR: 'Mar',
  APR: 'Apr',
  MAY: 'May',
  JUN: 'Jun',
  JUL: 'Jul',
  AUG: 'Aug',
  SEP: 'Sep',
  OCT: 'Oct',
  NOV: 'Nov',
  DEC: 'Dec'
}

const STATISTIC_RANGES_OPTIONS = Object.keys(STATISTIC_RANGES_LABELS).map(
  (key, index) => ({
    label: STATISTIC_RANGES_LABELS[key],
    value: key,
    monthNumber: index + 1
  })
)

export default STATISTIC_RANGES
export { STATISTIC_RANGES_LABELS, STATISTIC_RANGES, STATISTIC_RANGES_OPTIONS }
