import { useCallback, useEffect, useState } from 'react'

let timeOutId = 0

const useTimerByDate = (storageName) => {
  const [countdownTime, setCountdownTime] = useState(0)
  const [timerData, setTimerData] = useState({})

  const [isOpen, setIsOpen] = useState(false)
  const [timeLeft, setTimeLeft] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0
  })

  const computeCountdownTime = useCallback(
    (expectedTime, data) => {
      const computedTimerData = data || timerData

      const hours = computedTimerData?.hours || 0
      const minutes = computedTimerData?.minutes || 0
      const seconds = computedTimerData?.seconds || 0

      expectedTime += hours > 0 ? hours * 3600000 : 0
      expectedTime += minutes > 0 ? minutes * 60000 : 0
      expectedTime += seconds > 0 ? seconds * 1000 : 0

      return expectedTime
    },
    [timerData]
  )

  const computeTimeDiff = useCallback((expectedTime, time) => {
    let difference = expectedTime - time
    return {
      hours:
        difference > 0 ? Math.floor((difference / (1000 * 60 * 60)) % 24) : 0,
      minutes: difference > 0 ? Math.floor((difference / 1000 / 60) % 60) : 0,
      seconds: difference > 0 ? Math.floor((difference / 1000) % 60) : 0
    }
  }, [])

  const calculateTimeLeft = useCallback(() => {
    const currDate = new Date().getTime()
    let timeDiff = computeTimeDiff(countdownTime, currDate)

    timeDiff.hours =
      timeDiff.hours < 10 ? `0${timeDiff.hours}` : `${timeDiff.hours}`
    timeDiff.minutes =
      timeDiff.minutes < 10 ? `0${timeDiff.minutes}` : `${timeDiff.minutes}`
    timeDiff.seconds =
      timeDiff.seconds < 10 ? `0${timeDiff.seconds}` : `${timeDiff.seconds}`

    return timeDiff
  }, [countdownTime, computeTimeDiff])

  const computeInitialTimerData = useCallback(() => {
    const { time, leftSeconds } =
      JSON.parse(localStorage.getItem(storageName)) || {}

    const currentDate = new Date().getTime()
    const lastSendedTime = time ? new Date(time).getTime() : null

    const expectedTime = computeCountdownTime(lastSendedTime, leftSeconds)
    const timeDiff = computeTimeDiff(expectedTime, currentDate)

    const withCountdownTime =
      timeDiff.hours || timeDiff.minutes || timeDiff.seconds

    if (withCountdownTime) {
      setTimerData(timeDiff)
      setIsOpen(true)
    } else {
      localStorage.removeItem('lastSendedTime')
      return null
    }
  }, [computeCountdownTime, computeTimeDiff, storageName])

  useEffect(() => {
    let expectedTime = new Date().getTime()
    setCountdownTime(() => computeCountdownTime(expectedTime))
    return () => {
      expectedTime = 0
    }
  }, [computeCountdownTime])

  useEffect(() => {
    if (countdownTime > new Date().getTime()) {
      timeOutId = setTimeout(() => {
        setTimeLeft(calculateTimeLeft())
      }, 1000)
    }

    return () => {
      clearTimeout(timeOutId)
    }
  })

  useEffect(() => {
    computeInitialTimerData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return [timeLeft, setTimerData, isOpen, setIsOpen]
}

export default useTimerByDate
