import { Breadcrumbs, PageActions, PageLayout, Spinner } from 'components'
import { Col, Row } from 'antd'
import { useGetTickets, useTicketBaseActions } from 'domains/Ticket/hooks'

import { PATHS } from '__constants__'
import PropTypes from 'prop-types'
import { TicketList } from 'domains/Ticket/components'
import { useTranslations } from 'hooks'

const TicketsAllPage = (props) => {
  const { isListWithCreate, withHeader = true } = props

  /* Getting translations instance */
  const { t } = useTranslations()

  /* Fetching tickets */
  const [tickets, loading] = useGetTickets()
  /* Getting base actions */
  const listActions = useTicketBaseActions()

  /* Configuring page header */
  const headingProps = {
    title: t('Tickets'),
    textAlign: 'left'
  }

  /* Configuring page actions */
  const actions = {
    create: Boolean(
      PATHS.AUTHENTICATED.TICKET_CREATE || PATHS.AUTHENTICATED.TICKET_WIZARD
    )
  }

  /* Extracting base actions */
  const { onBack, onCreate } = listActions

  return (
    <PageLayout
      actions={
        withHeader ? (
          <PageActions
            onCreate={onCreate}
            actions={actions}
            createText={t('Create ticket')}
          />
        ) : null
      }
      onBack={withHeader ? onBack : null}
      breadcrumbs={withHeader ? <Breadcrumbs /> : null}
      headingProps={withHeader ? headingProps : null}
      area="tickets">
      <Row gutter={[24, 16]}>
        {loading ? (
          <Col flex="auto">
            <Spinner height="70vh" text={t('Ticket loading')} />
          </Col>
        ) : (
          <Col flex="auto">
            <TicketList
              actions={{ onCreate }}
              isListWithCreate={isListWithCreate}
              tickets={tickets}
            />
          </Col>
        )}
      </Row>
    </PageLayout>
  )
}

TicketsAllPage.propTypes = {
  isListWithCreate: PropTypes.bool,
  withHeader: PropTypes.bool
}

export default TicketsAllPage
