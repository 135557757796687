import { capitalize } from 'helpers'
const REFUND_TYPES = {
  BASIC: 'BASIC'
}

const REFUND_TYPES_LABELS = {
  BASIC: 'Basic'
}

const REFUND_TYPES_OPTIONS = Object.keys(REFUND_TYPES_LABELS).map((key) => ({
  label: capitalize(REFUND_TYPES[key]),
  value: key
}))

const REFUND_TYPES_DESCRIPTION = {
  [REFUND_TYPES.BASIC]:
    'In case of a refund, the refunded amount will be reduced by bank commission fees for money transfers'
}
export default REFUND_TYPES
export {
  REFUND_TYPES_OPTIONS,
  REFUND_TYPES,
  REFUND_TYPES_LABELS,
  REFUND_TYPES_DESCRIPTION
}
