import {
  getId,
  saveHasManyRelationship,
  updateParent
} from 'services/api/firebase'
import { useLocation, useNavigate } from 'react-router-dom'
import { useMutate, useTranslations } from 'hooks'

import { COLLECTIONS } from '__constants__'
import { message } from 'antd'
import { useState } from 'react'

const useActionsOrderAdvancedForm = ({ initialData, form } = {}) => {
  /* State for loading */
  const [loading, setLoading] = useState(false)
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting navigate instance */
  const navigate = useNavigate()
  /* Getting location state */
  const navigationState = useLocation().state
  /* Getting mutate function */
  const { create, update } = useMutate()

  /* Function for preparing values for saving */
  const prepareValues = async (values = {}, additionalValues = {}) => {
    /* Getting id */
    const orderId = initialData?.['order']?._id || getId(COLLECTIONS.ORDERS)

    const parentReferences = { orderId }

    /* Preparing order values */
    const preparedValues = {
      _id: orderId,
      ...additionalValues,
      status: values['order']?.['status'] ?? null,
      complaints:
        (await saveHasManyRelationship(
          COLLECTIONS.COMPLAINTS,
          values['complaints'],
          parentReferences
        )) ?? [],
      rentingRequestId: values['rentingRequestId']?._id ?? null,
      productId: values['productId']?._id ?? null,
      renterUserId: values['renterUserId']?._id ?? null,
      renteeUserId: values['renteeUserId']?._id ?? null,
      payments: values['payments'] ?? []
    }
    return preparedValues
  }

  /* Saving form data */
  const saveForm = async (values, callback) => {
    try {
      // Prepare data to be saved
      const data = await prepareValues(values)

      if (navigationState?.parent)
        await updateParent(navigationState.parent, data._id)

      // Save data
      if (initialData) {
        await update(COLLECTIONS.ORDERS, initialData.order?._id, data)
        message.success(t('Order successfully updated'))
      } else {
        await create(COLLECTIONS.ORDERS, data, data._id)
        message.success(t('Order successfully created'))
      }
      // Final callback
      callback?.()
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      throw new Error(t('Something went wrong during data save'))
    }
  }

  /* On finish callback */
  const onFinish = async () => {
    if (loading) return // Avoid multiple calls

    try {
      setLoading(true)
      // Get form values
      const formValues = form.getFieldsValue()
      // Save data
      await saveForm(formValues)
      // Final callback
      navigate(-1)
    } catch (error) {
      setLoading(false)
      message.error(error.message)
    }
  }

  /* On cancel callback */
  const onReset = () => {
    form.resetFields()
    navigate(-1)
  }

  return { onFinish, onReset, loading, saveForm, prepareValues }
}

export default useActionsOrderAdvancedForm
