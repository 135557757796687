import { Col, Row, Typography } from 'antd'

import PropTypes from 'prop-types'
import {
  StatisticSimpleView,
  StatisticInfoModal
} from 'domains/Statistic/components'
import { useGetTotalStatistics } from 'domains/Statistic/hooks'
import { useTranslations } from 'hooks'
import { useUser } from 'modules/session-module/contexts'
import { useState } from 'react'

const StatisticList = (props) => {
  const { gutter } = props

  const { user } = useUser()
  const config = useGetTotalStatistics(user?._id)
  const { t } = useTranslations()

  const [isModalOpen, setIsModalOpen] = useState()
  const [statisticsKey, setStatisticsKey] = useState()

  return (
    <>
      {config?.length ? (
        <Row gutter={gutter || [16, 16]}>
          {config.map((item, index) => (
            <Col
              key={`${index}-statistic-simple-view`}
              xs={24}
              sm={index === config?.length - 1 ? 24 : 12}
              md={8}
              className="flex justify-center align-center">
              <StatisticSimpleView
                {...item}
                setStatisticsKey={setStatisticsKey}
                setIsModalOpen={setIsModalOpen}
              />
            </Col>
          ))}
        </Row>
      ) : (
        <Typography.Text type="secondary">
          {t('No statistic data is available for now')}
        </Typography.Text>
      )}
      <StatisticInfoModal
        statisticsKey={statisticsKey}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </>
  )
}

StatisticList.propTypes = {
  span: PropTypes.number,
  gutter: PropTypes.number
}

export default StatisticList
