import { ENDPOINTS } from '__constants__/environment'

const API_URL = process.env.REACT_APP_FIREBASE_FUNCTIONS_API_URL

const removeUser = async ({ userId, accountId }) => {
  const data = {
    userId: userId,
    accountId: accountId
  }

  try {
    const apiEndpoint = `${API_URL}/${ENDPOINTS.DELETE_USER}`
    const request = await fetch(apiEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })

    const result = await request.json()

    return result
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
  }
}

export default removeUser
