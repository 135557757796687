import { DELIVERY_TYPES } from '__constants__'
import { Form } from 'antd'
import { useEffect } from 'react'
import { useUser } from 'modules/session-module/contexts'

const useInitializeCheckoutAdvancedForm = (initialData, cartItem, loading) => {
  const { user } = useUser()

  /* Getting form instance */
  const [form] = Form.useForm()
  /* Setting initial data */
  useEffect(() => {
    if (initialData && cartItem && !loading) {
      /* Getting initial values */
      const { address } = initialData

      /* Formatting date fields */
      const formValues = {
        checkout: {
          firstName: user?.firstName ?? null,
          lastName: user?.lastName ?? null,
          email: user?.email ?? null,
          phone: user?.phone ?? null
        },
        address: {
          _id: address?._id ?? null,
          latitude: address?.latitude ?? null,
          longitude: address?.longitude ?? null,
          addressData: address
        },
        dates: {
          startDate: cartItem?.startDate?.toDate(),
          endDate: cartItem?.endDate?.toDate()
        },
        paymentMethod: {}
      }

      /* Setting initial values to form instance */
      form.setFieldsValue(formValues)
    } else {
      const formValues = {
        address: {
          deliveryType: DELIVERY_TYPES.SELF_PICK_UP
        }
      }
      form.setFieldsValue(formValues)
    }
  }, [
    loading,
    initialData,
    form,
    user?.firstName,
    user?.lastName,
    user?.email,
    user?._id,
    user?.phone,
    cartItem?.startDate,
    cartItem?.endDate,
    cartItem
  ])

  return { form }
}

export default useInitializeCheckoutAdvancedForm
