import { PageLayout, LoadingBox } from 'components'
import { Col, Row } from 'antd'
import { useTranslations } from 'hooks'

import { useCart } from 'contexts'
import { CartList } from 'domains/Cart/components'

const Cart = () => {
  const { t } = useTranslations()
  const { cartItems, loading } = useCart()

  const headingProps = {
    title: t('Cart'),
    textAlign: 'left'
  }

  return (
    <PageLayout headingProps={headingProps} area="categories">
      <Row gutter={[24, 16]}>
        <Col flex="auto">
          <LoadingBox loading={loading}>
            <CartList carts={cartItems} />
          </LoadingBox>
        </Col>
      </Row>
    </PageLayout>
  )
}

export default Cart
