import { Form, Input } from 'antd'
import {
  useActionsAddressAdvancedForm,
  useInitializeAddressAdvancedForm
} from './hooks'
import { useCheckFormChange, useTranslations } from 'hooks'

import { AddressIconSelect } from 'domains/Address/components'
import { FormWrapper } from 'components'
import { KEYS } from '__constants__'
import { LocationForm } from 'domains/GoogleMaps/components'
import PropTypes from 'prop-types'

const AddressAdvancedForm = (props) => {
  const { initialData, group, showTitle, ...rest } = props

  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting form instance from hook */
  const { form, initialValues } = useInitializeAddressAdvancedForm(initialData)
  const [handleChangeValues, isEqual] = useCheckFormChange(initialValues)

  /* Handling enter key press */
  const handleKeyPress = (e) => {
    if (e.key === KEYS.ENTER) {
      e.preventDefault()
      onFinish()
    }
  }

  /* Getting loading state and actions from hook */
  const { loading, onFinish, onReset } = useActionsAddressAdvancedForm({
    initialData,
    form
  })

  /* Generating form field names */
  const getFieldName = (subGroup) => (group ? [...group, subGroup] : [subGroup])

  return (
    <FormWrapper
      onKeyPress={handleKeyPress}
      onFinish={onFinish}
      form={form}
      showTitle={showTitle}
      onReset={onReset}
      submitLoading={loading}
      submitText={t('Save address')}
      onValuesChange={handleChangeValues}
      submitDisabled={isEqual}
      group={group}
      title={t('Address')}
      {...rest}>
      <Form.Item
        rules={[{ required: true, message: t('Please enter location name') }]}
        name={getFieldName('icon')}
        label={t('Icon')}
        tooltip={t('Select the icon to differentiate locations')}>
        <AddressIconSelect />
      </Form.Item>
      <Form.Item
        rules={[{ required: true, message: t('Please enter location name') }]}
        name={getFieldName('name')}
        label={t('Name')}
        tooltip={t('Write the location name you want to add')}>
        <Input placeholder={t('Please enter address label')} />
      </Form.Item>
      <div className="mb-24">
        <LocationForm
          group={getFieldName('address')}
          form={form}
          initialValues={initialData?.address}
        />
      </div>
    </FormWrapper>
  )
}

AddressAdvancedForm.propTypes = {
  initialData: PropTypes.object,
  group: PropTypes.array,
  showTitle: PropTypes.bool
}

export default AddressAdvancedForm
