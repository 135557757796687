import { Space, Typography } from 'antd'

import { useTranslations } from 'hooks'

const ProceedWithoutAuthBtn = () => {
  const { t } = useTranslations()

  const linkToNextApp = process.env.REACT_APP_NEXT_APP_URL

  return (
    <Space align="center" className="mt-8 flex justify-center">
      <Typography.Text type="secondary">{`${t(
        'Want to proceed without login or sign up'
      )}?`}</Typography.Text>
      <Typography.Link href={linkToNextApp} target="_self">
        {t('Proceed as quest')}
      </Typography.Link>
    </Space>
  )
}

export default ProceedWithoutAuthBtn
