import { useEffect } from 'react'
import { Form } from 'antd'

const useInitializeOrderAdvancedForm = (initialData) => {
  /* Getting form instance */
  const [form] = Form.useForm()

  /* Setting initial data */
  useEffect(() => {
    if (initialData) {
      /* Getting initial values */
      const {
        order,
        complaints,
        rentingRequestId,
        productId,
        renterUserId,
        renteeUserId,
        payments
      } = initialData

      /* Formatting date fields */
      const formValues = {
        order: {
          status: order?.['status'] ?? null
        },
        complaints: complaints ?? null,
        rentingRequestId: rentingRequestId ?? null,
        productId: productId ?? null,
        renterUserId: renterUserId ?? null,
        renteeUserId: renteeUserId ?? null,
        payments: payments ?? null
      }

      /* Setting initial values to form instance */
      form.setFieldsValue(formValues)
    }
  }, [initialData, form])

  return { form }
}

export default useInitializeOrderAdvancedForm
