import { SelectableCard } from 'components/forms'
import styled from 'styled-components'

const StyledSelectableCard = styled(SelectableCard)`
  display: flex;
  align-items: center;
  justify-content: center;

  .ant-card-body {
    padding: 24px !important;
  }
  path {
    stroke: ${({ isSelected, theme }) =>
      isSelected && theme.colorPrimaryActive};
  }
`

export default StyledSelectableCard
