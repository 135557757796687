import styled from 'styled-components'
import { FormWrapper } from 'components'

const FormWrapperStyled = styled(FormWrapper)`
  .ant-form-item {
    margin-bottom: 12px;
  }
`

export default FormWrapperStyled
