import { Col, Row, Tooltip, Typography, theme } from 'antd'
import { REFUND_FAILURE_REASONS, REFUND_STATUSES } from '__constants__'

import { Icon } from '@qonsoll/icons'
import PropTypes from 'prop-types'
import RefundStatusDot from './RefundStatus.styled'
import { capitalize } from 'helpers'
import { useTranslations } from 'hooks'

const RefundStatus = (props) => {
  const { refund } = props

  const { t } = useTranslations()

  const status = capitalize(refund?.status)
  const { colorSuccess, colorError, colorTextDisabled, colorTextTertiary } =
    theme.useToken().token

  const statusColors = {
    [REFUND_STATUSES.FAILED]: colorError,
    [REFUND_STATUSES.SUCCEEDED]: colorSuccess,
    [REFUND_STATUSES.PENDING]: colorTextDisabled
  }

  const isFailedStatus = refund?.status === REFUND_STATUSES.FAILED

  const tooltipText = isFailedStatus
    ? t(
        REFUND_FAILURE_REASONS?.[refund?.failure_reason] ||
          REFUND_FAILURE_REASONS.unknown
      )
    : t(
        'Rentee sees the refund as a credit approximately 5-10 business days later'
      )

  const statusColor = statusColors?.[refund?.status]

  return (
    <Row className="align-center gap-8">
      <Col>
        <RefundStatusDot statusColor={statusColor} />
      </Col>
      <Col>
        <Typography.Text>{status}</Typography.Text>
      </Col>
      {isFailedStatus && (
        <Col>
          <Tooltip title={tooltipText}>
            <Icon name="AlertInfoFilled" fill={colorTextTertiary} />
          </Tooltip>
        </Col>
      )}
    </Row>
  )
}
RefundStatus.propTypes = {
  refund: PropTypes.object
}

export default RefundStatus
