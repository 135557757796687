import { COLLECTIONS } from '__constants__'
import { collection } from 'firebase/firestore'
import { firestore } from 'services/firebase'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { useMemo } from 'react'

const useGetChatMembers = (chatId) => {
  const querySnapshot = useMemo(
    () =>
      chatId
        ? collection(
            firestore,
            `${COLLECTIONS.CHATS}/${chatId}/${COLLECTIONS.MEMBERS}`
          )
        : null,
    [chatId]
  )

  return useCollectionData(querySnapshot)
}

export default useGetChatMembers
