import { Typography, theme } from 'antd'
import {
  useCalculateDistanceAndAddress,
  useGetProductAddressData
} from 'domains/Product/hooks'

import PropTypes from 'prop-types'
import { memo } from 'react'

const ProductLocationDetails = ({ product, onlyAddress }) => {
  const { colorPrimary } = theme.useToken().token

  const [address] = useGetProductAddressData(product)
  const [productAddress, productDistance] = useCalculateDistanceAndAddress(
    product,
    address,
    onlyAddress
  )
  return (
    <div className="flex mt-auto">
      <Typography.Paragraph
        className="mb-0"
        style={{ fontSize: 14 }}
        fontSize={1}
        type="secondary"
        ellipsis={{ rows: 2 }}>
        {productDistance && (
          <Typography.Paragraph
            className="mb-0"
            style={{ fontSize: 14, display: 'inline', color: colorPrimary }}
            fontSize={1}
            type="secondary">
            {productDistance}
          </Typography.Paragraph>
        )}
        {productAddress}
      </Typography.Paragraph>
    </div>
  )
}

ProductLocationDetails.propTypes = {
  product: PropTypes.object,
  onlyAddress: PropTypes.bool
}

export default memo(ProductLocationDetails)
