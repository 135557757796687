import { useEffect, useState } from 'react'
import { useGDPRStatus, useSessionActions } from '../../hooks'
import { useHandleError, useTranslations } from 'hooks'

import { COLLECTIONS } from '../../__constants__'
import firebase from 'firebase/compat/app'
import { onAuthStateChanged } from 'firebase/auth'
import { useAppNotifications } from 'contexts/AppNotificationsContext'

const useInitializeUser = () => {
  /* If the user is logged in, fetch the user's data from Firestore. */
  const [userId, setUserId] = useState(null)
  const [token, setToken] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const { t } = useTranslations()
  const { sendErrorNotification } = useAppNotifications()

  const logout = async () => {
    setToken(null)
    setUserId(null)

    await firebase.auth().signOut()
  }

  /* The above code is a function that takes in a callback function as an argument.
  The callback function is then called with the error as an argument. */
  const handleError = useHandleError()

  /* Using the useGDPRStatus hook to get the GDPR status of the user. */
  const gdpr = useGDPRStatus()

  // Session methods
  const { saveUserToDB } = useSessionActions()

  const checkUserAccess = (currentAuth, callback) => {
    currentAuth
      .getIdTokenResult(true)
      .then(({ token, claims }) => {
        if (!claims || claims?.isBlocked) {
          sendErrorNotification({
            message: `${t('You cannot be authenticated')}.`,
            description: `${t('Your account is blocked')}.`,
            key: 'checkCustomClaimError'
          })
          return logout?.()
        } else {
          callback?.()
          setToken(token)
        }
      })
      .catch((error) => {
        logout?.()
      })
  }

  useEffect(() => {
    const unsubscribeAuthListener = onAuthStateChanged(
      firebase.auth(),
      async (currentAuth) => {
        try {
          if (currentAuth) {
            const { uid, email, photoURL, displayName } = currentAuth
            const snapshot = await firebase
              .firestore()
              .collection(COLLECTIONS.USERS)
              .doc(currentAuth.uid)
              .get()

            if (!snapshot?.data()) {
              await saveUserToDB({
                gdpr,
                id: uid,
                email: email,
                avatarUrl: photoURL,
                agreement: true,
                onError: handleError,
                additionalData: { displayName }
              })
            }

            checkUserAccess(currentAuth, () => {
              setUserId(uid)
              setLoading(false)
            })
          } else {
            setLoading(false)
            logout()
          }
        } catch (error) {
          setError(error)
          setUserId(null)
          setLoading(false)
        }
      }
    )

    return () => {
      // Clean-up of listeners
      unsubscribeAuthListener?.()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { userId, loading, error, token, logout }
}

export default useInitializeUser
