import { PAYMENT_STATUSES_LABELS } from '__constants__/enums'
import { PaymentStatusDotView } from './components'
import PropTypes from 'prop-types'
import { Typography } from 'antd'

const OrderPaymentStatus = (props) => {
  const { paymentStatus } = props

  return (
    <div className="flex align-center">
      <PaymentStatusDotView paymentStatus={paymentStatus} className="mr-8" />
      <Typography.Text>
        {PAYMENT_STATUSES_LABELS?.[paymentStatus]}
      </Typography.Text>
    </div>
  )
}

OrderPaymentStatus.propTypes = {
  paymentStatus: PropTypes.string
}

export default OrderPaymentStatus
