import { useMemo, useState } from 'react'

import { ConfigProvider } from 'antd'
import PropTypes from 'prop-types'
import ThemeContext from './ThemeContext'
import appTheme from 'styles/theme'
import { useStateWithStorage } from 'hooks'

const ThemeProvider = ({ children }) => {
  const [theme] = useState(appTheme)
  const [isDarkMode, setIsDarkMode] = useStateWithStorage(false, 'isDarkMode')
  const [isThemeEdit, setIsThemeEdit] = useState(false)

  const currentTheme = useMemo(
    () => (isDarkMode ? theme : theme),
    [isDarkMode, theme]
  )

  const values = useMemo(
    () => ({
      isDarkMode,
      setIsDarkMode,
      isThemeEdit,
      setIsThemeEdit,
      theme: currentTheme
    }),
    [currentTheme, isDarkMode, isThemeEdit, setIsDarkMode, setIsThemeEdit]
  )

  return (
    <ConfigProvider theme={currentTheme}>
      <ThemeContext.Provider value={values}>{children}</ThemeContext.Provider>
    </ConfigProvider>
  )
}

ThemeProvider.propTypes = { children: PropTypes.node }

export default ThemeProvider
