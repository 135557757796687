import { Table } from 'antd'
import { useGetPopularProductsConfig } from 'domains/Statistic/hooks/configs'
import { useGetPopularRentedProducts } from 'domains/Statistic/hooks/get'
import { LoadingBox } from 'components'

const PopularProductsTable = () => {
  const [popularProducts, loading] = useGetPopularRentedProducts()

  const { columns, paginationProps, tableProps } =
    useGetPopularProductsConfig(popularProducts)

  return (
    <LoadingBox loading={loading}>
      <Table
        loading={loading}
        dataSource={popularProducts}
        columns={columns}
        pagination={paginationProps}
        {...tableProps}
      />
    </LoadingBox>
  )
}

export default PopularProductsTable
