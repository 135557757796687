import { Drawer } from 'antd'
import PropTypes from 'prop-types'
import { SearchHeader } from '../../components'

const SearchHeaderDrawer = (props) => {
  const { isDrawerOpened, setShowSearchHeaderDrawer } = props

  return (
    <Drawer
      rootClassName="search-header-drawer"
      placement="top"
      open={isDrawerOpened}>
      <SearchHeader onSelect={setShowSearchHeaderDrawer} />
    </Drawer>
  )
}
SearchHeaderDrawer.propTypes = {
  isDrawerOpened: PropTypes.bool,
  setShowSearchHeaderDrawer: PropTypes.func
}
export default SearchHeaderDrawer
