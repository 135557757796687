import PropTypes from 'prop-types'
import RenteeProductSimpleView from './RenteeProductSimpleView'
import RenterProductSimpleView from './RenterProductSimpleView'
import { memo } from 'react'
import { useGetCategory } from 'domains/Category/hooks'
import { useTranslations } from 'hooks'

const ProductSimpleView = (props) => {
  const { isRentee, product, onlyView } = props
  const { language } = useTranslations()
  const [category] = useGetCategory(product?.categoryId)

  return isRentee ? (
    <RenteeProductSimpleView
      {...props}
      category={category?.names?.[language.toUpperCase()] || category?.name}
      onlyView={onlyView}
    />
  ) : (
    <RenterProductSimpleView
      {...props}
      category={category?.names?.[language.toUpperCase()] || category?.name}
      onlyView={onlyView}
    />
  )
}

ProductSimpleView.propTypes = {
  product: PropTypes.object,
  isRentee: PropTypes.bool,
  onlyView: PropTypes.bool
}

export default memo(ProductSimpleView)
