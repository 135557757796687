import { STATISTIC_RANGES } from '__constants__'
import { StatisticChart } from 'domains/Statistic/components'
import moment from 'moment'
import { useGetRentFiguresStats } from 'domains/Statistic/hooks/get'
import { useState } from 'react'
import { useTranslations } from 'hooks'
const RentFiguresChart = () => {
  const currMonth = moment().format('MMM')
  const [range, setRange] = useState(
    STATISTIC_RANGES[`${currMonth.toUpperCase()}`]
  )
  const { t } = useTranslations()
  const onRangeChange = (value) => setRange(value)

  const [data] = useGetRentFiguresStats(range)

  const chartDescription = `${t(
    'The rent figures chart displays the total rental costs for each day of the selected month'
  )}.`
  return (
    <StatisticChart
      data={data}
      onRangeChange={onRangeChange}
      range={range}
      description={chartDescription}
    />
  )
}

export default RentFiguresChart
