import { Col, Row } from 'antd'
import { useEffect, useState } from 'react'

import { ChatButton } from 'modules/chat-module/domains/Chat/components'
import PropTypes from 'prop-types'
import { checkMatchingChats } from 'modules/chat-module/domains/Chat/helpers'
import { useListenUserChats } from 'modules/chat-module/domains/Chat/hooks'
import { useTranslations } from 'hooks'
import { useUser } from 'modules/session-module/contexts'

const UserTableActions = (props) => {
  const { user } = props
  const { t } = useTranslations()

  const { user: userProvider } = useUser()

  const [chats] = useListenUserChats(userProvider?._id)
  const [oppositeUserChats] = useListenUserChats(user?._id)

  const [isChatExist, setIsChatExist] = useState(
    checkMatchingChats({
      oppositeUsersChats: oppositeUserChats,
      userId: userProvider?._id,
      userChats: chats
    })
  )

  useEffect(() => {
    const chatExists = checkMatchingChats({
      oppositeUsersChats: oppositeUserChats,
      userId: userProvider?._id,
      userChats: chats
    })
    setIsChatExist(chatExists)
  }, [chats, oppositeUserChats, userProvider?._id])

  return (
    <Row align="middle" justify="center">
      {/* Temporary useless */}
      <Col flex="none">
        <ChatButton
          isChatExist={isChatExist}
          chats={chats}
          oppositeUserId={user?._id}
          btnText={t('Contact')}
          className="flex align-center justify-center"
        />
      </Col>
    </Row>
  )
}

UserTableActions.propTypes = {
  user: PropTypes.object.isRequired
}

export default UserTableActions
