import { Button, Col } from 'antd'
import {
  CategoryModalCollapse,
  CategoryModalTree
} from 'domains/Category/components/CategoryTree/components'
import {
  StyledModal,
  StyledTriggerWrapper,
  styles
} from './CategoryTree.styled.js'
import { generatePath, useNavigate } from 'react-router-dom'
import { useCallback, useLayoutEffect, useMemo, useState } from 'react'
import { useFilterCategories, useGetCategories } from 'domains/Category/hooks'
import { useScreen, useTranslations } from 'hooks'

import { Icon } from '@qonsoll/icons'
import { PATHS } from '__constants__'
import PropTypes from 'prop-types'
import { Spinner } from 'components'

const CategoryTree = (props) => {
  const { collapsed = true } = props
  const { t } = useTranslations()

  const [categories, categoriesLoading] = useGetCategories()
  const [filteredCategories, filteredCategoriesLoading, topLevelCategories] =
    useFilterCategories(categories)
  const computedLoadings = useMemo(
    () => filteredCategoriesLoading || categoriesLoading,
    [filteredCategoriesLoading, categoriesLoading]
  )
  const navigate = useNavigate()

  const [isModalOpen, setIsModalOpen] = useState(false)

  const [selectedCategoryId, setSelectedCategoryId] = useState(null)

  useLayoutEffect(() => {
    if (topLevelCategories?.[0]?._id) {
      setSelectedCategoryId(topLevelCategories?.[0]?._id || null)
    }
  }, [topLevelCategories])

  const showModal = () => {
    setIsModalOpen(true)
  }
  const handleOk = () => {
    setIsModalOpen(false)
  }
  const handleCancel = () => {
    setIsModalOpen(false)
  }
  const { isSmallScreen } = useScreen()
  const handleOpenCategory = useCallback(
    (categoryId) => () => {
      const path = generatePath(PATHS.AUTHENTICATED.PRODUCTS_ALL_BY_CATEGORY, {
        categoryId
      })

      navigate(path)
      setIsModalOpen(false)
    },
    [navigate]
  )
  const modalWidth = isSmallScreen ? '80vw' : '70vw'
  const modalHeight = isSmallScreen ? '80vh' : '60vh'

  return (
    <StyledTriggerWrapper collapsed={collapsed}>
      <Button
        // block
        icon={<Icon {...styles.iconStyles} />}
        onClick={showModal}
        {...styles.buttonStyles}>
        {!collapsed && t('Categories')}
      </Button>
      <StyledModal
        footer={[]}
        width={modalWidth}
        height={modalHeight}
        bodyStyle={styles.bodyStyles}
        closable={false}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        isSmallScreen={isSmallScreen}>
        <div className="full-height flex flex-row flex-nowrap">
          {computedLoadings ? (
            <Col flex="auto" height="inherit" justify="center">
              <Spinner height="50%" />
            </Col>
          ) : (
            <>
              {isSmallScreen ? (
                <CategoryModalCollapse
                  selectedCategoryId={selectedCategoryId}
                  setSelectedCategoryId={setSelectedCategoryId}
                  handleOpenCategory={handleOpenCategory}
                  topLevelCategories={topLevelCategories}
                  categories={filteredCategories}
                  isSmallScreen={isSmallScreen}
                  isModalOpen={isModalOpen}
                />
              ) : (
                <CategoryModalTree
                  selectedCategoryId={selectedCategoryId}
                  setSelectedCategoryId={setSelectedCategoryId}
                  handleOpenCategory={handleOpenCategory}
                  topLevelCategories={topLevelCategories}
                  categories={filteredCategories}
                />
              )}
            </>
          )}
        </div>
      </StyledModal>
    </StyledTriggerWrapper>
  )
}
CategoryTree.propTypes = {
  collapsed: PropTypes.bool
}
export default CategoryTree
