import { theme } from 'antd'
import { StyledText, StyledNumber } from './SubCategorySimpleView.styled'
import { useGetProductsNumber } from 'domains/Category/hooks'

import PropTypes from 'prop-types'
import { useTranslations } from 'hooks'

const SubCategorySimpleView = (props) => {
  const { level, subCategory, handleOpenCategory } = props

  const { colorPrimary } = theme.useToken().token
  const isOdd = level % 2 !== 0
  const strong = isOdd ? 'strong' : ''
  const { language } = useTranslations()
  const productsNumber = useGetProductsNumber(subCategory?._id)

  const computedSubcategory =
    subCategory?.names?.[language.toUpperCase()] || subCategory?.name

  return (
    <div className="mb-12 flex">
      <StyledText
        ellipsis
        colorPrimary={colorPrimary}
        className={strong}
        onClick={handleOpenCategory(subCategory._id)}>
        {computedSubcategory}
      </StyledText>
      <StyledNumber type="secondary">{`(${productsNumber})`}</StyledNumber>
    </div>
  )
}
SubCategorySimpleView.propTypes = {
  subCategory: PropTypes.string,
  level: PropTypes.string,
  handleOpenCategory: PropTypes.func
}

export default SubCategorySimpleView
