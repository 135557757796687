import { PAYMENT_STATUSES } from '__constants__'
import { PaymentStatusDot } from './PaymentStatusDotView.styled'
import PropTypes from 'prop-types'
import { theme } from 'antd'

const PaymentStatusDotView = (props) => {
  const { paymentStatus } = props
  const { colorSuccess, colorError, colorWarning, colorTextDisabled } =
    theme.useToken().token

  const statusColors = {
    [PAYMENT_STATUSES.CANCELED]: colorError,
    [PAYMENT_STATUSES.PAID]: colorSuccess,
    [PAYMENT_STATUSES.HOLD]: colorWarning,
    [PAYMENT_STATUSES.UNPAID]: colorTextDisabled,
    [PAYMENT_STATUSES.REFUND_SUCCEEDED]: colorSuccess,
    [PAYMENT_STATUSES.REFUND_PENDING]: colorTextDisabled,
    [PAYMENT_STATUSES.REFUND_FAILED]: colorError
  }

  const statusColorComputed = statusColors[paymentStatus]

  return (
    statusColorComputed && (
      <PaymentStatusDot {...props} statusColor={statusColorComputed} />
    )
  )
}

PaymentStatusDotView.propTypes = {
  paymentStatus: PropTypes.string
}

export default PaymentStatusDotView
