import {
  ForgotPassword,
  Login,
  Signup,
  TermsAndConditionsOfUse,
  VippsCallback
} from 'modules/session-module/pages/Auth'
import { Navigate, Route, Routes } from 'react-router-dom'

import { AUTH_PATHS } from 'modules/session-module/__constants__'
import firebase from 'firebase/compat/app'
import { useMemo } from 'react'

const { LOGIN, SIGNUP, FORGOT_PASSWORD, TERMS_AND_CONDITIONS, VIPPS_CALLBACK } =
  AUTH_PATHS

const routes = [
  { key: LOGIN, path: LOGIN, element: <Login /> },
  { key: SIGNUP, path: SIGNUP, element: <Signup /> },
  // { key: CONFIRM_EMAIL, path: CONFIRM_EMAIL, element: <ConfirmEmail /> },
  { key: FORGOT_PASSWORD, path: FORGOT_PASSWORD, element: <ForgotPassword /> },
  {
    key: TERMS_AND_CONDITIONS,
    path: TERMS_AND_CONDITIONS,
    element: <TermsAndConditionsOfUse />
  },
  { key: VIPPS_CALLBACK, path: VIPPS_CALLBACK, element: <VippsCallback /> },
  { key: '*', path: '*', element: <Navigate to="/service/404" /> },
  { key: '/', path: '/', element: <Navigate to={LOGIN} /> }
]

const AuthNavigator = () => {
  const isAuthenticated = useMemo(
    () => firebase.auth().currentUser?.emailVerified,
    []
  )

  return isAuthenticated ? null : (
    <Routes>
      {routes.map(({ key, path, element }) => (
        <Route key={key} path={path} element={element} />
      ))}
    </Routes>
  )
}

export default AuthNavigator
