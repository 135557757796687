// Function to calculate distance between two points using Haversine formula
const calculateDistance = (selectedPoint, item_coordinate) => {
  try {
    if (!selectedPoint || !item_coordinate) return

    const selectedPoint_latitude = selectedPoint?.lat
    const selectedPoint_longitude = selectedPoint?.lng
    const item_latitude = item_coordinate[0]
    const item_longitude = item_coordinate[1]

    const earthRadius = 6371 // Radius of the Earth in kilometers
    const deltaLatitude =
      ((item_latitude - selectedPoint_latitude) * Math.PI) / 180
    const deltaLongitude =
      ((item_longitude - selectedPoint_longitude) * Math.PI) / 180
    const haversineFormula =
      Math.sin(deltaLatitude / 2) * Math.sin(deltaLatitude / 2) +
      Math.cos((selectedPoint_latitude * Math.PI) / 180) *
        Math.cos((item_latitude * Math.PI) / 180) *
        Math.sin(deltaLongitude / 2) *
        Math.sin(deltaLongitude / 2)
    const angularDistance =
      2 *
      Math.atan2(Math.sqrt(haversineFormula), Math.sqrt(1 - haversineFormula))
    const distance = (earthRadius * angularDistance).toFixed(1)
    return distance
  } catch (error) {
    console.error(error)
    return null
  }
}

export default calculateDistance
