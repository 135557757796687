import { CheckoutContactInfoSimpleForm } from 'domains/Checkout/components'
import {
  CheckoutProductLocationForm,
  CheckoutRangeSelect
} from 'domains/Checkout/components/CheckoutAdvancedForm/components'
import { Divider, Form } from 'antd'
import { useKeyPress, useTranslations } from 'hooks'

import { FormWrapper } from 'components'
import { KEYS } from '__constants__'
import PropTypes from 'prop-types'
import { useState } from 'react'

const CheckoutAdvancedForm = (props) => {
  const {
    group,
    showTitle,
    form,
    onFinish,
    handleEnterPress,
    children,
    initialValues
  } = props
  /* Getting translations instance */
  const { t } = useTranslations()

  const [selectedStartDate, setSelectedStartDate] = useState()

  useKeyPress(KEYS.ENTER, handleEnterPress)

  /* Generating form field names */
  const getFieldName = (subGroup) => (group ? [...group, subGroup] : [subGroup])

  return (
    <FormWrapper
      onFinish={onFinish}
      preserve={true}
      form={form}
      showTitle={showTitle}
      group={group}
      title={t('Checkout')}
      actions={null}>
      <Form.Item name={getFieldName('dates')}>
        <CheckoutRangeSelect
          product={initialValues?.product}
          setSelectedStartDate={setSelectedStartDate}
        />
      </Form.Item>
      <Divider />
      {children}
      <CheckoutContactInfoSimpleForm
        form={form}
        group={['checkout']}
        title={t('Personal details')}
      />
      <CheckoutProductLocationForm
        selectedStartDate={selectedStartDate}
        disabled
        withAddressSelect={false}
        initialValues={initialValues}
        group={['address']}
        form={form}
        title={t('Pick-up details')}
      />
    </FormWrapper>
  )
}

CheckoutAdvancedForm.propTypes = {
  initialData: PropTypes.object,
  group: PropTypes.array,
  showTitle: PropTypes.bool,
  onFinishCallback: PropTypes.func,
  form: PropTypes.object,
  onFinish: PropTypes.func,
  handleEnterPress: PropTypes.func,
  children: PropTypes.node,
  initialValues: PropTypes.object
}

export default CheckoutAdvancedForm
