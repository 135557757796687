import './CartButton.css'

import { Badge, Popover, theme } from 'antd'

import { CartContent } from 'domains/Cart/components'
import { Icon } from '@qonsoll/icons'
import { MenuButton } from 'components/layouts/AppLayout/components/BottomMenu/BottomMenu.styled'
import PropTypes from 'prop-types'
import { useCart } from 'contexts'

const CartButton = (props) => {
  const { collapsed, disabled } = props
  const { cartItems } = useCart()

  const { colorTextDisabled } = theme.useToken().token

  return (
    <div className="full-width">
      <Popover
        overlayClassName={collapsed ? 'cart-popover-collapsed' : 'cart-popover'}
        showArrow={false}
        trigger="click"
        content={!disabled && <CartContent cartItems={cartItems} />}>
        <MenuButton
          collapsed={collapsed}
          {...props}
          disabled={disabled}
          prefix={
            <Icon
              fill={disabled ? colorTextDisabled : 'currentColor'}
              name="ShoppingCart1Outlined"
            />
          }
          suffix={
            <Badge
              color={disabled && colorTextDisabled}
              count={cartItems?.length}
              className="full-width"
            />
          }
          withBadge={collapsed}
          badgeNumber={cartItems?.length}
        />
      </Popover>
    </div>
  )
}

CartButton.propTypes = {
  collapsed: PropTypes.bool,
  disabled: PropTypes.bool
}
export default CartButton
