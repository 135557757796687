import { useCallback, useMemo } from 'react'

import { Form } from 'antd'

const useValidateProductForm = (form, step) => {
  const name = Form.useWatch(['product', 'name'], form)
  const pricePerDay = Form.useWatch(['product', 'pricePerDay'], form)
  const categoryId = Form.useWatch(['product', 'categoryId'], form)
  const location = Form.useWatch(['address'], form)
  const cancellationTerms = Form.useWatch(
    ['product', 'cancellationTerms'],
    form
  )
  const mediaObjects = Form.useWatch(['product', 'mediaObjects'], form)

  // Check for errors
  const pricePerDayErrors = form.getFieldError([
    'product',
    'pricePerDay'
  ]).length // get array with errors for ['product', 'pricePerDay'] field

  const checkIsFormValid = useCallback(
    () =>
      !!name &&
      !!pricePerDay &&
      !!categoryId &&
      mediaObjects?.length &&
      !!cancellationTerms &&
      !!location?.locationData &&
      !pricePerDayErrors,
    [
      name,
      location,
      cancellationTerms,
      pricePerDay,
      mediaObjects?.length,
      categoryId,
      pricePerDayErrors
    ]
  )

  const isStepValid = useMemo(() => true, [])
  const isFormValid = useMemo(() => checkIsFormValid(), [checkIsFormValid])

  return [isStepValid, isFormValid]
}

export default useValidateProductForm
