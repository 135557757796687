import { Row, Col, Typography, Button, Image } from 'antd'
import { useTranslations } from 'hooks'
import { Box } from 'components'
import IllustrationConfirmEmail from '__assets__/illustrations/confirm-email.svg'
import imageStyles from './CheckoutSuccessAdvancedView.styles'
import PropTypes from 'prop-types'

const { Title, Text } = Typography

const CheckoutSuccessAdvancedView = (props) => {
  const { onClick } = props

  const { t } = useTranslations()

  return (
    <Box className="flex align-center justify-center full-height">
      <Row className="flex-col align-center">
        <Col className="flex justify-center mb-16">
          <Image {...imageStyles} src={IllustrationConfirmEmail} />
        </Col>
        <Col className="mb-4">
          <Title level={2}>{t('Order was created')}</Title>
        </Col>
        <Col>
          <Text>
            {t('Please pay for your order using a method convenient for you')}
          </Text>
        </Col>

        <Col className="mt-24">
          <Button onClick={onClick} type="primary">
            {t('Pay')}
          </Button>
        </Col>
      </Row>
    </Box>
  )
}
CheckoutSuccessAdvancedView.propTypes = {
  onClick: PropTypes.func
}
export default CheckoutSuccessAdvancedView
