import { APP_PATHS } from '__constants__/paths'
import { ENDPOINTS } from '__constants__/environment'
import querystring from 'query-string'
import { useMemo } from 'react'
import { v4 as uuidv4 } from 'uuid'

const vippsUrl = process.env.REACT_APP_VIPPS_URL
const vippsClientId = process.env.REACT_APP_VIPPS_CLIENT_ID
const appUrl = process.env.REACT_APP_URL

const useAuthBaseActions = () => {
  const handleVippsLogin = async () => {
    const _url = `${vippsUrl}/${ENDPOINTS.VIPPS_AUTH_ENDPOINT}`

    const redirect_uri = `${appUrl}${APP_PATHS.VIPPS_CALLBACK}`

    const params = {
      response_type: 'code',
      client_id: vippsClientId,
      redirect_uri,
      scope: 'openid email phoneNumber name api_version_2 address',
      state: uuidv4()
    }

    const url = `${_url}?${querystring.stringify(params)}`

    const element = document.createElement('a')
    element.style.display = 'none'
    element.setAttribute('href', url)
    element.setAttribute('target', '_self')
    element.click()
  }

  return useMemo(() => ({ onVippsLogin: handleVippsLogin }), [])
}

export default useAuthBaseActions
