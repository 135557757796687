import { Row, Col, Modal, Typography, Button } from 'antd'
import { useHandleError, useTranslations } from 'hooks'
import PropTypes from 'prop-types'
import { createStripeAccountLink } from 'modules/stripe-module/helpers'
import { useState } from 'react'

const { Text } = Typography

const VerificationDetailsModal = (props) => {
  const { requirements, isOpened, setIsOpened, accountId } = props

  const handleError = useHandleError()
  const { t } = useTranslations()

  const [loading, setLoading] = useState(false)

  const list = requirements?.errors?.map(({ code, reason }) => (
    <Col span={24} key={code}>
      <Text>{reason}</Text>
    </Col>
  ))

  const handleClick = async () => {
    try {
      setLoading(true)
      // Generate link for updating account
      const link = await createStripeAccountLink(accountId)
      window.open(link, '_self')
      setIsOpened(false)
    } catch (error) {
      handleError(error, t('Error during creating account link'))
    } finally {
      setLoading(false)
    }
  }
  const handleCancel = () => setIsOpened(false)

  return (
    <Modal
      onCancel={handleCancel}
      title={t('Verification details')}
      open={isOpened}
      footer={null}>
      <Row gutter={[0, 8]}>
        {list}
        <Col span={24}>
          <Button type="primary" loading={loading} onClick={handleClick}>
            {t('Update')}
          </Button>
        </Col>
      </Row>
    </Modal>
  )
}

VerificationDetailsModal.propTypes = {
  requirements: PropTypes.object,
  isOpened: PropTypes.bool,
  accountId: PropTypes.string,
  setIsOpened: PropTypes.func
}

export default VerificationDetailsModal
