import { Row, Col, Typography, theme, Button } from 'antd'
import PropTypes from 'prop-types'
import { Box } from 'components'
import { useTranslations, useHandleError } from 'hooks'
import moment from 'moment'
import { getFormattedAddress } from 'modules/stripe-module/helpers'
import VERIFICATION_STATUSES, {
  VERIFICATION_STATUSES_KEYS
} from 'modules/stripe-module/__constants__/verificationStatuses'
import VerificationStatusText from './PersonalDetailsSimpleView.styled'
import { MOMENT_FORMATS } from '__constants__'
import { createStripeAccountLink } from 'modules/stripe-module/helpers'
import { useState } from 'react'

const { Text } = Typography

const PersonalDetailsSimpleView = (props) => {
  const { individual, accountId } = props

  const { t } = useTranslations()
  const token = theme.useToken().token
  const handleError = useHandleError()

  const [loading, setLoading] = useState(false)

  const dob = individual?.dob
  const verificationStatus = individual?.verification?.status
  const statusTitle = t(VERIFICATION_STATUSES?.[verificationStatus]?.title)
  // Don't show Upload button if documents are verified
  const showUploadButton =
    individual?.verification?.status !== VERIFICATION_STATUSES_KEYS.VERIFIED
  // Disable Upload button if verification is in the process
  const disableUploadButton =
    individual?.verification?.status !== VERIFICATION_STATUSES_KEYS.UNVERIFIED

  const handleUpload = async () => {
    try {
      setLoading(true)
      // Generate link for updating account
      const link = await createStripeAccountLink(accountId)
      window.open(link, '_self')
    } catch (error) {
      handleError(error, t('Error during creating account link'))
    } finally {
      setLoading(false)
    }
  }

  const information = [
    {
      title: t('Date of birth'),
      value:
        dob &&
        moment(
          `${dob.day} ${dob.month} ${dob.year}`,
          MOMENT_FORMATS.DAYS_MONTH_YEAR
        ).format(MOMENT_FORMATS.MONTH_DAYS_YEAR)
    },
    { title: t('Email'), value: individual?.email },
    { title: t('Phone number'), value: individual?.phone },
    {
      title: t('Home address'),
      value: getFormattedAddress(individual?.address)
    },
    {
      title: t('Verification'),
      value: (
        <Box className="flex gap-8">
          <VerificationStatusText token={token} status={verificationStatus}>
            {t(statusTitle)}
          </VerificationStatusText>

          {showUploadButton && (
            <Button
              size="small"
              disabled={disableUploadButton}
              onClick={handleUpload}
              loading={loading}>
              {t('Upload')}
            </Button>
          )}
        </Box>
      )
    }
  ]

  return (
    <Box className="flex flex-col gap-4">
      {information?.map(({ title, value }) => (
        <Row key={title} justify="space-between">
          <Col span={10}>
            <Text type="secondary">{title}:</Text>
          </Col>
          <Col span={12}>
            <Text>{value}</Text>
          </Col>
        </Row>
      ))}
    </Box>
  )
}

PersonalDetailsSimpleView.propTypes = {
  individual: PropTypes.shape({
    dob: PropTypes.shape({
      year: PropTypes.number,
      month: PropTypes.number,
      day: PropTypes.number
    }),
    verification: PropTypes.shape({ status: PropTypes.string }),
    email: PropTypes.string,
    phone: PropTypes.string,
    address: PropTypes.object
  }),
  accountId: PropTypes.string
}

export default PersonalDetailsSimpleView
