import PropTypes from 'prop-types'
import styled from 'styled-components'
import { theme } from 'antd'

const LikeWrapper = styled.div`
  border-radius: 50%;
  background: ${({ bg, withWrapper }) => (withWrapper ? bg : 'transparent')};

  width: ${({ size, withWrapper, iconSize }) =>
    withWrapper ? `${size}px` : `${iconSize}px`};
  height: ${({ size, withWrapper, iconSize }) =>
    withWrapper ? `${size}px` : `${iconSize}px`};
  display: flex;
  justify-content: center;
  align-items: center;
  & svg,
  & svg path {
    fill: ${({ isLiked, fill }) => (isLiked ? fill : 'transparent')};
    stroke: ${({ fill }) => fill};
  }
`
const likeIconStyles = {
  backgroundColor: 'rgba(255, 255, 255, 0.76)',
  padding: '9px',
  backdropFilter: 'blur(2px)'
}
const LikeWithStyled = (props) => {
  const { iconSize, children, isLiked, withWrapper, filled } = props
  const { colorPrimary, colorWrapperBg, colorText } = theme.useToken().token
  const wrapperSizeComputed = iconSize * 1.6
  const iconColorComputed = isLiked ? colorPrimary : colorText
  const wrapperBgColor = colorWrapperBg

  return (
    <LikeWrapper
      {...props}
      bg={wrapperBgColor}
      size={wrapperSizeComputed}
      filled={filled}
      withWrapper={withWrapper}
      fill={iconColorComputed}>
      {children}
    </LikeWrapper>
  )
}

LikeWithStyled.propTypes = {
  bg: PropTypes.string,
  iconSize: PropTypes.number,
  children: PropTypes.node,
  isLiked: PropTypes.bool,
  withWrapper: PropTypes.bool,
  filled: PropTypes.bool
}

export { LikeWithStyled as LikeWrapper, likeIconStyles }
