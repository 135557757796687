import { getId, updateParent } from 'services/api/firebase'
import { useLocation, useNavigate } from 'react-router-dom'
import { useMutate, useTranslations } from 'hooks'

import { COLLECTIONS } from '__constants__'
import { message } from 'antd'
import { useState } from 'react'
import { useUser } from 'modules/session-module/contexts'

const useActionsReviewAdvancedForm = ({
  initialData,
  form,
  productId,
  orderId,
  afterSubmitCallback
} = {}) => {
  const { user } = useUser()
  /* State for loading */
  const [loading, setLoading] = useState(false)
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting navigate instance */
  const navigate = useNavigate()
  /* Getting location state */
  const navigationState = useLocation().state
  /* Getting mutate function */
  const { create, update } = useMutate()

  /* Function for preparing values for saving */
  const prepareValues = async (values = {}, additionalValues = {}) => {
    /* Getting id */

    const reviewId = initialData?._id || getId(COLLECTIONS.REVIEWS)

    /* Preparing review values */
    const preparedValues = {
      _id: reviewId,
      ...additionalValues,
      review: values?.['review']?.['review'] ?? null,
      renteeId: user?._id ?? null,
      orderId: initialData?.orderId ?? orderId ?? null,
      productId: initialData?.productId ?? productId ?? null,
      rating: values?.['rating'] ?? null
    }

    return preparedValues
  }

  /* Saving form data */
  const saveForm = async (values, callback) => {
    try {
      // Prepare data to be saved
      const data = await prepareValues(values)

      if (navigationState?.parent)
        await updateParent(navigationState.parent, data._id)

      // Save data
      if (initialData) {
        await update(COLLECTIONS.REVIEWS, initialData?._id, data)
        message.success(t('Review successfully updated'))
      } else {
        await create(COLLECTIONS.REVIEWS, data, data?._id)
        message.success(t('Review successfully created'))
      }
      // Final callback
      callback?.()
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      throw new Error(t('Something went wrong during data save'))
    }
  }

  /* On finish callback */
  const onFinish = async () => {
    if (loading) return // Avoid multiple calls

    try {
      setLoading(true)
      // Get form values
      const formValues = form.getFieldsValue()
      const callback = afterSubmitCallback || navigate(-1)
      // Save data
      await saveForm(formValues, callback)
      // Final callback
      setLoading(false)
    } catch (error) {
      setLoading(false)
      message.error(error.message)
    }
  }

  /* On cancel callback */
  const onReset = (e) => {
    if (!afterSubmitCallback) {
      form.resetFields()
      navigate(-1)
    } else {
      afterSubmitCallback(e)
    }
  }

  return { onFinish, onReset, loading, saveForm, prepareValues }
}

export default useActionsReviewAdvancedForm
