import { COLLECTIONS } from '__constants__'
import {
  getId,
  saveBelongsToRelationship,
  updateParent
} from 'services/api/firebase'
import { useLocation, useNavigate } from 'react-router-dom'
import { useMutate, useTranslations } from 'hooks'
import { APP_PATHS } from '__constants__/paths'
import { ENDPOINTS } from '__constants__/environment'
import { fetchApi } from 'services/api/rest'
import { message } from 'antd'
import moment from 'moment'
import { useActionsAddressAdvancedForm } from 'domains/Address/components/AddressAdvancedForm/hooks'
import { useAppNotifications } from 'contexts'
import { useCart } from 'contexts'
import { useState } from 'react'
import { useUser } from 'modules/session-module/contexts'

const useActionsCheckoutAdvancedForm = ({
  form,
  initialData,
  cartItem
} = {}) => {
  const { user } = useUser()
  /* State for loading */
  const [loading, setLoading] = useState(false)

  const { t } = useTranslations()
  /* Getting navigate instance */
  const navigate = useNavigate()
  /* Getting location state */
  const navigationState = useLocation().state
  /* Getting mutate function */
  const { update } = useMutate()
  const { product } = initialData
  const { deleteCartItem } = useCart()

  const { sendErrorNotification } = useAppNotifications()

  /* Function for preparing values for saving */

  /* Getting Address values and validation function */
  const { prepareValues: prepareAddressValues } = useActionsAddressAdvancedForm(
    { initialData }
  )

  /* Saving form data */
  const saveForm = async (values, callback) => {
    try {
      // Prepare data to be saved
      const data = await prepareValues(values)

      if (navigationState?.parent)
        await updateParent(navigationState.parent, data._id)

      // Save data
      if (initialData.checkout?._id) {
        await update(COLLECTIONS.ORDERS, initialData.checkout?._id, data)
        message.success(t('Order successfully updated'))

        callback?.()
      } else {
        const fetchObj = {
          path: ENDPOINTS.CREATE_ORDER,
          method: 'POST',
          body: data
        }

        const [response, responseData] = await fetchApi(fetchObj)

        if (response.ok) {
          await deleteCartItem(cartItem?.index)
          callback?.()
          message.success(t('Order successfully created'))
        } else {
          const { message, description } = responseData
          sendErrorNotification({ message, description })
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      throw new Error(t('Something went wrong during data save'))
    }
  }
  const prepareValues = async (values = {}, additionalValues = {}) => {
    /* Getting id */
    const checkoutId = initialData?.checkout?._id || getId(COLLECTIONS.ORDERS)
    const parentReferences = {
      _id: values?.address?.locationData?._id || getId(COLLECTIONS.ADDRESSES),
      checkoutId
    }
    const addressDataFromValues = await prepareAddressValues(
      values,
      parentReferences
    )

    // if user chose specific address the get this address from values
    // otherwise get initialData address, as this address will be the product address
    // which means that user is going to pick up product from its place
    const addressDataFromValuesOrInitial = values?.address?.locationData
      ? addressDataFromValues
      : initialData?.address

    /* Preparing checkout values */
    const preparedValues = {
      _id: checkoutId,
      ...additionalValues,
      productId: product?._id ?? null,
      renteeUserId: user?._id ?? null,
      renterUserId: product?._createdBy ?? null,
      deliveryType: values?.address?.deliveryType ?? null,
      addressId: await saveBelongsToRelationship(
        COLLECTIONS.ADDRESSES,
        addressDataFromValuesOrInitial,
        parentReferences
      ),
      startDate: values?.dates?.startDate ?? cartItem?.startDate ?? null,
      endDate: values?.dates?.endDate ?? cartItem?.endDate ?? null,
      deliveryTime: values?.deliveryTimePrepared?.isValid()
        ? values?.deliveryTimePrepared
        : null,
      checkoutId: cartItem?.checkoutId
    }

    return preparedValues
  }

  /* On finish callback */
  const onFinish = async () => {
    if (loading) return // Avoid multiple calls

    try {
      setLoading(true)
      // Get form values
      const formValues = form.getFieldsValue()

      // delivery Time computed to make sure the start date is the same as order.startDate
      const time = {
        hour: formValues?.address?.deliveryTime?.hour() ?? 0,
        minute: formValues?.address?.deliveryTime?.minute() ?? 0,
        second: formValues?.address?.deliveryTime?.second() ?? 0
      }
      const deliveryTimePrepared = moment(formValues?.dates?.startDate)?.set(
        time
      )

      // Check difference between tim for start day and current time
      const differenceInMinutes = moment(deliveryTimePrepared).diff(
        moment(),
        'm'
      )

      if (differenceInMinutes < 60) {
        throw new Error(
          t(
            'This time is unavailable, the minimum time before the start of the rental is 60 minutes'
          )
        )
      }
      // Final callback
      const navigateCallback = () => navigate(APP_PATHS.DASHBOARD)

      // Save data
      await saveForm({ ...formValues, deliveryTimePrepared }, navigateCallback)
    } catch (error) {
      message.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  /* On cancel callback */
  const onReset = () => {
    form.resetFields()
    navigate(APP_PATHS.DASHBOARD)
  }

  return {
    onFinish,
    onReset,
    loading,
    prepareValues
  }
}

export default useActionsCheckoutAdvancedForm
