import {
  CURRENT_APP,
  LANGUAGES,
  TRANSLATION_LOADING_CONFIG
} from '../../__constants__'
import Provider, { TranslationContext } from '@qonsoll/translation'
import { ref as dbRef, onValue, update } from 'firebase/database'
import { useCallback, useMemo } from 'react'

import { ContextLoader } from '../../components'
import PropTypes from 'prop-types'
import { database } from 'services/firebase'

const REACT_APP_CUSTOM_URL_EN = process.env.REACT_APP_CUSTOM_URL_EN

const TranslationsProvider = (props) => {
  const { children } = props

  // Set a default language in dependence on a hostname
  const defaultLanguage =
    window?.location?.hostname === REACT_APP_CUSTOM_URL_EN ? 'en' : 'no'

  // [COMPUTED PROPERTIES]
  const loadingText = useMemo(
    () => TRANSLATION_LOADING_CONFIG?.[localStorage.getItem('language')],
    []
  )

  // [HELPER_FUNCTIONS]
  const onWrite = useCallback(
    ({ ref, value }) => update(dbRef(database, ref), value),
    []
  )
  const onRead = useCallback(({ ref: path, setTranslations, options }) => {
    // try/catch block is over handleRead function
    onValue(
      dbRef(database, path),
      (snapshot) => setTranslations(snapshot?.val() || {}),
      options
    )
  }, [])

  return (
    <Provider
      languages={LANGUAGES}
      defaultLanguage={defaultLanguage}
      currentApp={CURRENT_APP}
      onWrite={onWrite}
      saveNewAutomatically
      useHashes
      onRead={onRead}>
      <TranslationContext.Consumer>
        {({ loaded }) => (
          <ContextLoader loaded={loaded} loadingText={loadingText}>
            {children}
          </ContextLoader>
        )}
      </TranslationContext.Consumer>
    </Provider>
  )
}

TranslationsProvider.propTypes = {
  children: PropTypes.any
}

export default TranslationsProvider
