import { Icon } from '@qonsoll/icons'
import PropTypes from 'prop-types'
import { StyledCard } from './AddItemCard.styles'
import { Typography } from 'antd'
import { useTranslations } from 'hooks'

const AddItemCard = (props) => {
  const {
    height = '100%',
    onClick,
    message,
    icon,
    withMessage = true,
    messageType,
    minHeight = '70px',
    ...rest
  } = props

  const { t } = useTranslations()

  return (
    <StyledCard
      height={height}
      onClick={onClick}
      minHeight={minHeight}
      {...rest}>
      <div className="mb-8">
        <Typography.Text>
          {icon || <Icon name="Plus1Outlined" />}
        </Typography.Text>
      </div>
      {withMessage && (
        <Typography.Text type={messageType}>
          {message || t('Add')}
        </Typography.Text>
      )}
    </StyledCard>
  )
}

AddItemCard.propTypes = {
  message: PropTypes.string,
  onClick: PropTypes.func,
  height: PropTypes.string,
  withMessage: PropTypes.bool,
  minHeight: PropTypes.string,
  messageType: PropTypes.string,
  icon: PropTypes.bool
}

export default AddItemCard
