import { Space, Typography } from 'antd'
import { useScreen, useTranslations } from 'hooks'

import { Avatar } from './UserIntro.styled'
import PropTypes from 'prop-types'
import noProfile from '__assets__/illustrations/no-profile.png'
import { pluralizeStrBasedOnNumber } from 'helpers'
import { useGetUserDisplayName } from 'domains/User/hooks'
import { useMemo } from 'react'

const UserIntro = (props) => {
  const { rating, productsAmount, ordersAmount, user } = props
  const { avatarUrl, email } = user || {}
  const { t } = useTranslations()

  const userName = useGetUserDisplayName(user)
  const { isExtraSmallScreen, isSmallScreen } = useScreen()
  const isMobile = isExtraSmallScreen || isSmallScreen

  const extraInfo = useMemo(
    () => [
      { title: t('Rating'), value: rating },
      {
        title: pluralizeStrBasedOnNumber(productsAmount, t('Product')),
        value: productsAmount
      },
      {
        title: pluralizeStrBasedOnNumber(ordersAmount, t('Order')),
        value: ordersAmount
      }
    ],
    [ordersAmount, productsAmount, rating, t]
  )

  return (
    <Space size={24} align="top" className="flex flex-1">
      <Avatar
        src={avatarUrl || noProfile}
        isMobile={isMobile}
        preview={false}
      />
      <div className="flex flex-col">
        <Space className="mb-16" direction="vertical" size={2}>
          <Typography.Title level={4}>{userName}</Typography.Title>
          <Typography.Text type="secondary">{email}</Typography.Text>
        </Space>
        <div className="flex justify-between">
          {extraInfo?.map((item, index) => (
            <div
              className="flex flex-col align-center justify-center"
              key={`${index}-extra-item`}>
              <Typography.Text strong>{item.value}</Typography.Text>
              <Typography.Text>{item.title}</Typography.Text>
            </div>
          ))}
        </div>
      </div>
    </Space>
  )
}

UserIntro.propTypes = {
  rating: PropTypes.number,
  user: PropTypes.object,
  productsAmount: PropTypes.number,
  ordersAmount: PropTypes.number
}

export default UserIntro
