import { Upload, message } from 'antd'
import { getBase64, validateFileSize, validateImageSize } from './helpers'
import { memo, useEffect, useMemo, useState } from 'react'

import { Icon } from '@qonsoll/icons'
import { Image } from 'components'
import ImgCrop from 'antd-img-crop'
import PropTypes from 'prop-types'
import { styles } from './ImageUploader.styles'
import { uploadImage } from 'helpers'
import { useTranslations } from 'hooks'

/**
 * It's a React component that renders an image uploader
 * @param value {string} - The image url
 * @param onChange {function} - The function to call when the image is changed
 * @param withDirectUpload {boolean} - If true, returns an url to the image
 * @param imageCropProps {Object} - check available props on https://github.com/nanxiaobei/antd-img-crop
 * @param uploadProps {Object} - check available props on https://4x.ant.design/components/upload/
 * @param withImageCrop {boolean} - Should image be cropped before upload
 * @param withImageSizeValidation {boolean} - if true image size will be validated (1024px by 1024px for example)
 * @param withFileSizeValidation {boolean} - if true image size will be validated by size (2 Mib for example)
 * @param maxWidthUploadedImage {number} - max width of upload image (work's only with withImageSizeValidation = true)
 * @param maxHeightUploadedImage {number} - max height of upload image (work's only with withImageSizeValidation = true)
 * @param maxFileSize {number} - max size of upload image (work's only with withFileSizeValidation = true)
 */
const AVAILABLE_FORMATS = [
  'image/jpeg',
  'image/png',
  'image/jpg',
  'image/tiff',
  'image/svg'
]

const ImageUploader = (props) => {
  const {
    value,
    onChange,
    withImageCrop = true,
    withDirectUpload = false,
    withImageSizeValidation = false,
    withFileSizeValidation = false,
    imgCropProps,
    uploadProps,
    maxWidthUploadedImage = 1024,
    maxHeightUploadedImage = 1024,
    maxFileSize = 2,
    imgProps,
    path,
    listType = 'picture-card',
    uploadAction,
    getUploadFile64
  } = props

  const [file, setFile] = useState(null)

  const { t } = useTranslations()

  const handleUpload = async ({ onSuccess, file }) => {
    if (withDirectUpload) {
      const url = await uploadImage(file, path)
      onChange(url)
    } else {
      onChange?.(file)
      const onTransformSuccess = (result) => {
        setFile(result)
        getUploadFile64?.(result)
      }
      const onTransformError = () => {
        onTransformSuccess(null)
        message.error(t('Error during image upload, please try again'))
      }
      getBase64(file, onTransformSuccess, onTransformError)
    }
    onSuccess()
  }

  const beforeUpload = async (file) => {
    try {
      const isAvailableFormat = AVAILABLE_FORMATS.includes(file?.type)
      if (!isAvailableFormat) {
        const availableFormats = AVAILABLE_FORMATS.map((f) =>
          f.split('/').at(1).toUpperCase()
        ).join('/')
        const msg = `You can upload only ${availableFormats} files`
        message.error(t(msg))
        return false
      }

      if (withImageSizeValidation) {
        const isImgValid = await validateImageSize(
          file,
          maxWidthUploadedImage,
          maxHeightUploadedImage
        )
        if (!isImgValid) {
          const msg = `Images must be at least ${maxWidthUploadedImage} by ${maxHeightUploadedImage}`
          message.error(t(msg))
          return false
        }
      }

      if (withFileSizeValidation) {
        const isFileSizeValid = validateFileSize(file, maxFileSize)
        if (!isFileSizeValid) {
          message.error(t(`Images must be ${maxFileSize} Mib`))
          return false
        }
      }

      return true
    } catch (error) {
      setFile(null)
      message.error(t('Something went wrong during image upload'))
      return false
    }
  }

  useEffect(() => {
    let isMounted = true
    isMounted && value && setFile(value)
    return () => (isMounted = false)
  }, [value])

  const computedUploadContent = useMemo(
    () =>
      uploadAction ? (
        uploadAction
      ) : file ? (
        <Image
          alt="Image"
          style={styles.image}
          src={file || value}
          {...imgProps}
        />
      ) : (
        <Icon name="Plus1Outlined" />
      ),
    [file, imgProps, value, uploadAction]
  )

  return withImageCrop ? (
    <ImgCrop
      modalOk={t('Apply')}
      modalCancel={t('Cancel')}
      modalTitle={t('Crop image')}
      showGrid
      rotationSlider
      minZoom={0.9}
      quality={1}
      beforeCrop={beforeUpload}
      {...imgCropProps}>
      <Upload
        fileList={null}
        customRequest={handleUpload}
        listType={listType}
        accept={AVAILABLE_FORMATS}
        {...uploadProps}>
        {computedUploadContent}
      </Upload>
    </ImgCrop>
  ) : (
    <Upload
      fileList={null}
      customRequest={handleUpload}
      listType={listType}
      accept={AVAILABLE_FORMATS}
      {...uploadProps}>
      {computedUploadContent}
    </Upload>
  )
}

ImageUploader.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  withDirectUpload: PropTypes.bool,
  imgCropProps: PropTypes.object,
  uploadProps: PropTypes.object,
  withImageSizeValidation: PropTypes.bool,
  withFileSizeValidation: PropTypes.bool,
  withImageCrop: PropTypes.bool,
  maxWidthUploadedImage: PropTypes.number,
  maxHeightUploadedImage: PropTypes.number,
  maxFileSize: PropTypes.number,
  imgProps: PropTypes.object,
  path: PropTypes.string,
  listType: PropTypes.bool,
  uploadAction: PropTypes.node,
  getUploadFile64: PropTypes.func
}

export default memo(ImageUploader)
