import { AUTH_PATHS } from 'modules/session-module//__constants__'
import { AuthLayout } from 'modules/session-module//components'
import { SignupMethods } from 'modules/session-module//domains/Session/components'
import { useNavigate } from 'react-router-dom'
import { useSessionActions } from 'modules/session-module//hooks'
import { useState } from 'react'
import { useTranslations } from 'hooks'

const SignUpWithEmail = () => {
  const { t } = useTranslations()
  const navigate = useNavigate()
  const { googleLogin } = useSessionActions()

  const [loading, setLoading] = useState(false)

  const toggleLoading = () => setLoading(!loading)

  const signInWithEmail = () => navigate(`../${AUTH_PATHS.SIGNUP_WITH_EMAIL}`)

  const googleAuth = () =>
    googleLogin({ before: toggleLoading, after: toggleLoading })

  return (
    <AuthLayout
      withBackButton
      title={t('Create an account')}
      subTitle={t('Create an account easily using any of the methods below')}>
      <SignupMethods
        authTypeText={t('Sign up')}
        googleAuth={googleAuth}
        redirectToFormWithEmail={signInWithEmail}
        authSwitchText={t('Already have account') + '?'}
        authSwitchLinkText={t('Login')}
        authSwitchPath={`../${AUTH_PATHS.LOGIN}`}
      />
    </AuthLayout>
  )
}

export default SignUpWithEmail
