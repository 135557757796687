import { Row, Col, Image, Typography } from 'antd'
import error from '__assets__/illustrations/error.svg'
import { useTranslations } from 'hooks'
import imageStyles from './RefreshStripeAccountAdvancedView.styles'

const { Text, Title } = Typography

const RefreshStripeAccountAdvancedView = () => {
  const { t } = useTranslations()

  return (
    <Row align="center">
      <Col span={24} align="center" className="mb-16">
        <Image {...imageStyles} src={error} preview={false} />
      </Col>
      <Col span={24} align="center" className="mb-2">
        <Title level={2}> {t('Error')}!</Title>
      </Col>
      <Col>
        <Text>
          {t('There was an error creating your account, please try again')}
        </Text>
      </Col>
    </Row>
  )
}
export default RefreshStripeAccountAdvancedView
