import { Input } from 'antd'
import styled from 'styled-components'

const SearchHeaderStyled = styled.div`
  padding: 16px 36px;
  background: ${({ theme }) => theme.colorBgContainer};
  border-bottom: ${({ theme }) => `1px solid ${theme.colorBorderSecondary}`};
`

const InputStyled = styled(Input)`
  padding: 3px 12px;
  margin-top: 2px;
`

export { SearchHeaderStyled, InputStyled }
