import { Checkbox, Form, Typography } from 'antd'

import { AddressAdvancedSelect } from 'domains/Address/components'
import { FormWrapper } from 'components'
import { LocationFormInModal } from 'domains/GoogleMaps/components'
import PropTypes from 'prop-types'
import { useGetUserAddresses } from 'domains/Address/hooks'
import { useTranslations } from 'hooks'

const ProductLocationFormWithPreview = (props) => {
  const { initialValues, form, group = ['address'] } = props
  const { t } = useTranslations()

  const currentValue = Form.useWatch([...group, 'locationData'], form)

  const [addresses, loadingAddresses] = useGetUserAddresses()

  const getFieldName = (subGroup) => (group ? [...group, subGroup] : [subGroup])

  return (
    <>
      <div className="mb-16">
        <div className="flex flex-col mb-16">
          <Checkbox checked className="">
            {t('Pick-up location')}
          </Checkbox>
          <Typography.Text className="ml-24 mt-8" type="secondary">{`${t(
            'Choose the location of the product'
          )}. ${t(
            'This location will be visible for your customers as a location where they could pick up your product from'
          )}.`}</Typography.Text>
        </div>
        <div className="ml-24">
          <FormWrapper
            form={form}
            initialValues={initialValues}
            group={['address']}>
            <Form.Item name={getFieldName('locationData')}>
              <AddressAdvancedSelect
                form={form}
                defaultValue={initialValues?.address}
                addresses={addresses}
                loadingAddresses={loadingAddresses}
              />
            </Form.Item>
          </FormWrapper>
          <LocationFormInModal address={currentValue} form={form} />
        </div>
      </div>

      <div className="flex flex-col" style={{ order: 1 }}>
        <Form.Item
          className="mb-0"
          name={['product', 'isRenterDelivery']}
          valuePropName="checked">
          <Checkbox>{t('Renter delivery')}</Checkbox>
        </Form.Item>
        <Typography.Text className="ml-24 mt-4" type="secondary">{`${t(
          'You can provide your customers with delivery'
        )}. ${t(
          'If you want to notify customers that you are able to deliver a product'
        )}, ${t('please select this option')}.`}</Typography.Text>
      </div>
    </>
  )
}

ProductLocationFormWithPreview.propTypes = {
  initialValues: PropTypes.object,
  form: PropTypes.object,
  group: PropTypes.array
}

export default ProductLocationFormWithPreview
