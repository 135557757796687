import { collection, orderBy, query, where } from 'firebase/firestore'
import { useCallback, useEffect, useState } from 'react'
import { useHandleError, useTranslations } from 'hooks'

import { COLLECTIONS } from '__constants__'
import { firestore } from 'services/firebase'
import { getDocument } from 'services/api/firebase'
import { useCollectionData } from 'react-firebase-hooks/firestore'

const useGetReviews = (productId) => {
  const handleError = useHandleError()
  const { t } = useTranslations()

  const [expandedReviews, setExpandedReviews] = useState([])
  const [loading, setLoading] = useState(true)

  /* Getting collection data */
  const [reviews, loadingReviews, error] = useCollectionData(
    productId &&
      query(
        collection(firestore, COLLECTIONS.REVIEWS),
        where('productId', '==', productId),
        orderBy('_createdAt', 'desc')
      )
  )

  const getRelatedData = useCallback(async () => {
    try {
      // added this part to avoid error when reviews is undefined and not to request the server response
      if (Array.isArray(reviews)) {
        const results = await Promise.all(
          reviews.map(async (review) => {
            const rentee = await getDocument(
              COLLECTIONS.USERS,
              review?.renteeId
            )
            return { review, rentee }
          })
        )
        setExpandedReviews(results)
      }
    } catch (error) {
      handleError(error, t('Error during getting reviews'))
    } finally {
      setLoading(false)
    }
  }, [handleError, reviews, t])

  useEffect(() => {
    if (!loadingReviews) {
      getRelatedData()
    }
  }, [reviews, loadingReviews, getRelatedData])

  return [expandedReviews, loadingReviews || loading, error]
}

export default useGetReviews
