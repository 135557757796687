import PropTypes from 'prop-types'
import { Typography } from 'antd'
import { calculateFinalPayment } from 'helpers'
import { calculateProductPriceByDays } from 'domains/Product/helpers'
import { currencySymbol } from 'domains/Product/hooks'
import { useMemo } from 'react'

const OrderPrice = (props) => {
  const { order, noData, titleLevel } = props

  const [totalPrice] = calculateProductPriceByDays(order, order?.product)
  const totalPriceToShow = calculateFinalPayment(totalPrice)

  const productCurrencySymbol = useMemo(
    () => currencySymbol(order?.product?.currency),
    [order?.product?.currency]
  )

  return totalPriceToShow ? (
    <div className="flex align-baseline">
      <Typography.Text className="mr-4" level={titleLevel || 5}>
        {totalPriceToShow}
      </Typography.Text>
      <Typography.Text type="secondary">
        {productCurrencySymbol}
      </Typography.Text>
    </div>
  ) : (
    noData
  )
}

OrderPrice.propTypes = {
  order: PropTypes.object,
  noData: PropTypes.node,
  titleLevel: PropTypes.number
}

export default OrderPrice
