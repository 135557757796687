import { COLLECTIONS } from '__constants__'
import { doc } from 'firebase/firestore'
import { firestore } from 'services/firebase'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { useLoading } from 'hooks'
import { useMemo } from 'react'
import { useUser } from 'modules/session-module/contexts'

const useGetPaymentMethodInitialValues = (paymentMethodId) => {
  const { user, loading: userLoading } = useUser()

  /* Fetching data using useDocument hook */
  const query =
    paymentMethodId &&
    doc(firestore, COLLECTIONS.PAYMENT_METHODS, paymentMethodId)

  const [paymentMethod, paymentMethodLoading] = useDocumentData(query)

  /* Merge all fetched data */
  const initialValues = useMemo(
    () => ({
      paymentMethod,
      user
    }),
    [paymentMethod, user]
  )

  /* Merge all loading states */
  const loadings = useMemo(
    () => [paymentMethodLoading, userLoading],
    [paymentMethodLoading, userLoading]
  )

  /* Getting loading state using merged loadings */
  const loading = useLoading(loadings)

  return [initialValues, loading]
}

export default useGetPaymentMethodInitialValues
