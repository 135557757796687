import { generatePath, useNavigate } from 'react-router-dom'
import { useCallback, useMemo } from 'react'

import { APP_PATHS } from '__constants__/paths'
import { useSearchProductsByCategory } from 'domains/Product/hooks'

const useProductSearch = () => {
  const searchParams = useMemo(
    () => ({ perPage: 3, initialSearchValue: null }),
    []
  )

  const { products, setSearchValue } = useSearchProductsByCategory(searchParams)
  const navigate = useNavigate()

  const onReset = useCallback(() => setSearchValue(null), [setSearchValue])
  const onSelect = useCallback(
    (productId) => {
      if (!productId) return
      const path = generatePath(APP_PATHS.PRODUCT_SHOW, { productId })
      navigate(path)
    },
    [navigate]
  )

  return useMemo(
    () => ({
      data: products || [],
      onChange: setSearchValue,
      onReset,
      onSelect
    }),
    [products, setSearchValue, onReset, onSelect]
  )
}

export default useProductSearch
