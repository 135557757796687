import { Button, Form, Space, Typography } from 'antd'

import { Box } from 'components'
import PropTypes from 'prop-types'
import { useAppNotifications } from 'contexts'
import { useTranslations } from 'hooks'

const FormWrapper = (props) => {
  const {
    group,
    showTitle,
    children,
    actions: formActions,
    title: formTitle,
    layout,
    customAction,
    actionsDisabled,
    preserve,
    size,
    buttonSize,
    submitDisabled,
    spaceProps = {}
    // block
  } = props

  const { t } = useTranslations()
  const { sendErrorNotification } = useAppNotifications()

  const title = showTitle ? (
    <Box mb={2}>
      <Typography.Title level={3}>{formTitle}</Typography.Title>
    </Box>
  ) : null

  if (group) {
    return (
      <>
        {title}
        {children}
        {formActions}
      </>
    )
  }

  const {
    onReset,
    form,
    submitLoading,
    resetText,
    submitText,
    isBlockButtons,
    ...rest
  } = props

  const handleSubmit = () => form.submit()

  const spacePropsComputed = isBlockButtons
    ? {
        ...spaceProps,
        direction: 'vertical',
        className: 'full-width flex-col-reverse',
        size: 12
      }
    : spaceProps

  const defaultActions =
    onReset || form ? (
      <Space {...spacePropsComputed}>
        {onReset && (
          <Button
            block={true}
            onClick={onReset}
            size={isBlockButtons ? 'large' : buttonSize}
            disabled={actionsDisabled}
            id="formReset">
            {resetText || t('Cancel')}
          </Button>
        )}
        {customAction || null}
        {form && (
          <Button
            block={true}
            type="primary"
            size={isBlockButtons ? 'large' : buttonSize}
            disabled={submitDisabled || actionsDisabled}
            loading={submitLoading}
            onClick={handleSubmit}
            id="formSubmit">
            {submitText || t('Save')}
          </Button>
        )}
      </Space>
    ) : null

  const actions = formActions === null ? null : formActions ?? defaultActions

  const onFinishFailed = () => {
    const errorTitle = t("We're sorry")
    const errorDescription = `${t(
      'There was an error submitting your form'
    )}. ${t(
      'Please ensure that all required fields are completed and try again later'
    )}.`

    sendErrorNotification({
      message: errorTitle,
      description: errorDescription
    })
  }

  return (
    <Form
      preserve={preserve}
      onFinishFailed={onFinishFailed}
      layout={layout ? layout : 'vertical'}
      autoComplete="off"
      form={form}
      requiredMark="optional"
      size={size}
      {...rest}>
      {title}
      {children}
      {actions}
    </Form>
  )
}

FormWrapper.propTypes = {
  group: PropTypes.array,
  showTitle: PropTypes.bool,
  onReset: PropTypes.func,
  form: PropTypes.object,
  submitLoading: PropTypes.bool,
  children: PropTypes.node,
  resetText: PropTypes.string,
  submitText: PropTypes.string,
  actions: PropTypes.node,
  title: PropTypes.string,
  layout: PropTypes.string,
  customAction: PropTypes.node,
  actionsDisabled: PropTypes.bool,
  block: PropTypes.bool,
  preserve: PropTypes.bool,
  size: PropTypes.string,
  buttonSize: PropTypes.string,
  isBlockButtons: PropTypes.bool,
  submitDisabled: PropTypes.bool,
  spaceProps: PropTypes.object
}

export default FormWrapper
