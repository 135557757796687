import { useCallback, useState } from 'react'

import _ from 'lodash'

/*
This function checks for changes in a form and consists of several stages.

prepareData: It consolidates all the data into a unified format, transforming empty values or undefined into null.

transformObjects: It adds all the missing fields with initial form values to the current form values to ensure that both have the same fields.
 For example, if we have a field called "cardNumber" in the payment methods, but this field is not present in the current form values object until the user selects the payment type as "CARD," the comparison using JSON.stringify will show that initial form values !== current form values (the strings are not equal).

transformArrays:  function allows finding all the arrays in the object. In the case where an array contains objects, it extracts only their _id values. This is done to exclude different fields since there may be objects with the same identifiers in current form values but with additional data that modifies the form. 
 For example, when a media uploader is used, it adds a "uid" field to the media object, which is not present in the initial media object.
 */

const useCheckFormChange = (initialValue = {}, form) => {
  const [isEqual, setIsEqual] = useState(true)

  const isObject = useCallback(
    (item) => item && typeof item === 'object' && !Array.isArray(item),
    []
  )

  /* This function is only used where address since the locationData is not includes in the initialValues. This field is locally removed.
   Additionally, the latitude and longitude values are updated from the locationData into the address object. */
  const updateAddressData = useCallback((data) => {
    const locationDataLat = data?.address?.locationData?.latitude
    const locationDataLong = data?.address?.locationData?.longitude

    const latitude = locationDataLat || data?.address?.latitude

    const longitude = locationDataLong || data?.address?.longitude

    delete data?.address?.addressData
    delete data?.address?.locationData

    if (!latitude || !longitude) return

    data.address = { ...(data?.address || {}), latitude, longitude }
  }, [])

  const transformArrays = useCallback((obj) => {
    for (let key in obj) {
      if (Array.isArray(obj[key])) {
        const transformedItem = obj[key]?.map((item, i) => {
          const computedItem = item?._id || item?.uid || item
          const isCoveredItem = !computedItem?.includes?.('_isCovered')
            ? i === 0 || item?.isCoverImage
            : false

          if (isCoveredItem) return computedItem + '_isCovered'
          return computedItem
        })
        Object.assign(obj, { [key]: transformedItem })
      } else if (typeof obj[key] === 'object') {
        transformArrays(obj[key])
      }
    }

    return obj
  }, [])

  const mergeObjects = useCallback(
    (target, ...sources) => {
      if (!sources.length) return target
      const source = sources.shift()

      if (isObject(target) && isObject(source)) {
        for (const key in source) {
          if (isObject(source[key])) {
            if (!target[key]) Object.assign(target, { [key]: {} })
            mergeObjects(target[key], source[key])
          } else {
            Object.assign(target, { [key]: source[key] })
          }
        }
      }

      return mergeObjects(target, ...sources)
    },
    [isObject]
  )

  const prepareData = useCallback(
    (data) => {
      if (!data) return {}

      updateAddressData(data)

      const transformedArrays = transformArrays(data)
      const prepareStringify = JSON.stringify(transformedArrays)
        ?.replaceAll('undefined', 'null')
        ?.replaceAll('""', 'null')

      return JSON.parse(prepareStringify)
    },
    [transformArrays, updateAddressData]
  )

  const preparedInitialValue = prepareData(initialValue)

  const transformObjects = useCallback(
    (values) => {
      const initialValueCopy = JSON.parse(JSON.stringify(preparedInitialValue))
      const valuesCopy = prepareData(values)
      const mergedDeepValue = mergeObjects(initialValueCopy, valuesCopy)
      return mergedDeepValue
    },
    [preparedInitialValue, mergeObjects, prepareData]
  )

  const handleValuesChange = (changedValues, allValues) => {
    // case with form?.getFieldsValue() uses for UserAdvancedForm, because there is specific Form.Item - address
    const transformedValues = transformObjects(
      form?.getFieldsValue() || allValues
    )
    const isEqual = _.isEqual(preparedInitialValue, transformedValues)

    setIsEqual(isEqual)
  }

  return [handleValuesChange, isEqual]
}

export default useCheckFormChange
