import moment from 'moment'

const useOrderCancellationTime = (order) => {
  if (!order || !order.cancellationTimeExpiresAt?.seconds) {
    return null
  }

  const cancellationTimeExpiresAtMoment = moment.unix(
    order.cancellationTimeExpiresAt.seconds
  )

  if (!cancellationTimeExpiresAtMoment.isValid()) return null

  const hasTermExpired = moment() > cancellationTimeExpiresAtMoment

  const formattedCancellationTermExpiredAt =
    cancellationTimeExpiresAtMoment.format('DD.MM.YYYY, HH:mm')

  return hasTermExpired ? null : formattedCancellationTermExpiredAt
}

export default useOrderCancellationTime
