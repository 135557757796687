import PropTypes from 'prop-types'
import { Icon as QonsollIcon } from '@qonsoll/icons'
import { theme } from 'antd'

const Icon = (props) => {
  const { name, size = 16, fill } = props

  const { colorText } = theme.useToken().token

  return <QonsollIcon name={name} size={size} fill={fill || colorText} />
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fill: PropTypes.string
}

export default Icon
