import { PageHeaderSimple, PageLayout, Box } from 'components'
import { APP_PATHS } from '__constants__'
import { useNavigate } from 'react-router-dom'
import { RefreshStripeAccountAdvancedView } from 'modules/stripe-module/domains/Account/components'
import { Button, Row, Col } from 'antd'
import { useTranslations, useHandleError } from 'hooks'
import {
  createStripeAccountLink,
  createStripeAccount
} from 'modules/stripe-module/helpers'
import { useUser } from 'modules/session-module/contexts'

const RefreshStripeAccount = () => {
  const navigate = useNavigate()
  const { t } = useTranslations()
  const handleError = useHandleError()
  const { user } = useUser()

  const handleTryAgain = async () => {
    try {
      const origin = window?.origin
      const refreshUrl = `${origin}${APP_PATHS.REFRESH_STRIPE_ACCOUNT}`
      const returnUrl = `${origin}${APP_PATHS.RETURN_STRIPE_ACCOUNT}?id=${user?._id}`

      let url = ''
      if (user?.stripeAccountId)
        url = await createStripeAccountLink(
          user?.stripeAccountId,
          refreshUrl,
          returnUrl
        )
      else {
        url = createStripeAccount(user?.email, user?._id, refreshUrl, returnUrl)
      }
      window.open(url, '_self')
    } catch (error) {
      handleError(error, t('Error during creating stripe account link'))
    }
  }

  const onBack = () => navigate(APP_PATHS.DASHBOARD)

  return (
    <PageLayout topHeader={<PageHeaderSimple onlyLogo onBack={onBack} />}>
      <Box className="full-height flex align-center justify-center">
        <Row align="center">
          <Col span={24} className="mb-16">
            <RefreshStripeAccountAdvancedView />
          </Col>
          <Col align="center">
            <Button type="primary" onClick={handleTryAgain}>
              {t('Try again')}
            </Button>
          </Col>
        </Row>
      </Box>
    </PageLayout>
  )
}

export default RefreshStripeAccount
