const styles = {
  image: {
    width: '100%',
    minHeight: '100px',
    minWidth: '100px',
    maxHeight: '400px',
    objectFit: 'cover',
    cursor: 'pointer'
  }
}

export { styles }
