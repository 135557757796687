import { Upload, theme } from 'antd'

import PropTypes from 'prop-types'
import styled from 'styled-components'

const UploadStyled = styled(Upload)`
  .ant-upload-select {
    width: ${({ width }) => width} !important;
  }
  .ant-upload-list-item,
  .ant-upload-list-item.ant-upload-list-item-done {
    padding: '0px !important';
  }

  .ant-upload-list.ant-upload-list-picture-card
    .ant-upload-list-item-thumbnail
    img {
    object-fit: cover !important;
    border-radius: ${({ borderRadius }) => `${borderRadius}px`};
  }
  .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item::before,
  .ant-upload-list.ant-upload-list-picture-card
    .ant-upload-list-item:hover::before {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: ${({ borderRadius }) => `${borderRadius}px`};
  }
`

export const UploadMediaStyled = (props) => {
  const { children, ...rest } = props

  const { borderRadiusLG } = theme.useToken().token

  return (
    <UploadStyled {...rest} borderRadius={borderRadiusLG} headers="Uploading">
      {children}
    </UploadStyled>
  )
}

UploadMediaStyled.propTypes = {
  children: PropTypes.node
}
