import Proxy from '../../services/configProxy/Proxy'
import { useEffect } from 'react'

const ChatConfigProvider = (props) => {
  const { config, children } = props

  useEffect(() => {
    Proxy.setConfig(config)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config])

  return children
}

export default ChatConfigProvider
