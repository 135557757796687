import { Avatar, Col, Row, Typography } from 'antd'

import PropTypes from 'prop-types'
import noProfile from '__assets__/illustrations/no-profile.png'
import { useCallback } from 'react'

const CustomerTableSimpleView = (props) => {
  const { customer } = props

  const { Text } = Typography

  const handleImageError = useCallback(
    (e) => (e.currentTarget.src = noProfile),
    []
  )

  return (
    <Row gutter={[15, 0]} align="middle" wrap={false}>
      <Col flex="none">
        <Avatar
          size={40}
          src={customer?.avatarUrl || noProfile}
          onError={handleImageError}
        />
      </Col>
      <Col>
        <Text>{customer?.fullName}</Text>
      </Col>
    </Row>
  )
}

CustomerTableSimpleView.propTypes = {
  customer: PropTypes.object.isRequired
}

export default CustomerTableSimpleView
