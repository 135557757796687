const ENDPOINTS = {
  CREATE_ORDER: 'createOrder',
  UPDATE_ORDER_STATUS: 'updateOrderStatus',
  UPDATE_USER_VIEWS_STATISTICS: 'updateUserViewsStatistics',
  VIPPS_AUTH_ENDPOINT: 'access-management-1.0/access/oauth2/auth',
  LOGIN_BY_VIPPS: 'loginByVipps',
  DELETE_USER: 'deleteUser'
}

export { ENDPOINTS }
export default ENDPOINTS
