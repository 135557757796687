import { Tooltip, Typography } from 'antd'

import PropTypes from 'prop-types'
import { useTranslations } from 'hooks'

const RefundARN = (props) => {
  const { refund } = props

  const { t } = useTranslations()

  const arn = refund?.destination_details?.card?.reference

  const tooltipText = `${t(
    'You can take this reference to your bank, which can provide more information about when the refund will be available'
  )}`

  return (
    <Tooltip title={tooltipText}>
      <Typography.Text>{arn}</Typography.Text>
    </Tooltip>
  )
}

RefundARN.propTypes = {
  refund: PropTypes.object
}

export default RefundARN
