import { Typography, theme } from 'antd'

const styles = {
  fieldTitleProps: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '250px',
    whiteSpace: 'nowrap'
  },
  fieldRowProps: {
    justifyContent: 'center',
    alignItems: 'center',
    py: '4px',
    mb: 2
  },
  imageStyles: {
    height: '120px',
    paddingBottom: '120px'
  }
}

const TitleStyled = (props) => {
  const { colorBgContainer } = theme.useToken().token
  const titleStyles = { color: colorBgContainer, textAlign: 'center' }

  return <Typography.Title {...props} style={titleStyles}></Typography.Title>
}
export default styles
export { TitleStyled }
