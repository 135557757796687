import { Button, Divider, Tooltip, Typography } from 'antd'
import { useStateWithStorage, useTranslations } from 'hooks'

import { Fragment } from 'react'
import { Icon } from 'components'
import { NotificationList } from 'domains/Notification/components'
import PropTypes from 'prop-types'
import { theme } from 'antd'
import { useUser } from 'modules/session-module/contexts'

const NotificationsContent = (props) => {
  const { notifications, setPopoverVisible } = props
  const { user } = useUser()
  const { colorPrimary } = theme.useToken().token
  const { t } = useTranslations()

  const [hideViewedNotifications, setHideViewedNotifications] =
    useStateWithStorage(true, 'hide-viewed-notifications')
  const handleHideViewedNotifications = () =>
    setHideViewedNotifications(!hideViewedNotifications)

  // function to filter notification if hideViewedNotifications is true then do not show notifications where receivers.${user?._id}`, '==', true
  const filterNotifications = () => {
    if (hideViewedNotifications) {
      return notifications?.filter(
        (notification) => !notification.receivers?.[user?._id]
      )
    }
    return notifications
  }
  const filteredNotifications = filterNotifications(notifications)
  return (
    <Fragment>
      <div
        className="flex justify-between align-center px-12 py-16"
        style={{ width: '340px' }}>
        <Typography.Title level={5}>{t('Notifications')}</Typography.Title>
        <Tooltip
          title={
            hideViewedNotifications ? t('Show all') : t('Show unread only')
          }>
          <Button
            onClick={handleHideViewedNotifications}
            type="text"
            icon={
              <Icon
                size={20}
                name={'FilterLinesOutlined'}
                fill={hideViewedNotifications ? colorPrimary : 'currentColor'}
              />
            }
          />
        </Tooltip>
      </div>
      <Divider className="my-0" />
      <NotificationList
        span={24}
        notifications={filteredNotifications}
        setPopoverVisible={setPopoverVisible}
      />
    </Fragment>
  )
}
NotificationsContent.propTypes = {
  collapsed: PropTypes.bool,
  notifications: PropTypes.array,
  setPopoverVisible: PropTypes.func
}

export default NotificationsContent
