import { EnumSelect, FormWrapper } from 'components'

import { Form } from 'antd'
import { KEYS } from '__constants__'
import ORDER_STATUSES_OPTIONS from '__constants__/enums/orderStatuses'
import PropTypes from 'prop-types'
import { useTranslations } from 'hooks'

const OrderSimpleForm = (props) => {
  const { initialValues, onSubmit, onCancel, showTitle, form, group } = props

  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting new form instance from Form.useForm hook */
  const [baseForm] = Form.useForm()

  /* Getting used form from props or using base form */
  const usedForm = form || baseForm

  /* Resetting form on cancel */
  const handleCancel = () => {
    usedForm.resetFields()
    onCancel?.()
  }
  /* Submitting form on enter key press */
  const handleKeyPress = (e) => {
    if (e.key === KEYS.ENTER) {
      e.preventDefault()
      e.stopPropagation()
      usedForm?.submit()
    }
  }

  /* Generating form field names */
  const getFieldName = (subGroup) => (group ? [...group, subGroup] : [subGroup])

  return (
    <FormWrapper
      title={t('Order')}
      onFinish={onSubmit}
      initialValues={initialValues}
      onKeyPress={handleKeyPress}
      onReset={handleCancel}
      showTitle={showTitle}
      form={usedForm}
      group={group}>
      <Form.Item
        data-area="order-status"
        label={t('Status')}
        name={getFieldName('status')}>
        <EnumSelect
          placeholder={t('Please enter your order status')}
          options={ORDER_STATUSES_OPTIONS}
        />
      </Form.Item>
    </FormWrapper>
  )
}

OrderSimpleForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  showTitle: PropTypes.bool,
  form: PropTypes.any,
  group: PropTypes.array
}

export default OrderSimpleForm
