const NOTIFICATION_STATUSES = {
  SEND: 'SEND',
  PENDING: 'PENDING',
  READ: 'READ'
}

const NOTIFICATION_STATUSES_LABELS = {
  SEND: 'Send',
  PENDING: 'Pending',
  READ: 'Read'
}

const NOTIFICATION_STATUSES_OPTIONS = Object.keys(
  NOTIFICATION_STATUSES_LABELS
).map((key) => ({ label: NOTIFICATION_STATUSES[key], value: key }))

export default NOTIFICATION_STATUSES
export {
  NOTIFICATION_STATUSES_OPTIONS,
  NOTIFICATION_STATUSES,
  NOTIFICATION_STATUSES_LABELS
}
