import { Breadcrumb, Typography } from 'antd'
import { Link, generatePath } from 'react-router-dom'
import { StyledBreadCrumb, StyledLink } from './CategoryBreadcrumbs.styled'

import { Icon } from '@qonsoll/icons'
import { PATHS } from '__constants__'
import PropTypes from 'prop-types'
import { useExtraCategoryBreadcrumbsItems } from './hooks'
import { useTranslations } from 'hooks'

/**
 * It returns a breadcrumb component with a link to the home page and a link to the current page
 * @returns A breadcrumb component with a link to the home page and any extra breadcrumbs that are
 * passed in.
 */
const CategoryBreadcrumbs = (props) => {
  const { categoryId, productId } = props
  const { language } = useTranslations()

  /* Getting extra breadcrumbs */
  const [extraBreadcrumbItems] = useExtraCategoryBreadcrumbsItems({
    categoryId,
    productId
  })

  return (
    <StyledBreadCrumb
      separator={<Icon name="ArrowShortRightFilled" fill="currentColor" />}>
      <Breadcrumb.Item key={'/'}>
        <StyledLink to="/">
          <Icon name="Home3Outlined" fill="currentColor" />
        </StyledLink>
      </Breadcrumb.Item>

      {extraBreadcrumbItems?.map(({ _id, name, names }, index) => {
        const path = generatePath(
          PATHS.AUTHENTICATED.PRODUCTS_ALL_BY_CATEGORY,
          { categoryId: _id }
        )

        return (
          <Breadcrumb.Item
            key={_id}
            active={index === extraBreadcrumbItems.length - 1}>
            {index === extraBreadcrumbItems.length - 1 ? (
              <Typography.Text>
                {names?.[language.toUpperCase()] || name}
              </Typography.Text>
            ) : (
              <Link to={path}>{names?.[language.toUpperCase()] || name}</Link>
            )}
          </Breadcrumb.Item>
        )
      })}
    </StyledBreadCrumb>
  )
}

CategoryBreadcrumbs.propTypes = {
  categoryId: PropTypes.string,
  productId: PropTypes.string
}

export default CategoryBreadcrumbs
