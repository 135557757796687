import { Input } from 'antd'
import PropTypes from 'prop-types'

const CardNumberInput = (props) => {
  const { value, onChange, ...rest } = props

  const handleChange = (e) => {
    const stringWithoutSpace = e.target.value?.replaceAll(' ', '')
    const numberReg = /^\d*(\.\d*)?$/

    const isNumberValue = !numberReg.test(stringWithoutSpace)

    if (isNumberValue) return

    const transformedString = stringWithoutSpace
      .replace(/(\d{4})/g, '$1 ')
      ?.trim()

    onChange?.(transformedString)
  }

  return <Input value={value} onChange={handleChange} {...rest} />
}

CardNumberInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.bool
}

export default CardNumberInput
