import { Breadcrumbs, PageActions, PageLayout, Spinner } from 'components'
import { COLLECTIONS, PATHS } from '__constants__'
import { useNavigate, useParams } from 'react-router-dom'

import { useListenDocument } from 'services/api/firebase'
import { useTranslations } from 'hooks'
import { ComplaintAdvancedView } from 'domains/Complaint/components'

const ComplaintShow = (props) => {
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting navigate instance */
  const navigate = useNavigate()

  /* Getting params from router */
  const params = useParams()

  /* Fetching document data from useDocument hook */
  const [complaint, loading] = useListenDocument({
    ref: COLLECTIONS.COMPLAINTS + '/' + params.complaintId
  })

  /* Configuring page actions */
  const actions = { edit: !!PATHS.AUTHENTICATED.ORDER_EDIT }

  /* Function to handle back click */
  const handleBack = () => navigate(-1)

  return (
    <PageLayout
      actions={<PageActions actions={actions} />}
      onBack={handleBack}
      breadcrumbs={<Breadcrumbs />}
      area="order">
      {loading ? (
        <Spinner height="70vh" text={t('Order loading')} />
      ) : (
        <div
          style={{ position: 'relative' }}
          className="full-height full-width mb-24">
          <ComplaintAdvancedView complaint={complaint} isSmallPhone />
        </div>
      )}
    </PageLayout>
  )
}

export default ComplaintShow
