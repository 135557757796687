import { useMemo, useState } from 'react'

const useMyProductsActions = ({
  draftProducts,
  publishedProducts,
  blockedProducts,
  isDraftsPage,
  isBlockedPage
}) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)

  // Calculate the start and end index of the current page
  const startIndex = (currentPage - 1) * pageSize
  const endIndex = startIndex + pageSize
  // Get the current page items
  const productsForShowing = useMemo(() => {
    const computedProducts = isBlockedPage
      ? blockedProducts
      : isDraftsPage
      ? draftProducts
      : publishedProducts

    return computedProducts?.slice(startIndex, endIndex)
  }, [
    draftProducts,
    endIndex,
    isDraftsPage,
    publishedProducts,
    startIndex,
    isBlockedPage,
    blockedProducts
  ])

  const total = useMemo(
    () =>
      isBlockedPage
        ? blockedProducts?.length
        : isDraftsPage
        ? draftProducts?.length
        : publishedProducts?.length,
    [
      blockedProducts?.length,
      draftProducts?.length,
      publishedProducts?.length,
      isBlockedPage,
      isDraftsPage
    ]
  )

  const handlePageChange = (page, newPageSize) => {
    if (pageSize !== newPageSize) {
      setPageSize(newPageSize)
      setCurrentPage(1)
    } else {
      setCurrentPage(page)
    }
  }

  return {
    handlePageChange,
    currentPage,
    pageSize,
    setPageSize,
    setCurrentPage,
    total,
    productsForShowing
  }
}
export default useMyProductsActions
