import { Typography, theme } from 'antd'
import { useEffect, useState } from 'react'

import { Icon } from '@qonsoll/icons'
import PropTypes from 'prop-types'
import { RateStyled } from './RateSelect.styled'

const rateSizes = { sm: '14px', md: '16px', lg: '20px' }

const RateSelect = (props) => {
  const {
    size = 'md',
    value = 0,
    onRatingUpdate,
    allowClear = true,
    allowHalf = false,
    tooltipDesc = ['terrible', 'bad', 'normal', 'good', 'wonderful'],
    mb,
    document,
    isRentee,
    disabled,
    onChange
  } = props

  const { colorWarningBorderHover } = theme.useToken().token
  const [rating, setRating] = useState(value || null)

  const handleChange = (value) => {
    const newRating = value
    setRating(value)
    onRatingUpdate?.(document?._id || document, newRating)
    onChange?.(value)
  }

  useEffect(() => {
    if (value) {
      setRating(value)
    }
  }, [value])

  return (
    <div className="flex gap-4 align-center">
      {isRentee ? (
        <RateStyled
          disabled={disabled}
          allowClear={allowClear}
          allowHalf={allowHalf}
          tooltips={tooltipDesc}
          onChange={handleChange}
          size={rateSizes[size] || rateSizes.md}
          value={rating}
          mb={mb}
        />
      ) : (
        <Icon
          name={'StarFilled'}
          size={18}
          fill={colorWarningBorderHover}
          className="flex align-center"
        />
      )}
      <Typography.Text>{value || 0}</Typography.Text>
    </div>
  )
}

RateSelect.propTypes = {
  value: PropTypes.any,
  document: PropTypes.object,
  allowClear: PropTypes.bool,
  isRentee: PropTypes.bool,
  allowHalf: PropTypes.bool,
  disabled: PropTypes.bool,
  tooltipDesc: PropTypes.array,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  onRatingUpdate: PropTypes.func,
  mb: PropTypes.number,
  onChange: PropTypes.func
}
export default RateSelect
