import { CategoryButton, ProductList } from 'domains/Product/components'
import { Col, Empty, Pagination, Row, Typography } from 'antd'
import { generatePath, useNavigate } from 'react-router-dom'
import { memo, useCallback } from 'react'
import { useScreen, useTranslations } from 'hooks'

import { PATHS } from '__constants__'
import PropTypes from 'prop-types'
import { StyledSpace } from './ProductsAllView.styled'
import { useHorizontalScroll } from 'hooks'

const ProductsAllView = (props) => {
  const {
    products,
    totalResults,
    currentPage,
    onPageChange,
    topLevelCategories
  } = props
  const { isExtraSmallScreen } = useScreen()
  const { t } = useTranslations()
  const navigate = useNavigate()

  const [scrollRef, handleMouseEnter, handleMouseLeave] = useHorizontalScroll()
  const handleOpenSubcategory = useCallback(
    (categoryId) => () => {
      const path = generatePath(PATHS.AUTHENTICATED.PRODUCTS_ALL_BY_CATEGORY, {
        categoryId
      })

      navigate(path)
    },
    [navigate]
  )
  const heightToNegotiate = isExtraSmallScreen
    ? 'calc(100vh - 242px)'
    : 'calc(100vh - 198px)'
  return (
    <Row gutter={24} className="flex-1 flex-nowrap">
      <Col flex="auto">
        <StyledSpace
          ref={scrollRef}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          className="full-width max100 x-scroll gap-8 mb-24">
          {topLevelCategories?.map((category) => (
            <CategoryButton
              category={category}
              key={`${category?._id}-button`}
              handleOpen={handleOpenSubcategory}
              isTopLevel
            />
          ))}
        </StyledSpace>
        <>
          {products?.length ? (
            <ProductList
              maxHeight={heightToNegotiate}
              products={products}
              span={{ xs: 24, sm: 24, md: 12, lg: 12, xl: 8, xxl: 6 }}
              pagination={
                <Pagination
                  style={{ justifyContent: 'center', display: 'flex' }}
                  defaultCurrent={1}
                  total={totalResults}
                  pageSize={8}
                  current={currentPage}
                  onChange={onPageChange}
                />
              }
            />
          ) : (
            <Empty
              description={
                <Typography.Text type="secondary">
                  {t("There aren't products for current category")}
                </Typography.Text>
              }
            />
          )}
        </>
      </Col>
    </Row>
  )
}

ProductsAllView.propTypes = {
  category: PropTypes.object,
  products: PropTypes.array,
  totalResults: PropTypes.number,
  currentPage: PropTypes.number,
  onPageChange: PropTypes.func,
  topLevelCategories: PropTypes.array
}

export default memo(ProductsAllView)
