import { useEffect, useState } from 'react'

import firebase from 'firebase/compat/app'

const useListenDataFromRDB = (path) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [data, setData] = useState(null)

  useEffect(() => {
    if (path) {
      const ref = firebase.database().ref(path)
      try {
        ref.on('value', (snapshot) => {
          const reviewData = snapshot.val()
          const reviewArray = reviewData
          setData(reviewArray)
        })
      } catch (error) {
        setError(error)
      } finally {
        setLoading(false)
      }
      return () => {
        ref?.off('value')
      }
    }
  }, [path])

  return [data, loading, error]
}

export default useListenDataFromRDB
