import {
  AuthNavigator,
  RoutesRedirect
} from 'modules/session-module/navigators'
import { Route, Routes } from 'react-router-dom'

import AppNavigator from '../AppNavigator'
import ServiceNavigator from '../ServiceNavigator'
import { VippsCallback } from 'modules/session-module/pages/Auth'

// Top-level navigator
const Navigator = () => {
  return (
    <RoutesRedirect>
      <Routes>
        <Route path="/vipps-callback" element={<VippsCallback />} />
        <Route path="/*" element={<AppNavigator />} />
        <Route path="auth/*" element={<AuthNavigator />} />
        <Route path="service/*" element={<ServiceNavigator />} />
      </Routes>
    </RoutesRedirect>
  )
}

export default Navigator
