import { AccountContainer, verificationIconStyle } from './AccountMenu.styled'
import { Avatar, Col, Row, Typography, theme } from 'antd'

import { Icon } from 'components'
import PropTypes from 'prop-types'
import { useUser } from 'modules/session-module/contexts'

const AccountMenu = (props) => {
  const { collapsed, noOffsets } = props

  const { fontSizeSM, colorPrimary } = theme.useToken().token
  const { user } = useUser()

  const { avatarUrl, firstName = '', lastName = '', email } = user || {}

  return (
    <AccountContainer noOffsets={noOffsets}>
      <Row
        justify="center"
        align="middle"
        wrap={false}
        style={{ cursor: 'pointer' }}
        gutter={8}>
        <Col>
          <div className="relative">
            <Avatar
              className="flex align-center justify-center"
              src={avatarUrl}
              size={40}
              icon={<Icon name="User1Outlined" />}
            />
            {user?.isVerified && (
              <div className="absolute" style={verificationIconStyle}>
                <Icon
                  name="CheckVerified2Filled"
                  size={16}
                  fill={colorPrimary}
                />
              </div>
            )}
          </div>
        </Col>
        {!collapsed && (
          <Col flex="auto" className="flex flex-col">
            <Typography.Text
              ellipsis>{`${firstName} ${lastName}`}</Typography.Text>
            <Typography.Text
              type="secondary"
              ellipsis
              style={{ fontSize: fontSizeSM }}>
              {email}
            </Typography.Text>
          </Col>
        )}
      </Row>
    </AccountContainer>
  )
}

AccountMenu.propTypes = {
  collapsed: PropTypes.bool,
  noOffsets: PropTypes.bool,
  children: PropTypes.node,
  noDropdownMenu: PropTypes.bool
}

export default AccountMenu
