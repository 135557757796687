import { useEffect, useState } from 'react'

import { COLLECTIONS, COMPLAINT_STATUSES } from '__constants__'
import firebase from 'firebase/compat/app'

/**
 * A custom hook that checks if there are any unresolved complaints for a given product ID.
 *
 * @param {string} productId - The ID of the product to check for unresolved complaints.
 * @returns {boolean|null} - Returns a boolean value indicating whether there are any unresolved complaints for the given product ID. Returns null if the check is still in progress.
 */
const useGetUnresolvedComplaintByProductId = (productId) => {
  const [isExistUnresolvedComplaint, setIsExistUnresolvedComplaint] =
    useState(null)

  useEffect(() => {
    const checkProductCompliance = async () => {
      const snap = await firebase
        .firestore()
        .collection(COLLECTIONS.COMPLAINTS)
        .where('productId', '==', productId)
        .get()

      const complaints = snap.docs.map((doc) => doc.data())
      const result = complaints.filter(
        (item) =>
          item.status === COMPLAINT_STATUSES.PENDING ||
          item.status === COMPLAINT_STATUSES.ON_REVIEW
      )

      setIsExistUnresolvedComplaint(result?.length > 0)
    }

    checkProductCompliance()
  }, [productId])

  return isExistUnresolvedComplaint
}

export default useGetUnresolvedComplaintByProductId
