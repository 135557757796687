import moment from 'moment'

const calculateProductPriceByDays = (obj, product) => {
  if (!obj?.startDate || !obj?.endDate) {
    return []
  }

  const getDate = (date) => {
    const parsedDate = Date?.parse(date)
    // Convert milliseconds to seconds
    return Number(parsedDate) ? parsedDate * 0.001 : date?.seconds
  }

  const computeTotalDays = (startDate, endDate) =>
    moment.unix(endDate).add(1, 'day').diff(moment.unix(startDate), 'd')

  const computeTotalPrice = (totalDays, pricePerDay) => totalDays * pricePerDay

  const startDate = getDate(obj.startDate)
  const endDate = getDate(obj?.endDate)

  const totalDays = computeTotalDays(startDate, endDate)
  const totalPrice = computeTotalPrice(totalDays, product?.pricePerDay)

  return [totalPrice, totalDays]
}

export default calculateProductPriceByDays
