import { Button, Image, Space, Typography, notification, theme } from 'antd'
import { Fragment, useCallback, useEffect } from 'react'
import { TextStyled, styles } from './ConfirmEmailAdvancedView.styled.js'
import {
  useEvent,
  useHandleError,
  useTimerByDate,
  useTranslations
} from 'hooks'

import IllustrationConfirmEmail from '__assets__/illustrations/confirm-email.svg'
import { LS } from '__constants__'
import PropTypes from 'prop-types'
import firebase from 'firebase/compat/app'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useNavigate } from 'react-router-dom'
import { useSessionActions } from 'modules/session-module/hooks'

const leftSeconds = { hours: 0, minutes: 1, seconds: 1 }

const ConfirmEmailAdvancedView = (props) => {
  const { withRedirect, verifyEmailCallback } = props

  const { colorPrimaryText, fontSizeSM } = theme.useToken().token

  const { t } = useTranslations()
  const navigate = useNavigate()
  const onError = useHandleError()
  const { sendEmailVerification } = useSessionActions()
  const [user] = useAuthState(firebase.auth())

  const [counter, setLeftSeconds, isSendedMail, setIsSendedMail] =
    useTimerByDate(LS.LAST_SENDED_MAIL)

  const handleSendEmailVerification = useEvent(() => {
    setLeftSeconds({ hours: 0, minutes: 1, seconds: 1 })
    sendEmailVerification(onError)
    setIsSendedMail(true)

    localStorage.setItem(
      LS.LAST_SENDED_MAIL,
      JSON.stringify({ time: new Date(), leftSeconds })
    )
  })

  /* The function that recursively runs every second after sending a verification email,
  and stops once the email has been confirmed */
  const confirmEmail = useCallback(() => {
    if (!user) return
    user.reload()
    if (user?.emailVerified) {
      verifyEmailCallback?.()
      notification.success({
        key: 'emailVerifiedMessage',
        message: t('Email has been verified'),
        duration: 2
      })
    } else {
      setTimeout(confirmEmail, 1000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, t, user, withRedirect])

  useEffect(() => {
    !user?.emailVerified && setTimeout(confirmEmail, 1000)
  })

  const counterMinutes = counter?.minutes > 0
  const counterSeconds = counter?.seconds > 0

  return (
    <Fragment>
      <Space {...styles.infoSpace}>
        <Image {...styles.image} src={IllustrationConfirmEmail} />

        <Typography.Title level={2}>{t('Verify your email')}</Typography.Title>

        <div {...styles.descriptionWrapper}>
          <TextStyled fontSize={fontSizeSM}>
            {`${t("We've sent a verification link to your inbox")} `}
          </TextStyled>
          <TextStyled fontSize={fontSizeSM} color={colorPrimaryText} strong>
            {user?.email}
          </TextStyled>
          <TextStyled fontSize={fontSizeSM}>
            {`. ${t(
              'Verify your email address to access all available features'
            )}. `}
          </TextStyled>
        </div>
      </Space>

      <div>
        <Space {...styles.actionsSpace}>
          {isSendedMail ? (
            <div className="py-4">
              <Typography.Text>
                {t('Email verification was sent')}
              </Typography.Text>
            </div>
          ) : (
            <Button onClick={handleSendEmailVerification} type="primary">
              {t('Send email verification')}
            </Button>
          )}
          <div display="flex">
            <Typography.Text type="secondary" className="pr-4">
              {t('dontReceiveEmail')}
            </Typography.Text>

            {counterSeconds || counterMinutes ? (
              <Typography.Text>
                {counter?.minutes}:{counter?.seconds}
              </Typography.Text>
            ) : (
              <Typography.Link
                type="link"
                disabled={!isSendedMail}
                onClick={handleSendEmailVerification}>
                {t('Resend email')}
              </Typography.Link>
            )}
          </div>
        </Space>
      </div>
    </Fragment>
  )
}

ConfirmEmailAdvancedView.propTypes = {
  withRedirect: PropTypes.bool,
  verifyEmailCallback: PropTypes.func
}

export default ConfirmEmailAdvancedView
