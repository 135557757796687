import { Button, Divider, Space, Typography } from 'antd'
import { DrawerOverlay, DrawerSide, Spinner } from 'components'

import { Icon } from '@qonsoll/icons'
import PropTypes from 'prop-types'
import { ReviewAdvancedForm } from 'domains/Review/components'
import styles from './OrderReviewSideDrawer.styled'
import { useGetOrderReviewWithRating } from 'domains/Order/hooks'
import { useTranslations } from 'hooks'

const OrderReviewSideDrawer = (props) => {
  const { reviewDrawerProps, onReviewSideDrawerClose } = props
  const { order, visibility } = reviewDrawerProps || {}
  const { t } = useTranslations()

  const [review, rating, loading] = useGetOrderReviewWithRating(order)

  return (
    <>
      <DrawerSide isOpen={visibility} zIndex="1002">
        <Space size={16}>
          <Button
            type="text"
            className="flex align-center justify-center"
            icon={<Icon name="XCloseOutlined" size={24} />}
            onClick={onReviewSideDrawerClose}
          />
          <Typography.Title level={5}>{t('Product review')}</Typography.Title>
        </Space>
        <Divider className="mt-16 mb-24" style={styles.fullWidthDivider} />
        {!loading ? (
          <ReviewAdvancedForm
            submitText={t('Save review')}
            afterSubmitCallback={onReviewSideDrawerClose}
            rating={rating}
            initialData={review?.[0]}
            productId={order?.product?._id}
            orderId={order?._id}
          />
        ) : (
          <Spinner height="70vh" />
        )}
      </DrawerSide>
      <DrawerOverlay
        isOpen={visibility}
        onClick={onReviewSideDrawerClose}
        zIndex="1001"
      />
    </>
  )
}

OrderReviewSideDrawer.propTypes = {
  reviewDrawerProps: PropTypes.object,
  onReviewSideDrawerClose: PropTypes.func
}

export default OrderReviewSideDrawer
