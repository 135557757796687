import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Dropdown, Menu, theme } from 'antd'

import { Box } from 'components'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslations } from 'hooks'

/**
 * It renders a box with a dropdown menu that can be used to edit or delete the document
 * @param props - The props that are passed to the component.
 * @returns A CardDropdown component that is a wrapper for the children passed in.
 */
const CardDropdown = (props) => {
  const {
    onEdit,
    onDelete,
    children,
    onDoubleClick,
    onClick,
    onUnpublish,
    document,
    index,
    extraItems,
    wrapperStyles,
    className,
    ...rest
  } = props

  const { borderRadiusLG } = theme.useToken().token
  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()

  // [HANDLERS]
  const handleEdit = (e) => {
    e.domEvent?.stopPropagation()
    onEdit?.(document, index)
  }
  const handleDelete = (e) => {
    e.domEvent?.stopPropagation()
    onDelete?.(document, index)
  }

  // [COMPUTED_PROPERTIES]
  const hasDropdownActions = useMemo(
    () => onEdit || onDelete,
    [onEdit, onDelete]
  )
  const Wrapper = useMemo(
    () => (hasDropdownActions ? Dropdown : Box),
    [hasDropdownActions]
  )
  const cursorComputed = useMemo(
    () => (onDoubleClick || onClick ? 'pointer' : 'default'),
    [onDoubleClick, onClick]
  )

  const computedExtraItems = useMemo(
    () =>
      extraItems?.length
        ? extraItems?.map(({ key, icon, label, onClick }) => (
            <Menu.Item key={key} icon={icon} onClick={onClick}>
              {label}
            </Menu.Item>
          ))
        : [],
    [extraItems]
  )
  const wrapperProps = hasDropdownActions
    ? {
        trigger: ['contextMenu'],
        overlay: (
          <Menu>
            {onEdit && (
              <Menu.Item
                key="edit"
                icon={<EditOutlined />}
                onClick={handleEdit}>
                {t('Edit')}
              </Menu.Item>
            )}

            {computedExtraItems}

            {onDelete && (
              <Menu.Item
                key="delete"
                icon={<DeleteOutlined />}
                danger
                onClick={handleDelete}>
                {t('Delete')}
              </Menu.Item>
            )}
          </Menu>
        )
      }
    : {}
  return (
    <Wrapper {...wrapperProps}>
      <Box
        style={{
          height: '100%',
          cursor: cursorComputed,
          borderRadius: borderRadiusLG,
          overflow: 'hidden',
          ...wrapperStyles
        }}
        onDoubleClick={onDoubleClick}
        onClick={onClick}
        {...rest}>
        {children}
      </Box>
    </Wrapper>
  )
}

CardDropdown.propTypes = {
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onDoubleClick: PropTypes.func,
  onUnpublish: PropTypes.func,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  document: PropTypes.object.isRequired,
  index: PropTypes.number,
  extraItems: PropTypes.array,
  wrapperStyles: PropTypes.object,
  className: PropTypes.string
}

export default CardDropdown
