import {
  ORDER_STATUSES_LABELS,
  PAYMENT_STATUSES,
  PAYMENT_STATUSES_LABELS
} from '__constants__'
import {
  OrderActions,
  OrderPaymentStatus,
  OrderRentingDays,
  OrderStatus
} from 'domains/Order/components'
import {
  OrderPrice,
  OrderProduct
} from 'domains/Order/components/OrderList/components'
import { Typography, theme } from 'antd'
import { useGetTableSearchProps, useTranslations } from 'hooks'
import { useMemo, useState } from 'react'

import { useUser } from 'modules/session-module/contexts'

const useGetOrdersTableConfig = (orders, onReviewSideDrawerOpen) => {
  const [pageSize, setPageSize] = useState(6)
  const { renteeTableYScroll, renterTableYScroll } = theme.useToken().token
  const { t } = useTranslations()
  const { isRentee } = useUser()
  const noData = useMemo(() => <Typography.Text>n/a</Typography.Text>, [])
  const getColumnSearchProps = useGetTableSearchProps({
    placeholder: t('Search by product name or order number')
  })

  const tableYScroll = useMemo(
    () => (isRentee ? renteeTableYScroll : renterTableYScroll),
    [isRentee, renteeTableYScroll, renterTableYScroll]
  )
  const columns = useMemo(
    () => [
      {
        width: '270px',
        title: t('Product'),
        dataIndex: 'productName',
        key: 'product',
        fixed: 'left',
        ...getColumnSearchProps('productName', ['productName', 'orderNumber']),
        render: (_, record) => <OrderProduct order={record} width="560px" />
      },
      {
        width: '180px',
        title: t('Dates'),
        dataIndex: 'dates',
        key: 'dates',
        sorter: (a, b) =>
          (a?.startDate?.seconds || 0) - (b?.startDate?.seconds || 0),
        render: (_, record) => <OrderRentingDays order={record} />
      },
      {
        width: '110px',
        title: t('Price'),
        dataIndex: 'price',
        key: 'price',
        sorter: (a, b) => a.totalPrice - b.totalPrice,
        render: (_, record) => <OrderPrice order={record} noData={noData} />
      },
      {
        width: '120px',
        title: t('Payment status'),
        dataIndex: 'paymentStatus',
        key: 'paymentStatus',
        filters: Object.keys(PAYMENT_STATUSES_LABELS).map((key) => ({
          text: PAYMENT_STATUSES_LABELS[key],
          value: key
        })),
        onFilter: (value, record) => {
          const computedPaymentStatus =
            record?.paymentStatus || PAYMENT_STATUSES.UNPAID
          return computedPaymentStatus.indexOf(value) === 0
        },
        render: (_, record) => (
          <OrderPaymentStatus
            paymentStatus={record?.paymentStatus || PAYMENT_STATUSES.UNPAID}
            noData={noData}
          />
        )
      },
      {
        width: '100px',
        title: t('Status'),
        dataIndex: 'status',
        key: 'status',
        filters: Object.keys(ORDER_STATUSES_LABELS).map((key) => ({
          text: ORDER_STATUSES_LABELS[key],
          value: key
        })),
        onFilter: (value, record) => record?.status.indexOf(value) === 0,
        render: (_, record) => <OrderStatus order={record} noData={noData} />
      },

      {
        title: t('Actions'),
        width: '220px',
        dataIndex: 'actions',
        key: 'actions',
        align: 'right',
        render: (_, record) => (
          <OrderActions
            order={record}
            onReviewFormToggle={onReviewSideDrawerOpen}
          />
        )
      }
    ],
    [getColumnSearchProps, noData, onReviewSideDrawerOpen, t]
  )

  const paginationProps = {
    hideOnSinglePage: true,
    position: ['bottomLeft'],
    pageSize: pageSize,
    pageSizeOptions: [6, 10, 20, 50, 100],
    total: orders?.length,
    showSizeChanger: true,
    showQuickJumper: true,
    onShowSizeChange: (_, pageSize) => setPageSize(pageSize)
  }
  const tableProps = {
    scroll: {
      x: 1018,
      y: tableYScroll
    },
    tableLayout: 'fixed'
  }

  return {
    columns,
    paginationProps,
    tableProps
  }
}

export default useGetOrdersTableConfig
