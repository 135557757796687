import { APP_PATHS } from '__constants__'
import { Button } from 'antd'
import { Icon } from '@qonsoll/icons'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { useTranslations } from 'hooks'

const ProfileButton = (props) => {
  const { collapsed } = props
  const { t } = useTranslations()
  const navigate = useNavigate()

  const navigateToMyProfile = () => navigate(APP_PATHS.MY_PROFILE)

  return (
    <div className="px-4 full-width">
      <Button
        style={{ height: '40px' }}
        block
        type="text"
        onClick={navigateToMyProfile}
        className="flex align-center justify-start px-24">
        <Icon name="User3Outlined" size={16} mr={2} fill="currentColor" />
        {!collapsed ? t('My Profile') : null}
      </Button>
    </div>
  )
}

ProfileButton.propTypes = {
  collapsed: PropTypes.bool
}

export default ProfileButton
