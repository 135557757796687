import { COLLECTIONS, SETTINGS_PATHS } from '__constants__'
import { generatePath, useNavigate } from 'react-router-dom'
import { message, notification } from 'antd'
import { useEvent, useTranslations } from 'hooks'

import { deleteDocument } from 'services/api/firebase'
import { useMemo } from 'react'

/* Path's */
const createPath = SETTINGS_PATHS.ADDRESS_CREATE
const editPath = SETTINGS_PATHS.ADDRESS_EDIT

const useAddressBaseActions = ({ addressId, userId, actions } = {}) => {
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting navigate instance */
  const navigate = useNavigate()

  /* Function to handle back click */
  const handleBack = useEvent(() => navigate(-1))

  /* Handling opening of the create page */
  const handleCreate = useEvent(() => {
    navigate(createPath)
  })

  /* Handling opening of the show page */
  const handleOpen = useEvent(() => {
    const pathParams = { addressId }
    const path = generatePath(editPath, pathParams)
    navigate(path)
  })

  /* Handling opening of the edit page or change view to edit mode */
  const handleEdit = useEvent(() => {
    const pathParams = { addressId }
    const path = generatePath(editPath, pathParams)
    navigate(path)
  })

  const handleDelete = useEvent(async () => {
    try {
      await deleteDocument(COLLECTIONS.ADDRESSES, addressId)
      message.success(t('Address successfully deleted'))
    } catch (error) {
      notification.error(error.message)
    }
  })

  return useMemo(
    () => ({
      onBack: handleBack,
      onCreate: handleCreate,
      onOpen: handleOpen,
      onDelete: handleDelete,
      onEdit: handleEdit,
      ...(actions || {}),
      createPath,
      editPath
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      actions?.onBack,
      actions?.onCreate,
      actions?.onDelete,
      actions?.onEdit,
      actions?.onOpen,
      handleBack,
      handleCreate,
      handleDelete,
      handleOpen,
      handleEdit
    ]
  )
}

export default useAddressBaseActions
