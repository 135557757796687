import { Col, Divider, Row } from 'antd'
import { useMemo, useState } from 'react'

import { SettingsVerificationSimpleView } from 'domains/Verification/components'
import { useGetSettingsVerificationListItem } from 'domains/Verification/hooks'

const SettingsVerificationList = () => {
  const [openModal, setOpenModal] = useState(false)

  const showModal = (title) =>
    title ? setOpenModal({ isOpen: true, title }) : null
  const closeModal = () => setOpenModal(false)

  const [verificationItems] = useGetSettingsVerificationListItem({
    setOpenModal
  })

  const lastElement = useMemo(
    () => verificationItems?.length - 1,
    [verificationItems?.length]
  )

  const verificationListItem = useMemo(
    () =>
      verificationItems?.map(({ title, content, ...rest }, index) => {
        const isOpenModal = openModal?.isOpen && openModal?.title === title
        return (
          <Col span={24} key={`verification-item${index}`}>
            <SettingsVerificationSimpleView
              title={title}
              isOpenModal={isOpenModal}
              showModal={showModal}
              closeModal={closeModal}
              {...rest}>
              {content}
            </SettingsVerificationSimpleView>
            {lastElement === index ? null : <Divider />}
          </Col>
        )
      }),
    [openModal?.isOpen, openModal?.title, verificationItems, lastElement]
  )

  return <Row>{verificationListItem}</Row>
}

export default SettingsVerificationList
