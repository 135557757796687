import { DELIVERY_TYPES_LABELS, MOMENT_FORMATS } from '__constants__'

import { AddressSimpleView } from 'domains/Address/components'
import { DottedBlock } from 'components'
import PropTypes from 'prop-types'
import { Typography } from 'antd'
import moment from 'moment'
import { useTranslations } from 'hooks'

const OrderPickupDetails = (props) => {
  const { order } = props
  const { t } = useTranslations()

  const date = moment.unix(order?.deliveryTime?.seconds)

  const deliveryTimeComp = date.isValid()
    ? date.format(MOMENT_FORMATS.HOURS_MINUTES)
    : 'n/a'

  return (
    <>
      <Typography.Title level={5} className="mb-12">
        {t('Pick-up details')}
      </Typography.Title>
      <DottedBlock
        className="mb-8"
        height="22px"
        left={<Typography.Text>{t('Method')}</Typography.Text>}
        right={
          <Typography.Text>
            {DELIVERY_TYPES_LABELS[order?.deliveryType]}
          </Typography.Text>
        }
      />
      <DottedBlock
        className="mb-12"
        height="22px"
        left={<Typography.Text>{t('Time')}</Typography.Text>}
        right={<Typography.Text>{deliveryTimeComp}</Typography.Text>}
      />
      <AddressSimpleView address={order?.address} onlyView />
    </>
  )
}

OrderPickupDetails.propTypes = {
  order: PropTypes.object
}

export default OrderPickupDetails
