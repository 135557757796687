import { HeaderMenu, SearchHeader, SearchHeaderDrawer } from './components'
import { matchPath, useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { APP_PATHS } from '__constants__'
import PropTypes from 'prop-types'
import { useScreen } from 'hooks'
import { useUser } from 'modules/session-module/contexts'

const { PRODUCT_IMAGES_ALL, CHECKOUT, DASHBOARD } = APP_PATHS

// List of paths where the left aside menu mist be hidden
const PAGES_WITH_SIMPLE_HEADER = [PRODUCT_IMAGES_ALL, CHECKOUT]

const Header = ({ collapsed }) => {
  const location = useLocation()
  const { xs } = useScreen()
  const { isRentee } = useUser()

  const [showSearchHeader, setShowSearchHeader] = useState()
  const [showSearchHeaderDrawer, setShowSearchHeaderDrawer] = useState()
  const [showHeaderMenu, setShowHeaderMenu] = useState()
  const [searchProduct, setSearchProduct] = useState()

  useEffect(() => {
    const hideSearchHeader = PAGES_WITH_SIMPLE_HEADER.some((path) =>
      matchPath(path, location.pathname)
    )

    const homePage = location?.pathname === DASHBOARD

    if (xs || homePage || !isRentee) {
      setShowSearchHeader(false)
    } else setShowSearchHeader(!hideSearchHeader)

    setShowHeaderMenu(xs)

    if (!xs) {
      setShowSearchHeaderDrawer(false)
    }
  }, [xs, location.pathname, isRentee])
  const handleShowSearch = () =>
    setShowSearchHeaderDrawer((prevValue) => !prevValue)

  return (
    <>
      {showHeaderMenu && <HeaderMenu handleShowSearch={handleShowSearch} />}
      {showSearchHeader && (
        <SearchHeader
          collapsed={collapsed}
          setSearchProduct={setSearchProduct}
          searchProduct={searchProduct}
          productSearchOptions
        />
      )}
      {/** Show Drawer only for xs media size*/}
      {xs && (
        <SearchHeaderDrawer
          isDrawerOpened={showSearchHeaderDrawer}
          setShowSearchHeaderDrawer={setShowSearchHeaderDrawer}
        />
      )}
    </>
  )
}

Header.propTypes = {
  collapsed: PropTypes.bool
}

export default Header
