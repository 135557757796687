const checkMatchingChats = ({ oppositeUsersChats, userId, userChats }) => {
  const isMatchInOpposite = oppositeUsersChats?.filter(
    (chat) => chat.oppositeUserId === userId
  )
  const isMatchInCurrent = userChats?.filter(
    (chat) => chat.oppositeUserId === userId
  )

  const result =
    isMatchInOpposite?.length || isMatchInCurrent?.length
      ? isMatchInOpposite?.[0]?._id || isMatchInCurrent?.[0]?._id
      : false

  return result
}

export default checkMatchingChats
