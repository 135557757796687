import PropTypes from 'prop-types'
import { Typography } from 'antd'
import { useGetCategory } from 'domains/Category/hooks'
import { useTranslations } from 'hooks'

const ProductCategory = (props) => {
  const { categoryId } = props
  const [category] = useGetCategory(categoryId)
  const { language } = useTranslations()

  return (
    <Typography.Text>
      {category?.names?.[language.toUpperCase()] || category?.name || 'n/a'}
    </Typography.Text>
  )
}

ProductCategory.propTypes = {
  categoryId: PropTypes.string
}

export default ProductCategory
