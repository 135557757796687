import { StyledMenu } from './CategoryModalTree.styled'

import { Divider } from 'antd'
import PropTypes from 'prop-types'
import {
  SubCategoriesList,
  CategoryMenuItem
} from 'domains/Category/components/CategoryTree/components'

const CategoryModalTree = (props) => {
  const {
    selectedCategoryId,
    setSelectedCategoryId,
    handleOpenCategory,
    topLevelCategories,
    categories
  } = props

  return (
    <>
      <StyledMenu
        triggerSubMenuAction="hover"
        mode="vertical"
        selectedKeys={[selectedCategoryId]}
        defaultSelectedKeys={[selectedCategoryId]}>
        {topLevelCategories?.map((category) => (
          <CategoryMenuItem
            key={category._id}
            category={category}
            setSelectedCategoryId={setSelectedCategoryId}
            handleOpenCategory={handleOpenCategory}
          />
        ))}
      </StyledMenu>
      <Divider
        type="vertical"
        className="mr-16 ml-0 full-height"
        height="100%"
      />

      {selectedCategoryId && (
        <SubCategoriesList
          isTree
          level={1}
          handleOpenCategory={handleOpenCategory}
          categories={categories}
          parentId={selectedCategoryId}
        />
      )}
    </>
  )
}
CategoryModalTree.propTypes = {
  selectedCategoryId: PropTypes.string,
  setSelectedCategoryId: PropTypes.func,
  handleOpenCategory: PropTypes.func,
  topLevelCategories: PropTypes.array,
  categories: PropTypes.array
}
export default CategoryModalTree
