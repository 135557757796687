import { Button } from 'antd'
import styled from 'styled-components'

const ButtonStyled = styled(Button)`
  border-radius: 12px;
  height: 100%;
  box-sizing: border-box;
  &:hover {
    border: 1px solid #d9d9d9 !important;
    box-shadow: 0 6px 8px 0 rgb(0 0 0 / 4%), 0 8px 8px -2px rgb(0 0 0 / 8%),
      0 16px 48px -16px rgb(0 0 0 / 12%) !important;
    transition: 0.2s !important;
  }
  &:active {
    box-shadow: none;
    border: 1px solid #d9d9d9 !important;
  }
  &:focus-visible {
    outline: none !important;
  }
`

export { ButtonStyled }
