import { DatePicker, Form, Input } from 'antd'
import { FormWrapper, PhoneInput } from 'components'

import { KEYS } from '__constants__'
import PropTypes from 'prop-types'
import { UserImageUploader } from 'domains/User/components'
import dayjs from 'dayjs'
import { styles } from './UserSimpleForm.styles'
import { useTranslations } from 'hooks'

const UserSimpleForm = (props) => {
  const { initialValues, onSubmit, onCancel, showTitle, form, group } = props

  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting new form instance from Form.useForm hook */
  const [baseForm] = Form.useForm()

  /* Getting used form from props or using base form */
  const usedForm = form || baseForm

  /* Resetting form on cancel */
  const handleCancel = () => {
    usedForm.resetFields()
    onCancel?.()
  }
  /* Submitting form on enter key press */
  const handleKeyPress = (e) => {
    if (e.key === KEYS.ENTER) {
      e.preventDefault()
      e.stopPropagation()
      usedForm?.submit()
    }
  }

  const dateFormat = 'DD-MMMM-YYYY'

  /* Generating form field names */
  const getFieldName = (subGroup) => (group ? [...group, subGroup] : [subGroup])

  const disabledDate = (current) => {
    // Disable future dates
    if (current && dayjs(current).isAfter(dayjs(), 'day')) {
      return true
    }

    // Disable dates, that were less than 13 years ago
    const thirteenYearsAgo = dayjs().subtract(13, 'years')
    return current && !dayjs(current).isBefore(thirteenYearsAgo, 'day')
  }

  return (
    <FormWrapper
      title={t('User')}
      onFinish={onSubmit}
      initialValues={initialValues}
      onKeyPress={handleKeyPress}
      onReset={handleCancel}
      showTitle={showTitle}
      form={usedForm}
      group={group}>
      <Form.Item data-area="user-avatar" name={getFieldName('avatarUrl')}>
        <UserImageUploader />
      </Form.Item>
      <Form.Item
        data-area="user-firstName"
        label={t('First name')}
        name={getFieldName('firstName')}
        rules={[
          { required: true, message: t('Please enter your user first name!') }
        ]}>
        <Input
          placeholder={t('Please enter your user first name')}
          size="default"
        />
      </Form.Item>
      <Form.Item
        data-area="user-lastName"
        label={t('Last name')}
        name={getFieldName('lastName')}
        rules={[
          { required: true, message: t('Please enter your user last name!') }
        ]}>
        <Input
          placeholder={t('Please enter your user last name')}
          size="default"
        />
      </Form.Item>
      <Form.Item
        data-area="user-birthday"
        label={t('Birthday')}
        name={getFieldName('birthday')}
        rules={[{ required: true, message: t('Please enter your birthday!') }]}>
        <DatePicker
          format={dateFormat}
          style={styles.datePicker}
          disabledDate={disabledDate}
        />
      </Form.Item>

      <Form.Item
        data-area="user-email"
        label={t('Email')}
        name={getFieldName('email')}
        rules={[
          { required: true, message: t('Please enter your user email!') },
          { type: 'mail', message: 'Please enter valid email' }
        ]}>
        <Input placeholder={t('Please enter your user email')} size="default" />
      </Form.Item>
      <Form.Item
        data-area="user-phone"
        label={t('Phone')}
        name={getFieldName('phone')}
        rules={[
          { required: true, message: t('Please enter your user phone!') }
        ]}>
        <PhoneInput
          specialLabel=""
          inputStyle={{ height: 32, borderRadius: 7 }}
          buttonStyle={{ borderBottomLeftRadius: 7, borderTopLeftRadius: 7 }}
          placeholder={t('Please enter your user phone')}
        />
      </Form.Item>
    </FormWrapper>
  )
}

UserSimpleForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  showTitle: PropTypes.bool,
  form: PropTypes.any,
  group: PropTypes.array
}

export default UserSimpleForm
