import { useCallback, useMemo, useState } from 'react'

import { Icon } from '@qonsoll/icons'

const useOrderSort = (orders) => {
  const [sortedOrders, setSortedOrders] = useState([])
  const [sortDirection, setSortDirection] = useState('asc')
  const [currSortField, setCurrSortField] = useState(null)

  const sortedOrdersPrepared = useMemo(
    () => (sortedOrders?.length ? sortedOrders : orders),
    [orders, sortedOrders]
  )
  const onSortDirChange = () =>
    setSortDirection((prev) => (prev === 'asc' ? 'desc' : 'asc'))

  /* Fields for sorting */
  const sortKeys = useMemo(
    () => ({
      Price: 'totalPrice',
      'Start date': 'startDate'
    }),
    []
  )
  /* Getting sort icon for field */
  const getSortIcon = useCallback(
    (key) => {
      const field = sortKeys[key]
      if (field === currSortField) {
        return sortDirection === 'asc' ? (
          <Icon name="ArrowLongUpFilled" />
        ) : (
          <Icon name="ArrowLongDownFilled" />
        )
      } else {
        return <Icon name="MinusOutlined" />
      }
    },
    [sortKeys, currSortField, sortDirection]
  )

  const getDate = (date) => {
    const parsedDate = Date?.parse(date)
    // Convert milliseconds to seconds
    return Number(parsedDate) ? parsedDate * 0.001 : date?.seconds
  }
  /* Sort button click action */
  const onSortChange = (key) => {
    const field = sortKeys[key]
    setCurrSortField(field)
    onSortDirChange()

    const newSortedOrders = sortedOrdersPrepared.slice().sort((a, b) => {
      const valueA = getDate(a?.[field])
      const valueB = getDate(b?.[field])

      if (valueA < valueB) {
        return sortDirection === 'asc' ? 1 : -1
      }
      if (valueA > valueB) {
        return sortDirection === 'asc' ? -1 : 1
      }
      return 0
    })

    setSortedOrders(newSortedOrders)
  }

  const onClearSort = () => {
    setSortedOrders([])
    setCurrSortField(null)
  }

  return {
    sortKeys,
    currSortField,
    sortDirection,
    onSortChange,
    onClearSort,
    getSortIcon,
    sortedOrders: sortedOrdersPrepared
  }
}

export default useOrderSort
