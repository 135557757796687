import { Breadcrumb, Typography } from 'antd'
import { StyledBreadCrumb, StyledLink } from './Breadcrumbs.styled'

import { Icon } from '@qonsoll/icons'
import PropTypes from 'prop-types'
import { useExtraBreadcrumbsItems } from './hooks'
import { useTranslations } from 'hooks'

/**
 * It returns a breadcrumb component with a link to the home page and a link to the current page
 * @returns A breadcrumb component with a link to the home page and any extra breadcrumbs that are
 * passed in.
 */
const Breadcrumbs = (props) => {
  const { collection, noExtraItems } = props
  /* Getting translations instance */
  const { t, language } = useTranslations()
  /* Getting extra breadcrumbs */
  const [extraBreadcrumbItems] = useExtraBreadcrumbsItems(collection)
  // we need to disable click on the item name as it has no sense in it
  // so we check of extraBreadcrumbItems has Edit in path and then disable both items
  const hasEdit = extraBreadcrumbItems?.some(
    ({ name }) =>
      name.toLowerCase() === 'edit' || name.toLowerCase().includes('edit')
  )
  return (
    <StyledBreadCrumb
      separator={<Icon name="ArrowShortRightFilled" fill="currentColor" />}>
      <Breadcrumb.Item key={'/'}>
        <StyledLink to="/">
          <Icon name="Home3Outlined" fill="currentColor" />
        </StyledLink>
      </Breadcrumb.Item>
      {/* if path has Edit word then we can disable click on item name - replace it with Text */}
      {!noExtraItems &&
        extraBreadcrumbItems?.map(({ key, path, name }, index) => {
          if (hasEdit && index >= extraBreadcrumbItems.length - 1) {
            return (
              <Breadcrumb.Item key={key}>
                <Typography.Text>{t(name)}</Typography.Text>
              </Breadcrumb.Item>
            )
          }

          return (
            <Breadcrumb.Item
              key={key}
              active={index === extraBreadcrumbItems.length - 1}>
              {index === extraBreadcrumbItems.length - 1 ? (
                <Typography.Text>
                  {name?.[language.toUpperCase()] || name}
                </Typography.Text>
              ) : (
                <StyledLink to={path}>
                  {name?.[language.toUpperCase()] || name}
                </StyledLink>
              )}
            </Breadcrumb.Item>
          )
        })}
    </StyledBreadCrumb>
  )
}

Breadcrumbs.propTypes = {
  collection: PropTypes.string,
  noExtraItems: PropTypes.bool
}
export default Breadcrumbs
