import styled from 'styled-components'

export const StyledWrapper = styled.div`
  height: inherit;
  width: inherit;
`

export const StyledSpinnerWrapper = styled.div`
  background-color: white;
  width: 100vw;
  height: 100dvh;
  z-index: 1000;
`
