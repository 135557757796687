import { StyledText, StyledNumber } from './PanelHeader.styled'
import { useGetProductsNumber } from 'domains/Category/hooks'
import PropTypes from 'prop-types'

import { Icon } from '@qonsoll/icons'
import { theme } from 'antd'
import { useTranslations } from 'hooks'

const PanelHeader = (props) => {
  const { category, selectedCategoryId, handleOpenCategory } = props

  const equalId = selectedCategoryId === category?._id
  const productsNumber = useGetProductsNumber(category?._id)
  const { language } = useTranslations()
  const { colorPrimary } = theme.useToken().token

  const computedName =
    category?.names?.[language.toUpperCase()] || category.name

  return (
    <StyledText
      className="flex align-center"
      ellipsis
      color={colorPrimary}
      equalId={equalId}
      onClick={handleOpenCategory(category?._id)}>
      {category.icon ? (
        <Icon
          className="mr-8"
          name={category.icon}
          fill="currentColor"
          size={20}
        />
      ) : null}
      {computedName}
      <StyledNumber>{`(${productsNumber})`}</StyledNumber>
    </StyledText>
  )
}

PanelHeader.propTypes = {
  category: PropTypes.array,
  selectedCategoryId: PropTypes.string,
  handleOpenCategory: PropTypes.func
}

export default PanelHeader
