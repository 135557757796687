import PropTypes from 'prop-types'
import { Checkbox, Space, Typography } from 'antd'
import { useGetProductsNumber } from 'domains/Category/hooks'
import { useTranslations } from 'hooks'
import { StyledNumber } from './CollapseSubcategoryView.styled'

const { Text } = Typography

const CollapseSubcategoryView = ({
  subcategory,
  topCategory,
  checkIsEnabled,
  onChange
}) => {
  const { t, language } = useTranslations()
  const productsNumber = useGetProductsNumber(subcategory?._id)

  if (subcategory?.parentId !== topCategory?._id) return null

  const computedSubcategory =
    subcategory?.names?.[language.toUpperCase()] ||
    subcategory?.name ||
    t('Unnamed filter')

  return (
    <div key={subcategory?._id} className="flex justify-between align-center">
      <Space>
        <Checkbox
          checked={checkIsEnabled(subcategory)}
          onChange={() => onChange(subcategory)}
        />
        <Text wordBreak="break-all">
          {`${computedSubcategory} `}
          {!!subcategory?.names && (
            <StyledNumber>{`(${productsNumber})`}</StyledNumber>
          )}
        </Text>
      </Space>
    </div>
  )
}

CollapseSubcategoryView.propTypes = {
  subcategory: PropTypes.object,
  topCategory: PropTypes.object,
  checkIsEnabled: PropTypes.func,
  onChange: PropTypes.func
}

export default CollapseSubcategoryView
