import { Dropdown as AntDropdown, theme } from 'antd'

import PropTypes from 'prop-types'
import { cloneElement } from 'react'

const { useToken } = theme

const menuItemStyle = { paddingInline: '4px', paddingBlock: '2px' }

const Dropdown = (props) => {
  const { menu, children, topItems, bottomItems, ...rest } = props

  const { token } = useToken()
  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary
  }
  const menuStyle = { boxShadow: 'none' }

  return (
    <AntDropdown
      {...rest}
      menu={{ items: menu }}
      dropdownRender={(menu) => (
        <div style={contentStyle}>
          {topItems?.map((item) => (
            <div key={item.key} style={menuItemStyle}>
              {item?.element}
            </div>
          ))}
          {cloneElement(menu, { style: menuStyle })}
          {bottomItems?.map((item) => (
            <div key={item.key} style={menuItemStyle}>
              {item?.element}
            </div>
          ))}
        </div>
      )}>
      {children}
    </AntDropdown>
  )
}

Dropdown.propTypes = {
  menu: PropTypes.any,
  children: PropTypes.node,
  topItems: PropTypes.arrayOf(PropTypes.object),
  bottomItems: PropTypes.arrayOf(PropTypes.object)
}

export default Dropdown
