import { generatePath, useNavigate } from 'react-router-dom'
import { useCallback, useMemo, useState } from 'react'

import { APP_PATHS } from '__constants__/paths'
import { COLLECTIONS } from '__constants__'
import { useCollection } from 'services/api/firebase'

const useCategoriesSearch = () => {
  const [searchValue, setSearchValue] = useState()
  const navigate = useNavigate()

  const query = useMemo(() => {
    if (!searchValue) return null

    return {
      ref: COLLECTIONS.CATEGORIES,
      limit: 3,
      orderBy: ['name'],
      where: [
        ['name', '>=', searchValue],
        ['name', '<=', searchValue + '\uf8ff']
      ]
    }
  }, [searchValue])

  const [data] = useCollection(query)

  const onReset = useCallback(() => setSearchValue(null), [])
  const onSelect = useCallback(
    (categoryId) => {
      if (!categoryId) return
      const path = generatePath(APP_PATHS.PRODUCTS_ALL_BY_CATEGORY, {
        categoryId
      })
      navigate(path)
    },
    [navigate]
  )

  return useMemo(
    () => ({
      data: data || [],
      onChange: setSearchValue,
      onReset,
      onSelect
    }),
    [onReset, onSelect, setSearchValue, data]
  )
}

export default useCategoriesSearch
