import {
  useActionsTicketAdvancedForm,
  useInitializeTicketAdvancedForm
} from './hooks'
import { useCheckFormChange, useTranslations } from 'hooks'

import { FormWrapper } from 'components'
import { KEYS } from '__constants__'
import PropTypes from 'prop-types'
import { TicketSimpleForm } from 'domains/Ticket/components'

const TicketAdvancedForm = (props) => {
  const { initialData, group, showTitle } = props

  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting form instance from hook */
  const { form, initialValues } = useInitializeTicketAdvancedForm(initialData)

  const [handleValuesChange, isEqual] = useCheckFormChange(initialValues)

  /* Handling enter key press */
  const handleKeyPress = (e) => {
    if (e.key === KEYS.ENTER) {
      e.preventDefault()
      form.submit()
    }
  }

  /* Getting loading state and actions from hook */
  const { loading, onFinish, onReset } = useActionsTicketAdvancedForm({
    initialData,
    form
  })

  /* Generating form field names */
  const getFieldName = (subGroup) => (group ? [...group, subGroup] : [subGroup])

  return (
    <FormWrapper
      submitDisabled={isEqual}
      onKeyPress={handleKeyPress}
      onFinish={onFinish}
      onValuesChange={handleValuesChange}
      form={form}
      showTitle={showTitle}
      onReset={onReset}
      submitLoading={loading}
      group={group}
      title={t('Ticket')}>
      <TicketSimpleForm
        group={getFieldName('ticket')}
        initialValues={initialData}
      />
    </FormWrapper>
  )
}

TicketAdvancedForm.propTypes = {
  initialData: PropTypes.object,
  group: PropTypes.array,
  showTitle: PropTypes.bool
}

export default TicketAdvancedForm
