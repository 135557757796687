import { Button, Divider, Space, Typography } from 'antd'
import { DrawerOverlay, DrawerSide } from 'components'
import { OrderAdvancedView, OrderStatus } from 'domains/Order/components'

import { Icon } from '@qonsoll/icons'
import PropTypes from 'prop-types'
import styles from './OrderSideDrawer.styled'
import { useTranslations } from 'hooks'

const OrderSideDrawer = (props) => {
  const {
    orderDrawerProps,
    onReviewSideDrawerOpen,
    onOrderSideDrawerClose,
    rightShiftOpen
  } = props
  const { order, visibility } = orderDrawerProps || {}
  const { t } = useTranslations()

  return (
    <>
      <DrawerSide isOpen={visibility} rightShiftOpen={rightShiftOpen}>
        <Space size={16}>
          <Button
            type="text"
            className="flex align-center justify-center"
            icon={<Icon name="XCloseOutlined" size={24} />}
            onClick={onOrderSideDrawerClose}
          />

          <div>
            <Typography.Title level={5} className="mb-8">{`${t('Order')} № ${
              order?.orderNumber
            }`}</Typography.Title>
            <OrderStatus order={order} />
          </div>
        </Space>
        <Divider className="mt-16 mb-24" style={styles.fullWidthDivider} />
        <OrderAdvancedView
          order={order}
          withTitle={false}
          onReviewSideDrawerOpen={onReviewSideDrawerOpen}
        />
      </DrawerSide>
      <DrawerOverlay isOpen={visibility} onClick={onOrderSideDrawerClose} />
    </>
  )
}

OrderSideDrawer.propTypes = {
  orderDrawerProps: PropTypes.object,
  onReviewSideDrawerOpen: PropTypes.func,
  onOrderSideDrawerClose: PropTypes.func,
  rightShiftOpen: PropTypes.string
}

export default OrderSideDrawer
