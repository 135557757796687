import { APP_PATHS } from '__constants__'
import { List } from 'components'
import { ProductSimpleView } from 'domains/Product/components'
import { useMemo } from 'react'
import { useTranslations } from 'hooks'

const { MY_PRODUCTS_ALL, PRODUCT_DRAFTS_ALL, PRODUCT_BLOCKED_ALL } = APP_PATHS

const useGetUserProductsTabConfig = ({
  disableDraftProductsTab,
  draftProducts,
  publishedProducts,
  blockedProducts,
  emptyProps
}) => {
  const { t } = useTranslations()

  const tabItems = useMemo(
    () => [
      {
        label: t('Published'),
        key: MY_PRODUCTS_ALL,
        children: (
          <List
            emptyProps={emptyProps}
            dataSource={publishedProducts}
            renderItem={(product) => (
              <ProductSimpleView product={product} onlyView isRentee={false} />
            )}
          />
        )
      },
      {
        label: t('Drafts'),
        key: PRODUCT_DRAFTS_ALL,
        disabled: disableDraftProductsTab,
        children: (
          <List
            emptyProps={emptyProps}
            dataSource={draftProducts}
            renderItem={(product) => (
              <ProductSimpleView product={product} onlyView isRentee={false} />
            )}
          />
        )
      },
      {
        label: t('Blocked'),
        key: PRODUCT_BLOCKED_ALL,
        disabled: !blockedProducts?.length,
        children: (
          <List
            emptyProps={emptyProps}
            dataSource={blockedProducts}
            renderItem={(product) => (
              <ProductSimpleView product={product} onlyView isRentee={false} />
            )}
          />
        )
      }
    ],
    [
      blockedProducts,
      disableDraftProductsTab,
      draftProducts,
      emptyProps,
      publishedProducts,
      t
    ]
  )

  return tabItems
}

export default useGetUserProductsTabConfig
