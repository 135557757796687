import { Col, Typography } from 'antd'

import { NotificationSimpleView } from 'domains/Notification/components'
import PropTypes from 'prop-types'
import { StyledList } from './NotificationList.styled'
import { useTranslations } from 'hooks'

const NotificationList = (props) => {
  const { notifications, emptyProps, actions, setPopoverVisible } = props

  /* Getting translations instance */
  const { t } = useTranslations()

  /* Getting emptyProps from empty object */
  const isEmpty = !notifications?.length

  return (
    <StyledList>
      {isEmpty && (
        <Col span={24} className="p-12 flex ">
          <Typography.Text variant="overline" type="secondary">
            {emptyProps?.message === undefined
              ? t('You have no unread notifications')
              : emptyProps?.message}
          </Typography.Text>
        </Col>
      )}
      {notifications?.map((notification, index) => (
        <NotificationSimpleView
          key={`notification-${index + 1}`}
          notification={notification}
          area-label={`notification-${index + 1}`}
          actions={actions}
          index={index}
          length={notifications?.length}
          setPopoverVisible={setPopoverVisible}
        />
      ))}
    </StyledList>
  )
}

NotificationList.propTypes = {
  notifications: PropTypes.array,
  emptyProps: PropTypes.object,
  actions: PropTypes.any,
  rest: PropTypes.any,
  setPopoverVisible: PropTypes.func
}

export default NotificationList
