import { Col, Divider, Row } from 'antd'

import { Fragment } from 'react'
import PropTypes from 'prop-types'
import { SettingsHeader } from 'components'
import { useScreen } from 'hooks'

const SettingsLayout = (props) => {
  const { children, actions, headingProps, breadcrumbs, withoutDivider } = props

  const { lg } = useScreen()

  return (
    <Fragment>
      <Row wrap={false} className="full-height">
        {withoutDivider || !lg ? null : (
          <Col flex="none">
            <Divider type="vertical" className="full-height mx-48" />
          </Col>
        )}
        <Col flex="auto" className="flex flex-col">
          <SettingsHeader
            actions={actions}
            {...headingProps}
            breadcrumbs={breadcrumbs}
          />
          {children}
        </Col>
      </Row>
    </Fragment>
  )
}

SettingsLayout.propTypes = {
  children: PropTypes.node,
  actions: PropTypes.node,
  headingProps: PropTypes.object,
  breadcrumbs: PropTypes.node,
  withoutDivider: PropTypes.bool
}

export default SettingsLayout
