import { capitalize } from 'helpers'
const CANCELLATION_TERMS_TYPES = {
  FIXED: 'FIXED',
  MODERATE: 'MODERATE'
  // STRICT: 'STRICT'
}

const CANCELLATION_TERMS_TYPES_LABELS = {
  FIXED: 'Fixed',
  MODERATE: 'Moderate'
  // STRICT: 'Strict'
}

const CANCELLATION_TERMS_TIME = {
  FIXED: 24,
  MODERATE: 24 * 7
  // STRICT: 30 * 24
}

const CANCELLATION_TERMS_TYPES_OPTIONS = Object.keys(
  CANCELLATION_TERMS_TYPES_LABELS
).map((key) => ({
  label: capitalize(CANCELLATION_TERMS_TYPES[key]),
  value: key
}))

const CANCELLATION_TERMS_TYPES_DESCRIPTION = {
  [CANCELLATION_TERMS_TYPES.FIXED]:
    'You can request a return within the first 24 hours from the initial request',
  [CANCELLATION_TERMS_TYPES.MODERATE]:
    'You can request a return within the first week from the initial request'
  // [CANCELLATION_TERMS_TYPES.STRICT]:
  //   'You can request a return within the first month from the initial request'
}
export default CANCELLATION_TERMS_TYPES
export {
  CANCELLATION_TERMS_TYPES_OPTIONS,
  CANCELLATION_TERMS_TYPES,
  CANCELLATION_TERMS_TYPES_LABELS,
  CANCELLATION_TERMS_TYPES_DESCRIPTION,
  CANCELLATION_TERMS_TIME
}
