import PropTypes from 'prop-types'
import TagStyled from './TagListItem.styled'
import { capitalize } from 'helpers'

const TagListItem = (props) => {
  const { name } = props

  const capitalizedName = capitalize(name)

  return <TagStyled>{capitalizedName}</TagStyled>
}

TagListItem.propTypes = {
  name: PropTypes.string
}

export default TagListItem
