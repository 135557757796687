import { PRODUCT_STATUSES } from '__constants__/enums'

const computedPublishDate = (initialData, productStatus) => {
  const publishDate = new Date()

  const isPublishUpdatedData = productStatus === PRODUCT_STATUSES.PUBLISHED

  const isPublishInitialStatus =
    initialData?.product?.status === PRODUCT_STATUSES.PUBLISHED

  // Return initial data, if initial data is not exist, create new publish data
  if (isPublishInitialStatus && isPublishUpdatedData)
    return initialData?.product?.publishDate || publishDate?.toISOString()

  // Create publish data
  if (!isPublishInitialStatus && isPublishUpdatedData) {
    return publishDate?.toISOString()
  }

  return null
}
export default computedPublishDate
