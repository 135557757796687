import { Typography, Col } from 'antd'
import { Icon } from '@qonsoll/icons'
import PropTypes from 'prop-types'
import { useTranslations } from 'hooks'
import { useGetProductsNumber } from 'domains/Category/hooks'
import {
  StyledRow,
  StyledCol,
  StyledText,
  StyledMenuItem
} from './CategoryMenuItem.styled'

const CategoryMenuItem = (props) => {
  const { category, setSelectedCategoryId, handleOpenCategory } = props
  const { t, language } = useTranslations()
  const productsNumber = useGetProductsNumber(category?._id)

  const computedName = t(
    category?.names?.[language.toUpperCase()] || category?.name
  )
  const computedIcon = category.icon ? (
    <Icon className="mr-8" name={category.icon} fill="currentColor" size={30} />
  ) : null

  const onMouseEnter = () => setSelectedCategoryId(category?._id)

  return (
    <StyledMenuItem
      onMouseEnter={onMouseEnter}
      onClick={handleOpenCategory(category?._id)}
      icon={computedIcon}>
      <StyledRow>
        <StyledCol span={22}>
          <StyledText ellipsis>{computedName}</StyledText>
        </StyledCol>
        <Col span="auto">
          <Typography.Text type="secondary">{`(${productsNumber})`}</Typography.Text>
        </Col>
      </StyledRow>
    </StyledMenuItem>
  )
}

CategoryMenuItem.propTypes = {
  category: PropTypes.object,
  setSelectedCategoryId: PropTypes.func,
  handleOpenCategory: PropTypes.func
}

export default CategoryMenuItem
