import { COLLECTIONS } from '__constants__/models'
import { doc } from 'firebase/firestore'
import { firestore } from 'services/firebase'
import { useDocumentData } from 'react-firebase-hooks/firestore'

const useGetMediaObject = (documentId) => {
  const queryRef = documentId
    ? doc(firestore, COLLECTIONS.MEDIA_OBJECTS, documentId)
    : null
  return useDocumentData(queryRef)
}

export default useGetMediaObject
