import _ from 'lodash'

class Proxy {
  super() {}
  config = {
    constants: { VIDEO_ARCHIVE: 'videos', USERS: 'users' },
    assets: { noDataImage: '' },
    components: {},
    functionality: { scheduleMessages: true, videoArchive: true }
  }

  setConfig(config) {
    this.config = _.merge(this.config, config)
  }
}

const proxy = new Proxy()

export default proxy
