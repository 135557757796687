import boat from '__assets__/map/boat.png'
import business from '__assets__/map/business.png'
import car from '__assets__/map/car.png'
import events from '__assets__/map/events.png'
import market from '__assets__/map/market.png'
import moped from '__assets__/map/mc-and-engine.png'
import product from '__assets__/map/product.png'
import realEstate from '__assets__/map/real-estate.png'
import services from '__assets__/map/services.png'

const CATEGORY_ICONS = {
  Briefcase1Outlined: business,
  ShipOutlined: boat,
  Car2Outlined: car,
  Building5Outlined: realEstate,
  Cube1Outlined: product,
  StoreOutlined: market,
  Star6Outlined: events,
  MopedOutlined: moped,
  ClocheOutlined: services
}

const getIcon = (iconName) => {
  const iconPath = iconName || 'Cube1Outlined'

  return CATEGORY_ICONS[iconPath]
}

export default getIcon
