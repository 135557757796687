import { Segmented } from 'antd'
import styled from 'styled-components'

const SegmentedStyled = styled(Segmented)`
  display: ${({ fullWidth }) => (fullWidth ? 'flex' : '')};
  flex: ${({ fullWidth }) => (fullWidth ? '1' : '')};
  .ant-segmented-item {
    display: ${({ fullWidth }) => (fullWidth ? '1' : '')};
    flex: 1;
    justify-content: center;
  }
  ${(props) => props.wrapperStyles};
`
export default SegmentedStyled
