import { Breadcrumbs, PageActions, PageLayout, Spinner } from 'components'
import { COLLECTIONS, PATHS } from '__constants__'
import { generatePath, useNavigate, useParams } from 'react-router-dom'

import { ReviewAdvancedView } from 'domains/Review/components'
import { useListenDocument } from 'services/api/firebase'
import { useTranslations } from 'hooks'

const ReviewShowPage = (props) => {
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting navigate instance */
  const navigate = useNavigate()
  /* Getting params from router */
  const params = useParams()
  /* Fetching document data from useDocument hook */
  const [review, loading] = useListenDocument({
    ref: COLLECTIONS.REVIEWS + '/' + params.reviewId
  })

  /* Configuring page header */
  const headingProps = {
    title: t('Review show'),
    textAlign: 'left'
  }

  /* Getting id from params */
  const { reviewId } = params

  /* Configuring page actions */
  const actions = { edit: !!PATHS.AUTHENTICATED.REVIEW_EDIT }

  /* Function to handle back click */
  const handleBack = () => navigate(-1)
  /* Handling opening of the edit page */
  const handleEdit = () => {
    const pathParams = { reviewId }
    const path = generatePath(PATHS.AUTHENTICATED.REVIEW_EDIT, pathParams)
    navigate(path)
  }

  return (
    <PageLayout
      actions={<PageActions actions={actions} onEdit={handleEdit} />}
      onBack={handleBack}
      breadcrumbs={<Breadcrumbs />}
      headingProps={headingProps}
      area="review">
      {loading ? (
        <Spinner height="70vh" text={t('Review loading')} />
      ) : (
        <>
          <ReviewAdvancedView review={review} />
        </>
      )}
    </PageLayout>
  )
}

export default ReviewShowPage
