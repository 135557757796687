import 'firebaseui/dist/firebaseui.css'

import * as firebaseui from 'firebaseui'

import { useEffect, useRef, useState } from 'react'

import PropTypes from 'prop-types'
import { onAuthStateChanged } from 'firebase/auth'
import styled from 'styled-components'

const StyledDiv = styled.div`
  .firebaseui-idp-button,
  .mdl-button,
  .mdl-js-button,
  .mdl-button--raised,
  .firebaseui-idp-google,
  .firebaseui-id-idp-button {
    min-width: 52px;
    min-height: 52px;
    border-radius: 12px !important;
    box-shadow: none;
    border: 1px solid #d9d9d9;
    span:not(:first-of-type) {
      display: none;
    }
  }
  .firebaseui-card-content {
    display: flex;
    justify-content: center;
  }
`
const wrapperStyles = {
  minWidth: 52,
  minHeight: 52
}

const StyledFirebaseAuth = (props) => {
  const { uiConfig, firebaseAuth, className, uiCallback } = props

  const [userSignedIn, setUserSignedIn] = useState(false)
  const elementRef = useRef(null)

  useEffect(() => {
    // Get or Create a firebaseUI instance.
    const firebaseUiWidget =
      firebaseui.auth.AuthUI.getInstance() ||
      new firebaseui.auth.AuthUI(firebaseAuth)
    if (uiConfig.signInFlow === 'popup') firebaseUiWidget.reset()

    // We track the auth state to reset firebaseUi if the user signs out.
    const unregisterAuthObserver = onAuthStateChanged(firebaseAuth, (user) => {
      if (!user && userSignedIn) firebaseUiWidget.reset()
      setUserSignedIn(!!user)
    })

    // Trigger the callback if any was set.
    if (uiCallback) uiCallback(firebaseUiWidget)

    // Render the firebaseUi Widget.
    // @ts-ignore
    firebaseUiWidget.start(elementRef.current, uiConfig)

    return () => {
      unregisterAuthObserver()
      firebaseUiWidget.reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firebaseui, uiConfig])

  return (
    <div style={wrapperStyles}>
      <StyledDiv className={className} ref={elementRef} />
    </div>
  )
}

StyledFirebaseAuth.propTypes = {
  uiConfig: PropTypes.object.isRequired,
  firebaseAuth: PropTypes.object.isRequired,
  className: PropTypes.string,
  uiCallback: PropTypes.func
}

export default StyledFirebaseAuth
