import moment from 'moment'
/**
 * Function is used to get total rent number and price for products
 * @param    {object} statisticByDays statistic from realtime database by dates
 * @returns  {array}  a sorted array with products
 */
const getProductsStatistic = (statisticByDays) => {
  const productsTotalRentsAndPrice = []
  for (const date in statisticByDays) {
    // See products for an every date
    const products = statisticByDays[date].products

    // See every product from a date
    for (const productId in products) {
      // Get product's statistic by product's id
      const product = products[productId]

      // Get specific fields
      const productTotalRentNumber = product.total_rent_number
      const productTotalRentPrice = product.total_rent_price

      // If total_rent_number exists, add it to the corresponding product in the array
      const existingProduct = productsTotalRentsAndPrice.find(
        (p) => p.productId === productId
      )

      // If the product already exists in the array, update the amount
      if (existingProduct) {
        existingProduct.totalRentNumber += productTotalRentNumber || 0
        existingProduct.totalRentPrice += productTotalRentPrice || 0
      } else {
        // Get yesterday and today in format that is used for statistic
        const yesterdayFormatted = moment()
          .subtract(1, 'day')
          .format('YYYY-MM-DD')
        const todayFormatted = moment().format('YYYY-MM-DD')

        // Add new product to an output array
        productsTotalRentsAndPrice.push({
          productId,
          totalRentNumber: productTotalRentNumber || 0,
          totalRentPrice: productTotalRentPrice || 0,
          yesterdayRentNumber:
            statisticByDays?.[yesterdayFormatted]?.products?.[productId]
              ?.total_rent_number || 0,
          todayRentNumber:
            statisticByDays?.[todayFormatted]?.products?.[productId]
              ?.total_rent_number || 0
        })
      }
    }
  }

  // Sort the array by totalRentNumber in descending order
  productsTotalRentsAndPrice.sort(
    (a, b) => b.totalRentNumber - a.totalRentNumber
  )
  return productsTotalRentsAndPrice
}

export default getProductsStatistic
