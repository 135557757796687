import { useEffect } from 'react'
import { useEvent } from 'hooks'

function useKeyPress(targetKey, callback) {
  // If released key is our target key then set to false
  const upHandler = useEvent((e) => {
    if (e.key === targetKey) callback?.(e)
  })

  // Add event listeners
  useEffect(() => {
    window.addEventListener('keyup', upHandler)
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keyup', upHandler)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) // Empty array ensures that effect is only run on mount and unmount
}

export default useKeyPress
