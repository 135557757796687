import { Button, Card, Col, Divider, Form, Row, Typography } from 'antd'

import { Box } from 'components'
import { Icon } from '@qonsoll/icons'
import { PreviewContainer } from './ProductPreviewView.styled'
import { ProductPreviewImageBeforeUpload } from './components'
import PropTypes from 'prop-types'
import { TagList } from 'domains/Tag/components'
import { calculateFinalPayment } from 'helpers'
import { useMemo } from 'react'
import { useTranslations } from 'hooks'
import { currencySymbol } from 'domains/Product/hooks'

const ProductPreviewView = (props) => {
  const { form, handlePreview } = props

  const { t } = useTranslations()
  const product = Form.useWatch(['product'], form)

  const { previewImgUrl, tags } = product || {}

  const computedPreviewImgUrl = useMemo(() => {
    const previewImage = product?.mediaObjects?.find(
      (item) => item?.isCoverImage
    )

    if (previewImage) {
      return previewImage?.url || previewImage?.originFileObj
    }

    if (!previewImage || product?.mediaObjects?.length) {
      const firstElement = product?.mediaObjects?.[0]
      return firstElement?.url || firstElement?.originFileObj
    }

    return previewImgUrl
  }, [product?.mediaObjects, previewImgUrl])

  const productCurrencySymbol = currencySymbol(product?.currency)

  const priceWithFees =
    product?.pricePerDayWithFees || calculateFinalPayment(product?.pricePerDay)

  const totalCommission = useMemo(() => {
    const commission = priceWithFees - product?.pricePerDay
    return `${commission}${productCurrencySymbol}`
  }, [priceWithFees, product?.pricePerDay, productCurrencySymbol])

  return (
    <Card
      title={t('Preview')}
      extra={
        <Button type="link" className="p-0" onClick={handlePreview}>
          <Icon name="ChevronRightOutlined" size={16} />
        </Button>
      }>
      <Row>
        <Col span={24} className="mb-16 flex justify-center">
          <PreviewContainer>
            <ProductPreviewImageBeforeUpload
              previewImgUrl={computedPreviewImgUrl}
            />
          </PreviewContainer>
        </Col>
        {product?.name && (
          <Col span={24} className="mb-8">
            <Typography.Title level={4}>{product?.name}</Typography.Title>
          </Col>
        )}
        {!!tags?.length && (
          <Col span={24} className="mb-8">
            <TagList tags={tags} />
          </Col>
        )}
        {product?.description && (
          <Col span={24}>
            <Typography.Paragraph
              className="m-0"
              type="secondary"
              level={3}
              ellipsis={{ rows: 8 }}>
              {product?.description}
            </Typography.Paragraph>
          </Col>
        )}

        {/*  */}
        {product?.pricePerDay && (
          <>
            <Col span={24}>
              <Divider className="my-12" />
            </Col>
            <Col span={24}>
              <Row gutter={16} className="justify-between">
                <Col span={24} className="flex gap-4 mb-4">
                  <Typography.Title level={5}>
                    {t('Price breakdown')}:
                  </Typography.Title>
                </Col>
                <Col span={24} className="flex justify-between flex-1">
                  <Box className="mb-4">
                    <Typography.Text>
                      {product?.pricePerDay}
                      {productCurrencySymbol}
                    </Typography.Text>

                    <Typography.Text className="ml-8">x</Typography.Text>
                    <Typography.Text>{` 1 ${t('day')}`}</Typography.Text>
                  </Box>
                  <Typography.Title level={5}>
                    {product?.pricePerDay}
                    {productCurrencySymbol}
                  </Typography.Title>
                </Col>
                <Col span={24} className="flex justify-between">
                  <Typography.Text>{t('Commissions and fees')}</Typography.Text>

                  <Typography.Title level={5}>
                    {totalCommission}
                  </Typography.Title>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Divider className="my-12" />
            </Col>
            <Col span={24} className="flex justify-between">
              <Typography.Title level={5}>
                {t('Total rent cost')}/ {t('day')}
              </Typography.Title>
              <Typography.Title level={5}>
                {priceWithFees}
                {productCurrencySymbol}
              </Typography.Title>
            </Col>
          </>
        )}
      </Row>
    </Card>
  )
}

ProductPreviewView.propTypes = {
  form: PropTypes.object,
  handlePreview: PropTypes.func
}

export default ProductPreviewView
