import { Col, Row } from 'antd'

import styled from 'styled-components'

const StyledCol = styled(Col)`
  height: 100%;
  overflow-y: auto;
  background-color: white;
  border-radius: ${({ borderRadius }) => `${borderRadius}px`};
  border: ${({ colorBorderSecondary }) => `1px solid ${colorBorderSecondary}`};
`
const StyledChatCol = styled(Col)`
  height: 100%;
  background-color: white;
  border-radius: 7px;
  padding: 16px;
  border: ${({ colorBorderSecondary }) => `1px solid ${colorBorderSecondary}`};
`
const StyledRow = styled(Row)`
  height: ${({ xs, isRentee }) =>
    xs
      ? 'calc(100vh - 252px)'
      : isRentee
      ? ' calc(100vh - 220px)'
      : ' calc(100vh - 160px)'};
  position: relative;
  flex-wrap: nowrap;
`
export { StyledCol, StyledRow, StyledChatCol }
