import { COMPLAINT_STATUSES_LABELS } from '__constants__'
import PropTypes from 'prop-types'
import { StatusDot } from './components'
import { Typography } from 'antd'

const ComplaintStatus = (props) => {
  const { complaint } = props

  return (
    <div className="flex align-center">
      <StatusDot status={complaint?.status} className="mr-8" />
      <Typography.Text className="text-nowrap">
        {COMPLAINT_STATUSES_LABELS?.[complaint?.status]}
      </Typography.Text>
    </div>
  )
}

ComplaintStatus.propTypes = {
  complaint: PropTypes.object
}

export default ComplaintStatus
