import { useCallback, useEffect, useState } from 'react'

import { COLLECTIONS } from '__constants__'
import { getDocument } from 'services/api/firebase'

/**
 * It fetches a address document from Firestore and returns it
 * @param product - The product object that we're getting the address for.
 * @returns An array with four values. The first value is the address object, and the second value is a
 * boolean that indicates whether the address is loading.
 */
const useGetProductAddressData = (product) => {
  /* State for data */
  const [address, setAddress] = useState(null)
  /* State for loading */
  const [loading, setLoading] = useState(!!product?.addressId)
  /* State for error */
  const [error, setError] = useState(null)

  /* Fetching data */
  const getAddress = useCallback(async () => {
    try {
      if (!product?.addressId) return setAddress(null)

      /* Setting loading state to true */
      setLoading(true)

      /* Getting address document */
      const document = await getDocument(
        COLLECTIONS.ADDRESSES,
        product.addressId
      )

      /* Setting address data */
      setAddress(document)
    } catch (error) {
      setError(error)
    }

    /* Setting loading state to false */
    setLoading(false)
  }, [product?.addressId])

  useEffect(() => {
    product?.addressId !== undefined && getAddress()
  }, [product?.addressId, getAddress])

  return [address, loading, error, getAddress]
}

export default useGetProductAddressData
