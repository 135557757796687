import { Breadcrumbs, SettingsLayout, Spinner } from 'components'
import { Col, Row } from 'antd'
import { useScreen, useTranslations } from 'hooks'

import { ContactUsAdvancedForm } from 'domains/ContactUs/components'
import PropTypes from 'prop-types'
import { useUser } from 'modules/session-module/contexts'

const SettingsContactUs = (props) => {
  const { layoutProps } = props
  const { user, loading } = useUser()

  const { xl, lg } = useScreen()

  const { t } = useTranslations()

  const headingProps = {
    title: t('Contact us'),
    description: `${t('Our team will get back to you as soon as possible')}.`
  }

  return (
    <SettingsLayout
      breadcrumbs={<Breadcrumbs />}
      headingProps={headingProps}
      area="contactUs">
      <Row className="mb-16">
        {loading ? (
          <Spinner height={'100%'} />
        ) : (
          <Col {...layoutProps}>
            <ContactUsAdvancedForm
              initialData={{ user }}
              isBlockButtons={!xl}
              withNavigate={!lg}
            />
          </Col>
        )}
      </Row>
    </SettingsLayout>
  )
}

SettingsContactUs.propTypes = {
  withoutHeader: PropTypes.bool,
  layoutProps: PropTypes.object
}

export default SettingsContactUs
