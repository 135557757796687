import PropTypes from 'prop-types'
import { Table } from 'antd'
import { useGetCustomersTableConfig } from 'domains/Customer/hooks'

const CustomerList = (props) => {
  const { customers } = props

  const { columns, paginationStyles, tableProps } = useGetCustomersTableConfig()

  return (
    <Table
      dataSource={customers}
      columns={columns}
      pagination={paginationStyles}
      {...tableProps}
    />
  )
}

CustomerList.propTypes = {
  customers: PropTypes.array
}

export default CustomerList
