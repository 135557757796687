import { Form, Input } from 'antd'
import { MAX_LENGTH_PASSWORD, MIN_LENGTH_PASSWORD } from '__constants__'

import { FormWrapper } from 'components'
import PropTypes from 'prop-types'
import { useActionsChangePasswordForm } from './hooks'
import { useTranslations } from 'hooks'

const ChangePasswordForm = (props) => {
  const { changePassword, withIndicator, withNavigate, ...rest } = props
  const { t } = useTranslations()

  /* Getting form instance */
  const [form] = Form.useForm()

  const { onFinish, onReset, handleKeyPress } = useActionsChangePasswordForm({
    form,
    withNavigate
  })

  const validateMatchPassword = ({ getFieldValue }) => ({
    validator(_, value) {
      if (!value || getFieldValue('password') === value) {
        return Promise.resolve()
      }
      return Promise.reject(
        new Error(t('The two passwords that you entered do not match!'))
      )
    }
  })

  const rules = {
    oldPassword: [
      { required: true, message: t('Please, enter your old password') }
    ],
    password: [
      { required: true, message: t('Please, enter your password') },
      {
        min: MIN_LENGTH_PASSWORD,
        message: t(
          `Password must be at least ${MIN_LENGTH_PASSWORD} characters`
        )
      },
      {
        max: MAX_LENGTH_PASSWORD,
        message: t(
          `Password cannot be longer than ${MAX_LENGTH_PASSWORD} characters`
        )
      }
    ],

    confirmPassword: [
      {
        required: true,
        message: t('Please confirm your password!')
      },
      validateMatchPassword
    ]
  }

  return (
    <FormWrapper
      onKeyPress={handleKeyPress}
      onFinish={onFinish}
      onReset={onReset}
      size="small"
      buttonSize="middle"
      submitText={t('Change password')}
      form={form}
      {...rest}>
      <Form.Item
        name="oldPassword"
        rules={rules?.oldPassword}
        label={t('Old password')}>
        <Input.Password placeholder="• • • • • •" size="default" />
      </Form.Item>
      <Form.Item
        name="password"
        hasFeedback
        rules={rules?.password}
        label={t('New password')}>
        <Input.Password
          placeholder={t('at least 6 characters')}
          size="default"
        />
      </Form.Item>
      <Form.Item
        name="confirmPassword"
        dependencies={['password']}
        hasFeedback
        rules={rules?.confirmPassword}
        label={t('Confirm password')}>
        <Input.Password
          placeholder={t('at least 6 characters')}
          size="default"
        />
      </Form.Item>
    </FormWrapper>
  )
}

ChangePasswordForm.propTypes = {
  changePassword: PropTypes.func.isRequired,
  withIndicator: PropTypes.bool,
  withNavigate: PropTypes.bool
}

export default ChangePasswordForm
