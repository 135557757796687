import { Col, Divider, Form, Input, Row, Typography } from 'antd'
import { FormWrapper, PhoneInput } from 'components'

import { KEYS } from '__constants__'
import PropTypes from 'prop-types'
import { styles } from './CheckoutContactInfoSimpleForm.styled'
import { useTranslations } from 'hooks'

const CheckoutContactInfoSimpleForm = (props) => {
  const { initialValues, onSubmit, onCancel, showTitle, form, group, title } =
    props
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting new form instance from Form.useForm hook */
  const [baseForm] = Form.useForm()
  /* Getting used form from props or using base form */
  const usedForm = form || baseForm
  /* Resetting form on cancel */
  const handleCancel = () => {
    usedForm.resetFields()
    onCancel?.()
  }
  /* Submitting form on enter key press */
  const handleKeyPress = (e) => {
    if (e.key === KEYS.ENTER) {
      e.preventDefault()
      e.stopPropagation()
      usedForm?.submit()
    }
  }

  /* Generating form field names */
  const getFieldName = (subGroup) => (group ? [...group, subGroup] : [subGroup])

  return (
    <>
      <Row>
        <Col xs={24} sm={24} md={24} lg={20} xl={16} xxl={12}>
          {title && (
            <div className="mb-24">
              <Typography.Title level={4}>{title}</Typography.Title>
            </div>
          )}
          <FormWrapper
            onFinish={onSubmit}
            initialValues={initialValues}
            onKeyPress={handleKeyPress}
            onReset={handleCancel}
            showTitle={showTitle}
            form={usedForm}
            group={group}>
            <Form.Item
              data-area="checkout-first-name"
              label={t('First name')}
              name={getFieldName('firstName')}
              rules={[
                {
                  required: true,
                  message: t('Please enter your first name!')
                }
              ]}>
              <Input placeholder="John" />
            </Form.Item>
            <Form.Item
              data-area="checkout-last-name"
              label={t('Last name')}
              name={getFieldName('lastName')}
              rules={[
                { required: true, message: t('Please enter your last name!') }
              ]}>
              <Input placeholder="Doe" />
            </Form.Item>

            <Form.Item
              data-area="checkout-email"
              label={t('Email')}
              name={getFieldName('email')}
              rules={[
                { required: true, message: t('Please enter your email!') },
                {
                  type: 'email',
                  message: t('Please enter valid email address!')
                }
              ]}>
              <Input placeholder="john.doe@example.com" />
            </Form.Item>
            <Form.Item
              data-area="checkout-phone"
              label={t('Phone number')}
              name={getFieldName('phone')}
              rules={[
                { required: true, message: t('Please enter your phone!') },
                { type: 'phone', message: t('Please enter valid phone') }
              ]}>
              <PhoneInput
                specialLabel=""
                inputStyle={styles.inputStyles}
                buttonStyle={styles.buttonStyles}
                placeholder={t('Please enter your user phone')}
              />
            </Form.Item>
          </FormWrapper>
        </Col>
      </Row>
      <Divider className="mt-0" />
    </>
  )
}

CheckoutContactInfoSimpleForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  showTitle: PropTypes.bool,
  form: PropTypes.any,
  group: PropTypes.array,
  title: PropTypes.string
}

export default CheckoutContactInfoSimpleForm
