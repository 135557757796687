import { Form, Input, Radio, Space } from 'antd'
import {
  RENTEE_COMPLAIN_REASONS_OPTIONS,
  RENTER_COMPLAIN_REASONS_OPTIONS
} from '__constants__'

import { FormWrapper } from 'components'
import { KEYS } from '__constants__'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslations } from 'hooks'
import { useUser } from 'modules/session-module/contexts'

const ComplaintAdvancedForm = (props) => {
  const {
    initialValues,
    onSubmit,
    onCancel,
    showTitle,
    form,
    group,
    onComplaintFormToggle,
    ...rest
  } = props
  const { isRentee } = useUser()

  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting new form instance from Form.useForm hook */
  const [baseForm] = Form.useForm()

  /* Getting used form from props or using base form */
  const usedForm = form || baseForm

  /* Resetting form on cancel */
  const handleCancel = () => {
    usedForm.resetFields()
    onCancel?.()
    onComplaintFormToggle?.()
  }
  /* Submitting form on enter key press */
  const handleKeyPress = (e) => {
    if (e.key === KEYS.ENTER) {
      e.preventDefault()
      e.stopPropagation()
      usedForm?.submit()
    }
  }

  /* Generating form field names */
  const getFieldName = (subGroup) => (group ? [...group, subGroup] : [subGroup])
  const [value, setValue] = useState(RENTEE_COMPLAIN_REASONS_OPTIONS[0].value)
  const onChange = (e) => {
    setValue(e.target.value)
  }

  const complaintReasonsComputed = isRentee
    ? RENTEE_COMPLAIN_REASONS_OPTIONS
    : RENTER_COMPLAIN_REASONS_OPTIONS

  return (
    <FormWrapper
      title={t('Complaint')}
      onFinish={onSubmit}
      initialValues={initialValues}
      onKeyPress={handleKeyPress}
      isHidden
      onReset={handleCancel}
      showTitle={showTitle}
      form={usedForm}
      group={group}
      {...rest}>
      <Form.Item
        className="mt-12"
        rules={[
          {
            required: true,
            message: t('Please select complaint reason!')
          }
        ]}
        data-area="complaint-reason"
        label={t('Select complaint reason')}
        name={getFieldName('complaintReason')}>
        <Radio.Group onChange={onChange} value={value} className="mt-8">
          <Space className="gap-8" direction="vertical">
            {complaintReasonsComputed.map((option, index) => (
              <Radio
                key={`${index}+${option.value}`}
                value={option.value}
                checked={true}>
                {t(option.label)}
              </Radio>
            ))}
          </Space>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        data-area="complaint-comment"
        label={t('Comment')}
        name={getFieldName('comment')}>
        <Input.TextArea
          style={{
            resize: 'none'
          }}
          rows={4}
          maxLength={250}
          showCount
          placeholder={t('Please enter your complaint comment')}
        />
      </Form.Item>
    </FormWrapper>
  )
}

ComplaintAdvancedForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  showTitle: PropTypes.bool,
  form: PropTypes.any,
  group: PropTypes.array,
  onComplaintFormToggle: PropTypes.func
}

export default ComplaintAdvancedForm
