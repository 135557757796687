import { CardDropdown, Image, ImageOverlay } from 'components'
import { Col, Row, theme } from 'antd'

import PropTypes from 'prop-types'
import { TitleStyled } from './CategorySimpleView.styles'
import styles from './CategorySimpleView.styles'
import { useCategoryBaseActions } from 'domains/Category/hooks'

const CategorySimpleView = (props) => {
  const { category, onSelect, index, actions, categoryBgIcon } = props

  const { colorOverlayBg } = theme.useToken().token
  /* Getting base actions */
  const { onOpen } = useCategoryBaseActions({
    categoryId: category?._id,
    actions
  })
  /* Handling selection of the item */
  const handleSelect = (e) => {
    e.stopPropagation()
    onSelect?.(category?._id)
  }

  return (
    <CardDropdown
      onDoubleClick={onOpen}
      onClick={handleSelect}
      document={category}
      index={index}
      data-testid="categoryListItem"
      {...actions}>
      <Row className="flex justify-center align-center relative">
        <Col span={24}>
          {categoryBgIcon ? (
            <div
              className="flex justify-center align-center"
              style={{ height: '160px' }}>
              {categoryBgIcon}
            </div>
          ) : (
            <Image src={category?.imageUrl} style={styles.imageStyles} />
          )}
          <ImageOverlay bg={colorOverlayBg} className="p-16">
            <TitleStyled level={5}>{category?.name}</TitleStyled>
          </ImageOverlay>
        </Col>
      </Row>
    </CardDropdown>
  )
}

CategorySimpleView.propTypes = {
  category: PropTypes.object,
  onSelect: PropTypes.func,
  index: PropTypes.number,
  isSelected: PropTypes.bool,
  actions: PropTypes.object,
  categoryBgIcon: PropTypes.node
}

export default CategorySimpleView
