import {
  computedPublishDate,
  getCategoryNames,
  getUploadingObjects
} from './helpers'
import {
  getId,
  saveBelongsToRelationship,
  saveHasManyRelationship,
  updateParent
} from 'services/api/firebase'
import { useLocation, useNavigate } from 'react-router-dom'
import { useMutate, useTranslations } from 'hooks'

import { COLLECTIONS } from '__constants__'
import { calculateFinalPayment } from 'helpers'
import { message } from 'antd'
import { useActionsAddressAdvancedForm } from 'domains/Address/components/AddressAdvancedForm/hooks'
import { useState } from 'react'
import { useUpload } from 'contexts/UploadContext'

const useActionsProductAdvancedForm = (params) => {
  const { initialData, form } = params || {}
  const { useMediaUpload, uploadedFiles } = useUpload()

  const { handleUpload, handleRemoveFiles } = useMediaUpload({
    form,
    formGroup: ['product', 'mediaObjects']
  })

  /* State for loading */
  const [loading, setLoading] = useState(false)
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting navigate instance */
  const navigate = useNavigate()
  /* Getting location state */
  const navigationState = useLocation().state

  const { create, update } = useMutate()

  /* Getting Address values and validation function */
  const { prepareValues: prepareAddressValues } = useActionsAddressAdvancedForm(
    { initialData }
  )

  /* Function for preparing values for saving */
  const prepareValues = async (
    values = {},
    additionalValues = {},
    productStatus
  ) => {
    /* Getting category names. if we have initial names and they wasn't changed- set them if not - get db data */

    /* Getting id */
    const productId =
      initialData?.['product']?._id || getId(COLLECTIONS.PRODUCTS)

    const mediaObjectsPath = `/mediaObjects/product/${productId}`

    const valuesMedia = values?.product?.mediaObjects
    const initialMedia = initialData?.mediaObjects

    const parentReferences = { productId }

    const uploadData = await handleUpload(uploadedFiles, mediaObjectsPath)

    const mediaObjectsResult = await getUploadingObjects(
      valuesMedia,
      initialMedia,
      uploadData,
      mediaObjectsPath,
      handleRemoveFiles
    )

    mediaObjectsResult.forEach((item) => {
      delete item?.uid
      delete item?.isCoverImage
    })

    const addressData = await prepareAddressValues(values)

    const pricePerDay = values['product']?.['pricePerDay']
    const pricePerDayWithFees = pricePerDay
      ? calculateFinalPayment(pricePerDay)
      : null

    /* Preparing product values */
    const preparedValues = {
      _id: productId,
      ...additionalValues,
      name: values['product']?.['name'] ?? null,
      description: values['product']?.['description'] ?? null,
      brandId: values['product']?.['brandId'] ?? null,
      pricePerDay: pricePerDay ?? null,
      pricePerDayWithFees: pricePerDayWithFees ?? null,
      currency: values?.product?.currency ?? null,
      isRenterDelivery: values?.product?.isRenterDelivery ?? false,
      status: productStatus ?? null,
      fields: values['product']?.['fields'] ?? null,
      previewImgUrl: mediaObjectsResult?.[0]?.url ?? null,
      mediaObjects:
        (await saveHasManyRelationship(
          COLLECTIONS.MEDIA_OBJECTS,
          mediaObjectsResult,
          parentReferences
        )) || [],
      categoryId: values?.['product']?.['categoryId'] ?? null,
      categoryNames: (await getCategoryNames(initialData, values)) ?? null,
      tags:
        (await saveHasManyRelationship(
          COLLECTIONS.TAGS,
          values['product']?.['tags'],
          parentReferences
        )) ?? [],
      addressId: await saveBelongsToRelationship(
        COLLECTIONS.ADDRESSES,
        addressData,
        parentReferences
      ),
      cancellationTerms: values['product']?.['cancellationTerms'] ?? null,
      likes: initialData?.product?.likes ?? null,
      avgRating: initialData?.product?.avgRating ?? null,
      publishDate: computedPublishDate(initialData, productStatus) ?? null
    }

    return preparedValues
  }

  /* Saving form data */
  const saveForm = async (values, callback, productStatus) => {
    try {
      // Prepare data to be saved
      const data = await prepareValues(values, null, productStatus)

      if (navigationState?.parent)
        await updateParent(navigationState.parent, data._id)

      // Save data
      if (initialData && initialData?.product?._id) {
        await update(COLLECTIONS.PRODUCTS, initialData.product._id, data)
        message.success(t('Product successfully updated'))
      } else {
        await create(COLLECTIONS.PRODUCTS, data, data._id)
        message.success(t('Product successfully created'))
      }
      // Final callback
      callback?.()
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      throw new Error(t('Something went wrong during data save'))
    }
  }

  /* On finish callback */
  const onFinish = async (productStatus) => {
    if (loading) return // Avoid multiple calls

    try {
      setLoading(true)

      /* Prepare data to be saved */
      const formValues = form.getFieldsValue()

      // Save data
      await saveForm(formValues, null, productStatus)
      // Final callback
      navigate(-1)
    } catch (error) {
      message.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  /* On cancel callback */
  const onReset = () => {
    form.resetFields()
    navigate(-1)
  }

  return { onFinish, onReset, loading, saveForm, prepareValues }
}

export default useActionsProductAdvancedForm
