import { Popover } from 'antd'
import styled from 'styled-components'

const StyledPopover = styled(Popover)`
  & .ant-popover-inner {
    padding: 0 !important;
  }
  & .ant-popover .ant-popover-inner {
    padding: 0 !important;
  }
`
export { StyledPopover }
