import {
  BackgroundStyled,
  ChildrenWrapper,
  ColStyled,
  RowStyled,
  headerWrapper,
  layoutStyles,
  logoStyles,
  spaceStyles
} from './AuthLayout.styled'
import { Col, Grid, Image, Layout, Space, Typography, theme } from 'antd'

import { LanguageSelect } from 'modules/translation-module/domains/Translation/components'
import Logo from '__assets__/icons/logo.svg'
import PropTypes from 'prop-types'

const { useBreakpoint } = Grid

const AuthLayout = (props) => {
  const { children, title, subTitle } = props

  const screens = useBreakpoint()

  const {
    token: { colorTextBase }
  } = theme.useToken()

  return (
    <Layout>
      <Layout.Content style={layoutStyles}>
        <RowStyled screens={screens}>
          <ColStyled screens={screens} xl={12} md={24} sm={24} xs={24}>
            <ChildrenWrapper screens={screens}>
              <div style={headerWrapper}>
                <div>
                  <Image
                    src={Logo}
                    width={120}
                    preview={false}
                    style={logoStyles}
                  />
                </div>

                <div>
                  <LanguageSelect isIconExist />
                </div>
              </div>
              <Col xl={18} md={24} sm={24} xs={24}>
                <Space
                  direction="vertical"
                  className="flex items-center mb-32"
                  style={spaceStyles}>
                  <Typography.Title>{title}</Typography.Title>
                  <Typography.Text>{subTitle}</Typography.Text>
                </Space>
                {children}
              </Col>
            </ChildrenWrapper>
          </ColStyled>
          <BackgroundStyled colorTextBase={colorTextBase} />
        </RowStyled>
      </Layout.Content>
    </Layout>
  )
}

AuthLayout.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  isDarkMode: PropTypes.bool,
  setIsDarkMode: PropTypes.func,
  withBackButton: PropTypes.bool
}

export default AuthLayout
