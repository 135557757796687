import _ from 'lodash'

const getUploadingObjects = async (
  valuesMedia,
  initialMedia,
  uploadData,
  mediaObjectsPath,
  removeFilesCallback
) => {
  const computedMediaObjectValue = valuesMedia
    ? valuesMedia?.filter((item) => !item?.thumbUrl)
    : []

  const mediaObjects = [...computedMediaObjectValue, ...(uploadData || [])]

  const differenceObjects = _.differenceBy(
    initialMedia,
    computedMediaObjectValue,
    '_id'
  )

  const computedDeletedFiles = initialMedia?.length
    ? await removeFilesCallback(differenceObjects, mediaObjectsPath)
    : []

  const computedMediaObject = differenceObjects?.length
    ? _.differenceBy(mediaObjects, computedDeletedFiles, '_id')
    : mediaObjects

  const coverImage = computedMediaObject?.find((item) => item?.isCoverImage)

  if (coverImage) {
    const mediaObjectsWithoutCoverImage = computedMediaObject?.filter(
      (item) => item?.uid !== coverImage?.uid
    )
    return [coverImage, ...mediaObjectsWithoutCoverImage]
  }

  return computedMediaObject
}

export default getUploadingObjects
