import DottedDiv from './DottedBlock.styled'
import PropTypes from 'prop-types'

const DottedBlock = (props) => {
  const { left, right, height, className } = props
  return (
    <div className="flex full-width">
      {left}
      <div
        className={`flex px-8 flex-1 ${className || ''}`}
        style={{ height: height, paddingBottom: '5px' }}>
        <DottedDiv />
      </div>
      {right}
    </div>
  )
}

DottedBlock.propTypes = {
  height: PropTypes.string,
  left: PropTypes.node,
  right: PropTypes.node,
  className: PropTypes.string
}

export default DottedBlock
