import { Col, Form, Row } from 'antd'
import { doc, getFirestore } from 'firebase/firestore'
import { useCallback, useMemo } from 'react'

import { COLLECTIONS } from '__constants__/models'
import { CategorySelect } from 'domains/Category/components'
import { FieldsOptionsSimpleForm } from './components'
import { FormWrapper } from 'components'
import { KEYS } from '__constants__'
import PropTypes from 'prop-types'
import { TagListWithCreate } from 'domains/Tag/components'
import { useDocumentDataOnce } from 'react-firebase-hooks/firestore'
import { useTranslations } from 'hooks'

const useGetFormCategory = (form, getFieldName) => {
  const categoryId = Form.useWatch(getFieldName('categoryId'), form)
  const [category] = useDocumentDataOnce(
    categoryId ? doc(getFirestore(), COLLECTIONS.CATEGORIES, categoryId) : null
  )

  return category
}

const ProductDetailsForm = (props) => {
  const {
    initialValues,
    onSubmit,
    onCancel,
    showTitle,
    form,
    group,
    children,
    ...rest
  } = props

  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting new form instance from Form.useForm hook */
  const [baseForm] = Form.useForm()

  /* Getting used form from props or using base form */
  const usedForm = form || baseForm

  /* Resetting form on cancel */
  const handleCancel = () => {
    usedForm.resetFields()
    onCancel?.()
  }
  /* Submitting form on enter key press */
  const handleKeyPress = (e) => {
    if (e.key === KEYS.ENTER) {
      e.preventDefault()
      e.stopPropagation()
      usedForm?.submit()
    }
  }

  /* Generating form field names */
  const getFieldName = useCallback(
    (subGroup) => (group ? [...group, subGroup] : [subGroup]),
    [group]
  )

  const chosenTopCategoryId =
    Form.useWatch([...getFieldName('topCategoryId')], usedForm) || null

  const categoriesQuery = useMemo(
    () => ({
      where: [
        ['isTopLevel', '==', false],
        ['parentId', '==', chosenTopCategoryId]
      ]
    }),
    [chosenTopCategoryId]
  )
  const topCategoriesQuery = useMemo(
    () => ({
      where: [
        ['isTopLevel', '==', true],
        ['hasSubcategories', '==', true]
      ]
    }),
    []
  )

  const handleCategoryChange = () => {
    form.setFieldsValue({ product: { brandId: null, fields: null } })
  }
  const handleTopCategoryChange = () => {
    form.setFieldsValue({
      product: { brandId: null, fields: null, categoryId: null }
    })
  }

  const category = useGetFormCategory(form, getFieldName)

  const categoryColSpan = { xs: 24, sm: 24, md: 12 }
  return (
    <FormWrapper
      title={t('Product')}
      onFinish={onSubmit}
      initialValues={initialValues}
      onKeyPress={handleKeyPress}
      onReset={handleCancel}
      showTitle={showTitle}
      form={usedForm}
      group={group}
      formActions={null}
      {...rest}>
      <Row gutter={16}>
        <Col {...categoryColSpan}>
          <Form.Item
            name={getFieldName('topCategoryId')}
            label={t('Top category')}
            rules={[
              {
                required: true,
                message: t('Please select product top category!')
              }
            ]}>
            <CategorySelect
              placeholder={t('Please select product top category')}
              valueAsObject
              query={topCategoriesQuery}
              transformationLimit="1"
              onAfterChange={handleTopCategoryChange}
              group={getFieldName('topCategoryId')}
            />
          </Form.Item>
        </Col>
        <Col {...categoryColSpan}>
          <Form.Item
            name={getFieldName('categoryId')}
            label={t('Subcategory')}
            rules={[
              {
                required: true,
                message: t('Please select product subcategory!')
              }
            ]}>
            <CategorySelect
              disabled={!chosenTopCategoryId}
              placeholder={t('Please select product subcategory')}
              valueAsObject
              query={categoriesQuery}
              transformationLimit="1"
              onAfterChange={handleCategoryChange}
              group={getFieldName('categoryId')}
            />
          </Form.Item>
        </Col>
      </Row>

      {/* <Form.Item name={getFieldName('brandId')} label={t('Brand')}>
        <BrandSelect category={category} />
      </Form.Item> */}
      <FieldsOptionsSimpleForm
        category={category}
        getFieldName={getFieldName}
      />
      <Form.Item
        preserve
        name={getFieldName('tags')}
        label={t('Tags')}
        className="mb-0">
        <TagListWithCreate group={getFieldName('tags')} withTitle={false} />
      </Form.Item>
    </FormWrapper>
  )
}

ProductDetailsForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  showTitle: PropTypes.bool,
  form: PropTypes.any,
  group: PropTypes.array,
  children: PropTypes.node
}

export default ProductDetailsForm
