import { useMemo } from 'react'
import { useUser } from 'modules/session-module/contexts'

const useGetUserDisplayName = (userObject, loading) => {
  const { user } = useUser()

  const displayName = useMemo(() => {
    const userComputed = userObject || user
    const userLoadingComputed = userObject?.loading || user?.loading || loading

    if (!userComputed && userLoadingComputed) return null

    const fullUserNameExists = userComputed?.firstName && userComputed?.lastName
    const partialUserNameExists =
      userComputed?.firstName || userComputed?.lastName

    return fullUserNameExists
      ? `${userComputed?.firstName}  ${userComputed?.lastName}`
      : partialUserNameExists
      ? `${userComputed?.firstName || userComputed?.lastName}`
      : null
  }, [loading, user, userObject])

  return displayName
}

export default useGetUserDisplayName
