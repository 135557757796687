import { Avatar, Col, Grid, Row, Typography } from 'antd'
import { useEffect, useMemo, useState } from 'react'

import { Box } from 'components'
import { ChatButton } from 'modules/chat-module/domains/Chat/components'
import { Icon } from '@qonsoll/icons'
import { Link } from 'react-router-dom'
import MOMENT_FORMATS from '__constants__/enums/momentFormats'
import PropTypes from 'prop-types'
import { RatingAndIdentifyDetails } from './components'
import { StyledDisplayName } from './ProductLessorView.styles'
import { checkMatchingChats } from 'modules/chat-module/domains/Chat/helpers'
import moment from 'moment'
import noProfile from '__assets__/illustrations/no-profile.png'
import { useGetUserProfilePaths } from 'domains/User/hooks'
import { useListenUserChats } from 'modules/chat-module/domains/Chat/hooks'
import { useTranslations } from 'hooks'
import { useUser } from 'modules/session-module/contexts'

const { Text } = Typography
const { useBreakpoint } = Grid

const ProductLessorView = (props) => {
  const { user } = props
  const { user: userProvider, loading } = useUser()
  const { xl } = useBreakpoint()
  const { t } = useTranslations()

  const isVerified = user?.isVerified
  const bio = user?.bio
  const formattedJoinedDate = moment(user?._createdAt?.toDate()).format(
    MOMENT_FORMATS.MONTH_YEAR
  )
  const userDisplayName = `${user?.firstName} ${user?.lastName}`
  const joinedInLabel = `${t('Joined in')} ${formattedJoinedDate}`
  const buttonText = xl ? t('Contact lessor') : ''
  const buttonIcon = xl ? null : (
    <Icon name="MessageTextSquare1Outlined" size={18} />
  )

  const disabledChatButton = useMemo(
    () => (loading ? true : userProvider?._id === user?._id),
    [user?._id, userProvider?._id, loading]
  )
  const [chats] = useListenUserChats(userProvider?._id)
  const [oppositeUserChats] = useListenUserChats(user?._id)

  const [isChatExist, setIsChatExist] = useState(
    checkMatchingChats({
      oppositeUsersChats: oppositeUserChats,
      userId: userProvider?._id,
      userChats: chats
    })
  )

  useEffect(() => {
    const chatExists = checkMatchingChats({
      oppositeUsersChats: oppositeUserChats,
      userId: userProvider?._id,
      userChats: chats
    })
    setIsChatExist(chatExists)
  }, [chats, oppositeUserChats, userProvider?._id])

  const { pagePath } = useGetUserProfilePaths({ user })

  return (
    <>
      <Row
        align={`center ${bio || (!xl && 'mb-16')}`}
        className="flex flex-nowrap">
        <Col className="mr-16">
          <Avatar src={user?.avatarUrl || noProfile} size={48} />
        </Col>
        <Col flex="auto">
          <Box className="flex  gap-4 mb-2 text-align-start">
            <Text className="word-break-init mr-8">{t('Rented out by')}</Text>
            <Link to={pagePath}>
              <StyledDisplayName level={5}>{userDisplayName}</StyledDisplayName>
            </Link>
          </Box>
          <Text type="secondary">{joinedInLabel}</Text>
        </Col>

        {xl && (
          <Col className="flex align-start">
            <RatingAndIdentifyDetails bio={bio} isVerified={isVerified} />
          </Col>
        )}
        <Col className="flex align-start ml-32">
          <ChatButton
            isChatExist={isChatExist}
            chats={chats}
            disabled={disabledChatButton}
            oppositeUserId={user?._id}
            btnText={buttonText}
            className="flex align-center justify-center"
            icon={buttonIcon}
          />
        </Col>
      </Row>
      {!xl && <RatingAndIdentifyDetails isVerified={isVerified} bio={bio} />}
      {bio && (
        <Row>
          <Col>
            <Text>{bio}</Text>
          </Col>
        </Row>
      )}
    </>
  )
}

ProductLessorView.propTypes = {
  product: PropTypes.object,
  address: PropTypes.object,
  user: PropTypes.object
}

export default ProductLessorView
