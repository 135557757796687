import { ENDPOINTS } from 'modules/stripe-module/__constants__'

const API_URL = process.env.REACT_APP_FIREBASE_FUNCTIONS_API_URL

const getStripeBalance = async (accountId) => {
  try {
    const apiEndpoint = `${API_URL}/${ENDPOINTS.GET_STRIPE_BALANCE}`

    const result = await fetch(apiEndpoint, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        accountId
      })
    })

    const balance = (await result.json()).data

    return balance
  } catch (error) {
    throw new Error(`Error during getting an account balance: ${error}`)
  }
}

export default getStripeBalance
