import { APP_PATHS, SETTINGS_PATHS } from '__constants__/paths'

import { ENDPOINTS } from '__constants__/environment'
import { fetchApi } from 'services/api/rest'
import firebase from 'firebase/compat/app'
import { useAppNotifications } from 'contexts/AppNotificationsContext'
import { useNavigate } from 'react-router-dom'
import { useTranslations } from 'hooks'

const firebaseFunctionsUrl = process.env.REACT_APP_FIREBASE_FUNCTIONS_API_URL
const appUrl = process.env.REACT_APP_URL

const useLoginWithVipps = () => {
  const { t } = useTranslations()
  const navigate = useNavigate()
  const { sendErrorNotification, sendSuccessNotification } =
    useAppNotifications()

  const loginWithVipps = async (code, state, _id) => {
    try {
      const redirect_uri = `${appUrl}${APP_PATHS.VIPPS_CALLBACK}`

      const fetchObject = {
        customPath: `${firebaseFunctionsUrl}/${ENDPOINTS.LOGIN_BY_VIPPS}`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: {
          code: code,
          state: state,
          redirectUri: redirect_uri,
          userId: _id ?? null
        }
      }

      const [response, responseData] = await fetchApi(fetchObject)
      if (!response?.ok) {
        sendErrorNotification({
          message: t('Error'),
          description: response?.message || t('Error while logging by vipps')
        })
        return
      }

      const { token, isNewUser } = responseData.data

      if (_id) {
        sendSuccessNotification({
          message: t('Success'),
          description: t('You have successfully confirmed Vipps')
        })
        return navigate(SETTINGS_PATHS.VERIFICATION)
      } else {
        await firebase.auth().signInWithCustomToken(token)
      }

      if (isNewUser) {
        sendSuccessNotification({
          message: t('Success'),
          description: t('You have successfully registered')
        })
      }

      navigate(APP_PATHS.DASHBOARD)
    } catch (error) {
      sendErrorNotification({
        message: t('Error'),
        description: t("Can't login by vipps")
      })
      navigate('/')
    }
  }
  return loginWithVipps
}

export default useLoginWithVipps
