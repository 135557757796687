import { Checkbox, Form, Input, Select, Space, Typography } from 'antd'

import { FIELD_TYPES } from '__constants__/enums'
import PropTypes from 'prop-types'
import { useTranslations } from 'hooks'

const FieldsOptionsSimpleForm = (props) => {
  const { category, getFieldName } = props
  return (
    <>
      {category?.fields?.map((field, index) => (
        <Form.Item
          name={[...getFieldName('fields'), index]}
          initialValue={{
            value: null,
            name: field.name,
            type: field.type,
            searchName: field?.searchName || field?.name
          }}
          key={index}
          label={field.name}>
          <FieldTypeSimpleForm {...field} />
        </Form.Item>
      ))}
    </>
  )
}

const FieldTypeSimpleForm = (props) => {
  const { type, options, name, onChange, searchName, value, ...rest } = props

  /* Getting translations instance */
  const { t } = useTranslations()

  const handleChange = (v) =>
    onChange?.({ value: v, name, type, searchName: searchName || name })

  if (type === FIELD_TYPES.STRING) {
    const placeholder = t('Please enter') + ' ' + name
    return (
      <Input
        value={value?.value}
        onChange={(e) => handleChange(e.target.value)}
        placeholder={placeholder}
        {...rest}
      />
    )
  }
  if (type === FIELD_TYPES.CHECKBOX)
    return (
      <Space>
        <Checkbox
          checked={value?.value}
          onChange={() =>
            handleChange(value?.value === options?.[0] ? null : options?.[0])
          }
          {...rest}
        />
        <Typography.Text>{options?.[0]}</Typography.Text>
      </Space>
    )

  if (type === FIELD_TYPES.ONE_FROM_LIST) {
    const placeholder = t('Please select') + ' ' + name
    const _options = options.map((option) => ({ label: option, value: option }))

    return (
      <Select
        type="number"
        placeholder={placeholder}
        value={value?.value}
        options={_options}
        onChange={handleChange}
        {...rest}
      />
    )
  }

  return null
}

FieldsOptionsSimpleForm.propTypes = {
  category: PropTypes.object,
  getFieldName: PropTypes.func
}

FieldTypeSimpleForm.propTypes = {
  type: PropTypes.string,
  options: PropTypes.array,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.any,
  searchName: PropTypes.string
}

export default FieldsOptionsSimpleForm
