import { Button, Col, Form, Input, Row } from 'antd'
import { Fragment, useState } from 'react'
import { rowStyles, widthStyles } from './SignupForm.styled'

import { LS } from 'modules/session-module/__constants__'
import { Label } from 'modules/session-module/domains/Session/components'
import { PhoneInput } from 'components/lib'
import PropTypes from 'prop-types'
import { useTranslations } from 'hooks'

const SignupForm = ({
  signup,
  onError,
  onSuccess,
  authSwitchText,
  authSwitchPath,
  authSwitchLinkText,
  ...rest
}) => {
  const { t } = useTranslations()
  const [loading, setLoading] = useState(false)

  const onFinish = (credentials) => {
    /* Setting the loading state to true, then calling the signup function, then setting the loading
    state to false. */

    setLoading(true)

    localStorage.setItem(
      LS.SIGNUP_DATA,
      JSON.stringify({
        firstName: credentials?.firstName,
        lastName: credentials?.lastName,
        phone: credentials?.phone
      })
    )
    signup({ credentials, onError, onSuccess })
    setLoading(false)
  }

  const resetMarginBottom = { style: { marginBottom: 18 } }

  const passwordRules = ({ getFieldValue }) => ({
    validator(_, value) {
      if (value && getFieldValue('password') !== value) {
        return Promise.reject(
          new Error(t('The two passwords that you entered do not match!'))
        )
      }
      return Promise.resolve()
    }
  })

  const rules = {
    firstName: [
      {
        required: true,
        message: t('Please enter valid first name')
      }
    ],
    lastName: [
      {
        required: true,
        message: t('Please enter valid last name')
      }
    ],
    email: [
      {
        type: 'email',
        required: true,
        message: t('Please enter valid email')
      }
    ],
    phone: [
      {
        required: true,
        message: t('Please, enter your phone number')
      }
    ],
    password: [
      { required: true, message: t('Please, enter your password') },
      { min: 6, message: t('Password must be at least 6 characters') }
    ],
    confirm: [
      {
        required: true,
        message: t('Please confirm your password!')
      },
      passwordRules
    ]
  }

  return (
    <Fragment>
      <Form
        onFinish={onFinish}
        layout="vertical"
        requiredMark="optional"
        {...rest}>
        <Row className="justify-between" style={rowStyles}>
          <Col style={widthStyles}>
            <Form.Item
              {...resetMarginBottom}
              label={Label({ value: 'First name', htmlFor: 'firstName' })}
              name="firstName"
              rules={rules.firstName}>
              <Input size="large" autoFocus placeholder="John" />
            </Form.Item>
          </Col>
          <Col style={widthStyles}>
            <Form.Item
              {...resetMarginBottom}
              label={Label({ value: 'Last name', htmlFor: 'lastName' })}
              name="lastName"
              rules={rules.lastName}>
              <Input size="large" placeholder="Doe" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          {...resetMarginBottom}
          label={Label({ value: 'Email', htmlFor: 'email' })}
          name="email"
          rules={rules.email}>
          <Input size="large" placeholder="john.doe@example.com" />
        </Form.Item>
        <Form.Item
          {...resetMarginBottom}
          label={Label({ value: 'Phone', htmlFor: 'phone' })}
          name="phone"
          rules={rules.phone}>
          <PhoneInput />
        </Form.Item>
        <Form.Item
          {...resetMarginBottom}
          label={Label({ value: 'Password', htmlFor: 'password' })}
          name="password"
          hasFeedback
          rules={rules.password}>
          <Input.Password
            size="large"
            placeholder={t('At least 6 characters')}
          />
        </Form.Item>
        <Form.Item
          {...resetMarginBottom}
          label={Label({ value: 'Confirm password', htmlFor: 'confirm' })}
          className="pb-16"
          name="confirm"
          dependencies={['password']}
          hasFeedback
          rules={rules.confirm}>
          <Input.Password
            size="large"
            placeholder={t('Passwords should match')}
          />
        </Form.Item>

        <Button
          type="primary"
          htmlType="submit"
          size="large"
          block
          loading={loading}>
          {t('Create an account')}
        </Button>
      </Form>
    </Fragment>
  )
}

SignupForm.propTypes = {
  form: PropTypes.object,
  onError: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onFinish: PropTypes.func,
  onFinishFailed: PropTypes.func,
  signup: PropTypes.func.isRequired,
  authSwitchPath: PropTypes.any,
  authSwitchText: PropTypes.any,
  authSwitchLinkText: PropTypes.any
}

export default SignupForm
