import { COLLECTIONS } from '__constants__'
import { doc } from 'firebase/firestore'
import { firestore } from 'services/firebase'
import { useDocumentData } from 'react-firebase-hooks/firestore'

/**
 * It fetches a address document from Firestore and returns it
 * @param product - The product object that we're getting the address for.
 * @returns An array with four values. The first value is the address object, and the second value is a
 * boolean that indicates whether the address is loading.
 */
const useGetAddress = (addressId) => {
  const query = addressId && doc(firestore, COLLECTIONS.ADDRESSES, addressId)

  return useDocumentData(query)
}

export default useGetAddress
