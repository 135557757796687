import PropTypes from 'prop-types'
import { Tooltip } from 'antd'

const ConditionalTooltipWrapper = (props) => {
  const { withTooltip, children, title, tooltipPlacement } = props

  return withTooltip ? (
    <Tooltip placement={tooltipPlacement || 'top'} title={title}>
      {/* Box is needed to trigger tooltip even if the children-node in disabled status */}
      <div style={{ cursor: 'not-allowed' }}>{children}</div>
    </Tooltip>
  ) : (
    children
  )
}

ConditionalTooltipWrapper.propTypes = {
  withTooltip: PropTypes.bool,
  children: PropTypes.node,
  title: PropTypes.string,
  tooltipPlacement: PropTypes.string,
  tooltipVisible: PropTypes.bool
}

export default ConditionalTooltipWrapper
