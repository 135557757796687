import { ENDPOINTS } from 'modules/stripe-module/__constants__'

const API_URL = process.env.REACT_APP_FIREBASE_FUNCTIONS_API_URL

const getStripeAccountStatus = async (accountId) => {
  try {
    const apiEndpoint = `${API_URL}/${ENDPOINTS.GET_STRIPE_ACCOUNT_STATUS}`

    const result = await fetch(apiEndpoint, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        accountId
      })
    })
    const status = (await result.json()).data
    return status
  } catch (error) {
    throw new Error(`Error during getting an account status: ${error}`)
  }
}

export default getStripeAccountStatus
