import styled from 'styled-components'
import { Tag } from 'antd'

const TagStyled = styled(Tag)`
  line-height: 12px;
  padding: 5px 8px;
  margin-right: 0;
`

export default TagStyled
