import { useTranslations } from 'hooks'
import PropTypes from 'prop-types'

const Label = ({ value, htmlFor }) => {
  const { t } = useTranslations()

  return (
    <label style={{ fontWeight: '600' }} htmlFor={htmlFor}>
      {t(value)}
    </label>
  )
}

Label.propTypes = {
  value: PropTypes.string.isRequired,
  htmlFor: PropTypes.string.isRequired
}

export default Label
