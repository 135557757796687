const styles = {
  fieldTitleProps: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '250px',
    whiteSpace: 'nowrap'
  },
  fieldRowProps: {
    justifyContent: 'center',
    alignItems: 'center',
    py: '4px',
    mb: 2
  },
  imageProps: {
    height: '48px',
    width: '64px',
    objectFit: 'cover',
    marginRight: '16px'
  },
  fullWidthDivider: {
    marginRight: '-24px',
    marginLeft: '-24px',
    width: 'calc(100% + 48px)'
  }
}

export default styles
