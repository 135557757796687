import { Breadcrumbs, PageActions, PageLayout, Spinner } from 'components'
import { Col, Row } from 'antd'

import { CustomerList } from 'domains/Customer/components'
import { PATHS } from '__constants__'
import PropTypes from 'prop-types'
import { useCustomerBaseActions } from 'domains/Customer/hooks'
import { useGetCustomersByIds } from 'domains/Customer/hooks'
import { useTranslations } from 'hooks'
import { useUser } from 'modules/session-module/contexts'

const CustomersAllPage = (props) => {
  const { isListWithCreate, withHeader = true } = props

  /* Getting translations instance */
  const { t } = useTranslations()

  /* Getting base actions */
  const listActions = useCustomerBaseActions()

  /* Configuring page header */
  const headingProps = {
    title: t('Customers'),
    textAlign: 'left'
  }

  /* Configuring page actions */
  const actions = {
    create: Boolean(
      PATHS.AUTHENTICATED.CUSTOMER_CREATE || PATHS.AUTHENTICATED.CUSTOMER_WIZARD
    )
  }

  /* Extracting base actions */
  const { onBack, onCreate } = listActions
  const { user } = useUser()

  //user?.customerIds

  const [transformedCustomers, loading] = useGetCustomersByIds(
    user?.customerIds
  )

  return (
    <PageLayout
      actions={
        withHeader ? (
          <PageActions actions={actions} createText={t('Create customer')} />
        ) : null
      }
      onBack={withHeader ? onBack : null}
      breadcrumbs={withHeader ? <Breadcrumbs /> : null}
      headingProps={withHeader ? headingProps : null}
      area="customers">
      <Row gutter={[24, 16]}>
        {!user || loading ? (
          <Col flex="auto">
            <Spinner height="70vh" text={t('Customers loading')} />
          </Col>
        ) : (
          <Col flex="auto">
            <CustomerList
              span={{ xs: 24, sm: 12, md: 8, lg: 8, xl: 6, xxl: 4 }}
              actions={{ onCreate }}
              isListWithCreate={isListWithCreate}
              customers={transformedCustomers}
            />
          </Col>
        )}
      </Row>
    </PageLayout>
  )
}

CustomersAllPage.propTypes = {
  isListWithCreate: PropTypes.bool,
  withHeader: PropTypes.bool
}

export default CustomersAllPage
