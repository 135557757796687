import { Typography } from 'antd'

import styled from 'styled-components'

export const StyledText = styled(Typography.Text)`
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  color: ${({ equalId, color }) => (equalId ? color : 'inherit')};
`

export const StyledNumber = styled.span`
  color: #848b95;
  margin-left: 4px;
`
