import { Button, Col, Divider, Drawer, Row, Typography } from 'antd'
import { ParentCategoriesList, SubcategoriesList } from './components'
import { ColStyled, TextStyled } from './CategoriesDrawer.styles'
import { Icon } from '@qonsoll/icons'
import PropTypes from 'prop-types'
import { useGetGroupedCategories } from 'domains/Category/hooks'
import { useTranslations } from 'hooks'
import { useGetProductsNumber } from 'domains/Category/hooks'

const { Title } = Typography

const CategoriesDrawer = (props) => {
  const { open, handleClose } = props

  const { t, language } = useTranslations()

  const {
    topLevelCategories,
    subcategories,
    groupedByParentId,
    isRoot,
    selectedCategory,
    handleSelectCategory,
    handleSelectSubcategory,
    handleGoBack
  } = useGetGroupedCategories({ handleCloseDrawer: handleClose })

  const productsNumber = useGetProductsNumber(selectedCategory?._id)

  return (
    <Drawer
      rootClassName="categories-drawer"
      placement="bottom"
      closable={false}
      onClose={handleClose}
      open={open}>
      {isRoot ? (
        <Row>
          <Col span={24} className="mb-24">
            <Title level={3}>{t('All categories')}</Title>
          </Col>
          <Col span={24}>
            <ParentCategoriesList
              categories={topLevelCategories}
              handleSelectCategory={handleSelectCategory}
            />
          </Col>
        </Row>
      ) : (
        <>
          <Row className="mb-24" style={{ alignItems: 'center' }}>
            <Col>
              <Button
                onClick={handleGoBack}
                type="text"
                className="flex align-center justify-center"
                icon={<Icon name="ArrowLeftOutlined" size={16} />}
              />
            </Col>
            <Col>
              <Divider type="vertical" className="full-height" />
            </Col>
            <ColStyled>
              <Title level={3}>
                {selectedCategory?.names?.[language.toUpperCase()] ||
                  selectedCategory?.name}
              </Title>
            </ColStyled>
            <Col>
              <TextStyled type="secondary">({productsNumber})</TextStyled>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <SubcategoriesList
                handleSelectSubcategory={handleSelectSubcategory}
                subcategories={subcategories}
                groupedByParentId={groupedByParentId}
              />
            </Col>
          </Row>
        </>
      )}
    </Drawer>
  )
}
CategoriesDrawer.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func
}
export default CategoriesDrawer
