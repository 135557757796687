import { ORDER_STATUSES_LABELS } from '__constants__'
import PropTypes from 'prop-types'
import { StatusDot } from './components'
import { Typography } from 'antd'

const OrderStatus = (props) => {
  const { order } = props

  return (
    <div className="flex align-center">
      <StatusDot status={order?.status} className="mr-8" />
      <Typography.Text>
        {ORDER_STATUSES_LABELS?.[order?.status]}
      </Typography.Text>
    </div>
  )
}

OrderStatus.propTypes = {
  order: PropTypes.object
}

export default OrderStatus
