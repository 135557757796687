import { useNavigate, generatePath } from 'react-router-dom'
import { COLLECTIONS, PATHS } from '__constants__'
import { useTranslations, useEvent } from 'hooks'
import { deleteDocument } from 'services/api/firebase'
import { message } from 'antd'
import { useMemo } from 'react'

/* Path's */
const createPath =
  PATHS.AUTHENTICATED.NOTIFICATION_CREATE ||
  PATHS.AUTHENTICATED.NOTIFICATION_WIZARD
const editPath = PATHS.AUTHENTICATED.NOTIFICATION_EDIT
const showPath = PATHS.AUTHENTICATED.NOTIFICATION_SHOW

const useNotificationBaseActions = ({ notificationId, actions } = {}) => {
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting navigate instance */
  const navigate = useNavigate()

  /* Function to handle back click */
  const handleBack = useEvent(() => navigate(-1))

  /* Handling opening of the create page */
  const handleCreate = useEvent(() => {
    navigate(createPath)
  })

  /* Handling opening of the show page */
  const handleOpen = useEvent(() => {
    const pathParams = { notificationId }
    const path = generatePath(showPath, pathParams)
    navigate(path)
  })

  /* Handling opening of the edit page or change view to edit mode */
  const handleEdit = useEvent(() => {
    const pathParams = { notificationId }
    const path = generatePath(editPath, pathParams)
    navigate(path)
  })

  const handleDelete = useEvent(async () => {
    try {
      await deleteDocument(COLLECTIONS.NOTIFICATIONS, notificationId)
      message.success(t('Notification successfully deleted'))
    } catch (error) {
      message.error(error.message)
    }
  })

  return useMemo(
    () => ({
      onBack: handleBack,
      onCreate: handleCreate,
      onOpen: handleOpen,
      onEdit: handleEdit,
      onDelete: handleDelete,
      ...(actions || {}),
      createPath,
      editPath,
      showPath
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      actions?.onBack,
      actions?.onCreate,
      actions?.onDelete,
      actions?.onEdit,
      actions?.onOpen,
      handleBack,
      handleCreate,
      handleDelete,
      handleEdit,
      handleOpen
    ]
  )
}

export default useNotificationBaseActions
