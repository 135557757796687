import lookup from 'country-code-lookup'

const getFormattedAddress = (address) => {
  // Get a country's short code: NO
  const countryShortCode = address?.country || 'NO'

  // Get a full name of country by short code:  NO -> Norway
  const country = lookup.byInternet(countryShortCode)?.country

  try {
    if (address) {
      // Change a short code in an input object to a full name
      address.country = country

      // Remove empty data from address object
      const addressValues = Object.values(address)?.filter((value) => value)

      // Merge all parts of address to one
      const result = addressValues.join(', ')
      return result
    } else return country
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error during getting formatted address', error.message)
  }
}

export default getFormattedAddress
