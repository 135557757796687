import { Form } from 'antd'
import { useState } from 'react'

const useProductPreviewActions = ({ form }) => {
  const [isPreview, setIsPreview] = useState(false)

  const handlePreview = () => setIsPreview((prev) => !prev)

  const productPreviewValue = Form.useWatch('product', form)
  const addressPreviewValue = Form.useWatch('address', form)

  const formStyles = {
    opacity: isPreview ? '0' : '1',
    position: isPreview ? 'absolute' : 'static',
    transition: 'opacity ease 0.5s'
  }
  return {
    product: productPreviewValue,
    address: addressPreviewValue,
    isPreview,
    handlePreview,
    formStyles
  }
}

export default useProductPreviewActions
