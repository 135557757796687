import { Row, Col, Typography } from 'antd'
import { useState } from 'react'
import { useTranslations } from 'hooks'
import PropTypes from 'prop-types'
import {
  ConnectDetailsSimpleForm,
  ConnectProvideDocumentSimpleForm
} from 'modules/stripe-module/domains/Connect/components'
import { StepTitleStyled, StepsStyled } from './ConnectAdvancedForm.styled'
import { Icon } from '@qonsoll/icons'
import { useUser } from 'modules/session-module/contexts'
import { STRIPE_ACCOUNT_STATUSES } from '__constants__'

const { Text } = Typography

const ConnectAdvancedForm = (props) => {
  const { connectedAccount, accountId } = props

  const { t } = useTranslations()
  const { user } = useUser()

  const externalAccount = connectedAccount?.external_accounts?.data?.[0]
  const requirements = connectedAccount?.requirements
  const currentlyDue = requirements?.currently_due

  const [currentStep, setCurrentStep] = useState(0)

  const handleNextStep = () => {
    setCurrentStep((prev) => prev + 1)
  }

  const stepItems = [
    {
      key: 'DETAILS',
      title: (
        <StepTitleStyled>
          <Text>{t('Providing details')}</Text>
        </StepTitleStyled>
      ),
      content: (
        <ConnectDetailsSimpleForm
          handleNextStep={handleNextStep}
          externalAccount={externalAccount}
          accountId={accountId}
        />
      ),
      columnProps: { xs: 24, md: 12, xl: 8 }
    },
    {
      key: 'DOCUMENTS',
      title: (
        <StepTitleStyled>
          <Text>{t('Proof of identity document')}</Text>
          {user?.stripeAccountStatus === STRIPE_ACCOUNT_STATUSES.ENABLED &&
            !user?.isStripeAccountVerified && (
              <Icon name="AlertDangerWarningFilled" fill="#faad14" />
            )}
        </StepTitleStyled>
      ),
      content: (
        <ConnectProvideDocumentSimpleForm
          accountId={accountId}
          requirements={requirements}
          currentlyDue={currentlyDue}
        />
      ),
      columnProps: { xs: 24, md: 16, lg: 24, xl: 16 }
    }
  ]

  return (
    <>
      <Row>
        <Col xs={24} md={16} lg={24} xl={16} className="mb-12">
          <StepsStyled current={currentStep} items={stepItems} />
        </Col>
      </Row>
      <Row>
        <Col {...stepItems[currentStep].columnProps}>
          {stepItems[currentStep].content}
        </Col>
      </Row>
    </>
  )
}

ConnectAdvancedForm.propTypes = {
  isEdit: PropTypes.bool,
  connectedAccount: PropTypes.object,
  accountId: PropTypes.string
}

export default ConnectAdvancedForm
