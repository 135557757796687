import { RecaptchaVerifier, getAuth, linkWithPhoneNumber } from 'firebase/auth'
import { useCallback, useRef, useState } from 'react'

import { LS } from '__constants__'
import { message } from 'antd'
import { useAppNotifications } from 'contexts'
import { useTranslations } from '@qonsoll/translation'

const useConfirmPhone = (phoneNumber, setLeftSeconds, setIsOpen) => {
  const { t } = useTranslations()
  const { sendErrorNotification } = useAppNotifications()

  const recaptchaRef = useRef(null)
  const confirmationObjectRef = useRef(null)
  const [loading, setLoading] = useState(false)

  const getRecaptcha = useCallback((auth) => {
    if (recaptchaRef?.current) return recaptchaRef.current
    const recaptcha = new RecaptchaVerifier(
      'sign-in-button',
      { size: 'invisible' },
      auth
    )
    recaptchaRef.current = recaptcha

    return recaptcha
  }, [])

  const clearRecaptcha = useCallback(async () => {
    try {
      // if (!recaptchaRef.current?.destroyed)
      await recaptchaRef.current?.reset()
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error during destroy captcha', error?.message)
    }
  }, [recaptchaRef])

  const sendVerificationMessage = async () => {
    try {
      setLoading(true)

      const auth = getAuth()

      const recaptcha = getRecaptcha(auth)

      await recaptcha?.render()

      const confirmationObject = await linkWithPhoneNumber(
        auth.currentUser,
        phoneNumber,
        recaptcha
      )

      if (confirmationObject) confirmationObjectRef.current = confirmationObject

      const leftSeconds = { hours: 0, minutes: 1, seconds: 1 }
      setIsOpen(true)
      setLeftSeconds(leftSeconds)
      localStorage.setItem(
        LS.LAST_SENDED_PHONE,
        JSON.stringify({ time: new Date(), leftSeconds })
      )
    } catch (error) {
      sendErrorNotification({
        message: t('Something went wrong during confirm phone number'),
        description: error?.message
      })
    } finally {
      setLoading(false)
    }
  }

  const confirmPhone = useCallback(
    async (code, verifyCallback) => {
      try {
        const errorMessage = t('Error during verification phone')

        if (!code || code?.length < 6)
          return sendErrorNotification({
            message: errorMessage,
            description: t('Code is not valid')
          })

        await confirmationObjectRef.current?.confirm(code)

        message.success(t('Phone successfully verified'))
        await clearRecaptcha()
        verifyCallback?.()
      } catch (error) {
        sendErrorNotification({
          message: t('Error during verification phone'),
          description: error?.message
        })
      }
    },
    [confirmationObjectRef, t, sendErrorNotification, clearRecaptcha]
  )

  return { sendVerificationMessage, confirmPhone, clearRecaptcha, loading }
}

export default useConfirmPhone
