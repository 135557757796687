import './SearchHeader.css'

import { InputStyled, SearchHeaderStyled } from './SearchHeader.styles'
import { useScreen, useTranslations } from 'hooks'

import { Icon } from '@qonsoll/icons'
import ProductsSearchAutocomplete from 'domains/Product/components/ProductsSearchAutocomplete'
import PropTypes from 'prop-types'
import { theme } from 'antd'

const SearchHeader = (props) => {
  const {
    collapsed,
    onSelect,
    setSearchProduct,
    searchProduct,
    productSearchOptions
  } = props

  const token = theme.useToken().token
  const { t } = useTranslations()
  const { xs } = useScreen()

  const placeholder = `${t('Search anything')}...`

  return (
    <SearchHeaderStyled xs={xs} theme={token}>
      <ProductsSearchAutocomplete
        isLocations={false}
        setSearchProduct={setSearchProduct}
        searchProduct={searchProduct}
        onSelect={onSelect}
        productSearchOptions={productSearchOptions}
        popupClassName={
          xs
            ? 'search-popup-xs'
            : collapsed
            ? 'search-popup-collapsed'
            : 'search-popup'
        }
        input={
          <InputStyled
            prefix={
              <Icon
                name="SearchMdOutlined"
                fill="currentColor"
                size={14}
                mr={1}
              />
            }
            placeholder={placeholder}
            bordered={false}
          />
        }
      />
    </SearchHeaderStyled>
  )
}

SearchHeader.propTypes = {
  collapsed: PropTypes.bool,
  setSearchProduct: PropTypes.func,
  searchProduct: PropTypes.string,
  productSearchOptions: PropTypes.bool,
  onSelect: PropTypes.func
}

export default SearchHeader
