import { Grid, theme } from 'antd'

import { ComplaintAdvancedForm } from 'domains/Complaint/components'
import { DrawerBottom } from 'components'
import PropTypes from 'prop-types'
import { useActionsComplaintAdvancedForm } from 'domains/Complaint/components/ComplaintAdvancedForm/hooks'
import { useInitializeComplaintAdvancedForm } from 'domains/Complaint/hooks'

const { useBreakpoint } = Grid

const OrderComplaintBottomDrawer = (props) => {
  const { productId, orderId, complaintDrawerVisible, onComplaintFormToggle } =
    props

  const { form } = useInitializeComplaintAdvancedForm(productId)
  const { onFinish } = useActionsComplaintAdvancedForm({
    form,
    productId,
    orderId
  })

  const changeComplaintVisibility = (e) => {
    e.stopPropagation()
    if (
      e.target.id === 'review-form-bottom-drawer' ||
      // handles btn-icon click
      e.target.nodeName === 'svg' ||
      e.target.nodeName === 'path' ||
      e.target.noName === 'BUTTON' ||
      e.target.id === 'review-form-toggler'
    ) {
      onComplaintFormToggle?.(e)
    }
  }

  const { xs } = useBreakpoint()
  const token = theme.useToken().token

  return (
    <DrawerBottom
      id="review-form-bottom-drawer"
      xs={xs}
      isOpened={complaintDrawerVisible}
      theme={token}
      onClick={(e) => changeComplaintVisibility(e)}>
      <ComplaintAdvancedForm
        onSubmit={onFinish}
        onComplaintFormToggle={onComplaintFormToggle}
        form={form}
      />
    </DrawerBottom>
  )
}

OrderComplaintBottomDrawer.propTypes = {
  productId: PropTypes.string,
  complaintDrawerVisible: PropTypes.bool,
  onComplaintFormToggle: PropTypes.func,
  orderId: PropTypes.string
}

export default OrderComplaintBottomDrawer
