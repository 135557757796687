const DATA_TYPES = {
  HAS_ONE: 'HAS_ONE',
  HAS_MANY: 'HAS_MANY'
}

const DATA_TYPES_LABELS = {
  HAS_ONE: 'Has one',
  HAS_MANY: 'Has many'
}

const DATA_TYPES_OPTIONS = Object.keys(DATA_TYPES_LABELS).map((key) => ({
  label: DATA_TYPES[key],
  value: key
}))

export default DATA_TYPES
export { DATA_TYPES_OPTIONS, DATA_TYPES, DATA_TYPES_LABELS }
