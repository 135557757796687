import { Button, Space, Tooltip } from 'antd'
import { Fragment, useMemo } from 'react'
import { PRODUCT_STATUSES } from '__constants__'

import { Icon } from '@qonsoll/icons'
import PropTypes from 'prop-types'
import { useTranslations } from 'hooks'

const ProductFormActions = (props) => {
  const {
    submitLoading,
    onReset,
    form,
    handleChooseSaveMethod,
    isPrevStepDisabled,
    isNextStepDisabled,
    prevStep,
    nextStep,
    isFirstStep,
    isLastStep,
    isFormValid,
    onFinish,
    saveMethod,
    isPreview,
    handlePreview,
    product
  } = props

  /* Getting translations instance */
  const { t } = useTranslations()

  const isDraftMethod = useMemo(
    () => saveMethod === PRODUCT_STATUSES.DRAFT,
    [saveMethod]
  )
  const isPublishedMethod = useMemo(
    () => saveMethod === PRODUCT_STATUSES.PUBLISHED,
    [saveMethod]
  )

  const isDraftProduct = useMemo(
    () => product?.status === PRODUCT_STATUSES.DRAFT,
    [product?.status]
  )

  const isPublishedProduct = useMemo(
    () => product?.status === PRODUCT_STATUSES.PUBLISHED,
    [product?.status]
  )

  const publishBtnTooltip = useMemo(() => {
    const productCanNotBePublishedTooltip = !isFormValid
      ? t('Fill in the required fields to publish')
      : null

    const productCanBePublishedTooltip = isPublishedProduct
      ? t('Product is already published')
      : null

    return productCanNotBePublishedTooltip || productCanBePublishedTooltip
  }, [isFormValid, isPublishedProduct, t])

  const saveAsDraftBtnTooltip = useMemo(
    () => (isDraftProduct ? t('Product is already saved as draft') : null),
    [isDraftProduct, t]
  )

  const isDisabledDraftBtn = useMemo(() => isDraftProduct, [isDraftProduct])
  const isDisabledPublishBtn = useMemo(
    () => isPublishedProduct || !isFormValid,
    [isPublishedProduct, isFormValid]
  )

  const saveAsDraft = () => {
    handleChooseSaveMethod(PRODUCT_STATUSES.DRAFT)
    onFinish?.(PRODUCT_STATUSES.DRAFT)
  }
  const publishProduct = () => {
    handleChooseSaveMethod(PRODUCT_STATUSES.PUBLISHED)
    form.submit()
  }
  return (
    <Space>
      {isPreview ? (
        <Button
          onClick={handlePreview}
          className="flex align-center justify-center"
          icon={<Icon name="FlipBackwardOutlined" fill="currentColor" />}>
          {t('Back to edit')}
        </Button>
      ) : (
        <Fragment>
          {isFirstStep ? (
            <Button onClick={onReset}>{t('Cancel')}</Button>
          ) : (
            <Button disabled={isPrevStepDisabled} onClick={prevStep}>
              {t('Prev')}
            </Button>
          )}
          <Tooltip title={saveAsDraftBtnTooltip} placement="top">
            <Button
              loading={isDraftMethod && submitLoading}
              disabled={isDisabledDraftBtn}
              onClick={saveAsDraft}>
              {t('Save as draft')}
            </Button>
          </Tooltip>

          {isLastStep ? (
            <Tooltip title={publishBtnTooltip} placement="top">
              <Button
                loading={isPublishedMethod && submitLoading}
                disabled={isDisabledPublishBtn}
                type="primary"
                onClick={publishProduct}>
                {t('Publish')}
              </Button>
            </Tooltip>
          ) : (
            <Button
              disabled={isNextStepDisabled}
              onClick={nextStep}
              type="primary">
              {t('Next')}
            </Button>
          )}
        </Fragment>
      )}
    </Space>
  )
}

ProductFormActions.propTypes = {
  saveMethod: PropTypes.string,
  submitLoading: PropTypes.bool,
  submitDisabled: PropTypes.bool,
  onReset: PropTypes.func,
  handleChooseSaveMethod: PropTypes.func,
  form: PropTypes.object,
  isPrevStepDisabled: PropTypes.bool,
  isNextStepDisabled: PropTypes.bool,
  prevStep: PropTypes.func,
  nextStep: PropTypes.func,
  isFirstStep: PropTypes.bool,
  isLastStep: PropTypes.bool,
  isFormValid: PropTypes.bool,
  isPreview: PropTypes.bool,
  handlePreview: PropTypes.func,
  onFinish: PropTypes.func,
  product: PropTypes.object
}
export default ProductFormActions
