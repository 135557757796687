const STATISTIC_INFO = {
  TOTAL_EARNING: [
    {
      title: 'Total Earnings',
      descriptionKey: 'EARNING_TOTAL_DESCRIPTION',
      description: `The "Total Earnings" metric reflects the cumulative amount of money you've earned through rentals since the inception of your account. It includes all successful transactions, providing a clear picture of the overall success of your rental endeavors.`
    },
    {
      title: 'Today Earnings',
      descriptionKey: 'EARNING_TODAY_DESCRIPTION',
      description: `The "Today's Earnings" section outlines the total revenue generated from your rentals on the current day. This real-time metric is particularly useful for gauging your daily performance and identifying any notable trends or fluctuations.`
    },
    {
      title: 'Percentage Change (Today vs Yesterday)',
      descriptionKey: 'EARNING_PERCENTAGE_CHANGE_DESCRIPTION',
      description: `The "Percentage Change" metric illustrates the percentage difference in earnings between the current day and the previous day. This comparison offers a quick snapshot of your daily growth or decline, allowing you to assess the immediate impact of your rental activity.`
    },
    {
      title: 'Understanding the Metrics',
      descriptionKey: 'EARNING_PERCENTAGE_CHANGE_METRICS_DESCRIPTION',
      description: `Positive Percentage Change: Indicates an increase in today's earnings compared to yesterday. Negative Percentage Change: Signifies a decrease in today's earnings compared to yesterday. Zero Percentage Change: Suggests that today's earnings are consistent with those of the previous day.`
    }
  ],
  RENTED_PRODUCTS: [
    {
      title: 'Total Rentals This Month',
      descriptionKey: 'RENTED_PRODUCTS_THIS_MONTH_DESCRIPTION',
      description: `This metric provides a comprehensive overview of the total number of products rented out within the current month. It encapsulates the overall engagement and utilization of your rental platform, giving you a macroscopic view of your business performance over the monthly timeframe.`
    },
    {
      title: 'Today`s Rentals',
      descriptionKey: 'RENTED_PRODUCTS_TODAY_DESCRIPTION',
      description: `The "Today's Rentals" section details the total number of products successfully rented out on the current day. This real-time metric serves as a valuable tool for assessing your daily performance, allowing you to promptly identify any noteworthy trends or fluctuations in rental activity.`
    },
    {
      title: 'Percentage Change (Today vs Yesterday)',
      descriptionKey: 'RENTED_PRODUCTS_PERCENTAGE_CHANGE_DESCRIPTION',
      description: `The "Percentage Change" metric illustrates the percentage difference in the number of rentals between the current day and the previous day. This comparative analysis provides a quick snapshot of your daily growth or decline, enabling you to gauge the immediate impact of your rental activities.`
    },
    {
      title: 'Understanding the Metrics',
      descriptionKey: 'RENTED_PRODUCTS_PERCENTAGE_CHANGE_METRICS_DESCRIPTION',
      description: `Positive Percentage Change: Indicates an increase in today's rentals compared to yesterday. Negative Percentage Change: Signifies a decrease in today's rentals compared to yesterday. Zero Percentage Change: Suggests that today's rental activity is consistent with that of the previous day.`
    }
  ],
  NEW_CUSTOMERS: [
    {
      title: 'Total New Customers This Month',
      descriptionKey: 'NEW_CUSTOMERS_THIS_MONTH_DESCRIPTION',
      description: `Statistics offer a holistic snapshot of the number of individuals who have chosen to rent products from your offerings during the current month. By tracking the influx of new customers, you gain valuable insights into the expansion of your customer base and the overall appeal and effectiveness of your rental services, contributing to a comprehensive understanding of your business's monthly performance.`
    },
    {
      title: 'Today`s New Customers',
      descriptionKey: 'NEW_CUSTOMERS_TODAY_DESCRIPTION',
      description: `The "Today's New Customers" section provides a real-time snapshot of the total number of individuals who have become new customers on the current day. This metric is crucial for assessing your daily customer acquisition, enabling you to promptly identify any notable patterns or changes in new customer registrations.`
    },
    {
      title: 'Percentage Change (Today vs Yesterday)',
      descriptionKey: 'NEW_CUSTOMERS_PERCENTAGE_CHANGE_DESCRIPTION',
      description: `The "Percentage Change" metric illustrates the percentage difference in the number of new customers between the current day and the previous day. This comparative analysis offers a quick assessment of your daily customer acquisition dynamics, helping you gauge the immediate impact of your efforts in attracting new customers.`
    },
    {
      title: 'Understanding the Metrics',
      descriptionKey: 'NEW_CUSTOMERS_PERCENTAGE_CHANGE_METRICS_DESCRIPTION',
      description: `Positive Percentage Change: Indicates an increase in today's new customers compared to yesterday. Negative Percentage Change: Signifies a decrease in today's new customers compared to yesterday. Zero Percentage Change: Suggests that today's new customer acquisition is consistent with that of the previous day.`
    }
  ],
  MONTH_EARNING: [],
  MONTH_PRODUCT_VIEWS: []
}
export default STATISTIC_INFO
export { STATISTIC_INFO }
