/* eslint-disable no-console */
import { useEffect, useState } from 'react'

const useFilterCategories = (categories) => {
  const [filteredCategories, setFilteredCategories] = useState([])
  const [topLevelCategories, setTopLevelCategories] = useState([])
  const [subCategories, setSubCategories] = useState([])
  const [filteredCategoriesLoading, setFilteredCategoriesLoading] =
    useState(true)

  useEffect(() => {
    const fetchCategories = async () => {
      if (!Array.isArray(categories)) {
        console.error('Expected categories to be an array', categories)
        return
      }
      setFilteredCategoriesLoading(true)

      const topLevel = categories?.filter((category) => category.isTopLevel)
      const subLevel = categories?.filter((category) => !category.isTopLevel)

      // This sets the state for top-level categories and subcategories.
      setTopLevelCategories(topLevel)
      setSubCategories(subLevel)

      // This combines both top-level and subcategories into a single array.
      setFilteredCategories([...topLevel, ...subLevel])

      setFilteredCategoriesLoading(false)
    }

    fetchCategories()
  }, [categories])

  return [
    filteredCategories,
    filteredCategoriesLoading,
    topLevelCategories,
    subCategories
  ]
}

export default useFilterCategories

// === DO NOT DELETE THIS COMMENTED CODE. CUSTOMER REQUEST WAS TO CHANGE LOGIC and SHOW ALL CATEGORIES but possibly he will return previous logic
// import { collection, getDocs, limit, query, where } from 'firebase/firestore'
// import { useCallback, useEffect, useState } from 'react'

// import { COLLECTIONS } from '__constants__/models'
// import { PRODUCT_STATUSES } from '__constants__/enums'
// import { firestore } from 'services/firebase'

// const useFilterCategories = (categories) => {
//   const [filteredCategories, setFilteredCategories] = useState([])
//   const [topLevelCategories, setTopLevelCategories] = useState([])
//   const [subCategories, setSubCategories] = useState([])
//   const [filteredCategoriesLoading, setFilteredCategoriesLoading] =
//     useState(true)

//   const getCollection = useCallback(async (categoryId) => {
//     const queryConfig = query(
//       collection(firestore, COLLECTIONS.PRODUCTS),
//       where('categoryId', '==', categoryId),
//       where('status', '==', PRODUCT_STATUSES.PUBLISHED),
//       limit(1)
//     )

//     let docs = []

//     const docSnapshot = await getDocs(queryConfig)
//     docSnapshot?.forEach((snap) => snap?.exists() && docs.push(snap.data()))

//     return docs
//   }, [])

//   const filterCategories = useCallback(
//     async (categories) => {
//       try {
//         if (!categories?.length) return

//         const topLevelCategories = []

//         const categoriesPromises = categories?.map(async (category) => {
//           if (category?.isTopLevel) return null
//           const [product] = await getCollection(category?._id)
//           if (!product) return null

//           const parentId = category?.parentId
//           const isCategoryExist = topLevelCategories?.find(
//             (item) => item?._id === parentId
//           )

//           if (!isCategoryExist) {
//             const topLevelCategory = categories.find(
//               (item) => item?._id === parentId && item?.isTopLevel
//             )

//             topLevelCategory && topLevelCategories.push(topLevelCategory)
//           }

//           return category
//         })

//         const filteredCategoriesResolve = await Promise.all(categoriesPromises)

//         const filteredCategories = filteredCategoriesResolve?.filter(Boolean)

//         setFilteredCategories(() => [
//           ...topLevelCategories,
//           ...filteredCategories
//         ])

//         setTopLevelCategories(() => topLevelCategories)
//         setSubCategories(() => filteredCategories)
//       } catch (error) {
//         // eslint-disable-next-line no-console
//         console.error('Error during filter categories', error)
//         setFilteredCategories(categories)
//       } finally {
//         setFilteredCategoriesLoading(false)
//       }
//     },
//     [getCollection]
//   )

//   useEffect(() => {
//     let isMounted = true

//     isMounted && filterCategories(categories)
//     return () => (isMounted = false)
//   }, [categories, filterCategories])
//   console.log(filteredCategories)
//   return [
//     filteredCategories,
//     filteredCategoriesLoading,
//     topLevelCategories,
//     subCategories
//   ]
// }

// export default useFilterCategories
