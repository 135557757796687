import { Col, Row, Tabs } from 'antd'
import { UserActions, UserIntro } from './components'
import { useCurrentScreen, useStateWithStorage, useTranslations } from 'hooks'

import { List } from 'components'
import { ProductSimpleView } from 'domains/Product/components'
import PropTypes from 'prop-types'
import { useGetUserProductsTabConfig } from './hooks'
import { useMemo } from 'react'
import { usePublishDraftProducts } from 'domains/Product/hooks'

const UserAdvancedView = (props) => {
  const {
    user,
    products,
    isRentee,
    productsAmount,
    ordersAmount,
    rating,
    isMyProfile = false,
    draftProducts,
    publishedProducts,
    blockedProducts
  } = props

  const { t } = useTranslations()
  const emptyProps = useMemo(
    () => ({ message: t('No products are available to see here') }),
    [t]
  )
  // get helpers for publish products
  const { disableDraftProductsTab } = usePublishDraftProducts(draftProducts)

  /* State for active tab in AdvancedView with saving it to storage */
  const [activeTab, setActiveTab] = useStateWithStorage(
    null,
    'my-profile-active-tab'
  )

  const handleChangeTab = (key) => setActiveTab(key)
  const tabItems = useGetUserProductsTabConfig({
    draftProducts,
    publishedProducts,
    blockedProducts,
    disableDraftProductsTab,
    emptyProps
  })
  const currScr = useCurrentScreen()
  const isMobile = useMemo(
    () => currScr === 'xs' || currScr === 'sm',
    [currScr]
  )

  return (
    <Row gutter={{ xs: 8, sm: 16, md: 24 }} data-area="user-details">
      <Col mb={3} data-area="user-details-info" span={24}>
        <Row className="mb-24 flex" gutter={[16, 24]}>
          <Col>
            <UserIntro
              user={user}
              productsAmount={productsAmount}
              ordersAmount={ordersAmount}
              rating={rating}
            />
          </Col>
          <Col xs={24} flex={!isMobile && '1 0 auto'}>
            <UserActions
              isMobile={isMobile}
              user={user}
              isMyProfile={isMyProfile}
            />
          </Col>
        </Row>
        {!isMyProfile ? (
          <List
            emptyProps={emptyProps}
            dataSource={products}
            renderItem={(product) => (
              <ProductSimpleView
                product={product}
                onlyView
                isRentee={isRentee}
              />
            )}
          />
        ) : (
          <Tabs
            defaultActiveKey={activeTab}
            onChange={handleChangeTab}
            items={tabItems}
          />
        )}
      </Col>
    </Row>
  )
}

UserAdvancedView.propTypes = {
  rating: PropTypes.number,
  user: PropTypes.object,
  productsAmount: PropTypes.number,
  ordersAmount: PropTypes.number,
  isRentee: PropTypes.bool,
  isMyProfile: PropTypes.bool,
  products: PropTypes.array,
  draftProducts: PropTypes.array,
  publishedProducts: PropTypes.array,
  blockedProducts: PropTypes.array
}

export default UserAdvancedView
