import { Box } from 'components'
import { Grid } from 'antd'
import PropTypes from 'prop-types'

const variantStyles = {
  right: {
    position: 'fixed',
    right: 0,
    top: '50%',
    transform: 'translate(0, -50%)',
    zIndex: 1000
  },
  bottom: {
    right: 24,
    bottom: 0,
    width: 400,
    position: 'fixed',
    borderRadius: '7px 7px 0 0'
  },
  tabletBottom: {
    right: 24,
    bottom: 62,
    width: 400,
    position: 'fixed',
    borderRadius: '7px 7px 0 0'
  },
  xsPhonesBottom: {
    left: 24,
    right: 24,
    bottom: 120,
    width: 'calc(100% - 48px)',
    position: 'fixed',
    borderRadius: '7px 7px 0 0'
  }
}

const ChatPositionBlock = ({ children }) => {
  const { useBreakpoint } = Grid

  const { lg, xs } = useBreakpoint()
  let variant = 'bottom'
  if (xs) {
    variant = 'xsPhonesBottom'
  } else if (!lg) {
    variant = 'tabletBottom'
  }
  return (
    <Box {...variantStyles[variant]} zIndex="100">
      {children}
    </Box>
  )
}

ChatPositionBlock.propTypes = {
  variant: PropTypes.oneOf([
    'bottom',
    'right',
    'mobileBottom',
    'tabletBottom',
    'xsPhonesBottom'
  ]),
  children: PropTypes.node.isRequired
}

export default ChatPositionBlock
