import { Button, Col, Form, Row, Typography, message } from 'antd'
import {
  ComplaintAdvancedForm,
  ComplaintList
} from 'domains/Complaint/components'
import { useCallback, useMemo, useState } from 'react'

import { PlusOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'
import { useActionsComplaintAdvancedForm } from 'domains/Complaint/components/ComplaintAdvancedForm/hooks'
import { useTranslations } from 'hooks'

const ComplaintListWithCreate = (props) => {
  const { value, onChange } = props

  /* State for checking if form is visible */
  const [isAdding, setIsAdding] = useState(false)

  /* Getting form instance */
  const [form] = Form.useForm()

  /* Getting translations instance */
  const { t } = useTranslations()

  /* Getting actions for validating and preparing values */
  const { validateForm, prepareValues } = useActionsComplaintAdvancedForm()

  /* Toggling form visible */
  const toggleAdd = () => setIsAdding((prev) => !prev)

  /* Handling submit button action */
  const handleFinish = async (values) => {
    try {
      // Validate fields
      validateForm(values)
      // Prepare values
      const preparedValues = await prepareValues(values)
      // Save values
      onChange?.([...(value || []), preparedValues])
      // Reset form
      toggleAdd()
      form.resetFields()
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      message.error(error.message)
    }
  }

  /* Delete item handling */
  const handleDelete = useCallback(
    (elem, index) => {
      onChange?.(value.filter((item, i) => i !== index))
    },
    [value, onChange]
  )

  // /* Submitting form */
  // const handleSave = () => form.submit()

  /* Empty props message */
  const emptyProps = useMemo(
    () => ({
      message: t('No complaints have been added yet')
    }),
    [t]
  )

  /* Overriding default actions */
  const actions = useMemo(
    () => ({
      onDelete: handleDelete, // override default handler
      onEdit: null, // override default handler
      onDoubleClick: null // override default handler
    }),
    [handleDelete]
  )

  return (
    <>
      <Row className="mb-8">
        <Col flex="auto">
          <Typography.Title level={3}>{t('Complaints')}</Typography.Title>
        </Col>
        <Col flex="none">
          <Button onClick={toggleAdd} icon={<PlusOutlined />}>
            {t('Add')}
          </Button>
        </Col>
      </Row>
      {isAdding ? (
        <ComplaintAdvancedForm onSubmit={handleFinish} form={form} />
      ) : (
        <div className="mb-8">
          <ComplaintList
            isListWithCreate
            actions={actions}
            emptyProps={emptyProps}
            complaints={value}
            span={{ xs: 24, sm: 12, md: 8 }}
          />
        </div>
      )}
    </>
  )
}

ComplaintListWithCreate.propTypes = {
  value: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func
}

export default ComplaintListWithCreate
