import { Typography } from 'antd'
import styled from 'styled-components'

const StyledText = styled(Typography.Text)`
  width: 100%;
  &:hover {
    text-decoration: underline;
    color: ${({ colorPrimary }) => colorPrimary};
    cursor: pointer;
  }
`

const StyledNumber = styled(Typography.Text)`
  width: 50%;
`

export { StyledText, StyledNumber }
