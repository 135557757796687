import { useMemo } from 'react'
import { useTranslations } from '@qonsoll/translation'

const useGetSettingsNotificationListItems = () => {
  const { t } = useTranslations()
  return useMemo(
    () => [
      {
        iconName: 'ShoppingBag1Outlined',
        title: t('Product updates'),
        description: `${t(
          'Enabling this notification allows you to receive notifications about your products'
        )}.`,
        fieldName: 'productUpdatesNotification'
      },
      {
        iconName: 'ShoppingBag2Outlined',
        title: t('Checkout'),
        description: `${t(
          'Enabling this notification allows you to receive notifications about changes in the status of your checkouts'
        )}.`,
        fieldName: 'productCheckoutNotification'
      },
      {
        iconName: 'MessageDotsCircleOutlined',
        title: t('Comments'),
        description: `${t(
          'Enabling this notification allows you to receive notifications about comments left for you'
        )}.`,
        fieldName: 'commentsNotification'
      }
    ],
    [t]
  )
}

export default useGetSettingsNotificationListItems
