import { Breadcrumbs, PageLayout, Spinner } from 'components'
import { Col, Row } from 'antd'
import { useGetUser, useGetUserPublishedProducts } from 'domains/User/hooks'
import {
  useGetUserOrdersAmount,
  useGetUserProductsRating
} from 'domains/User/hooks'
import { useLoading, useTranslations } from 'hooks'

import PropTypes from 'prop-types'
import { UserAdvancedView } from 'domains/User/components'
import { useMemo } from 'react'
import { useMergeProductMediaObjects } from 'domains/Product/hooks'
import { useParams } from 'react-router-dom'

const UserProfile = (props) => {
  const { withHeader = true } = props

  const { t } = useTranslations()
  const { userId } = useParams()

  const [user, userLoading] = useGetUser(userId)
  const [products, productLoading] = useGetUserPublishedProducts(user?._id)
  const [mergedData, mergedDataLoading] = useMergeProductMediaObjects(products)

  const ordersAmount = useGetUserOrdersAmount(userId)
  const rating = useGetUserProductsRating(mergedData, mergedDataLoading)
  const productsAmount = useMemo(
    () => (!mergedDataLoading ? mergedData?.length : 0),
    [mergedData?.length, mergedDataLoading]
  )

  const loading = useLoading([
    !products,
    !mergedData,
    !user,
    productLoading,
    userLoading,
    mergedDataLoading
  ])

  return (
    <PageLayout
      breadcrumbs={withHeader ? <Breadcrumbs noExtraItems /> : null}
      area="products">
      <Row gutter={[24, 16]}>
        {loading ? (
          <Col flex="auto">
            <Spinner height="70vh" text={t('User profile loading')} />
          </Col>
        ) : (
          <Col flex="auto">
            <UserAdvancedView
              isRentee={true}
              productsAmount={productsAmount}
              ordersAmount={ordersAmount}
              rating={rating}
              user={user}
              products={mergedData}
            />
          </Col>
        )}
      </Row>
    </PageLayout>
  )
}
UserProfile.propTypes = {
  withHeader: PropTypes.bool
}

export default UserProfile
