import { Breadcrumbs, PageLayout } from 'components'
import {
  ProductFormActions,
  ProductFormWithPreview
} from 'domains/Product/components/ProductAdvancedForm/components'
import {
  useActionsProductAdvancedForm,
  useGetProductFormItems,
  useInitializeProductAdvancedForm
} from 'domains/Product/components/ProductAdvancedForm/hooks'
import { useCheckFormChange, useScreen, useTranslations } from 'hooks'

import { UploadProvider } from 'contexts/UploadContext'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { validatePressTarget } from 'domains/Product/helpers'

const ProductCreatePage = () => {
  const [saveMethod, setSaveMethod] = useState(null)
  const { t } = useTranslations()
  const navigate = useNavigate()
  const { isExtraSmallScreen } = useScreen()
  const headingProps = {
    title: t('Create new product'),
    textAlign: 'left',
    titleSize: isExtraSmallScreen ? 2 : 1,
    titleClasses: isExtraSmallScreen ? 'my-8' : ''
  }

  const { form, initialValues } = useInitializeProductAdvancedForm()

  const [handleValuesChange, isEqual] = useCheckFormChange(initialValues)

  const {
    loading: submitLoading,
    onFinish,
    onReset
  } = useActionsProductAdvancedForm({ form })

  // get form form-items for placing them in tabs
  const {
    steps,
    step,
    prevStep,
    nextStep,
    isNextStepDisabled,
    isPrevStepDisabled,
    isFirstStep,
    isLastStep,
    isFormValid,
    setStep
  } = useGetProductFormItems(form)

  const handleBack = () => navigate(-1)
  const handleChooseSaveMethod = (saveMethod) => setSaveMethod(saveMethod)
  const handleFinish = () => onFinish(saveMethod)

  const handleEnterPress = (e) => {
    form.validateFields()
    const isIncorrectTarget = validatePressTarget(e)
    if (isNextStepDisabled || isIncorrectTarget) return
    const action = isLastStep ? form.submit : nextStep
    action()
  }

  return (
    <PageLayout
      onBack={handleBack}
      breadcrumbs={<Breadcrumbs />}
      headingProps={headingProps}
      area="product"
      actions={
        <ProductFormActions
          onFinish={onFinish}
          saveMethod={saveMethod}
          handleChooseSaveMethod={handleChooseSaveMethod}
          isNextStepDisabled={isNextStepDisabled}
          isPrevStepDisabled={isPrevStepDisabled}
          submitLoading={submitLoading}
          submitDisabled={isEqual}
          isFirstStep={isFirstStep}
          isFormValid={isFormValid}
          isLastStep={isLastStep}
          prevStep={prevStep}
          nextStep={nextStep}
          onReset={onReset}
          form={form}
        />
      }>
      <ProductFormWithPreview
        onValuesChange={handleValuesChange}
        handleEnterPress={handleEnterPress}
        onFinish={handleFinish}
        steps={steps}
        step={step}
        form={form}
        setStep={setStep}
      />
    </PageLayout>
  )
}

const ProviderWrapper = (props) => {
  return (
    <UploadProvider>
      <ProductCreatePage {...props} />
    </UploadProvider>
  )
}

ProductCreatePage.propTypes = {}

export default ProviderWrapper
