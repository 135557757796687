import {
  RENTEE_COMPLAIN_REASONS_LABELS,
  RENTER_COMPLAIN_REASONS_LABELS
} from '__constants__'

import PropTypes from 'prop-types'
import { Typography } from 'antd'
import { useTranslations } from 'hooks'

const ComplaintReason = (props) => {
  const { complaint } = props

  const { t } = useTranslations()
  const reason =
    RENTEE_COMPLAIN_REASONS_LABELS[complaint?.complaintReason] ||
    RENTER_COMPLAIN_REASONS_LABELS[complaint?.complaintReason]
  return complaint?.complaintReason ? (
    <Typography.Text>{t(reason)}</Typography.Text>
  ) : (
    <Typography.Text>{t('n/a')}</Typography.Text>
  )
}

ComplaintReason.propTypes = {
  complaint: PropTypes.object,
  noData: PropTypes.node
}

export default ComplaintReason
