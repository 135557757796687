import { Button } from 'antd'
import { Icon } from 'components'
import { MenuButton } from '../BottomMenu/BottomMenu.styled'
import PropTypes from 'prop-types'
import { useToggleLanguage } from 'modules/translation-module/hooks'

const LanguageToggle = (props) => {
  const { collapsed, ...rest } = props

  const [currentLanguage, toggleLanguage] = useToggleLanguage()

  return !collapsed ? (
    <MenuButton
      collapsed={collapsed}
      onClick={toggleLanguage}
      block
      prefix={<Icon size={16} name="Globe11Outlined" />}>
      {currentLanguage.name}
    </MenuButton>
  ) : (
    <Button
      style={{ height: '40px', ...rest }}
      type="text"
      className="flex justify-center align-center"
      block
      onClick={toggleLanguage}>
      {currentLanguage.shortCode?.toUpperCase()}
    </Button>
  )
}

LanguageToggle.propTypes = {
  collapsed: PropTypes.bool
}

export default LanguageToggle
