// import { EyeInvisibleOutlined } from '@ant-design/icons'
import { Icon } from '@qonsoll/icons'
import { useMemo } from 'react'
import { useTranslations } from 'hooks'

const useGetProductDropdownItems = (dropdownActions) => {
  const { t } = useTranslations()

  const { onUnpublish, onPublish } = dropdownActions

  return useMemo(
    () =>
      [
        {
          key: 'unpublish',
          label: t('Unpublish'),
          icon: <Icon name="EyeOffOutlined" size={12} />,
          onClick: onUnpublish,
          hidden: !onUnpublish
        },
        {
          key: 'publish',
          label: t('Publish'),
          icon: <Icon name="EyeOutlined" size={12} />,
          onClick: onPublish,
          hidden: !onPublish
        }
      ]?.filter((item) => !item?.hidden),
    [onUnpublish, onPublish, t]
  )
}

export default useGetProductDropdownItems
