import {
  ComplaintDate,
  ComplaintProduct,
  ComplaintReason,
  ComplaintStatus
} from 'domains/Complaint/components/ComplaintList/components'
import { useMemo, useState } from 'react'
import { useScreen, useTranslations } from 'hooks'

import { COMPLAINT_STATUSES_LABELS } from '__constants__'
import { useGetTableSearchProps } from 'hooks'

const useGetComplaintsTableConfig = (complaints) => {
  const [pageSize, setPageSize] = useState(6)
  const { t } = useTranslations()
  const { isSmallScreen } = useScreen()
  const getColumnSearchProps = useGetTableSearchProps()

  const columns = useMemo(
    () => [
      {
        width: isSmallScreen ? '300px' : '600px',
        title: t('Product'),
        dataIndex: 'productName',
        key: 'product',
        fixed: 'left',
        ...getColumnSearchProps('productName', ['productName']),

        render: (text, record) => (
          <ComplaintProduct complaint={record} text={text} />
        )
      },

      {
        width: '200px',
        title: t('Reason'),
        dataIndex: 'reason',
        key: 'reason',
        render: (text, record) => (
          <ComplaintReason complaint={record} width="200px" />
        )
      },
      {
        width: '200px',
        title: t('Created at'),
        dataIndex: 'createdAt',
        key: 'createdAt',
        sorter: (a, b) => a?._createdAt - b?._createdAt,
        render: (text, record) => (
          <ComplaintDate complaint={record} width="200px" />
        )
      },

      {
        width: '124px',
        title: t('Status'),
        dataIndex: 'status',
        key: 'status',
        filters: Object.keys(COMPLAINT_STATUSES_LABELS).map((key) => ({
          text: COMPLAINT_STATUSES_LABELS[key],
          value: key
        })),
        onFilter: (value, record) => record?.status.indexOf(value) === 0,
        render: (_, record) => <ComplaintStatus complaint={record} />
      }
    ],
    [getColumnSearchProps, isSmallScreen, t]
  )

  const paginationProps = {
    hideOnSinglePage: true,
    position: ['bottomLeft'],
    pageSize: pageSize,
    pageSizeOptions: [6, 10, 20, 50, 100],
    total: complaints?.length,
    showSizeChanger: true,
    showQuickJumper: true,
    onShowSizeChange: (current, pageSize) => setPageSize(pageSize)
  }
  const tableProps = {
    scroll: { x: 640 },
    tableLayout: 'fixed'
  }

  return {
    columns,
    paginationProps,
    tableProps
  }
}

export default useGetComplaintsTableConfig
