import { Route, Routes, useLocation } from 'react-router-dom'

import { APP_PATHS } from '__constants__/paths'
import { APP_ROUTES } from './appRoutes'
import { AppLayout } from 'components'
import { useScreen } from 'hooks'
import queryString from 'query-string'
import { useEffect } from 'react'
import { useUser } from 'modules/session-module/contexts'
import { generatePath, useNavigate } from 'react-router-dom'
import { useUserUpdate } from 'hooks'

const AppNavigator = () => {
  const { lg } = useScreen()
  const location = useLocation()
  const { loaded, user } = useUser()
  const navigate = useNavigate()

  const disableSettingsNested = (path) => path === APP_PATHS.SETTINGS && lg

  useEffect(() => {
    const query = location.search
    const cart = queryString.parse(query)?.cart
    const likedProducts = queryString.parse(query)?.liked
    const checkoutId = queryString.parse(query)?.checkoutId

    cart && window?.localStorage?.setItem('cart', cart)
    likedProducts &&
      window?.localStorage?.setItem('likedProducts', likedProducts)
    checkoutId &&
      window?.localStorage?.setItem('checkoutId', JSON.stringify(checkoutId))
  }, [location.search, navigate])

  useEffect(() => {
    const checkoutIdComputed = localStorage.getItem('checkoutId') || null

    const checkoutId = JSON.parse(checkoutIdComputed)

    // if user is loaded and checkoutId was passed - redirect to checkout page
    if (loaded && checkoutId) {
      const pathParams = { checkoutId }
      const path = generatePath(APP_PATHS.CHECKOUT, pathParams)
      navigate(path)
      window?.localStorage?.removeItem('checkoutId')
    }
  }, [loaded, navigate])

  useUserUpdate(user?._id)

  return (
    <AppLayout>
      <Routes>
        {APP_ROUTES.map(({ key, path, element, nested }) => (
          <Route key={key} path={path} element={element}>
            {disableSettingsNested(path) &&
              nested?.map(({ key, path, element }) => (
                <Route key={key} path={path} element={element} />
              ))}
          </Route>
        ))}
      </Routes>
    </AppLayout>
  )
}

export default AppNavigator
