import { Button, Grid } from 'antd'
import { useEffect, useMemo, useState } from 'react'

import { ChatButton } from 'modules/chat-module/domains/Chat/components'
import { Icon } from '@qonsoll/icons'
import PropTypes from 'prop-types'
import { checkMatchingChats } from 'modules/chat-module/domains/Chat/helpers'
import { useGetUserProfilePaths } from 'domains/User/hooks'
import { useListenUserChats } from 'modules/chat-module/domains/Chat/hooks'
import { useNavigate } from 'react-router-dom'
import { useTranslations } from 'hooks'
import { useUser } from 'modules/session-module/contexts'

const { useBreakpoint } = Grid

const UserActions = (props) => {
  const { isMobile, user, isMyProfile } = props
  const { user: userProvider, loading } = useUser()

  const { xl } = useBreakpoint()
  const { t } = useTranslations()
  const navigate = useNavigate()
  const { messagesPath, ordersPath } = useGetUserProfilePaths({
    user
  })

  const navigateTo = (path) => () => navigate(path)

  const buttonText = xl ? t('Messages') : ''
  const buttonIcon = xl ? null : (
    <Icon name="MessageTextSquare1Outlined" size={18} />
  )
  const disabledChatButton = useMemo(
    () => (loading ? true : userProvider?._id === user?._id),
    [user?._id, userProvider?._id, loading]
  )

  const [chats] = useListenUserChats(userProvider?._id)
  const [oppositeUserChats] = useListenUserChats(user?._id)

  const [isChatExist, setIsChatExist] = useState(
    checkMatchingChats({
      oppositeUsersChats: oppositeUserChats,
      userId: userProvider?._id,
      userChats: chats
    })
  )

  useEffect(() => {
    const chatExists = checkMatchingChats({
      oppositeUsersChats: oppositeUserChats,
      userId: userProvider?._id,
      userChats: chats
    })
    setIsChatExist(chatExists)
  }, [chats, oppositeUserChats, userProvider?._id])

  return (
    <div className="flex gap-12 justify-start">
      {isMyProfile ? (
        <Button block={isMobile} onClick={navigateTo(messagesPath)}>
          {t('Messages')}
        </Button>
      ) : (
        <ChatButton
          isChatExist={isChatExist}
          chats={chats}
          disabled={disabledChatButton}
          oppositeUserId={user?._id}
          btnText={buttonText}
          className="flex align-center justify-center"
          icon={buttonIcon}
        />
      )}

      {isMyProfile && (
        <Button block={isMobile} onClick={navigateTo(ordersPath)}>
          {t('My orders')}
        </Button>
      )}
    </div>
  )
}

UserActions.propTypes = {
  isMobile: PropTypes.bool,
  isMyProfile: PropTypes.bool,
  user: PropTypes.object
}

export default UserActions
