import { Button, Col, Typography } from 'antd'

import { AddItemCard } from 'components'
import { Icon } from '@qonsoll/icons'
import PropTypes from 'prop-types'
import { StyledRow } from './List.styled'
import { useTranslations } from 'hooks'

const List = (props) => {
  const {
    message,
    dataSource,
    renderItem,
    isListWithCreate,
    listView,
    emptyProps,
    actions,
    span = { xs: 24, sm: 12, md: 8, lg: 6 },
    gutter = [
      { xs: 16, sm: 16, md: 24 },
      { xs: 16, sm: 16, md: 24 }
    ],
    wrapperStyles,
    pagination,
    isAddingLast,
    isAddButton
  } = props

  /* Getting translations instance */
  const { t } = useTranslations()

  /* Checking if list is empty */
  const isEmpty = !dataSource?.length
  /* Computing item width */
  const itemWidth = listView ? { span: 24 } : span

  const addItemCardBlock = (
    <Col {...itemWidth}>
      {isAddButton ? (
        <Button
          onClick={actions?.onCreate}
          size="large"
          className="flex align-center justify-center"
          icon={<Icon name="Plus1Outlined" fill="currentColor" />}
          block>
          {message}
        </Button>
      ) : (
        <AddItemCard onClick={actions?.onCreate} message={message} />
      )}
    </Col>
  )
  return (
    <StyledRow gutter={gutter} style={wrapperStyles}>
      {!isAddingLast &&
        isListWithCreate &&
        actions?.onCreate &&
        addItemCardBlock}
      {!isEmpty && renderItem && (
        <>
          {dataSource.map((item, index) => (
            <Col {...itemWidth} key={index}>
              {renderItem?.(item, index)}
            </Col>
          ))}
          {!isEmpty && renderItem && pagination && (
            <Col className="justify-center my-12" span={24}>
              {pagination}
            </Col>
          )}
        </>
      )}
      {isEmpty && !emptyProps?.hidden && (
        <Col span={24}>
          <Typography.Text variant="overline" type="secondary">
            {emptyProps?.message === undefined
              ? t('No items have been created yet')
              : emptyProps?.message}
          </Typography.Text>
        </Col>
      )}

      {isAddingLast &&
        isListWithCreate &&
        actions?.onCreate &&
        addItemCardBlock}
    </StyledRow>
  )
}

List.propTypes = {
  dataSource: PropTypes.array,
  renderItem: PropTypes.func,
  isListWithCreate: PropTypes.bool,
  listView: PropTypes.bool,
  emptyProps: PropTypes.object,
  actions: PropTypes.object,
  span: PropTypes.object,
  gutter: PropTypes.array,
  message: PropTypes.string,
  wrapperStyles: PropTypes.object,
  pagination: PropTypes.object,
  isAddingLast: PropTypes.bool,
  isAddButton: PropTypes.bool
}

export default List
