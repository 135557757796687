import { AccessDenied, NotFound, StripeCallbackRN } from 'pages/Service'

import { Navigate } from 'react-router-dom'
import { SERVICE_PATHS } from '__constants__'

const { ACCESS_DENIED, NOT_FOUND, STRIPE_CALLBACK_RN } = SERVICE_PATHS

export const SERVICE_ROUTES = [
  { key: ACCESS_DENIED, path: ACCESS_DENIED, element: <AccessDenied /> },
  { key: NOT_FOUND, path: NOT_FOUND, element: <NotFound /> },
  {
    key: STRIPE_CALLBACK_RN,
    path: STRIPE_CALLBACK_RN,
    element: <StripeCallbackRN />
  },
  { key: '404', path: '*', element: <Navigate to={NOT_FOUND} /> }
  // Add your routes here
  // ...
]

export default SERVICE_ROUTES
