import { Button, Col, Divider, Row, Tooltip, theme } from 'antd'
import {
  StyledDisplayDate,
  StyledDot,
  StyledMessage,
  StyledNotificationWrapper,
  StyledTitle,
  styles
} from './NotificationSimpleView.styled'
import {
  generateNotificationPath,
  markAsSeen
} from 'domains/Notification/helpers'
import { useCurrentScreen, useMutate, useTranslations } from 'hooks'

import { Icon } from '@qonsoll/icons'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useGetOrder } from 'domains/Order/hooks'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useUser } from 'modules/session-module/contexts'

const NotificationSimpleView = (props) => {
  const { notification, actions, index, length, setPopoverVisible } = props
  const { user, isRentee, setIsRentee } = useUser()
  const { language, t } = useTranslations()
  const { update } = useMutate()
  const navigate = useNavigate()
  const { colorPrimaryBg, colorPrimary } = theme.useToken().token
  const currScr = useCurrentScreen()

  const [order] = useGetOrder(notification?.orderId)
  const isMobile = currScr === 'xs' || currScr === 'sm'
  const handleMarkNotificationAsSeen = async () => {
    await markAsSeen(update, notification, user)
    setPopoverVisible(false)
    generateNotificationPath(
      navigate,
      notification,
      order?.status,
      isMobile,
      isRentee,
      setIsRentee
    )
  }
  const markAsSeenOnly = async (e) => {
    e.stopPropagation()
    await markAsSeen(update, notification, user)
    e.preventDefault()
  }
  const dateFormatted = moment(notification?._createdAt?.toDate()).fromNow()

  const languageKey = useMemo(() => language?.toUpperCase(), [language])

  const computedTitle = useMemo(
    () => notification.title?.[languageKey] || notification.title?.EN || 'n/a',
    [languageKey, notification?.title]
  )

  const computedMessage = useMemo(
    () =>
      notification.message?.[languageKey] || notification.message?.EN || 'n/a',
    [languageKey, notification?.message]
  )

  const isLast = index === length - 1
  const reviewedByThisUser = notification?.receivers?.[user?._id] === true
  const styledDotBg = reviewedByThisUser ? 'unset' : colorPrimary

  return (
    <>
      <StyledNotificationWrapper
        className="px-12 py-8"
        bg={colorPrimaryBg}
        data-testid="notificationListItem"
        {...actions}
        onClick={handleMarkNotificationAsSeen}>
        <div className="flex flex-1">
          <StyledDot bg={styledDotBg} className="mr-8 mt-8" />

          <div className="flex flex-1 flex-col">
            <div
              className="mb-12 flex justify-between"
              data-area="notification-details-title">
              <StyledTitle strong>{computedTitle}</StyledTitle>
              {!reviewedByThisUser && (
                <Tooltip title={t('Mark as read')}>
                  <Button
                    onClick={markAsSeenOnly}
                    style={styles.markAsReadButtonStyles}
                    type="text"
                    icon={<Icon size={14} name={'EyeOffOutlined'} />}
                  />
                </Tooltip>
              )}
            </div>
            <div className="mb-12" data-area="notification-details-message">
              <Row gutter={16}>
                <Col flex="none">
                  <StyledMessage fontSize="10">{computedMessage}</StyledMessage>
                </Col>
              </Row>
            </div>
            <div data-area="notification-details-data">
              <Row>
                <Col>
                  <StyledDisplayDate type="secondary">
                    {dateFormatted}
                  </StyledDisplayDate>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </StyledNotificationWrapper>
      {!isLast && <Divider className="my-4" />}
    </>
  )
}

NotificationSimpleView.propTypes = {
  notification: PropTypes.object,
  onSelect: PropTypes.func,
  index: PropTypes.number,
  isSelected: PropTypes.bool,
  actions: PropTypes.object,
  length: PropTypes.number,
  setPopoverVisible: PropTypes.func
}

export default NotificationSimpleView
