import { ORDER_STATUSES } from '__constants__'
import PropTypes from 'prop-types'
import { StatusDot } from './StatusDot.styled'
import { theme } from 'antd'

const StatusDotView = (props) => {
  const { status } = props
  const { colorSuccess, colorError, colorInfo, colorTextDisabled } =
    theme.useToken().token

  const statusColors = {
    [ORDER_STATUSES.IN_PROGRESS]: colorInfo,
    [ORDER_STATUSES.ACCEPTED]: colorSuccess,
    [ORDER_STATUSES.DECLINED]: colorError,
    [ORDER_STATUSES.CANCELED]: colorTextDisabled
  }

  const statusColorComputed =
    statusColors[status] || statusColors[ORDER_STATUSES.IN_PROGRESS]
  return <StatusDot {...props} statusColor={statusColorComputed} />
}

StatusDotView.propTypes = {
  status: PropTypes.string
}

export default StatusDotView
