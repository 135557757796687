import { Carousel as ReactResponsiveCarousel } from 'react-responsive-carousel'
import arrowLeft from '__assets__/illustrations/arrow-left-dark.svg'
import arrowRight from '__assets__/illustrations/arrow-right-dark.svg'
import styled from 'styled-components'

const CarouselStyled = styled(ReactResponsiveCarousel)`
  .carousel-root,
  .carousel.carousel-slider,
  .carousel .slider-wrapper {
    aspect-ratio: 1 / 1;
    display: flex;
    box-shadow: inset 0 0 100px 0 rgb(234, 234, 234);
    border-radius: 9px;
  }

  .carousel .slider {
    height: inherit;
    align-items: center;
  }
  .carousel-status {
    display: none;
  }
  .carousel-item-wrapper {
    position: relative;
    width: 100%;
    overflow: hidden;
  }

  .carousel.carousel-slider .control-prev.control-arrow,
  .carousel.carousel-slider .control-next.control-arrow {
    height: 32px;
    width: 32px;
    background-color: #fff;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
  }
  .carousel.carousel-slider .control-prev.control-arrow {
    left: 6px;
  }
  .carousel.carousel-slider .control-next.control-arrow {
    right: 6px;
  }
  .carousel.carousel-slider .control-prev.control-arrow:before,
  .carousel.carousel-slider .control-next.control-arrow:before {
    content: '';
    border: unset;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 26px;
    border-right: unset;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 100%;
    width: 100%;
  }
  .carousel.carousel-slider .control-prev.control-arrow:before {
    background-image: url(${arrowLeft});
    left: -5px;
  }
  .carousel.carousel-slider .control-next.control-arrow:before {
    background-image: url(${arrowRight});
    left: -4px;
  }
  .carousel.carousel-slider .control-arrow {
    opacity: 0.7 !important;
  }
  .carousel.carousel-slider .control-arrow:hover {
    opacity: 1 !important;
    background-color: #fff !important;
  }

  .carousel.carousel-slider .control-arrow {
    padding: 0 !important;
  }
  .control-dots {
    display: none;
  }

  .carousel .thumbs-wrapper {
    margin: 0;
  }
  .carousel .thumbs {
    display: flex;
    justify-content: center;
    padding: 0;
    width: fit-content;
  }
  .carousel .thumb {
    border: 1px solid transparent;
    padding: 2px;
    height: 60px;
    cursor: pointer;
    border-radius: 4px;
    margin-right: 0px;
  }
  .carousel .thumb img {
    border-radius: 4px;
  }
  .carousel .thumb.selected {
    border: ${({ theme }) => `1px solid ${theme.colorPrimary}`};
  }
  .carousel .thumb:hover {
    border: 1px solid rgba(11, 10, 14, 0.2);
  }
  .carousel .control-arrow {
    opacity: 1;
  }
  .control-arrow .control-prev:before,
  .control-arrow .control-next:before {
    border-right-color: var(--white);
  }
`
// padding is needed to prevent display of border of prev image
const carouselImageStyles = { objectFit: 'contain', padding: '0 1px' }

export { CarouselStyled, carouselImageStyles }
