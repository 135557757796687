import { Button, Col, Row, Typography } from 'antd'

import PropTypes from 'prop-types'
import { useTranslations } from 'hooks'

const OrderSortMenu = (props) => {
  const { sortKeys, onSortChange, onClearSort, getSortIcon } = props
  const { t } = useTranslations()

  return (
    <div style={{ minWidth: '200px' }} data-area="sort-box">
      {Object.keys(sortKeys).map((key, index) => (
        <Row gutter={[8]} align="center" key={index} data-area="sort-item">
          <Col flex="auto">
            <Typography.Text>{key}</Typography.Text>
          </Col>
          <Col flex="none">
            <Button
              data-area="sort-type-button"
              icon={getSortIcon(key)}
              size="small"
              type="text"
              onClick={() => onSortChange(key)}
            />
          </Col>
        </Row>
      ))}
      <Button
        className="mt-16"
        block
        onClick={onClearSort}
        size="small"
        data-area="clear-sort-button">
        {t('Clear sort')}
      </Button>
    </div>
  )
}

OrderSortMenu.propTypes = {
  sortKeys: PropTypes.object,
  onSortChange: PropTypes.func,
  onClearSort: PropTypes.func,
  getSortIcon: PropTypes.func
}

export default OrderSortMenu
