import { Button } from 'antd'
import ChatCollapse from '../ChatCollapse'
import ChatPositionBlock from '../ChatPositionBlock'
import { ChatProvider } from 'modules/chat-module/contexts'
import PropTypes from 'prop-types'
import { handleChatCreate } from 'modules/chat-module/domains/Chat/helpers'
import { useState } from 'react'
import { useUser } from 'modules/session-module/contexts'

const ChatButton = ({
  oppositeUserId,
  btnText,
  isChatExist,
  chats,
  disabled,
  ...rest
}) => {
  const [isChatOpen, setIsChatOpen] = useState(false)
  const { user } = useUser()

  const [isLoading, setIsLoading] = useState(false) // Initialize loading state

  const toggleChat = () => {
    if (isChatExist) {
      setIsChatOpen(true)
    } else {
      handleChatCreate({
        oppositeUserId,
        userId: user?._id,
        cuttentUserChats: chats,
        isChatExist,
        setIsLoading,
        setIsChatOpen
      })
    }
  }
  // const chatHeight
  // create var for chat height if screen height is less then 760px(iphone12pro)
  const chatHeight = window.innerHeight < 760 ? 400 : 550

  return (
    <>
      <Button
        onClick={toggleChat}
        {...rest}
        loading={isLoading}
        disabled={disabled}>
        {btnText}
      </Button>
      {isChatOpen && (
        <div style={{ zIndex: 1000 }}>
          <ChatProvider oppositeUserId={oppositeUserId}>
            <ChatPositionBlock>
              <ChatCollapse
                height={chatHeight}
                setIsChatOpen={setIsChatOpen}
                initialVisible
              />
            </ChatPositionBlock>
          </ChatProvider>
        </div>
      )}
    </>
  )
}

ChatButton.propTypes = {
  oppositeUserId: PropTypes.string.isRequired,
  btnText: PropTypes.string.isRequired,
  isChatExist: PropTypes.any.isRequired,
  chats: PropTypes.array,
  disabled: PropTypes.bool
}

export default ChatButton
