import { CHART_TYPES, STATISTIC_RANGES_OPTIONS } from '__constants__'

import { getDaysInMonth } from 'helpers'
import { theme } from 'antd'

const useGetChartConfig = (data, type, range) => {
  const { colorPrimary, colorTextSecondary, colorPrimaryBg } =
    theme.useToken().token
  const currMonthNumber = STATISTIC_RANGES_OPTIONS?.find(
    (item) => item?.value === range
  )?.monthNumber

  const rangeLabels = Array.from(
    { length: getDaysInMonth(currMonthNumber) },
    (_, index) => index + 1
  )

  const isLineChart = CHART_TYPES.LINE === type
  // Math.max(...(data || [])) * 0.05 - is to avoid cropped chart
  const maxValue =
    Math.round(Math.max(...(data || []))) + Math.max(...(data || [])) * 0.05 ||
    100

  const config = isLineChart
    ? {
        data: {
          labels: rangeLabels,
          type: 'line',
          datasets: [
            {
              label: '',
              data: data,
              borderColor: colorPrimary,
              backgroundColor: colorPrimaryBg,
              borderWidth: 2
            }
          ]
        },
        options: {
          elements: {
            point: {
              pointStyle: 'circle'
            }
          },
          plugins: {
            legend: {
              labels: { boxWidth: '0px' }
            },
            tooltip: {
              backgroundColor: colorPrimaryBg,
              interaction: {
                intersect: false
              },
              titleColor: colorTextSecondary,
              bodyColor: colorPrimary,
              titleFont: {
                weight: 400
              },
              titleAlign: 'center',
              displayColors: false,
              bodyAlign: 'center',
              borderWidth: 2,
              bodyFont: {
                size: '16px',
                lineHeight: '24px'
              }
            }
          },
          cubicInterpolationMode: 'monotone',
          responsive: true,
          scales: {
            x: {
              grid: {
                display: false,
                drawOnChartArea: true,
                drawTicks: true,
                border: false
              }
            },
            y: {
              position: 'right',
              min: 0,
              max: maxValue,
              ticks: {
                stepSize: maxValue / 5
              }
            }
          }
        }
      }
    : {
        data: {
          labels: rangeLabels,
          type: 'bar',
          datasets: [
            {
              label: '',
              data: data,
              borderColor: colorPrimary,
              backgroundColor: colorPrimary,
              grouped: true,
              maxBarThickness: 80,
              borderWidth: 1,
              borderRadius: 4
            }
          ]
        },
        options: {
          plugins: {
            legend: {
              labels: { boxWidth: '0px' }
            },
            tooltip: {
              backgroundColor: colorPrimaryBg,
              interaction: {
                intersect: false
              },
              titleColor: colorTextSecondary,
              bodyColor: colorPrimary,
              titleFont: {
                weight: 400
              },
              titleAlign: 'center',
              displayColors: false,
              bodyAlign: 'center',
              borderWidth: 2,
              bodyFont: {
                size: '16px',
                lineHeight: '24px'
              }
            }
          },
          responsive: true,
          scales: {
            x: {
              grid: {
                display: false,
                drawOnChartArea: true,
                drawTicks: true,
                border: false
              }
            },
            y: {
              position: 'right',
              min: 0,
              max: maxValue,
              ticks: {
                stepSize: maxValue / 5
              }
            }
          }
        }
      }
  return config
}

export default useGetChartConfig
