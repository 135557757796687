const styles = {
  labelWrapper: {
    className: 'flex align-center'
  },
  iconProps: {
    fill: 'currentColor',
    className: 'mr-8'
  },
  textProps: {
    style: { color: 'currentColor' }
  },

  buttonProps: {
    size: 'small',
    className: 'flex align-center justify-center',
    type: 'text'
  }
}

export default styles
