import { Typography } from 'antd'
import styled from 'styled-components'
import { VERIFICATION_STATUSES } from 'modules/stripe-module/__constants__'

const { Text } = Typography

const VerificationStatusText = styled(Text)`
  color: ${({ token, status }) =>
    token?.[VERIFICATION_STATUSES?.[status]?.color]};
`

export default VerificationStatusText
