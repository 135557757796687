import {
  AddressAdvancedSelect,
  AddressSimpleView
} from 'domains/Address/components'
import { Col, Form, Row, Typography } from 'antd'
import { DELIVERY_TYPES, KEYS } from '__constants__'
import { FormWrapper, SegmentedSelect, TimePicker } from 'components'
import { LocationFormInModal } from 'domains/GoogleMaps/components'
import PropTypes from 'prop-types'
import { useGetUserAddresses } from 'domains/Address/hooks'
import { useTranslations } from 'hooks'

const CheckoutProductLocationForm = (props) => {
  const { initialValues, form, group, title, selectedStartDate } = props
  const { t } = useTranslations()
  /* Getting new form instance from Form.useForm hook */
  const [baseForm] = Form.useForm()
  const [addresses, loadingAddresses] = useGetUserAddresses()

  const currentValue = Form.useWatch([...group, 'locationData'], form)

  /* Getting used form from props or using base form */
  const usedForm = form || baseForm

  /* Submitting form on enter key press */
  const handleKeyPress = (e) => {
    if (e.key === KEYS.ENTER) {
      e.preventDefault()
      e.stopPropagation()
      usedForm?.submit()
    }
  }

  const getFieldName = (subGroup) => (group ? [...group, subGroup] : [subGroup])
  const radioValue = Form.useWatch([...group, 'deliveryType'], form)

  const isDeliveryAvailable = initialValues?.product?.isRenterDelivery

  const deliveryOptions = [
    { label: t('Pick up yourself'), value: DELIVERY_TYPES.SELF_PICK_UP },
    { label: t('Deliver to me'), value: DELIVERY_TYPES.DELIVERY }
  ]

  return (
    <>
      <Row>
        <Col xs={24} sm={24} md={24} lg={20} xl={16} xxl={12}>
          {title && (
            <div className="mb-24">
              <Typography.Title level={4}>{title}</Typography.Title>
            </div>
          )}
          <FormWrapper
            initialValues={initialValues}
            onKeyPress={handleKeyPress}
            form={usedForm}
            group={group}>
            <Form.Item
              noStyle
              isDeliveryAvailable={isDeliveryAvailable}
              data-area="checkout-delivery-type"
              name={getFieldName('deliveryType')}
              rules={[
                { required: true, message: t('Please select delivery type!') }
              ]}>
              <SegmentedSelect
                options={deliveryOptions}
                segmented
                className="mb-24"
              />
            </Form.Item>

            {radioValue === DELIVERY_TYPES.SELF_PICK_UP && (
              <div className="mb-24">
                <AddressSimpleView onlyView address={initialValues?.address} />
              </div>
            )}

            {radioValue === DELIVERY_TYPES.DELIVERY && (
              <div className="mb-24">
                <Form.Item name={getFieldName('locationData')}>
                  <AddressAdvancedSelect
                    form={form}
                    defaultValue={initialValues?.address}
                    addresses={addresses}
                    loadingAddresses={loadingAddresses}
                  />
                </Form.Item>
                <LocationFormInModal address={currentValue} form={form} />
              </div>
            )}
            <Form.Item
              label={<Typography.Text>{t('Time')}</Typography.Text>}
              data-area="checkout-delivery-time"
              rules={[
                { required: true, message: t('Please select delivery time!') }
              ]}
              name={getFieldName('deliveryTime')}>
              <TimePicker selectedDate={selectedStartDate} showNow={false} />
            </Form.Item>
          </FormWrapper>
        </Col>
      </Row>
    </>
  )
}

CheckoutProductLocationForm.propTypes = {
  initialValues: PropTypes.object,
  form: PropTypes.object,
  group: PropTypes.array,
  onSubmit: PropTypes.array,
  title: PropTypes.string,
  selectedStartDate: PropTypes.object
}

export default CheckoutProductLocationForm
