import { Icon } from '@qonsoll/icons'
import styled from 'styled-components'

const ErrorIconStyled = styled(Icon)`
  path {
    fill: #faad14;
    stroke: unset !important ;
  }
`

export default ErrorIconStyled
