import { Col, Row, Typography } from 'antd'
import {
  GrowthPercentageView,
  ProductCategory
} from 'domains/Statistic/components'
import { StyledRelativeImage } from 'domains/Statistic/components/StatisticProductSimpleView/StatisticProductSimpleView.styled'
import { formatNumber, getTransformedImageUrl } from 'helpers'
import { useMemo, useState } from 'react'
import { useGetTableSearchProps, useTranslations } from 'hooks'
import { currencySymbol } from 'domains/Product/hooks'

const { Text } = Typography

const useGetPopularProductsConfig = (products) => {
  const [pageSize, setPageSize] = useState(6)
  const { t } = useTranslations()

  const getColumnSearchProps = useGetTableSearchProps({
    placeholder: t('Search by product name')
  })

  const columns = useMemo(
    () => [
      {
        width: '328px',
        title: t('Product'),
        dataIndex: 'productName',
        key: 'product',
        fixed: 'left',
        ...getColumnSearchProps('name', ['name']),
        render: (_, record) => {
          const transformedImageUrl = getTransformedImageUrl(
            record?.previewImageDocument
          )

          return (
            <Row gutter={[12, 12]} align="middle" className="flex-nowrap">
              <Col>
                <StyledRelativeImage
                  ratio={1.5}
                  src={transformedImageUrl}
                  withOverlay={false}
                />
              </Col>
              <Col className={`flex align-center x-hidden`}>
                <Text ellipsis>{record?.name}</Text>
              </Col>
            </Row>
          )
        }
      },
      {
        width: '160px',
        title: t('Category'),
        dataIndex: 'category',
        key: 'category',

        render: (_, record) => (
          <ProductCategory categoryId={record?.categoryId} />
        )
      },
      {
        width: '124px',
        title: t('Growth'),
        dataIndex: 'growth',
        key: 'growth',

        render: (_, record) => (
          <GrowthPercentageView
            firstValue={record?.yesterdayRentNumber}
            secondValue={record?.todayRentNumber}
          />
        )
      },
      {
        width: '124px',
        title: t('Rented'),
        dataIndex: 'rented',
        key: 'rented',
        render: (_, record) => <Text>{record?.totalRentNumber}</Text>
      },
      {
        width: '124px',
        title: t('Price'),
        dataIndex: 'price',
        key: 'price',
        render: (_, record) => {
          const formattedTotalRentPrice = formatNumber({
            locales: 'en-US',
            number: record?.pricePerDayWithFees
          })
          return <Text>{formattedTotalRentPrice}</Text>
        }
      },

      {
        width: '124px',
        title: t('Total earning'),
        dataIndex: 'total earning',
        key: 'total earning',
        render: (_, record) => {
          const formattedTotalRentPrice = formatNumber({
            locales: 'en-US',
            number: record?.totalRentPrice
          })
          const productCurrency = currencySymbol(record?.currency)
          return (
            <Text>
              {formattedTotalRentPrice} {productCurrency}
            </Text>
          )
        }
      }
    ],
    [getColumnSearchProps, t]
  )

  const paginationProps = useMemo(
    () => ({
      hideOnSinglePage: true,
      position: ['bottomLeft'],
      pageSize: pageSize,
      pageSizeOptions: [6, 10, 20, 50, 100],
      total: products?.length,
      showSizeChanger: true,
      showQuickJumper: true,
      onShowSizeChange: (_, pageSize) => setPageSize(pageSize)
    }),
    [pageSize, products?.length]
  )
  const tableProps = { scroll: { x: 940, y: 650 }, tableLayout: 'fixed' }

  return {
    columns,
    paginationProps,
    tableProps
  }
}

export default useGetPopularProductsConfig
