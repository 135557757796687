import { DottedBlock } from 'components'
import PropTypes from 'prop-types'
import { Typography } from 'antd'
import { useGetUserDisplayName } from 'domains/User/hooks'
import { useTranslations } from 'hooks'

const OrderRentee = (props) => {
  const { order } = props
  const { t } = useTranslations()

  const renteeDisplayName = useGetUserDisplayName(order?.rentee || {})

  return (
    <>
      <Typography.Title level={5} className="mt-24 mb-12">
        {t('Rentee')}
      </Typography.Title>
      <DottedBlock
        className="mb-8"
        height="22px"
        left={<Typography.Text>{t('Name')}</Typography.Text>}
        right={<Typography.Text>{renteeDisplayName}</Typography.Text>}
      />
      <DottedBlock
        className="mb-8"
        height="22px"
        left={<Typography.Text>{t('Email')}</Typography.Text>}
        right={<Typography.Text>{order?.rentee?.email}</Typography.Text>}
      />
      <DottedBlock
        className="mb-8"
        height="22px"
        left={<Typography.Text>{t('Phone')}</Typography.Text>}
        right={<Typography.Text>{order?.rentee?.phone}</Typography.Text>}
      />
    </>
  )
}

OrderRentee.propTypes = {
  order: PropTypes.object
}

export default OrderRentee
