const REVIEW_STATUSES = {
  PUBLISHED: 'PUBLISHED',
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED'
}

const REVIEW_STATUSES_LABELS = {
  PUBLISHED: 'Published',
  PENDING: 'Pending',
  APPROVED: 'Approved',
  REJECTED: 'Rejected'
}

const REVIEW_STATUSES_OPTIONS = Object.keys(REVIEW_STATUSES_LABELS).map(
  (key) => ({ label: REVIEW_STATUSES[key], value: key })
)

export default REVIEW_STATUSES
export { REVIEW_STATUSES_OPTIONS, REVIEW_STATUSES, REVIEW_STATUSES_LABELS }
