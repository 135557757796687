import { AddressPreview } from './components'
import { LocationForm } from 'domains/GoogleMaps/components'
import { Modal, Row, Col, Button, Typography, message } from 'antd'
import PropTypes from 'prop-types'
import { useScreen, useTranslations } from 'hooks'
import { useState } from 'react'

const { Title } = Typography

const LocationFormInModal = (props) => {
  const {
    handleCloseModal,
    form,
    address,
    showAddressTitle,
    group = ['address'],
    onSaveValue,
    className,
    addressId,
    ...rest
  } = props

  const [isLocationModalOpen, setIsLocationModalOpen] = useState(false)

  const { isSmallScreen } = useScreen()
  const { t } = useTranslations()

  const openLocationModal = () => setIsLocationModalOpen(true)
  const closeLocationModal = () => setIsLocationModalOpen(false)

  const [addressData, setAddressData] = useState()

  const onAddressChange = ({ address }) => {
    setAddressData(address)
  }

  const handleSave = () => {
    form.setFieldValue([...group, 'locationData'], addressData)
    message.success(t('The address was added'))
    closeLocationModal()
    onSaveValue?.(addressData)
  }

  const addressComputed = form.getFieldValue([...group, 'locationData'])

  return (
    <>
      <AddressPreview
        showAddressTitle={showAddressTitle}
        onClick={openLocationModal}
        address={addressComputed}
        className={className}
        loading={addressId && !addressComputed}
      />

      <Modal
        open={isLocationModalOpen}
        footer={null}
        title={
          <Row className="justify-between">
            <Col>
              <Title level={5}>{t('Select address')}</Title>
            </Col>
            <Col>
              <Button type="primary" onClick={handleSave}>
                {t('Save')}
              </Button>
            </Col>
          </Row>
        }
        onCancel={closeLocationModal}
        onOk={closeLocationModal}
        okText={t('Select')}
        forceRender
        width={isSmallScreen ? '90%' : '50%'}
        bodyStyle={{ padding: '0px !important', height: '60vh' }}
        closable={false}
        {...rest}>
        <LocationForm
          address={addressComputed}
          group={group}
          form={form}
          onAddressChange={onAddressChange}
        />
      </Modal>
    </>
  )
}

LocationFormInModal.propTypes = {
  isModalOpen: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  onAddressChange: PropTypes.func,
  initialValues: PropTypes.object,
  form: PropTypes.object,
  address: PropTypes.object,
  showAddressTitle: PropTypes.bool,
  group: PropTypes.array,
  onSaveValue: PropTypes.func,
  className: PropTypes.string,
  addressId: PropTypes.string
}
export default LocationFormInModal
