const DELIVERY_TYPES = {
  SELF_PICK_UP: 'SELF_PICK_UP',
  DELIVERY: 'DELIVERY'
}

const DELIVERY_TYPES_LABELS = {
  SELF_PICK_UP: 'Self pick-up',
  DELIVERY: 'Delivery'
}

const DELIVERY_TYPES_OPTIONS = Object.keys(DELIVERY_TYPES_LABELS).map(
  (key) => ({
    label: DELIVERY_TYPES_LABELS[key],
    value: key
  })
)

export default DELIVERY_TYPES
export { DELIVERY_TYPES_OPTIONS, DELIVERY_TYPES, DELIVERY_TYPES_LABELS }
