import { Badge, Typography, theme } from 'antd'

import PropTypes from 'prop-types'
import styled from 'styled-components'

const MenuButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px 0 24px;
  margin: 0px 4px 0px 4px;
  height: 40px;
  cursor: pointer;
  transition: border-color 0.3s, background 0.3s,
    padding 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  &:hover {
    background: ${({ hoverBg }) => hoverBg};
  }
`
const BtnText = styled(Typography.Text)`
  opacity: ${({ collapsed }) => (collapsed ? 0 : 1)};
  display: ${({ collapsed }) => (collapsed ? 'none' : 'flex')};
  transition: all 0.2s;
`
export const BtnWithStyles = (props) => {
  const {
    prefix,
    suffix,
    collapsed,
    children,
    withBadge,
    badgeNumber,
    disabled,
    style = {}
  } = props

  const { borderRadiusLG, colorFillSecondary } = theme.useToken().token
  return !withBadge ? (
    <MenuButtonWrapper
      collapsed={collapsed}
      {...props}
      style={{ borderRadius: borderRadiusLG, ...style }}
      hoverBg={colorFillSecondary}>
      <div
        className="flex align-center"
        style={{ paddingLeft: collapsed && '3px' }}>
        <div className="flex align-center justify-center mr-8">
          {prefix || null}
        </div>
        {!collapsed && <BtnText disabled={disabled}>{children}</BtnText>}
      </div>
      <div className="flex align-center justify-center">
        {!collapsed ? suffix || null : null}
      </div>
    </MenuButtonWrapper>
  ) : (
    <Badge
      size="small"
      count={badgeNumber}
      style={{ position: 'absolute', right: 24, top: 8 }}>
      <MenuButtonWrapper
        collapsed={collapsed}
        {...props}
        style={{ borderRadius: borderRadiusLG, ...style }}
        hoverBg={colorFillSecondary}>
        <div
          className="flex align-center"
          style={{ paddingLeft: collapsed && '3px' }}>
          <div className="flex align-center justify-center mr-8">
            {prefix || null}
          </div>
        </div>
        <div className="flex align-center justify-center">
          {!collapsed ? suffix || null : null}
        </div>
      </MenuButtonWrapper>
    </Badge>
  )
}

BtnWithStyles.propTypes = {
  prefix: PropTypes.node,
  suffix: PropTypes.node,
  collapsed: PropTypes.bool,
  children: PropTypes.node,
  withBadge: PropTypes.bool,
  badgeNumber: PropTypes.number,
  style: PropTypes.object,
  disabled: PropTypes.bool
}

export { BtnWithStyles as MenuButton }
