import PropTypes from 'prop-types'
import UIContext from './context'

const UIProvider = (props) => {
  const { children } = props

  return <UIContext.Provider>{children}</UIContext.Provider>
}

UIProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element
  ])
}

export default UIProvider
