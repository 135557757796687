import { theme } from 'antd'
import {
  calculatePercentageGrowth,
  getGrowthIconAndColor
} from 'domains/Statistic/helpers'
import { Icon } from '@qonsoll/icons'
import PropTypes from 'prop-types'
import { Box } from 'components'

const GrowthPercentageView = (props) => {
  const { firstValue, secondValue } = props

  const token = theme.useToken().token

  const growthPercentage = calculatePercentageGrowth(firstValue, secondValue)
  const growthIconAndColor = getGrowthIconAndColor(growthPercentage, token)

  return (
    <Box className="flex align-center">
      <Icon
        name={growthIconAndColor?.growthIcon}
        fill={growthIconAndColor?.growthIconColor}
      />
      {growthPercentage}
    </Box>
  )
}

GrowthPercentageView.propTypes = {
  firstValue: PropTypes.number,
  secondValue: PropTypes.number
}

export default GrowthPercentageView
