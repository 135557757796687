import { useLocation, useNavigate } from 'react-router-dom'

import PropTypes from 'prop-types'
import { SegmentedSelect } from 'components'

const PageNav = (props) => {
  const { options } = props

  const navigate = useNavigate()
  const location = useLocation()

  const navigateByPath = (key) => navigate(key)

  const wrapperStyles = { minWidth: '180px', marginBottom: '24px' }

  return (
    <div>
      <SegmentedSelect
        wrapperStyles={wrapperStyles}
        defaultValue={location.pathname}
        options={options}
        segmented
        onChange={navigateByPath}
      />
    </div>
  )
}

PageNav.propTypes = {
  options: PropTypes.array.isRequired
}

export default PageNav
