const COMPLAINT_STATUSES = {
  PENDING: 'PENDING',
  ON_REVIEW: 'ON_REVIEW',
  RESOLVED: 'RESOLVED',
  REJECTED: 'REJECTED',
  CLOSED: 'CLOSED'
}

const COMPLAINT_STATUSES_LABELS = {
  PENDING: 'Pending',
  ON_REVIEW: 'On review',
  RESOLVED: 'Resolved',
  REJECTED: 'Rejected',
  CLOSED: 'Closed'
}

const COMPLAINT_STATUSES_OPTIONS = Object.keys(COMPLAINT_STATUSES_LABELS).map(
  (key) => ({ label: COMPLAINT_STATUSES[key], value: key })
)

export default COMPLAINT_STATUSES
export {
  COMPLAINT_STATUSES_OPTIONS,
  COMPLAINT_STATUSES,
  COMPLAINT_STATUSES_LABELS
}
