import { APP_PATHS, PATHS } from '__constants__'
import { Breadcrumbs, LoadingBox, PageActions, PageLayout } from 'components'
import { Col, Dropdown, Pagination, Row, Tabs } from 'antd'
import { useGetActiveTab, useListView, useTranslations } from 'hooks'
import {
  useGetMyProducts,
  useMyProductsActions,
  useProductBaseActions,
  usePublishDraftProducts
} from 'domains/Product/hooks'

import { ProductList } from 'domains/Product/components'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

const { MY_PRODUCTS_ALL, PRODUCT_DRAFTS_ALL, PRODUCT_BLOCKED_ALL } = APP_PATHS

const MyProductsAllPage = (props) => {
  const {
    isListWithCreate,
    withHeader = true,
    isBlockedPage,
    isDraftsPage
  } = props

  const [{ draftProducts, publishedProducts, blockedProducts }, loading] =
    useGetMyProducts()
  const {
    handlePageChange,
    currentPage,
    pageSize,
    setPageSize,
    setCurrentPage,
    total,
    productsForShowing
  } = useMyProductsActions({
    draftProducts,
    publishedProducts,
    blockedProducts,
    isDraftsPage,
    isBlockedPage
  })

  /* Getting translations instance */
  const { t } = useTranslations()
  /* State for controlling list view with localStorage remembering */
  const [listView, setListView] = useListView('product')

  const navigate = useNavigate()
  const activeTab = useGetActiveTab()

  /* Query for fetching products */

  /* Getting base actions */
  const listActions = useProductBaseActions()

  // get helpers for publish products
  const {
    onDraftsToPublishSelect,
    selectedDrafts,
    onPublishCompletedProducts,
    onPublishSelectedProducts,
    disablePublish,
    disableDraftProductsTab
  } = usePublishDraftProducts(draftProducts)

  const tabItems = useMemo(
    () => [
      {
        label: t('Published'),
        key: MY_PRODUCTS_ALL
      },
      {
        label: t('Drafts'),
        key: PRODUCT_DRAFTS_ALL,
        disabled: disableDraftProductsTab
      },
      {
        label: t('Blocked'),
        key: PRODUCT_BLOCKED_ALL,
        disabled: !blockedProducts?.length
      }
    ],
    [blockedProducts, t, disableDraftProductsTab]
  )

  const handleChangeTab = (key) => navigate(key)

  const pageTitle = t(
    isBlockedPage
      ? 'Blocked products'
      : isDraftsPage
      ? 'Draft products'
      : 'Products'
  )
  /* Configuring page header */
  const headingProps = {
    title: pageTitle,
    textAlign: 'left'
  }

  /* Configuring page actions */
  const actions = {
    listView: true,
    create: Boolean(PATHS.AUTHENTICATED.PRODUCT_CREATE)
  }

  /* Extracting base actions */
  const { onBack, onCreate } = listActions

  return (
    <PageLayout
      actions={
        withHeader ? (
          <PageActions
            listView={listView}
            setListView={setListView}
            onCreate={onCreate}
            actions={actions}
            createText={t('Create product')}
          />
        ) : null
      }
      onBack={withHeader ? onBack : null}
      breadcrumbs={withHeader ? <Breadcrumbs /> : null}
      headingProps={withHeader ? headingProps : null}
      area="products">
      <Tabs
        defaultActiveKey={activeTab}
        onChange={handleChangeTab}
        items={tabItems}
        tabBarExtraContent={
          isDraftsPage && (
            <Dropdown.Button
              disabled={disablePublish}
              menu={{
                items: [
                  {
                    key: 'all',
                    label: t('All completed'),
                    onClick: onPublishCompletedProducts
                  },
                  {
                    key: 'selected',
                    label: t('Selected'),
                    onClick: onPublishSelectedProducts,
                    disabled: !selectedDrafts?.length
                  }
                ]
              }}>
              {t('Publish')}
            </Dropdown.Button>
          )
        }
      />
      <Row className="full-height overflow-auto" gutter={[24, 16]}>
        <LoadingBox
          height="100%"
          loading={loading}
          spinnerProps={{ text: t('Products loading...') }}>
          <Col flex="auto">
            <ProductList
              maxHeight="unset"
              span={{ xs: 24, sm: 12, md: 12, lg: 8, xl: 8, xxl: 6 }}
              pagination={
                <Pagination
                  hideOnSinglePage={true}
                  style={{ justifyContent: 'center', display: 'flex' }}
                  defaultCurrent={1}
                  total={total}
                  pageSize={pageSize}
                  current={currentPage}
                  onChange={handlePageChange}
                />
              }
              total={total}
              isDraftsPage={isDraftsPage}
              isBlockedPage={isBlockedPage}
              listView={listView}
              actions={{ onCreate }}
              isListWithCreate={isListWithCreate}
              products={productsForShowing}
              onDraftsToPublishSelect={onDraftsToPublishSelect}
              selectedDrafts={selectedDrafts}
              pageSize={pageSize}
              setPageSize={setPageSize}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              handlePageChange={handlePageChange}
            />
          </Col>
        </LoadingBox>
      </Row>
    </PageLayout>
  )
}
MyProductsAllPage.propTypes = {
  isListWithCreate: PropTypes.bool,
  withHeader: PropTypes.bool,
  isDraftsPage: PropTypes.bool,
  isBlockedPage: PropTypes.bool
}

export default MyProductsAllPage
