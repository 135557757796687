const PAYMENT_STATUSES = {
  PAID: 'PAID',
  OPEN: 'OPEN',
  FAILED: 'FAILED',
  CANCELED: 'CANCELED',
  UNPAID: 'UNPAID',
  HOLD: 'HOLD',
  REFUND_SUCCEEDED: 'REFUND_SUCCEEDED',
  REFUND_PENDING: 'REFUND_PENDING',
  REFUND_FAILED: 'REFUND_FAILED'
}

const PAYMENT_STATUSES_LABELS = {
  PAID: 'Paid',
  OPEN: 'Open',
  FAILED: 'Failed',
  CANCELED: 'Canceled',
  UNPAID: 'Unpaid',
  HOLD: 'Hold',
  REFUND_SUCCEEDED: 'Refund succeeded',
  REFUND_PENDING: 'Refund pending',
  REFUND_FAILED: 'Refund failed'
}

const PAYMENT_STATUSES_OPTIONS = Object.keys(PAYMENT_STATUSES_LABELS).map(
  (key) => ({ label: PAYMENT_STATUSES[key], value: key })
)

export default PAYMENT_STATUSES
export { PAYMENT_STATUSES_OPTIONS, PAYMENT_STATUSES, PAYMENT_STATUSES_LABELS }
