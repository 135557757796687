// import { APP_PATHS, SERVICE_PATHS, SETTINGS_PATHS } from '../paths'
import APP_PATHS from './appPaths'
import { AUTH_PATHS } from 'modules/session-module/__constants__'
import SERVICE_PATHS from './servicePaths'
import SETTINGS_PATHS from './servicePaths'

const PATHS = {
  CONFIG: {
    DEFAULT: AUTH_PATHS.LOGIN,
    AFTER_LOGOUT: AUTH_PATHS.LOGIN,
    AFTER_LOGIN: APP_PATHS.DASHBOARD,
    AFTER_SIGNUP: APP_PATHS.DASHBOARD
  },
  UNAUTHENTICATED: {
    ...AUTH_PATHS
  },
  SERVICE: {
    ...SERVICE_PATHS
  },
  AUTHENTICATED: {
    ...APP_PATHS,
    ...SETTINGS_PATHS
  }
}

export default PATHS
