import { Card, Col, Row, Tabs, Typography } from 'antd'
import { useStateWithStorage, useTranslations } from 'hooks'

import PropTypes from 'prop-types'
import { ReviewSubjectRelationView } from 'domains/Review/components'
import { StickyContainer } from 'react-sticky'

const ReviewAdvancedView = (props) => {
  const { review } = props

  /* State for active tab in AdvancedView with saving it to storage */
  const [activeTab, setActiveTab] = useStateWithStorage(
    null,
    'review-advanced-view-active-tab'
  )

  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting TabPane component from Tabs */
  const { TabPane } = Tabs

  /* Handling change tab */
  const changeTab = (key) => setActiveTab(key)

  return (
    <Row gutter={{ xs: 8, sm: 16, md: 24 }} data-area="review-details">
      <Col span={8} mb={3} data-area="review-details-info">
        <Card className="mb-32">
          <div className="mb-12" data-area="review-details-comment">
            <Row gutter={16}>
              <Col flex="auto" maxWidth="100%">
                <Typography.Text type="secondary">
                  {t('Comment')}
                </Typography.Text>
              </Col>
              <Col flex="none" maxWidth="100%">
                <Typography.Text>{review?.comment || 'n/a'}</Typography.Text>
              </Col>
            </Row>
          </div>
          <div className="mb-12" data-area="review-details-rating">
            <Row gutter={16}>
              <Col flex="auto" maxWidth="100%">
                <Typography.Text type="secondary">
                  {t('Rating')}
                </Typography.Text>
              </Col>
              <Col flex="none" maxWidth="100%">
                <Typography.Text>{review?.rating || 'n/a'}</Typography.Text>
              </Col>
            </Row>
          </div>
          <div className="mb-12" data-area="review-details-status">
            <Row gutter={16}>
              <Col flex="auto" maxWidth="100%">
                <Typography.Text type="secondary">
                  {t('Status')}
                </Typography.Text>
              </Col>
              <Col flex="none" maxWidth="100%">
                <Typography.Text>{review?.status || 'n/a'}</Typography.Text>
              </Col>
            </Row>
          </div>
        </Card>
      </Col>
      <Col span={16} data-area="review-extra-details">
        <StickyContainer>
          <Tabs defaultActiveKey={activeTab} onChange={changeTab}>
            <TabPane tab={t('Subject')} key="#subject" tab-index="#subject">
              <ReviewSubjectRelationView review={review} />
            </TabPane>
          </Tabs>
        </StickyContainer>
      </Col>
    </Row>
  )
}

ReviewAdvancedView.propTypes = {
  review: PropTypes.object
}

export default ReviewAdvancedView
