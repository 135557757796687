import { Col, Row, theme } from 'antd'
import { useCallback, useMemo } from 'react'

import { APP_PATHS } from '__constants__'
import { AddItemCard } from 'components'
import { CartSimpleView } from 'domains/Cart/components'
import { Icon } from '@qonsoll/icons'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { useTranslations } from 'hooks'

const CartList = (props) => {
  const { carts, isCheckout } = props
  const { t } = useTranslations()
  const navigate = useNavigate()

  const handleAddClick = useCallback(() => {
    navigate(APP_PATHS.DASHBOARD)
  }, [navigate])

  const { colorPrimaryBg, colorPrimaryBgHover } = theme.useToken().token

  const addItemCartProps = useMemo(
    () => ({
      icon: <Icon name="ShoppingCart3Outlined" size={32} />,
      onClick: handleAddClick,
      withMessage: true,
      messageType: 'secondary',
      message: t('Your cart is empty. Start shopping to fill up your cart.'),
      borderColorHover: colorPrimaryBgHover,
      backgroundColorHover: colorPrimaryBg
    }),
    [colorPrimaryBg, t, colorPrimaryBgHover, handleAddClick]
  )

  const checkoutsMap = useMemo(
    () =>
      carts?.map((cart, index) => (
        <Col span={24} key={`cartItem-${index}`}>
          <CartSimpleView cart={cart} isCheckout={isCheckout} />
        </Col>
      )),
    [carts, isCheckout]
  )

  return (
    <Row gutter={[0, 16]} justify={'center'}>
      {!carts?.length && (
        <Col span={24}>
          <AddItemCard {...addItemCartProps} />
        </Col>
      )}
      {checkoutsMap}
    </Row>
  )
}

CartList.propTypes = {
  carts: PropTypes.array.isRequired,
  isCheckout: PropTypes.bool
}

export default CartList
