import { ref, update } from 'firebase/database'

import { RDB_COLLECTIONS } from '__constants__'
import { database } from 'services/firebase'
import { useUser } from 'modules/session-module/contexts'
import { useHandleError, useTranslations } from 'hooks'

const useProductRatingActions = () => {
  const handleError = useHandleError()
  const { user } = useUser()
  const { t } = useTranslations()

  const onRatingUpdate = async (productId, ratingValue) => {
    try {
      await update(
        ref(database, `${RDB_COLLECTIONS.PRODUCT_RATINGS}/${productId}`),
        {
          [user?._id]: ratingValue
        }
      )
    } catch (error) {
      handleError(error, t('Error during saving rating'))
    }
  }

  return { onRatingUpdate }
}

export default useProductRatingActions
