import {
  ChatHeader,
  ChatLoading
} from 'modules/chat-module/domains/Chat/components'
import { Collapse, Divider, theme } from 'antd'
import {
  SelectedMessageProvider,
  useChatFetch,
  useChatsContext
} from '@qonsoll/lentli-chat-core'
import { Suspense, lazy, useState } from 'react'

import { Box } from 'components'
import { Icon } from '@qonsoll/icons'
import { Panel } from './ChatCollapse.styled'
import PropTypes from 'prop-types'

// for now we don't use this component and user cant denied to receive message
// const SendMessagePermissionSwitch = lazy(() =>
//   import(
//     'modules/chat-module/domains/Permissions/components/SendMessagePermissionSwitch'
//   )
// )
const ChatMessageSimpleForm = lazy(() =>
  import('../../../MessageForm/components/MessageSimpleForm')
)
const MessageList = lazy(() =>
  import(
    'modules/chat-module/domains/MessageList/components/MessageListWrapper'
  )
)

const PANEL_NAME = 'chat'

const ChatCollapse = (props) => {
  const { height = 600, initialVisible, setIsChatOpen } = props
  const { colorTextQuaternary } = theme.useToken().token
  const chat = useChatsContext()
  const chatLoading = useChatFetch()[1]

  const [activePanel] = useState(initialVisible ? PANEL_NAME : null)

  const fallback = (
    <Icon
      name="LoadingFilled"
      spin
      mr="16px"
      size={24}
      fill={colorTextQuaternary}
    />
  )

  return (
    <Collapse
      className="disquss-collapse"
      ghost={false}
      expandIconPosition="right"
      destroyInactivePanel
      activeKey={activePanel}
      onChange={() => {
        setIsChatOpen((prev) => !prev)
      }}
      expandIcon={({ isActive }) => (
        <Icon
          size={30}
          name={isActive ? 'ArrowShortDownFilled' : 'ArrowShortUpFilled'}
          fill={colorTextQuaternary}
        />
      )}>
      <Panel
        className="customCollapseHeader"
        header={<ChatHeader withUser withWrapper={false} />}
        key={PANEL_NAME}>
        <Box height={height}>
          <Box display="flex" flexDirection="column" height="100%">
            {chatLoading || chat?.oppositeUserDataLoading ? (
              <ChatLoading />
            ) : (
              <Suspense fallback={fallback}>
                <SelectedMessageProvider>
                  {activePanel && <MessageList />}
                  <Divider className="disquss-footer-divider my-8" />
                  <Suspense fallback={fallback}>
                    <ChatMessageSimpleForm chat={chat} />
                  </Suspense>
                </SelectedMessageProvider>
              </Suspense>
            )}
          </Box>
        </Box>
      </Panel>
    </Collapse>
  )
}

ChatCollapse.propTypes = {
  height: PropTypes.number,
  initialVisible: PropTypes.bool,
  setIsChatOpen: PropTypes.func
}

export default ChatCollapse
