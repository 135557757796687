import { Badge, Button, Col, Image, Popover, Row, theme } from 'antd'

import AccountMenu from '../AccountMenu'
import { HeaderMenuStyled } from './HeaderMenu.styles'
import { Icon } from '@qonsoll/icons'
import { LanguageToggle } from 'components/layouts/AppLayout/components'
import { MenuButton } from '../../../BottomMenu/BottomMenu.styled'
import { NotificationsContent } from 'domains/Notification/components'
import PropTypes from 'prop-types'
import logo from '__assets__/icons/logo.svg'
import { useGetNotifications } from 'domains/Notification/hooks'
import { useState } from 'react'
import { useUser } from 'modules/session-module/contexts'

const HeaderMenu = (props) => {
  const [popoverVisible, setPopoverVisible] = useState(false)
  const [notifications, unseenNotifications] = useGetNotifications()

  const { handleShowSearch } = props

  const token = theme.useToken().token

  const { isRentee } = useUser()

  return (
    <HeaderMenuStyled theme={token}>
      <Row className="full-height align-center">
        <Col className="mr-auto">
          <Image
            src={logo}
            className="cursor-pointer"
            preview={false}
            height={24}
            alt="logo"
          />
        </Col>
        {isRentee && (
          <Col className="mr-4">
            <Button
              onClick={handleShowSearch}
              type="text"
              icon={<Icon name="SearchLgOutlined" size={18} />}
            />
          </Col>
        )}
        <Col className="mr-4">
          <Popover
            overlayStyle={{ width: '340px' }}
            overlayInnerStyle={{ padding: '0' }}
            trigger="click"
            content={
              <NotificationsContent
                notifications={notifications}
                setPopoverVisible={setPopoverVisible}
              />
            }
            open={popoverVisible}
            onOpenChange={setPopoverVisible}>
            <MenuButton
              className="flex align-center justify-center"
              suffix={<Badge count={unseenNotifications?.length} />}
              withBadge={true}
              collapsed={true}
              block
              prefix={<Icon size={16} name="Bell1Outlined" />}
              badgeNumber={unseenNotifications?.length}
            />
          </Popover>
        </Col>
        <Col className="mr-12">
          <LanguageToggle collapsed padding={4} height={32} />
        </Col>
        <Col>
          <AccountMenu />
        </Col>
      </Row>
    </HeaderMenuStyled>
  )
}
HeaderMenu.propTypes = {
  handleShowSearch: PropTypes.func
}
export default HeaderMenu
