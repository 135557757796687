import {
  Cart,
  CategoriesAll,
  Chat,
  Checkout,
  CheckoutSuccess,
  ComplaintsAll,
  ComplaintShow,
  CustomerShow,
  CustomersAll,
  Dashboard,
  FavoriteProducts,
  MyProductShow,
  MyProductsAll,
  NotificationShow,
  NotificationsAll,
  OrderCreate,
  OrderShow,
  OrdersAll,
  ProductCreate,
  ProductEdit,
  ProductImagesAll,
  ProductShow,
  ProductsAll,
  ProductsAllByCategory,
  ProductsSearchResultAll,
  RefreshStripeAccount,
  ReturnStripeAccount,
  ReviewShow,
  ReviewsAll,
  Settings,
  SettingsPaymentMethodEdit,
  Statistics,
  StatisticsPopularProductsAll,
  StripeCheckoutError,
  StripeCheckoutSuccess,
  TicketCreate,
  TicketEdit,
  TicketsAll,
  UserProfile
} from 'pages/App'

import { APP_PATHS } from '__constants__'
import { Navigate } from 'react-router-dom'
import SETTINGS_ROUTES from './settingsRoutes'

const { DASHBOARD, SETTINGS, STATISTICS } = APP_PATHS
const {
  MY_PRODUCTS_ALL,
  PRODUCT_DRAFTS_ALL,
  PRODUCT_EDIT,
  PRODUCT_SHOW,
  PRODUCT_IMAGES_ALL,
  PRODUCT_CREATE,
  PRODUCTS_ALL,
  PRODUCTS_ALL_BY_CATEGORY,
  PRODUCTS_ALL_SEARCH_RESULT,
  CATEGORIES_ALL,
  ORDERS_ALL,
  ORDER_SHOW,
  ORDER_CREATE,
  REVIEWS_ALL,
  REVIEW_SHOW,
  NOTIFICATIONS_ALL,
  NOTIFICATION_SHOW,
  PAYMENT_METHOD_EDIT,
  CUSTOMERS_ALL,
  CUSTOMER_SHOW,
  CHECKOUT,
  CHECKOUT_SUCCESS,
  TICKETS_ALL,
  TICKET_CREATE,
  TICKET_EDIT,
  COMPLAINTS_ALL,
  COMPLAINT_SHOW,
  FAVORITE_PRODUCTS,
  CART,
  CHATS,
  POPULAR_PRODUCTS_ALL,
  MY_PRODUCT_SHOW,
  PRODUCT_BLOCKED_ALL,
  USER_PROFILE,
  REFRESH_STRIPE_ACCOUNT,
  RETURN_STRIPE_ACCOUNT,
  STRIPE_CHECKOUT_SUCCESS,
  STRIPE_CHECKOUT_ERROR
} = APP_PATHS

export const APP_ROUTES = [
  { key: DASHBOARD, path: DASHBOARD, element: <Dashboard /> },
  { key: STATISTICS, path: STATISTICS, element: <Statistics /> },
  {
    key: POPULAR_PRODUCTS_ALL,
    path: POPULAR_PRODUCTS_ALL,
    element: <StatisticsPopularProductsAll />
  },

  { key: MY_PRODUCTS_ALL, path: MY_PRODUCTS_ALL, element: <MyProductsAll /> },
  {
    key: MY_PRODUCT_SHOW,
    path: MY_PRODUCT_SHOW,
    element: <MyProductShow isRentee={false} />
  },
  {
    key: PRODUCTS_ALL_SEARCH_RESULT,
    path: PRODUCTS_ALL_SEARCH_RESULT,
    element: <ProductsSearchResultAll />
  },
  {
    key: PRODUCT_DRAFTS_ALL,
    path: PRODUCT_DRAFTS_ALL,
    element: <MyProductsAll isDraftsPage />
  },
  {
    key: PRODUCT_BLOCKED_ALL,
    path: PRODUCT_BLOCKED_ALL,
    element: <MyProductsAll isBlockedPage />
  },
  { key: PRODUCT_EDIT, path: PRODUCT_EDIT, element: <ProductEdit /> },
  { key: PRODUCT_SHOW, path: PRODUCT_SHOW, element: <ProductShow /> },
  {
    key: PRODUCT_IMAGES_ALL,
    path: PRODUCT_IMAGES_ALL,
    element: <ProductImagesAll />
  },
  { key: PRODUCT_CREATE, path: PRODUCT_CREATE, element: <ProductCreate /> },
  {
    key: PRODUCTS_ALL_BY_CATEGORY,
    path: PRODUCTS_ALL_BY_CATEGORY,
    element: <ProductsAllByCategory />
  },
  {
    key: PRODUCTS_ALL,
    path: PRODUCTS_ALL,
    element: <ProductsAll />
  },
  { key: CATEGORIES_ALL, path: CATEGORIES_ALL, element: <CategoriesAll /> },
  { key: ORDERS_ALL, path: ORDERS_ALL, element: <OrdersAll /> },
  { key: ORDER_SHOW, path: ORDER_SHOW, element: <OrderShow /> },
  { key: ORDER_CREATE, path: ORDER_CREATE, element: <OrderCreate /> },
  { key: REVIEWS_ALL, path: REVIEWS_ALL, element: <ReviewsAll /> },
  { key: REVIEW_SHOW, path: REVIEW_SHOW, element: <ReviewShow /> },
  {
    key: NOTIFICATIONS_ALL,
    path: NOTIFICATIONS_ALL,
    element: <NotificationsAll />
  },
  {
    key: NOTIFICATION_SHOW,
    path: NOTIFICATION_SHOW,
    element: <NotificationShow />
  },

  {
    key: PAYMENT_METHOD_EDIT,
    path: PAYMENT_METHOD_EDIT,
    element: <SettingsPaymentMethodEdit />
  },
  { key: TICKETS_ALL, path: TICKETS_ALL, element: <TicketsAll /> },
  { key: TICKET_CREATE, path: TICKET_CREATE, element: <TicketCreate /> },
  { key: TICKET_EDIT, path: TICKET_EDIT, element: <TicketEdit /> },
  { key: CUSTOMERS_ALL, path: CUSTOMERS_ALL, element: <CustomersAll /> },
  { key: CUSTOMER_SHOW, path: CUSTOMER_SHOW, element: <CustomerShow /> },
  { key: COMPLAINTS_ALL, path: COMPLAINTS_ALL, element: <ComplaintsAll /> },
  { key: COMPLAINT_SHOW, path: COMPLAINT_SHOW, element: <ComplaintShow /> },
  { key: CHECKOUT, path: CHECKOUT, element: <Checkout /> },
  {
    key: CHECKOUT_SUCCESS,
    path: CHECKOUT_SUCCESS,
    element: <CheckoutSuccess />
  },
  {
    key: FAVORITE_PRODUCTS,
    path: FAVORITE_PRODUCTS,
    element: <FavoriteProducts />
  },
  {
    key: REFRESH_STRIPE_ACCOUNT,
    path: REFRESH_STRIPE_ACCOUNT,
    element: <RefreshStripeAccount />
  },
  {
    key: RETURN_STRIPE_ACCOUNT,
    path: RETURN_STRIPE_ACCOUNT,
    element: <ReturnStripeAccount />
  },
  {
    key: STRIPE_CHECKOUT_SUCCESS,
    path: STRIPE_CHECKOUT_SUCCESS,
    element: <StripeCheckoutSuccess />
  },
  {
    key: STRIPE_CHECKOUT_ERROR,
    path: STRIPE_CHECKOUT_ERROR,
    element: <StripeCheckoutError />
  },
  { key: CART, path: CART, element: <Cart /> },
  { key: USER_PROFILE, path: USER_PROFILE, element: <UserProfile /> },
  { key: CHATS, path: CHATS, element: <Chat /> },
  { key: '404', path: '*', element: <Navigate to="service/404" /> },
  { key: '/', path: '/', element: <Navigate to={DASHBOARD} /> },
  // Other routes
  {
    key: SETTINGS,
    path: SETTINGS,
    element: <Settings />,
    nested: [...SETTINGS_ROUTES]
  },
  ...SETTINGS_ROUTES
]

export default APP_ROUTES
