import PropTypes from 'prop-types'
import { Select } from 'antd'
import { useGetCategories } from 'domains/Category/hooks'
import { useMemo } from 'react'
import { useTranslations } from 'hooks'

// Takes a value of type object and onChange function
// Returns selected option object
const CategorySelect = (props) => {
  const {
    value,
    onChange,
    allowClear = true,
    exclude = [],
    mode,
    query,
    onAfterChange,
    placeholder,
    disabled,
    ...rest
  } = props
  /* Getting all possibility variants for select */
  const [allCategories = [], loading] = useGetCategories(
    query ?? { orderBy: 'name' }
  )
  /* Getting translation instance */
  const { t, language } = useTranslations()

  /* Checking if mode is multiple */
  const isMultiple = useMemo(() => mode === 'multiple', [mode])
  /* Handling case when db record not added yet but value already exists and ready to be used */
  const valueComputed = useMemo(() => {
    if (isMultiple) return value
    return value || null
  }, [value, isMultiple])
  /* Filtering out excluded values */
  const optionsComputed = useMemo(() => {
    const _options = allCategories
    return _options.filter((o) => !exclude.includes(o._id))
  }, [allCategories, exclude])

  /* Handling change selected option */
  const handleChange = (value) => {
    if (isMultiple) {
      const _options = optionsComputed.filter((o) => value.includes(o._id))
      onChange?.(_options || null, value)
      onAfterChange?.()
    } else {
      const _option = optionsComputed.find(({ _id: id }) => id === value)
      onChange?.(_option?._id || null, value)
      onAfterChange?.()
    }
  }

  const onFilterOption = (input, option) =>
    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
    option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0

  return (
    <div {...rest} area-label="category-select-box">
      <Select
        disabled={disabled}
        showSearch={true}
        filterOption={onFilterOption}
        mode={mode}
        allowClear={allowClear}
        onClear={() => {
          handleChange(null)
          onAfterChange?.()
        }}
        loading={loading}
        placeholder={placeholder || t('Please, select category')}
        value={valueComputed}
        onChange={handleChange}>
        {optionsComputed.map((category) => (
          <Select.Option key={category?._id} value={category?._id}>
            {category?.names?.[language.toUpperCase()] || category?.name}
          </Select.Option>
        ))}
      </Select>
    </div>
  )
}

CategorySelect.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  showTitle: PropTypes.bool,
  allowClear: PropTypes.bool,
  disabled: PropTypes.bool,
  exclude: PropTypes.array,
  mode: PropTypes.string,
  placeholder: PropTypes.string,
  query: PropTypes.object,
  onAfterChange: PropTypes.func
}

export default CategorySelect
