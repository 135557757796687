const TICKET_STATUSES = {
  PENDING: 'PENDING',
  ON_REVIEW: 'ON_REVIEW',
  CLOSED: 'CLOSED'
}

const TICKET_STATUSES_LABELS = {
  PENDING: 'Pending',
  ON_REVIEW: 'On review',
  CLOSED: 'Closed'
}

const TICKET_STATUSES_OPTIONS = Object.keys(TICKET_STATUSES_LABELS).map(
  (key) => ({ label: TICKET_STATUSES_LABELS[key], value: key })
)

export default TICKET_STATUSES
export { TICKET_STATUSES_OPTIONS, TICKET_STATUSES, TICKET_STATUSES_LABELS }
