import { Breadcrumbs, PageLayout } from 'components'
import { Chat, ChatsList } from 'modules/chat-module/domains/Chat/components'
import { Image, Typography, theme } from 'antd'
import { StyledChatCol, StyledCol, StyledRow } from './Chat.styled'
import { useScreen, useTranslations } from 'hooks'

import { ChatProvider } from 'modules/chat-module/contexts'
import { LoadingBox } from 'components'
import NoMessages from '__assets__/illustrations/ill-no-messages.svg'
import { PATHS } from '__constants__'
import { useListenUserChats } from 'modules/chat-module/domains/Chat/hooks'
import { useState } from 'react'
import { useUser } from 'modules/session-module/contexts'

const ChatPage = () => {
  const { userId, isRentee } = useUser()
  const [oppositeUserId, setOppositeUserId] = useState()
  const [chats, chatsLoading] = useListenUserChats(userId)
  const { colorBorderSecondary, borderRadius } = theme.useToken().token
  const { t } = useTranslations()
  const { lg, xs } = useScreen()

  const headingProps = {
    title: t('My chats'),
    textAlign: 'left'
  }

  return (
    <PageLayout
      breadcrumbs={<Breadcrumbs collection={PATHS.AUTHENTICATED.CHATS} />}
      headingProps={headingProps}>
      <StyledRow isRentee={isRentee} xs={xs} className="gap-24">
        <LoadingBox loading={chatsLoading}>
          <>
            {chats?.length ? (
              <>
                <StyledCol
                  colorBorderSecondary={colorBorderSecondary}
                  borderRadius={borderRadius}
                  xs={24}
                  sm={24}
                  md={24}
                  lg={8}
                  xl={6}>
                  <ChatsList
                    chats={chats}
                    chatsLoading={chatsLoading}
                    userId={userId}
                    selected={oppositeUserId}
                    onSelect={setOppositeUserId}
                  />
                </StyledCol>

                {oppositeUserId ? (
                  <StyledChatCol
                    style={{
                      inset: 0,
                      zIndex: 1000,
                      position: !lg ? 'absolute' : ''
                    }}
                    flex="auto"
                    borderRadius={borderRadius}
                    colorBorderSecondary={colorBorderSecondary}>
                    <ChatProvider oppositeUserId={oppositeUserId}>
                      <Chat
                        setOppositeUserId={setOppositeUserId}
                        height="100%"
                      />
                    </ChatProvider>
                  </StyledChatCol>
                ) : (
                  <>
                    {lg && (
                      <StyledChatCol
                        flex="auto"
                        borderRadius={borderRadius}
                        colorBorderSecondary={colorBorderSecondary}>
                        <div className="flex full-height full-width align-center justify-center">
                          <Typography.Text>
                            {t('Select a chat to start messaging')}
                          </Typography.Text>
                        </div>
                      </StyledChatCol>
                    )}
                  </>
                )}
              </>
            ) : (
              <div className="flex full-height full-width align-center justify-center">
                <StyledChatCol
                  flex="auto"
                  borderRadius={borderRadius}
                  colorBorderSecondary={colorBorderSecondary}>
                  <div className="flex full-height full-width align-center justify-center flex-col text-align-center gap-16">
                    <Image height={300} src={NoMessages} preview={false} />
                    <Typography.Title level={4}>
                      {t('You do not have active chats')}
                    </Typography.Title>
                  </div>
                </StyledChatCol>
              </div>
            )}
          </>
        </LoadingBox>
      </StyledRow>
    </PageLayout>
  )
}

export default ChatPage
