import { generatePath, useNavigate } from 'react-router-dom'
import { useEvent, useTranslations } from 'hooks'

import { COLLECTIONS } from '__constants__'
import { SETTINGS_PATHS } from '__constants__/paths'
import { deleteDocument } from 'services/api/firebase'
import { message } from 'antd'
import { updateParentHasManyRelationship } from 'services/api/firebase'
import { useMemo } from 'react'
import { useUser } from 'modules/session-module/contexts'

/* Path's */
const createPath = SETTINGS_PATHS.PAYMENT_METHOD_CREATE
const editPath = SETTINGS_PATHS.PAYMENT_METHOD_EDIT

const usePaymentMethodBaseActions = ({ paymentMethodId, actions } = {}) => {
  const { user } = useUser()
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting navigate instance */
  const navigate = useNavigate()

  /* Function to handle back click */
  const handleBack = useEvent(() => navigate(-1))

  /* Handling opening of the create page */
  const handleCreate = useEvent(() => {
    navigate(createPath)
  })

  /* Handling opening of the show page */
  // Temporary useless
  // const handleOpen = useEvent(() => {
  //   const pathParams = { paymentMethodId }
  //   const path = generatePath(showPath, pathParams)
  //   navigate(path)
  // })

  /* Handling opening of the edit page or change view to edit mode */
  const handleEdit = useEvent(() => {
    const pathParams = { paymentMethodId }
    const path = generatePath(editPath, pathParams)
    navigate(path)
  })

  const handleDelete = useEvent(async () => {
    try {
      await deleteDocument(COLLECTIONS.PAYMENT_METHODS, paymentMethodId)
      user?._id &&
        (await updateParentHasManyRelationship(
          user?._id,
          COLLECTIONS.USERS,
          'paymentMethodIds',
          paymentMethodId,
          true
        ))
      message.success(t('PaymentMethod successfully deleted'))
    } catch (error) {
      message.error(error.message)
    }
  })

  return useMemo(
    () => ({
      onBack: handleBack,
      onCreate: handleCreate,
      onEdit: handleEdit,
      onDelete: handleDelete,
      ...(actions || {}),
      createPath,
      editPath
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      actions?.onBack,
      actions?.onCreate,
      actions?.onDelete,
      actions?.onEdit,
      actions?.onOpen,
      handleBack,
      handleCreate,
      handleDelete,
      handleEdit
    ]
  )
}

export default usePaymentMethodBaseActions
