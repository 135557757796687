import { COLLECTIONS } from '__constants__'
import { doc } from 'firebase/firestore'
import { firestore } from 'services/firebase'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { useGetAddress } from 'domains/Address/hooks'
import { useLoading } from 'hooks'
import { useMemo } from 'react'
import { useCart } from 'contexts'

const useGetCheckoutInitialValues = (checkoutId) => {
  const { cartItems } = useCart()

  const checkoutItem = useMemo(
    () =>
      checkoutId && cartItems?.find((item) => item?.checkoutId === checkoutId),
    [cartItems, checkoutId]
  )
  const productId = checkoutItem?.productId

  const [product, productLoading] = useDocumentData(
    productId && doc(firestore, COLLECTIONS.PRODUCTS, productId)
  )

  const [address, addressLoading] = useGetAddress(product?.addressId)

  /* Merge all fetched data */
  const productInitialValues = useMemo(
    () => ({
      product,
      address
    }),
    [product, address]
  )
  /* Merge all loading states */
  const loadings = useMemo(
    () => [!product, productLoading, !address, addressLoading],
    [productLoading, addressLoading, address, product]
  )

  /* Getting loading state using merged loadings */
  const loading = useLoading(loadings)

  return [checkoutItem, productInitialValues, loading]
}

export default useGetCheckoutInitialValues
