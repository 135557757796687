import {
  getId,
  updateParent,
  deleteDocument,
  getDocument
} from 'services/api/firebase'
import { useLocation, useNavigate } from 'react-router-dom'
import { useMutate, useTranslations } from 'hooks'

import { COLLECTIONS } from '__constants__'
import { getAuth } from 'firebase/auth'
import { message } from 'antd'
import { uploadImage } from 'helpers'
import { useAppNotifications } from 'contexts'
import { useRemoveUserProvider } from 'hooks'
import { useState } from 'react'
import moment from 'moment'
import { getDobFromDateOfBirth } from 'modules/stripe-module/helpers'

const useActionsUserAdvancedForm = ({
  initialData,
  form,
  setInitialFieldsValue,
  withNavigate
} = {}) => {
  /* State for loading */
  const [loading, setLoading] = useState(false)
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting location state */
  const navigationState = useLocation().state
  /* Getting notifications context */
  const { sendSuccessNotification } = useAppNotifications()
  /* Getting mutate function */
  const { create, update } = useMutate()

  const removeUserProvider = useRemoveUserProvider()

  const navigate = useNavigate()

  const navigateCallback = () => (withNavigate ? navigate(-1) : null)
  /* Function for preparing values for saving */
  const prepareValues = async (values = {}, additionalValues = {}) => {
    /* Getting id */
    const userId = initialData?.user?._id || getId(COLLECTIONS.USERS)

    const locationData = values?.user?.locationData
      ? values?.user?.locationData
      : null
    const document = initialData?.user?.addressId
      ? await getDocument(COLLECTIONS.ADDRESSES, initialData?.user?.addressId)
      : null

    const isAddressDontChange = document?.placeId === locationData?.placeId

    const initialAddressId = document?._id || null
    const newAddressId = locationData ? getId(COLLECTIONS.ADDRESSES) : null

    const addressId = isAddressDontChange ? initialAddressId : newAddressId

    if (!isAddressDontChange && locationData) {
      const preparedAddressValues = {
        _id: addressId,
        placeId: locationData?.placeId ?? null,
        userId: initialData?.user?._id ?? null,
        name: values?.user?.name ?? null,
        latitude: locationData?.latitude ?? null,
        longitude: locationData?.longitude ?? null,
        country: locationData?.country ?? null,
        city: locationData?.city ?? null,
        streetName: locationData?.streetName ?? null,
        streetNumber: locationData?.streetNumber ?? null,
        zipCode: locationData?.zipCode ?? null,
        postalCode: locationData?.postalCode ?? null,
        postalTown: locationData?.postalTown ?? null,
        district: locationData?.district ?? null,
        region: locationData?.region ?? null,
        icon: values?.icon ?? null,
        ...additionalValues
      }

      await create(COLLECTIONS.ADDRESSES, preparedAddressValues, addressId)
    }

    /* Preparing user values */
    const preparedValues = {
      _id: userId,
      ...additionalValues,
      avatarUrl:
        (await uploadImage(
          values?.user?.avatarUrl,
          'user-avatars/' + userId
        )) ?? null,
      firstName: values?.user?.firstName ?? null,
      lastName: values?.user?.lastName ?? null,
      birthday: values?.user?.birthday?.toDate?.() ?? null,
      birthdayByNumbers: getDobFromDateOfBirth(values?.user?.birthday),
      email: values?.user?.email ?? null,
      phone: values?.user?.phone ?? null,
      addressId: addressId
    }

    return preparedValues
  }
  /* Saving form data */
  const saveForm = async (values, callback) => {
    try {
      // Prepare data to be saved
      const data = await prepareValues(values)

      if (
        initialData?.user?.addressId &&
        initialData?.user?.addressId !== data?.addressId
      ) {
        await deleteDocument(
          COLLECTIONS.ADDRESSES,
          initialData?.user?.addressId
        )
      }
      if (navigationState?.parent)
        await updateParent(navigationState.parent, data._id)
      if (initialData) {
        await update(COLLECTIONS.USERS, initialData.user?._id, data)
      } else {
        await create(COLLECTIONS.USERS, data, data._id)
      }
      if (initialData?.user?.phone !== values?.user?.phone) {
        const auth = getAuth()
        const authPhoneNumber = auth?.currentUser?.phoneNumber
        const transformedPhoneNumber = `+${initialData?.user?.phone}`
        if (authPhoneNumber === transformedPhoneNumber && authPhoneNumber)
          return removeUserProvider()
      }
      const computeMessageType = initialData ? 'updated' : 'created'
      sendSuccessNotification({
        message: t('Basic'),
        description: t(`Basic settings successfully ${computeMessageType}`)
      })
      // Final callback
      callback?.()
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      throw new Error(t('Something went wrong during data save'))
    }
  }

  /* On finish callback */
  const onFinish = async () => {
    if (loading) return // Avoid multiple calls

    try {
      setLoading(true)
      // Get form values
      const formValues = form?.getFieldsValue?.()

      const birthday = formValues?.user?.birthday?.toISOString()

      const isValidBirthday = moment(birthday).isBefore(
        moment().subtract(13, 'years'),
        'date'
      )

      if (!isValidBirthday)
        throw new Error(t('The account owner must be at least 13 years old'))

      await saveForm(formValues, navigateCallback)
      // Final callback
    } catch (error) {
      setLoading(false)
      message.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  /* On cancel callback */
  const onReset = () => {
    setInitialFieldsValue?.()
    navigateCallback?.()
  }

  return { onFinish, onReset, loading, saveForm, prepareValues }
}

export default useActionsUserAdvancedForm
