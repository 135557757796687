import { Breadcrumbs, SettingsLayout } from 'components'
import { Col, Row } from 'antd'
import { useScreen, useTranslations } from 'hooks'

import { AddressAdvancedForm } from 'domains/Address/components'
import PropTypes from 'prop-types'
import { Spinner } from 'components'
import { useUser } from 'modules/session-module/contexts'

const SettingsAddressCreatePage = (props) => {
  const { layoutProps } = props

  const { xl } = useScreen()

  const { user, loading: userLoading } = useUser()

  const { t } = useTranslations()

  const headingProps = {
    title: t('Shipping address create')
  }

  return (
    <SettingsLayout
      breadcrumbs={<Breadcrumbs />}
      headingProps={headingProps}
      area="addressCreate">
      <Row className="mb-16">
        {userLoading ? (
          <Col span={24}>
            <Spinner height="50vh" />
          </Col>
        ) : (
          <Col {...layoutProps}>
            <AddressAdvancedForm initialData={{ user }} isBlockButtons={!xl} />
          </Col>
        )}
      </Row>
    </SettingsLayout>
  )
}

SettingsAddressCreatePage.propTypes = {
  layoutProps: PropTypes.object
}

export default SettingsAddressCreatePage
