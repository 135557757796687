import { Badge, Checkbox, Typography, theme } from 'antd'

import { SelectableCard } from 'components/forms'
import styled from 'styled-components'
import { useState } from 'react'

// TITLE for listView
const TitleStyled = styled(Typography.Title)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`
// SELECTABLE CARD
const SelectableCardStyled = styled(SelectableCard)`
  .ant-card-body {
    padding: 2px !important;
  }
  position: relative;
  transition: box-shadow 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);

  box-shadow: ${({ checked }) => checked && '0 0px 0px 2px #1c74e0'} !important;

  & .ant-checkbox-wrapper {
    opacity: 0.5;
    transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  &:hover .ant-checkbox-wrapper {
    opacity: 1;
  }
`

// BRAND TEXT
export const brandStyles = {
  position: 'absolute',
  bottom: 8,
  left: 8,
  color: '#fff',
  zIndex: 100
}

export const imageStyles = {
  height: '180px',
  width: '100%',
  style: { objectFit: 'cover' }
}

export const likeFormStyles = {
  position: 'absolute',
  top: 12,
  right: 12
}

const CheckboxStyled = styled(Checkbox)`
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 100;
  width: 28px;
  height: 28px;
  border-radius: 100%;
  overflow: hidden;
  border: ${({ checked }) => `2px solid ${checked ? '#fff' : '#1c74e0'}`};
  opacity: ${({ checked }) => checked && 1} !important;

  .ant-checkbox {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
  .ant-checkbox-inner {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
  .ant-checkbox-inner:after {
    width: 6px;
    height: 10px;
    left: 7px;
  }
`
const RibbonStyled = styled(Badge.Ribbon)`
  display: ${({ text }) => !text && 'none'};
  font-size: 12px;
  line-height: 20px;
`

// IMAGE OVERLAY
const ImageOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: transparent;
  background-image: ${({ bg }) => `linear-gradient(
    transparent 60%,
    ${bg}
  )`};
`
const OverlayWithStyled = (props) => {
  const { colorWrapperBg } = theme.useToken().token

  return <ImageOverlay {...props} bg={colorWrapperBg} />
}

export const HoverableCard = (props) => {
  // eslint-disable-next-line react/prop-types
  const { children, hoveredChildren, hoveredVisible, ...rest } = props

  const [isHovered, setIsHovered] = useState(false)

  const handleMouseEnter = () => setIsHovered(true)
  const handleMouseLeave = () => setIsHovered(false)

  return (
    <SelectableCardStyled
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      {...rest}>
      {isHovered || hoveredVisible ? hoveredChildren : null}
      {children}
    </SelectableCardStyled>
  )
}
export const imageRow = {
  height: '176px'
}

export {
  OverlayWithStyled as ImageOverlay,
  CheckboxStyled,
  SelectableCardStyled,
  RibbonStyled,
  TitleStyled
}
