/* eslint-disable no-console */
import { ENDPOINTS } from '__constants__/environment'
import { fetchApi } from 'services/api/rest'

const updateUserViewsStatistics = async (product) => {
  try {
    const fetchObj = {
      path: ENDPOINTS.UPDATE_USER_VIEWS_STATISTICS,
      method: 'POST',
      body: {
        productId: product?._id,
        renterId: product?._createdBy
      }
    }

    const [response, responseData] = await fetchApi(fetchObj)

    if (!response?.ok) {
      const { description } = responseData
      console.error('ERROR on user-views statistic update', description)
    }
  } catch (error) {
    console.error('ERROR on user-views statistic update', error)
  }
}

export default updateUserViewsStatistics
