import { Row, Col, Typography, theme, Tooltip, Button } from 'antd'
import PropTypes from 'prop-types'
import { Box } from 'components'
import { useTranslations } from 'hooks'
import { Icon } from '@qonsoll/icons'

const { Text } = Typography

const AccountBalancesSimpleView = (props) => {
  const { balance } = props

  const { t } = useTranslations()

  const { colorInfoText } = theme.useToken().token

  const information = [
    {
      title: t('Available'),
      value: (
        <Row className="flex align-center" gutter={[4, 0]}>
          <Col>
            <Text>{`${balance?.available?.[0]?.amount / 100} ${
              balance?.available?.[0]?.currency
            }`}</Text>
          </Col>
          <Col>
            <Tooltip
              title={
                <Box>
                  {`${t(
                    "You can't get a payout into your bank account if the available amount is less than the minimum allowed"
                  )}. ${t(
                    'For Norway, the minimum amount is 3kr, for other countries, see the'
                  )}`}
                  <Button
                    size="small"
                    target="_blank"
                    href="https://stripe.com/docs/payouts#:~:text=The%20minimum%20payout%20amount%20depends,you%20can%20receive%20a%20payout."
                    type="link">
                    {t('link')}
                  </Button>
                </Box>
              }
              placement="top">
              <Icon
                name="AlertInfoFilled"
                fill={colorInfoText}
                className="cursor-pointer"
              />
            </Tooltip>
          </Col>
        </Row>
      )
    },
    {
      title: t('Pending'),
      value: `${balance?.pending?.[0]?.amount / 100} ${
        balance?.pending?.[0]?.currency
      }`
    }
  ]

  return (
    <Box className="flex flex-col gap-4">
      {information?.map(({ title, value }) => (
        <Row key={title} justify="space-between">
          <Col span={10}>
            <Text type="secondary">{title}:</Text>
          </Col>
          <Col span={12}>
            <Text>{value}</Text>
          </Col>
        </Row>
      ))}
    </Box>
  )
}

AccountBalancesSimpleView.propTypes = {
  balance: PropTypes.object
}

export default AccountBalancesSimpleView
