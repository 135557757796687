/* eslint-disable react/prop-types */
import { Grid, Layout, theme } from 'antd'

import styled from 'styled-components'
import { useScreen } from 'hooks'

const MenuMask = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${({ colorBgMask }) => colorBgMask};
  z-index: 2;
`

export const StyledMask = (props) => {
  const { collapsed, ...rest } = props

  const screens = Grid.useBreakpoint()
  const { colorBgMask } = theme.useToken().token

  const smallScreen = !screens.sm || !screens.md || !screens.lg
  const smallScreenOpenMenu = smallScreen && !collapsed

  if (!smallScreenOpenMenu) return null

  return <MenuMask colorBgMask={colorBgMask} {...rest} />
}

const InnerContent = styled.div`
  background: linear-gradient(180deg, #ffffff 0%, #f5f5f5 100%);
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;
  padding: ${({ isExtraSmallScreen, isSmallScreen }) =>
    isExtraSmallScreen ? '20px' : isSmallScreen ? '32px 24px' : '32px 48px'};
`

export const StyledContent = (props) => {
  const { children, header, showSimpleHeader, ...rest } = props

  const token = theme.useToken().token
  const { isSmallScreen, isExtraSmallScreen } = useScreen()

  return (
    <Layout.Content {...rest}>
      <InnerContent
        isSmallScreen={isSmallScreen}
        isExtraSmallScreen={isExtraSmallScreen}
        theme={token}>
        {children}
      </InnerContent>
    </Layout.Content>
  )
}
