import { Button } from 'antd'
import styled from 'styled-components'

export const StyledNumber = styled.span`
  color: #848b95;
  margin-left: 4px;
`
export const StyledButton = styled(Button)`
  &:hover {
    & span {
      color: #4596ed;
    }
  }
  &:active {
    & span {
      color: #0f56ba;
    }
  }
`
