import { Breadcrumbs, PageActions, PageLayout, Spinner } from 'components'
import { COLLECTIONS, PATHS } from '__constants__'
import { generatePath, useNavigate, useParams } from 'react-router-dom'

import { NotificationAdvancedView } from 'domains/Notification/components'
import { useListenDocument } from 'services/api/firebase'
import { useTranslations } from 'hooks'

const NotificationShowPage = (props) => {
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting navigate instance */
  const navigate = useNavigate()
  /* Getting params from router */
  const params = useParams()
  /* Fetching document data from useDocument hook */
  const [notification, loading] = useListenDocument({
    ref: COLLECTIONS.NOTIFICATIONS + '/' + params.notificationId
  })

  /* Configuring page header */
  const headingProps = {
    title: notification?.title || t('Notification show'),
    textAlign: 'left'
  }

  /* Getting id from params */
  const { notificationId } = params

  /* Configuring page actions */
  const actions = { edit: !!PATHS.AUTHENTICATED.NOTIFICATION_EDIT }

  /* Function to handle back click */
  const handleBack = () => navigate(-1)
  /* Handling opening of the edit page */
  const handleEdit = () => {
    const pathParams = { notificationId }
    const path = generatePath(PATHS.AUTHENTICATED.NOTIFICATION_EDIT, pathParams)
    navigate(path)
  }

  return (
    <PageLayout
      actions={<PageActions actions={actions} onEdit={handleEdit} />}
      onBack={handleBack}
      breadcrumbs={<Breadcrumbs />}
      headingProps={headingProps}
      area="notification">
      {loading ? (
        <Spinner height="70vh" text={t('Notification loading')} />
      ) : (
        <>
          <NotificationAdvancedView notification={notification} />
        </>
      )}
    </PageLayout>
  )
}

export default NotificationShowPage
