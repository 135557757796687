import PropTypes from 'prop-types'
import { Typography } from 'antd'
import { useGetProductsNumber } from 'domains/Category/hooks'
import { StyledCollapse } from 'components/views/Filter/Filter.styled'
import { useTranslations } from 'hooks'
import { CollapseSubcategoryView } from 'domains/Category/components/CategoryFilter/components'
const { Text } = Typography

const CollapseCategoryView = ({
  topCategory,
  onChange,
  checkIsEnabled,
  categories
}) => {
  const { t, language } = useTranslations()
  const productsNumber = useGetProductsNumber(topCategory?._id)

  const computedNumber = (
    <Text>
      {topCategory?.names?.[language.toUpperCase()] || topCategory?.name}
      <span style={{ color: '#848b95' }}>{` (${productsNumber})`}</span>
    </Text>
  )

  return (
    <StyledCollapse ghost className="mb-4 mx-12" key={topCategory?._id}>
      <StyledCollapse.Panel header={computedNumber} key={topCategory?._id}>
        {topCategory?.subcategories?.length ? (
          categories?.map((subcategory) => (
            <CollapseSubcategoryView
              key={`${subcategory?._id}-subcategory-collapse`}
              subcategory={subcategory}
              topCategory={topCategory}
              checkIsEnabled={checkIsEnabled}
              onChange={onChange}
            />
          ))
        ) : (
          <Text type="secondary">{t('No subcategories')}</Text>
        )}
      </StyledCollapse.Panel>
    </StyledCollapse>
  )
}

CollapseCategoryView.propTypes = {
  topCategory: PropTypes.object,
  onChange: PropTypes.func,
  checkIsEnabled: PropTypes.func,
  categories: PropTypes.array
}

export default CollapseCategoryView
