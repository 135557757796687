const ENDPOINTS = {
  CREATE_STRIPE_ACCOUNT: 'createStripeAccount',
  UPDATE_STRIPE_ACCOUNT: 'updateStripeAccount',
  CREATE_STRIPE_ACCOUNT_LINK: 'createStripeAccountLink',
  DELETE_STRIPE_ACCOUNT: 'deleteStripeAccount',
  CREATE_STRIPE_CHECKOUT_SESSION: 'createStripeCheckoutSession',
  CHECK_STRIPE_ACCOUNT_STATUS: 'checkStripeAccountStatus',
  GET_STRIPE_ACCOUNT_STATUS: 'getStripeAccountStatus',
  GET_STRIPE_COUNTRIES: 'getStripeCountries',
  GET_STRIPE_BALANCE: 'getStripeBalance'
}

export default ENDPOINTS
