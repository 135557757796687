import { Collapse } from 'antd'
import styled from 'styled-components'
const StyledCollapse = styled(Collapse)`
  .ant-collapse-header {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .ant-collapse-content-box {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 0 !important;
  }
`
export { StyledCollapse }
