import { Button, Col, Row, Typography, theme } from 'antd'
import {
  RentFiguresChart,
  StatisticList,
  StatisticProductList,
  UserViewsChart
} from 'domains/Statistic/components'

import { APP_PATHS } from '__constants__/paths'
import { Icon } from '@qonsoll/icons'
import { PageLayout } from 'components'
import { useGetUserDisplayName } from 'domains/User/hooks'
import { useNavigate } from 'react-router-dom'
import { useTranslations } from 'hooks'

const Dashboard = () => {
  const { t } = useTranslations()
  const { colorPrimaryText } = theme.useToken().token
  const displayName = useGetUserDisplayName()
  const navigate = useNavigate()

  const headingProps = {
    title: `${t('Welcome back')}, ${displayName}!`,
    subTitle: t(
      'We have collected all the latest updates on your account since your last visit'
    )
  }
  const navigateToPopularProductsPage = () =>
    navigate(APP_PATHS.POPULAR_PRODUCTS_ALL)

  return (
    <PageLayout headingProps={headingProps}>
      <Row className="mb-16">
        <Col span={24}>
          <StatisticList />
        </Col>
      </Row>
      <Row gutter={[16, 16]} className="mb-32">
        <Col xs={24} sm={24} md={12}>
          <RentFiguresChart />
        </Col>
        <Col xs={24} sm={24} md={12}>
          <UserViewsChart />
        </Col>
      </Row>
      <Row className="mb-16 align-center" wrap={false}>
        <Col flex="auto">
          <Typography.Title level={3}>
            {t('Popular rented products')}
          </Typography.Title>
        </Col>
        <Col>
          <Button
            onClick={navigateToPopularProductsPage}
            className="flex align-center px-0"
            type="link"
            icon={
              <Icon
                pl="4px"
                size={16}
                name="ChevronRightOutlined"
                style={{ order: 1 }}
                fill={colorPrimaryText}
              />
            }>
            {t('See all')}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <StatisticProductList />
        </Col>
      </Row>
    </PageLayout>
  )
}

export default Dashboard
