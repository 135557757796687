import { COLLECTIONS } from '__constants__'
import { doc } from 'firebase/firestore'
import { firestore } from 'services/firebase'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { useMemo } from 'react'

const useGetCategory = (categoryId) => {
  const querySnapshot = useMemo(
    () =>
      categoryId ? doc(firestore, COLLECTIONS.CATEGORIES, categoryId) : null,
    [categoryId]
  )

  return useDocumentData(querySnapshot)
}
export default useGetCategory
