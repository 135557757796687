import RenteeCategorySimpleView from './RenteeCategorySimpleView'
import RenterCategorySimpleView from './RenterCategorySimpleView'
import { useUser } from 'modules/session-module/contexts'

const CategorySimpleView = (props) => {
  const { isRentee } = useUser()

  return isRentee ? (
    <RenteeCategorySimpleView {...props} />
  ) : (
    <RenterCategorySimpleView {...props} />
  )
}

export default CategorySimpleView
