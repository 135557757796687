const FIELD_TYPES = {
  ONE_FROM_LIST: 'ONE_FROM_LIST',
  CHECKBOX: 'CHECKBOX',
  STRING: 'STRING'
  // RANGE: 'RANGE',
  // BELONGS_TO: 'BELONGS_TO'
}

const FIELD_TYPES_LABELS = {
  ONE_FROM_LIST: 'One from list',
  CHECKBOX: 'Checkbox',
  STRING: 'String'
  // RANGE: 'Range'
  // this const is currently not in use
  // BELONGS_TO: 'Category'
}

const FIELD_TYPES_OPTIONS = Object.keys(FIELD_TYPES_LABELS).map((key) => ({
  label: FIELD_TYPES_LABELS[key],
  value: key
}))

export default FIELD_TYPES
export { FIELD_TYPES_OPTIONS, FIELD_TYPES, FIELD_TYPES_LABELS }
