import { LikeForm } from 'components'
import PropTypes from 'prop-types'
import { useLikedProducts } from 'contexts'
import { useMemo } from 'react'
import { useProductLikesActions } from 'domains/Product/hooks'

const ProductLike = (props) => {
  const { productId } = props

  const { products } = useLikedProducts()
  // checks whether liked-products-arr contains current product
  // if so - then like value is true
  const isLikedProduct = useMemo(
    () => products?.some((item) => item?._id === productId),
    [productId, products]
  )

  const { onLikeUpdate } = useProductLikesActions()

  const onChange = (likeValue) => onLikeUpdate(productId, likeValue)

  return <LikeForm {...props} value={isLikedProduct} onChange={onChange} />
}

ProductLike.propTypes = {
  productId: PropTypes.string
}

export default ProductLike
