import {
  useGetProductMediaObjectsData,
  useGetProductTagsData
} from 'domains/Product/hooks'

import { COLLECTIONS } from '__constants__'
import { doc } from 'firebase/firestore'
import { firestore } from 'services/firebase'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { useGetAddress } from 'domains/Address/hooks'
import useGetAverageProductRating from 'domains/Rating/hooks/get/useGetAverageProductRating'
import { useGetBrand } from 'domains/Brand/hooks'
import { useGetCategory } from 'domains/Category/hooks'
import { useGetMediaObject } from 'domains/MediaObject/hooks'
import { useGetReviews } from 'domains/Review/hooks'
import { useGetUser } from 'domains/User/hooks'
import { useLoading } from 'hooks'
import { useMemo } from 'react'

const useGetProductInitialValues = (id) => {
  const docRef = id && doc(firestore, COLLECTIONS.PRODUCTS, id)

  const [product, productLoading] = useDocumentData(docRef)

  const [mediaObjects, mediaObjectsLoading] =
    useGetProductMediaObjectsData(product)
  const [tags, tagsLoading] = useGetProductTagsData(product)
  const [address, addressLoading] = useGetAddress(product?.addressId)
  const [brand, brandLoading] = useGetBrand(product?.brandId)
  const [user, userLoading] = useGetUser(product?._createdBy)
  const [rating, ratingLoading] = useGetAverageProductRating(id)
  const [reviews, loadingReviews] = useGetReviews(id)
  const previewImageId = product?.mediaObjects?.[0]
  const [previewImage, loadingPreviewImage] = useGetMediaObject(previewImageId)
  const [category, categoryLoading] = useGetCategory(product?.categoryId)

  /* Merge all fetched data */
  const initialValues = useMemo(
    () => ({
      product,
      mediaObjects: mediaObjects?.filter(Boolean),
      tags,
      address,
      brand,
      user,
      rating,
      reviews,
      previewImage,
      category
    }),
    [
      product,
      mediaObjects,
      tags,
      address,
      brand,
      user,
      rating,
      reviews,
      previewImage,
      category
    ]
  )

  /* Merge all loading states */
  const loadings = useMemo(
    () =>
      id
        ? [
            productLoading,
            mediaObjectsLoading,
            tagsLoading,
            addressLoading,
            brandLoading,
            userLoading,
            ratingLoading,
            loadingReviews,
            loadingPreviewImage,
            categoryLoading
          ]
        : [],
    [
      id,
      productLoading,
      mediaObjectsLoading,
      tagsLoading,
      addressLoading,
      brandLoading,
      userLoading,
      ratingLoading,
      loadingReviews,
      loadingPreviewImage,
      categoryLoading
    ]
  )

  /* Getting loading state using merged loadings */
  const loading = useLoading(loadings)

  return [initialValues, loading]
}

export default useGetProductInitialValues
