import { Col, Typography, theme } from 'antd'

import { Icon } from '@qonsoll/icons'
import {
  TextStyled,
  ParentCategoriesListItem
} from './ParentCategorySimpleView.styled'
import PropTypes from 'prop-types'
import { useTranslations } from 'hooks'
import { useGetProductsNumber } from 'domains/Category/hooks'

const { Text } = Typography

const ParentCategorySimpleView = (props) => {
  const { categories, category, index, handleSelectCategory } = props
  const token = theme.useToken().token
  const { language } = useTranslations()

  const isLastInRow = index % 2
  const isLastElement = index === categories?.length - 1
  const isPreviousElement = index === categories?.length - 2
  const isLastInCol = isLastInRow
    ? isLastElement
    : isLastElement || isPreviousElement

  const productsNumber = useGetProductsNumber(category?._id)

  return (
    <Col span={12} key={category?._id}>
      <TextStyled type="secondary">{productsNumber}</TextStyled>
      <ParentCategoriesListItem
        onClick={() => handleSelectCategory(category)}
        theme={token}
        isLastInRow={isLastInRow}
        isLastInCol={isLastInCol}>
        <Icon name={category?.icon} size={24} />
        <Text className="text-align-center">
          {category?.names?.[language.toUpperCase()] || category?.name}
        </Text>
      </ParentCategoriesListItem>
    </Col>
  )
}

ParentCategorySimpleView.propTypes = {
  categories: PropTypes.array,
  handleSelectCategory: PropTypes.func,
  category: PropTypes.func,
  index: PropTypes.func
}

export default ParentCategorySimpleView
