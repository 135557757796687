import PropTypes from 'prop-types'
import SegmentedStyled from './SegmentedSelect.styled'
import { Select } from 'antd'
import { useTranslations } from 'hooks'

const SegmentedSelect = (props) => {
  const {
    value,
    onChange,
    options,
    placeholder,
    wrapperStyles,
    fullWidth,
    defaultValue
  } = props
  /* Getting translations instance */
  const { t } = useTranslations()

  if (options?.length < 4) {
    return (
      <SegmentedStyled
        defaultValue={defaultValue}
        fullWidth={fullWidth}
        options={options?.map((o) => ({ ...o, label: t(o.label) }))}
        wrapperStyles={wrapperStyles}
        value={value}
        onChange={onChange}
        {...props}
      />
    )
  }
  return (
    <Select
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      options={options?.map((o) => ({ ...o, label: t(o.label) }))}
    />
  )
}
SegmentedSelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  wrapperStyles: PropTypes.object,
  fullWidth: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
export default SegmentedSelect
