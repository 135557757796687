import {
  APP_PATHS,
  CANCELLATION_TERMS_TYPES_DESCRIPTION,
  REFUND_TYPES_DESCRIPTION
} from '__constants__'
import { Divider, Grid, Typography } from 'antd'
import { DottedBlock, Image } from 'components'
import {
  OrderActions,
  OrderComplaintBottomDrawer,
  OrderRentingDays,
  OrderReviewBottomDrawer,
  OrderStatus
} from 'domains/Order/components'
import {
  OrderPaymentDetails,
  OrderPickupDetails,
  OrderRentee,
  OrderRenter
} from 'domains/Order/components/OrderAdvancedView/components'
import { useCallback, useEffect, useMemo, useState } from 'react'

import PropTypes from 'prop-types'
import styles from './OrderAdvancedView.styled'
import { useGetUserDisplayName } from 'domains/User/hooks'
import { useNavigate } from 'react-router-dom'
import { useTranslations } from 'hooks'
import { useUser } from 'modules/session-module/contexts'

const { useBreakpoint } = Grid

const OrderAdvancedView = (props) => {
  const { order, withTitle = true, onReviewSideDrawerOpen } = props
  const [reviewDrawerVisible, setReviewDrawerVisible] = useState(false)
  const [complaintDrawerVisible, setComplaintDrawerVisible] = useState(false)

  const { t } = useTranslations()
  const navigate = useNavigate()
  const displayName = useGetUserDisplayName(order?.renter)
  const { md } = useBreakpoint()
  const { isRentee } = useUser()

  // used in mobile devices
  const onReviewBottomDrawerToggle = () =>
    setReviewDrawerVisible((prev) => !prev)
  const onComplaintBottomDrawerToggle = () =>
    setComplaintDrawerVisible((prev) => !prev)

  const navigateToOrdersAll = useCallback(
    () => navigate(APP_PATHS.ORDERS_ALL),
    [navigate]
  )
  useEffect(() => {
    md && navigateToOrdersAll()
  }, [md, navigate, navigateToOrdersAll])

  const computedImageUrl = useMemo(
    () => order?.previewImageUrl || order?.product?.previewImgUrl,
    [order?.previewImageUrl, order?.product]
  )
  return (
    <div className="flex flex-col">
      {/* title */}
      {withTitle && (
        <div className="mb-24">
          <div className="mb-4">
            <Typography.Text type="secondary">{`${t(
              'Order'
            )} №`}</Typography.Text>
            <Typography.Title level={4}>{order?.orderNumber}</Typography.Title>
          </div>
          {/* status */}
          <OrderStatus order={order} />
        </div>
      )}
      {/* product preview */}
      <div className="flex align-start">
        <Image
          isSquare={false}
          src={computedImageUrl}
          style={styles.imageProps}
        />
        <div className="flex flex-col">
          <Typography.Text strong>{order?.product?.name}</Typography.Text>
          <Typography.Text>{`${t('by')} ${displayName}`}</Typography.Text>
        </div>
      </div>
      <Divider />
      {/* Dates */}
      <DottedBlock
        className="mb-24"
        height="22px"
        left={<Typography.Text>{t('Dates')}</Typography.Text>}
        right={<OrderRentingDays order={order} inRow />}
      />
      {/* Pick-up details */}
      <OrderPickupDetails order={order} />

      {/* Rentee/renter */}
      {isRentee ? <OrderRenter order={order} /> : <OrderRentee order={order} />}

      {/* cancellationTerms */}
      <Typography.Title level={5} className="mt-24 mb-12">
        {t('Cancellation terms')}
      </Typography.Title>
      <Typography.Text>
        {t(
          CANCELLATION_TERMS_TYPES_DESCRIPTION[
            order?.product?.cancellationTerms
          ]
        )}
      </Typography.Text>
      {/* Refund details */}
      <Typography.Title level={5} className="mt-24 mb-12">
        {t('Refund info')}
      </Typography.Title>
      <Typography.Text>{t(REFUND_TYPES_DESCRIPTION.BASIC)}</Typography.Text>
      {/* payment details */}
      <OrderPaymentDetails order={order} />

      <Divider className="mt-24 mb-16" />

      {/* actions */}
      <div>
        <OrderActions
          order={order}
          fullWidth
          // depends on device
          onReviewFormToggle={
            onReviewSideDrawerOpen || onReviewBottomDrawerToggle
          }
          onComplaintFormToggle={onComplaintBottomDrawerToggle}
        />
      </div>

      {!md && (
        <>
          <OrderReviewBottomDrawer
            order={order}
            reviewDrawerVisible={reviewDrawerVisible}
            onReviewFormToggle={onReviewBottomDrawerToggle}
          />
          <OrderComplaintBottomDrawer
            orderId={order?._id}
            productId={order?.product?._id}
            complaintDrawerVisible={complaintDrawerVisible}
            onComplaintFormToggle={onComplaintBottomDrawerToggle}
          />
        </>
      )}
    </div>
  )
}

OrderAdvancedView.propTypes = {
  order: PropTypes.object,
  withTitle: PropTypes.bool,
  onReviewSideDrawerOpen: PropTypes.func
}

export default OrderAdvancedView
