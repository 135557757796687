import { Card, Col, Divider, Row } from 'antd'
import {
  CheckoutProductPreview,
  CheckoutProductPriceDetails
} from 'domains/Checkout/components/CheckoutAdvancedForm/components'

import { CheckoutAdvancedForm } from 'domains/Checkout/components'
import PropTypes from 'prop-types'
import { useCurrentScreen } from 'hooks'
import { useMemo } from 'react'
import { useProductPreviewFormActions } from 'domains/Product/components/ProductPreview/hooks'

const CheckoutAdvancedFormWithPreview = (props) => {
  const { form, onFinish, handleEnterPress, cartItem, initialValues } = props
  // handle product preview modal actions
  const { isisOpenModal, handleOpenModal } = useProductPreviewFormActions()
  const currScr = useCurrentScreen()

  const isSmallScreen = useMemo(
    () => currScr === 'xs' || currScr === 'sm',
    [currScr]
  )

  return (
    <Row gutter={24}>
      <Col xs={24} sm={24} md={14} lg={14} xl={16} className="mb-16">
        {/* for small screens */}
        {isSmallScreen ? (
          <div className="mb-24">
            <CheckoutProductPreview cart={cartItem} />
          </div>
        ) : null}

        <CheckoutAdvancedForm
          initialValues={initialValues}
          form={form}
          isOpenModal={isisOpenModal}
          handleOpenModal={handleOpenModal}
          onFinish={onFinish}
          handleEnterPress={handleEnterPress}>
          {/* children passed for small screens */}
          {isSmallScreen ? (
            <CheckoutProductPriceDetails
              cart={cartItem}
              isSmallScreen={isSmallScreen}
              withTitle={isSmallScreen && true}
              form={form}
            />
          ) : null}
        </CheckoutAdvancedForm>
      </Col>

      {/* for md and larger screens */}
      {!isSmallScreen ? (
        <Col xs={24} sm={24} md={10} lg={10} xl={8}>
          <Card>
            <CheckoutProductPreview cart={cartItem} />
            {!isSmallScreen && <Divider />}
            <CheckoutProductPriceDetails cart={cartItem} form={form} />
          </Card>
        </Col>
      ) : null}
    </Row>
  )
}

CheckoutAdvancedFormWithPreview.propTypes = {
  initialValues: PropTypes.object,
  form: PropTypes.object,
  onFinish: PropTypes.func,
  steps: PropTypes.array,
  step: PropTypes.number,
  handleEnterPress: PropTypes.func,
  handleBackspacePress: PropTypes.func,
  setStep: PropTypes.func,
  cartItem: PropTypes.object
}

export default CheckoutAdvancedFormWithPreview
