import { ChatConfigProvider, UIProvider } from '../../../../contexts'
import {
  ChatsProvider,
  MessagesProvider
} from '@qonsoll/lentli-chat-core/contexts'

import PropTypes from 'prop-types'

const ChatWrapper = ({
  oppositeUser,
  children,
  userData,
  onSendNotification,
  onUpdateMessageStatus,
  config,
  fbConfig
}) => (
  <ChatConfigProvider config={config}>
    <UIProvider>
      <ChatsProvider
        fbConfig={fbConfig}
        oppositeUser={oppositeUser}
        userData={userData}
        onSendNotification={onSendNotification}
        onUpdateMessageStatus={onUpdateMessageStatus}>
        <MessagesProvider>{children}</MessagesProvider>
      </ChatsProvider>
    </UIProvider>
  </ChatConfigProvider>
)

ChatWrapper.propTypes = {
  oppositeUser: PropTypes.shape({
    data: PropTypes.object,
    loading: PropTypes.bool
  }).isRequired,
  setOppositeUserId: PropTypes.func,
  userData: PropTypes.object.isRequired,
  onSendNotification: PropTypes.func,
  onUpdateMessageStatus: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element
  ]),
  config: PropTypes.shape({
    constants: PropTypes.shape({
      VIDEO_ARCHIVE: PropTypes.string,
      USERS: PropTypes.string
    }),
    assets: PropTypes.shape({ noDataImage: PropTypes.string }),
    components: {},
    functionality: PropTypes.shape({
      scheduleMessages: PropTypes.bool,
      videoArchive: PropTypes.bool
    })
  }),
  fbConfig: PropTypes.object
}

export default ChatWrapper
