import { useEvent } from 'hooks'
import { useState } from 'react'

const useDrawerActions = () => {
  const [complaintDrawerProps, setComplaintDrawerProps] = useState({
    complaint: null,
    visibility: false
  })

  const onComplaintSideDrawerOpen = useEvent((complaint) => {
    setComplaintDrawerProps({
      complaint: complaint,
      visibility: true,
      status: complaint.status
    })
  })
  const onComplaintSideDrawerClose = useEvent(() =>
    setComplaintDrawerProps((prev) => ({ ...prev, visibility: false }))
  )

  return {
    complaintDrawerProps,
    onComplaintSideDrawerOpen,
    onComplaintSideDrawerClose
  }
}

export default useDrawerActions
