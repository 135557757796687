import { Button, Image, Space, Typography, theme } from 'antd'

import IllustrationNotFound from '__assets__/illustrations/not-found.svg'
import { ServiceLayout } from 'components'
import { useNavigate } from 'react-router-dom'
import { useTranslations } from 'hooks'

const NotFound = () => {
  const { t } = useTranslations()
  const navigate = useNavigate()
  const { sizeLG } = theme.useToken().token
  const goBack = () => navigate(-3)

  return (
    <ServiceLayout>
      <Space className="flex" direction="vertical" align="center">
        <Image src={IllustrationNotFound} alt="Not Found" preview={false} />
        <div className="text-align-center">
          <Typography.Title>{`${t('Oops')}... ${t(
            'Page not found'
          )}`}</Typography.Title>
          <Typography.Text>
            {`${t('We can')}'${t(
              't seem to find the page you are looking for'
            )}.`}
          </Typography.Text>
        </div>
        <Button
          style={{ marginTop: sizeLG }}
          type="primary"
          size="large"
          onClick={goBack}>
          {t('Go back')}
        </Button>
      </Space>
    </ServiceLayout>
  )
}

export default NotFound
