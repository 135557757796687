import { useMemo } from 'react'
const useCheckIfProductCanBePublished = (product) => {
  return useMemo(() => {
    return (
      !!product?.name &&
      !!product?.pricePerDay &&
      product?.mediaObjects?.length &&
      !!product?.cancellationTerms &&
      !!product?.addressId
    )
  }, [
    product?.name,
    product?.pricePerDay,
    product?.mediaObjects?.length,
    product?.cancellationTerms,
    product?.addressId
  ])
}

export default useCheckIfProductCanBePublished
