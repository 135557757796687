import styled from 'styled-components'

const SelectWrapper = styled.div`
  position: relative;
  width: 100%;

  .prefix-icon-wrapper {
    position: absolute;
    z-index: 1;
    width: 38px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  && .ant-select .ant-select-selector {
    padding-left: ${({ isIconExist }) => isIconExist && '32px'};
  }
`

export default SelectWrapper
