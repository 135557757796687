import {
  AccountMenu,
  LanguageToggle,
  LogoutBtn,
  ModeSelect,
  ProfileButton
} from '../../components'
import { Button, Divider, Dropdown } from 'antd'
import { useScreen, useTranslations } from 'hooks'

import { APP_PATHS } from '__constants__'
import { CartButton } from 'domains/Cart/components'
import { Icon } from 'components'
import { MenuButton } from './BottomMenu.styled'
import { NotificationCard } from '../../components'
import PropTypes from 'prop-types'
import { useGetSettingsMenu } from './hooks'
import { useNavigate } from 'react-router-dom'

const { SETTINGS } = APP_PATHS

const BottomMenu = (props) => {
  const { collapsed, isRentee } = props

  const { t } = useTranslations()
  const navigate = useNavigate()

  const { lg } = useScreen()

  const navigateToSettingsGlobal = () => !lg && navigate(SETTINGS)

  const settingsMenu = useGetSettingsMenu()

  return (
    <div>
      <Divider />
      <div
        className="mb-16"
        style={{ marginLeft: collapsed ? '19px' : '16px' }}>
        <AccountMenu collapsed={collapsed} noDropdownMenu>
          <Button type="text" className="flex align-center justify-center p-8">
            <Icon name="SwitchHorizontal1Outlined" size={16} />
          </Button>
        </AccountMenu>
      </div>
      <div className={collapsed ? 'mb-8' : 'mx-16 mb-8'}>
        <ModeSelect collapsed={collapsed} />
      </div>
      <div style={{ marginLeft: collapsed ? '2px' : 0 }}>
        <ProfileButton collapsed={collapsed} />
      </div>

      {/* Cart */}
      <CartButton disabled={!isRentee} collapsed={collapsed}>
        {t('Cart')}
      </CartButton>
      {/* Notifications */}
      <NotificationCard collapsed={collapsed}>
        {t('Notifications')}
      </NotificationCard>
      <Dropdown
        menu={{ items: settingsMenu }}
        trigger="hover"
        placement="rightBottom">
        <MenuButton
          collapsed={collapsed}
          onClick={navigateToSettingsGlobal}
          block
          prefix={<Icon size={16} name="Settings2Outlined" />}
          suffix={<Icon size={16} name="ChevronRightOutlined" />}>
          {t('Settings')}
        </MenuButton>
      </Dropdown>
      {/* Language */}

      <LanguageToggle collapsed={collapsed} />

      <Divider className="m-4" />
      <div style={{ marginLeft: collapsed ? '2px' : 0 }}>
        <LogoutBtn collapsed={collapsed} />
      </div>
    </div>
  )
}

BottomMenu.propTypes = {
  collapsed: PropTypes.bool,
  isRentee: PropTypes.bool
}

export default BottomMenu
