export const wrapperStyles = {
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}

export const iconStyles = {
  name: 'LoadingFilled',
  spin: true,
  mr: '8px',
  size: 24
}
