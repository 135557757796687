import { useChangeUserStatus, useInitializeUser } from '../../hooks'
import { useEffect, useMemo } from 'react'
import { useHandleError, useStateWithStorage } from 'hooks'
import { useGetUser } from 'domains/User/hooks'

import PropTypes from 'prop-types'
import UserContext from './UserContext'
import firebase from 'firebase/compat/app'
import { useAuthState } from 'react-firebase-hooks/auth'

const UserProvider = ({ children }) => {
  /* The above code is a function that takes in a callback function as an argument.
  The callback function is then called with the error as an argument. */
  const handleError = useHandleError()

  const [isRentee, setIsRentee] = useStateWithStorage('false', 'isRentee')
  const [auth] = useAuthState(firebase.auth())

  const [user, loadingUser] = useGetUser(auth?.uid)

  /* Using the useInitializeUser hook to get the user from the firebase auth state. */
  const { userId, token, loading, error } = useInitializeUser()

  /* Change user online status */
  useChangeUserStatus(userId)

  // Handling user fetching error
  useEffect(() => {
    error && handleError(error)
  }, [error, handleError])

  // Preparing context
  const contextData = useMemo(() => {
    const isUserLoaded = user && !loading & !loadingUser

    return {
      user,
      token: token,
      loading: loading || loadingUser,
      loaded: isUserLoaded,
      setIsRentee,
      isRentee,
      userId
    }
  }, [user, loading, loadingUser, token, setIsRentee, isRentee, userId])

  return (
    <UserContext.Provider value={contextData}>{children}</UserContext.Provider>
  )
}

UserProvider.propTypes = { children: PropTypes.node }

export default UserProvider
