import { Typography, theme } from 'antd'

import { CartList } from 'domains/Cart/components'
import { ContentWrapper } from './CartContent.styled'
import { Fragment } from 'react'
import PropTypes from 'prop-types'
import { useTranslations } from 'hooks'

const PopoverContent = (props) => {
  const { cartItems } = props
  const { colorBorderSecondary } = theme.useToken().token
  const { t } = useTranslations()

  return (
    <Fragment>
      <div className="flex align-center mb-12">
        <Typography.Title level={5} className="mr-4">
          {t('My cart')}{' '}
        </Typography.Title>
        <Typography.Title level={5} style={{ fontWeight: 'normal' }}>
          {`(${cartItems?.length})`}
        </Typography.Title>
      </div>

      <ContentWrapper className="mb-12" borderColor={colorBorderSecondary}>
        <CartList carts={cartItems} />
      </ContentWrapper>
    </Fragment>
  )
}

PopoverContent.propTypes = {
  cartItems: PropTypes.array
}

export default PopoverContent
