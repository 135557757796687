import { useMutate, useTranslations } from 'hooks'

import { COLLECTIONS } from '__constants__'
import { getId } from 'services/api/firebase'
import { message } from 'antd'
import { updateParentHasManyRelationship } from 'services/api/firebase'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'

const useActionsAddressAdvancedForm = ({ initialData, form } = {}) => {
  /* State for loading */
  const [loading, setLoading] = useState(false)
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting navigate instance */
  const navigate = useNavigate()
  /* Getting mutate function */
  const { create, update } = useMutate()

  /* Function for preparing values for saving */
  const prepareValues = async (values = {}, additionalValues = {}) => {
    /* Getting id */

    const addressId = initialData?.address?._id || getId(COLLECTIONS.ADDRESSES)

    const locationData = values?.address.locationData
    const addressData = values?.address.addressData

    /* Preparing address values */
    const preparedValues = {
      _id: addressId,
      placeId: locationData?.placeId ?? null,
      userId: initialData?.user?._id ?? addressData?.userId ?? null,
      name: values?.name ?? locationData?.name ?? addressData?.name ?? null,
      latitude: locationData?.latitude ?? null,
      longitude: locationData?.longitude ?? null,
      country: locationData?.country ?? null,
      city: locationData?.city ?? null,
      streetName: locationData?.streetName ?? null,
      streetNumber: locationData?.streetNumber ?? null,
      zipCode: locationData?.zipCode ?? null,
      postalCode: locationData?.postalCode ?? null,
      postalTown: locationData?.postalTown ?? null,
      district: locationData?.district ?? null,
      region: locationData?.region ?? null,
      icon: values?.icon ?? null,
      ...additionalValues
    }
    return preparedValues
  }

  /* Saving form data */
  const saveForm = async (values, callback) => {
    try {
      // Prepare data to be saved
      const data = await prepareValues(values)

      // Save data
      if (initialData?.address) {
        await update(COLLECTIONS.ADDRESSES, initialData?.address?._id, data)
        message.success(t('Address successfully updated'))
      } else {
        await create(COLLECTIONS.ADDRESSES, data, data._id)

        await updateParentHasManyRelationship(
          initialData?.user?._id,
          COLLECTIONS.USERS,
          'addressIds',
          data?._id
        )

        message.success(t('Address successfully created'))
      }

      // Final callback
      callback?.()
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      throw new Error(t('Something went wrong during data save'))
    }
  }

  /* On finish callback */
  const onFinish = async () => {
    if (loading) return // Avoid multiple calls

    try {
      setLoading(true)
      // Get form values
      const formValues = form.getFieldsValue()
      // Save data
      await saveForm(formValues)
      // Final callback
      navigate(-1)
    } catch (error) {
      setLoading(false)
      message.error(error.message)
    }
  }

  /* On cancel callback */
  const onReset = () => {
    form.resetFields()
    navigate(-1)
  }

  return { onFinish, onReset, loading, saveForm, prepareValues }
}

export default useActionsAddressAdvancedForm
