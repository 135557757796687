import { CANCELLATION_TERMS_TYPES, CURRENCIES } from '__constants__'
import { useCallback, useEffect, useState } from 'react'

import { Form } from 'antd'

const useInitializeProductAdvancedForm = (initialData, loading) => {
  const [initialValues, setInitialValues] = useState({})
  /* Getting form instance */
  const [form] = Form.useForm()

  const getInitialFieldsValue = useCallback(
    (product, mediaObjects, tags, address, category) => ({
      product: {
        name: product?.name ?? null,
        description: product?.description ?? null,
        brandId: product?.brandId ?? null,
        pricePerDay: product?.pricePerDay ?? null,
        currency: product?.currency ?? CURRENCIES?.NOK,
        isRenterDelivery: product?.isRenterDelivery ?? false,
        status: product?.status ?? null,
        previewImgUrl: product?.previewImgUrl ?? mediaObjects?.[0]?.url ?? null,
        topCategoryId: category?.parentId ?? null,
        categoryId: product?.categoryId ?? null,
        categoryPath: product?.categoryPath ?? null,
        tags: tags ?? [],
        fields: product?.fields ?? null,
        mediaObjects: mediaObjects ?? [],
        cancellationTerms:
          product?.cancellationTerms ?? CANCELLATION_TERMS_TYPES.FIXED
      },
      address: {
        _id: address?._id ?? null,
        latitude: address?.latitude ?? null,
        longitude: address?.longitude ?? null,
        addressData: address ?? {}
      }
    }),
    []
  )

  const setFieldsValue = useCallback(
    (formValues) => {
      form.setFieldsValue(formValues)
      setInitialValues(formValues)
    },
    [form]
  )

  /* Setting initial data */
  useEffect(() => {
    if (initialData && !loading) {
      /* Getting initial values */
      const { product, mediaObjects, tags, address, category } = initialData
      /* Formatting date fields */
      const formValues = getInitialFieldsValue(
        product,
        mediaObjects,
        tags,
        address,
        category
      )
      /* Setting initial values to form instance */
      setFieldsValue(formValues)
    } else {
      const formValues = getInitialFieldsValue()

      setFieldsValue(formValues)
    }
  }, [initialData, getInitialFieldsValue, setFieldsValue, loading])
  return { form, initialValues }
}

export default useInitializeProductAdvancedForm
