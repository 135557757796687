import { COLLECTIONS } from '__constants__'
import { useLoading } from 'hooks'
import { useCallback, useEffect, useState } from 'react'
import { getDocument } from 'services/api/firebase'
import { useHandleError, useTranslations } from 'hooks'

const useGetProductsAndRelatedDataByIds = (productIds) => {
  const handleError = useHandleError()
  const { t } = useTranslations()

  const [productsAndRelatedData, setProductsAndRelatedData] = useState()
  const [loading, setLoading] = useState(true)

  const getProduct = async (productId) => {
    try {
      const product = await getDocument(COLLECTIONS.PRODUCTS, productId)

      const brand = product?.brandId
        ? await getDocument(COLLECTIONS.BRANDS, product?.brandId)
        : {}

      return { ...product, brand }
    } catch (error) {
      handleError(error, t('Error during getting product'))
    }
  }

  const getProductsAndRelatedData = useCallback(async () => {
    try {
      setLoading(true)
      const result = await Promise.all(
        productIds?.map((_id) => getProduct(_id))
      )
      setProductsAndRelatedData(result)
    } catch (error) {
      handleError(error, t('Error during getting product'))
    } finally {
      setLoading(false)
    }
    // t and handleError functions inside dependencies create loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productIds])

  useEffect(() => {
    productIds && getProductsAndRelatedData()
  }, [getProductsAndRelatedData, productIds])

  const computedLoading = useLoading(!productsAndRelatedData, loading)
  return [productsAndRelatedData, computedLoading]
}

export default useGetProductsAndRelatedDataByIds
