import {
  useActionsOrderAdvancedForm,
  useInitializeOrderAdvancedForm
} from './hooks'

import { ComplaintListWithCreate } from 'domains/Complaint/components'
import { Form } from 'antd'
import { FormWrapper } from 'components'
import { KEYS } from '__constants__'
import { OrderSimpleForm } from 'domains/Order/components'
import PropTypes from 'prop-types'
import { useTranslations } from 'hooks'

const OrderAdvancedForm = (props) => {
  const { initialData, group, showTitle } = props

  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting form instance from hook */
  const { form } = useInitializeOrderAdvancedForm(initialData)

  /* Handling enter key press */
  const handleKeyPress = (e) => {
    if (e.key === KEYS.ENTER) {
      e.preventDefault()
      onFinish()
    }
  }

  /* Getting loading state and actions from hook */
  const { loading, onFinish, onReset } = useActionsOrderAdvancedForm({
    initialData,
    form
  })

  /* Generating form field names */
  const getFieldName = (subGroup) => (group ? [...group, subGroup] : [subGroup])

  return (
    <FormWrapper
      onKeyPress={handleKeyPress}
      onFinish={onFinish}
      form={form}
      showTitle={showTitle}
      onReset={onReset}
      submitLoading={loading}
      group={group}
      title={t('Order')}>
      <OrderSimpleForm group={getFieldName('order')} />
      <Form.Item name={getFieldName('complaints')}>
        <ComplaintListWithCreate group={getFieldName('complaints')} showTitle />
      </Form.Item>
    </FormWrapper>
  )
}

OrderAdvancedForm.propTypes = {
  initialData: PropTypes.object,
  group: PropTypes.array,
  showTitle: PropTypes.bool
}

export default OrderAdvancedForm
