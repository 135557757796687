import { Button } from 'antd'
import { useTranslations } from 'hooks'
import { useUserBaseActions } from 'domains/User/hooks'

const DeleteUserButton = () => {
  const { t } = useTranslations()

  const { onDelete, deletionLoading } = useUserBaseActions()

  return (
    <Button danger onClick={onDelete} loading={deletionLoading}>
      {t('Delete account')}
    </Button>
  )
}

export default DeleteUserButton
