import { Button, Col, Form, Input, Row, Typography } from 'antd'
import { useEffect, useState } from 'react'
import { useQueryParams, useTranslations } from 'hooks'

import { Label } from 'modules/session-module/domains/Session/components'
import PropTypes from 'prop-types'
import { useLastSession } from 'modules/session-module/hooks'

const LoginForm = ({
  login,
  onError,
  onSuccess,
  onForgotPasswordClick,
  ...rest
}) => {
  const { t } = useTranslations()
  /* Creating a state variable called loading and setting it to false. */
  const [loading, setLoading] = useState(false)
  /* Using the useLastSession hook to store the last session in the local storage. */
  const lastSession = useLastSession()
  /* Using the useSessionActions hook to get the last session provider. */
  const [form] = Form.useForm()

  // [CLEAN_FUNCTIONS]
  const isEmailExists = useQueryParams('email')

  const onFinish = async (credentials) => {
    /* Setting the loading state to true, then calling the login function, then setting the loading
    state to false. */
    setLoading(true)
    login({ credentials, onError, onSuccess })
    setLoading(false)
  }

  useEffect(() => {
    /* This code is setting the email field to the lastSession email if it exists. */
    if (lastSession.email) {
      form.setFieldsValue({ email: lastSession.email })
    }
  }, [form, lastSession.email])

  const resetMarginBottom = { style: { marginBottom: 18 } }

  const rules = {
    email: [
      {
        type: 'email',
        required: true,
        message: t('Please enter valid email')
      }
    ],
    password: [
      { required: true, message: t('Please, enter your password') },
      { min: 6, message: t('Password must be at least 6 characters') }
    ]
  }

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout="vertical"
      requiredMark="optional"
      {...rest}>
      <Form.Item
        {...resetMarginBottom}
        label={Label({ value: 'Email', htmlFor: 'email' })}
        name="email"
        normalize={(value) => value.trim()}
        rules={rules.email}>
        <Input size="large" autoFocus placeholder="john.doe@example.com" />
      </Form.Item>
      <Form.Item
        {...resetMarginBottom}
        label={Label({ value: 'Password', htmlFor: 'password' })}
        className="mb-24"
        name="password"
        rules={rules.password}>
        <Input.Password
          size="large"
          autoFocus={isEmailExists}
          placeholder={t('At least 6 characters')}
        />
      </Form.Item>
      <Row wrap={false} align="middle">
        <Col flex="auto">
          <Typography.Link onClick={onForgotPasswordClick}>
            {t('Forgot password?')}
          </Typography.Link>
        </Col>
        <Col flex="auto">
          <Button
            block
            htmlType="submit"
            type="primary"
            size="large"
            loading={loading}>
            {t('Login')} 🚀
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

LoginForm.propTypes = {
  form: PropTypes.object,
  login: PropTypes.func.isRequired,
  onError: PropTypes.any.isRequired,
  onFinish: PropTypes.func,
  onSuccess: PropTypes.func,
  onFinishFailed: PropTypes.func,
  onForgotPasswordClick: PropTypes.func
}

export default LoginForm
