import * as firebaseui from 'firebaseui'

import { Col, Divider, Row, Space, Typography } from 'antd'
import { useCallback, useMemo } from 'react'
import { useHandleError, useTranslations } from 'hooks'

import { PATHS } from '__constants__'
import PropTypes from 'prop-types'
import { SignupForm } from 'modules/session-module/domains/Session/components'
import StyledFirebaseAuth from '../AuthMethods/StyledFirebaseAuth'
import VippsAuth from '../AuthMethods/VippsAuth'
import firebase from 'firebase/compat/app'
import { useNavigate } from 'react-router-dom'
import { useSessionActions } from 'modules/session-module/hooks'

const SignupMethods = (props) => {
  const { authSwitchText, authSwitchLinkText, authSwitchPath } = props
  const { t } = useTranslations()
  const navigate = useNavigate()

  const redirect = () => navigate(authSwitchPath)

  const onError = useHandleError()
  const onSuccess = () => {
    navigate(PATHS.CONFIG.AFTER_SIGNUP)
  }
  // navigate(`/auth/${AUTH_PATHS.CONFIRM_EMAIL}`)

  // DO NOT DELETE
  const uiConfig = useMemo(
    () => ({
      signInFlow: 'popup',
      signInOptions: [
        {
          provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          customParameters: { prompt: 'select_account' },
          clientId: process.env.REACT_APP_OAUTH_CLIENT_ID
        }
      ],
      callbacks: {
        // Avoid redirects after sign-in.
        signInSuccessWithAuthResult: () => false
      },
      credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO
      // queryParameterForWidgetMode: 'mode'
    }),
    []
  )

  const { signup } = useSessionActions()

  const uiCallback = useCallback((ui) => ui.disableAutoSignIn(), [])

  const firebaseAuthProps = useMemo(
    () => ({ uiCallback, uiConfig, firebaseAuth: firebase.auth() }),
    [uiCallback, uiConfig]
  )

  return (
    <>
      <Row gutter={[24, 0]} justify="center">
        <Col flex="none">
          <StyledFirebaseAuth {...firebaseAuthProps} />
        </Col>
        <Col flex="none">
          <VippsAuth />
        </Col>
      </Row>
      <Divider plain>{t('OR')}</Divider>
      <SignupForm onError={onError} onSuccess={onSuccess} signup={signup} />
      <Space className="flex justify-center items-center my-24">
        <Typography.Text type="secondary">{authSwitchText}</Typography.Text>
        <Typography.Link onClick={redirect}>
          {authSwitchLinkText}
        </Typography.Link>
      </Space>
    </>
  )
}

SignupMethods.propTypes = {
  authSwitchLinkText: PropTypes.any,
  authSwitchPath: PropTypes.any,
  authSwitchText: PropTypes.any,
  authTypeText: PropTypes.any,
  lastSession: PropTypes.any,
  redirectToFormWithEmail: PropTypes.any
}

export default SignupMethods
