import { RDB_COLLECTIONS, STATISTIC_RANGES_OPTIONS } from '__constants__'
import { get, getDatabase, ref } from 'firebase/database'
import { useEffect, useState } from 'react'

import { getDaysInMonth } from 'helpers'
import { prepareRDBDateKey } from '../../../helpers'
import { useUser } from 'modules/session-module/contexts'

const useGetRentFiguresStats = (range) => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const { user } = useUser()

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)

        const currMonthNumber = STATISTIC_RANGES_OPTIONS?.find(
          (item) => item?.value === range
        )?.monthNumber

        const daysInMonthArr = getDaysInMonth(currMonthNumber, true)

        const promises = daysInMonthArr?.map(async (day) => {
          const datePath = prepareRDBDateKey(currMonthNumber, day)

          const dbRef = ref(
            getDatabase(),
            `${RDB_COLLECTIONS.STATISTICS_BY_DAYS}/${datePath}`
          )
          return await get(dbRef).then((snapshot) => {
            if (snapshot.exists()) {
              return snapshot.val()
            }
          })
        })

        const res = await Promise.all(promises)

        const preparedData = res?.map(
          (item) => item?.renters?.[user?._id]?.total_rent_price || 0
        )
        setData(preparedData)
      } catch (error) {
        setError(error)
      } finally {
        setLoading(false)
      }
    }
    if (user?._id && range) {
      fetchData()
    }
  }, [user?._id, range])

  return [data, loading, error]
}

export default useGetRentFiguresStats
