const VERIFICATION_STATUSES_KEYS = {
  VERIFIED: 'verified',
  UNVERIFIED: 'unverified',
  PENDING: 'pending'
}

const VERIFICATION_STATUSES = {
  [VERIFICATION_STATUSES_KEYS.VERIFIED]: {
    title: 'Verified',
    color: 'colorSuccess'
  },
  [VERIFICATION_STATUSES_KEYS.UNVERIFIED]: {
    title: 'Verification needed',
    color: 'colorError'
  },
  [VERIFICATION_STATUSES_KEYS.PENDING]: {
    title: 'Pending',
    color: 'colorWarning'
  }
}
export { VERIFICATION_STATUSES_KEYS }
export default VERIFICATION_STATUSES
