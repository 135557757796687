import { CardDropdown, SelectableCard } from 'components'
import { Col, Empty, Row, Typography } from 'antd'

import { COLLECTIONS } from '__constants__'
import PropTypes from 'prop-types'
import { updateDocument } from 'services/api/firebase/helpers'
import { useGetReviewSubjectData } from 'domains/Review/hooks'
import { useProductBaseActions } from 'domains/Product/hooks'
import { useTranslations } from 'hooks'

const ReviewSubjectRelationView = (props) => {
  const { review } = props

  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting review subject */
  const [subject, loading] = useGetReviewSubjectData(review)
  /* Getting product base actions */
  const { onOpen, onEdit, showPath, editPath } = useProductBaseActions()

  /* Handling deletion of the item */
  const onDelete = async () => {
    await updateDocument(COLLECTIONS.REVIEWS, review?._id, { subject: null })
  }

  /* Getting actions for relationship view */
  const actions = {
    onDelete,
    onEdit: editPath ? onEdit : null,
    onClick: showPath ? onOpen : null
  }

  return (
    <CardDropdown document={subject} data-testid="subjectListItem" {...actions}>
      <SelectableCard>
        {!subject && !loading && <Empty />}
        {subject && (
          <>
            <div className="mb-12" data-area="subject-details-title">
              <Typography.Title level={5}>
                {subject?.title || 'n/a'}
              </Typography.Title>
            </div>
            <div className="mb-12" data-area="subject-details-brand">
              <Row gutter={16}>
                <Col flex="auto" maxWidth="100%">
                  <Typography.Text type="secondary">
                    {t('Brand')}
                  </Typography.Text>
                </Col>
                <Col flex="none" maxWidth="100%">
                  <Typography.Text>{subject?.brand || 'n/a'}</Typography.Text>
                </Col>
              </Row>
            </div>
            <div className="mb-12" data-area="subject-details-price">
              <Row gutter={16}>
                <Col flex="auto" maxWidth="100%">
                  <Typography.Text type="secondary">
                    {t('Price')}
                  </Typography.Text>
                </Col>
                <Col flex="none" maxWidth="100%">
                  <Typography.Text>{subject?.price || 'n/a'}</Typography.Text>
                </Col>
              </Row>
            </div>
            <div className="mb-12" data-area="subject-details-productCondition">
              <Row gutter={16}>
                <Col flex="auto" maxWidth="100%">
                  <Typography.Text type="secondary">
                    {t('Product condition')}
                  </Typography.Text>
                </Col>
                <Col flex="none" maxWidth="100%">
                  <Typography.Text>
                    {subject?.productCondition || 'n/a'}
                  </Typography.Text>
                </Col>
              </Row>
            </div>
            <div className="mb-12" data-area="subject-details-currency">
              <Row gutter={16}>
                <Col flex="auto" maxWidth="100%">
                  <Typography.Text type="secondary">
                    {t('Currency')}
                  </Typography.Text>
                </Col>
                <Col flex="none" maxWidth="100%">
                  <Typography.Text>
                    {subject?.currency || 'n/a'}
                  </Typography.Text>
                </Col>
              </Row>
            </div>
            <div className="mb-12" data-area="subject-details-availability">
              <Row gutter={16}>
                <Col flex="auto" maxWidth="100%">
                  <Typography.Text type="secondary">
                    {t('Availability')}
                  </Typography.Text>
                </Col>
                <Col flex="none" maxWidth="100%">
                  <Typography.Text>
                    {subject?.availability || 'n/a'}
                  </Typography.Text>
                </Col>
              </Row>
            </div>
            <div className="mb-12" data-area="subject-details-status">
              <Row gutter={16}>
                <Col flex="auto" maxWidth="100%">
                  <Typography.Text type="secondary">
                    {t('Status')}
                  </Typography.Text>
                </Col>
                <Col flex="none" maxWidth="100%">
                  <Typography.Text>{subject?.status || 'n/a'}</Typography.Text>
                </Col>
              </Row>
            </div>
            <div className="mb-12" data-area="subject-details-rentingPeriod">
              <Row gutter={16}>
                <Col flex="auto" maxWidth="100%">
                  <Typography.Text type="secondary">
                    {t('Renting period')}
                  </Typography.Text>
                </Col>
                <Col flex="none" maxWidth="100%">
                  <Typography.Text>
                    {subject?.rentingPeriod || 'n/a'}
                  </Typography.Text>
                </Col>
              </Row>
            </div>
            <div data-area="subject-details-description">
              <Typography.Text>{subject?.description || 'n/a'}</Typography.Text>
            </div>
          </>
        )}
      </SelectableCard>
    </CardDropdown>
  )
}

ReviewSubjectRelationView.propTypes = {
  review: PropTypes.object
}

export default ReviewSubjectRelationView
