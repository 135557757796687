import { PATHS } from '__constants__'
import { generatePath, useNavigate } from 'react-router-dom'
import { useEvent, useTranslations, useHandleError } from 'hooks'

import { useMemo, useCallback, useState } from 'react'
import { useUser } from 'modules/session-module/contexts'
import { removeUser } from 'domains/User/helpers'
import { message, Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { useInitializeUser } from 'modules/session-module/hooks'

/* Path's */
const createPath =
  PATHS.AUTHENTICATED.USER_CREATE || PATHS.AUTHENTICATED.USER_WIZARD
const editPath = PATHS.AUTHENTICATED.USER_EDIT
const showPath = PATHS.AUTHENTICATED.USER_SHOW

const useUserBaseActions = ({ userId, actions } = {}) => {
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting navigate instance */
  const navigate = useNavigate()
  const { user } = useUser()
  const { logout } = useInitializeUser()
  const handleError = useHandleError()

  const [deletionLoading, setDeletionLoading] = useState(false)

  /* Function to handle back click */
  const handleBack = useEvent(() => navigate(-1))

  /* Handling opening of the create page */
  const handleCreate = useEvent(() => {
    navigate(createPath)
  })

  /* Handling opening of the show page */
  const handleOpen = useEvent(() => {
    const pathParams = { userId }
    const path = generatePath(showPath, pathParams)
    navigate(path)
  })

  /* Handling opening of the edit page or change view to edit mode */
  const handleEdit = useEvent(() => {
    const pathParams = { userId }
    const path = generatePath(editPath, pathParams)
    navigate(path)
  })

  const onUserDelete = useCallback(async () => {
    setDeletionLoading(true)
    try {
      const result = await removeUser({
        userId: user?._id,
        accountId: user?.stripeAccountId
      })
      if (result?.statusCode === 200) {
        message.success(t('Account was deleted'))
        logout()
      } else {
        message.error(t(result.error))
      }
    } catch (error) {
      handleError(error, t('Error during deleting account'))
    }
  }, [user?._id, user?.stripeAccountId, t, logout, handleError])

  const confirmDeletion = useCallback(() => {
    Modal.confirm({
      title: t('Confirm Account Deletion'),
      centered: true,
      icon: <ExclamationCircleOutlined />,
      content: t('Are you sure you want to delete your account') + '?',
      okText: t('Delete'),
      onOk: onUserDelete,
      cancelText: t('Cancel')
    })
  }, [t, onUserDelete])

  return useMemo(
    () => ({
      onBack: handleBack,
      onCreate: handleCreate,
      onOpen: handleOpen,
      onEdit: handleEdit,
      onDelete: confirmDeletion,
      ...(actions || {}),
      createPath,
      editPath,
      showPath,
      deletionLoading
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      actions?.onBack,
      actions?.onCreate,
      actions?.onEdit,
      actions?.onOpen,
      handleBack,
      handleCreate,
      confirmDeletion,
      handleEdit,
      handleOpen,
      deletionLoading
    ]
  )
}

export default useUserBaseActions
