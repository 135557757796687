import { getBase64 } from 'helpers'

const getImageSrcBeforeUpload = async (file, setImageSrcCallback) => {
  if (!file) return setImageSrcCallback()

  if (file?.url) return setImageSrcCallback(file?.url)

  if (typeof file?.originFileObj === 'object') {
    const onTransformSuccess = (result) => setImageSrcCallback(result)
    const onTransformError = (error) => {}
    getBase64(file?.originFileObj, onTransformSuccess, onTransformError)
    return
  }
}

export default getImageSrcBeforeUpload
