import PropTypes from 'prop-types'
import { theme } from 'antd'

const verificationIconStyle = { top: -2, left: -4 }

const AccountContainer = (props) => {
  const { noOffsets, children, ...rest } = props
  const { sizeMD, sizeXS } = theme.useToken().token
  const styleComputed = !noOffsets
    ? {
        paddingInline: 0,
        paddingBlock: sizeXS,
        paddingRight: sizeMD
      }
    : {}

  return (
    <div style={styleComputed} {...rest}>
      {children}
    </div>
  )
}

AccountContainer.propTypes = {
  noOffsets: PropTypes.bool,
  children: PropTypes.node
}

export { AccountContainer, verificationIconStyle }
