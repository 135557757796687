import { useMemo, useState } from 'react'

const useComplaintSearch = (complaints) => {
  const [searchedValue, setSearchedValue] = useState('')

  const onSearchChange = (e) => setSearchedValue(e.target.value)

  const searchedComplaints = useMemo(() => {
    if (!searchedValue) {
      return complaints
    }

    const searchedValueLower = searchedValue.toLowerCase()

    return complaints?.filter((item) => {
      const productNameLower = item?.productName?.toLowerCase()

      return productNameLower?.includes(searchedValueLower) && item
    })
  }, [complaints, searchedValue])

  return { onSearchChange, searchedComplaints }
}

export default useComplaintSearch
