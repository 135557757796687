import { useTranslations } from 'hooks'
import { useGetProductsNumber } from 'domains/Category/hooks'
import { StyledNumber, StyledButton } from './CategoryButton.styled'
import { Icon } from '@qonsoll/icons'
import PropTypes from 'prop-types'

const CategoryButton = (props) => {
  const { category, handleOpen, isTopLevel } = props

  const productsNumber = useGetProductsNumber(category?._id)
  const { language } = useTranslations()

  const computedName =
    category?.names?.[language.toUpperCase()] || category?.name
  return (
    <StyledButton
      className="flex align-center"
      onClick={handleOpen(category._id)}
      key={category._id}
      icon={isTopLevel && <Icon name={category?.icon} fill="currentColor" />}>
      {computedName}
      <StyledNumber>{`(${productsNumber})`}</StyledNumber>
    </StyledButton>
  )
}

CategoryButton.propTypes = {
  category: PropTypes.object,
  handleOpen: PropTypes.func,
  isTopLevel: PropTypes.bool
}

export default CategoryButton
