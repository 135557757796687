const styles = {
  inputStyles: {
    height: 32,
    borderRadius: 7
  },
  buttonStyles: {
    borderBottomLeftRadius: 7,
    borderTopLeftRadius: 7
  }
}
export { styles }
