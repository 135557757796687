const AUTH_PATHS = {
  LOGIN: 'login',
  SIGNUP: 'signup',
  FORGOT_PASSWORD: 'forgot-password',
  CONFIRM_EMAIL: 'confirm-email',
  TERMS_AND_CONDITIONS: 'terms-and-conditions',
  VIPPS_CALLBACK: 'vipps-callback'
}

export default AUTH_PATHS
