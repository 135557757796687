import { Checkbox, Typography } from 'antd'
import { ORDER_STATUSES, ORDER_STATUSES_LABELS } from '__constants__'
import { useSubfilterActions, useTranslations } from 'hooks'

import PropTypes from 'prop-types'

const StatusFilter = ({ filterParams, setFilterParams }) => {
  const statuses = [
    ORDER_STATUSES.ACCEPTED,
    ORDER_STATUSES.DECLINED,
    ORDER_STATUSES.IN_PROGRESS
  ]

  const { t } = useTranslations()
  const { onChange } = useSubfilterActions({
    filterParams,
    setFilterParams,
    fieldName: 'status',
    operand: 'array-contains',
    isField: true
  })
  const checkIsEnabled = (data) =>
    filterParams &&
    Object.values(filterParams)?.some((query) => query?.includes(data))

  return (
    <>
      <Typography.Title level={5} className="mb-8">
        {t('Status')}
      </Typography.Title>
      {statuses?.map((status, index) => (
        <div key={`status-${index}`}>
          <Checkbox
            key={`status-${index}`}
            checked={checkIsEnabled(status)}
            onChange={() => onChange(status)}>
            <Typography.Text wordBreak="break-all">
              {ORDER_STATUSES_LABELS[status] || t('Unnamed filter')}
            </Typography.Text>
          </Checkbox>
        </div>
      ))}
    </>
  )
}

StatusFilter.propTypes = {
  filterParams: PropTypes.object,
  setFilterParams: PropTypes.func
}

export default StatusFilter
