import { Breadcrumbs, List, PageActions, PageLayout, Spinner } from 'components'
import {
  OrderList,
  OrderMobileActions,
  OrderSimpleView
} from 'domains/Order/components'
import { useCurrentScreen, useListView, useTranslations } from 'hooks'
import {
  useGetOrdersByUserAndStatus,
  useOrderBaseActions,
  useOrderFilter,
  useOrderSearch,
  useOrderSort
} from 'domains/Order/hooks'

import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'

const OrdersAllPage = (props) => {
  const { isListWithCreate, withHeader = true } = props
  const currScr = useCurrentScreen()
  const location = useLocation()

  const isSmallScr = currScr === 'xs' || currScr === 'sm'
  const orderId = location.state?.orderId
  const role = location.state?.role
  /* Getting translations instance */
  const { t } = useTranslations()
  /* State for controlling list view with localStorage remembering */
  const [listView, setListView] = useListView('order')

  /* Getting base actions */
  const listActions = useOrderBaseActions()

  // Get orders related data by user and status
  const [orders, loading] = useGetOrdersByUserAndStatus()
  const sortProps = useOrderSort(orders)
  const filterProps = useOrderFilter(sortProps?.sortedOrders)
  const searchProps = useOrderSearch(
    filterProps?.filteredOrders || sortProps?.sortedOrders
  )

  /* Configuring page header */
  const headingProps = {
    title: t('Orders'),
    textAlign: 'left'
  }

  /* Configuring page actions */
  const actions = {
    filter: false,
    sort: false,
    listView: false
  }

  /* Extracting base actions */
  const { onBack, onCreate } = listActions

  return (
    <PageLayout
      actions={
        withHeader ? (
          <PageActions
            listView={listView}
            setListView={setListView}
            actions={actions}
          />
        ) : null
      }
      onBack={withHeader ? onBack : null}
      breadcrumbs={withHeader ? <Breadcrumbs /> : null}
      headingProps={withHeader ? headingProps : null}
      area="orders">
      <div>
        {!loading ? (
          !isSmallScr ? (
            <OrderList
              span={{ xs: 24 }}
              listView={listView}
              actions={{ onCreate }}
              isListWithCreate={isListWithCreate}
              orders={orders}
              orderId={orderId}
              role={role}
            />
          ) : (
            <>
              <OrderMobileActions
                sortProps={sortProps}
                filterProps={filterProps}
                searchProps={searchProps}
              />
              <List
                className="mb-24"
                emptyProps={{ message: t('No orders are available') }}
                span={{ xs: 24, sm: 24, md: 8, lg: 6 }}
                dataSource={searchProps?.searchedOrders}
                renderItem={(order) => <OrderSimpleView order={order} />}
              />
            </>
          )
        ) : (
          <Spinner height="70vh" />
        )}
      </div>
    </PageLayout>
  )
}

OrdersAllPage.propTypes = {
  isListWithCreate: PropTypes.bool,
  withHeader: PropTypes.bool
}

export default OrdersAllPage
