import { MOMENT_FORMATS } from '__constants__'
import TimePickerStyled from './TimePicker.styled'
import PropTypes from 'prop-types'
import { useCallback, useState } from 'react'
import moment from 'moment'

const TimePicker = (props) => {
  const { selectedDate, ...rest } = props

  const [value, setValue] = useState()

  const onSelect = (value) => {
    setValue(value?.$d)
  }

  const disabledTime = useCallback(
    (now) => {
      const nowFormatted = now?.$d
      const currentHours = moment(nowFormatted).hour()
      const currentMinutes = moment(nowFormatted).minute()

      const disabledHours = []
      const disabledMinutes = []

      const differenceInHours = moment(value).diff(moment(nowFormatted), 'h')

      if (moment(selectedDate).isSame(nowFormatted, 'date')) {
        // set to array hours that are before current hour
        for (let i = 0; i <= currentHours; i++) {
          disabledHours.push(i)
        }
        // set to array minutes that are before current minute if difference between selected hour and current hour is less that 1
        if (differenceInHours < 1)
          for (let i = 0; i <= currentMinutes; i++) {
            disabledMinutes.push(i)
          }
      }
      return {
        disabledHours: () => disabledHours,
        disabledMinutes: () => disabledMinutes
      }
    },
    [selectedDate, value]
  )

  return (
    <TimePickerStyled
      {...rest}
      format={MOMENT_FORMATS.HOURS_MINUTES}
      disabledTime={disabledTime}
      onSelect={onSelect}
    />
  )
}

TimePicker.propTypes = {
  isDisablePreviousTime: PropTypes.bool,
  selectedDate: PropTypes.object
}
export default TimePicker
