import { COLLECTIONS } from '__constants__'
import { collection } from 'firebase/firestore'
import { firestore } from 'services/firebase'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { useMemo } from 'react'

const useListenUserChats = (userId) => {
  const querySnapshot = useMemo(
    () =>
      userId
        ? collection(
            firestore,
            `${COLLECTIONS.CHAT_USERS}/${userId}/folders/ALL_CHATS/chats`
          )
        : null,
    [userId]
  )

  return useCollectionData(querySnapshot)
}

export default useListenUserChats
