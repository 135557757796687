import { useEvent } from 'hooks'
import { useState } from 'react'

const useDrawerActions = () => {
  const [orderDrawerProps, setOrderDrawerProps] = useState({
    order: null,
    visibility: false
  })
  const [reviewDrawerProps, setReviewDrawerProps] = useState({
    order: null,
    visibility: false
  })
  const onOrderSideDrawerOpen = useEvent((order) => {
    setOrderDrawerProps({ order: order, visibility: true })
  })
  const onOrderSideDrawerClose = useEvent(() =>
    setOrderDrawerProps((prev) => ({ ...prev, visibility: false }))
  )

  const onReviewSideDrawerOpen = useEvent((e, order) =>
    setReviewDrawerProps({ order: order, visibility: true })
  )
  const onReviewSideDrawerClose = useEvent(() =>
    setReviewDrawerProps((prev) => ({ ...prev, visibility: false }))
  )

  return {
    orderDrawerProps,
    reviewDrawerProps,
    onOrderSideDrawerOpen,
    onOrderSideDrawerClose,
    onReviewSideDrawerOpen,
    onReviewSideDrawerClose
  }
}

export default useDrawerActions
