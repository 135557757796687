import { Col, Row, Space, Typography } from 'antd'

import { Icon } from '@qonsoll/icons'
import { Link } from 'react-router-dom'
import { ProductHorizontalList } from 'domains/Product/components'
import PropTypes from 'prop-types'
import { useGetProductsByUser } from 'domains/Product/hooks'
import { useGetUserProfilePaths } from 'domains/User/hooks'
import { useParams } from 'react-router-dom'
import { useTranslations } from 'hooks'

const { Title, Text } = Typography

const ProductsListFromCurrentLessor = (props) => {
  const { user, showHeading = true } = props

  const { productId } = useParams()
  const [products = [], productsLoading] = useGetProductsByUser(user?._id, {
    exceptCurrentProduct: true,
    currentProductId: productId
  })
  const { t } = useTranslations()

  const otherFromTitle = `${t('Other from')} ${user?.firstName} ${
    user?.lastName
  }`
  const productsAmount = products?.length

  const productToShow = products?.slice(0, 20)
  const { pagePath } = useGetUserProfilePaths({ user })
  return (
    !!productsAmount && (
      <Row>
        <Col span={24} className="mb-16">
          {showHeading && (
            <Row gutter={16}>
              <Col>
                <Title level={2}>{otherFromTitle}</Title>
              </Col>
              <Col className="mr-auto flex align-end pb-2">
                <Text>{`${productsAmount} ${t('products')}`}</Text>
              </Col>
              <div className="flex align-end ml-auto">
                <Link to={pagePath}>
                  <Space>
                    {t('See all')}
                    <Icon
                      name="ChevronRightOutlined"
                      ml={1}
                      fill="currentColor"
                      size={16}
                    />
                  </Space>
                </Link>
              </div>
            </Row>
          )}
        </Col>
        <Col span={24}>
          <ProductHorizontalList
            products={productToShow}
            productsLoading={productsLoading}
          />
        </Col>
      </Row>
    )
  )
}

ProductsListFromCurrentLessor.propTypes = {
  user: PropTypes.object,
  showHeading: PropTypes.bool
}

export default ProductsListFromCurrentLessor
