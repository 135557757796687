import { Col, Input, Row } from 'antd'

import { Icon } from '@qonsoll/icons'
import PropTypes from 'prop-types'
import { useTranslations } from 'hooks'

const ProductSearch = (props) => {
  const { setSearchValue } = props
  const { t } = useTranslations()

  const handleChange = (e) => {
    setSearchValue(e.target.value || '*')
  }

  // const { isSmallScreen } = useScreen()

  return (
    <Row
      className="flex justify-center align-center mb-24"
      gutter={16}
      wrap={false}>
      <Col flex="auto">
        <Input
          onChange={handleChange}
          suffix={<Icon name="SearchLgOutlined" size={16} />}
          placeholder={t('Search anything...')}
          size="large"
        />
      </Col>
      {/* <Col>
        <Button
          className="flex justify-center align-center"
          size="large"
          icon={
            <Icon name="Map1Outlined" size={16} mr={isSmallScreen ? 0 : 2} />
          }>
          {isSmallScreen ? null : t('Search by location')}
        </Button>
      </Col> */}
    </Row>
  )
}

ProductSearch.propTypes = {
  setSearchValue: PropTypes.func
}

export default ProductSearch
