import { Typography } from 'antd'
import styled from 'styled-components'

const TextStyled = styled(Typography.Text)`
  font-size: ${({ fontSize }) => `${fontSize}px`};
  font-weight: ${({ fontWeight }) => fontWeight};
  color: ${({ color }) => color};
`

const styles = {
  icon: { size: 24, className: 'justify-center' },
  modal: {
    footer: null,
    forceRender: true,
    centered: true,
    width: '650px'
  }
}

export { TextStyled, styles }
