import { Button, Space, message } from 'antd'
import { COLLECTIONS, PRODUCT_STATUSES } from '__constants__'
import {
  useCheckIfProductCanBePublished,
  useProductBaseActions
} from 'domains/Product/hooks'
import { useMemo, useState } from 'react'

import { ConditionalTooltipWrapper } from 'components'
import { Icon } from '@qonsoll/icons'
import { ProductDeletionModal } from 'domains/Product/components'
import PropTypes from 'prop-types'
import { updateDocument } from 'services/api/firebase'
import { useNavigate } from 'react-router-dom'
import { useTranslations } from 'hooks'

const RenterActions = (props) => {
  const { product, productId } = props
  const { t } = useTranslations()
  const navigate = useNavigate()

  // check if all required fields are filled
  const productCanBePublished = useCheckIfProductCanBePublished(product)

  const computedStatus = useMemo(
    () => ({
      blocked: product?.status === PRODUCT_STATUSES.BLOCKED,
      draft: product?.status === PRODUCT_STATUSES.DRAFT,
      published: product?.status === PRODUCT_STATUSES.PUBLISHED
    }),
    [product?.status]
  )

  const publishBtnDisabled = useMemo(
    () =>
      computedStatus.blocked ||
      computedStatus.published ||
      !productCanBePublished ||
      (productCanBePublished && computedStatus.published),
    [computedStatus, productCanBePublished]
  )

  const draftBtnDisabled = useMemo(
    () => computedStatus.draft || computedStatus.blocked,
    [computedStatus]
  )

  const [modalVisible, setModalVisible] = useState(false)

  const blockedTooltip = useMemo(
    () => (computedStatus.blocked ? t('Product is blocked') : null),
    [computedStatus?.blocked, t]
  )

  const publishBtnTooltip = useMemo(() => {
    const productCanNotBePublishedTooltip = !productCanBePublished
      ? t('Fill in the required fields to publish')
      : null

    const productCanBePublishedTooltip =
      productCanBePublished && computedStatus.published
        ? t('Product is already published')
        : null

    return (
      blockedTooltip ||
      productCanNotBePublishedTooltip ||
      productCanBePublishedTooltip
    )
  }, [computedStatus, productCanBePublished, t, blockedTooltip])

  const draftBtnTooltip = useMemo(
    () =>
      computedStatus.blocked
        ? t('Product is blocked')
        : draftBtnDisabled && t('Product is already saved as draft'),
    [computedStatus.blocked, draftBtnDisabled, t]
  )

  const removeBtnTooltip = useMemo(
    () => t('This action will permanently delete your product'),
    [t]
  )

  const { onEdit } = useProductBaseActions({ productId })

  const publishProduct = async () => {
    try {
      await updateDocument(COLLECTIONS.PRODUCTS, productId, {
        status: PRODUCT_STATUSES.PUBLISHED
      })
      message.success(t('Product status successfully updated'))
      navigate(-1)
    } catch (error) {
      message.error(t('Error during product status update'))
    }
  }
  const saveAsDraft = async () => {
    try {
      await updateDocument(COLLECTIONS.PRODUCTS, productId, {
        status: PRODUCT_STATUSES.DRAFT
      })
      message.success(t('Product status successfully updated'))
      navigate(-1)
    } catch (error) {
      message.error(t('Error during product status update'))
    }
  }

  return (
    <Space>
      <ConditionalTooltipWrapper
        withTooltip={computedStatus.blocked}
        title={blockedTooltip}>
        <Button
          disabled={blockedTooltip}
          className="flex align-center justify-center"
          onClick={onEdit}
          icon={<Icon name="Edit3Outlined" fill="currentColor" />}
        />
      </ConditionalTooltipWrapper>
      <ConditionalTooltipWrapper
        withTooltip={draftBtnDisabled}
        title={draftBtnTooltip}>
        <Button onClick={saveAsDraft} disabled={draftBtnDisabled}>
          {t('Save as draft')}
        </Button>
      </ConditionalTooltipWrapper>
      <ConditionalTooltipWrapper />
      <ConditionalTooltipWrapper
        withTooltip={publishBtnDisabled}
        title={publishBtnTooltip}>
        <Button
          type="primary"
          disabled={publishBtnDisabled}
          onClick={publishProduct}>
          {t('Publish')}
        </Button>
      </ConditionalTooltipWrapper>
      <ConditionalTooltipWrapper title={removeBtnTooltip} withTooltip>
        <Button onClick={() => setModalVisible(true)} type="primary" danger>
          {t('Delete product')}
        </Button>
      </ConditionalTooltipWrapper>
      <ProductDeletionModal
        modalVisible={modalVisible}
        productId={productId}
        setModalVisible={setModalVisible}
      />
    </Space>
  )
}

RenterActions.propTypes = {
  product: PropTypes.object,
  productId: PropTypes.string
}

export default RenterActions
