const RENTEE_COMPLAIN_REASONS = {
  WRONG_PRODUCT_INFO: 'WRONG_PRODUCT_INFO',
  LOW_QUALITY_PRODUCT: 'LOW_QUALITY_PRODUCT',
  FAKE_REPLICA_COPY: 'FAKE_REPLICA_COPY',
  COMPLAIN_ABOUT_LESSER: 'COMPLAIN_ABOUT_LESSER'
}

const RENTEE_COMPLAIN_REASONS_LABELS = {
  WRONG_PRODUCT_INFO: 'Wrong product information',
  LOW_QUALITY_PRODUCT: 'Low quality product',
  FAKE_REPLICA_COPY: 'Fake/replica/copy',
  COMPLAIN_ABOUT_LESSER: 'Complain about the lesser'
}

const RENTEE_COMPLAIN_REASONS_OPTIONS = Object.keys(
  RENTEE_COMPLAIN_REASONS_LABELS
).map((key) => ({
  label: RENTEE_COMPLAIN_REASONS_LABELS[key],
  value: key
}))
const RENTER_COMPLAIN_REASONS = {
  PRODUCT_DAMAGE: 'PRODUCT_DAMAGE',
  COMPLAINT_AGAINST_RENTEE: 'COMPLAINT_AGAINST_RENTEE'
}

const RENTER_COMPLAIN_REASONS_LABELS = {
  PRODUCT_DAMAGE: 'Product damage',
  COMPLAINT_AGAINST_RENTEE: 'Complaint against rentee'
}

const RENTER_COMPLAIN_REASONS_OPTIONS = Object.keys(
  RENTER_COMPLAIN_REASONS_LABELS
).map((key) => ({
  label: RENTER_COMPLAIN_REASONS_LABELS[key],
  value: key
}))

export default RENTEE_COMPLAIN_REASONS
export {
  RENTEE_COMPLAIN_REASONS_OPTIONS,
  RENTEE_COMPLAIN_REASONS,
  RENTEE_COMPLAIN_REASONS_LABELS,
  RENTER_COMPLAIN_REASONS,
  RENTER_COMPLAIN_REASONS_LABELS,
  RENTER_COMPLAIN_REASONS_OPTIONS
}
