import { Tabs } from 'antd'
import styled from 'styled-components'

const SettingsTabsStyled = styled(Tabs)`
  height: fill-available;
  height: -moz-available;
  height: -webkit-fill-available;

  .ant-tabs-ink-bar {
    display: none;
  }
  .ant-tabs-nav {
    position: relative;
    z-index: 1;
  }

  .ant-tabs-content-holder {
    display: flex;
    width: ${({ contentwidth }) => contentwidth};
    flex-grow: 0;
    min-height: 100%;
    height: max-content;
  }
  .ant-tabs-nav-list {
    min-width: 200px;
    flex: 0 0 auto !important;
    width: inherit;
    border-radius: 12px;
  }

  .ant-tabs-content-holder {
    border: none;
  }

  .ant-tabs-tabpane {
    border-radius: 12px;
    height: 100%;
  }

  .ant-tabs-tabpane-active {
    padding: 0px !important;
  }

  .ant-tabs-tab {
    border: 1px solid transparent;
    margin: 0 !important;
    padding: 8px 16px !important;
    margin-bottom: 4px !important;
    border-radius: 12px;

    &:hover {
      color: inherit;
      background-color: ${({ backgroundhovercolor }) => backgroundhovercolor};
    }

    &:active {
      background: ${({ activebgcolor }) => activebgcolor};
    }

    .ant-tabs-tab-btn {
      &:active {
        color: ${({ textcolor }) => textcolor} !important;
      }

      transition: 0s !important;
    }
  }

  .ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      font-weight: 400 !important;
      color: ${({ activetextcolor }) => activetextcolor} !important;
      & path:not[id='AlertDangerWarning'] {
        stroke: currentColor !important;
      }
      & path[id='AlertDangerWarning'] {
        stroke: unset !important;
      }

      & path {
        stroke: currentColor !important;
      }

      &:active {
        color: ${({ activetextcolor }) => activetextcolor} !important;
      }
      transition: 0s !important;
    }

    background: ${({ activebgcolor }) => activebgcolor};

    &:hover,
    &:active {
      background-color: ${({ activebgcolor }) => activebgcolor};
    }
  }
`
export default SettingsTabsStyled
