import { FormWrapper, RateSelect } from 'components'
import {
  useActionsReviewAdvancedForm,
  useInitializeReviewAdvancedForm
} from './hooks'

import { Form } from 'antd'
import { KEYS } from '__constants__'
import PropTypes from 'prop-types'
import { ReviewSimpleForm } from 'domains/Review/components'
import { useProductRatingActions } from 'domains/Product/hooks'
import { useTranslations } from 'hooks'
import { useUser } from 'modules/session-module/contexts'

const ReviewAdvancedForm = (props) => {
  const {
    initialData,
    group,
    showTitle,
    productId,
    orderId,
    rating,
    afterSubmitCallback,
    block,
    submitText
  } = props
  const { isRentee } = useUser()

  const { onRatingUpdate } = useProductRatingActions()

  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting form instance from hook */
  const { form } = useInitializeReviewAdvancedForm(initialData, rating)

  /* Handling enter key press */
  const handleKeyPress = (e) => {
    if (e.key === KEYS.ENTER) {
      e.preventDefault()
      onFinish()
    }
  }

  /* Getting loading state and actions from hook */
  const { loading, onFinish, onReset } = useActionsReviewAdvancedForm({
    initialData,
    form,
    productId,
    orderId,
    afterSubmitCallback
  })

  /* Generating form field names */
  const getFieldName = (subGroup) => (group ? [...group, subGroup] : [subGroup])

  return (
    <FormWrapper
      submitText={submitText}
      block={block}
      onKeyPress={handleKeyPress}
      onFinish={onFinish}
      form={form}
      showTitle={showTitle}
      onReset={onReset}
      submitLoading={loading}
      group={group}
      title={t('Review')}>
      <Form.Item name={getFieldName('rating')} label={t('Overall rating')}>
        <RateSelect
          isRentee={isRentee}
          size="lg"
          onRatingUpdate={onRatingUpdate}
          document={productId}
        />
      </Form.Item>
      <ReviewSimpleForm group={getFieldName('review')} />
    </FormWrapper>
  )
}

ReviewAdvancedForm.propTypes = {
  initialData: PropTypes.object,
  productId: PropTypes.object,
  orderId: PropTypes.object,
  group: PropTypes.array,
  showTitle: PropTypes.bool,
  block: PropTypes.bool,
  submitText: PropTypes.string,
  rating: PropTypes.number,
  afterSubmitCallback: PropTypes.func
}

export default ReviewAdvancedForm
