import { Form, theme } from 'antd'
import { Map, PlacesAutocomplete } from 'domains/GoogleMaps/components'
import { useEffect, useState } from 'react'
import { useScreen, useTranslations } from 'hooks'

import { LocationInputWrapper } from './LocationForm.styled'
import PropTypes from 'prop-types'

const REACT_APP_GOOGLE_MAP_ID = process.env.REACT_APP_GOOGLE_MAP_ID

const LocationForm = (props) => {
  const { initialValues, address, group, onAddressChange, disabled } = props

  const [latLng, setLatLng] = useState()

  useEffect(() => {
    const latitude = address?.latitude || initialValues?.latitude
    const longitude = address?.longitude || initialValues?.longitude

    if (latitude && longitude) {
      setLatLng({ lat: latitude, lng: longitude })
    } else setLatLng(null)
  }, [address, initialValues])

  const { t } = useTranslations()
  const { xs } = useScreen()
  const {
    token: { borderRadiusLG }
  } = theme.useToken()

  const getFieldName = (subGroup) => (group ? [...group, subGroup] : [subGroup])

  return (
    <div className="full-height">
      <Map
        options={{
          fullscreenControl: false,
          mapTypeControl: false,
          streetViewControl: false,
          zoomControl: false,
          mapId: REACT_APP_GOOGLE_MAP_ID
        }}
        mapContainerStyle={{
          borderRadius: borderRadiusLG,
          minHeight: '50vh',
          height: '100%'
        }}
        onChange={setLatLng}
        disabled={disabled}
        value={latLng}>
        <LocationInputWrapper xs={xs}>
          <Form.Item
            rules={[
              {
                required: true,
                message: t('Please select location on input on map')
              }
            ]}
            name={getFieldName('locationData')}>
            <PlacesAutocomplete
              disabled={disabled}
              size={xs ? 'large' : 'middle'}
              latLng={latLng}
              setLatLng={setLatLng}
              onAddressChange={onAddressChange}
            />
          </Form.Item>
        </LocationInputWrapper>
      </Map>
    </div>
  )
}

LocationForm.propTypes = {
  initialValues: PropTypes.object,
  form: PropTypes.object,
  group: PropTypes.array,
  exclude: PropTypes.array,
  withAddressSelect: PropTypes.bool,
  onAddressChange: PropTypes.func,
  disabled: PropTypes.bool,
  address: PropTypes.object
}

export default LocationForm
