import moment from 'moment'

const getDobFromDateOfBirth = (dateOfBirth) => {
  const date = moment(dateOfBirth.toDate()).date()
  const month = moment(dateOfBirth.toDate()).month() + 1 // jan=0, dec=11
  const year = moment(dateOfBirth.toDate()).year()

  return { date, month, year }
}

export default getDobFromDateOfBirth
