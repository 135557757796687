import { Typography } from 'antd'
import styled from 'styled-components'

const TextStyled = styled(Typography.Text)`
  position: absolute;
  top: 4px;
  right: 8px;
`
const ParentCategoriesListItem = styled.div`
  padding: 20px 12px;
  height: 112px;
  position: relative;
  display: flex;
  gap: 8px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-right: ${({ isLastInRow, theme }) =>
    !isLastInRow && `1px solid ${theme.colorBorder}`};
  border-bottom: ${({ isLastInCol, theme }) =>
    !isLastInCol && `1px solid ${theme.colorBorder}`};
  cursor: pointer;

  &:before {
    ${({ isLastInRow }) =>
      !isLastInRow &&
      ` content: '';
        right: -8px;
        top: -8px;
        position: absolute;
        width: 16px;
        height: 16px;
        background: #f5f5f5;
      `}
  }
`

export { TextStyled, ParentCategoriesListItem }
