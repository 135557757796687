import { Button, Divider, Popover, Segmented, Space, Tooltip } from 'antd'

import { Icon } from '@qonsoll/icons'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { useScreen } from 'hooks'
import { useTranslations } from 'hooks'

const PageActions = (props) => {
  const {
    onCreate,
    actions = {},
    createText,
    form,
    setListView,
    listView,
    children,
    customActions,
    filterNode
  } = props

  /* Getting translations instance */
  const { t } = useTranslations()

  const { isSmallScreen } = useScreen()

  const navigate = useNavigate()

  return (
    <Space size={8}>
      {customActions && <Space>{customActions}</Space>}
      {actions.filter && (
        <Popover content={filterNode} placement="bottomLeft" trigger="hover">
          <Button
            type="text"
            className="flex align-center justify-center"
            icon={<Icon name="FilterFunnel2Outlined" size={16} />}
          />
        </Popover>
      )}
      {!isSmallScreen && actions.listView && (
        <Tooltip title={t('Change list view')} placement="topRight">
          <Segmented
            onChange={(value) => setListView(value)}
            value={listView}
            options={[
              {
                value: false,
                icon: (
                  <Icon
                    height="28px"
                    alignItems="center"
                    name="Grid1Outlined"
                    fill="currentColor"
                  />
                )
              },
              {
                value: true,
                icon: (
                  <Icon
                    height="28px"
                    alignItems="center"
                    name="ListOutlined"
                    fill="currentColor"
                  />
                )
              }
            ]}
          />
        </Tooltip>
      )}

      {!isSmallScreen &&
        actions.listView &&
        (children || actions.create || form) && (
          <div className="mr-12">
            <Divider type="vertical" />
          </div>
        )}
      {children}
      {actions.create && (
        <>
          <Button type="primary" onClick={onCreate}>
            {createText}
          </Button>
        </>
      )}
      {form && (
        <Space>
          <Button onClick={() => navigate(-1)}>{t('Cancel')}</Button>
          <Button onClick={() => form.submit()} type="primary">
            {t('Save')}
          </Button>
        </Space>
      )}
    </Space>
  )
}

PageActions.propTypes = {
  actions: PropTypes.shape({
    create: PropTypes.bool,
    edit: PropTypes.bool,
    filter: PropTypes.bool,
    listView: PropTypes.bool,
    sort: PropTypes.bool,
    wizard: PropTypes.bool
  }),
  sortMenu: PropTypes.node,
  createText: PropTypes.string,
  filterButtonIcon: PropTypes.any,
  filterButtonText: PropTypes.string,
  listView: PropTypes.bool,
  onCreate: PropTypes.func,
  onEdit: PropTypes.func,
  onFilterButtonClick: PropTypes.func,
  onWizard: PropTypes.func,
  setListView: PropTypes.func,
  form: PropTypes.object,
  children: PropTypes.node,
  customActions: PropTypes.node,
  filterNode: PropTypes.node
}

export default PageActions
