import { RenteeActions, RenterActions } from './components'

import PropTypes from 'prop-types'
import { useUser } from 'modules/session-module/contexts'

const OrderActions = (props) => {
  const { order, fullWidth, onReviewFormToggle, onComplaintFormToggle } = props
  const { isRentee } = useUser()

  return isRentee ? (
    <RenteeActions
      order={order}
      fullWidth={fullWidth}
      onReviewFormToggle={onReviewFormToggle}
      onComplaintFormToggle={onComplaintFormToggle}
    />
  ) : (
    <RenterActions
      order={order}
      fullWidth={fullWidth}
      onComplaintFormToggle={onComplaintFormToggle}
    />
  )
}

OrderActions.propTypes = {
  order: PropTypes.object,
  fullWidth: PropTypes.bool,
  onReviewFormToggle: PropTypes.func,
  onComplaintFormToggle: PropTypes.func
}

export default OrderActions
