import { DottedBlock } from 'components'
import PropTypes from 'prop-types'
import { Typography } from 'antd'
import { calculateFinalPayment } from 'helpers'
import { calculateProductPriceByDays } from 'domains/Product/helpers'
import { currencySymbol } from 'domains/Product/hooks/helpers'
import { useTranslations } from 'hooks'
import { OrderPaymentStatus } from 'domains/Order/components'
import { PAYMENT_STATUSES } from '__constants__'

const OrderPaymentDetails = (props) => {
  const { order, isComplaintView } = props
  const { t } = useTranslations()

  const productCurrencySymbol = currencySymbol(order?.product?.currency)

  const [totalPrice, totalDays] = calculateProductPriceByDays(
    order,
    order?.product
  )
  const totalPriceToShow = calculateFinalPayment(totalPrice)
  const pricePerDayWithFees = calculateFinalPayment(order?.product?.pricePerDay)

  return (
    <>
      <Typography.Title level={5} className="mt-24 mb-12">
        {t('Payment details')}
      </Typography.Title>
      {isComplaintView && (
        <DottedBlock
          className="mb-8"
          height="22px"
          left={<Typography.Text>{t('Status')}</Typography.Text>}
          right={
            <OrderPaymentStatus
              paymentStatus={order?.paymentStatus || PAYMENT_STATUSES.UNPAID}
              noData={<Typography.Text>{t('n/a')}</Typography.Text>}
            />
          }
        />
      )}

      <DottedBlock
        className="mb-8"
        height="22px"
        left={<Typography.Text>{t('Price per day')}</Typography.Text>}
        right={
          <Typography.Text>{`${pricePerDayWithFees} ${productCurrencySymbol}`}</Typography.Text>
        }
      />
      <DottedBlock
        className="mb-8"
        height="22px"
        left={<Typography.Text>{'Period'}</Typography.Text>}
        right={
          <Typography.Text>{`${totalDays} ${
            totalDays === 1 ? 'day' : 'days'
          }`}</Typography.Text>
        }
      />
      {/* For now we don't need this so commented */}
      {/* <DottedBlock
        className="mb-8"
        height="22px"
        left={<Typography.Text>{t('Taxes')}</Typography.Text>}
        right={
          <Typography.Text>{`0 ${productCurrencySymbol}`}</Typography.Text>
        }
      /> */}
      {/* <DottedBlock
        className="mb-8"
        height="22px"
        left={<Typography.Text>{t('Weekly discount')}</Typography.Text>}
        right={
          <Typography.Text>{`0 ${productCurrencySymbol}`}</Typography.Text>
        }
      /> */}
      <DottedBlock
        className="mb-8"
        height="22px"
        left={<Typography.Text strong>{t('Total')}</Typography.Text>}
        right={
          <Typography.Text
            strong>{`${totalPriceToShow} ${productCurrencySymbol}`}</Typography.Text>
        }
      />
    </>
  )
}

OrderPaymentDetails.propTypes = {
  order: PropTypes.object,
  isComplaintView: PropTypes.bool
}

export default OrderPaymentDetails
