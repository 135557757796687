import {
  ConfirmEmailAdvancedView,
  ConfirmPhoneAdvancedView
} from 'modules/session-module/domains/Session/components'
import { useCallback, useMemo, useState } from 'react'

import firebase from 'firebase/compat/app'
import { useAuthBaseActions } from 'modules/session-module/domains/Session/components/AuthMethods/hooks'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useTranslations } from 'hooks'
import { useUser } from 'modules/session-module/contexts'

const useGetSettingsVerificationListItem = (props) => {
  const { setOpenModal } = props
  const { t } = useTranslations()
  const { user, loading } = useUser()
  const [userAuth, authLoading] = useAuthState(firebase.auth())
  const { onVippsLogin } = useAuthBaseActions()
  const [isVerifiedEmail, setIsVerifiedEmail] = useState(false)
  const [isVerifiedPhone, setIsVerifiedPhone] = useState(false)
  const closeModal = useCallback(() => setOpenModal(false), [setOpenModal])

  const verifyEmailCallback = useCallback(() => {
    closeModal()
    setIsVerifiedEmail(true)
  }, [closeModal])

  const verifyPhoneCallback = useCallback(() => {
    closeModal()
    setIsVerifiedPhone(true)
  }, [closeModal])

  const listItems = useMemo(
    () =>
      !loading && !authLoading
        ? [
            {
              icon: 'Mail1Outlined',
              title: t('Email'),
              description: `Email verification confirms the authenticity and ownership of
              an email address through a validation process, ensuring accurate
              contact information`,
              isVerified: isVerifiedEmail || userAuth?.emailVerified,
              content: (
                <ConfirmEmailAdvancedView
                  verifyEmailCallback={verifyEmailCallback}
                />
              )
            },
            {
              icon: 'PhoneCall1Outlined',
              title: t('Phone'),
              description: `Securely confirm their identity and authorize transactions using the BankID authentication system, widely used by banks and financial institutions.`,
              isVerified: isVerifiedPhone || userAuth?.phoneNumber,
              content: (
                <ConfirmPhoneAdvancedView
                  closeModal={closeModal}
                  verifyCallback={verifyPhoneCallback}
                  phone={user?.phone}
                />
              )
            },
            {
              icon: 'VippsOutlined',
              title: t('Vipps'),
              description: `Allows authenticate their identity and confirm their eligibility for using VIPPS, a popular mobile payment service.`,
              isVerified: user?.bankIdVerified,
              onClick: onVippsLogin,
              disabled: false
            }
          ]
        : null,
    [
      user,
      loading,
      closeModal,
      verifyEmailCallback,
      t,
      isVerifiedEmail,
      authLoading,
      userAuth,
      verifyPhoneCallback,
      isVerifiedPhone,
      onVippsLogin
    ]
  )

  return [listItems, loading]
}

export default useGetSettingsVerificationListItem
