import styled from 'styled-components'
import { Steps } from 'antd'

const StepTitleStyled = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`

const StepsStyled = styled(Steps)`
  .ant-steps-item-container {
    display: flex;
    align-items: center;
  }
`

export { StepTitleStyled, StepsStyled }
