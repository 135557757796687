import PropTypes from 'prop-types'
import { Typography } from 'antd'
import { formatAddress } from 'helpers'

const AddressView = (props) => {
  const { address } = props

  const formattedAddress = formatAddress(address)

  return (
    <div className="flex flex-col">
      <Typography.Text strong>
        {address?.name || 'New location'}
      </Typography.Text>
      {address && <Typography.Text>{formattedAddress}</Typography.Text>}
    </div>
  )
}

AddressView.propTypes = {
  address: PropTypes.object,
  onSelect: PropTypes.func,
  index: PropTypes.number,
  isSelected: PropTypes.bool,
  actions: PropTypes.object
}

export default AddressView
