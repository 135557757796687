import { Button, Col, Modal, Row, theme } from 'antd'
import { Fragment, useMemo } from 'react'
import { TextStyled, styles } from './SettingsVerificationSimpleView.styled'

import { Icon } from '@qonsoll/icons'
import PropTypes from 'prop-types'
import { useTranslations } from 'hooks'

const SettingsVerificationSimpleView = ({
  modalTitle,
  children,
  title,
  isVerified,
  isOpenModal,
  showModal,
  closeModal,
  disabled,
  icon,
  description,
  onClick
}) => {
  const { t } = useTranslations()

  const { fontSizeSM, colorPrimaryText } = theme.useToken().token

  const handleOpen = () => (onClick ? onClick() : showModal(title))

  const modal = useMemo(
    () =>
      onClick ? null : (
        <Modal
          {...styles.modal}
          index={`modal-${title}`}
          title={modalTitle}
          open={isOpenModal}
          onCancel={closeModal}>
          {children}
        </Modal>
      ),
    [children, closeModal, modalTitle, title, isOpenModal, onClick]
  )

  return (
    <Fragment>
      {modal}
      <Row
        justify="center"
        align="middle"
        gutter={[40, 0]}
        wrap={false}
        className="ml-0">
        <Col flex="none" className="p-12 align-self-start">
          <Icon {...styles.icon} name={icon} />
        </Col>
        <Col flex="auto">
          <div>
            <TextStyled fontWeight={700}>{title}</TextStyled>
          </div>
          <TextStyled fontSize={fontSizeSM}>{description}</TextStyled>
        </Col>
        <Col flex="none">
          {isVerified ? (
            <div className="flex align-center justify-center">
              <Icon
                name="CheckmarkFilled"
                fill={colorPrimaryText}
                className="mr-8"
              />
              <TextStyled color={colorPrimaryText}>{t('Verified')}</TextStyled>
            </div>
          ) : (
            <Button id={title} disabled={disabled} onClick={handleOpen}>
              {t('Verify')}
            </Button>
          )}
        </Col>
      </Row>
    </Fragment>
  )
}

SettingsVerificationSimpleView.propTypes = {
  modalTitle: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  isVerified: PropTypes.bool,
  isOpenModal: PropTypes.bool,
  showModal: PropTypes.func,
  closeModal: PropTypes.func,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  description: PropTypes.string,
  onClick: PropTypes.func
}

export default SettingsVerificationSimpleView
