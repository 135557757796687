import { matchPath, useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useScreen, useStateWithStorage } from 'hooks'

import { APP_PATHS } from '__constants__'

const {
  PRODUCT_IMAGES_ALL,
  CHECKOUT,
  REFRESH_STRIPE_ACCOUNT,
  RETURN_STRIPE_ACCOUNT,
  STRIPE_CHECKOUT_SUCCESS,
  STRIPE_CHECKOUT_ERROR
} = APP_PATHS

// List of paths where the left aside menu mist be hidden
const PAGES_WITH_NOT_ASIDE = [
  PRODUCT_IMAGES_ALL,
  CHECKOUT,
  REFRESH_STRIPE_ACCOUNT,
  RETURN_STRIPE_ACCOUNT,
  STRIPE_CHECKOUT_SUCCESS,
  STRIPE_CHECKOUT_ERROR
]

const useAside = () => {
  const location = useLocation()

  const [collapsed, setCollapsed] = useStateWithStorage(false, 'collapsed')
  const [showAside, setShowAside] = useState(false)

  const { isSmallScreen, xs } = useScreen()

  const handleCollapse = () => setCollapsed((prevValue) => !prevValue)

  // Checking if the left aside should be hidden on the current page
  useEffect(() => {
    const hideAside = PAGES_WITH_NOT_ASIDE.some((path) =>
      matchPath(path, location.pathname)
    )
    if (xs) {
      setShowAside(false)
    } else setShowAside(!hideAside)
  }, [location.pathname, xs])

  useEffect(() => {
    setCollapsed(isSmallScreen)
  }, [isSmallScreen, setCollapsed])

  useEffect(() => {}, [xs])

  return { collapsed, handleCollapse, showAside }
}

export default useAside
