const PRODUCT_CONDITIONS = {
  SECOND_HAND: 'SECOND_HAND',
  NEW: 'NEW'
}

const PRODUCT_CONDITIONS_LABELS = {
  SECOND_HAND: 'Second hand',
  NEW: 'New'
}

const PRODUCT_CONDITIONS_OPTIONS = Object.keys(PRODUCT_CONDITIONS_LABELS).map(
  (key) => ({ label: PRODUCT_CONDITIONS[key], value: key })
)

export default PRODUCT_CONDITIONS
export {
  PRODUCT_CONDITIONS_OPTIONS,
  PRODUCT_CONDITIONS,
  PRODUCT_CONDITIONS_LABELS
}
