import { Col, Row, Typography } from 'antd'

import DraggerStyled from './Dragger.styled'
import { Icon } from '@qonsoll/icons'
import PropTypes from 'prop-types'
import { createElement } from 'react'
import { useTranslations } from 'hooks'
import { useUpload } from 'contexts'

const UploadMediaWithContext = (props) => {
  const {
    value,
    onChange,
    path,
    accept = '*',
    listType = 'picture-card',
    itemRender
  } = props

  const { t } = useTranslations()

  const { useMediaUpload, setUploadedFiles } = useUpload()

  const { customRequest, handleChange, onRemove } = useMediaUpload({
    onChange,
    value,
    path
  })

  const renderItem = (_, file) =>
    itemRender
      ? createElement(itemRender, {
          value,
          file,
          onRemove,
          onChange,
          setUploadedFiles
        })
      : null

  return (
    <DraggerStyled
      multiple
      accept={accept}
      listType={listType}
      fileList={value}
      onChange={handleChange}
      customRequest={customRequest}
      itemRender={itemRender && renderItem}>
      <Row justify={'center'} gutter={[0, 16]}>
        <Col span={24} className="flex justify-center">
          <Icon size={48} name="Inbox1Outlined" />
        </Col>
        <Col span={24}>
          <Typography.Text>
            {t('Click or drag file to this area to upload')}
          </Typography.Text>
        </Col>
      </Row>
    </DraggerStyled>
  )
}

UploadMediaWithContext.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
  path: PropTypes.string,
  readOnly: PropTypes.bool,
  accept: PropTypes.bool,
  fileList: PropTypes.array,
  listType: PropTypes.string,
  itemRender: PropTypes.node,
  form: PropTypes.object
}
export default UploadMediaWithContext
