import { Col, Divider, Row } from 'antd'

import { SettingsListItem } from '../'
import { useMemo } from 'react'
import { useGetSettingsTabsData } from 'hooks'

const SettingsList = () => {
  const SETTINGS_TABS_DATA = useGetSettingsTabsData()

  const listItems = useMemo(() => {
    const lastIndex = SETTINGS_TABS_DATA?.length - 1
    return SETTINGS_TABS_DATA?.map((item, i) => (
      <Col span={24} key={item?.title}>
        <SettingsListItem {...item} path={item?.key} />
        {lastIndex === i ? null : <Divider className="my-4" />}
      </Col>
    ))
  }, [SETTINGS_TABS_DATA])

  return <Row>{listItems}</Row>
}

export default SettingsList
