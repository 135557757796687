import { Col, Row, Typography, theme } from 'antd'

import { Icon } from '@qonsoll/icons'
import PropTypes from 'prop-types'
import { SelectableCard } from 'components'
import { useTranslations } from 'hooks'

const AddressPreview = (props) => {
  const {
    address,
    onSelect,
    isSelected,
    showAddressTitle = true,
    loading,
    ...rest
  } = props

  const { t } = useTranslations()

  const {
    country,
    city,
    region,
    streetName,
    streetNumber,
    district,
    postalTown
  } = address || {}
  const addressData = [
    country,
    city,
    region,
    streetName,
    streetNumber,
    district,
    postalTown
  ]
    ?.filter(Boolean)
    ?.join(', ')

  const {
    token: { colorBgSpotlight, colorTextSecondary }
  } = theme.useToken()

  return (
    <SelectableCard
      {...rest}
      hoverable
      style={{}}
      isSelected={isSelected}
      data-area="address-details">
      <Row gutter={[24]} align="middle" wrap={false}>
        <Col flex="none">
          <Icon fill={colorBgSpotlight} name="Map1Outlined" size={32} />
        </Col>
        <Col flex="auto">
          {showAddressTitle ? (
            <Typography.Title level={5}>
              {address?.name || 'Custom location'}
            </Typography.Title>
          ) : null}
          <Typography.Text type="secondary">
            {loading ? t('Loading') : addressData || 'Address not selected'}
          </Typography.Text>
        </Col>
        <Col>
          <Icon
            size={28}
            fill={colorTextSecondary}
            name="ArrowNarrowRightOutlined"
          />
        </Col>
      </Row>
    </SelectableCard>
  )
}

AddressPreview.propTypes = {
  address: PropTypes.object,
  onSelect: PropTypes.func,
  index: PropTypes.number,
  isSelected: PropTypes.bool,
  actions: PropTypes.object,
  showAddressTitle: PropTypes.bool,
  loading: PropTypes.bool
}

export default AddressPreview
