/* eslint-disable no-console */
import 'firebase/compat/database'
import 'firebase/compat/firestore'
import 'firebase/database'
import 'firebase/firestore'

import { CHAT_CONFIG } from './chatConfig'
import { ChatWrapper } from 'modules/chat-module/domains/Chat/components'
import PropTypes from 'prop-types'
import { firebaseConfig } from 'services/firebase'
import { useGetUser } from 'domains/User/hooks'
import { useUser } from 'modules/session-module/contexts'

// Chat-packages
// react-rnd
// react-virtualized-auto-sizer
// use-long-press
// file-saver
// react-window

const ChatProvider = ({ oppositeUserId, children }) => {
  const { user } = useUser()
  const [oppositeUserData, loading] = useGetUser(oppositeUserId)

  if (!user || !oppositeUserData) return null

  return (
    <ChatWrapper
      oppositeUser={{
        data: oppositeUserData,
        loading: loading || !oppositeUserData
      }}
      userData={user}
      config={CHAT_CONFIG}
      fbConfig={firebaseConfig}
      onSendNotification={(data) => console.log(data)}
      onUpdateMessageStatus={(data) => console.log(data)}>
      {children}
    </ChatWrapper>
  )
}

ChatProvider.propTypes = {
  oppositeUserId: PropTypes.string.isRequired,
  setOppositeUserId: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element
  ])
}

export default ChatProvider
