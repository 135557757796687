import { Button, Space, Typography, theme } from 'antd'
import { formatDateForView, sortByAlphabet } from 'helpers'
import { useGetTableSearchProps, useTranslations } from 'hooks'

import { Icon } from '@qonsoll/icons'
import PropTypes from 'prop-types'
import { TicketStatus } from 'domains/Ticket/components'
import { useMemo } from 'react'
import { useTicketBaseActions } from 'domains/Ticket/hooks'

const TicketActions = (props) => {
  const { ticketId } = props

  const { colorErrorText } = theme.useToken().token

  const { onEdit, onDelete } = useTicketBaseActions({ ticketId })

  return (
    <Space size={2} className="flex justify-end">
      <Button
        onClick={onEdit}
        type="text"
        className="flex justify-center align-center"
        icon={<Icon name="Edit3Outlined" size={16} />}
      />
      <Button
        onClick={onDelete}
        type="text"
        className="flex justify-center align-center"
        icon={<Icon name="Trash3Outlined" size={16} fill={colorErrorText} />}
      />
    </Space>
  )
}

TicketActions.propTypes = {
  ticketId: PropTypes.string
}

const useGetProductsTableConfig = () => {
  const { t } = useTranslations()
  // product actions
  /* Handling opening of the show page */

  const getColumnSearchProps = useGetTableSearchProps({
    placeholder: t('Search by product name')
  })

  const columns = useMemo(
    () => [
      {
        width: '200px',
        title: t('Ticket'),
        dataIndex: 'title',
        key: 'title',
        sorter: (a, b) => sortByAlphabet(a?.title, b?.title),
        ...getColumnSearchProps('title', ['title']),
        render: (title) => <Typography.Text>{title}</Typography.Text>
      },
      {
        title: t('Description'),
        dataIndex: 'description',
        width: '400px',
        key: 'description',
        sorter: (a, b) => sortByAlphabet(a?.description, b?.description),
        ...getColumnSearchProps('description', ['description']),
        render: (description) => (
          <Typography.Paragraph ellipsis={{ rows: 3 }} className="mb-0">
            {description}
          </Typography.Paragraph>
        )
      },
      {
        width: '400px',
        title: t('Answer'),
        dataIndex: 'answer',
        key: 'answer',
        sorter: (a, b) => sortByAlphabet(a?.answer, b?.answer),
        ...getColumnSearchProps('answer', ['answer']),
        render: (answer) => (
          <Typography.Paragraph ellipsis={{ rows: 3 }} className="mb-0">
            {answer || 'n/a'}
          </Typography.Paragraph>
        )
      },
      {
        width: '150px',
        title: t('Created'),
        dataIndex: '_createdAt',
        key: '_createdAt',
        sorter: (a, b) => a?._createdAt?.seconds - b?._createdAt?.seconds,
        render: (_createdAt) => (
          <Typography.Text>{formatDateForView(_createdAt)}</Typography.Text>
        )
      },
      {
        width: '150px',
        title: t('Status'),
        dataIndex: 'status',
        key: 'status',
        sorter: (a, b) => sortByAlphabet(a?.status, b?.status),
        render: (status) => <TicketStatus status={status} />
      }
    ],
    [t, getColumnSearchProps]
  )

  const paginationStyles = {
    position: ['bottomCenter'],
    pageSize: 5
  }
  const tableProps = {
    scroll: { x: 1000 }
  }

  return {
    columns,
    paginationStyles,
    tableProps
  }
}

export default useGetProductsTableConfig
