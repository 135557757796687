import { Breadcrumb } from 'antd'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const StyledBreadCrumb = styled(Breadcrumb)`
  margin-left: 4px;
  .ant-breadcrumb-separator,
  .ant-breadcrumb-link > * {
    display: flex;
    align-items: center;
  }
`
const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
`

export { StyledBreadCrumb, StyledLink }
