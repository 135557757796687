import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytesResumable
} from 'firebase/storage'
import { useCallback, useState } from 'react'

import { COLLECTIONS } from '__constants__'
import PropTypes from 'prop-types'
import { getId } from 'services/api/firebase'
import { message } from 'antd'
import { storage } from 'services/firebase'
import { useTranslations } from 'hooks'

const UPLOADING_STATUSES = {
  RUNNING: 'running',
  UPLOADING: 'uploading',
  DONE: 'done',
  ERROR: 'error'
}

const uploaderRef = (fileId, path) => {
  return ref(storage, `${path}/${fileId}`)
}

const useUploadMedia = (props) => {
  const { value, onChange, path } = props

  const [files, setFiles] = useState(value || null)
  const { t } = useTranslations()

  /**
   * `handleChange` is a function that takes an object as an argument, and that object has a property
   * called `fileList` which is assigned to a variable called `newFileList`
   */
  const handleChange = ({ fileList: newFileList }) => {
    setFiles(newFileList)
  }

  // It takes a fileData object, and adds it to the current value
  const onUploadSuccess = (fileData) => {
    onChange([...value, fileData])
  }

  /* Updating the state of the file. */
  const processUploading = (snapshot, file) => {
    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
    setFiles((prevFiles) =>
      prevFiles?.map((stateFile) => {
        const { uid } = stateFile

        if (uid === file.uid) {
          return {
            ...stateFile,
            percent: Number.parseInt(progress.toFixed(0)),
            status:
              snapshot.state === UPLOADING_STATUSES.RUNNING
                ? UPLOADING_STATUSES.UPLOADING
                : snapshot.state
          }
        }

        return stateFile
      })
    )
  }
  /* A callback function that is called when the upload fails. */
  const errorUploading = (file) =>
    setFiles((prevFiles) =>
      prevFiles?.map((stateFile) => {
        const { uid } = stateFile
        if (uid === file.uid) {
          return {
            ...stateFile,
            percent: 100,
            status: UPLOADING_STATUSES.ERROR
          }
        }
        return stateFile
      })
    )
  /* A callback function that is called when the upload is successful. */
  const completedUploadingFile = async (
    uploadMedia,
    file,
    fileId,
    onSuccess
  ) => {
    const downloadUrl = await getDownloadURL(uploadMedia.snapshot.ref)
    const fileData = {
      _id: fileId,
      name: file.name,
      type: file.type,
      url: downloadUrl
    }
    setFiles((prevFiles) => {
      const arr = prevFiles?.map((stateFile) => {
        const { uid } = stateFile
        if (uid === file.uid) {
          return {
            ...fileData
          }
        }
        return stateFile
      })
      onChange(arr)
      return arr
    })

    onSuccess?.(fileData)
  }
  /* A callback function that is used to upload the file to the storage. */
  const onUpload = useCallback(
    async (data) => {
      const { file } = data
      const fileId = getId(COLLECTIONS.MEDIA_OBJECTS)
      const storageRef = uploaderRef(fileId, path)
      const uploadMedia = uploadBytesResumable(storageRef, file)
      uploadMedia?.on(
        'state_changed',
        /* Updating the state of the file. */
        (snapshot) => processUploading(snapshot, file),
        /* A callback function that is called when the upload fails. */
        () => errorUploading(file),
        /* A callback function that is called when the upload is successful. */
        () => completedUploadingFile(uploadMedia, file, fileId, onUploadSuccess)
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [path, onUploadSuccess]
  )

  // It removes the file from the local state
  const handleRemoveLocalState = (file) => {
    onChange(value.filter((item) => item._id !== file._id))
    return file
  }

  // It removes files from storage
  const handleRemoveFromStorage = (fileToDelete) => {
    const storageRef = ref(storage, `${path}/${fileToDelete?._id}`)
    deleteObject(storageRef).catch((error) => {
      message.error(`${t('Some problem with deleting files')}: ${error}`)
    })
  }

  return {
    files,
    onUpload,
    handleChange,
    handleRemoveLocalState,
    handleRemoveFromStorage
  }
}

useUploadMedia.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
  path: PropTypes.string
}

export default useUploadMedia
