import { Breadcrumbs, PageLayout, Spinner } from 'components'
import { COLLECTIONS, SERVICE_PATHS } from '__constants__'
import { Col, Row } from 'antd'
import {
  ProductFormActions,
  ProductFormWithPreview
} from 'domains/Product/components/ProductAdvancedForm/components'
import {
  useActionsProductAdvancedForm,
  useGetProductFormItems,
  useInitializeProductAdvancedForm
} from 'domains/Product/components/ProductAdvancedForm/hooks'
import { useCheckFormChange, useTranslations } from 'hooks'
import {
  useGetProductInitialValues,
  useProductPreviewActions
} from 'domains/Product/hooks'
import { useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { ProductPreviewAdvancedView } from 'domains/Product/components'
import { UploadProvider } from 'contexts/UploadContext'
import { useUser } from 'modules/session-module/contexts'
import { validatePressTarget } from 'domains/Product/helpers'

const { ACCESS_DENIED } = SERVICE_PATHS

const ProductEditPage = (props) => {
  const [saveMethod, setSaveMethod] = useState(null)
  const { user } = useUser()
  const { t } = useTranslations()
  const navigate = useNavigate()
  const { productId } = useParams()
  const [initialData, loading] = useGetProductInitialValues(productId)

  // forbid access if user is not the product`s owner
  const isAccessAllowed = useMemo(
    () => (!loading ? initialData?.product?._createdBy === user?._id : true),
    [initialData?.product?._createdBy, loading, user?._id]
  )

  if (!isAccessAllowed) navigate(ACCESS_DENIED)

  const handleBack = () => navigate(-1)
  const { form, initialValues } = useInitializeProductAdvancedForm(
    initialData,
    loading
  )

  const [handleValuesChange, isEqual] = useCheckFormChange(initialValues)

  const {
    loading: submitLoading,
    onFinish,
    onReset
  } = useActionsProductAdvancedForm({
    initialData: initialData,
    form: form
  })

  // get form form-items for placing them in tabs
  const {
    steps,
    step,
    prevStep,
    nextStep,
    isNextStepDisabled,
    isPrevStepDisabled,
    isFirstStep,
    isLastStep,
    isFormValid,
    setStep
  } = useGetProductFormItems(form, initialData)

  const { product, address, isPreview, handlePreview, formStyles } =
    useProductPreviewActions({ form })

  const handleChooseSaveMethod = (saveMethod) => setSaveMethod(saveMethod)
  const handleFinish = () => onFinish(saveMethod)
  const handleEnterPress = (e) => {
    form.validateFields()
    const isIncorrectTarget = validatePressTarget(e)
    if (isNextStepDisabled || isIncorrectTarget) return
    const action = isLastStep ? form.submit : nextStep
    action()
  }

  const headingProps = {
    title: product?.name || t('Edit product'),
    textAlign: 'left'
  }

  return (
    <PageLayout
      onBack={handleBack}
      breadcrumbs={<Breadcrumbs collection={COLLECTIONS.PRODUCTS} />}
      headingProps={headingProps}
      area="product"
      actions={
        <ProductFormActions
          isPreview={isPreview}
          handlePreview={handlePreview}
          product={initialValues?.product}
          onFinish={onFinish}
          handleChooseSaveMethod={handleChooseSaveMethod}
          saveMethod={saveMethod}
          submitDisabled={isEqual}
          isNextStepDisabled={isNextStepDisabled}
          isPrevStepDisabled={isPrevStepDisabled}
          submitLoading={submitLoading}
          isFirstStep={isFirstStep}
          isFormValid={isFormValid}
          isLastStep={isLastStep}
          prevStep={prevStep}
          nextStep={nextStep}
          onReset={onReset}
          form={form}
        />
      }>
      {loading ? (
        <Spinner height="70vh" text={t('Product loading')} />
      ) : (
        <Row className="full-height">
          <Col span={24} style={formStyles}>
            <ProductFormWithPreview
              handlePreview={handlePreview}
              onValuesChange={handleValuesChange}
              handleEnterPress={handleEnterPress}
              initialValues={initialData}
              onFinish={handleFinish}
              steps={steps}
              step={step}
              form={form}
              setStep={setStep}
            />
          </Col>
          <Col span={24}>
            {isPreview && (
              <ProductPreviewAdvancedView
                handlePreview={handlePreview}
                product={product}
                address={address}
              />
            )}
          </Col>
        </Row>
      )}
    </PageLayout>
  )
}

const ProviderWrapper = (props) => {
  return (
    <UploadProvider>
      <ProductEditPage {...props} />
    </UploadProvider>
  )
}

export default ProviderWrapper
