import { Box, DateRange, DateRangeInputs } from 'components'
import { Button, Col, Divider, Popover, Row, Typography, theme } from 'antd'

import { ProductCalendarWrapper } from './ProductRangeSelect.styled'
import PropTypes from 'prop-types'
import { useTranslations } from 'hooks'

const ProductCalendar = (props) => {
  const {
    disabled,
    productId,
    periodInDays,
    range,
    handleChangeRange,
    pricePerDayDisplay,
    pricePerPeriodDisplay,
    computedDayLabel,
    handleBuyProduct,
    formattedDateRange,
    disabledDates,
    ...rest
  } = props
  const { t } = useTranslations()
  const token = theme.useToken().token

  return (
    <ProductCalendarWrapper theme={token} {...rest}>
      <Row>
        <Col span={24} className="flex align-baseline mb-24">
          <Typography.Title level={3}>{pricePerDayDisplay}</Typography.Title>
          <Typography.Text type="secondary">{` /${t('day')}`}</Typography.Text>
        </Col>
        <Col span={24} className="mb-24">
          <Popover
            id="date-range-popover"
            content={
              <DateRange
                showHeader
                onChange={handleChangeRange}
                range={range}
                periodInDays={periodInDays}
                formattedDateRange={formattedDateRange}
                disabledDates={disabledDates}
                computedDayLabel={computedDayLabel}
              />
            }
            arrow={false}
            trigger="click">
            <Box>
              <DateRangeInputs range={range} onChange={handleChangeRange} />
            </Box>
          </Popover>
        </Col>

        <>
          <Col span={24}>
            <Row gutter={16} className="justify-between">
              <Col className="flex gap-4">
                <Typography.Text>{'Period'}:</Typography.Text>
              </Col>
              <Col>
                <Typography.Text>{t(computedDayLabel)}</Typography.Text>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Divider />
          </Col>
          <Col span={24} className="flex justify-between mb-24">
            <Typography.Title level={5}>{t('Total')}</Typography.Title>
            <Typography.Title level={5}>
              {pricePerPeriodDisplay}
            </Typography.Title>
          </Col>
        </>

        <Col span={24}>
          <Button
            disabled={disabled}
            block
            size="large"
            id="buy-product"
            type="primary"
            key="buy-product"
            onClick={handleBuyProduct}>
            {t('Reserve')}
          </Button>
        </Col>
      </Row>
    </ProductCalendarWrapper>
  )
}

ProductCalendar.propTypes = {
  periodInDays: PropTypes.number,
  range: PropTypes.object,
  handleChangeRange: PropTypes.func,
  pricePerDayDisplay: PropTypes.string,
  pricePerPeriodDisplay: PropTypes.string,
  computedDayLabel: PropTypes.string,
  handleBuyProduct: PropTypes.func,
  formattedDateRange: PropTypes.string,
  productId: PropTypes.string,
  disabled: PropTypes.bool,
  disabledDates: PropTypes.array
}

export default ProductCalendar
