import { Button, Row, Col, Typography } from 'antd'
import { useHandleError, useTranslations } from 'hooks'
import { createStripeAccountLink } from 'modules/stripe-module/helpers'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { VerificationDetailsModal } from 'modules/stripe-module/domains/Connect/components'

const { Text } = Typography

const ConnectProvideDocumentSimpleForm = (props) => {
  const { accountId, currentlyDue, requirements } = props

  const [loading, setLoading] = useState(false)
  const [isOpened, setIsOpened] = useState(false)

  const handleError = useHandleError()
  const { t } = useTranslations()

  const handleClick = async () => {
    try {
      setLoading(true)
      const link = await createStripeAccountLink(accountId)
      window.open(link, '_self')
    } catch (error) {
      handleError(error, t('Error during creating account link'))
    } finally {
      setLoading(false)
    }
  }

  return (
    <Row>
      <Col span={24} className="mb-8">
        <Text>
          {`${t(
            'To finish setting up your Stripe account, upload your identity verification'
          )}. ${t('For security, you will be redirected to another page')}`}
        </Text>
      </Col>
      <Col span={24}>
        <Button loading={loading} onClick={handleClick}>
          {t('Upload')}
        </Button>
      </Col>
      <VerificationDetailsModal
        isOpened={isOpened}
        requirements={requirements}
        currentlyDue={currentlyDue}
        setIsOpened={setIsOpened}
      />
    </Row>
  )
}
ConnectProvideDocumentSimpleForm.propTypes = {
  isStripeDocumentsProvided: PropTypes.bool,
  accountId: PropTypes.string,
  isStripeAccountVerified: PropTypes.bool,
  isEdit: PropTypes.bool,
  currentlyDue: PropTypes.array,
  requirements: PropTypes.array
}
export default ConnectProvideDocumentSimpleForm
