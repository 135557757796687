import PropTypes from 'prop-types'
import SettingsTabsStyled from './SettingsTabsStyled'
import { theme } from 'antd'

const SettingsTabs = (props) => {
  const { children, ...rest } = props
  const { controlItemBgActive, colorText, colorFillContent, colorPrimary } =
    theme.useToken().token || {}

  return (
    <SettingsTabsStyled
      tabBarStyle={{ width: 'auto' }}
      contentwidth="100%"
      {...rest}
      activebgcolor={controlItemBgActive}
      activetextcolor={colorPrimary}
      backgroundhovercolor={colorFillContent}
      textcolor={colorText}
      destroyInactiveTabPane>
      {children}
    </SettingsTabsStyled>
  )
}

SettingsTabs.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node)
}

export default SettingsTabs
