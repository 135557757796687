import { CategoryBreadcrumbs, LoadingBox, PageLayout, Rate } from 'components'
import {
  useGetProductCustomActions,
  useGetProductInitialValues
} from 'domains/Product/hooks'
import { useLoading, useTranslations } from 'hooks'
import { useNavigate, useParams } from 'react-router-dom'

import { ProductAdvancedView } from 'domains/Product/components'
import { useMemo } from 'react'
import { useUser } from 'modules/session-module/contexts'

const ProductShowPage = () => {
  const { t } = useTranslations()
  const navigate = useNavigate()
  const { productId } = useParams()
  const [initialValues, initialValuesLoading] =
    useGetProductInitialValues(productId)

  const { isRentee } = useUser()

  const actions = useGetProductCustomActions({
    layout: 'vertical',
    productId: productId,
    isRentee,
    product: initialValues?.product
  })

  const handleBack = () => navigate(-1)
  const loading = useLoading([initialValuesLoading])

  const headerSubtitle = useMemo(
    () => <Rate type="advanced" value={initialValues?.rating} />,
    [initialValues?.rating]
  )
  const headingProps = !loading && {
    title: initialValues?.product?.name || t('Product show'),
    marginBottom: 4,
    textAlign: 'left',
    subTitle: headerSubtitle,
    actions
  }

  return (
    <PageLayout
      onBack={handleBack}
      breadcrumbs={<CategoryBreadcrumbs productId={productId} />}
      headingProps={headingProps}
      area="product">
      <LoadingBox
        loading={loading}
        spinnerProps={{ text: t('Product loading') }}>
        <ProductAdvancedView {...initialValues} />
      </LoadingBox>
    </PageLayout>
  )
}

export default ProductShowPage
