import { Button, Grid, Tooltip, Typography, Row, Col } from 'antd'
import { useCallback, useMemo, useState } from 'react'
import { useOrderActions, useOrderCancellationTime } from 'domains/Order/hooks'

import { ComplaintModal } from 'domains/Complaint/components'
import { Icon } from '@qonsoll/icons'
import { ORDER_STATUSES } from '__constants__'
import PropTypes from 'prop-types'
import { Spinner } from 'components'
import {
  cancelBtnStyle,
  payBtnStyle,
  ReviewButtonStyled
} from './RetnerRenteeActions.styled'
import { useTranslations } from 'hooks'

const { useBreakpoint } = Grid

const toggleTypes = {
  COMPLAINT: 'COMPLAINT',
  REVIEW: 'REVIEW'
}

const RenteeActions = (props) => {
  const { order, fullWidth, onReviewFormToggle, onComplaintFormToggle } = props
  const [loading, setLoading] = useState(null)

  const { t } = useTranslations()
  const { md } = useBreakpoint()

  const eStopPropagation = (e) => e.stopPropagation()

  // type is to define what state callback to use
  const changeVisibility = (type) => (e) => {
    e.stopPropagation()
    if (
      e.target.id === 'review-form-bottom-drawer' ||
      // handles btn-icon click
      e.target.nodeName === 'svg' ||
      e.target.nodeName === 'path' ||
      e.target.noName === 'BUTTON' ||
      e.target.id === 'review-form-toggler'
    ) {
      type === 'COMPLAINT'
        ? onComplaintFormToggle(e)
        : onReviewFormToggle?.(e, order)
    }
  }
  const complaintButtonIcon = <Icon name="AlertTriangleOutlined" size={14} />

  const { cancelOrder } = useOrderActions(setLoading)
  const cancellationTermExpiresAt = useOrderCancellationTime(order)

  const computedOrderStatus = useCallback(
    (status) => status === order?.status,
    [order?.status]
  )

  const computedLoading = useMemo(
    () => loading?.type === ORDER_STATUSES.CANCELED && loading?.loading,
    [loading]
  )

  const orderStatuses = useMemo(
    () => ({
      inProgress: computedOrderStatus(ORDER_STATUSES.IN_PROGRESS),
      accepted: computedOrderStatus(ORDER_STATUSES.ACCEPTED),
      declined: computedOrderStatus(ORDER_STATUSES.DECLINED),
      canceled: computedOrderStatus(ORDER_STATUSES.CANCELED)
    }),
    [computedOrderStatus]
  )

  const isCancelBtnDisabled = useMemo(() => {
    if (cancellationTermExpiresAt) {
      if (orderStatuses.declined || orderStatuses.canceled) return true
      if (orderStatuses.inProgress || orderStatuses.accepted) return false
    }
    return true
  }, [
    cancellationTermExpiresAt,
    orderStatuses.accepted,
    orderStatuses.canceled,
    orderStatuses.declined,
    orderStatuses.inProgress
  ])

  const computedTooltipTitle = useMemo(() => {
    return cancellationTermExpiresAt ? (
      <div className="flex flex-col align-center justify-between mb-8">
        <Typography.Text className="mr-4">{`${t(
          'You can cancel this order until'
        )}: `}</Typography.Text>
        <Typography.Text strong>{cancellationTermExpiresAt}</Typography.Text>
      </div>
    ) : null
  }, [cancellationTermExpiresAt, t])

  const isPayAvaliable =
    order?.status === ORDER_STATUSES.ACCEPTED &&
    order?.paymentStatus === 'UNPAID'
  const handlePay = () => {
    if (order?.checkoutSessionUrl) {
      window.open(order.checkoutSessionUrl, '_blank')
    }
  }

  return (
    <Row onClick={eStopPropagation} gutter={8} className="justify-end">
      {isPayAvaliable && (
        <Col>
          <Button
            style={payBtnStyle}
            block={fullWidth}
            type="primary"
            onClick={handlePay}>
            {t('Pay')}
          </Button>
        </Col>
      )}
      <Col>
        {md ? (
          <ComplaintModal
            productId={order?.product?._id}
            orderId={order?._id}
          />
        ) : (
          <Tooltip title={t('Create complaint')}>
            <Button
              onClick={changeVisibility(toggleTypes.COMPLAINT)}
              className="flex align-center justify-center"
              icon={complaintButtonIcon}
            />
          </Tooltip>
        )}
      </Col>
      <Col>
        <Tooltip title={t('Create review')}>
          <ReviewButtonStyled
            id="review-form-toggler"
            onClick={changeVisibility()}
            disabled={!orderStatuses.accepted}
            icon={<Icon name="Star1Outlined" size={16} fill="currentColor" />}
          />
        </Tooltip>
      </Col>
      <Col>
        <Tooltip
          overlayClassName="tooltip-white-bg"
          title={computedTooltipTitle}>
          <Button
            style={cancelBtnStyle}
            disabled={isCancelBtnDisabled}
            block={fullWidth}
            onClick={cancelOrder(order?._id)}>
            {computedLoading ? <Spinner className="mb-4" /> : t('Cancel')}
          </Button>
        </Tooltip>
      </Col>
    </Row>
  )
}

RenteeActions.propTypes = {
  order: PropTypes.object,
  fullWidth: PropTypes.bool,
  onReviewFormToggle: PropTypes.func,
  onComplaintFormToggle: PropTypes.func
}

export default RenteeActions
