import {
  BottomMenu,
  CreateRentButton,
  MainMenu,
  MenuLogo
} from 'components/layouts/AppLayout/components'
import { Divider, theme } from 'antd'

import { CategoryTree } from 'domains/Category/components'
import { MENU_ITEMS } from '__constants__'
import PropTypes from 'prop-types'
import StyledSider from './Aside.styled'

const Aside = (props) => {
  const { collapsed, isRentee, onLogoClick } = props

  const token = theme.useToken().token

  return (
    <StyledSider
      width={240}
      trigger={null}
      collapsible
      collapsed={collapsed}
      theme={token}>
      <div className="app-sider x-hidden">
        <div className="mb-24">
          {/* logo */}
          <MenuLogo collapsed={collapsed} onClick={onLogoClick} />
        </div>
        {isRentee ? (
          <div className="mx-16 flex justify-center">
            <CategoryTree collapsed={collapsed} />
          </div>
        ) : (
          <div className="mx-16 flex justify-center">
            <CreateRentButton collapsed={collapsed} />
          </div>
        )}
        <Divider className="mt-12 mb-16" />
        {/* main menu */}
        <MainMenu
          menuItems={isRentee ? MENU_ITEMS.RENTEE : MENU_ITEMS.RENTER}
        />
        {/* bottom menu */}
        <div className="mt-auto full-width flex flex-col mb-24">
          <BottomMenu collapsed={collapsed} isRentee={isRentee} />
        </div>
      </div>
    </StyledSider>
  )
}

Aside.propTypes = {
  collapsed: PropTypes.bool,
  isRentee: PropTypes.bool,
  onLogoClick: PropTypes.func
}
export default Aside
