import { Breadcrumbs, SettingsLayout, Spinner } from 'components'
import { Col, Row } from 'antd'
import { useScreen, useTranslations } from 'hooks'

import { AddressAdvancedForm } from 'domains/Address/components'
import { COLLECTIONS } from '__constants__'
import PropTypes from 'prop-types'
import { useGetAddressInitialValues } from 'domains/Address/hooks'
import { useParams } from 'react-router-dom'

const SettingsAddressEditPage = (props) => {
  const { layoutProps } = props

  const { addressId } = useParams()

  const [addressInitialValues, addressLoading] =
    useGetAddressInitialValues(addressId)

  const { xl } = useScreen()

  const { t } = useTranslations()

  const headingProps = {
    title: t('Shipping address edit')
  }

  return (
    <SettingsLayout
      breadcrumbs={<Breadcrumbs collection={COLLECTIONS.ADDRESSES} />}
      headingProps={headingProps}
      area="addressEdit">
      <Row className="mb-16">
        {addressLoading ? (
          <Col span={24}>
            <Spinner height="50vh" />
          </Col>
        ) : (
          <Col {...layoutProps}>
            <AddressAdvancedForm
              initialData={addressInitialValues}
              isBlockButtons={!xl}
            />
          </Col>
        )}
      </Row>
    </SettingsLayout>
  )
}

SettingsAddressEditPage.propTypes = {
  layoutProps: PropTypes.object
}

export default SettingsAddressEditPage
