import { Col, Row } from 'antd'

import Background from '__assets__/illustrations/loginBackground.png'
import styled from 'styled-components'

const layoutStyles = {
  width: '100vw',
  height: '100vh',
  paddingInline: 0
}

const headerWrapper = {
  display: 'flex',
  justifyContent: 'space-between',
  position: 'absolute',
  width: '100%',
  top: 0
}

const RowStyled = styled(Row)`
  height: inherit;
  flex-wrap: initial;
  align-items: center;
  justify-content: ${({ screens }) =>
    screens.sm || screens.xs ? 'center' : 'space-between'};
`

const ChildrenWrapper = styled(Row)`
  position: relative;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding-top: 64px;
`

const ColStyled = styled(Col)`
  height: inherit;
  padding: 32px 48px;
  overflow: auto;
`

const logoStyles = {
  paddingTop: 2
}

const BackgroundStyled = styled.div`
  z-index: 1;
  background-color: ${({ colorTextBase }) => colorTextBase};
  background-image: url(${Background});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  width: 50vw;
`

const spaceStyles = {
  textAlign: 'center',
  maxWidth: '80%',
  margin: 'auto'
}

export {
  ColStyled,
  RowStyled,
  logoStyles,
  spaceStyles,
  layoutStyles,
  ChildrenWrapper,
  headerWrapper,
  BackgroundStyled
}
