import { Card, Steps } from 'antd'
import { useKeyPress, useScreen, useTranslations } from 'hooks'

import { FormWrapper } from 'components'
import { KEYS } from '__constants__'
import PropTypes from 'prop-types'
import { memo } from 'react'

const ProductAdvancedForm = (props) => {
  const { steps, step, handleEnterPress, setStep, form, ...rest } = props
  const { lg } = useScreen()
  const { t } = useTranslations()

  useKeyPress(KEYS.ENTER, handleEnterPress)

  const onChange = (value) => {
    setStep(value)
  }

  return (
    <Card>
      <FormWrapper
        preserve={true}
        title={t('Product')}
        actions={null}
        form={form}
        {...rest}>
        <Steps
          direction={lg ? 'horizontal' : 'vertical'}
          onChange={onChange}
          items={steps}
          className="mb-24"
          current={step}
        />
        {steps?.map(({ title, children }, index) => (
          <div
            key={title}
            style={{ display: step === index ? 'block' : 'none' }}>
            {children}
          </div>
        ))}
      </FormWrapper>
    </Card>
  )
}

ProductAdvancedForm.propTypes = {
  steps: PropTypes.array,
  step: PropTypes.number,
  handleEnterPress: PropTypes.func,
  handleBackspacePress: PropTypes.func,
  setStep: PropTypes.func,
  form: PropTypes.object
}

export default memo(ProductAdvancedForm)
