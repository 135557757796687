import { useCallback, useEffect, useState } from 'react'

import { COLLECTIONS } from '__constants__'
import { getDocument } from 'services/api/firebase'

/**
 * It fetches a mediaObjects documents from Firestore and returns it
 * @param product - The product object that we're getting the mediaObjects for.
 * @returns An array with two values. The first value is the mediaObjects object, and the second value is a
 * boolean that indicates whether the mediaObjects is loading.
 */
const useGetProductMediaObjectsData = (product) => {
  /* State for data */
  const [mediaObjects, setMediaObjects] = useState([])
  /* State for loading */
  const [loading, setLoading] = useState()
  /* State for error */
  const [error, setError] = useState(null)

  /* Fetching data */
  const getMediaObjects = useCallback(async () => {
    try {
      if (!product?.mediaObjects) return setMediaObjects(null)
      /* Setting loading state to true */
      setLoading(true)

      /* Getting mediaObjects promises*/
      const promises = product.mediaObjects.map((mediaObject) =>
        getDocument(COLLECTIONS.MEDIA_OBJECTS, mediaObject)
      )

      /* Getting mediaObjects data */
      const data = await Promise.all(promises)
      setMediaObjects(data)
    } catch (error) {
      setError(error)
    }
    /* Setting loading state to false */
    setLoading(false)
  }, [product?.mediaObjects])

  useEffect(() => {
    product?.mediaObjects !== undefined && getMediaObjects()
  }, [product?.mediaObjects, getMediaObjects])

  return [mediaObjects, loading, error, getMediaObjects]
}

export default useGetProductMediaObjectsData
