import { useCallback, useEffect } from 'react'
import { Form } from 'antd'
import { useState } from 'react'
import dayjs from 'dayjs'

const useInitializeUserAdvancedForm = (initialData) => {
  /* Getting form instance */
  const [form] = Form.useForm()
  const [initialValue, setInitialValue] = useState({})

  const setInitialFieldsValue = useCallback(() => {
    /* Getting initial values */
    const { user, address } = initialData

    const milliseconds =
      user?.birthday?.seconds * 1000 + user?.birthday?.nanoseconds / 1e6
    const birthdayDate = user?.birthday ? dayjs(new Date(milliseconds)) : null

    /* Formatting date fields */
    const formValues = {
      user: {
        avatarUrl: user?.['avatarUrl'] ?? null,
        firstName: user?.['firstName'] ?? null,
        birthday: birthdayDate ?? null,
        lastName: user?.['lastName'] ?? null,
        email: user?.['email'] ?? null,
        phone: user?.['phone'] ?? null,
        addressId: user?.['addressId'] ?? null,
        locationData: address ?? null
      }
    }

    /* Setting initial values to form instance */
    form.setFieldsValue(formValues)
    setInitialValue(formValues)
  }, [form, initialData])

  /* Setting initial data */
  useEffect(() => {
    if (initialData) setInitialFieldsValue()
  }, [initialData, setInitialFieldsValue])

  return { form, setInitialFieldsValue, initialValue }
}

export default useInitializeUserAdvancedForm
