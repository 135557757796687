import { Box } from 'components'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const AnimatedDotsStyled = styled('div')`
  /**
	 * ==============================================
	 * Dot Pulse
	 * ==============================================
	 */
  & {
    margin: auto;
    margin-left: 10px;
    position: relative;
    left: -10000px;
    width: 4px;
    height: 4px;
    border-radius: 3px;
    background-color: ${({ colorPrimary }) => colorPrimary};
    color: ${({ colorPrimary }) => colorPrimary};
    box-shadow: ${({ colorPrimary }) => `10000px 0 0 -5px ${colorPrimary}`};
    animation: dotPulse 1s infinite linear;
    animation-delay: 0.25s;
  }

  &::before,
  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    width: 4px;
    height: 4px;
    border-radius: 3px;
    background-color: ${({ colorPrimary }) => colorPrimary};
    color: ${({ colorPrimary }) => colorPrimary};
  }

  &::before {
    box-shadow: ${({ colorPrimary }) => `9988px 0 0 -5px ${colorPrimary}`};
    animation: dotPulseBefore 1s infinite linear;
    animation-delay: 0s;
  }

  &::after {
    box-shadow: ${({ colorPrimary }) => `10012px 0 0 -5px ${colorPrimary}`};
    animation: dotPulseAfter 1s infinite linear;
    animation-delay: 0.5s;
  }

  @keyframes dotPulseBefore {
    0% {
      box-shadow: ${({ colorPrimary }) => `9992px 0 0 0 ${colorPrimary}`};
    }
    30% {
      box-shadow: ${({ colorPrimary }) => `9992px 0 0 1px ${colorPrimary}`};
    }
    60%,
    100% {
      box-shadow: ${({ colorPrimary }) => `9992px 0 0 0 ${colorPrimary}`};
    }
  }

  @keyframes dotPulse {
    0% {
      box-shadow: ${({ colorPrimary }) => `10000px  0 0 0 ${colorPrimary}`};
    }
    30% {
      box-shadow: ${({ colorPrimary }) => `10000px  0 0 1px ${colorPrimary}`};
    }
    60%,
    100% {
      box-shadow: ${({ colorPrimary }) => `10000px  0 0 0 ${colorPrimary}`};
    }
  }

  @keyframes dotPulseAfter {
    0% {
      box-shadow: ${({ colorPrimary }) => `10008px  0 0 0 ${colorPrimary}`};
    }
    30% {
      box-shadow: ${({ colorPrimary }) => `10008px  0 0 1px ${colorPrimary}`};
    }
    60%,
    100% {
      box-shadow: ${({ colorPrimary }) => `10008px  0 0 0 ${colorPrimary}`};
    }
  }
`

const AnimatedDots = (props) => {
  const { colorPrimary } = props

  return (
    <Box width={28} display="flex" {...props}>
      <AnimatedDotsStyled colorPrimary={colorPrimary || '#1c74e0'} />
    </Box>
  )
}
AnimatedDots.propTypes = {
  colorPrimary: PropTypes.string
}
export default AnimatedDots
