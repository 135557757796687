export const AVAILABLE_FILE_FORMATS = [
  '.pdf',
  '.ppt',
  '.pptx',
  '.txt',
  '.doc',
  '.docx'
]
export const AVAILABLE_IMAGE_FORMATS = [
  'image/jpeg',
  'image/png',
  'image/jpg',
  'image/tiff',
  'image/svg'
]
