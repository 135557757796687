const validatePressTarget = (e) => {
  const AVAILABLE_TAGS = ['INPUT', 'BODY']
  const eventType = e.target.type
  const tagName = e.target.tagName

  const isTagAvailable = AVAILABLE_TAGS.includes(tagName)
  const isTypeAvailable = !eventType || !eventType.includes('search')

  const isDisabled = !isTagAvailable || !isTypeAvailable
  return isDisabled
}

export default validatePressTarget
