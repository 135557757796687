const RENTING_REQUEST_STATUSES = {
  IN_PROGRESS: 'IN_PROGRESS',
  APPROVED: 'APPROVED',
  DECLINED: 'DECLINED'
}

const RENTING_REQUEST_STATUSES_LABELS = {
  IN_PROGRESS: 'In progress',
  APPROVED: 'Approved',
  DECLINED: 'Declined'
}

const RENTING_REQUEST_STATUSES_OPTIONS = Object.keys(
  RENTING_REQUEST_STATUSES_LABELS
).map((key) => ({ label: RENTING_REQUEST_STATUSES[key], value: key }))

export default RENTING_REQUEST_STATUSES
export {
  RENTING_REQUEST_STATUSES_OPTIONS,
  RENTING_REQUEST_STATUSES,
  RENTING_REQUEST_STATUSES_LABELS
}
