import { Button, Typography } from 'antd'
import styled from 'styled-components'
export const cancelBtnStyle = { minWidth: '78px' }
export const payBtnStyle = { minWidth: '52px' }
const ButtonStyled = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
`

const styles = {
  modal: {
    footer: null,
    forceRender: true,
    centered: true,
    width: '650px'
  },

  headerSpace: {
    size: 12,
    direction: 'vertical',
    className: 'flex flex-col align-center mb-24'
  },
  mainSpace: {
    direction: 'vertical',
    className: 'flex flex-col align-center mb-24'
  },
  actionsSpace: {
    direction: 'vertical',
    className: 'full-width'
  },

  verificationInput: {
    length: 6,
    autoFocus: true,
    placeholder: '',
    classNames: {
      container: 'container',
      character: 'character',
      characterInactive: 'character-inactive',
      characterSelected: 'character-selected'
    }
  },
  descriptionWrapper: {
    style: { maxWidth: '500px' },
    className: 'text-align-center'
  }
}

const TextStyled = styled(Typography.Text)`
  font-size: ${({ fontSize }) => `${fontSize}px`};
  color: ${({ color }) => color};
`

const ReviewButtonStyled = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
`
export { ButtonStyled, styles, TextStyled, ReviewButtonStyled }
