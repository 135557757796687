import { ref, remove, set } from 'firebase/database'

import { RDB_COLLECTIONS } from '__constants__'
import { database } from 'services/firebase'
import { useUser } from 'modules/session-module/contexts'

const { PRODUCT_LIKES, PRODUCT_LIKES_BY_USERS } = RDB_COLLECTIONS

const useProductLikesActions = () => {
  const { user } = useUser()

  const onLikeUpdate = async (productId, likeValue) => {
    if (!user?._id) return

    const productLikesPath = `${PRODUCT_LIKES}/${productId}`
    const userLikesPath = `${PRODUCT_LIKES_BY_USERS}/${user._id}`

    const productLikeRef = ref(database, `${productLikesPath}/${user._id}`)
    const likeByUserRef = ref(database, `${userLikesPath}/${productId}`)

    if (likeValue) {
      set(productLikeRef, true)
      set(likeByUserRef, true)
    } else {
      remove(productLikeRef)
      remove(likeByUserRef)
    }
  }

  return { onLikeUpdate }
}

export default useProductLikesActions
