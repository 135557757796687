import { Avatar, Badge, Typography, theme } from 'antd'
/* eslint-disable react/prop-types */
import {
  MESSAGE_TYPES,
  useChatFetch,
  useChatsContext,
  useUser
} from '@qonsoll/lentli-chat-core'
import { StyledBox, styles } from './ChatsList.styled'
import { useLocation, useNavigate } from 'react-router-dom'

import AnimatedDots from '../../../../components/AnimatedDots'
import { Box } from 'components'
import { ChatProvider } from 'modules/chat-module/contexts'
import { Icon } from '@qonsoll/icons'
import PropTypes from 'prop-types'
import { Spinner } from 'components'
import _ from 'lodash'
import moment from 'moment'
import { useEffect } from 'react'
import { useListenOnlineStatus } from 'modules/session-module/hooks'
import { useTranslations } from '@qonsoll/translation'

const ChatView = (props) => {
  const { onSelect, selected } = props
  const { t } = useTranslations()
  const { _id: userId } = useUser()

  const chat = useChatsContext()
  const chatLoading = useChatFetch()[1]
  const { colorPrimary, colorPrimaryBg, colorFillSecondary, borderRadius } =
    theme.useToken().token
  const [isOnline] = useListenOnlineStatus(
    chat?.currentChatData?.oppositeUserId
  )

  const statusMap = {
    SEEN: { name: 'DoubleCheckmarkFilled', fill: colorPrimary || '#848b95' },
    SEND: { name: 'CheckmarkFilled', fill: colorPrimary || '#848b95' }
  }

  if (chatLoading) return null

  const displayName =
    chat?.oppositeUserData?.firstName + ' ' + chat?.oppositeUserData?.lastName
  const chatPhotoUrl = chat?.oppositeUserData?.avatarUrl

  const {
    unreadMessagesAmount,
    lastMessageText,
    lastMessageDateAndTime,
    lastMessageSenderId,
    lastMessageStatus,
    isOppositeUserTyping,
    lastMessageType,
    oppositeUserId
  } = chat?.currentChatData || {}

  return (
    <StyledBox
      borderRadius={borderRadius}
      selected={selected}
      colorPrimaryBg={colorPrimaryBg}
      colorFillSecondary={colorFillSecondary}
      className="px-16 py-8"
      onClick={() => onSelect(oppositeUserId)}>
      <div className="flex flex-nowrap gap-16">
        <div className="flex align-center">
          <Badge
            count={
              isOnline ? (
                <Box {...styles.boxStyles} backgroundColor={colorPrimary} />
              ) : null
            }
            offset={[-4, 36]}>
            <Avatar
              size="large"
              icon={<Icon {...styles.avatarStyles} />}
              src={chatPhotoUrl}
            />
          </Badge>
        </div>
        <div className="ellipsis flex-1">
          <Box display="flex" justifyContent="space-between" flex={1}>
            <Typography.Text className="mb-4 ellipsis mr-8">
              {displayName}
            </Typography.Text>

            <Box display="flex" alignItems="center">
              {lastMessageStatus && userId === lastMessageSenderId && (
                <Icon
                  name={statusMap[lastMessageStatus].name}
                  fill={statusMap[lastMessageStatus].fill}
                  size={16}
                  mr="8px"
                />
              )}

              <Typography.Text>
                {moment(lastMessageDateAndTime?.toDate()).format('HH:mm')}
              </Typography.Text>
            </Box>
          </Box>
          <Box className="flex flex-1 justify-between align-center">
            {isOnline && isOppositeUserTyping ? (
              <Box className="flex flex-nowrap align-center" height={24}>
                <AnimatedDots colorPrimary={colorPrimary} />
                <Typography.Text style={{ color: colorPrimary }} ellipsis>
                  {t('typing')}
                </Typography.Text>
              </Box>
            ) : (
              <Box
                height={24}
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                overflow="hidden">
                <Typography.Text ellipsis type="secondary">
                  {!lastMessageType && !lastMessageText
                    ? t('No messages yet')
                    : lastMessageType !== MESSAGE_TYPES.TEXT
                    ? `${_.upperFirst(lastMessageType?.toLowerCase())}.`
                    : lastMessageText}
                </Typography.Text>
              </Box>
            )}
            <Badge
              color={unreadMessagesAmount && colorPrimary}
              count={unreadMessagesAmount}
            />
          </Box>
        </div>
      </div>
    </StyledBox>
  )
}

const ChatsList = (props) => {
  const { chats, chatsLoading, onSelect, selected } = props
  const { t } = useTranslations()
  const navigate = useNavigate()
  const location = useLocation()

  // we got an chat id from notification and open needed chat
  const chatIdFromNotification = location?.state?.chatId

  useEffect(() => {
    if (chatIdFromNotification) {
      // Find the chat in the list that matches the chatId
      const selectedChat = chats.find(
        (chat) => chat._id === chatIdFromNotification
      )
      if (selectedChat) {
        // if receive chatId from notification automatically select this chat
        onSelect(selectedChat.oppositeUserId)
      }
    }
  }, [chatIdFromNotification, chats, onSelect])

  // Overriding the onSelect function to reset navigation state
  const handleSelect = (chatId) => {
    // Call the original onSelect function
    props.onSelect(chatId)

    // Update the location state to reset chatIdFromNotification
    navigate(location.pathname, {
      state: { ...location.state, chatId: null },
      replace: true
    })
  }

  if (chatsLoading)
    return <Spinner text={t('Chats loading...')} height="100%" />

  return chats
    .sort((a, b) =>
      moment
        .unix(b?._updatedAt?.toDate() || 0)
        .diff(moment.unix(a?._updatedAt?.toDate()) || 0)
    )
    ?.map?.((chatData, index) => (
      <ChatProvider
        key={chatData?._id}
        oppositeUserId={chatData.oppositeUserId}>
        <ChatView
          selected={selected === chatData.oppositeUserId}
          isLast={chats?.length - 1 === index}
          onSelect={handleSelect}
          _id={chatData._id}
          oppositeUserId={chatData.oppositeUserId}
          {...chatData}
        />
      </ChatProvider>
    ))
}

ChatsList.propTypes = {
  userId: PropTypes.any,
  onSelect: PropTypes.func
}

export default ChatsList
