import { useEffect, useState } from 'react'
import firebase from 'firebase/compat/app'

import { COLLECTIONS } from '__constants__'

/**
 * Custom hook to update a user in the Firestore database.
 *
 * @param {string} userId - The ID of the user to update.
 */
const useUserUpdate = (userId) => {
  const [isUserUpdated, setIsUserUpdated] = useState(false)
  const _ref = firebase.firestore()

  useEffect(() => {
    const updateUser = async () => {
      await _ref.collection(COLLECTIONS.USERS).doc(userId).update({
        _updatedAt: firebase.firestore.FieldValue.serverTimestamp()
      })
    }

    if (userId && !isUserUpdated) {
      updateUser()
      setIsUserUpdated(true)
    }
  }, [isUserUpdated, userId])
}

export default useUserUpdate
