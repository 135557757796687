import { Typography, Row, Col, Button } from 'antd'

import {
  PayoutInformationSimpleView,
  PersonalDetailsSimpleView,
  AccountBalancesSimpleView
} from 'modules/stripe-module/domains/Connect/components'
import TabsStyled from './ConnectAdvancedView.styled'
import { useTranslations } from 'hooks'
import PropTypes from 'prop-types'
import { Icon } from '@qonsoll/icons'
import { useNavigate } from 'react-router-dom'
import { SETTINGS_PATHS } from '__constants__'

const { Title } = Typography
const { MY_PROFILE } = SETTINGS_PATHS

const ConnectAdvancedView = (props) => {
  const { connectedAccount, balance } = props

  const navigate = useNavigate()
  const { t } = useTranslations()

  const handleEdit = () => navigate(MY_PROFILE)

  return (
    <Row gutter={[0, 24]}>
      <Col span={24}>
        <TabsStyled
          items={[
            {
              label: <Title level={5}>{t('Account balances')}</Title>,
              key: 'accountBalances',
              children: <AccountBalancesSimpleView balance={balance} />
            }
          ]}
        />
      </Col>
      <Col span={24}>
        <TabsStyled
          items={[
            {
              label: <Title level={5}>{t('Payout information')}</Title>,
              key: 'payoutInformation',
              children: (
                <PayoutInformationSimpleView
                  externalAccount={
                    connectedAccount?.external_accounts?.data?.[0]
                  } // currently we only have one account per user
                  settings={connectedAccount?.settings}
                />
              )
            }
          ]}
        />
      </Col>
      <Col span={24}>
        <TabsStyled
          tabBarExtraContent={
            <Button
              className="flex align-center justify-center"
              icon={<Icon name="Edit4Outlined" />}
              onClick={handleEdit}
            />
          }
          items={[
            {
              label: <Title level={5}>{t('Personal details')}</Title>,
              key: 'personalDetails',
              children: (
                <PersonalDetailsSimpleView
                  individual={connectedAccount?.individual}
                  accountId={connectedAccount?.id}
                />
              )
            }
          ]}
        />
      </Col>
    </Row>
  )
}
ConnectAdvancedView.propTypes = {
  connectedAccount: PropTypes.shape({
    individual: PropTypes.object,
    settings: PropTypes.object,
    external_accounts: PropTypes.object,
    id: PropTypes.string
  }),
  balance: PropTypes.object
}

export default ConnectAdvancedView
